import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  readMoreBox: {
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative',
    '& p': {
      fontSize: '.875rem'
    }
  },
  readMoreBoxRevealed: {
    maxHeight: 'none',
    overflow: 'visible'
  },
  readMoreButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    '& button': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '1.125em',
      width: '100%',
      border: 'none',
      background: 'none',
      color: theme.palette.primary.main,
      textAlign: 'left',
      textDecoration: 'underline'
    }
  },
  sectionContainer: {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(),
    '& h3': {
      fontWeight: 600,
      fontSize: '1.25em',
      color: theme.palette.primary.main
    },
    '& > div': {
      '& > p': {
        paddingTop: theme.spacing(2)
      }
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem',
    flexGrow: 1,
    marginTop: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      width: 250
    }
  }
}))

export default useStyles
