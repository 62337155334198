import React from 'react'
import { Radio, Typography } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './MenuOption.style'

const ACTION_TYPE = {
  ROLE_ADD: 'roleAdd',
  ROLE_CHANGE: 'roleChange'
}

const MenuOption = ({
  title,
  actionType = ACTION_TYPE.ROLE_ADD,
  imageClassName,
  icon,
  role,
  checked,
  color = 'primary',
  handleChange = () => {},
  hidden
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        actionType === ACTION_TYPE.ROLE_ADD ? classes.option : classes.activeOption,
        hidden && 'hide'
      )}
      title={title}
      onClick={handleChange}>
      <div className={classes.content}>
        <div className={imageClassName} style={{ backgroundImage: `url(${icon})` }} />

        <div className={classes.titleContainer}>
          <Typography className={classes.title} color='primary' variant='h6'>
            {title}
          </Typography>

          {actionType === ACTION_TYPE.ROLE_CHANGE && (
            <Typography className={classes.role} color='primary'>
              {role}
            </Typography>
          )}
        </div>
      </div>

      {actionType === ACTION_TYPE.ROLE_ADD && <AddIcon color='primary' />}

      {actionType === ACTION_TYPE.ROLE_CHANGE && (
        <Radio color='primary' className={classes.radio} checked={checked} />
      )}
    </div>
  )
}

export default MenuOption
