import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, IconButton, Popover, Typography, useMediaQuery } from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import defaultUserImage from 'assets/img/blue-user-mock.png'
import profileOrganizer from 'assets/img/profile-organizer.jpg'
import timeKeeperProfile from 'assets/img/time-keeper-profile.jpg'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { tryInscriptionRegister, verifyUserLogged } from 'state/modules/app'
import { loadUserData, signOut } from 'state/modules/auth'
import { USER_ROLE } from 'utils/constants'

import { SignInDialog } from '../SignInDialog'
import { SignUpDialog } from '../SignUpDialog'

import { SelectUserRoleDialog, UserMenu } from './components'
import { useStyles } from './UserSession.style'

const DIALOGS = {
  SIGN_IN_DIALOG: '/sign-in',
  SIGN_UP_DIALOG: '/sign-up'
}

const UserSession = ({
  user,
  hiddenUsername,
  setOpenUserOptions = () => {},
  transparent,
  roleColor
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const contentRef = useRef(null)

  const { userData, userDataPending } = useSelector((state) => state.auth)
  const { userAccount, settingImage } = useSelector((state) => state.userAccounts)
  const { userLogged, activeRole, addRoleState, inscriptionRegister } = useSelector(
    (state) => state.app
  )

  const [openSignInDialog, setOpenSignInDialog] = useState(false)
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false)
  const [showSearchPopupEl, setShowSearchPopupEl] = useState(null)
  const [showUserMenuPopupEl, setShowUserMenuPopupEl] = useState(null)
  const [openSelectUserRoleDialog, setOpenSelectUserRoleDialog] = useState(false)

  useEffect(() => {
    if (user) {
      dispatch(loadUserData(user.id))
    }
  }, [user, dispatch, userAccount, settingImage])

  useEffect(() => {
    if (
      userData &&
      !userData[`is${USER_ROLE.ATHLETE.value}`] &&
      !userData[`is${USER_ROLE.ORGANIZER.value}`] &&
      !userData[`is${USER_ROLE.TIMEKEEPER.value}`]
    )
      setOpenSelectUserRoleDialog(true)
  }, [userData])

  useEffect(() => {
    if (userLogged?.state) {
      const nameBox = userLogged.nameBox || DIALOGS.SIGN_IN_DIALOG
      if (nameBox === DIALOGS.SIGN_IN_DIALOG) {
        setOpenSignInDialog(true)
      } else if (nameBox === DIALOGS.SIGN_UP_DIALOG) {
        setOpenSignUpDialog(true)
      }
    }
  }, [userLogged, user])

  useEffect(() => {
    if (contentRef && addRoleState?.state) setShowUserMenuPopupEl(contentRef.current)
  }, [addRoleState, contentRef])

  useEffect(() => {
    if (inscriptionRegister) {
      setOpenSignUpDialog(true)
    }
  }, [inscriptionRegister])

  const handleUserOptions = (e) => {
    if (!userDataPending && !user && !isDesktop && !openSignInDialog && !openSignUpDialog) {
      setOpenUserOptions(true)
      setShowSearchPopupEl(e.currentTarget)
    }

    if (user) setShowUserMenuPopupEl(e.currentTarget)
  }

  const onClose = () => {
    setShowSearchPopupEl(null)
    setOpenUserOptions(false)
    setOpenSignInDialog(false)
    handleOnCloseSignUpDialog()
  }

  const handleClose = () => {
    if (userLogged?.redirectUrl === location.pathname) {
      history.push(ROUTES.ROOT)
    }

    if (userLogged?.state) {
      dispatch(verifyUserLogged({ state: false }))

      if (user && !user.auth_token) {
        dispatch(signOut())
      }
    }

    onClose()
  }

  const handleOpenSignInDialog = () => {
    setOpenSignInDialog(true)
    setOpenSignUpDialog(false)
  }

  const handleOpenSignUpDialog = () => {
    setOpenSignUpDialog(true)
    setOpenSignInDialog(false)
  }

  const handleOnCloseSignUpDialog = () => {
    if (userLogged?.redirectUrl === location.pathname) {
      history.push(ROUTES.ROOT)
    }

    if (userLogged?.state) {
      dispatch(verifyUserLogged({ state: false }))
    }

    dispatch(tryInscriptionRegister(null))
    setOpenSignUpDialog(false)
  }

  const userImage =
    (activeRole === USER_ROLE.ORGANIZER && profileOrganizer) ||
    (activeRole === USER_ROLE.TIMEKEEPER && timeKeeperProfile) ||
    (Array.isArray(userData?.profileImages) ? userData.profileImages[0] : defaultUserImage)

  return (
    <>
      <div className={classes.container} onClick={handleUserOptions} ref={contentRef}>
        {user && userData && !hiddenUsername && (
          <Typography
            color='primary'
            className={clsx(classes.alias, roleColor?.value)}
            title={`@${userData.username || userData.id}`}>
            @{userData.username || userData.id}
          </Typography>
        )}

        {isDesktop && !user ? (
          <div className={classes.actionContainer}>
            <Typography className={classes.link} onClick={() => setOpenSignInDialog(true)}>
              Iniciá sesión
            </Typography>
            <Typography className={classes.link} onClick={() => setOpenSignUpDialog(true)}>
              Registrate
            </Typography>
          </div>
        ) : !showSearchPopupEl && !openSignInDialog && !openSignUpDialog ? (
          <div className={classes.userContainer}>
            <div
              style={{ backgroundImage: `url(${userImage})` }}
              className={classes.profileImage}
            />
            {user &&
              (showUserMenuPopupEl ? (
                <ArrowDropUpIcon className={clsx(classes.arrowIcon, roleColor?.value)} />
              ) : (
                <ArrowDropDownIcon className={clsx(classes.arrowIcon, roleColor?.value)} />
              ))}
          </div>
        ) : (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
      <Popover
        PaperProps={{
          className: classes.searchPopup
        }}
        elevation={0}
        open={!!showSearchPopupEl}
        onClose={() => {
          setOpenUserOptions(false)
          setShowSearchPopupEl(null)
        }}
        anchorEl={showSearchPopupEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <div className={classes.sessionOptions}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            fullWidth
            onClick={() => {
              setShowSearchPopupEl(null)
              setOpenSignInDialog(true)
            }}>
            Iniciar sesión
          </Button>
          <Button
            color='primary'
            variant='outlined'
            className={classes.button}
            fullWidth
            onClick={() => {
              setShowSearchPopupEl(null)
              setOpenSignUpDialog(true)
            }}>
            Registrate
          </Button>
        </div>
      </Popover>

      {user && (
        <>
          <UserMenu
            popupEl={showUserMenuPopupEl}
            onClose={() => setShowUserMenuPopupEl(null)}
            userImage={userImage}
            roleColor={roleColor}
          />
          <SelectUserRoleDialog
            open={openSelectUserRoleDialog}
            onClose={() => setOpenSelectUserRoleDialog(false)}
          />
        </>
      )}
      <SignInDialog
        open={openSignInDialog}
        onClose={handleClose}
        userLogged={userLogged}
        handleOpenSignUpDialog={handleOpenSignUpDialog}
      />
      <SignUpDialog
        open={openSignUpDialog}
        onClose={handleOnCloseSignUpDialog}
        handleOpenSignInDialog={handleOpenSignInDialog}
        data={inscriptionRegister?.inscription}
        state={inscriptionRegister?.state}
      />
    </>
  )
}

export default UserSession
