import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { EventsProxy } from 'services'
import { SectionLayoutDialog } from 'shared'
import { loadCategories } from 'state/modules/events'
import { CONTENT_TYPES, STRING_EMPTY } from 'utils/constants'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './AddCategoryDialog.style'

const ACTION_TYPE = {
  CREATE: 'create',
  IMPORT: 'import',
  COPY: 'copy'
}

const AddCategoryDialog = ({
  open,
  onClose,
  onOpenCopyCategoryDialog,
  onOpenCreateCategoryDialog
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const fileInputRef = useRef()

  const { event } = useSelector((state) => state.events)

  const [optionValue, setOptionValue] = useState('')
  const [fileLoading, setFileLoading] = useState(false)

  const handleChangeOptionValue = (e) => setOptionValue(e.target.value)

  const handleOnClose = () => {
    setOptionValue('')
    onClose()
  }

  const handleOnAction = () => {
    if (optionValue === ACTION_TYPE.COPY) onOpenCopyCategoryDialog()
    else if (optionValue === ACTION_TYPE.CREATE) onOpenCreateCategoryDialog()
    else if (optionValue === ACTION_TYPE.IMPORT) {
      fileInputRef.current.click()
      return
    }

    onClose()
  }

  const handleFile = async (e) => {
    if (e.target.files.length === 0) return

    try {
      setFileLoading(true)

      const file = e.target.files[0]
      if (file.type !== CONTENT_TYPES.APPLICATION_EXCEL) {
        showSnackbarError({
          message: 'Solo se admiten la importación de categorías en formato excel'
        })
        return
      }

      e.target.value = STRING_EMPTY

      const proxy = new EventsProxy()
      const importSuccess = await proxy.importEventCategories(event.id, file)

      if (importSuccess) {
        dispatch(loadCategories(event.id))
        onClose()
        showSnackbarSuccess('¡Categorías agregadas correctamente!')
      }

      e.target.value = ''
    } catch (e) {
      showSnackbarError(e)
    } finally {
      setFileLoading(false)
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      className={classes.dialog}
      onClose={handleOnClose}
      title='AGREGAR CATEGORÍAS'>
      <div className={classes.container}>
        <Typography color='primary' variant='h6'>
          Seleccione una de estas opciones:
        </Typography>
        <RadioGroup
          aria-label='quiz'
          name='optionValue'
          className={classes.radioContainer}
          value={optionValue}
          onChange={handleChangeOptionValue}>
          <FormControlLabel
            value={ACTION_TYPE.COPY}
            classes={{ label: classes.label }}
            control={<Radio color='primary' className={classes.radio} />}
            label='Copiar categoría de un evento creado con Sportmetric'
          />

          <FormControlLabel
            value={ACTION_TYPE.IMPORT}
            classes={{ label: classes.label }}
            control={<Radio color='primary' className={classes.radio} />}
            label='Importar categorías desde archivo (excel)'
          />

          <FormControlLabel
            value={ACTION_TYPE.CREATE}
            classes={{ label: classes.label }}
            control={<Radio color='primary' className={classes.radio} />}
            label='Crear nueva categoría'
          />
        </RadioGroup>
        <Button
          color='primary'
          variant='contained'
          disabled={!optionValue || fileLoading}
          className={classes.acceptButton}
          endIcon={fileLoading && <CircularProgress size={16} color='primary' />}
          onClick={handleOnAction}>
          Aceptar
        </Button>
        <input
          type='file'
          ref={fileInputRef}
          style={{ display: 'none' }}
          variant='outlined'
          accept={CONTENT_TYPES.APPLICATION_EXCEL}
          onChange={handleFile}
        />
      </div>
    </SectionLayoutDialog>
  )
}

export default AddCategoryDialog
