import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0),
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(7),
      marginLeft: theme.spacing(4)
    }
  },
  button: {
    height: 45,
    width: '45%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      padding: theme.spacing(0, 6)
    }
  },
  linkBodyContainer: {
    margin: theme.spacing(7, 0),
    marginBottom: theme.spacing(9)
  },
  saveUrlButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 9)
  },
  textField: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    '& input': {
      height: 2
    },
    '& fieldset': {
      height: 43,
      borderColor: theme.palette.primary.main
    }
  },
  linkForm: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  }
}))
