import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: '0 auto',
    minHeight: '62vh',
    [theme.breakpoints.up('lg')]: {
      width: '85%',
      minHeight: '70vh'
    },
    [theme.breakpoints.up('xl')]: {
      width: '80%'
    }
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexWrap: 'wrap',
      flexDirection: 'row'
    }
  },
  cardContainer: {
    margin: '0 auto',
    width: '80%',
    [theme.breakpoints.up('lg')]: {
      width: '25%',
      margin: 0,
      padding: theme.spacing(2, 0)
    }
  },
  endContainer: {
    marginTop: theme.spacing(2),
    height: 200,
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  backButton: {
    padding: 15,
    order: 1,
    alignSelf: 'center',
    margin: theme.spacing(4, 0),
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    },
    [theme.breakpoints.up('md')]: {
      order: 0
    }
  },
  containerLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100
  },
  buttonContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  }
}))
