import * as types from './types'

export const setSearchTabIndex = (tabIndex) => ({
  type: types.SET_SEARCH_TAB_INDEX,
  tabIndex
})

export const verifyUserLogged = (userLogged) => ({
  type: types.VERIFY_USER_LOGGED,
  userLogged
})

export const toggleShowInfoContact = (showInfoContact) => ({
  type: types.TOGGLE_SHOW_INFO_CONTACT,
  showInfoContact
})
export const verifyUserNotLogged = (userNotLogged) => ({
  type: types.VERIFY_USER_NOT_LOGGED,
  userNotLogged
})

export const setActiveRole = (activeRole) => ({
  type: types.SET_ACTIVE_ROLE,
  activeRole
})

export const setAdministratorAccount = (administratorAccount) => ({
  type: types.SET_ADMINISTRATOR_ACCOUNT,
  administratorAccount
})

export const verifyUserRole = (userRole) => ({
  type: types.VERIFY_USER_ROLE,
  userRole
})

export const addUserRole = (addRoleState) => ({
  type: types.ADD_USER_ROLE,
  addRoleState
})

export const tryInscriptionRegister = (inscription) => ({
  type: types.TRY_INSCRIPTION_REGISTER,
  inscription
})
