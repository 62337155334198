import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './FeatureCard.style'

const FeatureCard = ({ title, image, imagePosition, mainText, secondaryText }) => {
  const classes = useStyles()

  return (
    <div className={classes.cardContainer}>
      <Typography color='primary' variant='h3'>
        {title}
      </Typography>

      <Typography className={classes.textDescription}>
        <strong>{mainText}</strong> {secondaryText}
      </Typography>
    </div>
  )
}

export default FeatureCard
