import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { togglePublished } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './EventPublishedDialog.style'

const EventPublishedDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event, togglePublishedPending } = useSelector((state) => state.events)

  const handleEventPublished = async () => {
    const publishedSuccess = await dispatch(togglePublished(event))

    if (publishedSuccess) {
      onClose()
      showSnackbarSuccess('¡El evento fue publicado con éxito!')
    }
  }

  return (
    <SectionLayoutDialog title='Evento publicado' open={open} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant='h6' className={classes.text}>
            Estás publicando el evento <strong>{event.name}</strong>.
          </Typography>
          <Typography variant='h6' className={classes.text}>
            Esto quiere decir que a partir de este momento tu evento será visible para todos los
            usuarios de sportmetric.
          </Typography>
        </div>

        <div className={classes.actionButtonContainer}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.button}
            onClick={onClose}
            disabled={togglePublishedPending}>
            Anular publicación
          </Button>

          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            disabled={togglePublishedPending}
            endIcon={togglePublishedPending && <CircularProgress size={16} color='primary' />}
            onClick={handleEventPublished}>
            Continuar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default EventPublishedDialog
