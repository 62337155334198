import React from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'

import { TicketForm } from '../TicketForm'

import { useStyles } from './EditTicketDialog.style'

const STATE_FORM = {
  STATE_FORM_UPDATE: 'update',
  STATE_FORM_DUPLICATE: 'duplicate'
}
const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}
const EditTicketDialog = ({
  open,
  onClose,
  ticket,
  onSubmit,
  items,
  eventIsFree,
  position,
  title,
  state = STATE_FORM.STATE_FORM_UPDATE
}) => {
  const classes = useStyles()
  const { values: formState } = useFormikContext()

  const { distances, categories } = useSelector((state) => state.events)

  const activeItems =
    ticket.items?.filter(
      (i) =>
        distances.some((d) => d.id === i.inscriptionFormItem.eventDistanceId) &&
        categories.some((c) => c.id === i.inscriptionFormItem.eventCategoryId)
    ) ?? []
  const values = {
    ...ticket,
    position,
    quota: ticket.quota || '',
    saleToDate: ticket.saleTo != null ? moment(ticket.saleTo).format('YYYY-MM-DD') : ticket.saleTo,
    saleToTime: ticket.saleTo != null ? moment(ticket.saleTo).format() : ticket.saleTo,
    associatedBy: formState.associatedBy,
    eventIsFree,
    paymentUrl: ticket.paymentUrl || '',
    bearCost: ticket.mercadoPagoPrice === ticket.price,
    items: activeItems,
    distancesOrCategoriesItems:
      activeItems
        .filter(
          (x) =>
            distances.some((d) => d.id === x.inscriptionFormItem.eventDistanceId) &&
            categories.some((c) => c.id === x.inscriptionFormItem.eventCategoryId)
        )
        .reduce((result, element) => {
          if (!element.inscriptionFormItem) return result

          if (formState.associatedBy === OPTIONS.Distance.key) {
            if (result[element.inscriptionFormItem.eventDistanceId]) return result
            return {
              ...result,
              [element.inscriptionFormItem.eventDistanceId]: true
            }
          } else {
            if (result[element.inscriptionFormItem.eventCategoryId]) return result
            return {
              ...result,
              [element.inscriptionFormItem.eventCategoryId]: true
            }
          }
        }, {}) ?? {}
  }

  return (
    <SectionLayoutDialog open={open} onClose={onClose} title={title} className={classes.dialog}>
      {(activeScrolling) => (
        <TicketForm
          values={values}
          onSubmit={onSubmit}
          items={items}
          activeScrolling={activeScrolling}
          eventIsFree={eventIsFree}
          state={
            state === STATE_FORM.STATE_FORM_DUPLICATE
              ? STATE_FORM.STATE_FORM_DUPLICATE
              : STATE_FORM.STATE_FORM_UPDATE
          }
        />
      )}
    </SectionLayoutDialog>
  )
}

export default EditTicketDialog
