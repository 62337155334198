import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { PaymentLocationCard, SectionLayoutDialog } from 'shared'
import { loadPaymentLocations, updatePaymentLocation } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './EditPaymentLocationDialog.style'

const CARD_FORM = 'cardForm'
const FORM_UPDATE = 'formUpdate'

const EditPaymentLocationDialog = ({ open, onClose, paymentLocation, title }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event } = useSelector((state) => state.events)
  const { values, setFieldValue } = useFormikContext()

  const handleSave = async (values) => {
    const successUpdate = await dispatch(updatePaymentLocation(event.id, values))

    if (successUpdate) {
      dispatch(loadPaymentLocations(event.id))
      showSnackbarSuccess('¡Se actualizó con éxito el lugar de pago!')
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='EDITAR PUNTO FISICO'
      className={classes.dialog}
      contentClassName={classes.container}>
      <PaymentLocationCard
        paymentLocation={paymentLocation}
        cardState={CARD_FORM}
        title={title}
        {...{ values, setFieldValue }}
        formType={FORM_UPDATE}
        onSubmit={handleSave}
      />
    </SectionLayoutDialog>
  )
}

export default EditPaymentLocationDialog
