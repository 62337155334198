import { makeStyles, TextField, withStyles } from '@material-ui/core'

export const QueryTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.primary.contrastText
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.contrastText
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: `2px solid ${theme.palette.primary.contrastText}`,
        borderRadius: 10
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.contrastText
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.contrastText
      }
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.primary.contrastText,
      fontWeight: 600
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#f44336',
      fontWeight: 600
    }
  }
}))(TextField)

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(3)
  },
  formGroup: {
    [theme.breakpoints.up('lg')]: {
      width: 450,
      alignSelf: 'flex-end'
    }
  },
  sendButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    alignSelf: 'flex-end',
    borderRadius: 10,
    width: 150,
    '&:hover': {
      backgroundColor: theme.palette.primary.contrastText
    },
    '& span': {
      fontSize: '.875rem',
      fontWeight: 600
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
      marginTop: theme.spacing(10),
      '& span': {
        fontSize: '1.6875rem'
      }
    }
  },
  textField: {
    appearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },

    '& input': {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      marginBottom: theme.spacing(),
      '&::placeholder': {
        color: theme.palette.primary.contrastText
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem'
      }
    },

    '& > div': {
      height: 48,
      [theme.breakpoints.up('md')]: {
        height: 85
      }
    },
    '& label': {
      color: theme.palette.primary.contrastText,
      transform: 'translate(14px,15px) scale(1)',
      fontSize: '.75rem',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        transform: 'translate(14px,30px) scale(1)',
        fontSize: '1.5rem'
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.contrastText,
      height: 48,

      [theme.breakpoints.up('md')]: {
        height: 85
      }
    }
  },
  nameTextField: {
    '& fieldset > legend > span': {
      width: 52,
      [theme.breakpoints.up('md')]: {
        width: 95
      }
    }
  },
  organizationTextField: {
    '& fieldset > legend > span': {
      width: 72,
      [theme.breakpoints.up('md')]: {
        width: 140
      }
    }
  },
  eMailTextField: {
    '& fieldset > legend > span': {
      width: 40,
      [theme.breakpoints.up('md')]: {
        width: 70
      }
    }
  },
  textArea: {
    '& label': {
      color: theme.palette.primary.contrastText,
      transform: 'translate(14px,16px) scale(1)',
      fontSize: '.75rem',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        transform: 'translate(14px,30px) scale(1)',
        fontSize: '1.5rem'
      }
    },
    '& > div': {
      minHeight: 48,
      padding: '14px',
      [theme.breakpoints.up('md')]: {
        minHeight: 85,
        padding: '29.5px 14px'
      }
    },
    '& textarea': {
      lineHeight: 'normal',
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      '&::placeholder': {
        color: theme.palette.primary.contrastText
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 24
      }
    }
  },
  queryTextArea: {
    '& fieldset > legend > span': {
      width: 55,
      [theme.breakpoints.up('md')]: {
        width: 105
      }
    }
  }
}))
