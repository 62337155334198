import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Button, Popover, Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { setActiveRole } from 'state/modules/app'
import { loadUserData, signOut } from 'state/modules/auth'
import { USER_ROLE } from 'utils/constants'

import { UserRoleSection } from '../UserRoleSection'

import { useStyles } from './UserMenu.style'

const UserMenu = ({ popupEl, onClose, userImage, roleColor }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { userData, user } = useSelector((state) => state.auth)
  const { activeRole } = useSelector((state) => state.app)
  const { organizationsAdminAll, organizationsAdminAllPending, userAccount, settingImage } =
    useSelector((state) => state.userAccounts)

  useEffect(() => {
    if (user) {
      dispatch(loadUserData(user.id))
    }
  }, [user, dispatch, userAccount, settingImage])

  useEffect(() => {
    if (userData && !activeRole) {
      const userRole =
        (userData[`is${USER_ROLE.ATHLETE.value}`] && USER_ROLE.ATHLETE) ||
        (userData[`is${USER_ROLE.ORGANIZER.value}`] && USER_ROLE.ORGANIZER) ||
        (userData[`is${USER_ROLE.TIMEKEEPER.value}`] && USER_ROLE.TIMEKEEPER)

      dispatch(setActiveRole(userRole))
    }
  }, [userData])

  const handleSignOut = (e) => {
    e.preventDefault()

    dispatch(setActiveRole(null))
    dispatch(signOut())

    onClose()

    history.push(ROUTES.ROOT)
  }

  return (
    <Popover
      elevation={0}
      PaperProps={{
        className: clsx(classes.userPopup, !isDesktop && classes.userPopupMini)
      }}
      open={!!popupEl}
      anchorEl={popupEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      onClose={onClose}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <div className={clsx(classes.userOptionsContainer, roleColor?.value)}>
        <div className={classes.userProfileContainer}>
          <div className={classes.profile}>
            <div
              style={{ backgroundImage: `url(${userImage})` }}
              className={classes.profileImage}
            />
            <div>
              <Typography
                color='primary'
                className={classes.alias}
                title={`@${userData?.username || userData?.id}`}>
                @{userData?.username || userData?.id}
              </Typography>
              {activeRole && (
                <Typography className={classes.rol} color='primary'>
                  Rol {activeRole.label}
                </Typography>
              )}
            </div>
          </div>
          <Button
            to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${user.id}`}
            component={Link}
            onClick={onClose}
            color={roleColor?.value}
            variant='contained'
            className={clsx(classes.myProfileButton, roleColor?.value)}>
            Mi perfil
          </Button>

          {activeRole === USER_ROLE.ORGANIZER &&
            !organizationsAdminAllPending &&
            !organizationsAdminAll.some((x) => x.organization.createdById === user.id) && (
              <Button
                color='secondary'
                variant='contained'
                component={Link}
                onClick={onClose}
                to={ROUTES.ORGANIZATIONS.CREATE}
                className={classes.createButton}>
                Crear Organización
              </Button>
            )}
        </div>

        <UserRoleSection userData={userData ?? {}} roleColor={roleColor} onMenuClose={onClose} />

        <div className={classes.signOutContainer}>
          <Link className={classes.signOutLink} onClick={handleSignOut} to='#'>
            Cerrar sesión
          </Link>
        </div>
      </div>
    </Popover>
  )
}

export default UserMenu
