import React, { useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import { Error as ErrorIcon } from '@material-ui/icons'
import { Formik, useFormikContext } from 'formik'
import { CustomizedTooltip, SectionLayoutDialog } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'

import { WarningDialog } from './components'
import { useStyles } from './SettingMercadoPagoDialog.style'

const PRICE_FORMAT = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '$ '
}

const STATE_FORM = {
  Setting: 'setting',
  Simulate: 'simulate'
}

const FILTER_PERCENTAGES = {
  MercadoPago: 'MercadoPago',
  Sportmetric: 'Sportmetric'
}

const getFeeAmount = (total, percentageWithoutVat) => {
  const result = ((total * calculatePercentage(percentageWithoutVat)) / 100).toFixed(2)
  return result
}

const calculatePercentage = (percentage) => {
  const result = ((percentage * 21) / 100 + percentage).toFixed(4)
  return Number(result)
}

const SettingMercadoPagoDialog = ({ open, onClose, state = 'setting', values }) => {
  const classes = useStyles()
  const { setValues } = useFormikContext()

  const { ticketTypeFees } = useSelector((state) => state.events)

  const mercadoPagoFeePercentages = ticketTypeFees
    .filter((e) => e.feeType === FILTER_PERCENTAGES.MercadoPago)
    .map((ticketTypeFee) => {
      return {
        value: ticketTypeFee.feePercentage.toString(),
        label: ticketTypeFee.name
      }
    })

  const observeOptions = mercadoPagoFeePercentages.map((e) => {
    const percentage = calculatePercentage(Number(e.value))
    return {
      value: percentage.toFixed(2),
      label: e.label,
      valueWithoutVat: e.value
    }
  })

  const SPORTMETRIC_FEE_PERCENTAGE_WITHOUT_IVA = ticketTypeFees
    .filter((e) => e.feeType === FILTER_PERCENTAGES.Sportmetric)
    .reduce((acc, e) => {
      return acc + e.feePercentage
    }, 0)

  const SPORTMETRIC_FEE_PERCENTAGE_WITH_IVA = calculatePercentage(
    SPORTMETRIC_FEE_PERCENTAGE_WITHOUT_IVA
  )

  const getSelectLabel = (values) => {
    const result = mercadoPagoFeePercentages.find(
      (element) => element.value === values.mercadoPagoFee?.toString()
    )
    if (result) {
      return result.label
    } else {
      return ''
    }
  }

  const calculateFinalCost = (mercadoPagoFeePercentage) => {
    const result = (
      -values.price /
      (SPORTMETRIC_FEE_PERCENTAGE_WITH_IVA / 100 + mercadoPagoFeePercentage / 100 - 1)
    ).toFixed(2)
    return Number(result)
  }

  const finalPrice = values.bearCost
    ? values.price
    : calculateFinalCost(calculatePercentage(values.mercadoPagoFee ?? 0))

  const initialValues = {
    ...values,
    totalCost: finalPrice,
    sportmetricFeePercentage: SPORTMETRIC_FEE_PERCENTAGE_WITHOUT_IVA,
    sportmetricFeeAmount: getFeeAmount(
      values.bearCost ? values.price : finalPrice,
      SPORTMETRIC_FEE_PERCENTAGE_WITHOUT_IVA
    ),
    mercadoPagoFeeAmount: getFeeAmount(
      values.bearCost ? values.price : finalPrice,
      values.mercadoPagoFee
    ),
    selectValue: {
      value: calculatePercentage(values.mercadoPagoFee).toFixed(2) ?? '',
      label: getSelectLabel(values)
    },
    totalForOrganizer: values.bearCost
      ? values.price -
        getFeeAmount(finalPrice, Number(values.mercadoPagoFee)) -
        getFeeAmount(finalPrice, SPORTMETRIC_FEE_PERCENTAGE_WITHOUT_IVA)
      : values.price ?? 0
  }

  const [openWarningDialog, setOpenWarningDialog] = useState(false)

  const handleChangeValues = (option, setValues) => {
    setValues((values) => {
      const totalCost = values.bearCost
        ? values.price
        : calculateFinalCost(calculatePercentage(Number(option.valueWithoutVat)))

      const totalForOrganizer = values.bearCost
        ? values.price -
          getFeeAmount(totalCost, Number(option.valueWithoutVat)) -
          values.sportmetricFeeAmount
        : values.price

      return {
        ...values,
        selectValue: option,
        mercadoPagoFee: Number(option.valueWithoutVat),
        mercadoPagoFeeAmount: getFeeAmount(totalCost, Number(option.valueWithoutVat)),
        sportmetricFeeAmount: getFeeAmount(totalCost, values.sportmetricFeePercentage),
        totalCost,
        mercadoPagoPrice: totalCost,
        totalForOrganizer
      }
    })
  }

  const handleSaveMercadoPagoPrice = ({
    mercadoPagoPrice,
    mercadoPagoFee,
    sportmetricFeeAmount,
    sportmetricFeePercentage
  }) => {
    calculateFinalCost()
    setValues((values) => ({
      ...values,
      mercadoPagoPrice: Number(mercadoPagoPrice),
      mercadoPagoFee: Number(mercadoPagoFee),
      sportmetricFeeAmount: Number(sportmetricFeeAmount),
      sportmetricFeePercentage: Number(sportmetricFeePercentage)
    }))
    onClose()
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title={
        state === STATE_FORM.Setting
          ? 'CONFIGURAR EL VALOR DEL TICKET CON MP'
          : 'SIMULAR COSTOS CON MERCADO PAGO'
      }
      className={classes.dialog}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSaveMercadoPagoPrice}
        enableReinitialize>
        {({ values, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.containerText}>
              <Typography className={classes.text}>Precio de inscripción</Typography>
              <Typography className={classes.textPrice}>
                {numericFormatter(values.price?.toString() ?? '0', PRICE_FORMAT)}
              </Typography>
            </div>
            <div className={classes.containerTextGreySportmetricCommission}>
              <Typography className={classes.textSportmetricCommissionLeft}>
                Comisión Sportmetric
              </Typography>
              <div className={classes.textSportmetricCommissionRight}>
                <Typography className={classes.textSportmetricPercentage}>
                  {SPORTMETRIC_FEE_PERCENTAGE_WITH_IVA.toFixed(2).replace('.', ',')}%
                </Typography>
                <Typography className={classes.text}>
                  {numericFormatter(values.sportmetricFeeAmount?.toString() ?? '0', PRICE_FORMAT)}
                </Typography>
              </div>
            </div>
            <div className={classes.containerText}>
              <Typography className={classes.text}>Comisión Mercado Pago</Typography>
              <div className={classes.containerSettingMP}>
                <div className={classes.containerTooltip}>
                  {values.mercadoPagoFee === 0 && (
                    <CustomizedTooltip
                      title='Debe seleccionar una opción para conocer el costo'
                      className={classes.switchTooltip}
                      popperClassName={classes.tooltipPopper}
                      position='right-end'>
                      {({ handleTooltip, handleTooltipClose }) => (
                        <ErrorIcon
                          onMouseOut={handleTooltipClose}
                          onMouseOver={handleTooltip}
                          className={classes.IconInfo}
                        />
                      )}
                    </CustomizedTooltip>
                  )}
                </div>
                <div className={classes.containerSelect}>
                  <div>
                    <Typography className={classes.Subtext}>Dinero disponible</Typography>
                    <StyledSelect
                      className={classes.select}
                      defaultOptions={observeOptions}
                      value={values.selectValue}
                      colorOptions='primary'
                      onChange={(option) => {
                        handleChangeValues(option, setValues)
                      }}
                      placeholder=''
                    />
                  </div>
                  <div>
                    <Typography className={classes.Subtext}>Porcentaje</Typography>
                    <Typography>
                      {values.selectValue.value === '0.00'
                        ? ''
                        : `${values.selectValue.value?.replace('.', ',')}%`}
                    </Typography>
                  </div>
                </div>
              </div>
              <Typography className={classes.text}>
                {numericFormatter(values.mercadoPagoFeeAmount?.toString() ?? '0', PRICE_FORMAT)}
              </Typography>
            </div>
            <div className={classes.containerTextGrey}>
              <Typography className={classes.text}>Costo total para el deportista</Typography>

              <Typography className={classes.text}>
                {numericFormatter(values.totalCost?.toString() ?? '0', PRICE_FORMAT)}
              </Typography>
            </div>

            <div className={classes.containerText}>
              <Typography className={classes.text}>Tu ingreso como organizador</Typography>
              <Typography className={classes.textTotalForOrganizer}>
                {numericFormatter(values.totalForOrganizer?.toString() ?? '0', PRICE_FORMAT)}
              </Typography>
            </div>
            <Typography className={classes.textInformation}>
              Los precios son finales e incluyen IVA, no incluye retenciones que pueden aplicarse
              según tu condición fiscal.
            </Typography>
            <div className={classes.containerButton}>
              <Button
                className={classes.button}
                color='primary'
                variant='contained'
                disabled={!values.mercadoPagoFee}
                onClick={() => setOpenWarningDialog(true)}>
                Aceptar
              </Button>
              <WarningDialog
                open={openWarningDialog}
                onClose={() => setOpenWarningDialog(false)}
                onSubmit={handleSubmit}
              />
            </div>
          </form>
        )}
      </Formik>
    </SectionLayoutDialog>
  )
}

export default SettingMercadoPagoDialog
