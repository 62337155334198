import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import {
  AccessTime as AccessTimeIcon,
  Close as CloseIcon,
  Info as InfoIcon
} from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { ROUTES } from 'routes'
import { CustomizedTooltip, DeleteConfirmDialog, FormNotifyDialog, NumericField } from 'shared'
import {
  createInscriptionForms,
  deleteInscriptionForms,
  loadActiveInscriptionForm,
  resetInscriptionForms,
  resetValue,
  updateInscriptionForms
} from 'state/modules/events'
import { MAX_DATE, MIN_DATE } from 'utils/constants'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import {
  CustomFieldCard,
  ReUseFormDialog,
  SectionBlock,
  SectionCategoryAndDistance,
  SectionCustomField
} from '../../components'
import { DiscountCodeSection } from '../DiscountCodeSection'
import { InscriptionPriceSection } from '../InscriptionPriceSection'
import { PaymentMethodsSection } from '../PaymentMethodsSection'
import { PaymentNotifyDialog } from '../PaymentNotifyDialog'
import { ViewPreviewDialog } from '../ViewPreviewDialog'

import { useStyles } from './SettingForm.style'

const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}

const formActionType = {
  created: 'created',
  updated: 'updated'
}

const DIALOG_STATES = {
  MERCADO_PAGO_CONFIRM_DIALOG: 'mercadoPagoConfirmDialog',
  MERCADO_PAGO_INFO_DIALOG: 'mercadoPagoInfoDialog'
}

const mercadoPagoNotifyInitalState = {
  open: false,
  state: DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG,
  onConfirm: () => {}
}

const SettingForm = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    event,
    activeInscriptionForm,
    distances,
    categories,
    createInscriptionFormsError,
    createInscriptionFormsPending,
    updateInscriptionFormsPending,
    updateInscriptionFormsError,
    deleteInscriptionFormsError,
    deleteInscriptionFormsPending,
    paymentLocations,
    eventBankAccounts
  } = useSelector((state) => state.events)

  const [openDeleteFormDialog, setOpenDeleteFormDialog] = useState(false)
  const [createOrUpdateDraftFormPending, setCreateOrUpdateDraftFormPending] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
  const [openNotifyErrorsDialog, setOpenNotifyErrorsDialog] = useState(false)
  const [openFormDraftNotifyErrorsDialog, setopenFormDraftNotifyErrorsDialog] = useState(false)
  const [mercadoPagoNotifyProps, setMercadoPagoNotifyProps] = useState(mercadoPagoNotifyInitalState)
  const [formErrorList, setFormErrorList] = useState([])
  const [submitCount, setSubmitCount] = useState(0)

  const MAX_LENGTH_WELCOME_MESSAGE = 600
  const TIME_FORMAT_EXPRESSION = /^([0-9]+):([0-5]\d)$/
  const hoursPattern = /^\d+$/
  const MAX_HOURS = 24

  const distancesValidate = (items) =>
    !!distances.length &&
    !distances.every((x) =>
      items
        .filter((v) => categories.some((c) => c.id === v.eventCategoryId))
        .some((r) => r.eventDistanceId === x.id)
    )

  const errorDictionary = useMemo(
    () => ({
      welcomeMessage: { label: 'Mensaje inicial', order: 1 },
      openFromDate: { label: 'Fecha de inscripción', order: 2 },
      openFromTime: { label: 'Fecha de inscripción', order: 2 },
      openToDate: { label: 'Fecha de inscripción', order: 2 },
      openToTime: { label: 'Fecha de inscripción', order: 2 },
      quota: { label: 'Cupo general del evento', order: 3 },
      validateAgeFrom: { label: 'Edad del competidor', order: 4 },
      categories: { label: 'Categorías', order: 5 },
      distances: { label: 'Distancias', order: 6 },
      items: { label: 'Distancias', order: 6, callbackValidate: distancesValidate },
      paymentMethod: { label: 'Medios de pago', order: 7 },
      paymentConfirmationPeriod: { label: 'Tiempo para realizar pago', order: 8 },
      paymentConfirmationPeriodEnabled: { label: 'Tiempo para realizar pago', order: 8 },
      ticketTypes: {
        label: event.isFree ? 'Tickets de inscripción' : 'Precios de inscripción',
        order: 9
      },
      welcomeText: { label: 'Texto de bienvenida', order: 10 },
      showInscriptionsFrom: { label: 'Mostrar inscriptos', order: 11 }
    }),
    [event, distances]
  )

  useEffect(() => {
    if (event.activeInscriptionFormId) {
      dispatch(loadActiveInscriptionForm(event))
    }
  }, [event, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetInscriptionForms())
    }
  }, [])

  useEffect(() => {
    if (createInscriptionFormsError) {
      showSnackbarError(createInscriptionFormsError)
    }
  }, [createInscriptionFormsError])

  useEffect(() => {
    if (updateInscriptionFormsError) {
      showSnackbarError(updateInscriptionFormsError)
    }
  }, [updateInscriptionFormsError])

  useEffect(() => {
    if (deleteInscriptionFormsError) {
      showSnackbarError(deleteInscriptionFormsError)
    }
  }, [deleteInscriptionFormsError])

  const timeFormat = (time) => {
    if (!time) return null

    const { 0: hours, 1: minutes } = time.split(':')

    if (hoursPattern.test(hours)) return `${hours}:${minutes}`

    const numberHours = Number(hours)
    const days = Math.floor(numberHours)
    const restHours = numberHours % 1
    const totalHours = days * 24 + Math.round(restHours * 100)

    return `${totalHours}:${minutes}`
  }

  const values = useMemo(
    () => ({
      openFromDate: null,
      openFromTime: null,
      openToDate: null,
      openToTime: null,
      quota: '',
      welcomeText: '',
      welcomeMessage: '',
      isDraft: false,
      customFields: [],
      showInscriptionsFrom: 1,
      checkedShowInscriptionsFrom: false,
      validateAge: true,
      validateGender: true,
      validateAgeFrom: moment(event.from).format('YYYY-MM-DD'),
      mercadoPagoCode: event.mercadoPagoCode ?? '',
      mercadoPagoEnabled: event.mercadoPagoEnabled || false,
      validationDate: true,
      allowCategoriesInMultipleDistances: false,
      allowUnregisteredUsers: true,
      items: [],
      paymentConfirmationPeriod: '',
      paymentConfirmationPeriodEnabled: false,
      isFree: event.isFree,
      associatedBy: OPTIONS.Distance.key,
      ticketTypes: [],
      inscriptionsCount: 0,
      paymentUrlEnabled: false,
      ...(activeInscriptionForm
        ? {
            ...activeInscriptionForm,

            ...(activeInscriptionForm.openFrom && {
              openFromDate: moment(activeInscriptionForm.openFrom).format('YYYY-MM-DD'),
              openFromTime: moment(activeInscriptionForm.openFrom).format()
            }),
            ...(activeInscriptionForm.openTo && {
              openToDate: moment(activeInscriptionForm.openTo).format('YYYY-MM-DD'),
              openToTime: moment(activeInscriptionForm.openTo).format()
            }),

            checkedShowInscriptionsFrom: !activeInscriptionForm.showInscriptionsFrom,
            showInscriptionsFrom: activeInscriptionForm.showInscriptionsFrom || '',

            ...(activeInscriptionForm.items.length && {
              validateGender: activeInscriptionForm.items[0].validateGender,
              validateAge: activeInscriptionForm.items[0].validateAge
            }),

            validationDate: moment(moment(event.from).format('YYYY-MM-DD')).isSame(
              moment(activeInscriptionForm.validateAgeFrom)
            ),
            quota: activeInscriptionForm.quota || '',
            welcomeMessage: activeInscriptionForm.welcomeMessage ?? '',
            paymentConfirmationPeriod:
              timeFormat(activeInscriptionForm.paymentConfirmationPeriod)?.split(':')[0] ?? '',
            paymentConfirmationPeriodEnabled: !!activeInscriptionForm.paymentConfirmationPeriod,
            associatedBy: activeInscriptionForm.associatedBy || OPTIONS.Distance.key,
            ticketTypes: activeInscriptionForm.ticketTypes.map((x) => ({
              ...x,
              mercadoPagoEnabled: !!event.mercadoPagoCode && x.mercadoPagoEnabled,
              paymentLocationsEnabled:
                event.eventPaymentLocationsEnabled && x.paymentLocationsEnabled,
              bankAccountsEnabled: event.eventBankAccountsEnabled && x.bankAccountsEnabled
            })),
            paymentUrlEnabled: activeInscriptionForm.ticketTypes.some((x) => !!x.paymentUrl),
            isFree: event.isFree
          }
        : {})
    }),
    [activeInscriptionForm, event]
  )

  const validationSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          openFromDate: Yup.mixed()
            .nullable()
            .required('Debe ingresar una fecha de inicio de inscripción')
            .test('valid-date', 'Formato de fecha invalida', (value) => moment(value).isValid())
            .test('range-date', 'Fecha inválida', (value) =>
              moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
            )
            .when(['openToDate'], {
              is: (openToDate) => !!openToDate && moment(openToDate).isValid(),
              then: (schema) =>
                schema.test(
                  'from-max',
                  'La fecha de inicio debe ser menor a la fecha de cierre',
                  (value, { parent }) =>
                    moment(`${moment(value).format('YYYY-MM-DD')}`).isBefore(
                      moment(`${moment(parent.openToDate).format('YYYY-MM-DD')}`)
                    )
                )
            }),
          openToDate: Yup.mixed()
            .nullable()
            .required('Debe ingresar una fecha de fin de inscripción')
            .test('valid-date', 'Formato de fecha invalida', (value) => moment(value).isValid())
            .test('range-date', 'Fecha inválida', (value) =>
              moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
            )
            .test(
              'openTo',
              'La fecha de cierre de inscripción debe ser menor o igual a la fecha del evento',
              (value) => moment(value).isSameOrBefore(moment(event.to))
            )
            .when(['openFromDate'], {
              is: (openFromDate) => !!openFromDate && moment(openFromDate).isValid(),
              then: (schema) =>
                schema.test(
                  'to-min',
                  'La fecha de cierre debe ser mayor a la fecha de inicio',

                  (value, { parent }) =>
                    moment(`${moment(value).format('YYYY-MM-DD')}`).isAfter(
                      moment(`${moment(parent.openFromDate).format('YYYY-MM-DD')}`)
                    )
                )
            }),
          welcomeText: Yup.string()
            .max(4000, 'El mensaje de bienvenida no puede superar los 4000 caracteres')
            .trim()
            .required('Debe ingresar un mensaje de bienvenida para los participantes del evento'),
          welcomeMessage: Yup.string()
            .max(600, 'El mensaje inicial no debe ser mayor a 600 caracteres')
            .trim(),
          distances: Yup.mixed().test(
            'test-required-distances',
            'Debe tener distancias cargadas',
            () => !!distances.length
          ),
          categories: Yup.mixed().test(
            'test-required-distances',
            'Debe tener categorías cargadas',
            () => !!categories.length
          ),
          showInscriptionsFrom: Yup.number().when('checkedShowInscriptionsFrom', {
            is: (checkedShowInscriptionsFrom) => !checkedShowInscriptionsFrom,
            then: (schema) =>
              schema
                .typeError('Ingrese un número válido')
                .test('test-required', 'Debe ingresar un número entero', (value) =>
                  Number.isInteger(value)
                )
                .test(
                  'test-negative',
                  'No esta permitido el ingreso de números negativos ni el cero',
                  (value) => value > 0
                )
                .max(999999999, 'Se puede ingresar hasta 9 dígitos')
          }),
          validateAgeFrom: Yup.mixed()
            .nullable()
            .required('Debe ingresar una fecha para la validar la edad')
            .test('valid-date', 'Formato de fecha invalida', (value) => moment(value).isValid())
            .test('test-range-date', 'La fecha ingresada se encuentra fuera de rango', (value) =>
              moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
            ),
          items: Yup.mixed()
            .nullable()
            .test(
              'test-distances',
              'Por lo menos debe haber una distancia/categoría vinculada',
              (value = []) => !!value?.length
            )
            .test(
              'test-distances-available',
              'Las distancias deben estar vinculadas al menos a una categoría',
              (value = []) =>
                distances.every((x) =>
                  value
                    .filter((v) => categories.some((c) => c.id === v.eventCategoryId))
                    .some((r) => r.eventDistanceId === x.id)
                )
            ),
          quota: Yup.number()
            .typeError('Ingrese un número válido')
            .test(
              'test-negative',
              'La cantidad de cupos tiene que ser un número entero positivo. Dejarlo en blanco o cero (Sin límite)',
              (value = 0) => value >= 0
            )
            .test(
              'test-min-quota',
              'El cupo del evento debe ser mayor a la sumatoria de los cupos por distancias',
              (value = 0) => {
                const totalQuotaDistance = distances.reduce((acc, d) => acc + d.quota, 0)
                return !value || value >= totalQuotaDistance
              }
            )
            .integer('Ingrese un número entero')
            .max(99999999, 'Se puede ingresar hasta 8 dígitos'),
          paymentConfirmationPeriod: Yup.string()
            .nullable()
            .when('paymentConfirmationPeriodEnabled', {
              is: (paymentConfirmationPeriodEnabled) => paymentConfirmationPeriodEnabled,
              then: (schema) =>
                schema
                  .required('Debe ingresar el período de tiempo')
                  .matches(hoursPattern, 'No es un formato de tiempo válido')
                  .test(
                    'test-max-time',
                    'El tiempo máximo es de 72 horas',
                    (value = 0) => +value <= 72
                  )
                  .test(
                    'test-min-time',
                    'El tiempo mínimo es de 2 horas',
                    (value = 0) => +value >= 2
                  )
            }),
          paymentConfirmationPeriodEnabled: Yup.boolean().test(
            'test-required',
            'Debe ingresar el tiempo',
            (value, { parent }) => !value || !!parent.paymentConfirmationPeriod
          ),
          ticketTypes: Yup.array()
            .min(1, 'Debe crear al menos 1 ticket')
            .test('test-items', 'Debe tener almenos un categoría/distancia', (ticketTypes = []) =>
              ticketTypes.every(
                (x) =>
                  !!x.items.filter(
                    (i) =>
                      distances.some((d) => d.id === i.inscriptionFormItem.eventDistanceId) &&
                      categories.some((c) => c.id === i.inscriptionFormItem.eventCategoryId)
                  ).length
              )
            )
            .test('test-active-tickets', 'Debe crear al menos 1 ticket', (ticketTypes = []) =>
              ticketTypes.some((x) => x.isActive)
            ),
          paymentMethod: Yup.boolean().when('isFree', {
            is: (isFree) => !isFree,
            then: (schema) =>
              schema.test(
                'validate-method-payment',
                'Debe configurar al menos 1 medio de pago',
                (value, { parent }) =>
                  parent.mercadoPagoCode ||
                  parent.mercadoPagoEnabled ||
                  eventBankAccounts.length ||
                  paymentLocations.length ||
                  parent.paymentUrlEnabled
              )
          })
        },
        ['openFromDate', 'openToDate']
      ),
    [event, distances, categories, eventBankAccounts, paymentLocations]
  )

  const onSubmit =
    (actionSuccess, actionType) =>
    async (
      {
        openFromDate,
        openFromTime,
        openToDate,
        openToTime,
        validateAgeFrom,
        validateAge,
        validateGender,
        items,
        customFields,
        checkedShowInscriptionsFrom,
        showInscriptionsFrom,
        quota,
        welcomeMessage,
        isDraft,
        copyForm,
        paymentConfirmationPeriod,
        validateDate,
        ticketTypes,
        ...restProps
      },
      e
    ) => {
      const formInscription = {
        ...restProps,
        eventId: event.id,
        customFields: customFields.map((field) => ({
          ...field,
          eventCustomFieldId: field.eventCustomField.id
        })),
        paymentConfirmationPeriod: dateTimeFormat(`${paymentConfirmationPeriod}:00`),
        openFrom:
          moment(openFromDate).isValid() && moment(openFromTime).isValid()
            ? moment(
                `${moment(openFromDate).format('YYYY-MM-DD')} ${moment(openFromTime).format(
                  'HH:mm'
                )}`
              ).format()
            : null,
        openTo:
          moment(openToDate).isValid() && moment(openToTime).isValid()
            ? moment(
                `${moment(openToDate).format('YYYY-MM-DD')} ${moment(openToTime).format('HH:mm')}`
              ).format()
            : null,
        validateAgeFrom: moment(validateAgeFrom).isValid()
          ? moment(validateAgeFrom).format('YYYY-MM-DD')
          : null,
        items: items
          .filter((x) => distances.some((d) => d.id === x.eventDistance.id))
          .filter((x) => categories.some((c) => c.id === x.eventCategory.id))
          .map((item) => ({
            ...item,
            validateAge,
            validateGender
          })),

        ticketTypes: ticketTypes.map((x) => ({
          ...x,
          items: x.items.filter(
            (i) =>
              distances.some((d) => d.id === i.inscriptionFormItem.eventDistanceId) &&
              categories.some((c) => c.id === i.inscriptionFormItem.eventCategoryId)
          )
        })),
        showInscriptionsFrom: checkedShowInscriptionsFrom ? 0 : Number(showInscriptionsFrom),
        quota: Number(quota),
        welcomeMessage: welcomeMessage.trim(),
        isDraft
      }

      if (!activeInscriptionForm) {
        const createSuccess = await dispatch(createInscriptionForms(event, formInscription))

        if (createSuccess) return actionSuccess(actionType, isDraft)
      } else {
        const updateSuccess = await dispatch(updateInscriptionForms(event, formInscription))

        if (updateSuccess) return actionSuccess(actionType, isDraft)
      }
    }

  const mercadoPagoValidate = (handleSubmit, values, setFieldValue) => {
    if (values.isFree || event.mercadoPagoEnabled) return true

    const hasMercadoPagoTicket = values.ticketTypes.some((x) => x.mercadoPagoEnabled)
    if (!hasMercadoPagoTicket) return true

    const hasActivePaymentLocation = paymentLocations.some((x) => x.isActive)
    const hasActiveBankAccount = eventBankAccounts.some((x) => x.isActive)

    const hasMercadoPagoTickets = values.ticketTypes.some(
      (x) =>
        (hasActivePaymentLocation && x.paymentLocationsEnabled) ||
        (hasActiveBankAccount && x.bankAccountsEnabled) ||
        !!x.paymentUrl
    )

    setMercadoPagoNotifyProps({
      open: true,
      state: !hasMercadoPagoTickets
        ? DIALOG_STATES.MERCADO_PAGO_INFO_DIALOG
        : DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG,
      onConfirm: async () => {
        await handleSubmit({ ...values, isDraft: false })
        setFieldValue('isDraft', false)
        setMercadoPagoNotifyProps(mercadoPagoNotifyInitalState)
      },
      title:
        !!activeInscriptionForm && !activeInscriptionForm.isDraft ? 'ADVERTENCIA' : 'NOTIFICACIÓN',
      items: values.ticketTypes.filter(
        (x) =>
          x.mercadoPagoEnabled &&
          !x.paymentLocationsEnabled &&
          !x.bankAccountsEnabled &&
          !x.paymentUrl
      )
    })
  }

  const formValidate = (handleSubmit, isValid, errors, values, setFieldValue) => async (e) => {
    if (isValid) {
      const mercadoPagoValid = mercadoPagoValidate(handleSubmit, values, setFieldValue)
      if (!mercadoPagoValid) return

      setFieldValue('isDraft', false)
      await handleSubmit({ ...values, isDraft: false })
    } else {
      setFormErrorList(
        getErrorsList(errors).filter(
          (x, index, array) => array.findIndex((obj) => obj?.order === x?.order) === index
        )
      )
      setOpenNotifyErrorsDialog(true)
    }
  }

  const dateTimeFormat = (time) => {
    if (!time || !time.match(TIME_FORMAT_EXPRESSION)) return null

    const { 0: hours, 1: minutes } = time.split(':')
    const numberHours = Number(hours)

    if (numberHours < MAX_HOURS) return `${time}:00`

    const days = parseInt(numberHours / 24)
    const restHours = numberHours % 24

    return `${days}.${restHours}:${minutes}:00`
  }

  const filterErrorOption = (error, values) => {
    if (error.callbackValidate) return error.callbackValidate(values[error.key])

    return Array.isArray(values[error.key]) ? values[error.key].some(Boolean) : !!values[error.key]
  }

  const draftFormValidate =
    (handleSubmit, errors, values, setFieldValue, action, setErrors) => async () => {
      const dataInvalidErrors = getErrorsList(errors)
        .filter((x) => filterErrorOption(x, values))
        .filter((x, index, array) => array.findIndex((obj) => obj?.order === x?.order) === index)

      if (!dataInvalidErrors.length) {
        setCreateOrUpdateDraftFormPending(true)
        setFieldValue('isDraft', true)
        const actionType = !activeInscriptionForm ? formActionType.created : formActionType.updated
        await handleSubmit(action, actionType)({ ...values, isDraft: true })

        setErrors(errors)
        setCreateOrUpdateDraftFormPending(false)
      } else {
        setFormErrorList(dataInvalidErrors)
        setopenFormDraftNotifyErrorsDialog(true)
      }
    }

  const actionSuccessForm = (action, isFormDraft) => {
    if (isFormDraft) {
      showSnackbarSuccess('Se guardo la configuración como borrador correctamente')
    } else {
      if (action === formActionType.created) {
        history.push(`${ROUTES.EVENTS.PROFILE}/${event.slugUrl}?edit=true`)
        showSnackbarSuccess('Configuración de inscripción creada correctamente')
      }

      if (action === formActionType.updated) {
        showSnackbarSuccess('Configuración de inscripción actualizada correctamente')
      }
    }

    setSubmitCount(submitCount + 1)
    return true
  }

  const handleRemoveOption = (state, setFieldValue, id, field) => {
    const filterOptions = state[field]
      .filter((item) => item.eventCustomFieldId !== id)
      .map((x, index) => ({ ...x, itemNumber: index }))
    setFieldValue(field, filterOptions)
  }

  const onDateTimeChanged = (setValues, field, timeField, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,
      [field]: value ? value.format() : null,
      [timeField]:
        !values[timeField] && value && moment(value).isValid() ? defaultTime : values[timeField]
    }))

  const handleChangeTime = (setFieldValue, field) => (date) =>
    setFieldValue(field, date ? moment(date).format() : null)

  const handleChangeDate = (setFieldValue, field) => (date) => setFieldValue(field, date)

  const handleDeleteForm = async () => {
    const deleteSuccess = await dispatch(deleteInscriptionForms(event.id, activeInscriptionForm.id))
    if (deleteSuccess) {
      dispatch(resetValue('paymentParams'))
      setOpenDeleteFormDialog(false)
      history.push(`${ROUTES.EVENTS.PROFILE}/${event.slugUrl}?edit=true`)
      showSnackbarSuccess('Inscripción eliminada correctamente')
    }
    setOpenDeleteFormDialog(false)
  }

  const handleChangeShowInscriptionsFrom = (setFieldValue) => (e) => {
    const enableShowInscriptionsFrom = !e.target.checked

    setFieldValue('showInscriptionsFrom', enableShowInscriptionsFrom ? 1 : '', false)
    setFieldValue(
      'checkedShowInscriptionsFrom',
      !enableShowInscriptionsFrom,
      !enableShowInscriptionsFrom
    )
  }

  const handleChangeAllowUnregisteredUsers = (setFieldValue) => (e) =>
    setFieldValue('allowUnregisteredUsers', e.target.value === 'true')

  const handleChangeValidation = (setValues) => (e) => {
    const validationActive = e.target.value === 'true'

    setValues((values) => ({
      ...values,
      validateAge: validationActive,
      validateGender: validationActive,
      items: values.items.map((item) => ({
        ...item,
        validateGender: validationActive,
        validateAge: validationActive
      }))
    }))
  }

  const handleChangeValidationDateFrom = (setValues) => (e) => {
    const validationDate = e.target.value === 'true'
    const validateAgeFrom = validationDate ? event.from : moment().endOf('year').format()

    setValues((values) => ({
      ...values,
      validationDate,
      validateAgeFrom
    }))
  }

  const getErrorsList = (errors) =>
    Object.keys(errors)
      .map((x) => (errorDictionary[x] ? { ...errorDictionary[x], key: x } : null))
      .sort((a, b) => a?.order - b?.order)

  const redirectEventProfile = () => history.push(`${ROUTES.EVENTS.PROFILE}/${event.slugUrl}`)

  const formState =
    !activeInscriptionForm || activeInscriptionForm.isDraft
      ? formActionType.created
      : formActionType.updated

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={onSubmit(actionSuccessForm, formState)}>
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        setValues,
        setErrors
      }) => {
        const avalibleLetterCount =
          MAX_LENGTH_WELCOME_MESSAGE >= values.welcomeMessage.length
            ? MAX_LENGTH_WELCOME_MESSAGE - values.welcomeMessage.length
            : 0

        return (
          <>
            <div className={clsx(classes.titleContainer, submitCount && 'submitted')}>
              <Typography color='primary' variant='h5'>
                CONFIGURACIÓN DE INSCRIPCIONES ONLINE -&nbsp;
                {event.isFree ? 'EVENTO GRATUITO ' : 'EVENTO PAGO'}
              </Typography>

              {!!submitCount && (
                <IconButton color='primary' onClick={redirectEventProfile}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            <div className={classes.bodyContainer}>
              <div className={classes.mainContainer}>
                <ReUseFormDialog onSave={setValues} />

                <SectionBlock title='Mensaje Inicial'>
                  <TextField
                    variant='outlined'
                    fullWidth
                    name='welcomeMessage'
                    value={values.welcomeMessage}
                    autoComplete='off'
                    onBlur={handleBlur}
                    autoFocus
                    onChange={handleChange}
                    multiline
                    className={clsx(classes.textAreaEdit, 'mini')}
                    error={!!errors.welcomeMessage && touched.welcomeMessage}
                    helperText={errors.welcomeMessage}
                    placeholder='Ingrese un mensaje para que el usuario lo lea antes de iniciar el proceso de inscripción (opcional)'
                  />
                  <Typography
                    color='primary'
                    variant='h6'
                    className={classes.letterCounter}
                    align='right'>{`${avalibleLetterCount} caracteres`}</Typography>
                </SectionBlock>
                <SectionBlock
                  title='Fecha de inscripción*'
                  body='Seleccione la fecha de inicio y cierre de inscripciones.'>
                  <div className={classes.datePickersSection}>
                    <div className={classes.fromContainer}>
                      <Typography color='primary' variant='h6'>
                        Desde
                      </Typography>
                      <div className={classes.pickerContainer}>
                        <KeyboardDatePicker
                          className={clsx(classes.inputDateField, classes.dateField)}
                          autoOk
                          name='openFromDate'
                          format='DD/MM/YYYY'
                          variant='inline'
                          margin='none'
                          id='date-picker-inline'
                          inputVariant='outlined'
                          onBlur={handleBlur}
                          value={values.openFromDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          minDateMessage=''
                          maxDateMessage=''
                          invalidDateMessage=''
                          onChange={onDateTimeChanged(
                            setValues,
                            'openFromDate',
                            'openFromTime',
                            moment('00:00', 'HH:mm').format()
                          )}
                          placeholder='ddmmaaaa'
                          error={touched.openFromDate && Boolean(errors.openFromDate)}
                        />
                        <KeyboardTimePicker
                          className={clsx(
                            `${classes.inputDateField} timePicker`,
                            classes.dateField
                          )}
                          autoOk
                          name='openFromTime'
                          variant='inline'
                          onBlur={handleBlur}
                          inputVariant='outlined'
                          keyboardIcon={<AccessTimeIcon />}
                          value={values.openFromTime}
                          placeholder='hh:mm am'
                          invalidDateMessage=''
                          minDateMessage=''
                          maxDateMessage=''
                          onChange={handleChangeTime(setFieldValue, 'openFromTime')}
                        />
                      </div>
                      {errors.openFromDate && (
                        <Typography color='error' variant='caption'>
                          {errors.openFromDate}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Typography color='primary' variant='h6'>
                        Hasta
                      </Typography>
                      <div className={classes.pickerContainer}>
                        <KeyboardDatePicker
                          className={clsx(
                            `${classes.inputDateField} datePicker`,
                            classes.dateField
                          )}
                          autoOk
                          format='DD/MM/YYYY'
                          variant='inline'
                          name='openToDate'
                          margin='none'
                          id='date-picker-inline'
                          onBlur={handleBlur}
                          inputVariant='outlined'
                          value={values.openToDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          onChange={onDateTimeChanged(
                            setValues,
                            'openToDate',
                            'openToTime',
                            moment('23:59', 'HH:mm').format()
                          )}
                          invalidDateMessage=''
                          minDateMessage=''
                          maxDateMessage=''
                          placeholder='ddmmaaaa'
                          error={touched.openToDate && Boolean(errors.openToDate)}
                        />
                        <KeyboardTimePicker
                          className={clsx(
                            `${classes.inputDateField} timePicker`,
                            classes.dateField
                          )}
                          autoOk
                          name='openToTime'
                          onBlur={handleBlur}
                          variant='inline'
                          inputVariant='outlined'
                          keyboardIcon={<AccessTimeIcon />}
                          value={values.openToTime}
                          placeholder='hh:mm am'
                          minDateMessage=''
                          maxDateMessage=''
                          invalidDateMessage=''
                          onChange={handleChangeTime(setFieldValue, 'openToTime')}
                        />
                      </div>
                      {errors.openToDate && (
                        <Typography color='error' variant='caption'>
                          {errors.openToDate}
                        </Typography>
                      )}
                    </div>
                  </div>
                </SectionBlock>
                <SectionBlock title='Cupo General del Evento'>
                  <div>
                    <NumericField
                      variant='outlined'
                      fullWidth
                      name='quota'
                      value={values.quota}
                      autoComplete='off'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={classes.inputField}
                      error={!!errors.quota && touched.quota}
                      helperText={errors.quota}
                      placeholder='Ingrese el número total de competidores del evento (Opcional).'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <CustomizedTooltip
                              position='right-end'
                              arrow
                              title='Este valor debe ser igual o superior a la sumatoria de lo definido en distancias/categorías.'>
                              {({ handleTooltip }) => (
                                <IconButton onClick={handleTooltip}>
                                  <InfoIcon color='primary' />
                                </IconButton>
                              )}
                            </CustomizedTooltip>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </SectionBlock>
                <SectionBlock
                  title='Configuración de categorías*'
                  body='Para la definición de las categorías debemos definir algunos parámetros:'>
                  <div className={classes.validationContainer}>
                    <Typography variant='h6' color='primary'>
                      ¿Desea habilitar la posibilidad que el deportista se inscriba en cualquier
                      categoría?
                    </Typography>
                    <RadioGroup
                      aria-label='quiz'
                      name='validateAge'
                      value={values.validateAge}
                      onChange={handleChangeValidation(setValues)}>
                      <FormControlLabel
                        value={false}
                        control={<Radio color='primary' className={classes.radio} />}
                        label='Si'
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio color='primary' className={classes.radio} />}
                        label='No, habilitar sólo las correspondientes a su edad y género.'
                      />
                    </RadioGroup>
                  </div>
                </SectionBlock>
                <SectionBlock title='Edad del competidor*'>
                  <div className={classes.validateAgeAndGenderContainer}>
                    <RadioGroup
                      aria-label='validateAge'
                      name='validationDate'
                      row
                      value={values.validationDate}
                      className={classes.validateDateFromContainer}
                      onChange={handleChangeValidationDateFrom(setValues)}>
                      <FormControlLabel
                        value={true}
                        control={<Radio color='primary' className={classes.radio} />}
                        label='Edad al día de la carrera'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color='primary' className={classes.radio} />}
                        label='Edad a fecha personalizada.'
                      />
                    </RadioGroup>
                    {!values.validationDate && (
                      <KeyboardDatePicker
                        className={clsx(
                          classes.inputDateField,
                          classes.dateField,
                          classes.dateValidationField
                        )}
                        autoOk
                        name='validateAgeFrom'
                        format='DD/MM/YYYY'
                        variant='inline'
                        margin='none'
                        minDateMessage='La fecha ingresada se encuentra fuera de rango'
                        maxDateMessage='La fecha ingresada se encuentra fuera de rango'
                        id='date-picker-inline'
                        inputVariant='outlined'
                        onBlur={handleBlur}
                        error={!!errors.validateAgeFrom}
                        helperText={errors.validateAgeFrom}
                        value={values.validateAgeFrom}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        onChange={handleChangeDate(setFieldValue, 'validateAgeFrom')}
                        invalidDateMessage='Formato de fecha invalido'
                        placeholder='dd/mm/aaaa'
                      />
                    )}
                  </div>
                </SectionBlock>

                <SectionCategoryAndDistance
                  {...{
                    setFieldValue,
                    values,
                    handleChange,
                    handleBlur,
                    errors,
                    touched,
                    setValues
                  }}
                  eventId={event.id}
                />

                {!event.isFree && <PaymentMethodsSection />}

                <InscriptionPriceSection
                  title={
                    !event.isFree ? '4 Precios de inscripción *' : '3 Tickets de inscripción *'
                  }
                />

                {!event.isFree && <DiscountCodeSection />}

                <SectionBlock title='Texto de bienvenida*'>
                  <TextField
                    variant='outlined'
                    multiline
                    value={values.welcomeText}
                    name='welcomeText'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.welcomeText && touched.welcomeText}
                    helperText={errors.welcomeText}
                    className={classes.textAreaEdit}
                    placeholder='Escribe aqui el texto de bienvenida que será enviando por e-mail al deportista cuando confirme su inscripción.'
                  />
                </SectionBlock>
                <SectionBlock
                  title='Campos predeterminados'
                  body={
                    <>
                      <strong className={classes.importantText}>
                        No es necesario crear campos para los siguientes ítems{' '}
                      </strong>
                      ya que son predeterminados y obligatorios en todas las inscripciones y se
                      llenan automáticamente con los datos de la cuenta del usuario si se inscribe a
                      sí mismo.
                    </>
                  }>
                  <div className={classes.listContainer}>
                    <ul className={classes.list}>
                      <li>Nombre</li>
                      <li>Apellido</li>
                      <li>N° de DNI</li>
                    </ul>
                    <ul className={classes.list}>
                      <li>Género</li>
                      <li>Fecha de nacimiento</li>
                      <li>País</li>
                    </ul>
                    <ul className={classes.list}>
                      <li>Provincia</li>
                      <li>Ciudad</li>
                      <li>E-mail</li>
                    </ul>
                    <ul className={classes.list}>
                      <li>Teléfono</li>
                    </ul>
                  </div>
                </SectionBlock>
                <SectionCustomField
                  values={values}
                  setFieldValue={setFieldValue}
                  organizationId={event.organization.id}
                />
              </div>
            </div>
            <div className={classes.sectionBottomContainer}>
              <div className={classes.fieldCardContainer}>
                {values.customFields
                  .sort((a, b) => a.itemNumber - b.itemNumber)
                  .map((field, index) => (
                    <div key={index}>
                      <CustomFieldCard
                        {...{ index, setFieldValue, values }}
                        field={field.eventCustomField}
                        organizationId={event.organization.id}
                        onDelete={(id) =>
                          handleRemoveOption(values, setFieldValue, id, 'customFields')
                        }
                      />
                    </div>
                  ))}
              </div>
              <SectionBlock
                title='Mostrar inscriptos en el evento'
                body='Indique la cantidad de inscriptos a partir del cual se habilita el botón “Ver inscriptos”'>
                <div className={classes.showInscriptionContainer}>
                  <NumericField
                    variant='outlined'
                    color='primary'
                    size='small'
                    value={values.showInscriptionsFrom}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.numberField}
                    error={touched.showInscriptionsFrom && !!errors.showInscriptionsFrom}
                    helperText={errors.showInscriptionsFrom}
                    name='showInscriptionsFrom'
                    disabled={values.checkedShowInscriptionsFrom}
                    placeholder={
                      !values.checkedShowInscriptionsFrom ? 'Cantidad de inscriptos' : ''
                    }
                  />
                  <FormControlLabel
                    className={classes.checkboxContainer}
                    control={
                      <Checkbox
                        checked={values.checkedShowInscriptionsFrom}
                        onChange={handleChangeShowInscriptionsFrom(setFieldValue)}
                        name='checkedShowInscriptionsFrom'
                        color='primary'
                      />
                    }
                    label='No mostrar inscriptos'
                  />
                </div>
              </SectionBlock>
              <SectionBlock
                title='Configuración de la inscripción'
                body='Configure como desea que se inscriban a su evento'>
                <RadioGroup
                  aria-label='allowUnregisteredUsers'
                  name='allowUnregisteredUsers'
                  row
                  value={values.allowUnregisteredUsers}
                  className={classes.validateDateFromContainer}
                  onChange={handleChangeAllowUnregisteredUsers(setFieldValue)}>
                  <FormControlLabel
                    value={false}
                    control={<Radio color='primary' className={classes.radio} />}
                    classes={{ label: classes.allowUnregisteredUsersLabel }}
                    label='Solo con cuenta registrada de Sportmetric'
                  />
                  <FormControlLabel
                    value={true}
                    classes={{ label: classes.allowUnregisteredUsersLabel }}
                    control={<Radio color='primary' className={classes.radio} />}
                    label='Con y sin cuenta registrada de Sportmetric'
                  />
                </RadioGroup>
              </SectionBlock>
            </div>
            <div className={classes.buttonsContainer}>
              <div
                className={clsx(
                  classes.buttonContainer,
                  (!activeInscriptionForm || activeInscriptionForm.isDraft) && 'medium'
                )}>
                <DeleteConfirmDialog
                  title='Eliminar formulario de inscripción'
                  question='¿Está seguro que desea eliminar el formulario?'
                  helperText='En caso que sí, todas las configuraciones que realizó hasta ahora sobre la inscripción se eliminarán permanentemente.'
                  actionName='Eliminar'
                  deletePending={deleteInscriptionFormsPending}
                  onClose={() => setOpenDeleteFormDialog(false)}
                  onDelete={handleDeleteForm}
                  open={openDeleteFormDialog}
                />
                <Button
                  variant='outlined'
                  color='primary'
                  disabled={
                    !activeInscriptionForm ||
                    activeInscriptionForm.isDraft ||
                    deleteInscriptionFormsPending
                  }
                  className={classes.button}
                  onClick={() => setOpenDeleteFormDialog(true)}>
                  Eliminar formulario
                </Button>
                {(!activeInscriptionForm || activeInscriptionForm.isDraft) && (
                  <Button
                    onClick={draftFormValidate(
                      onSubmit,
                      errors,
                      values,
                      setFieldValue,
                      actionSuccessForm,
                      setErrors
                    )}
                    endIcon={
                      createOrUpdateDraftFormPending && (
                        <CircularProgress size={16} color='primary' />
                      )
                    }
                    variant='outlined'
                    disabled={createOrUpdateDraftFormPending}
                    color='primary'
                    className={clsx(classes.button, classes.createButton)}>
                    Guardar borrador
                  </Button>
                )}
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.button}
                  disabled={!activeInscriptionForm}
                  onClick={() => setOpenPreviewDialog(!openPreviewDialog)}>
                  Vista previa
                </Button>
                <ViewPreviewDialog
                  data={activeInscriptionForm}
                  title={event.name}
                  open={openPreviewDialog}
                  onClose={() => setOpenPreviewDialog(false)}
                />
                <FormNotifyDialog
                  open={openNotifyErrorsDialog}
                  title={
                    !activeInscriptionForm
                      ? 'NOTIFICACIÓN FORMULARIO INCOMPLETO'
                      : 'NOTIFICACIÓN FORMULARIO INCOMPLETO/ERROR'
                  }
                  subtitle={
                    !activeInscriptionForm
                      ? 'Para poder crear tu formulario de inscripción debe estar completo y no tener errores, revisa los siguientes campos:'
                      : 'Para guardar los cambios en tu formulario de inscripción debe estar completo  y no tener errores. \nRevisa los siguientes campos:'
                  }
                  onClose={() => setOpenNotifyErrorsDialog(false)}
                  errors={formErrorList}
                />
                <FormNotifyDialog
                  open={openFormDraftNotifyErrorsDialog}
                  title='NOTIFICACIÓN FORMULARIO - ERROR'
                  subtitle={
                    'Para poder guardar el borrador de tu formulario de inscripción, el mismo no debe tener errores. \nRevisa los siguientes campos:'
                  }
                  onClose={() => setopenFormDraftNotifyErrorsDialog(false)}
                  errors={formErrorList}
                />
                <PaymentNotifyDialog
                  {...mercadoPagoNotifyProps}
                  onClose={() =>
                    setMercadoPagoNotifyProps({ ...mercadoPagoNotifyProps, open: false })
                  }
                />

                {!activeInscriptionForm || activeInscriptionForm.isDraft ? (
                  <Button
                    disabled={
                      isSubmitting || createInscriptionFormsPending || updateInscriptionFormsPending
                    }
                    endIcon={
                      (createInscriptionFormsPending || updateInscriptionFormsPending) && (
                        <CircularProgress size={16} color='primary' />
                      )
                    }
                    variant='contained'
                    color='primary'
                    onClick={formValidate(handleSubmit, isValid, errors, values, setFieldValue)}
                    className={clsx(classes.button, classes.createButton)}>
                    Crear inscripción
                  </Button>
                ) : (
                  <Button
                    disabled={isSubmitting || updateInscriptionFormsPending}
                    endIcon={updateInscriptionFormsPending && <CircularProgress size={16} />}
                    variant='contained'
                    color='primary'
                    onClick={formValidate(handleSubmit, isValid, errors, values, setFieldValue)}
                    className={clsx(classes.button, classes.createButton)}>
                    Guardar cambios
                  </Button>
                )}
              </div>
            </div>
          </>
        )
      }}
    </Formik>
  )
}

export default SettingForm
