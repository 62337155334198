import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import { ROUTES } from 'routes'
import { AccountSportmetricDialog, CreateOrganizationInfoDialog } from 'shared'
import { AddOrganizerRoleDialog } from 'shared/AddOrganizerRoleDialog'
import { SignInDialog } from 'shared/SignInDialog'
import { SignUpDialog } from 'shared/SignUpDialog'
import { loadUserData } from 'state/modules/auth'
import { loadOrganizationsAdminAll } from 'state/modules/userAccounts'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ManageEventSection.style'

const ManageEventSection = ({
  activeAnimation,
  endAnimationAction = () => {},
  ...sectionBlockProps
}) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { user, userData } = useSelector((state) => state.auth)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  useEffect(() => {
    if (user && !userData) {
      handleLoadUserData()
    }
  }, [user, dispatch, userData])

  useEffect(() => {
    if (userData) dispatch(loadOrganizationsAdminAll())
  }, [userData])

  const [openAccountSportmetricDialog, setOpenAccountSportmetricDialog] = useState(false)
  const [openSignInDialog, setOpenSignInDialog] = useState(false)
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false)
  const [openAddOrganizerRoleDialog, setOpenAddOrganizerRoleDialog] = useState(false)
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false)

  const eventManagerWhiteSpace = useMediaQuery((theme) => theme.breakpoints.up(566)) ? ' ' : ''
  const resultWhiteSpace = useMediaQuery((theme) => theme.breakpoints.up(451)) ? ' ' : ''

  const handleOpenSignUpDialog = () => {
    setOpenSignUpDialog(true)
    setOpenSignInDialog(false)
  }

  const handleOpenSignInDialog = () => {
    setOpenSignInDialog(true)
    setOpenSignUpDialog(false)
  }

  const handleLoadUserData = async () => {
    const result = await dispatch(loadUserData(user.id))
    if (!result?.isOrganizer) {
      setOpenAddOrganizerRoleDialog(true)
    }
  }

  const handleOnClose = () => {
    setOpenAddOrganizerRoleDialog(false)
    history.push(`${ROUTES.USERS_ACCOUNTS.PROFILE}/${user.id}`)
  }

  const redirectCreateOrganization = () => {
    if (!user) {
      setOpenAccountSportmetricDialog(true)
      return
    }
    if (!userData?.isOrganizer) {
      setOpenAddOrganizerRoleDialog(true)
      return
    }

    if (organizationsAdminAll.some((x) => x.organization.createdById === user.id)) {
      setOpenCreateOrganizationDialog(true)
      return
    }

    history.push(ROUTES.ORGANIZATIONS.CREATE)
    trackEvent(ANALYTICS_EVENTS.ACTION.CREATE_ORGANIZATION)
  }

  const handleOnCloseAll = () => {
    setOpenSignInDialog(false)
    setOpenSignUpDialog(false)
  }

  return (
    <SectionBlock
      containerClassName={classes.container}
      buttonClassName={classes.endButton}
      nextSectionId='publishEventsSection'
      {...sectionBlockProps}>
      <NavBar />
      {(openSignInDialog || openSignUpDialog) && !isDesktop && (
        <NavBar
          altMode
          position='fixed'
          className={classes.appNavBar}
          onCloseProps={{
            activeClose: true,
            sportmetricLogo: classes.sportmetricLogo,
            buttonClassName: classes.closeButton,
            onCloseAction: handleOnCloseAll
          }}
        />
      )}
      <div className={classes.content}>
        <div className={classes.headerContainer}>
          <Typography color='primary' className={classes.title}>
            <span className={classes.sportmetric}>SPORTMETRIC</span> TE PERMITE
            <MarkText className={classes.eventManager} activeAnimation={activeAnimation}>
              GESTIONAR TUS EVENTOS
            </MarkText>
            {eventManagerWhiteSpace}
            DEPORTIVOS DESDE LA&nbsp;
            <MarkText className={classes.inscription} activeAnimation={activeAnimation}>
              INSCRIPCIÓN
            </MarkText>
            {resultWhiteSpace}
            HASTA LA PUBLICACIÓN DE&nbsp;
            <MarkText
              className={classes.result}
              activeAnimation={activeAnimation}
              endAnimationAction={endAnimationAction}>
              RESULTADOS.
            </MarkText>
          </Typography>
        </div>
        <div className={classes.subTextContainer}>
          <Typography className={classes.title}>
            AHORRÁ TIEMPO Y MEJORÁ <br /> LA EXPERIENCIA DE TUS ASISTENTES.
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={redirectCreateOrganization}>
            Creá tu organización
          </Button>
        </div>
      </div>

      <CreateOrganizationInfoDialog
        open={openCreateOrganizationDialog}
        onClose={() => setOpenCreateOrganizationDialog(false)}
      />
      <AccountSportmetricDialog
        open={openAccountSportmetricDialog}
        onClose={() => setOpenAccountSportmetricDialog(false)}
        setOpenSignInDialog={setOpenSignInDialog}
        setOpenSignUpDialog={setOpenSignUpDialog}
      />
      <SignInDialog
        open={openSignInDialog}
        onClose={() => setOpenSignInDialog(false)}
        handleOpenSignUpDialog={handleOpenSignUpDialog}
      />
      <SignUpDialog
        open={openSignUpDialog}
        onClose={() => setOpenSignUpDialog(false)}
        handleOpenSignInDialog={handleOpenSignInDialog}
      />
      <AddOrganizerRoleDialog open={openAddOrganizerRoleDialog} onClose={handleOnClose} />
    </SectionBlock>
  )
}

export default ManageEventSection
