import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  cropperContainer: {
    backgroundColor: '#D9D9D9',
    width: '100%'
  },
  crooper: {
    width: '100%',
    height: '70vh',
    marginTop: theme.spacing(7),
    [theme.breakpoints.up('lg')]: {
      height: '73vh'
    },
    '& .cropper-modal': {
      opacity: 0
    },
    '& .cropper-crop-box': {
      display: 'none'
    },
    '&.normal': {
      '& .cropper-move': {
        cursor: 'initial'
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(),
    margin: theme.spacing(1, 0)
  },
  zoomIcon: {
    width: 30,
    height: 30
  },
  fileViewer: {
    overflowY: 'auto',
    height: '80vh',
    '& .bPJfhN ': {
      overflowY: 'hidden'
    },
    [theme.breakpoints.up('md')]: {
      height: '86vh'
    }
  }
}))
