import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textField: {
    minHeight: 190,
    '& textarea': {
      minHeight: 190
    }
  },
  iconImageOrganization: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 25,
    height: 25,
    marginRight: 10,
    flexShrink: 0
  },
  dialog: {
    '& > div >div': {
      minHeight: 100,
      width: 500
    }
  },
  bodyContainer: {
    display: 'flex',
    margin: '10px 22px',
    flexDirection: 'column'
  },
  reasonText: {
    margin: 9
  },
  subTitleContainer: {
    display: 'flex'
  }
}))
