import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 800,
      maxWidth: 800,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 400
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(6)
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    padding: theme.spacing(4, 2.5),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0),
      width: '75%'
    }
  },
  subTitle: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    '& span': {
      fontWeight: 600
    }
  },
  infoText: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    flexDirection: 'column',
    '& p': {
      fontWeight: 500,
      color: '#000000',
      fontSize: 15
    },
    '& li': {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '1rem',
      marginBottom: theme.spacing(2)
    },
    '& svg': {
      marginTop: 3,
      fontSize: 18
    }
  },
  actionButton: {
    marginTop: theme.spacing(),
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: '40%',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('md')]: {
        width: 210
      }
    }
  }
}))
