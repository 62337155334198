import React from 'react'
import { useSelector } from 'react-redux'
import { LayoutDialog } from 'shared'

import { InscriptionForm } from '../InscriptionForm'

import { useStyles } from './ViewPreviewDialog.style'

const ViewPreviewDialog = ({ open, onClose, title, data, mode = 'read' }) => {
  const classes = useStyles()

  const { user, userData } = useSelector((state) => state.auth)

  const formData = {
    ...data,
    customFields: data?.customFields.map((e) => ({ ...e.eventCustomField, value: '' }))
  }

  return (
    <LayoutDialog
      title={title}
      open={open}
      onClose={onClose}
      className={classes.dialog}
      contentClassName={classes.container}
      fullScreen={true}>
      {(scrolling) => (
        <InscriptionForm
          user={user}
          userData={userData}
          isAdmin={true}
          formData={formData}
          onBack={onClose}
          mode={mode}
          scrolling={scrolling}
        />
      )}
    </LayoutDialog>
  )
}

export default ViewPreviewDialog
