import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  appNavBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 3000,
    height: 80,
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      top: 20,
      left: 0,
      height: 80,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2)
    }
  },
  sportmetricLogo: {
    width: 210,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      width: 180,
      height: 53,
      backgroundPosition: 'center'
    }
  },
  closeButton: {
    color: theme.palette.primary.contrastText
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(20),
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(25),
      gap: theme.spacing(),
      width: 850
    }
  },
  subTextContainer: {
    padding: '50px 0',
    color: '#454545',
    '& br': {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      '& br': {
        display: 'initial'
      }
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    lineHeight: '35px',
    fontSize: '1.75rem',
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      lineHeight: '45px',
      fontSize: '2.6875rem'
    }
  },
  sportmetric: {
    fontWeight: 700
  },
  eventManager: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontSize: '1.75rem',
    lineHeight: '35px',
    width: '100%',
    '& svg': {
      animationDelay: '1500ms !important'
    },
    [theme.breakpoints.up(410)]: {
      width: 360
    },
    [theme.breakpoints.up('md')]: {
      lineHeight: '45px',
      width: 545,
      fontSize: '2.6875rem'
    }
  },
  inscription: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontSize: '1.75rem',
    width: 185,
    marginRight: 7,
    '& svg': {
      animationDelay: '2500ms !important'
    },
    [theme.breakpoints.up(450)]: {
      marginRight: 0
    },
    [theme.breakpoints.up('md')]: {
      width: 280,
      lineHeight: '45px',
      fontSize: '2.6875rem'
    }
  },
  result: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontSize: '1.75rem',
    width: 175,
    marginRight: 4,
    '& svg': {
      animationDelay: '3500ms !important'
    },
    [theme.breakpoints.up('md')]: {
      width: 270,
      marginRight: 0,
      lineHeight: '45px',
      fontSize: '2.6875rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'right'
    }
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: -10,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  button: {
    borderRadius: 10,
    width: 187,
    height: 41,
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },

    '& span': {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamilyAlt,
      fontWeight: 700,
      fontSize: 16
    },

    [theme.breakpoints.up('md')]: {
      borderRadius: 10,
      width: 306,
      height: 67,
      '& span': {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamilyAlt,
        fontWeight: 700,
        fontSize: 27.5
      }
    }
  },
  content: {
    width: '100%',
    minHeight: '105vh',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      padding: 0,
      width: 1200
    }
  }
}))
