import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { BankAccountCard, SectionLayoutDialog } from 'shared'
import { loadEventBankAccounts, updateEventBankAccount } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './EditBankAccountDialog.style'

const CARD_FORM = 'cardForm'
const FORM_UPDATE = 'formUpdate'

const EditBankAccountDialog = ({ open, onClose, bankAccount }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const formikProps = useFormikContext()
  const { event } = useSelector((state) => state.events)

  const handleSave = async (values) => {
    if (values.radioValue === 'alias') {
      values.number = ''
    } else if (values.radioValue === 'number') {
      values.alias = ''
    }

    values.bankId = values.bank.value
    values.bankName = values.bank.label

    const successUpdate = await dispatch(updateEventBankAccount(event.id, values))

    if (successUpdate) {
      dispatch(loadEventBankAccounts(event.id))
      showSnackbarSuccess('¡Se actualizó con éxito la cuenta bancaria!')
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='EDITAR CUENTA BANCARIA'
      className={classes.dialog}
      contentClassName={classes.container}>
      <BankAccountCard
        bankAccount={bankAccount}
        cardState={CARD_FORM}
        title={''}
        {...formikProps}
        formType={FORM_UPDATE}
        onSubmit={handleSave}
      />
    </SectionLayoutDialog>
  )
}

export default EditBankAccountDialog
