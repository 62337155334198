import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 1900,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: 1700
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1100
    }
  },
  date: {
    width: '30%',
    height: 20
  },
  title: {
    width: '80%',
    height: 20,
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  subTitle: {
    height: 20,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '23%'
    }
  },
  section: {
    height: 16,
    width: '90%',
    [theme.breakpoints.up('lg')]: {
      width: '20%'
    }
  },
  optionCheck: {
    height: 14,
    width: '40%',
    [theme.breakpoints.up('lg')]: {
      width: '8%'
    }
  },
  fieldName: {
    height: 12,
    width: '30%',
    [theme.breakpoints.up('lg')]: {
      width: '10%'
    }
  }
}))
