import React from 'react'
import clsx from 'clsx'

import useStyles from './SliderArrow.style'

const SliderArrow = ({ mode = 'prev', onClick, position, className }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes[mode], className)}
      id='slick-arrow'
      onClick={onClick}
      style={{ ...position }}
    />
  )
}

export default SliderArrow
