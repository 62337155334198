import React, { useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { BankAccountCard, SectionLayoutDialog } from 'shared'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './DeleteBankAccountDialog.style'

const DeleteBankAccountDialog = ({ open, onClose, bankAccount, title, onDelete }) => {
  const classes = useStyles()
  const formikProps = useFormikContext()

  const [deletePending, setDeletePending] = useState(false)

  const handleDelete = async () => {
    try {
      setDeletePending(true)
      await onDelete()
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='ELIMINAR CUENTA BANCARIA'
      className={classes.dialog}
      contentClassName={classes.container}>
      <header className={classes.header}>
        <Typography className={classes.title}>
          ¿Desea eliminar la siguiente Cuenta Bancaria?
        </Typography>
      </header>
      <BankAccountCard bankAccount={bankAccount} title={title} {...formikProps} />

      <div className={classes.buttonsContainer}>
        <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          endIcon={deletePending && <CircularProgress color='primary' size={15} />}
          disabled={deletePending}
          onClick={handleDelete}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default DeleteBankAccountDialog
