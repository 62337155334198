import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadResults } from 'state/modules/userAccounts'

import { RunnerResult } from '../RunnerResult'
import { SectionBlock } from '../SectionBlock'
import { SectionResultsPlaceholderLoader } from '../SectionResultsPlaceholderLoader'

import { useStyles } from './SectionResults.style'

const SectionResults = ({ userAccount }) => {
  const { results, loadingResults } = useSelector((state) => state.userAccounts)
  const dispatch = useDispatch()
  const { id } = userAccount
  const classes = useStyles()

  useEffect(() => {
    dispatch(loadResults(id))
  }, [dispatch, id])

  return (
    <SectionBlock title={`Resultados de ${userAccount.firstName}`} className={classes.firstName}>
      {loadingResults ? (
        <SectionResultsPlaceholderLoader />
      ) : (
        results.map((o) => <RunnerResult key={o.id} runner={o} />)
      )}
    </SectionBlock>
  )
}

export default SectionResults
