import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    minHeight: '340px !important',
    [theme.breakpoints.up('md')]: {
      width: '750px !important',
      maxWidth: '750px !important',
      margin: '0 auto'
    }
  },
  mainContainer: {
    marginTop: theme.spacing(2),
    width: '95%',
    margin: '0 auto'
  },
  buttonsContainer: {
    marginTop: theme.spacing(7),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  text: {
    fontSize: '1rem',
    fontWeight: 500,
    padding: theme.spacing(0, 2)
  },
  button: {
    width: 230,
    height: 40,
    borderRadius: 10,
    '& span': {
      fontWeight: 500,
      fontSize: '1rem'
    }
  }
}))
