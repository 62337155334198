import axios from 'axios'

import BaseProxy from './BaseProxy'

export class UserProfilesProxy extends BaseProxy {
  async get(id) {
    const response = await axios.get(`${this._baseUrl}/user-profiles/${id}`)

    return response.data
  }

  async put(id, userAccount) {
    const response = await axios.put(`${this._baseUrl}/user-profiles/${id}`, userAccount)

    return response.data
  }

  async putPassword(id, passwordData) {
    const response = await axios.put(`${this._baseUrl}/user-profiles/${id}/password`, passwordData)

    return response.data
  }

  async putImage(id, file, type) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.put(
      `${this._baseUrl}/user-profiles/${id}/image?type=${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  }

  async postOrganization(id, organization) {
    const response = await axios.post(
      `${this._baseUrl}/user-profiles/${id}/organizations`,
      organization
    )
    return response.data
  }

  async deleteImage(id, type) {
    const urlParams = this._makeUrlParams({ type })
    const response = await axios.delete(`${this._baseUrl}/user-profiles/${id}/image${urlParams}`)
    return response.data
  }
}

export default UserProfilesProxy
