import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  image: {
    width: 72,
    height: 72,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    border: '1px solid #C8C8C8'
  },
  nameContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  },
  name: {
    fontSize: '1.125em',
    fontWeight: 600
  }
}))

export default useStyles
