import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './EventResultsPlaceholderLoader.style'

const EventResultsPlaceholderLoader = () => {
  const classes = useStyles()
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader className={classes.container}>
            <rect className={classes.select} x='0' y='8' rx='3' ry='3' width='100%' height='100%' />

            <rect
              className={classes.title}
              x='20'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.title}
              x='120'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.title}
              x='220'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.subtitle}
              x='8'
              y='115'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.subtitle}
              x='8'
              y='135'
              rx='3'
              ry='3'
              width='90%'
              height='100%'
            />
          </ContentLoader>
        ) : (
          <ContentLoader className={classes.container}>
            <rect className={classes.select} x='0' y='8' rx='3' ry='3' width='100%' height='100%' />
            <rect className={classes.title} x='7' y='75' rx='3' ry='3' width='100%' height='100%' />
            <rect
              className={classes.title}
              x='140'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.title}
              x='273'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.title}
              x='460'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.title}
              x='640'
              y='75'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.subtitle}
              x='8'
              y='115'
              rx='3'
              ry='3'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.subtitle}
              x='8'
              y='135'
              rx='3'
              ry='3'
              width='90%'
              height='100%'
            />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default EventResultsPlaceholderLoader
