import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, HeaderBox, MainLayout, NavBar, PageContainer } from 'shared'
import { EventCard } from 'shared/EventCard'
import { loadCreatedByMeAll, resetCreatedByMeAll } from 'state/modules/events'

import { useStyles } from './MyCreatedEventsPage.style'

const MyCreatedEventsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const { createdEventsAll, createdAllLastPage, createdEventsAllPending } = useSelector(
    (state) => state.events
  )

  const [page, setPage] = useState(1)

  useEffect(() => {
    if (user) dispatch(loadCreatedByMeAll(page))
  }, [dispatch, page, user])

  useEffect(() => {
    return () => dispatch(resetCreatedByMeAll())
  }, [dispatch])

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer>
        <div className={classes.mainContainer}>
          <HeaderBox titles={['Mis eventos', 'Mis eventos creados']} />
          <div className={classes.bodyContainer}>
            {!!createdEventsAll.length && (
              <InfiniteScroll
                dataLength={createdEventsAll.length}
                next={() => {
                  if (user) setPage((state) => state + 1)
                }}
                hasMore={!createdAllLastPage}
                className={classes.eventsContainer}>
                {createdEventsAll.map((event) => (
                  <div className={classes.cardContainer} key={event.id}>
                    <EventCard
                      typeCard='createCard'
                      reLoadEvents={() => {
                        if (page === 1) dispatch(loadCreatedByMeAll())
                        else setPage(1)
                      }}
                      event={event}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            )}
            {createdEventsAllPending && (
              <div className={classes.containerLoader}>
                <CircularProgress size={40} color='secondary' />
              </div>
            )}
            <div className={classes.endContainer}>
              {!createdEventsAllPending && !createdEventsAll.length && (
                <p>No hay eventos próximos</p>
              )}
              <div className={classes.buttonsContainer}>
                {!createdEventsAllPending && !createdAllLastPage && (
                  <div className={classes.buttonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.button}
                      onClick={() => {
                        setPage((state) => state + 1)
                      }}>
                      Ver más
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default MyCreatedEventsPage
