export const SET_SEARCH_PARAMS = 'userAccounts/SET_SEARCH_PARAMS'

export const SEARCH_ATTEMPT = 'userAccounts/SEARCH_ATTEMPT'
export const SEARCH_SUCCESS = 'userAccounts/SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'userAccounts/SEARCH_FAILURE'
export const RESET_SEARCH = 'userAccounts/RESET_SEARCH'
export const RESET_DATA = 'userAccounts/RESET_DATA'
export const SEARCH_METADATA_ATTEMPT = 'userAccounts/SEARCH_METADATA_ATTEMPT'
export const SEARCH_METADATA_SUCCESS = 'userAccounts/SEARCH_METADATA_SUCCESS'
export const SEARCH_METADATA_FAILURE = 'userAccounts/SEARCH_METADATA_FAILURE'
export const LOAD_ATTEMPT = 'userAccounts/LOAD_ATTEMPT'
export const LOAD_SUCCESS = 'userAccounts/LOAD_SUCCESS'
export const LOAD_FAILURE = 'userAccounts/LOAD_FAILURE'
export const LOAD_RESET = 'userAccounts/LOAD_RESET'
export const LOAD_RESULTS_ATTEMPT = 'userAccounts/LOAD_RESULTS_ATTEMPT'
export const LOAD_RESULTS_SUCCESS = 'userAccounts/LOAD_RESULTS_SUCCESS'
export const LOAD_RESULTS_FAILURE = 'userAccounts/LOAD_RESULTS_FAILURE'
export const LOAD_ORGANIZATIONS_ATTEMPT = 'userAccounts/LOAD_ORGANIZATIONS_ATTEMPT'
export const LOAD_ORGANIZATIONS_SUCCESS = 'userAccounts/LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAILURE = 'userAccounts/LOAD_ORGANIZATIONS_FAILURE'
export const UPDATE_ATTEMPT = 'userAccounts/UPDATE_ATTEMPT'
export const UPDATE_FAILURE = 'userAccounts/UPDATE_FAILURE'
export const UPDATE_SUCCESS = 'userAccounts/UPDATE_SUCCESS'
export const SET_IMAGE_ATTEMPT = 'userAccounts/SET_IMAGE_ATTEMPT'
export const SET_IMAGE_SUCCESS = 'userAccounts/SET_IMAGE_SUCCESS'
export const SET_IMAGE_FAILURE = 'userAccounts/SET_IMAGE_FAILURE'

export const LOAD_CALENDAR_ATTEMPT = 'userAccounts/LOAD_CALENDAR_ATTEMPT'
export const LOAD_CALENDAR_SUCCESS = 'userAccounts/LOAD_CALENDAR_SUCCESS'
export const LOAD_CALENDAR_FAILURE = 'userAccounts/LOAD_CALENDAR_FAILURE'

export const FOLLOW_ATTEMPT = 'userAccounts/FOLLOW_ATTEMPT'
export const FOLLOW_SUCCESS = 'userAccounts/FOLLOW_SUCCESS'
export const FOLLOW_FAILURE = 'userAccounts/FOLLOW_FAILURE'

export const LOAD_FOLLOWED_USER_ACCOUNTS_ATTEMPT =
  'userAccounts/LOAD_FOLLOWED_USER_ACCOUNTS_ATTEMPT'
export const LOAD_FOLLOWED_USER_ACCOUNTS_SUCCESS =
  'userAccounts/LOAD_FOLLOWED_USER_ACCOUNTS_SUCCESS'
export const LOAD_FOLLOWED_USER_ACCOUNTS_FAILURE =
  'userAccounts/LOAD_FOLLOWED_USER_ACCOUNTS_FAILURE'

export const LOAD_FOLLOWED_ORGANIZATIONS_ATTEMPT =
  'userAccounts/LOAD_FOLLOWED_ORGANIZATIONS_ATTEMPT'
export const LOAD_FOLLOWED_ORGANIZATIONS_SUCCESS =
  'userAccounts/LOAD_FOLLOWED_ORGANIZATIONS_SUCCESS'

export const LOAD_FOLLOWED_ORGANIZATIONS_FAILURE =
  'userAccounts/LOAD_FOLLOWED_ORGANIZATIONS_FAILURE'

export const DELETE_IMAGE_ATTEMPT = 'userAccounts/DELETE_IMAGE_ATTEMPT'
export const DELETE_IMAGE_SUCCESS = 'userAccounts/DELETE_IMAGE_SUCCESS'
export const DELETE_IMAGE_FAILURE = 'userAccounts/DELETE_IMAGE_FAILURE'

export const RESET_SEARCH_PARAMS = 'userAccounts/RESET_SEARCH_PARAMS'

export const LOAD_ORGANIZATIONS_ADMIN_ALL_ATTEMPT =
  'userAccounts/LOAD_ORGANIZATIONS_ADMIN_ALL_ATTEMPT'
export const LOAD_ORGANIZATIONS_ADMIN_ALL_SUCCESS =
  'userAccounts/LOAD_ORGANIZATIONS_ADMIN_ALL_SUCCESS'
export const LOAD_ORGANIZATIONS_ADMIN_ALL_FAILURE =
  'userAccounts/LOAD_ORGANIZATIONS_ADMIN_ALL_FAILURE'

export const LOAD_UPCOMING_EVENT_MANAGED_BY_ATTEMPT =
  'userAccounts/LOAD_UPCOMING_EVENT_MANAGED_BY_ATTEMPT'
export const LOAD_UPCOMING_EVENT_MANAGED_BY_SUCCESS =
  'userAccounts/LOAD_UPCOMING_EVENT_MANAGED_BY_SUCCESS'
export const LOAD_UPCOMING_EVENT_MANAGED_BY_FAILURE =
  'userAccounts/LOAD_UPCOMING_EVENT_MANAGED_BY_FAILURE'

export const LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_ATTEMPT =
  'userAccounts/LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_ATTEMPT'
export const LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_SUCCESS =
  'userAccounts/LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_SUCCESS'
export const LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_FAILURE =
  'userAccounts/LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_FAILURE'

export const LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_ATTEMPT =
  'userAccounts/LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_ATTEMPT'
export const LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_SUCCESS =
  'userAccounts/LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_SUCCESS'
export const LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_FAILURE =
  'userAccounts/LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_FAILURE'
