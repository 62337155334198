import React from 'react'
import { Button, Typography } from '@material-ui/core'
import {} from '@material-ui/icons'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './DeleteTicketDialog.style'

const DeleteTicketDialog = ({ open, onClose, ticket, position }) => {
  const classes = useStyles()
  const { setValues } = useFormikContext()

  const getActiveTicketTypes = (values) =>
    values.ticketTypes
      .filter((x) => x.isActive)
      .sort(
        (a, b) =>
          moment(a.saleFrom).diff(moment(b.saleFrom)) ||
          moment(moment(a.saleTo).format('YYYY-MM-DD')).diff(
            moment(moment(b.saleTo).format('YYYY-MM-DD'))
          ) ||
          Number(a.price) - Number(b.price) ||
          a.name.localeCompare(b.name)
      )

  const handleDeleteTicket = () => {
    setValues((values) => ({
      ...values,
      ticketTypes: [
        ...values.ticketTypes.filter((x) => !x.isActive),
        ...getActiveTicketTypes(values).filter((_, index) => index !== position)
      ]
    }))

    showSnackbarSuccess('¡Ticket eliminado con éxito!')
    onClose()
  }

  return (
    <SectionLayoutDialog
      open={open}
      title='ELIMINAR TICKET'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography className={classes.title} color='primary' variant='h6'>
        ¿Está seguro que desea eliminar del evento el ticket {ticket.name}?
      </Typography>

      <div className={classes.buttonsContainer}>
        <Button color='primary' variant='contained' onClick={onClose} className={classes.button}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleDeleteTicket}
          className={classes.button}>
          Eliminar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default DeleteTicketDialog
