import React from 'react'
import { Divider, Typography } from '@material-ui/core'

import { useStyles } from './TermsAndConditionsOrganizationContent.style'

const TermsAndConditionsOrganizationContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Typography className={classes.title} variant='h2' color='primary'>
          CONTRATO DE ACEPTACION DE TÉRMINOS Y CONDICIONES Y PRESTACIÓN DE SERVICIOS.
        </Typography>

        <Typography className={classes.text}>
          Usted está por comenzar a utilizar nuestros servicios, y para ello resulta indispensable
          que lea atentamente los Términos y Condiciones de Uso para ORGANIZADORES, que se expondrán
          a continuación. Los mismos harán las veces de un contrato que regirá la relación jurídica
          de consumo que tendrá a SPORTMETRIC de oferente de un Servicio y a los ORGANIZADORES como
          consumidoras del mismo. Por ello, para poder operar en nuestra plataforma, deberá de
          manera indefectible aceptar los mismos, prestando así conformidad y entendimiento respecto
          de lo aquí expresado.
        </Typography>
      </div>
      <Divider />
      <div className={classes.block}>
        <Typography className={classes.subTitle} variant='h4'>
          PRIMERO: CONSENTIMIENTO Y ACEPTACION EXPRESA DEL ORGANIZADOR.
        </Typography>

        <Typography className={classes.text}>
          Al aceptar los Términos y Condiciones establecidos, usted está manifestando libre y
          expresamente su conformidad con los mismos, por lo que luego de ello, no podrá realizar
          reclamo alguno respecto de todo lo que en el presente contrato se encuentre pautado y
          regulado.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography className={classes.subTitle} variant='h4'>
          SEGUNDO: GENERALIDADES DEL SERVICIO.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC ARGENTINA S.A.S. (en adelante “SPORTMETRIC”), C.U.I.T. 30- 71842438-7 es una
          empresa cuyo objeto comercial recae en prestar servicios de gestión de venta de Entradas
          para eventos de distinta índole. Dicha compra y venta de entradas puede ser realizada
          tanto mediante forma virtual, como también físicamente en puntos de venta dispuestos al
          efecto.
        </Typography>
        <Typography className={classes.text}>
          La función de SPORTMETRIC, debe quedar en claro, no resulta ser la de Organizadora de
          Eventos, sino que simplemente limita su función a ser intermediara entre el Organizador de
          un Evento (en adelante “ORGANIZADOR”) y el Usuario Final (en adelante “USUARIO”), quien
          compra una entrada para asistir a determinado evento, o bien quien vaya a ser el poseedor
          de un ticket.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC, le brinda al ORGANIZADOR, por cuenta, mandato y orden de esta, el servicio de
          venta de Entradas para un determinado Evento. Dicha venta de entradas se realizará tanto
          de forma virtual como física en caso de ser requerida.
        </Typography>
        <Typography className={classes.text}>
          Es por ello, que el único y exclusivo responsable por la organización, modificación,
          realización, calidad, licitud, daños, lesiones, irregularidades impositivas, etc., del
          Evento para el cual un USUARIO adquirió, o posea una entrada, es el ORGANIZADOR del
          evento. SPORTMETRIC no tendrá responsabilidad alguna por todo lo que tenga que ver con
          ello.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC es el único titular y responsable de la propiedad intelectual de la plataforma
          virtual de venta de entradas. El ORGANIZADOR no posee en ningún momento titularidad
          respecto de la misma, sino que solo tendrá la disposición de la misma respecto a su cuenta
          y evento registrado, siempre bajo los lineamientos de presente contrato.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography className={classes.subTitle} variant='h4'>
          TERCERO: SERVICIO BRINDADO AL ORGANIZADOR.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC, pondrá a disposición del ORGANIZADOR su plataforma virtual de venta de
          entradas para que esta, por intermedio de SPORTMETRIC pueda vender y poner a disposición
          del comprador las entradas de los eventos organizados.
        </Typography>
        <Typography className={classes.text}>
          Para ello, el ORGANIZADOR deberá crear una cuenta en la plataforma de venta online
          utilizada al efecto y a posterior deberá generar la configuración de su producto y/o
          servicio (el evento). Para esto, SPORTMETRIC, se compromete a prestarle la debida asesoría
          al efecto. Es menester destacar que la consignación, configuración y disposición de datos
          del EVENTO creado por el ORGANIZADOR, como así también su veracidad, queda bajo su
          exclusiva responsabilidad, quedando exento SPORTMETRIC de cualquier error al respecto.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC, a su vez, se encuentra facultada a verificar en forma permanente el estado de
          la cuenta creada por el ORGANIZADOR, a los fines de tener noción de la cantidad de
          personas que adquirieron el producto y/o servicio; los pagos efectuados por los usuarios;
          el ingreso total de pagos; los egresos por pagos de comisiones al prestador, como así
          también para mantener un control de la calidad y funcionalidad del servicio prestado.
          Asimismo, también tendrá la facultad de verificar, si así lo considerara, los datos
          incorporados por el ORGANIZADOR en la plataforma y solicitar que se realicen
          modificaciones o bien que se completen los mismos. Esto no lo transforma en solidario de
          el ORGANIZADOR en cuanto a la responsabilidad indicada en el párrafo que antecede.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR no goza de exclusividad respecto del uso de la plataforma de venta virtual
          de SPORTMETRIC y, por tanto, dicha plataforma podrá brindar servicio a varios
          ORGANIZADORES a la vez, sin que esto genere perjuicio alguno a ellos, ni tampoco dará
          lugar a reclamo alguno de parte de estos.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC no garantiza bajo ningún aspecto al ORGANIZADOR que el evento organizado y
          para el cual se utilizan los servicios de SPORTMETRIC, reúna la cantidad de participantes
          esperada, como así tampoco el correcto desarrollo ni la licitud del mismo. De todo ello se
          hará cargo y será exclusivamente responsable el ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Se aclara también, en este punto, que el ORGANIZADOR tendrá la posibilidad de realizar
          ventas de entradas de manera física y cobrando las mismas en efectivo, por cuenta y
          responsabilidad de sí mismo, dejando exenta de responsabilidad a SPORTMETRIC de cualquier
          inconveniente que pueda surgir con dicha modalidad de venta y cobro. Asimismo, el
          ORGANIZADOR será exclusivo responsable de responder frente a los USUARIOS por cualquier
          circunstancia relacionada a ello como así también al evento.
        </Typography>
      </div>
      <div>
        <Typography className={classes.subTitle} variant='h4'>
          CUARTO: CAPACIDAD DEL ORGANIZADOR.
        </Typography>

        <Typography className={classes.text}>
          El ORGANIZADOR declara expresamente, ser una persona (física o jurídica) hábil, mayor de
          edad y que posee capacidad legal para contratar y recibir servicios, todo ello en el marco
          de la Legislación Argentina vigente.
        </Typography>
        <Typography className={classes.text}>
          En idéntico sentido, se obliga a suministrar a SPORTMETRIC datos verdaderos, correctos,
          actuales y completos respecto de su persona, ya sea física o jurídica, o bien sus
          apoderados al momento de registrarse en la plataforma virtual, como así también a informar
          cualquier actualización de los mismos.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC se reserva el derecho de suspender o cancelar, sin previo aviso, ni previa
          notificación, la cuenta registrada del ORGANIZADOR de la cual haya certeza o sospechas de
          que haya suministrado datos no fidedignos, incompletos o maliciosos, sin que el
          ORGANIZADOR tenga nada por reclamar.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          QUINTO: DEL EVENTO OFRECIDO POR EL ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC no tendrá responsabilidad alguna respecto de la efectiva realización del
          evento puesto a la venta por el ORGANIZADOR; como así tampoco de su veracidad,
          habilitación, licitud, seguridad, calidad, modificación, suspensión, y cualquier otra
          cuestión relativa al correcto desempeño del evento, como también al lugar en el que se
          desarrolle el mismo. Tampoco será responsable de todo daño que pueda ser generado con
          motivo de la realización u organización del evento ofrecido y organizado por el
          ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR, en tanto, se hace expresamente responsable por todo ello, y manifiesta que
          SPORTMETRIC nada tendrá que responder por todo lo mencionado.
        </Typography>
        <Typography className={classes.text}>
          Por este acto, el ORGANIZADOR declara su conformidad con la dinámica antes descripta y se
          compromete a dar informaciónn véridica de todos los datos relativos a su Evento.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          SEXTO: MODALIDAD DE COBRO DE ENTRADAS Y PAGO AL ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          En cuanto a la modalidad de venta electrónica/virtual, se aclara que SPORTMETRIC utiliza
          para el cobro de las mismas la plataforma virtual de MERCADOPAGO, motivo por el cual los
          cobros de las entradas mencionadas se automatizarán directamente, derivándose de manera
          automática los importes que le corresponda a cada parte, a la cuenta de MERCADOPAGO que
          hayan registrado y autorizado al efecto. Es importante destacar que SPORTMETRIC no
          percibirá de ninguna manera el dinero total correspondiente a cualquier venta, sino que
          solo percibirá lo correspondiente a la comisión que le es debida por cada entrada vendida
          por su sistema de gestión de venta online, mientras el importe correspondiente al
          ORGANIZADOR, será automáticamente derivado a su cuenta de MERCADOPAGO.
        </Typography>
        <Typography className={classes.text}>
          Tanto el ORGANIZADOR como SPORTMETRIC, convienen y aceptan conocer el sistema de
          recaudación que brinda MERCADOPAGO, siendo que este se basa en un “Split de Pago” mediante
          el cual se separa automáticamente el dinero cobrado por cada entrada vendida en 3 partes:
          1) El dinero correspondiente a la comisión que le corresponda a SPORTMETRIC por el
          servicio brindado, se deriva automáticamente en la cuenta de MERCADOPAGO de esta; 2) El
          dinero correspondiente a la comisión que cobra MERCADOPAGO por ser el gestor de pago
          utilizado, se deriva directamente a su cuenta propia de MERCADOPAGO; 3) El saldo restante
          del total del valor de la entrada, se deposita automáticamente en la cuenta de MERCADOPAGO
          del ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Para el caso de los medios de venta físicos de cobro en efectivo que proponga el
          ORGANIZADOR, se aclara que SPORTMETRIC solamente brindará el sistema de Gestión de la
          generación de la entrada propiamente dicha. El ORGANIZADOR asume la responsabilidad de la
          venta y cobro de entradas en efectivo, como así también del personal que se disponga al
          efecto.
        </Typography>
        <Typography className={classes.text}>
          Por dicho servicio, se pacta expresamente que SPORTMETRIC cobrará una comisión por
          aquellas ventas realizadas en efectivo mediante el uso de la plataforma, la cual se
          acordará previamente con el ORGANIZADOR, y que deberá ser integrada dentro de los plazos
          previamente definidos.
        </Typography>
      </div>

      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          SEPTIMO: COMISION DE SPORTMETRIC POR LA PRESTACION DEL SERVICIO DE VENTA DE ENTRADAS AL
          ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Para brindar el servicio contratado, SPORTMETRIC utilizará un sistema de gestión propio, y
          por dicho servicio prestado cobrará un porcentaje (que será previamente acordado con el
          ORGANIZADOR en un anexo comercial), sobre el valor de cada entrada vendida, ya sea bajo
          modalidad virtual o en efectivo. Es importante destacar, que dicho porcentaje mencionado
          no incluye IVA, lo cual se acepta y conviene entre las partes. Este importe, se destinará
          automáticamente en la cuenta de MERCADOPAGO de SPORTMETRIC en el caso de las ventas
          online, mientras para el caso de la venta física en efectivo, deben remitirse a los
          estipulado en la Cláusula SEXTA.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          OCTAVO: RESPOSABILIDADES DEL ORGANIZADOR RESPECTO A LA NO REALIZACION DEL EVENTO EN EL
          MARCO DE LA COMISION DE SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR, entiende, asume y presta conformidad de que la función de SPORTMETRIC está
          basada pura y exclusivamente en la prestación del servicio de venta de entradas, por medio
          de su plataforma virtual, en favor del evento organizado ofrecido.
        </Typography>
        <Typography className={classes.text}>
          Es por ello, que el ORGANIZADOR asume exclusivamente la responsabilidad por la no
          realización del evento ofrecido, motivo por el cual en el caso de que el evento no se
          realice por causas exclusivamente imputables al ORGANIZADOR, SPORTMETRIC igualmente deberá
          percibir, por parte del ORGANIZADOR, la totalidad de las comisiones correspondientes por
          las entradas que se hayan vendido, es decir el servicio brindado y efectivamente
          realizado.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          NOVENO: COMISION DE MERCADOPAGO COMO PLATAFORMA DE GESTION DE PAGOS.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta y expresa conocer, que MERCADOPAGO cobrará también un porcentaje del
          valor total de la entrada vendida de manera virtual en concepto de comisión por gestión de
          pago, en virtud del plazo que el ORGANIZADOR elija para recibir el dinero en la aplicación
          de MERCADOPAGO. Así también acepta conocer los términos y condiciones de MERCADOPAGO en
          relación a los aumentos del mencionado porcentaje para el caso de que el ORGANIZADOR
          quiera percibir el dinero en un plazo más rápido.
        </Typography>
        <Typography className={classes.text}>
          Se aclara, y así se conviene, que SPORTMETRIC no se responsabiliza bajo ningún aspecto por
          los posibles aumentos de comisión que pueda imponer MERCADOPAGO durante la vigencia del
          presente contrato. SPORTMETRIC no se responsabiliza por los errores que cometiera el
          ORGANIZADOR en la configuración de su cuenta tanto de la plataforma de SPORTMETRIC como de
          MERCADOPAGO.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMO: CANCELACION O MODIFICACION DE LAS CONDICIONES DE REALIZACION DEL EVENTO ORGANIZADO
          POR EL ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR, será único y exclusivo responsable por la realización o no del evento
          organizado, como así también por cualquier modificación que puedan sufrir las condiciones
          iniciales del mismo. En tanto a ello, responderá frente a los USUARIOS por dichas
          cuestiones, motivo por el cual deberá tener a disposición de estos una Política, dejando
          expresamente ajeno a SPORTMETRIC de tener que responder por los términos y condiciones
          propios del ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR se obliga a informar a SPORTMETRIC cualquier circunstancia relativa a ello,
          a los efectos de que SPORTMETRIC pause o de fin a la venta de entradas de dicho evento.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR será único y exclusivo responsable por las políticas que adopte para el
          caso de modificación de las condiciones iniciales del evento ofrecido, como así también
          para aquellas que adopte en caso de cancelación. Esto quiere decir que se hará cargo
          expresamente por aquellos casos en los que haya que realizar devoluciones de entradas,
          siendo el único responsable por ello frente a los USUARIOS. Dichas políticas deberán ser
          comunicadas a los USUARIOS por los canales que el ORGANIZADOR crea más apropiados, o
          incluso los mismos que esta haya utilizado para promocionar el evento.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOPRIMERO: ENTREGA DE LA ENTRADA A USUARIOS.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR manifiesta conocer que, respecto de la entrega de las entradas a los
          USUARIOS, una vez realizada la compra de la ENTRADA por el USUARIO, se activará en la
          cuenta que este haya registrado en la plataforma de SPORTMETRIC su “Inscripción” (Ticket
          electrónico), el cual podrá utilizar personalmente o transferirlo a un tercero si es que
          así lo determina el ORGANIZADOR, así mismo será el ORGANIZADOR quien determine la
          documentación requerida al USUARIO para validar dicha entrada.
        </Typography>
        <Typography className={classes.text}>
          Así mismo, recibirá vía electrónica, más precisamente por correo electrónico, un aviso de
          cortesía de que su “Preinscripción/Inscripción/Anulación” ya fue procesada y que la
          información se encuentra disponible en la plataforma a fin de corroborarla.
        </Typography>
        <Typography className={classes.text}>
          Dicho envío de la información del estado de “Preinscripción/Inscripción/Anulación”,
          dependerá también de la forma de pago elegida por el USUARIO.
        </Typography>
        <Typography className={classes.text}>
          De esa manera, para el caso del que el USUARIO realice la compra a través de los medios
          habilitados por MERCADOPAGO (compra online), recibirá en su correo electrónico consignado
          al efecto, la notificación de que se encuentra “Inscripto”, en su cuenta de la plataforma
          de SPORTMETRIC, una vez procesado y autorizado el pago, tanto por la empresa emisora de la
          tarjeta, como también por MERCADOPAGO.
        </Typography>
        <Typography className={classes.text}>
          Si el USUARIO elige como método de pago un cupón de pago de las entidades de cobro
          habilitadas al efecto, se deberá esperar un plazo de entre 48 y 72 horas para que el mismo
          sea acreditado, y de esa forma, una vez acreditado el pago, el USUARIO recibirá la
          confirmación en la forma indicada.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEGUNDO: DEVOLUCION DE LAS ENTRADAS A LOS USUARIOS.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta conocer que la plataforma de gestión de cobros utilizada por
          SPORTMETRIC, es decir MERCADOPAGO, establece un proceso de devolución automática que será
          aplicada en el caso de suspensión del evento objeto del presente contrato, para gestionar
          la devolución del valor de las entradas vendidas a los USUARIOS de manera automática.
        </Typography>
        <Typography className={classes.text}>
          Es dable destacar, asimismo, que dichas pautas establecidas por MERCADOPAGO, están sujetas
          a los cambios que considere realizar dicha entidad. Frente a estas políticas que están
          estipuladas por MERCADOPAGO, queda bajo la exclusiva responsabilidad del ORGANIZADOR
          hacérselas conocer a los USUARIOS, quedando exento de responsabilidad SPORTMETRIC para el
          caso de que los USUARIOS tengan algo por reclamar respecto al desconocimiento de cualquier
          información y sus modificaciones.
        </Typography>
        <Typography className={classes.text}>
          Dicha devolución automatizada, será pura y exclusivamente para los usuarios que hayan
          adquirido el producto en el plazo estipulado oportunamente por MERCADOPAGO. Pasado dicho
          plazo, el ORGANIZADOR deberá hacerse cargo de realizar las devoluciones por sus propios
          medios, responsabilizándose expresamente por ello, y comprometiéndose a no reclamar
          importe alguno a SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          Para el caso de que se realicen devoluciones de manera automática utilizando el servicio
          de gestión de pagos de MERCADOPAGO, las partes aceptan conocer que se le devolverá al
          usuario el 100% del valor que este hubiere abonado, es decir se le reintegrará también la
          comisión por servicio que le correspondiere tanto a SPORTMETRIC como a MERCADOPAGO. Frente
          a esto es menester recordar que, en dicho caso, El ORGANIZADOR deberá abonar a SPORTMETRIC
          el valor correspondiente a la comisión pactada por las entradas que hubieren sido
          vendidas.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOTERCERO: PROCESO DE DEVOLUCIÓN AUTOMATICA POR INTERMEDIO DE MERCADOPAGO.
        </Typography>
        <Typography className={classes.text}>
          La gestión y políticas de devolución del dinero por la compra de las entradas es pura y
          exclusiva responsabilidad del ORGANIZADOR, el cual deberá informar a los USUARIOS el
          procedimiento de reintegro. Queda expresamente acordado que, si bien el método de “Split
          de cobro” contempla que el USUARIO reciba el 100% del pago abonado, incluida la comisión
          de SPORTMETRIC, el ORGANIZADOR abonará igualmente a SPORTMETRIC la comisión
          correspondiente aplicada a la gestión de sus servicios ya prestados.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOCUARTO: POLITICA DE PRIVACIDAD.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC utilizará los datos personales de los ORGANIZADORES registrados con el único
          fin de validar y brindar el servicio de venta de entradas de los eventos organizados, como
          así también para mejorar sus servicios prestados y la comercialización de las entradas
          vendidas. También serán utilizados con el fin de llevar un control y recuento de las
          entradas vendidas de un evento, información que podrá ser solicitada a SPORTMETRIC por el
          ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Sin perjuicio de ello, se aclara que el servicio antes mencionado está disponible en la
          plataforma de SPORTMETRIC y en casos excepcionales, SPORTMETRIC brindará dicha
          información.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC se compromete a no traspasar los datos mencionados a terceros ajenos a la
          prestación del servicio brindado, salvo a aquellas personas o empresas relacionadas con
          SPORTMETRIC, como ser el USUARIO que adquirió una entrada.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC podrá generar bases de datos propias con la información ingresada a la
          plataforma por el ORGANIZADOR, para utilizarlos exclusivamente con fines comerciales y de
          promoción.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta y deja expresa constancia que toda la información solicitada por
          SPORTMETRIC, es provista por ésta, de manera voluntaria y consentida, como así también
          entendiendo y aceptando la política de privacidad, como así también la totalidad del
          presente contrato.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOQUINTO: DESLINDE DE RESPONSABILIDAD DE SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta expresamente, que SPORTMETRIC quedará excluido de cualquier tipo de
          responsabilidad que pueda surgir por cuestiones relativas al fin último que tenga la
          realización del evento organizado por el, como ser fines ilícitos, delictivos, evasivos en
          términos impositivos, o cualquier otro fin que exceda el de entretenimiento. Así tampoco
          será responsable por todo lo relacionado con la realización del mismo, sus modificaciones,
          su calidad, accidentes/daños/lesiones dentro del evento, y demás contratiempos e
          inconvenientes imputables al ORGANIZADOR, como así tampoco en todas las cuestiones
          relativas al lugar en donde se realice físicamente el evento.
        </Typography>
        <Typography className={classes.text}>
          Todas aquellas cuestiones de índole legal, ya sea delictual, infraccionaría o impositiva,
          que se puedan suscitar en el marco del evento ofrecido por el ORGANIZADOR, quedan bajo la
          expresa órbita de responsabilidad de este última, quien deja expresa constancia de
          asumirla, dejando exenta a SPORTMETRIC de responder por cualquier tipo de responsabilidad
          mencionada.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, SPORTMETRIC también quedará exento de la responsabilidad por aquellos USUARIOS
          que utilicen las entradas con otro fin más que el de asistir al evento, para el cual
          compraron la entrada, como ser, por ejemplo, la reventa ilegal de entradas, etc. Al
          respecto, el ORGANIZADOR deslinda expresamente de responsabilidad a SPORTMETRIC, por
          cualquier ilegalidad que se pueda cometer con respecto al uso y disposición de las
          entradas vendidas, asumiendo el ORGANIZADOR dichas responsabilidades de control y
          recaudos.
        </Typography>
        <Typography className={classes.text}>
          Es menester recordar en este punto que la única responsabilidad que tiene SPORTMETRIC, es
          la de poner al servicio del ORGANIZADOR y el USUARIO un canal de intermediario para que un
          oferente (ORGANIZADOR) pueda poner a la venta las entradas de un evento por el organizado
          y el USUARIO pueda adquirirlas. Se deja expresa constancia que SPORTMETRIC responde
          solamente por el correcto funcionamiento de su plataforma de gestión de ventas, respecto
          al servicio brindado a el ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, acepta el ORGANIZADOR, que SPORTMETRIC queda totalmente exenta de
          responsabilidad por cualquier daño y perjuicio que pueda generarle el uso de la
          plataforma, en lo relacionado a la presencia de virus u cualquier otro elemento en los
          contenidos que en la plataforma se muestran y que puedan generar alteraciones en los
          sistemas operativos, informativos o dispositivos móviles/computadoras que el ORGANIZADOR
          utilice para operar en la plataforma.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC no será responsable, y el ORGANIZADOR asume que nada tendrá que reclamarle por
          cualquier daño o perjuicio, ya sea pérdida económica, lucro cesante, etc., que pueda
          surgir por el uso de los servicios de la plataforma de venta de entradas, siempre y cuando
          estas fueran producidas a pesar del buen y correcto funcionamiento de la misma, incluso
          cuando haya sido por alguna interrupción momentánea del servicio de venta de entradas, por
          cuestiones técnicas ajenas a SPORTMETRIC, como ser cortes de suministros eléctricos,
          inconvenientes técnicos de la conectividad a internet, y cualquier otro motivo que dentro
          de la lógica normal exceda la responsabilidad de SPORTMETRIC.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEXTO: CUESTIONES IMPOSITIVAS.
        </Typography>
        <Typography className={classes.text}>
          Cada una de las partes intervinientes en el presente contrato será responsable de todas
          aquellas obligaciones impositivas que deba cumplir, dejando expresamente asentado que
          SPORTMETRIC no será responsable por cualquier posible incumplimiento y/o evasión que
          realice el ORGANIZADOR por los ingresos percibidos ni otras cuestiones impositivas que
          deba informar o cumplir a los entes correspondientes.
        </Typography>
        <Typography className={classes.text}>
          Cada una de las partes será estrictamente responsables de sí misma, por aquellas
          retenciones o gastos extras que pudieran surgir durante el transcurso de la presente
          relación contractual, con motivo de no haberse inscripto en la categoría impositiva que le
          corresponda, en función de las normas impositivas tanto nacionales, como provinciales o
          municipales, al igual que para el caso de no haberse registrado correctamente ni
          acompañado la debida documentación correspondiente que fuera solicitada por MERCADOPAGO (o
          cualquier otro organismo), al momento de registrarse en dicha plataforma. En virtud de
          ello, ninguna de las partes tendrá nada que reclamarle a la otra por las cuestiones
          planteadas en esta cláusula y que fueran por su exclusiva culpa, omisión y/o
          responsabilidad.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC, por cuestiones impositivas, hace saber que tiene la obligación de facturar,
          una vez al mes, las ganancias percibidas por las comisiones de la venta de entradas. Dicha
          facturación será realizada a nombre de la persona o empresa que sea asignada por el
          ORGANIZADOR en su cuenta de MERCADOPAGO o aquella que determine para tal fin. El
          ORGANIZADOR acepta esta obligación y se compromete a colaborar en dicha facturación, y
          nada tendrá por reclamar ni entorpecer respecto de ello, quedando a su exclusiva
          responsabilidad, los inconvenientes que puedan surgir en caso de no colaborar con dicha
          obligación.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta expresamente que MERCADOPAGO oficiará como agente de retenciónn o
          percepción, en el marco de los regímenes impositivos por los que se encuentren alcanzadas
          las partes, como ser IVA, impuesto a las ganancias, ingresos brutos, impuestos a los
          débitos y créditos, como así también cualquier otra obligación impositiva que pueda surgir
          en lo sucesivo.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEPTIMO: PERMISOS PARA PUBLICITAR.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR le otorga expresamente a SPORTMETRIC licencia para realizar, ya sea en
          forma coordinada o separada, la publicidad del evento objeto del presente contrato.
          Incluso, reconoce a SPORTMETRIC la posibilidad de utilizar el contenido que el ORGANIZADOR
          cargó en la plataforma, con motivos publicitarios.
        </Typography>
        <Typography className={classes.text}>
          En virtud de ello, el material a publicitar por SPORTMETRIC será presentado al ORGANIZADOR
          previo a su publicación, para que esta lo revise, a los fines de mantener indemne su
          Derecho a la Imagen, y lo autorice.
        </Typography>
        <Typography className={classes.text}>
          Una vez autorizado y publicado el material publicitario, las partes dejan expresa
          constancia en el presente, de que nada tendrán que reclamarse por la publicidad que cada
          uno realice, siempre y cuando, la publicidad realizada guarde el debido respeto y decoro
          respecto de la otra parte, y no manche su honor y buen nombre.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR, acepta colaborar en cuanto al material a publicitar, como así también a
          mencionar a SPORTMETRIC en el material publicitario que el ORGANIZADOR publique, previa
          puesta a consideración de esta y su correspondiente aceptación y autorización. Así
          también, SPORTMETRIC se compromete a nombrar al ORGANIZADOR del evento en cualquier
          material publicitario que publique.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOCTAVO: INDEMNIDAD
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR se obliga con la aceptación de los presentes términos y condiciones a
          mantener indemne al/los titular/es de la plataforma de SPORTMETRIC, sus funcionarios,
          directores y empleados, incluyendo honorarios de abogados, frente a cualquier reclamo de
          terceros basado en su utilización de esta Plataforma y/o de sus contenidos, o por
          violación del presente contrato.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMONOVENO: CONDICIONES Y LIMITACIONES DE USO.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC se reserva el derecho de establecer a los ORGANIZADORES las condiciones de uso
          generales, como así también los límites del servicio. Esto puede aplicarse, sin limitarse,
          al plazo durante el cual estarán los eventos disponibles para la venta, al monto máximo y
          mínimo que se puede cobrar por un evento, a la cantidad de tickets que puedan ser puestos
          a la venta, el plazo de inactividad de cuentas, etc.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta expresamente que SPORTMETRIC no tendrá responsabilidad alguna
          respecto de la posible cancelación de cuentas de ORGANIZADORES, que se encuentren
          inactivas por un determinado periodo de tiempo o que hayan incumplido alguno de los
          términos y condiciones aquí estipulados.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR también acepta expresamente la facultad de SPORTMETRIC de modificar las
          condiciones de uso, en cualquier momento, sin necesidad de comunicación previa.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC tampoco será responsable, y podrá reclamarle al ORGANIZADOR por modificar, o
          suprimir el servicio o cualquier parte del mismo, ya sea de manera temporal o permanente,
          frente a cualquier irregularidad o ilegalidad que detecte respecto al evento publicado por
          el ORGANIZADOR, reservándose asimismo este derecho, todo lo cual el ORGANIZADOR entiende y
          acepta.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMO: CANCELACION DEL SERVICIO
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta expresamente que SPORTMETRIC podrá en determinadas circunstancias, y
          sin la obligación de notificar previamente, dar de baja la cuenta del ORGANIZADOR por
          motivos que tengan que ver con incumplimientos o violaciones a los presentes términos y
          condiciones, sus modificaciones o apartados; solicitudes de alguna autoridad legal o
          gubernamental; pedido expreso de registrado; cuestiones de seguridad informática,
          jurídica, persona, etc.; periodos de inactividad; entre otras, todo lo cual queda a
          discreción pura y exclusiva de SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          La baja de la cuenta del ORGANIZADOR traerá aparejada la prohibición de acceso al servicio
          brindado por SPORTMETRIC, como así también la baja de todo el contenido publicado
          oportunamente por la cuenta cancelada.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR acepta y reconoce que SPORTMETRIC no será responsable por ello, y nada
          tendrá por responder frente a él ni a terceros.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGECIMOPRIMERO: EXCENCION DE GARANTIAS POR PARTE DE SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC no garantiza, y así lo acepta el ORGANIZADOR, que la plataforma no sufra
          interrupciones o errores momentáneos, los cuales se compromete a solucionarlos lo más
          rápido posible dentro de sus posibilidades; ni el asesoramiento constante a el
          ORGANIZADOR; como así tampoco a que se alcancen las ventas esperadas por el ORGANIZADOR.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMOSEGUNDO: ACEPTACIÓN DE LOS PRESENTES TERMINOS Y CONDICIONES.
        </Typography>
        <Typography className={classes.text}>
          El ORGANIZADOR presta conformidad, de manera libre y voluntaria, a los presentes términos
          y condiciones, dejando expresa constancia que es su voluntad someterse a ellos, habiendo
          entendido los mismos.
        </Typography>
        <Typography className={classes.text}>
          Sin perjuicio de ello, y por fuera de esto, el ORGANIZADOR y SPORTMETRIC podrán pactar en
          caso de necesitarlo, un contrato privado entre partes, distinto, o bien anexos y
          aclaraciones al presente.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMOTERCERO: DOMICILIO
        </Typography>
        <Typography className={classes.text}>
          Se fija como domicilio de SPORTMETRIC en “Las Espuelas 1473 - BAJO LA VIÑA”, Ciudad de San
          Salvador de Jujuy, Provincia Jujuy, República Argentina.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMOCUARTO: VIGENCIA Y CAMBIOS EN LOS TERMINOS y CONDICIONES
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC se reserva el derecho de hacer modificaciones, reemplazar, remover o extender
          los presentes Términos y Condiciones en cualquier momento y hacerlas entrar en vigencia de
          manera inmediata sin la obligación de dar previo aviso.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMOQUINTO: JURISDICCION Y LEY APLICABLE.
        </Typography>
        <Typography className={classes.text}>
          Los presentes Términos y Condiciones se regirán en lo sucesivo por las Leyes vigentes en
          la República Argentina.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, en el caso de existir cualquier conflicto que derive del presente, su contenido,
          interpretación, alcance o cumplimiento, será sometido exclusivamente a los Tribunales
          Ordinarios de la Ciudad de San Salvador de Jujuy, renunciando el ORGANIZADOR a cualquier
          otra jurisdicción aplicable al caso concreto.
        </Typography>
      </div>
    </div>
  )
}

export default TermsAndConditionsOrganizationContent
