import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { USER_ROLE } from 'utils/constants'

import useStyles from './TabList.style'

const TabList = ({ tabLabels, onSelectTab, selectedTab, mini }) => {
  const location = useLocation()

  const { activeRole } = useSelector((state) => state.app)
  const renderDrop = location.pathname === ROUTES.ROOT

  const color = activeRole === USER_ROLE.ORGANIZER ? 'secondary' : 'primary'

  const renderDropdown = () => {
    return (
      <div className={clsx(mini ? classes.tabDropdownListMini : classes.tabDropdownList, color)}>
        <select
          onChange={(e) => onSelectTab(parseInt(e.target.value))}
          value={selectedTab}
          className={clsx(mini ? classes.tabDropdownMini : classes.tabDropdown, color)}>
          {tabLabels.map((label, index) => (
            <option key={index} value={index}>
              {label}
            </option>
          ))}
        </select>
      </div>
    )
  }

  const renderFull = () => {
    return (
      <>
        <div className={clsx(classes.tabList, !renderDrop && classes.tabListMini)}>
          {tabLabels.map((label, index) => (
            <Button
              key={index}
              className={clsx(classes.tab, selectedTab === index && classes.tabSelected)}
              onClick={() => onSelectTab(index)}>
              <Typography variant='caption'>{label}</Typography>
            </Button>
          ))}
        </div>
        {renderDrop && renderDropdown()}
      </>
    )
  }

  const renderMini = () => {
    return renderDropdown()
  }

  const classes = useStyles()
  return mini ? renderMini() : renderFull()
}

export default TabList
