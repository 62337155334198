import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 2600,
    display: 'flex',

    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      height: 1750
    }
  },
  profileLoading: {
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      width: '55%',
      height: 500
    }
  },
  title: {
    width: '40%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '30%',
      height: 15
    },
    [theme.breakpoints.up('lg')]: {
      width: '20%'
    }
  },
  subtitle: {
    height: 10,
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      height: 15
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%'
    }
  },
  textsocial: {
    width: '25%',
    height: 10
  }
}))
