import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 6)
    }
  },
  titleContainer: {
    flex: '0 0 auto',
    '& h6': {
      fontWeight: 600
    }
  },
  title: {
    fontSize: '1.1rem'
  },
  table: {
    width: '100%',
    marginTop: 20
  },
  row: {
    '& th': {
      textAlign: 'left',
      fontWeight: 500,
      color: theme.palette.primary.main
    }
  },

  col: {
    height: 40
  },
  nameCol: {
    [theme.breakpoints.up('sm')]: {
      width: 448
    },
    '&.shared': {
      [theme.breakpoints.up('sm')]: {
        width: 250
      }
    }
  },
  link: {
    color: theme.palette.link.main,
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}))
