import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  sportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    marginBottom: theme.spacing()
  },
  sport: {
    textTransform: 'uppercase',
    fontSize: '.9rem',
    fontWeight: 600
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem',
    cursor: 'pointer',

    '&.disabled': {
      color: '#696969',
      cursor: 'auto'
    }
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0),
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },

  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    transform: 'translate(14px, 13px) scale(1)'
  },
  select: {
    height: 48,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: 300
    },

    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(0.5)
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5,
      zIndex: 0
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    }
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column'
  },
  disciplinesContainer: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  saveButton: {
    width: 200,
    height: 48,
    alignSelf: 'flex-start',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing()
  },
  combo: {
    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  comboInput: {
    marginBottom: theme.spacing(),

    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.helperText.main
    }
  }
}))
