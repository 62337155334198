import React from 'react'
import { Typography } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './AdministratorPermissionDialog.style'

const AdministratorPermissionDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      containerClassName={classes.container}
      contentClassName={classes.bodyContainer}
      onClose={onClose}
      title='¿Qué puede hacer un colaborador asignado?'>
      <div className={classes.infoContainer}>
        <Typography className={classes.caption}>
          CON NOTIFICACION AL REFERENTE DE LA ORGANIZACION
        </Typography>
        <Typography className={classes.subtext}>
          Recibirás una notificación por correo electrónico de estas acciones porque las
          consideramos de gran importancia.
        </Typography>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Crear, suspender y cancelar evento.
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Habilitar formulario de inscripción de eventos con ticket pagos.
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Definir y modificar medios de pago.
          </Typography>
        </div>
        <Typography className={classes.caption}>
          SIN NOTIFICACION AL REFERENTE DE LA ORGANIZACION
        </Typography>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Editar información del evento: circuito, cronograma.
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Habilitar formulario de inscripción de eventos gratuitos.
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Editar formulario de inscripción: todo excepto medios de pago.
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>
            Administrar inscriptos (cambiar estado, controlar pagos).
          </Typography>
        </div>
        <div className={classes.option}>
          <CheckIcon className={classes.iconCheck} color='secondary' />
          <Typography className={classes.optionText}>Subir resultados.</Typography>
        </div>
        <Typography className={classes.finalText}>
          Una vez creada la cuenta se le enviara una invitación a los colaborador/es designados
        </Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default AdministratorPermissionDialog
