import React from 'react'
import { Button, CircularProgress, TextField, Typography } from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useStyle } from './ForgotPasswordForm.style'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Ingresa tu e-mail')
    .email('El email ingresado no posee un formato válido')
})

const ForgotPasswordForm = ({ onSubmit, recoveryError }) => {
  const classes = useStyle()
  const value = {
    email: ''
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={value}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid
        }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <Typography variant='caption' className={classes.label}>
              E-mail
            </Typography>
            <TextField
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email}
              error={touched.email && Boolean(errors.email)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
            />
            {recoveryError && (
              <Typography variant='caption' color='error' className={classes.errorMessage}>
                {recoveryError.message}
              </Typography>
            )}
            <div className={classes.buttonContainer}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!isValid || isSubmitting}>
                  Recuperar contraseña
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ForgotPasswordForm
