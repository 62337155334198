import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './SuccessCreateMercadoPagoAuthorizationDialog.style'

const SuccessCreateMercadoPagoAuthorizationDialog = ({ open, onAccept }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      title='VINCULACIÓN EXITOSA'
      open={open}
      className={classes.dialog}
      contentClassName={classes.container}>
      <main className={classes.mainContainer}>
        <Typography variant='h6' className={classes.title}>
          Has vinculado con éxito la cuenta de Mercado Pago, quedando habilitada para el pago Online
          de tu evento.
        </Typography>
        <Button color='primary' variant='contained' onClick={onAccept} className={classes.button}>
          Aceptar
        </Button>
      </main>
    </SectionLayoutDialog>
  )
}

export default SuccessCreateMercadoPagoAuthorizationDialog
