import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './NotificationDialog.style'

const DIALOG_STATES = {
  REPLACE_METHOD_NOTIFY: 'replaceMethodNotify',
  REPLACE_METHOD_CONFLICT_NOTIFY: 'replaceMethodConflictNotify',
  DELETE_METHOD_NOTIFY: 'deleteMethodNotify'
}

const NotificationDialog = ({
  open,
  onClose,
  state = DIALOG_STATES.DELETE_METHOD_NOTIFY,
  onConfirm,
  backDropClassName
}) => {
  const classes = useStyles()
  const { activeInscriptionForm } = useSelector((state) => state.events)

  const availableInscription =
    !!activeInscriptionForm &&
    !activeInscriptionForm.isDraft &&
    moment().isBetween(moment(activeInscriptionForm.openFrom), moment(activeInscriptionForm.openTo))

  return (
    <SectionLayoutDialog
      title='NOTIFICACION'
      open={open}
      onClose={onClose}
      className={classes.dialog}
      backDropClassName={backDropClassName}
      contentClassName={classes.container}>
      {state === DIALOG_STATES.DELETE_METHOD_NOTIFY && (
        <>
          <Typography color='primary' variant='h6' className={classes.textAlert}>
            No se puede deshabilitar el medio <br /> de pago porque se encuentra asociado a los
            <br /> tickets creados{availableInscription && ' y las inscripciones están abiertas'}.
          </Typography>
          <Typography color='primary' variant='h6' className={classes.subtext}>
            (Para poder realizar esta acción deberá primero habilitar otro medio de pago.)
          </Typography>
          <Button className={classes.button} color='primary' variant='contained' onClick={onClose}>
            Aceptar
          </Button>
        </>
      )}

      {state === DIALOG_STATES.REPLACE_METHOD_NOTIFY && (
        <>
          <Typography color='primary' variant='h6' className={classes.text}>
            Al cambiar la cuenta de Mercado Pago debes completar la nueva vinculación, de lo
            contrario sólo se mostrarán el/los tickets que contengan medios de pagos activos.
          </Typography>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={onConfirm}>
            Aceptar
          </Button>
        </>
      )}

      {state === DIALOG_STATES.REPLACE_METHOD_CONFLICT_NOTIFY && (
        <>
          <Typography color='primary' variant='h6' className={classes.text}>
            Al cambiar la cuenta de Mercado Pago debes completar la nueva vinculación, de lo
            contrario el formulario quedará deshabilitado hasta que concretes el proceso o actives
            otra forma de pago.
          </Typography>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={onConfirm}>
            Aceptar
          </Button>
        </>
      )}
    </SectionLayoutDialog>
  )
}

export default NotificationDialog
