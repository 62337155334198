import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Button, IconButton, TextField, Typography } from '@material-ui/core'
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Authenticated } from 'shared'

import { StyledSelect, useStyles } from './EditableMultipleSelection.style'

const EditableMultipleSelection = ({
  canEdit,
  entity,
  selectClassName,
  selectContainerClassName,
  field,
  children,
  subField,
  onSave,
  isMultiline,
  loadOptions,
  items,
  validation,
  type,
  mode,
  activeEditSection,
  placeholder,
  onChange,
  color = 'secondary',
  errors,
  noOptionsMessage,
  filterOption,
  linkEmpty,
  showLinkEmpty,
  showLink,
  onClickLink = () => {},
  resetOptions
}) => {
  const classes = useStyles()
  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  const [responseMessage, setResponseMessage] = useState('')

  useEffect(() => {
    setHeight(ref.current?.clientHeight)
  }, [setHeight])

  const resetValues = () => {
    let values

    if (!Array.isArray(items)) {
      values = isMultiline
        ? entity[field]?.map((e) => ({
            ...e,
            value: e.id,
            label: e[subField]
          }))
        : { value: entity[field].id, label: entity[field][subField] }
    } else {
      values =
        !isMultiline &&
        items.reduce(
          (acc, item) => ({
            ...acc,
            [item.value]: {
              value: entity[item.otherOption]
                ? item.otherOptionValue.value
                : entity[item.value] || item.optionEmpty.value,
              label: entity[item.otherOption]
                ? item.otherOptionValue.label
                : entity[item.label] || item.optionEmpty.label,
              otherOption: entity[item.otherOption] || ''
            }
          }),
          {}
        )
    }
    return values
  }

  const [editionComplete, setEditionComplete] = useState(true)
  const [values, setValues] = useState(resetValues())
  const [hideSelect, setHideSelect] = useState(showLinkEmpty)
  const [selectValue, setSelectValue] = useState()

  const handleSaveOptions = () => {
    if (!isMultiline) {
      items?.forEach((item, i) => {
        const field = item.value

        if (item.otherOption && values[field].value === item.otherOptionValue?.value) {
          entity[item.otherOption] = values[field].otherOption
          entity[item.value] = null
          entity[item.label] = null
        } else {
          entity[item.value] = values[field].value
          entity[item.label] = values[field].label
          entity[item.otherOption] = ''
        }
      })

      onSave({ value: entity })
    } else {
      entity[field] = values?.map((e) => ({ id: e.value, ...e }))
      onSave({ value: entity[field], field })
    }

    setEditionComplete(true)
  }

  const handleCancelSave = () => {
    if (resetOptions) resetOptions()

    setEditionComplete(true)
    setValues(resetValues())
  }

  const handleOnChange = (field, change) => async (options) => {
    if (isMultiline) {
      setValues(options)

      if (onChange) {
        onChange(field, options)
      }
    } else {
      const value = { ...options, otherOption: '' }

      const data = !!change && (await change(options.value, values))

      setValues((state) => ({ ...state, ...data, [field]: value }))
    }
  }

  const handleOtherOption = (e, field) => {
    values[field].otherOption = e.target.value
    setValues({ ...values })
  }

  const loadError = async () => {
    if (validation) {
      const response = await validation(values, type, field)
      setResponseMessage(response)
    }
  }

  loadError()

  const handleOnChangeSelect = () => (options) => setSelectValue(options)

  const handleAddOptionsSelect = (selectValue) => {
    setValues([...values, selectValue])
    setSelectValue(null)
    const disciplines = [...values, selectValue].map((d) => ({ id: d.value }))
    onChange(field, disciplines)
  }

  const handleRemoveOptionsSelect = (value) => {
    const disciplines = values.filter((d) => d.value !== value)
    setValues(disciplines)
    onChange(
      field,
      disciplines.map((d) => ({ id: d.value }))
    )
  }

  const editComplete = editionComplete && !activeEditSection

  return (
    <Authenticated>
      {editComplete ? (
        <div className={clsx(classes.editMode, height > 32 && classes.editModeAlign)} ref={ref}>
          <div className={classes.content}>
            {children}
            {showLink && (
              <a className={classes.link} onClick={onClickLink}>
                {linkEmpty}
              </a>
            )}
          </div>
          {canEdit && (
            <IconButton size='small' onClick={() => setEditionComplete(false)}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      ) : (
        <>
          {mode === 'full' ? (
            <div className={classes.container}>
              <div className={classes.actionContainer}>
                <div className={classes.selectContainer}>
                  <StyledSelect
                    defaultOptions
                    loadOptions={loadOptions}
                    value={selectValue}
                    name={field}
                    colorOptions={color}
                    placeholder={placeholder}
                    onChange={handleOnChangeSelect(selectValue)}
                    className={classes.select}
                    filterOption={(option) => !values.find((e) => e.value === option.value)}
                    noOptionsMessage={noOptionsMessage}
                  />
                  {errors && (
                    <Typography variant='caption' className={classes.errorMessage} color='error'>
                      {errors}
                    </Typography>
                  )}
                </div>
                <Button
                  color='primary'
                  disabled={!selectValue}
                  variant='contained'
                  className={classes.button}
                  onClick={() => handleAddOptionsSelect(selectValue)}>
                  Agregar
                </Button>
              </div>
              <div className={classes.disciplinesContainer}>
                {values.map(({ label, value }, index) => (
                  <div className={classes.disciplineContainer} key={index}>
                    <Typography>{label}</Typography>
                    <IconButton color='primary' onClick={() => handleRemoveOptionsSelect(value)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={classes.formGroup}>
              {!items ? (
                <div className={clsx(selectContainerClassName, 'activeEdit')}>
                  {linkEmpty && (
                    <a className={classes.linkEmpty} onClick={() => setHideSelect(false)}>
                      {linkEmpty}
                    </a>
                  )}
                  {!hideSelect && (
                    <>
                      <StyledSelect
                        isMulti={isMultiline}
                        cacheOptions
                        value={values}
                        loadOptions={loadOptions}
                        defaultOptions
                        name={field}
                        onChange={handleOnChange(field)}
                        placeholder={placeholder}
                        filterOption={filterOption}
                        colorOptions={color}
                        className={clsx(classes.select, selectClassName)}
                      />
                      {responseMessage && (
                        <Typography variant='caption' color='error'>
                          {responseMessage}
                        </Typography>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className={classes.selectContainer}>
                  {items.map((item, i) => {
                    const showSelect = (item.static && !!item.options?.length) || item.dynamic

                    return (
                      <div className={selectClassName} key={i}>
                        {showSelect && (
                          <>
                            <StyledSelect
                              isMulti={isMultiline}
                              cacheOptions
                              defaultOptions={item.options || true}
                              value={values[item.value]}
                              name={item.value}
                              loadOptions={item.load}
                              colorOptions={color}
                              onChange={handleOnChange(item.value, item.onChange)}
                              className={classes.select}
                            />
                            {item.otherOption &&
                              values[item.value].value === item.otherOptionValue.value && (
                                <TextField
                                  value={values[item.value].otherOption}
                                  variant='outlined'
                                  color='primary'
                                  autoFocus
                                  autoComplete='off'
                                  size='small'
                                  onChange={(e) => handleOtherOption(e, item.value)}
                                  className={classes.input}
                                />
                              )}
                            {errors && errors[item.value] && (
                              <Typography
                                variant='caption'
                                className={classes.error}
                                component='h6'
                                color='error'>
                                {errors[item.value]}
                              </Typography>
                            )}
                          </>
                        )}
                      </div>
                    )
                  })}
                </div>
              )}

              {!activeEditSection && (
                <>
                  <IconButton
                    disabled={
                      !!responseMessage ||
                      (!!errors && !!Object.values(errors).filter(Boolean).length)
                    }
                    color='primary'
                    onClick={handleSaveOptions}
                    size='small'>
                    <CheckCircleOutlineOutlinedIcon />
                  </IconButton>
                  <IconButton onClick={handleCancelSave} size='small'>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </>
              )}
            </div>
          )}
        </>
      )}
    </Authenticated>
  )
}

export default EditableMultipleSelection
