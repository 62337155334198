import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 950
    }
  },
  cardContainer: {
    paddingLeft: theme.spacing(4),
    '& > div': {
      height: 160
    }
  },
  slickSlider: {
    display: 'flex',
    '& > div': {
      [theme.breakpoints.up('md')]: {
        minWidth: '1000px !important'
      }
    },
    '& > div > div': {
      marginLeft: 0
    }
  }
}))
