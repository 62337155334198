import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '200px !important',
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600,
      margin: '0 auto'
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: '0 auto'
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    '&.dialogSubmitted': {
      gap: theme.spacing(2)
    }
  },
  title: {
    fontWeight: 600
  },
  textField: {
    '& input': {
      height: 19,
      fontSize: '.825rem',
      '&::placeholder': {
        fontSize: 14
      }
    }
  },
  helperText: {
    color: '#FF9D29',
    fontWeight: 600,
    fontSize: '.825rem',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  button: {
    alignSelf: 'center',
    width: 200,
    height: 40
  },
  pendingBindContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  pendingBindText: {
    color: '#FF9D29',
    fontSize: '.925rem',
    fontWeight: 600
  },
  eMail: {
    fontWeight: 600
  },
  copyButton: {
    height: 35,
    width: 35,
    '& svg': {
      height: 20,
      width: 20
    }
  },
  copyText: {
    fontWeight: 600,
    fontSize: '.825rem'
  }
}))
