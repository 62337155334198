import React from 'react'
import { Button, Typography } from '@material-ui/core'
import {} from '@material-ui/icons'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './WarningDeleteTicketDialog.style'

const WarningDeleteTicketDialog = ({ open, onClose, ticket }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      title='ELIMINAR TICKET'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography className={classes.title} color='primary' variant='h6'>
        No se puede eliminar del evento el ticket <br /> {ticket.name}, porque ya hay pre
        inscriptos/ inscriptos <br /> con ese ticket.
      </Typography>
      <div className={classes.buttonsContainer}>
        <Button color='primary' variant='contained' onClick={onClose} className={classes.button}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default WarningDeleteTicketDialog
