import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.contrastText, 0.6),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    boxShadow: 'none',

    [theme.breakpoints.up('md')]: {
      width: 1027,
      maxWidth: 1027,
      margin: '0 auto'
    }
  },
  container: {
    marginTop: theme.spacing(10),
    zIndex: '1000 !important'
  },
  content: {
    padding: 0
  },
  signInOptionsContainer: {
    width: 350,
    margin: '0 auto',
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(6)
  },
  googleLoginContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: theme.spacing(6, 0),

    '& > div > div > div > div': {
      borderRadius: 10,
      width: 210,
      border: `1px solid ${theme.palette.primary.main} !important`,

      '& > div > span': {
        color: theme.palette.primary.main,
        fontWeight: 500
      }
    }
  },
  signUpOptionsContainer: {
    width: '100%',
    height: 130,
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  signUpTitle: {
    fontSize: '1.25rem'
  },
  signUpButton: {
    borderRadius: 10,
    height: 46,
    width: 210,
    backgroundColor: theme.palette.primary.contrastText,

    '& span': {
      fontSize: '1rem'
    }
  }
}))
