import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { EditableSection } from 'shared'
import { searchDisciplines } from 'utils/functions'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './DisciplineSection.style'

const DisciplineSection = ({ event, onSave, ...sectionProps }) => {
  const classes = useStyles()

  const { disciplines: eventDisciplines } = event

  const [disciplines, setDisciplines] = useState([])

  const validationSchema = Yup.object().shape({
    disciplines: Yup.array().min(1, 'Debe tener agregada al menos una disciplina')
  })

  useEffect(async () => {
    setDisciplines(await searchDisciplines(''))
  }, [])

  const onDisciplinesChange = (setFieldValue) => (options) => setFieldValue('disciplines', options)

  const handleSaveDisciplines = async ({ value }) => {
    const disciplines = value.disciplines.map((x) => ({ id: x.value }))

    await onSave({ value: { disciplines } })
    return true
  }

  const values = {
    ...event,
    disciplines: event.disciplines.map((x) => ({ value: x.id, label: x.name }))
  }

  return (
    <EditableSection
      {...sectionProps}
      entity={values}
      validationSchema={validationSchema}
      onSave={handleSaveDisciplines}>
      {({
        activeEdit,
        values,
        setFieldValue,
        errors,
        isValid,
        setActiveEdit,
        canEdit,
        saveLoading,
        handleSubmit
      }) => {
        const options = disciplines.filter((x) => !eventDisciplines.some((d) => d.id === x.value))

        return (
          <SectionBlock title={'Disciplinas'}>
            {activeEdit ? (
              <FormControl variant='outlined' className={classes.formControl}>
                <Autocomplete
                  multiple
                  id='disciplinesSelect-autocomplete'
                  options={disciplines}
                  getOptionLabel={(option) => option.label}
                  selectOnFocus
                  onChange={(event, optionSelected) =>
                    onDisciplinesChange(setFieldValue)(optionSelected)
                  }
                  clearText='Quitar todo'
                  openText='Abrir desplegable'
                  closeText='Cerrar desplegable'
                  getOptionSelected={(x) => values.disciplines.some((o) => o.value === x.value)}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.label}
                        key={option.value}
                        title={option.label}
                        {...getTagProps({ index })}
                        color='primary'
                        className={classes.option}
                      />
                    ))
                  }
                  value={values.disciplines}
                  className={classes.combo}
                  loadingText={<Typography align='center'> Cargando...</Typography>}
                  noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.comboInput}
                      name='disciplines'
                      label='Seleccione Deporte/ Disciplina'
                      variant='outlined'
                      error={Boolean(errors.disciplines)}
                      helperText={errors.disciplines || 'Escriba el nombre de la disciplina'}
                    />
                  )}
                />
                <Button
                  color='primary'
                  type='submit'
                  variant='contained'
                  disabled={saveLoading || !isValid}
                  onClick={handleSubmit}
                  endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
                  className={classes.saveButton}>
                  Guardar
                </Button>
              </FormControl>
            ) : (
              <div className={classes.sportsContainer}>
                {eventDisciplines.map((d) => (
                  <Typography className={classes.sport} key={d.id}>
                    {d?.name}
                  </Typography>
                ))}
              </div>
            )}

            {canEdit && !activeEdit && !!options.length && (
              <Typography
                color='primary'
                variant='h6'
                className={classes.link}
                onClick={() => setActiveEdit(true)}>
                + Añadir disciplina
              </Typography>
            )}
          </SectionBlock>
        )
      }}
    </EditableSection>
  )
}

export default DisciplineSection
