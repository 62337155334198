import React from 'react'
import clsx from 'clsx'

import { useStyles } from './PaymentBlock.style'

const PaymentBlock = ({ children, className }) => {
  const classes = useStyles()

  return <div className={clsx(classes.container, className)}>{children}</div>
}

export default PaymentBlock
