import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  welcomeText: {
    margin: theme.spacing(2)
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '90%',
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  input: {
    margin: 0,
    marginBottom: theme.spacing(3),

    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  label: {
    color: theme.palette.primary.main,

    fontWeight: 500,
    fontSize: '0.75rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem'
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '.9rem',
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    margin: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    '& button': {
      borderRadius: 10,
      height: 46,
      width: 210,
      '& span': {
        fontSize: '1rem'
      }
    }
  },
  bottomParagraph: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    margin: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '& > a': {
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  },
  infoText: {
    fontSize: '.875rem',
    fontWeight: 500
  }
}))

export default useStyles
