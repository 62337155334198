import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import { Typography } from '@material-ui/core'
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram-icon.svg'
import clsx from 'clsx'
import { ROUTES } from 'routes'

import useStyles from './Footer.style'

const Footer = ({ altMode }) => {
  const classes = useStyles()
  const location = useLocation()

  const showFooterEvent = (location) => {
    const route =
      location.pathname.includes(ROUTES.EVENTS.PROFILE) ||
      location.pathname.includes(ROUTES.EVENTS.INSCRIPTION)

    setShowFooterContent(route)
  }

  const [showFooterContent, setShowFooterContent] = useState(false)

  useEffect(() => {
    if (location) showFooterEvent(location)
  }, [showFooterContent, location])

  return (
    <div className={clsx(classes.container, altMode && classes.containerAlt)}>
      <div className={clsx(classes.innerContainer, showFooterContent && 'showFooterContent')}>
        <div className={classes.logo} />
        {!showFooterContent && (
          <div className={classes.footerBlock}>
            <ul className={classes.list}>
              <li>
                <Typography color='primary' variant='h6'>
                  CONTACTANOS
                </Typography>
              </li>
              <li>
                <a
                  href='https://wa.me/5493885205251?text=Hola%20quisiera%20mas%20informacion%20sobre%20el%20producto'
                  target='_blank'
                  rel='noopener noreferrer'>
                  +54 9 388 520 5251
                </a>
              </li>
              <li>
                <a href='mailto:info@sportmetric.net' target='_blank' rel='noopener noreferrer'>
                  info@sportmetric.net
                </a>
              </li>
            </ul>
            <div className={classes.followContainer}>
              <Typography color='primary'>SEGUINOS EN</Typography>
              <a
                href='https://www.instagram.com/sportmetric.oficial/'
                target='_blank'
                className={classes.instagramIcon}
                rel='noopener noreferrer'>
                <InstagramIcon />
              </a>
              <SocialIcon
                className={classes.facebookIcon}
                network='facebook'
                bgColor='transparent'
                fgColor='#0600ad'
                url='https://www.facebook.com/sportmetric.net/'
                target='_blank'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Footer
