import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Transition } from 'utils'

import { useStyles } from './LayoutDialog.style'

const LayoutDialog = ({
  open,
  onClose,
  title,
  className,
  titleClassName,
  children: content,
  contentClassName,
  hiddenHeader,
  fullScreen
}) => {
  const classes = useStyles()

  const [contentScrolling, setContentScrolling] = useState(false)

  const handleScrollStart = () => setContentScrolling(true)

  const handleScrollEnd = () => setContentScrolling(false)

  const handleScroll = () => {
    if (!contentScrolling) handleScrollStart()

    clearTimeout(handleScroll.timer)
    handleScroll.timer = setTimeout(() => {
      handleScrollEnd()
    }, 150)
  }

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: clsx(classes.dialog, className)
        }
      }}
      fullWidth
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      open={open}
      className={classes.container}>
      {!hiddenHeader && (
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.headerContainer}>
            <Typography
              color='primary'
              align='center'
              variant='h5'
              className={clsx(classes.title, titleClassName)}>
              {title}
            </Typography>
            {onClose && (
              <IconButton color='secondary' onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </DialogTitle>
      )}
      <DialogContent className={classes.dialogContent} onScroll={handleScroll}>
        <div className={clsx(classes.bodyContainer, contentClassName)}>
          {typeof content === 'function' ? content(contentScrolling) : content}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default LayoutDialog
