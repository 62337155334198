import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Step,
  StepConnector,
  StepIcon,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Instagram as InstagramIcon } from '@material-ui/icons'
import { ReactComponent as FinishLineIcon } from 'assets/icons/finish-line-icon.svg'
import { ReactComponent as MenRunnerIcon } from 'assets/icons/men-runner-icon.svg'
import { ReactComponent as WomanRunnerIcon } from 'assets/icons/woman-runner-icon.svg'
import clsx from 'clsx'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import { load, sendOnboardingInformation, updateOrganization } from 'state/modules/organizations'
import { URL_PATTERN } from 'utils/constants'
import { normalizeUrl, searchDisciplines } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './OnboardingStepper.style'

const STEPPER_QUESTIONS = {
  EVENT_EXPERIENCE: {
    QUESTION: '¿Tenés experiencia realizando eventos?',
    POSITION: 0
  },
  EVENT_DISCIPLINES: {
    QUESTION: '¿Cuáles son las disciplinas de tus eventos?',
    POSITION: 1
  },
  EVENT_COUNT: {
    QUESTION: '¿Cuántos eventos organizás o pensás organizar al año?',
    POSITION: 2
  },
  INTEGRATIONS_ORGANIZATION: {
    QUESTION: '¿Cuántas personas conforman el staff de la organización?',
    POSITION: 3
  },
  PARTICIPANTS_COUNT: {
    QUESTION: '¿Cuántas personas asisten a tu evento regularmente?',
    POSITION: 4
  },
  NEED_SUPPORT: {
    QUESTION: '¿En cuáles de estos aspectos necesitás soporte/ayuda?',
    POSITION: 5
  },
  THANKS_FOR_YOUR_CONTRIBUTION: {
    QUESTION: 'Muchas gracias por tus aportes',
    POSITION: 6
  },
  COMMENT: {
    QUESTION: '¿Querés comentarnos algo más sobre tu organización que creas relevante?',
    POSITION: 7
  }
}

const ASPECT_NEEDS = [
  'Promoción y difusión del evento.',
  'Gestión de inscripciones/ acreditaciones.',
  'Comunicación con los asistentes.',
  'Gestión de cobros y pagos.',
  'Formulación del Ranking (Puntos del campeonato).'
]

const OTHER_OPTION = 'Otras'

const OnboardingStepper = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { handleOrId } = useParams()
  const history = useHistory()
  const theme = useTheme()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { organization } = useSelector((state) => state.organizations)

  const [activeStep, setActiveStep] = useState(0)
  const [positionStep, setPositionStep] = useState(0)
  const [disciplines, setDisciplines] = useState([])

  const fetchDisciplines = async () => {
    const data = await searchDisciplines('')
    setDisciplines(data.map((x) => x.label).filter((x) => x !== OTHER_OPTION))
  }

  useEffect(() => {
    fetchDisciplines()
  }, [])

  const handleNextStep = (setFieldValue) => () => {
    const changedActiveStep = activeStep !== 1 || (activeStep === 1 && positionStep === 4)
    setActiveStep(changedActiveStep ? activeStep + 1 : activeStep)

    setPositionStep(positionStep + 1)
    setFieldValue('step', positionStep + 1)
  }

  useEffect(() => {
    dispatch(load(handleOrId))
  }, [handleOrId])

  const handleBackStep = (setFieldValue) => () => {
    const changedActiveStep = (activeStep === 1 && positionStep === 1) || activeStep === 2
    setActiveStep(changedActiveStep ? activeStep - 1 : activeStep)

    setPositionStep(positionStep - 1)
    setFieldValue('step', positionStep - 1)
  }

  const values = {
    eventExperience: '',
    eventDisciplinesSelect: [],
    eventCount: '',
    memberCount: '',
    eventParticipantsCount: '',
    needs: [],

    newDiscipline: '',
    comments: '',
    facebookUrl: '',
    youtubeUrl: '',
    instagramUrl: '',
    web: '',
    step: 0
  }

  const validation = Yup.object().shape({
    eventExperience: Yup.string().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.EVENT_EXPERIENCE.POSITION,
      then: (schema) => schema.required('Debe ingresar su experiencia realizando eventos')
    }),
    eventDisciplinesSelect: Yup.array().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.EVENT_DISCIPLINES.POSITION,
      then: (schema) =>
        schema.min(1, 'Debe escoger las disciplinas que podrian asociarse a sus eventos')
    }),
    eventCount: Yup.string().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.EVENT_COUNT.POSITION,
      then: (schema) => schema.required('Debe ingresar la cantidad de eventos realizados')
    }),
    memberCount: Yup.string().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.INTEGRATIONS_ORGANIZATION.POSITION,
      then: (schema) => schema.required('Debe ingresar la cantidad de miembros de su organización')
    }),
    eventParticipantsCount: Yup.string().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.PARTICIPANTS_COUNT.POSITION,
      then: (schema) =>
        schema.required('Debe ingresar la cantidad de participantes aproximado de los eventos')
    }),
    needs: Yup.array().when('step', {
      is: (step) => step === STEPPER_QUESTIONS.NEED_SUPPORT.POSITION,
      then: (schema) => schema.min(1, 'Debe escoger al menos 1 aspecto en lo que necesites ayuda')
    }),
    newDiscipline: Yup.string()
      .min(5, 'La disciplina debe tener al menos 5 caracteres')
      .max(50, 'La disciplina tiene un máximo de 50 caracteres')
      .matches(/^[a-z ]+$/i, 'La disciplina no debe tener caracteres especiales ni números')
      .trim(),
    facebookUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección facebook válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    instagramUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección instagram válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    youTubeUrl: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección youtube válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    ),
    web: Yup.string().test(
      'test-url',
      'La URL ingresada no corresponde con una dirección web válida',
      (value) => !value || URL_PATTERN.test(normalizeUrl(value))
    )
  })

  const handleEnabledAddDiscipline = (setFieldValue) => () =>
    setFieldValue('enableAddDiscipline', true)

  const handleAddNewDiscipline = (values, setValues, hasDisciplineText) => () => {
    if (!hasDisciplineText) return

    const isRepeat = disciplines.find(
      (x) => x.toLowerCase().trim() === values.newDiscipline?.toLowerCase().trim()
    )

    if (isRepeat) {
      showSnackbarError({ message: 'La disciplina ingresada ya se encuentra como opción.' })
      return
    }

    setDisciplines([...disciplines, values.newDiscipline])
    setValues((values) => ({
      ...values,
      eventDisciplinesSelect: [...values.eventDisciplinesSelect, values.newDiscipline],
      newDiscipline: '',
      enableAddDiscipline: false
    }))
  }

  const onSubmit = async ({
    eventExperience,
    eventDisciplinesSelect,
    eventCount,
    memberCount,
    eventParticipantsCount,
    needs,
    comments,
    facebookUrl,
    youtubeUrl,
    instagramUrl,
    web
  }) => {
    const organizationPayload = {
      ...organization,
      facebookUrl: facebookUrl.trim() ? normalizeUrl(facebookUrl.trim()) : organization.facebookUrl,
      youtubeUrl: youtubeUrl.trim() ? normalizeUrl(youtubeUrl.trim()) : organization.youtubeUrl,
      instagramUrl: instagramUrl.trim()
        ? normalizeUrl(instagramUrl.trim())
        : organization.instagramUrl,
      web: web.trim() ? normalizeUrl(web.trim()) : organization.web,
      comments: comments.trim()
    }

    const onboardingInformation = [
      {
        question: STEPPER_QUESTIONS.EVENT_EXPERIENCE.QUESTION,
        response: eventExperience,
        itemNumber: STEPPER_QUESTIONS.EVENT_EXPERIENCE.POSITION
      },
      {
        question: STEPPER_QUESTIONS.EVENT_DISCIPLINES.QUESTION,
        response: eventDisciplinesSelect.join(', '),
        itemNumber: STEPPER_QUESTIONS.EVENT_DISCIPLINES.POSITION
      },
      {
        question: STEPPER_QUESTIONS.EVENT_COUNT.QUESTION,
        response: eventCount,
        itemNumber: STEPPER_QUESTIONS.EVENT_COUNT.POSITION
      },
      {
        question: STEPPER_QUESTIONS.INTEGRATIONS_ORGANIZATION.QUESTION,
        response: memberCount,
        itemNumber: STEPPER_QUESTIONS.INTEGRATIONS_ORGANIZATION.POSITION
      },
      {
        question: STEPPER_QUESTIONS.PARTICIPANTS_COUNT.QUESTION,
        response: eventParticipantsCount,
        itemNumber: STEPPER_QUESTIONS.PARTICIPANTS_COUNT.POSITION
      },
      {
        question: STEPPER_QUESTIONS.NEED_SUPPORT.QUESTION,
        response: needs.join(' '),
        itemNumber: STEPPER_QUESTIONS.NEED_SUPPORT.POSITION
      },
      {
        question: STEPPER_QUESTIONS.COMMENT.QUESTION,
        response: comments.trim(),
        itemNumber: STEPPER_QUESTIONS.COMMENT.POSITION
      }
    ]

    const updateOrganizationSuccess = await dispatch(updateOrganization(organizationPayload))

    const sendOnboardingInformationSuccess = await dispatch(
      sendOnboardingInformation(organization.id, onboardingInformation)
    )

    if (updateOrganizationSuccess && sendOnboardingInformationSuccess)
      history.replace(`${ROUTES.ORGANIZATIONS.PROFILE}/${organization.handle}`)
  }

  const maxSteps = isDesktop ? 6 : 3
  const step = isDesktop ? positionStep : activeStep
  const socialSize = { height: 48, width: 48 }

  const handleClearField = (handleChange, setFieldValue, field) => (e) => {
    handleChange(e)
    setFieldValue(field, '')
  }

  return (
    <Formik
      initialValues={values}
      validationSchema={validation}
      isInitialValid={false}
      onSubmit={onSubmit}
      enableReinitialize>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        handleSubmit,
        isValid,
        isSubmitting,
        setFieldValue,
        setValues
      }) => (
        <form
          onSubmit={handleSubmit}
          className={clsx(
            classes.form,
            positionStep === STEPPER_QUESTIONS.THANKS_FOR_YOUR_CONTRIBUTION.POSITION && 'secondary'
          )}>
          {positionStep === STEPPER_QUESTIONS.EVENT_EXPERIENCE.POSITION && (
            <div className={classes.stepContainer}>
              <Typography variant='h6' color='primary' className={classes.stepTitle}>
                {STEPPER_QUESTIONS.EVENT_EXPERIENCE.QUESTION}
              </Typography>
              <RadioGroup
                aria-label='eventExperience'
                name='eventExperience'
                value={values.eventExperience}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.radioGroup}>
                <FormControlLabel
                  value='No, esta es la primera vez.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='No, esta es la primera vez.'
                />
                <FormControlLabel
                  value='Sí, he organizado al menos 2 eventos.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Sí, he organizado al menos 2 eventos.'
                />
                <FormControlLabel
                  value='Sí, tengo mucha experiencia. (más de 6 eventos)'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Sí, tengo mucha experiencia. (más de 6 eventos)'
                />
              </RadioGroup>
            </div>
          )}
          {positionStep === STEPPER_QUESTIONS.EVENT_DISCIPLINES.POSITION && (
            <div className={classes.stepContainer}>
              <div className={classes.titleStep}>
                <Typography variant='h6' color='primary' className={classes.stepTitle}>
                  {STEPPER_QUESTIONS.EVENT_DISCIPLINES.QUESTION}
                </Typography>
                <Typography color='primary' className={classes.stepSubTitle}>
                  Podés seleccionar más de una opción.
                </Typography>
              </div>
              <FormGroup className={classes.formGroup}>
                {disciplines.map((d, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={handleChange}
                        className={classes.control}
                        checked={!!values.eventDisciplinesSelect.find((x) => x === d)}
                        onBlur={handleBlur}
                        value={d}
                        name='eventDisciplinesSelect'
                        color='primary'
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={d}
                  />
                ))}
              </FormGroup>
              {!values.enableAddDiscipline ? (
                <Typography
                  color='primary'
                  variant='h6'
                  className={classes.addDisciplineLink}
                  onClick={handleEnabledAddDiscipline(setFieldValue)}>
                  +Agregar disciplina
                </Typography>
              ) : (
                <div className={classes.addDisciplineContainer}>
                  <TextField
                    color='primary'
                    variant='outlined'
                    autoFocus
                    name='newDiscipline'
                    label='Disciplina'
                    autoComplete='off'
                    onChange={handleChange}
                    value={values.newDiscipline}
                    onBlur={handleBlur}
                    error={touched.newDiscipline && Boolean(errors.newDiscipline)}
                    helperText={errors.newDiscipline}
                    className={clsx(classes.textField, classes.disciplineTextField)}
                  />
                  <Typography
                    className={clsx(
                      values.newDiscipline.trim() && !errors.newDiscipline
                        ? classes.addLink
                        : classes.addDisabledLink
                    )}
                    color='primary'
                    variant='h6'
                    onClick={handleAddNewDiscipline(
                      values,
                      setValues,
                      !!values.newDiscipline.trim() && !errors.newDiscipline
                    )}>
                    Añadir
                  </Typography>
                </div>
              )}
            </div>
          )}

          {positionStep === STEPPER_QUESTIONS.EVENT_COUNT.POSITION && (
            <div className={classes.stepContainer}>
              <Typography color='primary' variant='h6' className={classes.stepTitle}>
                {STEPPER_QUESTIONS.EVENT_COUNT.QUESTION}
              </Typography>
              <RadioGroup
                aria-label='eventCount'
                name='eventCount'
                onBlur={handleBlur}
                value={values.eventCount}
                onChange={handleChange}
                className={classes.radioGroup}>
                <FormControlLabel
                  value='Sólo uno.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Sólo uno.'
                />
                <FormControlLabel
                  value='Entre 2 y 4.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Entre 2 y 4.'
                />
                <FormControlLabel
                  value='5 o más.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='5 o más.'
                />
              </RadioGroup>
            </div>
          )}

          {positionStep === STEPPER_QUESTIONS.INTEGRATIONS_ORGANIZATION.POSITION && (
            <div className={classes.stepContainer}>
              <Typography color='primary' variant='h6' className={classes.stepTitle}>
                {STEPPER_QUESTIONS.INTEGRATIONS_ORGANIZATION.QUESTION}
              </Typography>
              <RadioGroup
                aria-label='memberCount'
                name='memberCount'
                value={values.memberCount}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.radioGroup}>
                <FormControlLabel
                  value='Sólo uno.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Sólo uno.'
                />
                <FormControlLabel
                  value='Entre 2 y 4.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Entre 2 y 4.'
                />
                <FormControlLabel
                  value='5 o más.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='5 o más.'
                />
              </RadioGroup>
            </div>
          )}

          {positionStep === STEPPER_QUESTIONS.PARTICIPANTS_COUNT.POSITION && (
            <div className={classes.stepContainer}>
              <Typography color='primary' variant='h6' className={classes.stepTitle}>
                {STEPPER_QUESTIONS.PARTICIPANTS_COUNT.QUESTION}
              </Typography>
              <RadioGroup
                aria-label='eventParticipantsCount'
                name='eventParticipantsCount'
                value={values.eventParticipantsCount}
                onChange={handleChange}
                className={classes.radioGroup}>
                <FormControlLabel
                  value='Aún no lo sé.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Aún no lo sé.'
                />
                <FormControlLabel
                  value='Menos de 200 personas.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Menos de 200 personas.'
                />
                <FormControlLabel
                  value='Entre 200 y 400 personas.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Entre 200 y 400 personas.'
                />
                <FormControlLabel
                  value='Más de 400 personas.'
                  className={classes.control}
                  classes={{ label: classes.formControlLabel }}
                  control={<Radio color='primary' />}
                  label='Más de 400 personas.'
                />
              </RadioGroup>
            </div>
          )}

          {positionStep === STEPPER_QUESTIONS.NEED_SUPPORT.POSITION && (
            <div className={classes.stepContainer}>
              <div className={classes.titleStep}>
                <Typography variant='h6' color='primary' className={classes.stepTitle}>
                  {STEPPER_QUESTIONS.NEED_SUPPORT.QUESTION}
                </Typography>
                <Typography color='primary' className={classes.stepSubTitle}>
                  Podés seleccionar más de una opción.
                </Typography>
              </div>
              <FormGroup className={classes.formGroup}>
                {ASPECT_NEEDS.map((n, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        className={classes.control}
                        checked={!!values.needs.find((x) => x === n)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name='needs'
                        value={n}
                        color='primary'
                      />
                    }
                    classes={{ label: classes.formControlLabel }}
                    label={n}
                  />
                ))}
              </FormGroup>
            </div>
          )}

          {positionStep <= STEPPER_QUESTIONS.NEED_SUPPORT.POSITION ? (
            <>
              <Stepper
                activeStep={step}
                className={classes.stepper}
                connector={
                  <StepConnector
                    className={classes.connector}
                    classes={{
                      completed: classes.activeConnector,
                      active: classes.activeConnector
                    }}
                  />
                }>
                {[1, 2, 3, 4, 5, 6]
                  .reduce((acc, x, index) => {
                    if (maxSteps === 6) return [...acc, x]

                    if (positionStep === 0 && x <= maxSteps) return [...acc, x]

                    const values =
                      positionStep !== STEPPER_QUESTIONS.NEED_SUPPORT.POSITION
                        ? [positionStep - 1, positionStep, positionStep + 1]
                        : [positionStep - 2, positionStep - 1, positionStep]

                    if (values.includes(index) && acc.length <= 3) return [...acc, x]

                    return acc
                  }, [])
                  .map((x, index) => (
                    <Step className={classes.step} key={x}>
                      <StepLabel
                        classes={{
                          iconContainer: classes.iconContainer
                        }}
                        StepIconProps={{
                          classes: {
                            root: classes.stepIcon,
                            active: classes.activeStep,
                            completed: classes.completedIcon
                          }
                        }}
                        StepIconComponent={(props) => (
                          <StepIcon
                            {...props}
                            icon={x}
                            active={props.active || props.completed}
                            completed={false}
                          />
                        )}>
                        {step === index && (
                          <>
                            <MenRunnerIcon
                              className={clsx(classes.menRunnerIcon, `position-${positionStep}`)}
                            />
                            <WomanRunnerIcon
                              className={clsx(classes.womanRunnerIcon, `position-${positionStep}`)}
                            />
                          </>
                        )}

                        {step === maxSteps - 1 && positionStep === 5 && step === index && (
                          <FinishLineIcon className={classes.finishLineIcon} />
                        )}
                      </StepLabel>
                    </Step>
                  ))}
              </Stepper>

              {!isDesktop && (
                <Typography color='primary' className={classes.stepHelperText} align='right'>
                  Paso {positionStep + 1} de 6
                </Typography>
              )}

              <div className={classes.actionsContainer}>
                <Button
                  onClick={handleNextStep(setFieldValue)}
                  variant='contained'
                  color='primary'
                  disabled={!isValid}
                  className={classes.nextButton}>
                  Siguiente
                </Button>

                {positionStep > STEPPER_QUESTIONS.EVENT_EXPERIENCE.POSITION && (
                  <Typography
                    onClick={handleBackStep(setFieldValue)}
                    variant='h6'
                    color='primary'
                    className={classes.backLink}>
                    Paso anterior
                  </Typography>
                )}
              </div>
            </>
          ) : (
            <div className={classes.finishContainer}>
              <div className={classes.backgroundImage} />
              <div className={classes.socialNetworkContainer}>
                <Typography
                  color='primary'
                  variant='h5'
                  align='center'
                  className={classes.socialNetworkTitle}>
                  Muchas gracias por tus aportes
                </Typography>
                <Typography color='primary' variant='h6' className={classes.socialNetworkSubtitle}>
                  Comparte tus redes para que más deportistas puedan seguirte:
                </Typography>

                <div className={classes.socialNetworkOptions}>
                  <FormGroup className={classes.finishFormControl}>
                    <div className={classes.formControl}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.control}
                            checked={values.webEnabled}
                            onChange={handleClearField(handleChange, setFieldValue, 'web')}
                            onBlur={handleBlur}
                            name='webEnabled'
                            color='primary'
                          />
                        }
                        classes={{ label: clsx(classes.formControlLabel, classes.webLabel) }}
                        label='Web'
                      />
                      {values.webEnabled && (
                        <TextField
                          color='primary'
                          variant='outlined'
                          autoFocus
                          name='web'
                          onChange={handleChange}
                          value={values.web}
                          onBlur={handleBlur}
                          error={touched.web && Boolean(errors.web)}
                          helperText={errors.web}
                          className={classes.textField}
                        />
                      )}
                    </div>
                    <div className={classes.formControl}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.control}
                            checked={values.facebookEnabled}
                            onChange={handleClearField(handleChange, setFieldValue, 'facebookUrl')}
                            onBlur={handleBlur}
                            name='facebookEnabled'
                            color='primary'
                          />
                        }
                        classes={{ label: classes.formControlLabel }}
                        label={
                          <SocialIcon
                            network='facebook'
                            style={socialSize}
                            bgColor='transparent'
                            fgColor={theme.palette.primary.main}
                            className={classes.facebookIcon}
                          />
                        }
                      />
                      {values.facebookEnabled && (
                        <TextField
                          color='primary'
                          autoFocus
                          variant='outlined'
                          name='facebookUrl'
                          onChange={handleChange}
                          value={values.facebookUrl}
                          onBlur={handleBlur}
                          error={touched.facebookUrl && Boolean(errors.facebookUrl)}
                          helperText={errors.facebookUrl}
                          className={classes.textField}
                        />
                      )}
                    </div>

                    <div className={classes.formControl}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.control}
                            checked={values.instagramEnabled}
                            onChange={handleClearField(handleChange, setFieldValue, 'instagramUrl')}
                            onBlur={handleBlur}
                            name='instagramEnabled'
                            color='primary'
                          />
                        }
                        classes={{ label: classes.formControlLabel }}
                        label={<InstagramIcon className={classes.instagramIcon} />}
                      />
                      {values.instagramEnabled && (
                        <TextField
                          color='primary'
                          variant='outlined'
                          autoFocus
                          name='instagramUrl'
                          onChange={handleChange}
                          value={values.instagramUrl}
                          onBlur={handleBlur}
                          error={touched.instagramUrl && Boolean(errors.instagramUrl)}
                          helperText={errors.instagramUrl}
                          className={classes.textField}
                        />
                      )}
                    </div>

                    <div className={classes.formControl}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.control}
                            checked={values.youtubeEnabled}
                            onChange={handleClearField(handleChange, setFieldValue, 'youtubeUrl')}
                            onBlur={handleBlur}
                            name='youtubeEnabled'
                            color='primary'
                          />
                        }
                        classes={{ label: classes.formControlLabel }}
                        label={
                          <SocialIcon
                            network='youtube'
                            style={socialSize}
                            bgColor='transparent'
                            className={classes.youtubeIcon}
                            fgColor={theme.palette.primary.main}
                          />
                        }
                      />
                      {values.youtubeEnabled && (
                        <TextField
                          color='primary'
                          variant='outlined'
                          value={values.youtubeUrl}
                          name='youtubeUrl'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.youtubeUrl && Boolean(errors.youtubeUrl)}
                          helperText={errors.youtubeUrl}
                          autoFocus
                          className={classes.textField}
                        />
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
              <div className={classes.commentContainer}>
                <Typography color='primary' variant='h6' className={classes.commentTitle}>
                  ¿Querés comentarnos algo más sobre tu organización que creas relevante?
                </Typography>

                <TextField
                  variant='outlined'
                  color='primary'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name='comments'
                  multiline
                  className={classes.textArea}
                />
              </div>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                disabled={isSubmitting}
                endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
                className={classes.sendButton}>
                Enviar
              </Button>
            </div>
          )}
        </form>
      )}
    </Formik>
  )
}

export default OnboardingStepper
