import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700
    }
  },
  container: {
    width: '80%'
  },
  subTitle: {
    fontWeight: 500,
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  paragraph: {
    fontWeight: 500
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5)
  },
  buttonContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing()
  }
}))
