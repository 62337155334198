import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 760,
      maxWidth: 760
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    fontSize: '1rem',
    fontWeight: 600,
    '& span': {
      fontWeight: 500
    }
  },
  button: {
    alignSelf: 'center',
    borderRadius: 10,
    width: 180,
    height: 43
  },
  rolesParagraph: {
    fontSize: '.875rem',
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  checkContainer: {
    margin: theme.spacing(2, 0)
  },
  checkboxRoles: {
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}))
