import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUnPublishedEvents } from 'state/modules/organizations'

import { EventCard } from '../EventCard'
import { SectionBlock } from '../SectionBlock'

const UnPublishedEventsSection = ({ organizationId }) => {
  const { unPublishedEvents } = useSelector((state) => state.organizations)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadUnPublishedEvents(organizationId))
  }, [dispatch, organizationId])

  return (
    !!unPublishedEvents.length && (
      <SectionBlock title={'Eventos no publicados'}>
        {unPublishedEvents.map((event) => (
          <EventCard key={event.id} event={event} />
        ))}
      </SectionBlock>
    )
  )
}

export default UnPublishedEventsSection
