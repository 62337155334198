import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    paddingTop: 0
  },
  dialog: {
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      width: '70% !important',
      height: 'calc(100% - 60px) !important',
      maxWidth: '70% !important'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      margin: '0 auto'
    }
  },
  container: {
    padding: 0,
    width: '100%'
  },
  headerContainer: {
    height: 77,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    marginLeft: theme.spacing(4)
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    fontSize: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },

  button: {
    '& span': {
      fontWeight: 500
    }
  },
  backButton: {
    padding: 15,
    alignSelf: 'center',
    marginBottom: theme.spacing(5),
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  }
}))
