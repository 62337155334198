import React from 'react'

import { FeatureCard } from '../FeatureCard'

import { useStyles } from './SectionFeature.style'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SectionFeature = () => {
  const classes = useStyles()

  const features = [
    {
      key: '1',
      title: 'Acreditá',
      mainText: 'Registrá los participantes',
      secondaryText: 'de la competencia en simples pasos o realizá la importación desde un excel.',
      imagePosition: '100%'
    },
    {
      key: '2',
      title: 'Cronometrá',
      mainText: 'Registrá los tiempos parciales y totales',
      secondaryText: 'de cada participante con solo un clic. ',
      backgroundPosition: 'bottom'
    },
    {
      key: '3',
      title: 'Compartí',
      mainText: 'Publicá las posiciones y los tiempos',
      secondaryText: 'en Sportmetric.net y en tus redes sociales fácilmente.',
      imagePosition: '100%'
    }
  ]
  return (
    <div className={classes.bodyContainer}>
      {features.map((e) => (
        <FeatureCard key={e.key} {...e} />
      ))}
    </div>
  )
}

export default SectionFeature
