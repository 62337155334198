import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import { Formik } from 'formik'
import { ManagementDialog } from 'pages/Events/InscriptionPage/components'
import { ConfirmDialog, MultiFormatSelector } from 'shared'
import { deleteCustomField, loadCustomFields, updateCustomField } from 'state/modules/organizations'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { ActionCustomFieldDialog } from '../ActionCustomFieldDialog'

import { useStyles } from './CustomFieldDialog.style'

const CustomFieldDialog = ({ organizationId, customFields, setFieldValue, values, onCreate }) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const { createCustomFieldError, updateCustomFieldError, deleteCustomFieldError } = useSelector(
    (state) => state.organizations
  )
  const dispatch = useDispatch()
  const defaultValue = {
    name: '',
    isRequired: 'Si',
    type: '',
    options: [],
    isPrivate: false,
    allowMultiselect: false,
    helperText: ''
  }
  const [selectedField, setSelectedField] = useState(defaultValue)
  const [openCustomFieldDialog, setOpenCustomFieldDialog] = useState(false)
  const [openInfoDialog, setOpenInfoDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const canCreateCustomField = customFields.length < 10

  const handleSave = async (value, { setErrors }) => {
    const updateField = {
      ...value,
      isRequired: value.isRequired === 'Si',
      options: (value.type === 'List' && value.options?.filter((e) => !!e.name)) || [],
      allowMultiselect: false
    }
    const updateSuccess = await dispatch(updateCustomField(organizationId, updateField))
    if (updateSuccess) {
      await dispatch(loadCustomFields(organizationId))
      setFieldValue(
        'customFields',
        values.customFields.map((e) =>
          e.eventCustomFieldId === value.id ? { ...e, eventCustomField: updateField } : e
        )
      )
      setSelectedField(defaultValue)
      showSnackbarSuccess('¡Campo personalizado actualizado con éxito!')
      return true
    } else {
      setErrors({
        name: 'El nombre del campo ya existe'
      })
      return false
    }
  }

  const handleDelete = async (value) => {
    await dispatch(deleteCustomField(organizationId, value.id))
    await dispatch(loadCustomFields(organizationId))
    setFieldValue(
      'customFields',
      values.customFields.filter((field) => field.eventCustomFieldId !== value.id)
    )
    setSelectedField(defaultValue)
    showSnackbarSuccess('¡Campo personalizado eliminado con éxito!')
    return true
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test(
        'unique',
        'El nombre del campo ya existe',
        (value) => !createCustomFieldError || value !== createCustomFieldError.name
      )
      .required('El nombre del campo es requerido')
      .trim()
      .max(100, 'El nombre del campo no puede tener más de 100 caracteres'),
    type: Yup.string().required('El tipo del campo es requerido'),
    options: Yup.array().when('type', {
      is: (type) => type === 'List',
      then: Yup.array()
        .test(
          'options',
          'Debe ingresar al menos una opción',
          (options) => !!options.filter((e) => !!e.name?.trim()).length
        )
        .of(
          Yup.object().shape({
            name: Yup.string().max(100, 'La opción no puede tener más de 100 caracteres')
          })
        )
    })
  })

  const handleCreate = async (value, { resetForm, setErrors }) => {
    if (canCreateCustomField) {
      const data = await onCreate(value)
      if (data) {
        resetForm()
      } else {
        setErrors({ name: 'El nombre del campo ya existe' })
      }
    }
  }

  const handleLabel = (type) => {
    switch (type) {
      case 'Text':
        return 'Texto'
      case 'Number':
        return 'Número'
      case 'Boolean':
        return 'Si/No'
      case 'List':
        return 'Lista desplegable'
      case 'File':
        return 'Archivo adjunto'
      default:
        return 'Texto'
    }
  }

  return (
    <ManagementDialog
      onChangeDialog={setOpenCustomFieldDialog}
      title='campos personalizados'
      open={openCustomFieldDialog}
      onClose={() => setOpenCustomFieldDialog(false)}>
      <ActionCustomFieldDialog
        open={openDialog}
        action={handleSave}
        asyncError={updateCustomFieldError}
        field={selectedField}
        title='Editar campo personalizado'
        actionName='Guardar'
        onClose={() => setOpenDialog(false)}
      />
      <ConfirmDialog
        openDialog={openInfoDialog}
        title='¡Límite de campos personalizados alcanzado!'
        type='info'
        titleClassName={classes.dialogTitle}
        message='Para crear un nuevo campo personalizado debe eliminar alguno de los ya existentes.'
        setOpen={setOpenInfoDialog}
        onAction={() => setOpenInfoDialog(false)}
      />
      <ActionCustomFieldDialog
        open={openDeleteDialog}
        className={classes.deleteDialogContainer}
        title='Eliminar campo personalizado'
        asyncError={deleteCustomFieldError}
        actionName='Eliminar'
        action={handleDelete}
        onClose={() => setOpenDeleteDialog(false)}
        field={{ ...selectedField, isRequired: selectedField.isRequired ? 'Si' : 'No' }}
      />

      <div className={classes.mainContainer}>
        <Formik
          enableReinitialize
          initialValues={defaultValue}
          onSubmit={handleCreate}
          validationSchema={validationSchema}>
          {({
            values,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            isValid,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className={classes.sectionBlock}>
              <div className={classes.sectionHeaderContainer}>
                <Typography variant='h6' color='primary'>
                  CREAR CAMPO PERSONALIZADO
                </Typography>
              </div>
              <div className={classes.sectionBodyContainer}>
                <div className={classes.formGroup}>
                  <Typography color='primary' variant='h6'>
                    Nombre*
                  </Typography>
                  <TextField
                    variant='outlined'
                    size='small'
                    autoFocus
                    name='name'
                    autoComplete='off'
                    helperText={errors.name}
                    error={touched.name && !!errors.name}
                    onBlur={handleBlur}
                    value={values.name}
                    onChange={handleChange}
                    placeholder='Escriba un nombre para su campo'
                    fullWidth
                  />
                </div>
                <div className={classes.formGroup}>
                  <MultiFormatSelector
                    entity={values}
                    onChange={setFieldValue}
                    touched={touched}
                    errors={errors}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.checksContainer}>
                  <div className={classes.formGroup}>
                    <Typography variant='h6' color='primary'>
                      ¿Este campo es obligatorio?
                    </Typography>
                    <RadioGroup
                      row
                      aria-label='required'
                      name='isRequired'
                      value={values.isRequired}
                      onChange={handleChange}>
                      <FormControlLabel value='Si' control={<Radio color='primary' />} label='Si' />
                      <FormControlLabel value='No' control={<Radio color='primary' />} label='No' />
                    </RadioGroup>
                  </div>
                  <div className={classes.formGroup}>
                    <FormControlLabel
                      className={classes.checkContainer}
                      label='Solo visible para el administrador'
                      control={
                        <Checkbox
                          checked={values.isPrivate}
                          onChange={handleChange}
                          name='isPrivate'
                          color='primary'
                        />
                      }
                    />
                  </div>
                </div>
                <div className={classes.createButtonContainer}>
                  <Button
                    disabled={!isValid || isSubmitting}
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      if (!canCreateCustomField) setOpenInfoDialog(true)
                    }}
                    className={classes.createButton}
                    type='submit'>
                    Crear
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
        <div className={classes.sectionBlock}>
          <div className={classes.sectionHeaderContainer}>
            <Typography variant='h6' color='primary'>
              ADMINISTRAR CAMPOS PERSONALIZADOS
            </Typography>
          </div>
          <div className={classes.sectionBodyContainer}>
            <table className={classes.table}>
              <tbody>
                {customFields.map((item) => (
                  <tr className={classes.row} key={item.id}>
                    <td className={classes.col}>{item.name}</td>
                    <td className={classes.col}>{handleLabel(item.type)}</td>
                    <td className={classes.col}>
                      <IconButton
                        color='primary'
                        onClick={() => {
                          setSelectedField({ ...item, isRequired: item.isRequired ? 'Si' : 'No' })
                          setOpenDialog(true)
                        }}>
                        <EditOutlinedIcon />
                      </IconButton>

                      <IconButton
                        color='primary'
                        onClick={() => {
                          setSelectedField(item)
                          setOpenDeleteDialog(true)
                        }}>
                        <DeleteOutlineIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ManagementDialog>
  )
}

export default CustomFieldDialog
