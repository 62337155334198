import React, { useEffect, useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, IconButton, Typography } from '@material-ui/core'
import {
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { loadEventDiscountCodes } from 'state/modules/events'
import { PRICE_FORMAT } from 'utils/constants'

import { CreateDiscountCodeDialog } from '../CreateDiscountCodeDialog'
import { DeleteDiscountCodeDialog } from '../DeleteDiscountCodeDialog'
import { DuplicateButton } from '../DuplicateButton'
import { DuplicateDiscountCodeDialog } from '../DuplicateDiscountCodeDialog'
import { EditDiscountCodeDialog } from '../EditDiscountCodeDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './DiscountCodeSection.style'

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const DiscountCodeSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event, eventDiscountCodes, eventDiscountCodesPending } = useSelector(
    (state) => state.events
  )

  const { values: formState } = useFormikContext()

  const [openCreateDiscountDialog, setOpenCreateDiscountDialog] = useState(false)
  const [duplicateDiscountCodeProps, setDuplicateDiscountCodeProps] = useState({ open: false })
  const [updateDiscountDialogProps, setUpdateDiscountDialogProps] = useState({ open: false })
  const [deleteDiscountDialogProps, setDeleteDiscountDialogProps] = useState({ open: false })

  const dateFormatted = (date) => moment(date).format('DD/MM/YYYY')

  useEffect(() => {
    dispatch(loadEventDiscountCodes(event.id))
  }, [])

  const handleDuplicateDiscountCode = (discountCode, index) => {
    const { id, name, ...code } = discountCode

    const newDiscountCode = {
      ...code,
      name: `Copia-de-${name}`
    }

    setDuplicateDiscountCodeProps({ discountCode: newDiscountCode, open: true, index })
  }

  const hasDiscountCodes = !!eventDiscountCodes.length
  const hasActiveTicketTypes = formState.ticketTypes.some((x) => x.isActive)

  return (
    <SectionBlock
      title='5 Códigos de descuento'
      body='Generá códigos de descuento para impulsar tus inscripciones.'>
      <div className={classes.container}>
        {!!eventDiscountCodes.length && (
          <table className={classes.table}>
            <thead>
              <tr className={classes.row}>
                <th className={classes.column}>Nombre</th>
                <th className={classes.column}>Porcentaje/Importe</th>
                <th className={classes.column}>Cantidad</th>
                <th className={classes.column}>Desde/Hasta</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {eventDiscountCodes
                .sort(
                  (a, b) =>
                    moment(a.validFrom).diff(moment(b.validFrom)) ||
                    moment(moment(a.validTo).format('YYYY-MM-DD')).diff(
                      moment(moment(b.validTo).format('YYYY-MM-DD'))
                    ) ||
                    a.name.localeCompare(b.name)
                )
                .map((x, index) => (
                  <tr key={index} className={classes.row}>
                    <td className={classes.bodyColumn}>{x.name}</td>
                    <td className={classes.bodyColumn}>
                      {x.discountType === DISCOUNT_TYPE.PERCENTAGE
                        ? `${x.percentage}%`
                        : numericFormatter(Number(x.fixedAmount).toString(), PRICE_FORMAT)}
                    </td>
                    <td className={classes.bodyColumn}>{x.totalCount}</td>
                    <td className={classes.bodyColumn}>{`${dateFormatted(
                      x.validFrom
                    )} al ${dateFormatted(x.validTo)}`}</td>
                    <td>
                      <IconButton
                        color='primary'
                        onClick={() =>
                          setUpdateDiscountDialogProps({ discountCode: x, open: true })
                        }>
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        color='primary'
                        onClick={() =>
                          setDeleteDiscountDialogProps({ discountCode: x, open: true })
                        }>
                        <DeleteIcon />
                      </IconButton>
                      <DuplicateButton
                        color='primary'
                        title='Duplicar código de descuento'
                        onDuplicate={() => handleDuplicateDiscountCode(x, index)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}

        {!hasDiscountCodes && (
          <div className={classes.emptyMessageContainer}>
            {eventDiscountCodesPending ? (
              <CircularProgress size={30} color='primary' />
            ) : (
              <Typography className={classes.emptyMessage}>
                Aún no has creado códigos de descuento.
              </Typography>
            )}
          </div>
        )}
        <DuplicateDiscountCodeDialog
          {...duplicateDiscountCodeProps}
          onClose={() => setDuplicateDiscountCodeProps({ open: false })}
        />
        <EditDiscountCodeDialog
          {...updateDiscountDialogProps}
          onClose={() => setUpdateDiscountDialogProps({ open: false })}
        />
        <DeleteDiscountCodeDialog
          {...deleteDiscountDialogProps}
          onClose={() => setDeleteDiscountDialogProps({ open: false })}
        />
        <Button
          color='primary'
          variant='contained'
          disabled={!hasActiveTicketTypes}
          className={classes.createCodeButton}
          onClick={() => setOpenCreateDiscountDialog(true)}>
          Crear código
        </Button>

        <CreateDiscountCodeDialog
          open={openCreateDiscountDialog}
          onClose={() => setOpenCreateDiscountDialog(false)}
        />
      </div>
    </SectionBlock>
  )
}

export default DiscountCodeSection
