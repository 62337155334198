import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, useMediaQuery } from '@material-ui/core'
import { loadPasteventsByMyOrganization } from 'state/modules/userAccounts'

import { EventContainer } from '../EventContainer'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './PastEventsByMyOrganizationSection.style'

const PastEventsByMyOrganizationSection = ({ myOrganization }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { pastEventsByMyOrganization } = useSelector((state) => state.userAccounts)

  useEffect(() => {
    if (myOrganization) dispatch(loadPasteventsByMyOrganization(myOrganization.id))
  }, [myOrganization])

  return (
    !!pastEventsByMyOrganization.length && (
      <SectionBlock title={!isDesktop && 'Eventos pasados de Mi organización'}>
        <div className={classes.mainContainer}>
          {isDesktop && (
            <Typography className={classes.title} color='primary' variant='h6'>
              Eventos pasados de Mi organización
            </Typography>
          )}
          <EventContainer
            events={pastEventsByMyOrganization}
            autoplay={true}
            isFinished={true}
            infinite={pastEventsByMyOrganization.length > 3}
          />
        </div>
      </SectionBlock>
    )
  )
}

export default PastEventsByMyOrganizationSection
