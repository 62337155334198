import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '380px !important',
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700,
      margin: '0 auto'
    }
  },
  container: {
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '65%',
      margin: '0 auto'
    }
  },
  text: {
    fontWeight: 700,
    textAlign: 'center',
    padding: 10
  },
  button: {
    alignSelf: 'center',
    width: 200,
    height: 40,
    marginTop: 20
  },
  subtext: {
    textAlign: 'center'
  },
  textAlert: {
    fontWeight: 700,
    textAlign: 'center',
    padding: 10
  }
}))
