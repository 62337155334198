import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import { Radio } from '@material-ui/core'
import { ROUTES } from 'routes'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { Banner } from '../Banner'

import { useStyles } from './BannerSection.style'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const BannerSection = () => {
  const classes = useStyles()
  const history = useHistory()

  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)

  const customPaging = (index) => (
    <Radio
      className={classes.radioButton}
      checked={currentSlideNumber === index}
      value={index}
      name='radio-buttons'
      color='primary'
    />
  )

  const settings = {
    className: classes.slickSlider,
    dots: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => setCurrentSlideNumber(next),
    customPaging
  }

  const banners = [
    {
      id: '1',
      title: '¡Publicar eventos deportivos en Sportmetric es gratis!',
      action: () => {
        history.push(ROUTES.LANDING.ORGANIZATION)
        trackEvent(ANALYTICS_EVENTS.ACCESS.LANDING_ORGANIZATION_FROM_BANNER)
      },
      titleButton: 'CONOCER MÁS'
    },
    {
      id: '2',
      title: 'Conocé nuestras herramientas de cronometraje.',
      action: () => history.push(ROUTES.LANDING.TIMING),
      titleButton: 'CONTRATAR PLAN'
    }
  ]

  return (
    <Slider {...settings}>
      {banners.map((banner) => (
        <Banner key={banner.id} {...banner} />
      ))}
    </Slider>
  )
}

export default BannerSection
