import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  container: {
    '& > div > div': {
      minHeight: 500
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(2)
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('lg')]: {
      width: '65%',
      margin: '0 auto'
    }
  },
  backButton: {
    padding: 15,
    alignSelf: 'center',
    marginBottom: theme.spacing(5),
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600,
      margin: '0 auto'
    }
  },
  button: {
    minWidth: 40,
    padding: '6px'
  }
}))
