import React from 'react'
import { TextField } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { normalizeUrl } from 'utils/functions'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './LinkInscriptionSection.style'

const LinkInscriptionSection = () => {
  const classes = useStyles()

  const { values, errors, handleChange, handleBlur, touched } = useFormikContext()

  const handleOnchangeUrl = (e) => {
    e.target.value = normalizeUrl(e.target.value)

    handleChange(e)
  }

  return (
    <SectionBlock title='Link de inscripción'>
      <TextField
        className={classes.textField}
        variant='outlined'
        color='primary'
        name='externalInscriptionUrl'
        value={values.externalInscriptionUrl}
        onChange={handleOnchangeUrl}
        onBlur={handleBlur}
        error={touched.externalInscriptionUrl && Boolean(errors.externalInscriptionUrl)}
        helperText={errors.externalInscriptionUrl}
      />
    </SectionBlock>
  )
}

export default LinkInscriptionSection
