import * as types from './types'

// InitialState
const initialState = {
  upcomingEvents: [],
  upcomingPageNumber: null,
  pastEvents: [],
  pastPageNumber: null,
  loadingUpcomingEvents: false,
  loadingPastEvents: false,
  error: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_UPCOMING_EVENTS_ATTEMPT:
      return {
        ...state,
        error: null,
        loadingUpcomingEvents: true
      }
    case types.LOAD_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        upcomingEvents: [...state.upcomingEvents, ...action.eventsPage.results],
        upcomingPageNumber: action.eventsPage.currentPage,
        loadingUpcomingEvents: false
      }
    case types.LOAD_UPCOMING_EVENTS_FAILURE:
      return {
        ...state,
        loadingUpcomingEvents: false,
        error: action.error
      }
    case types.RESET_UPCOMING_EVENTS:
      return {
        ...state,
        loadingUpcomingEvents: false,
        error: null,
        upcomingEvents: [],
        upcomingPageNumber: 1
      }
    case types.LOAD_PAST_EVENTS_ATTEMPT:
      return {
        ...state,
        error: null,
        loadingPastEvents: true
      }
    case types.LOAD_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        pastEvents: [...state.pastEvents, ...action.eventsPage.results],
        pastPageNumber: action.eventsPage.currentPage,
        loadingPastEvents: false
      }
    case types.LOAD_PAST_EVENTS_FAILURE:
      return {
        ...state,
        pending: false,
        loadingPastEvents: action.error
      }
    case types.RESET_PAST_EVENTS:
      return {
        ...state,
        loadingPastEvents: false,
        error: null,
        pastEvents: [],
        pastPageNumber: 1
      }
    default:
      return state
  }
}
