import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { CustomizedTooltip, SectionLayoutDialog } from 'shared'
import { updateEvent } from 'state/modules/events'

import { useStyles } from './ModalityEventDialog.style'

const EVENT_MODALITY_STATES = {
  FREE_MODALITY: {
    value: 'Free',
    label: 'GRATIS'
  },
  PAY_MODALITY: {
    value: 'Pay',
    label: 'PAGO'
  }
}

const EventModalityDialog = ({ open, onClose, setOpenSignServiceLetter }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const [eventModalityValue, setEventModalityValue] = useState(
    EVENT_MODALITY_STATES.FREE_MODALITY.value
  )

  const { user } = useSelector((state) => state.auth)
  const { event, updateEventPending } = useSelector((state) => state.events)

  const handleChange = (e) => {
    setEventModalityValue(e.target.value)
  }

  const handleUpdateEventState = async () => {
    const { organization } = event

    if (
      eventModalityValue === EVENT_MODALITY_STATES.PAY_MODALITY.value &&
      !organization.hasActiveContract
    ) {
      setOpenSignServiceLetter(true)
      onClose()
      return
    }

    const updateSuccess = await dispatch(
      updateEvent({
        ...event,
        isFree: eventModalityValue === EVENT_MODALITY_STATES.FREE_MODALITY.value
      })
    )

    if (updateSuccess) history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
  }

  const isAdminOwnerEvent = event.organization.createdById === user?.id
  const payModalityEventEnabled = isAdminOwnerEvent || event.organization.hasActiveContract

  return (
    <SectionLayoutDialog
      title='MODALIDAD DE EVENTO'
      open={open}
      onClose={onClose}
      className={classes.dialog}>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='gender'
          name='modalityEvent'
          value={eventModalityValue}
          onChange={handleChange}>
          <FormControlLabel
            value={EVENT_MODALITY_STATES.FREE_MODALITY.value}
            className={classes.formControlLabel}
            classes={{ label: classes.radioLabel }}
            control={<Radio color='primary' className={classes.radio} />}
            label={EVENT_MODALITY_STATES.FREE_MODALITY.label}
          />
          <FormLabel
            component='p'
            className={clsx(
              classes.label,
              eventModalityValue === EVENT_MODALITY_STATES.FREE_MODALITY.value && 'selected'
            )}>
            En esta modalidad, los tickets deben ser todos gratuitos, si tienes algún ticket con
            costo seleccioná la modalidad PAGO.
          </FormLabel>

          <FormControlLabel
            value={EVENT_MODALITY_STATES.PAY_MODALITY.value}
            className={classes.formControlLabel}
            disabled={!payModalityEventEnabled}
            classes={{ label: classes.radioLabel }}
            control={
              <Radio
                color='primary'
                className={clsx(classes.radio, !payModalityEventEnabled && 'disabled')}
                disabled={!payModalityEventEnabled}
              />
            }
            label={
              <Typography
                className={clsx(classes.radioLabel, !payModalityEventEnabled && 'disabled')}>
                {EVENT_MODALITY_STATES.PAY_MODALITY.label}
                {!payModalityEventEnabled && (
                  <CustomizedTooltip
                    position='top'
                    arrow
                    className={classes.tooltip}
                    arrowClassName={classes.arrowTooltip}
                    popperClassName={classes.viewPopper}
                    disabledClickAway
                    title='Para poder crear un evento pago, el referente de la organización debe firmar la carta de servicio'>
                    {({ handleTooltip }) => (
                      <IconButton onClick={handleTooltip} size='small'>
                        <InfoIcon color='primary' />
                      </IconButton>
                    )}
                  </CustomizedTooltip>
                )}
              </Typography>
            }
          />

          <FormLabel
            component='p'
            className={clsx(
              classes.label,
              eventModalityValue === EVENT_MODALITY_STATES.PAY_MODALITY.value && 'selected'
            )}>
            En esta modalidad, los tickets tienen un costo, aunque puedes ofrecer algunos tickets
            gratuitos.
          </FormLabel>
        </RadioGroup>
      </FormControl>

      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        disabled={updateEventPending}
        endIcon={updateEventPending && <CircularProgress size={16} color='primary' />}
        onClick={handleUpdateEventState}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default EventModalityDialog
