import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%'
    }
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& input': {
      height: 15
    },

    '& fieldset': {
      height: 40,
      borderColor: theme.palette.primary.main
    }
  },
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 60,

      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },
    padding: theme.spacing(3, 0),
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  radioContainer: {
    marginTop: theme.spacing()
  },
  customFileContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      minWidth: 40,
      height: 36,
      marginTop: 14
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%'
    }
  },
  link: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.85rem',
    cursor: 'pointer',
    flexShrink: 0
  },
  attachIcon: {
    transform: 'rotate(45deg)',
    height: 20,
    width: 20
  },
  error: {
    marginTop: theme.spacing()
  }
}))
