import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    width: '100% !important',
    margin: 0
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: 700,
      margin: '0 auto'
    },
    zIndex: '5000 !important'
  },
  infoContainer: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      margin: '0 auto'
    },
    width: 350,
    margin: '0 auto'
  },
  caption: {
    fontSize: 16,
    fontWeight: 600,
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      marginLeft: 25
    }
  },
  subtext: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      marginLeft: 25
    }
  },
  iconCheck: {
    marginTop: theme.spacing(0.4),
    width: 20,
    height: 20
  },
  optionText: {
    fontWeight: 500,
    fontSize: 16
  },
  option: {
    display: 'flex',
    gap: theme.spacing(0.5),
    padding: '5px 0'
  },
  finalText: {
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      marginLeft: 25
    }
  }
}))
