import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, useMediaQuery } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { search } from 'state/modules/organizations'

import { useStyles } from './OrganizationListDialog.style'

const OrganizationListDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    searchOrganizations,
    searchOrganizationsLastPage,
    searchOrganizationsPageNumber,
    pending
  } = useSelector((state) => state.organizations)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  useEffect(() => {
    dispatch(search({ searchText: '', page: 1 }, 10, 'Name', 'ASC'))
  }, [])

  const nextOrganizations = () =>
    dispatch(
      search(
        {
          searchText: '',
          page: searchOrganizationsPageNumber + 1
        },
        10,
        'Name',
        'ASC'
      )
    )

  return (
    <SectionLayoutDialog
      open={open}
      mounted
      onClose={onClose}
      fullScreen={!isDesktop}
      title='LISTADO DE ORGANIZACIONES EXISTENTES'
      contentClassName={classes.bodyContainer}
      containerClassName={classes.container}>
      <div className={classes.content} id='scrollableDiv'>
        <InfiniteScroll
          dataLength={searchOrganizations.length}
          hasMore={!searchOrganizationsLastPage}
          next={nextOrganizations}
          scrollableTarget='scrollableDiv'
          style={{ overflow: 'inherit' }}
          className={classes.listContainer}>
          <table className={classes.table}>
            <thead>
              <tr className={classes.head}>
                {isDesktop ? (
                  <>
                    <th className={classes.headCol}>Nombre de Organización</th>
                    <th className={classes.headCol}>Alias</th>
                  </>
                ) : (
                  <th className={classes.headCol}>Nombre de Organización/ Alias</th>
                )}
              </tr>
            </thead>

            <tbody className={classes.tableBody}>
              <tr className={classes.row}></tr>
              {searchOrganizations.map((x) => (
                <tr key={x.id} className={classes.row}>
                  {isDesktop ? (
                    <>
                      <td className={classes.col} title={x.name}>
                        {x.name}
                      </td>
                      <td className={classes.col} title={`@${x.handle || x.id}`}>
                        @{x.handle || x.id}
                      </td>
                    </>
                  ) : (
                    <td className={classes.col} title={`${x.name} @${x.handle || x.id}`}>
                      {x.name}
                      <br />@{x.handle || x.id}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>

        {!searchOrganizationsLastPage && (
          <div className={classes.buttonContainer}>
            {!pending ? (
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={nextOrganizations}>
                Ver más
              </Button>
            ) : (
              <CircularProgress size={30} color='primary' />
            )}
          </div>
        )}
      </div>
    </SectionLayoutDialog>
  )
}

export default OrganizationListDialog
