import React, { useRef, useState } from 'react'
import { FacebookShareButton, WhatsappShareButton } from 'react-share'
import { Button, Dialog, DialogContent, IconButton, TextField, Typography } from '@material-ui/core'
import {
  Close as CloseIcon,
  Facebook as FacebookIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Share as ShareIcon,
  ShareOutlined as ShareOutlinedIcon,
  WhatsApp as WhatsAppIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { crc16 } from 'utils/functions'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './ShareUrlMenuDialog.style'

const ShareUrlMenuDialog = ({
  buttonClassName,
  isHiddenLabel,
  isOutlineIcon,
  suspended,
  title,
  type,
  copyMessage,
  updatedAt,
  slugUrl
}) => {
  const copyTxt = useRef(null)
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()
  const restUrl = `${process.env.REACT_APP_SHARE_BASE_URL}/${type}/${slugUrl}?${crc16(updatedAt)}`
  const shareUrl = `${restUrl}`

  const handleCopyUrl = () => {
    copyTxt.current.select()
    navigator.clipboard.writeText(shareUrl)

    showSnackbarSuccess(copyMessage)
  }

  return (
    <>
      <Button
        variant='outlined'
        className={clsx(
          classes.actionButton,
          suspended && 'suspended',
          buttonClassName,
          suspended && 'suspended'
        )}
        onClick={(e) => {
          setOpenDialog(true)
        }}
        title='Compartir'
        classes={{ label: classes.actionButtonLabel }}>
        {isOutlineIcon ? <ShareOutlinedIcon /> : <ShareIcon />}
        {!isHiddenLabel && <span className={classes.actionButtonLabelHidable}>Compartir</span>}
      </Button>

      <Dialog
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
        PaperProps={{
          classes: {
            root: classes.dialog
          }
        }}
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        className={classes.container}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title} title={title}>
            {title}
          </Typography>
          <IconButton onClick={() => setOpenDialog(false)}>
            <CloseIcon color='secondary' />
          </IconButton>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.shareContainer}>
            <Typography color='primary' variant='h6' className={classes.subtitle}>
              Copiar Link
            </Typography>
            <div className={classes.copyContainer}>
              <TextField
                color='primary'
                variant='outlined'
                type='text'
                autoComplete='off'
                value={shareUrl}
                inputRef={copyTxt}
                onFocus={(e) => e.currentTarget.select()}
                className={classes.textField}
              />
              <IconButton onClick={handleCopyUrl} title={'Copiar link al portapapeles'}>
                <FileCopyOutlinedIcon color='primary' />
              </IconButton>
            </div>
            <Typography color='primary' variant='h6' className={classes.subtitle}>
              Compartir
            </Typography>
            <div className={classes.shareButtonContainer}>
              <div className={classes.buttonContainer} title='Compartir en Facebook'>
                <FacebookShareButton
                  url={shareUrl}
                  hashtag='#Sportmetric'
                  className={classes.shareButton}>
                  <FacebookIcon />
                </FacebookShareButton>
              </div>
              <div className={classes.buttonContainer} title='Compartir en WhatsApp'>
                <WhatsappShareButton url={shareUrl} className={classes.shareButton}>
                  <WhatsAppIcon />
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShareUrlMenuDialog
