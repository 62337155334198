import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './EmptyCategoriesNotifyDialog.style'

const EmptyCategoriesNotifyDialog = ({ open, onClose, message }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      title='NOTIFICACIÓN'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography variant='h6' color='primary' className={classes.message} align='center'>
        {message}
      </Typography>
      <Button variant='contained' color='primary' onClick={onClose} className={classes.button}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default EmptyCategoriesNotifyDialog
