import axios from 'axios'

import BaseProxy from './BaseProxy'

export class SaleOrdersProxy extends BaseProxy {
  async post(saleOrder) {
    const response = await axios.post(`${this._baseUrl}/sale-orders`, saleOrder)
    return response.data
  }

  async putSetReceiptFile(id, orderPaymentId, file) {
    const formData = new FormData()
    formData.append('file', file)

    const response = await axios.put(
      `${this._baseUrl}/sale-orders/${id}/sale-order-payment/${orderPaymentId}/set-receipt-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  }

  async addSaleOrderPayment(id, saleOrderPayment) {
    const response = await axios.put(
      `${this._baseUrl}/sale-orders/${id}/add-sale-order-payment`,
      saleOrderPayment
    )

    return response.data
  }

  async getPreferenceUrl(id) {
    const response = await axios.get(`${this._baseUrl}/sale-orders/${id}/preference-url`)
    return response.data
  }

  async deleteSaleOrderPayment(id, saleOrderPaymentId) {
    const response = await axios.delete(
      `${this._baseUrl}/sale-orders/${id}/sale-order-payment/${saleOrderPaymentId}`
    )
    return response.data
  }

  async updateSaleOrderPayment(saleOrderPayment) {
    const response = await axios.put(
      `${this._baseUrl}/sale-orders/sale-order-payment/${saleOrderPayment.id}`,
      saleOrderPayment
    )
    return response.data
  }
}

export default SaleOrdersProxy
