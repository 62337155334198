import axios from 'axios'

import BaseProxy from './BaseProxy'

export class UserAccountsProxy extends BaseProxy {
  async register(userAccount) {
    const response = await axios.post(`${this._baseUrl}/user-accounts/register`, userAccount)

    return response.data
  }

  async registerFacebook(userAccount, accessToken) {
    const response = await axios.post(
      `${this._baseUrl}/user-accounts/register-facebook?accessToken=${accessToken}`,
      userAccount
    )

    return response.data
  }

  async registerGoogle(userAccount, accessToken) {
    const response = await axios.post(
      `${this._baseUrl}/user-accounts/register-google?accessToken=${accessToken}`,
      userAccount
    )

    return response.data
  }

  async existsUsernameEmail(username) {
    const response = await axios
      .post(`${this._baseUrl}/user-accounts/exists`, username)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
    return response.data
  }

  async existsIdNumber(idNumber, countryId) {
    const response = await axios.post(`${this._baseUrl}/user-accounts/exists-id-number`, {
      idNumber,
      countryId
    })

    return response.data
  }

  async sendRecoverInstructions(eMail) {
    const response = await axios.post(`${this._baseUrl}/user-accounts/recover`, eMail)

    return response.data
  }

  async resetPassword(resetPasswordModel) {
    const response = await axios.post(
      `${this._baseUrl}/user-accounts/reset-password`,
      resetPasswordModel
    )

    return response.data
  }

  async resendActivationEmail(email) {
    const response = await axios.post(`${this._baseUrl}/user-accounts/activation-email`, email)

    return response.data
  }

  async getByKey(key) {
    const response = await axios.get(`${this._baseUrl}/user-accounts/by-key/${key}`)

    return response.data
  }

  async get(id) {
    const response = await axios.get(`${this._baseUrl}/user-profiles/${id}`)

    return response.data
  }

  async post(userAccount) {
    const response = await axios.post(`${this._baseUrl}/user-accounts`, userAccount)

    return response.data
  }

  async put(id, userAccount) {
    const response = await axios.put(`${this._baseUrl}/user-profiles/${id}`, userAccount)

    return response.data
  }

  async putImage(id, file, type) {
    const formData = new FormData()
    formData.append('file', file, type)
    const response = await axios.put(
      `${this._baseUrl}/user-profiles/${id}/image?type=${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return response.data
  }

  async getSearch(searchObj, page, pageSize, orderBy, order) {
    const urlParams = this._makeUrlParams({
      ...searchObj,
      page,
      pageSize,
      orderBy,
      order
    })
    const response = await axios.get(`${this._baseUrl}/user-accounts/${urlParams}`)
    return response.data
  }

  async getSearchMetadata(searchObj) {
    const urlParams = this._makeUrlParams({ ...searchObj })
    const response = await axios.get(`${this._baseUrl}/user-accounts/search-metadata/${urlParams}`)
    return response.data
  }

  async getEventResultsPage(userId, page, pageSize) {
    const urlParams = this._makeUrlParams({ page, pageSize })
    const response = await axios.get(
      `${this._baseUrl}/user-accounts/${userId}/event-results/${urlParams}`
    )

    return response.data
  }

  async getResults(id) {
    const response = await axios.get(`${this._baseUrl}/user-accounts/${id}/event-results`)
    return response.data
  }

  async getOrganizations(id) {
    const response = await axios.get(`${this._baseUrl}/user-accounts/${id}/organizations`)
    return response.data
  }

  async getCalendarEvents(id) {
    const response = await axios.get(`${this._baseUrl}/events/in-calendar/${id}`)
    return response.data
  }

  async putFollow(id) {
    const response = await axios.put(`${this._baseUrl}/user-accounts/${id}/follow`)
    return response.data
  }

  async putUnfollow(id) {
    const response = await axios.put(`${this._baseUrl}/user-accounts/${id}/unfollow`)
    return response.data
  }

  async getFollowedUserAccounts(id) {
    const response = await axios.get(`${this._baseUrl}/user-accounts/${id}/followed-user-accounts`)
    return response.data
  }

  async getFollowedOrganizations(id) {
    const response = await axios.get(`${this._baseUrl}/user-accounts/${id}/followed-organizations`)
    return response.data
  }

  async getOrganizationsAdmin() {
    const response = await axios.get(`${this._baseUrl}/organizations/admin/all`)
    return response.data
  }

  async manualActivation(userAccount) {
    const response = await axios.post(
      `${this._baseUrl}/user-accounts/manual-activation`,
      userAccount
    )
    return response.data
  }

  async getUpcomingEventManagedBy(id) {
    const response = await axios.get(
      `${this._baseUrl}/user-accounts/${id}/upcoming-events-managed-by`
    )

    return response.data
  }

  async getUpcomingEventsByMyOrganization(organizationId) {
    const response = await axios.get(
      `${this._baseUrl}/user-accounts/upcoming-events-by-my-organization/${organizationId}`
    )

    return response.data
  }

  async getPastEventsByMyOrganization(organizationId) {
    const response = await axios.get(
      `${this._baseUrl}/user-accounts/past-events-by-my-organization/${organizationId}`
    )

    return response.data
  }

  async getUserAccountOrganizationSuggestions(searchText, page, pageSize) {
    const urlParams = this._makeUrlParams({ searchText, page, pageSize })

    const response = await axios.get(
      `${this._baseUrl}/user-accounts/user-account-organization-suggestions${urlParams}`
    )

    return response.data
  }
}

export default UserAccountsProxy
