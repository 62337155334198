import { makeStyles } from '@material-ui/styles'
import footerLogo from 'assets/img/icon_sportmetric_simple.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexShrink: 0,
    minHeight: 100,
    '&.medium': {
      minHeight: 150,
      [theme.breakpoints.up('lg')]: {
        minHeight: 115
      }
    }
  },
  logo: {
    backgroundImage: `url(${footerLogo})`,
    width: 90,
    height: 90,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    flexShrink: 0,
    marginTop: -100,
    '&.top': {
      marginTop: -35
    },
    '&.center': {
      marginTop: -70
    }
  },
  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'center',

    '& li': {
      fontWeight: 500,
      padding: theme.spacing(1, 2)
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontSize: '.9rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.1rem'
      }
    }
  },
  infoContactList: {
    display: 'flex',
    listStyle: 'none',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    paddingInlineStart: 0,
    color: theme.palette.primary.main,
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(0, 4),
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  footerBlock: {
    marginTop: theme.spacing(2),
    height: 150
  }
}))
