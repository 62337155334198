import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerMini: {
    width: '100%',
    height: 1300,
    display: 'flex',
    [theme.breakpoints.up('xs')]: {
      margin: '0 auto',
      width: 320
    },
    [theme.breakpoints.up('sm')]: {
      width: 600,
      margin: '0 auto'
    }
  },
  containerImg: {
    width: '100%',
    height: 300,
    zIndex: 10,
    padding: 30
  },
  backgroundImage: {
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 445
    }
  },
  organizationName: {
    width: 120,
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: 170
    }
  },
  dicipline: {
    width: 130,
    height: 8
  },
  nickName: {
    width: 90,
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: 140
    }
  },
  followers: {
    width: 200,
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '15%',
      height: 10
    }
  },
  title: {
    width: '40%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      height: 10
    }
  },
  subtitle: {
    width: '30%',
    height: 9,
    [theme.breakpoints.up('sm')]: {
      width: '15%',
      height: 9
    }
  },
  buttonFollow: {
    width: '25%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '10%',
      height: 10
    }
  },
  text: {
    width: '80%',
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      height: 8
    }
  },
  date: {
    width: '30%',
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: '6%'
    }
  },
  nameEvent: {
    width: '60%',
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: '30%'
    }
  },
  social: {
    width: '10%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '4%'
    }
  },
  container: {
    width: '100%',
    height: 1250,
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1200
    }
  }
}))
