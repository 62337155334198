import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import { acceptOrganization, load, rejectOrganization } from 'state/modules/events'
import { Transition } from 'utils'

import { useStyles } from './InvitationConfirmDialog.style'

const InvitationConfirmDialog = ({ open, onClose, organization }) => {
  const classes = useStyles()
  const { event } = useSelector((state) => state.events)
  const dispatch = useDispatch()

  const handleUpdateInvitation = (action) => async () => {
    await dispatch(action(event.id, organization.id))
    dispatch(load(event.id))
  }

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      TransitionComponent={Transition}
      open={open}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title}>
            Aceptación Organizador
          </Typography>
          <IconButton color='secondary' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContainer}>
          <Typography variant='h6' className={classes.subTitle}>
            <span>{event.organization.name}</span> te añadió como <span>organizador</span> del
            evento&nbsp;
            <span>{event.name}</span>, la aceptación implica:
          </Typography>
          <ul className={classes.infoText}>
            <li>
              <DoneIcon color='secondary' />
              &nbsp;Responsabilidad sobre toda la información publicada y aceptación del reglamento
              del evento.
            </li>
            <li>
              <DoneIcon color='secondary' />
              &nbsp;Acceso a la edición del mismo (detalle, ubicación, gestión de inscripciones,
              etc.)
            </li>
          </ul>
          <div className={classes.actionButton}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdateInvitation(rejectOrganization)}>
              Rechazar
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdateInvitation(acceptOrganization)}>
              Aceptar
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default InvitationConfirmDialog
