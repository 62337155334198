import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  socialLoginContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    width: '60%'
  },
  optionsMenu: {
    textAlign: 'center',
    width: '82%',
    margin: '20% auto',
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      width: '85%',
      margin: '8% auto'
    }
  },
  socialButton: {
    fontSize: '1.4rem',
    width: '100%',
    marginBottom: theme.spacing(3),
    fontWeight: 500,
    height: 50,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
      width: '80%'
    }
  },
  userContainer: {
    width: '80%',
    margin: '0 auto'
  },
  container: {
    paddingTop: '2%',
    minHeight: '70vh',
    [theme.breakpoints.up('xl')]: {
      paddingTop: '5%'
    }
  },
  userProfileContainer: {
    display: 'flex',
    flexGrow: 1
  },
  userOptionsMenu: {
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%',
    margin: '30px auto'
  },
  imagenIcon: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 60,
    height: 60,
    flexShrink: 0
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(4)
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2.5)
  },
  title: {
    fontSize: '1.4rem',
    textTransform: 'capitalize',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 350
  },
  subTitle: {
    fontSize: '1.1rem'
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(10)
  },
  buttonContainer: {
    textAlign: 'center'
  },
  socialUserButton: {
    filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.15))',
    padding: theme.spacing(1.5, 2),
    marginBottom: theme.spacing(2),
    '& svg': {
      width: 40,
      height: 40
    }
  },
  label: {
    fontWeight: 500
  },
  customTab: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4.5, 0)
    }
  },
  signOutLink: {
    fontSize: '1.1rem',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      fontWeight: 500,
      color: theme.palette.primary.main
    }
  }
}))
