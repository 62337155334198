import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 3500,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: 2500
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1100
    }
  },
  date: {
    width: '30%',
    height: 20
  },
  title: {
    width: '80%',
    height: 20,
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  subTitle: {
    height: 20,
    width: '60%',
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  section: {
    height: 16,
    width: '85%',
    [theme.breakpoints.up('lg')]: {
      width: '20%'
    }
  }
}))
