import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  footer: {
    minHeight: 95,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
      minHeight: 90
    }
  },
  developmentInfo: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  developmentText: {
    color: '#A9ABAE',
    fontWeight: 600,
    fontSize: '.75rem'
  },
  pinardLogo: {
    width: 87,
    height: 35
  }
}))
