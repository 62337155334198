import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import {
  createCategory,
  loadCategories,
  loadCategoriesCloneSuggestions,
  resetCategoriesCloneSuggestions
} from 'state/modules/events'
import { loadOrganizationsCloneSuggestions } from 'state/modules/organizations'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './CopyCategoryDialog.style'

const GENDER_LIST = {
  Male: 'Masculino',
  Female: 'Femenino',
  Other: 'Libre',
  NonBinary: 'No binario'
}

const DIALOG_STATE = {
  initial: 'COPIAR CATEGORÍAS DE UN EVENTO',
  copy: 'COPIAR CATEGORÍAS',
  conflict: 'CATEGORIAS DUPLICADAS'
}

const CopyCategoryDialog = ({ open, onClose, categories, formSetFieldValue }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { categoriesCloneSuggestions, categoriesCloneSuggestionsPending, event } = useSelector(
    (state) => state.events
  )
  const { organizationsCloneSuggestions } = useSelector((state) => state.organizations)

  const [selectedOption, setSelectedOption] = useState('')
  const [categoriesSelected, setCategoriesSelected] = useState({})
  const [copyCategoryState, setCopyCategoryState] = useState(DIALOG_STATE.initial)
  const [duplicateCategories, setDuplicateCategories] = useState([])
  const [selectedOrganization, setSelectedOrganization] = useState('')
  const [organizationSelectedConfirm, setOrganizationSelectedConfirm] = useState(false)
  const [createCategoriesPending, setCreateCategoriesPending] = useState(false)

  const someSeletedOption = Object.values(categoriesSelected).filter(Boolean).length

  useEffect(() => {
    dispatch(loadOrganizationsCloneSuggestions())

    return () => {
      dispatch(resetCategoriesCloneSuggestions())
    }
  }, [dispatch, event])

  const getEventCategoryOption = ({ event, eventCategories }) => {
    const fromEvent = moment(event.from).format('DD/MM/YYYY')
    const label = `${event.name} - ${fromEvent}`

    return {
      value: event.id,
      eventName: event.name,
      categories: eventCategories,
      labelText: label,
      label: (
        <Typography title={label} className={classes.option}>
          {label}
        </Typography>
      )
    }
  }

  const handleChangeOption = (handleChange) => (option) => handleChange(option)

  const filterEventCategories = async (inputValue) => {
    const selectOptions = (
      event && event.activeInscriptionFormId
        ? categoriesCloneSuggestions.filter((i) => i.event.id !== event.id)
        : categoriesCloneSuggestions
    ).map((form) => getEventCategoryOption(form))

    return selectOptions.filter((option) =>
      option.labelText.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const handleChangeDialogState = () => setCopyCategoryState(DIALOG_STATE.copy)

  const handleClose = () => {
    onClose()
    setCopyCategoryState(DIALOG_STATE.initial)
    setOrganizationSelectedConfirm(false)
    setSelectedOrganization('')
    setSelectedOption('')
    setCategoriesSelected({})
  }

  const handleChange = (option) => (e) => {
    const checked = e.target.checked

    setCategoriesSelected({
      ...categoriesSelected,
      [option.name]: checked ? option : null
    })
  }

  const getRangeAgeLabel = (category) => {
    if (category.ageNoLimit) return 'Sin límite'

    return `${category.ageRangeMin} a ${category.ageRangeMax} años`
  }

  const handleAllSelectOptions = (e) => {
    e.preventDefault()
    const allOptions = selectedOption.categories.reduce(
      (acc, category) => ({ ...acc, [category.name]: category }),
      {}
    )

    setCategoriesSelected(allOptions)
  }

  const handleAllRemoveOptions = (e) => {
    e.preventDefault()
    setCategoriesSelected({})
  }

  const tryCopyCategories = async (categoriesSelected, messageSuccess) => {
    const duplicateCategories = categories
      .filter((x) => !!categoriesSelected[x.name])
      .map((x) => ({ ...categoriesSelected[x.name] }))

    if (duplicateCategories.length) {
      setDuplicateCategories(duplicateCategories)
      setCopyCategoryState(DIALOG_STATE.conflict)
    } else {
      setCreateCategoriesPending(true)

      const newCategories = Object.values(categoriesSelected).filter(Boolean)

      for (const category of newCategories) {
        const copyCategory = {
          ...category,
          name: category.name.trim(),
          shortName: (category.shortName ?? '').trim()
        }

        await dispatch(createCategory(event.id, copyCategory))
      }

      setCreateCategoriesPending(false)
      handleClose()

      const data = await dispatch(loadCategories(event.id))
      formSetFieldValue('categories', data)

      showSnackbarSuccess(messageSuccess)
    }
  }

  const getCopyCount = (categoryName) => {
    if (!categories.some((x) => x.name.includes(categoryName) && x.name !== categoryName)) return 1

    return categories.reduce((acc, x) => {
      if (x.name.includes(categoryName) && x.name !== categoryName) {
        const { 0: key, 1: count = '' } = x.name.split('-')

        if (key === 'Copia' && count.match('^[0-9]+$') && +count > acc) return +count + 1
      }
      return acc
    }, 0)
  }

  const handleCopyDuplicateCategory = (copyDuplicate) => {
    for (const category of duplicateCategories) {
      delete categoriesSelected[category.name]

      if (copyDuplicate) {
        const copyCount = getCopyCount(category.name)
        const newDuplicateCategoryName = `Copia-${copyCount}-${category.name}`
        categoriesSelected[newDuplicateCategoryName] = {
          ...category,
          name: newDuplicateCategoryName.trim(),
          shortName: (category.shorName ?? '').trim()
        }
      }
    }

    const someCategory = Object.values(categoriesSelected).some(Boolean)

    if (someCategory) tryCopyCategories(categoriesSelected, 'Categoría/s copias con exito!')

    handleClose()
  }

  const filterOrganizationsCloneSuggestions = async (inputValue) =>
    organizationsCloneSuggestions
      .map((o) => ({ value: o.id, label: o.name, ...o }))
      .filter((x) => x.label.toLowerCase().includes(inputValue.toLowerCase()))

  const onConfirmSelectedOrganization = async () => {
    const responseSuccess = await dispatch(
      loadCategoriesCloneSuggestions(event.id, selectedOrganization.value)
    )

    setOrganizationSelectedConfirm(responseSuccess)
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={copyCategoryState !== DIALOG_STATE.conflict ? handleClose : null}
      className={classes.dialog}
      contentClassName={classes.content}
      title={copyCategoryState}>
      {copyCategoryState === DIALOG_STATE.initial && (
        <div className={classes.container}>
          {organizationSelectedConfirm ? (
            <>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6'>
                  Seleccione el evento del cual quiere copiar las categorías
                </Typography>
                <StyledSelect
                  name='event'
                  colorOptions='secondary'
                  value={selectedOption}
                  maxMenuHeight={168}
                  disabled
                  defaultOptions={categoriesCloneSuggestions.map((form) =>
                    getEventCategoryOption(form)
                  )}
                  onChange={handleChangeOption(setSelectedOption)}
                  loadOptions={filterEventCategories}
                  className={classes.select}
                  placeholder='Escribí el nombre del evento o selecciónalo del listado'
                />
              </div>
              <Button
                color='primary'
                variant='contained'
                className={classes.copyButton}
                onClick={handleChangeDialogState}
                disabled={!selectedOption}>
                Copiar
              </Button>
            </>
          ) : (
            <>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6'>
                  Seleccione el organizador del cual quiere copiar las categorías
                </Typography>
                <StyledSelect
                  defaultOptions={organizationsCloneSuggestions.map((o) => ({
                    value: o.id,
                    label: o.name,
                    ...o
                  }))}
                  name='organizations'
                  colorOptions='secondary'
                  value={selectedOrganization}
                  maxMenuHeight={168}
                  onChange={handleChangeOption(setSelectedOrganization)}
                  loadOptions={filterOrganizationsCloneSuggestions}
                  className={classes.select}
                  placeholder='Escribí el nombre del organizador o selecciónalo del listado'
                />
              </div>
              <Button
                color='primary'
                variant='contained'
                className={classes.copyButton}
                endIcon={
                  categoriesCloneSuggestionsPending && (
                    <CircularProgress color='primary' size={16} />
                  )
                }
                onClick={onConfirmSelectedOrganization}
                disabled={!selectedOrganization || categoriesCloneSuggestionsPending}>
                Seleccionar
              </Button>
            </>
          )}
        </div>
      )}

      {copyCategoryState === DIALOG_STATE.copy && (
        <div className={classes.container}>
          <div className={classes.subTitleContainer}>
            <Typography color='primary' variant='h6' className={classes.subTitle}>
              Seleccione las categorías que desea copiar del evento:
            </Typography>
            <Typography
              color='primary'
              variant='h6'
              title={selectedOption.eventName}
              className={classes.eventName}>
              {selectedOption.eventName}
            </Typography>
            {!someSeletedOption ? (
              <Link className={classes.link} to='/' onClick={handleAllSelectOptions}>
                Seleccionar todas
              </Link>
            ) : (
              <Link className={classes.link} to='/' onClick={handleAllRemoveOptions}>
                Limpiar selección
              </Link>
            )}
          </div>
          {selectedOption.categories.map((c) => (
            <div className={classes.checkContainer} key={c.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={!!categoriesSelected[c.name]}
                    onChange={handleChange(c)}
                    name={c.name}
                  />
                }
                className={classes.formControl}
                classes={{ label: classes.label }}
                label={c.name}
                title={c.name}
              />
              <Typography
                className={clsx(classes.label, classes.gender)}
                title={GENDER_LIST[c.gender]}
                align='center'>
                {GENDER_LIST[c.gender][0]}
              </Typography>
              <Typography
                className={clsx(classes.label, classes.rangeAgeLabel)}
                title={getRangeAgeLabel(c)}
                align='right'>
                {getRangeAgeLabel(c)}
              </Typography>
            </div>
          ))}
          <Button
            color='primary'
            variant='contained'
            disabled={!someSeletedOption || createCategoriesPending}
            endIcon={createCategoriesPending && <CircularProgress size={16} color='primary' />}
            className={clsx(classes.copyButton, 'right')}
            onClick={() => tryCopyCategories(categoriesSelected, 'Categorias copiadas con exito!')}>
            Copiar
          </Button>
        </div>
      )}

      {copyCategoryState === DIALOG_STATE.conflict && (
        <div className={classes.container}>
          <Typography color='primary' variant='h6' className={classes.question}>
            ¿Las siguientes categorías ya se encuentran en tu evento, queres duplicarlas?
          </Typography>
          <div className={classes.duplicateCategoriesContainer}>
            {duplicateCategories.map((c) => (
              <div key={c.id} className={classes.categoryItem}>
                <Typography title={c.name} className={classes.duplicateName}>
                  {c.name}
                </Typography>
                <Typography align='center' title={GENDER_LIST[c.gender]} className={classes.gender}>
                  {GENDER_LIST[c.gender][0]}
                </Typography>
                <Typography
                  align='right'
                  title={getRangeAgeLabel(c)}
                  className={classes.rangeAgeLabel}>
                  {getRangeAgeLabel(c)}
                </Typography>
              </div>
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => handleCopyDuplicateCategory(false)}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              disabled={createCategoriesPending}
              endIcon={createCategoriesPending && <CircularProgress size={16} color='primary' />}
              onClick={() => handleCopyDuplicateCategory(true)}>
              Duplicar
            </Button>
          </div>
        </div>
      )}
    </SectionLayoutDialog>
  )
}

export default CopyCategoryDialog
