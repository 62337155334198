import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(6)
  },
  linkFollowed: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    fontSize: '.9rem',
    cursor: 'pointer'
  }
}))
