import React from 'react'
import { Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './AddOrganizerRoleDialog.style'

const AddOrganizerRoleDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      containerClassName={classes.container}
      contentClassName={classes.bodyContainer}
      onClose={onClose}
      title='AGREGAR ROL ORGANIZADOR'>
      <div className={classes.infoContainer}>
        <Typography className={classes.textInitial}>
          Para poder crear una organización debes primero agregar en tu cuenta Sportmetric el rol
          organizador y luego en el menú seleccionar: <br />
          crear organización.
        </Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default AddOrganizerRoleDialog
