import React, { useEffect, useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { PaymentGatewayProxy, SaleOrdersProxy } from 'services'
import { BankAccountCard, PaymentLocationCard, SectionLayoutDialog } from 'shared'
import {
  loadActiveBankAccounts,
  loadActivePaymentLocations,
  publicUpdateEventInscription
} from 'state/modules/events'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './PaymentMethodDialog.style'

const PAYMENT_TYPES = {
  PaymentLocation: {
    value: 'PaymentLocation',
    label: 'EN EFECTIVO'
  },
  BankAccount: {
    value: 'BankAccount',
    label: 'TRANSFERENCIA'
  },
  MercadoPago: {
    value: 'MercadoPago',
    label: 'Mercado Pago'
  },
  PaymentUrl: {
    value: 'PaymentUrl',
    label: 'Link de Pago'
  }
}

const DIALOG_STATES = {
  PAYMENT_METHOD: {
    value: 'paymentMethod',
    title: 'MEDIOS DE PAGOS'
  },
  SELECT_PAYMENT_METHOD: {
    value: 'selectPaymentMethod',
    title: 'SELECCIÓN MEDIOS DE PAGO'
  },
  PAYMENT_LOCATION_DIALOG: {
    value: 'paymentLocation',
    title: 'PAGO EN EFECTIVO'
  },
  BANK_ACCOUNT_DIALOG: {
    value: 'bankAccount',
    title: 'PAGO CON TRANSFERENCIA'
  },
  MERCADO_PAGO_DIALOG: {
    value: 'mercadoPago',
    title: 'PAGAR CON MERCADO PAGO'
  },
  PAYMENT_URL_DIALOG: {
    value: 'paymentUrl',
    title: 'LINK DE PAGO'
  }
}

const PRICE_FORMAT = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '$'
}

const resetDialogState = (hasMultiplePaymentMethods, paymentType, activePaymentType) => {
  if (!activePaymentType) return DIALOG_STATES.SELECT_PAYMENT_METHOD
  else if (paymentType === PAYMENT_TYPES.MercadoPago.value) return DIALOG_STATES.MERCADO_PAGO_DIALOG
  else if (hasMultiplePaymentMethods) return DIALOG_STATES.PAYMENT_METHOD
  else if (paymentType === PAYMENT_TYPES.BankAccount.value) return DIALOG_STATES.BANK_ACCOUNT_DIALOG
  else if (paymentType === PAYMENT_TYPES.PaymentLocation.value)
    return DIALOG_STATES.PAYMENT_LOCATION_DIALOG
  else if (paymentType === PAYMENT_TYPES.PaymentUrl.value) return DIALOG_STATES.PAYMENT_URL_DIALOG
}

const PaymentMethodDialog = ({ open, onClose, activePaymentType }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    eventInscription,
    event,
    activeBankAccounts,
    activePaymentLocations,
    publicUpdateEventInscriptionPending
  } = useSelector((state) => state.events)

  const [updateSaleOrderPending, setUpdateSaleOrderPending] = useState(false)
  const [preferenceUrlPending, setPreferenceUrlPending] = useState(false)
  const [nextPaymentType, setNextPaymentType] = useState(eventInscription.paymentType)

  const { paymentType, idNumber, firstName, lastName, ticketTypeId, saleOrder } = eventInscription

  const ticketType = event.activeInscriptionForm.ticketTypes.find((x) => x.id === ticketTypeId)

  const hasMultiplePaymentMethods =
    [
      ticketType.mercadoPagoEnabled,
      ticketType.paymentLocationsEnabled,
      ticketType.bankAccountsEnabled,
      !!ticketType.paymentUrl
    ].filter(Boolean).length > 1

  const [dialogState, setDialogState] = useState(
    resetDialogState(hasMultiplePaymentMethods, paymentType, activePaymentType)
  )

  useEffect(() => {
    if (paymentType) {
      dispatch(loadActiveBankAccounts(event.id))
      dispatch(loadActivePaymentLocations(event.id))
    }
  }, [paymentType])

  const renderBankAccounts = (bankAccounts) => {
    const hasMultipleBankAccounts = bankAccounts.length > 1

    return bankAccounts.map((x, index) => (
      <BankAccountCard
        key={x.id}
        bankAccount={x}
        showCopyButton={true}
        title={`Cuenta bancaria ${hasMultipleBankAccounts ? index + 1 : ''}`}
      />
    ))
  }

  const renderPaymentLocations = (paymentLocations) => {
    const hasMultiplePaymentLocations = paymentLocations.length > 1

    return paymentLocations.map((x, index) => (
      <PaymentLocationCard
        key={x.id}
        paymentLocation={x}
        title={`Lugar de pago ${hasMultiplePaymentLocations ? index + 1 : ''}`}
      />
    ))
  }

  const handleOnClose = () => {
    onClose()
    setDialogState(resetDialogState(hasMultiplePaymentMethods, paymentType, activePaymentType))
  }

  const handleChangePaymentType = (state, paymentType) => async () => {
    setNextPaymentType(paymentType)

    const pubicUpdateSuccess = await dispatch(
      publicUpdateEventInscription({ ...eventInscription, paymentType: paymentType.value })
    )

    if (!pubicUpdateSuccess) return

    setDialogState(state)

    if (paymentType === PAYMENT_TYPES.MercadoPago)
      await handleMercadoPagoPayment(eventInscription.saleOrder)
  }

  const handleMercadoPagoPayment = async (values) => {
    setUpdateSaleOrderPending(true)

    const proxy = new PaymentGatewayProxy()
    const data = await proxy.postMercadoPagoPayment(values)

    setUpdateSaleOrderPending(false)
    return data
  }

  const handleGetPreferenceUrl = async () => {
    setPreferenceUrlPending(true)

    try {
      const proxy = new SaleOrdersProxy()
      const url = await proxy.getPreferenceUrl(eventInscription.saleOrder.id)

      return (window.location.href = url)
    } catch (error) {
      showSnackbarError(error)
      setPreferenceUrlPending(false)
    }
  }

  const getDescriptionFor = (paymentType) => (
    <Typography color='primary' variant='h6'>
      {paymentType === PAYMENT_TYPES.BankAccount.value &&
        `A continuación detallamos los datos de la cuenta Bancaria para realizar el pago de ${priceFormat(
          saleOrder?.totalAmount
        )}.`}
      {paymentType === PAYMENT_TYPES.PaymentLocation.value &&
        `A continuación, te mostramos los lugares donde puedes realizar el pago de ${priceFormat(
          saleOrder?.totalAmount
        )} en efectivo.`}
      {paymentType === PAYMENT_TYPES.PaymentUrl.value &&
        `A continuación te detallamos el link para realizar el pago de ${priceFormat(
          saleOrder?.totalAmount
        )}.`}
      <br />
      {paymentType === PAYMENT_TYPES.PaymentUrl.value
        ? 'No olvides subir la captura del comprobante en'
        : 'No olvides subir el comprobante de pago en'}
      <br />
      <strong>Estado de mi inscripción.</strong>
    </Typography>
  )

  const priceFormat = (price) =>
    numericFormatter(Number(price).toString(), {
      ...PRICE_FORMAT,
      fixedDecimalScale: !Number.isInteger(price)
    })

  return (
    <SectionLayoutDialog
      open={open}
      onClose={handleOnClose}
      title={dialogState.title}
      backDropClassName={classes.backDrop}
      className={clsx(
        dialogState.value !== DIALOG_STATES.MERCADO_PAGO_DIALOG.value && classes.dialog
      )}
      contentClassName={classes.container}>
      {dialogState === DIALOG_STATES.PAYMENT_METHOD && (
        <div className={classes.mainContainer}>
          {getDescriptionFor(paymentType)}

          <Typography color='primary' variant='h6' className={classes.paymentType}>
            {PAYMENT_TYPES[paymentType].label}
          </Typography>
          <div className={classes.paymentMethodContainer}>
            {paymentType === PAYMENT_TYPES.BankAccount.value &&
              renderBankAccounts(activeBankAccounts)}

            {paymentType === PAYMENT_TYPES.PaymentLocation.value &&
              renderPaymentLocations(activePaymentLocations)}

            {paymentType === PAYMENT_TYPES.PaymentUrl.value && (
              <a
                href={ticketType.paymentUrl}
                target='_blank'
                rel='noreferrer'
                className={classes.paymentUrl}>
                {ticketType.paymentUrl}
              </a>
            )}
          </div>

          {hasMultiplePaymentMethods && (
            <>
              <Typography color='primary' variant='h6' className={classes.changePaymentType}>
                Si quieres cambiar el medio de pago hacé click en la opción que prefieres.
              </Typography>

              <div className={classes.otherPaymentTypeContainer}>
                {event.eventPaymentLocationsEnabled &&
                  ticketType.paymentLocationsEnabled &&
                  PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.PaymentLocation && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.paymentTypeButton}
                      disabled={publicUpdateEventInscriptionPending}
                      endIcon={
                        publicUpdateEventInscriptionPending &&
                        nextPaymentType === PAYMENT_TYPES.PaymentLocation && (
                          <CircularProgress color='primary' size={16} />
                        )
                      }
                      onClick={handleChangePaymentType(
                        DIALOG_STATES.PAYMENT_LOCATION_DIALOG,
                        PAYMENT_TYPES.PaymentLocation
                      )}>
                      Pagar en efectivo
                    </Button>
                  )}

                {event.eventBankAccountsEnabled &&
                  ticketType.bankAccountsEnabled &&
                  PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.BankAccount && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.paymentTypeButton}
                      disabled={publicUpdateEventInscriptionPending}
                      endIcon={
                        publicUpdateEventInscriptionPending &&
                        nextPaymentType === PAYMENT_TYPES.BankAccount && (
                          <CircularProgress color='primary' size={16} />
                        )
                      }
                      onClick={handleChangePaymentType(
                        DIALOG_STATES.BANK_ACCOUNT_DIALOG,
                        PAYMENT_TYPES.BankAccount
                      )}>
                      Pagar con transferencia
                    </Button>
                  )}

                {event.mercadoPagoEnabled &&
                  ticketType.mercadoPagoEnabled &&
                  PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.MercadoPago && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.paymentTypeButton}
                      disabled={publicUpdateEventInscriptionPending}
                      endIcon={
                        publicUpdateEventInscriptionPending &&
                        nextPaymentType === PAYMENT_TYPES.MercadoPago && (
                          <CircularProgress color='primary' size={16} />
                        )
                      }
                      onClick={handleChangePaymentType(
                        DIALOG_STATES.MERCADO_PAGO_DIALOG,
                        PAYMENT_TYPES.MercadoPago
                      )}>
                      Pagar con Mercado Pago
                    </Button>
                  )}

                {!!ticketType.paymentUrl &&
                  PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.PaymentUrl && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.paymentTypeButton}
                      disabled={publicUpdateEventInscriptionPending}
                      endIcon={
                        publicUpdateEventInscriptionPending &&
                        nextPaymentType === PAYMENT_TYPES.PaymentUrl && (
                          <CircularProgress color='primary' size={16} />
                        )
                      }
                      onClick={handleChangePaymentType(
                        DIALOG_STATES.PAYMENT_URL_DIALOG,
                        PAYMENT_TYPES.PaymentUrl
                      )}>
                      Pagar con link de pago
                    </Button>
                  )}
              </div>
            </>
          )}
        </div>
      )}

      {dialogState === DIALOG_STATES.SELECT_PAYMENT_METHOD && (
        <div className={classes.selectPaymentContainer}>
          <div className={classes.selectPaymentHeader}>
            <Typography variant='h5' color='primary' className={classes.selectPaymentTitle}>
              Te listamos las opciones para realizar el pago.
              <br />
              Seleccioná la modalidad que prefieras.
            </Typography>
            <Typography variant='h6' color='primary' className={classes.selectPaymentHelperText}>
              Si pagas con transferencia o en efectivo deberás subir el comprobante.
            </Typography>
          </div>

          <div className={classes.otherPaymentTypeContainer}>
            {event.eventBankAccountsEnabled &&
              ticketType.bankAccountsEnabled &&
              PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.BankAccount && (
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.paymentTypeButton}
                  disabled={publicUpdateEventInscriptionPending}
                  endIcon={
                    publicUpdateEventInscriptionPending &&
                    nextPaymentType === PAYMENT_TYPES.BankAccount && (
                      <CircularProgress color='primary' size={16} />
                    )
                  }
                  onClick={handleChangePaymentType(
                    DIALOG_STATES.BANK_ACCOUNT_DIALOG,
                    PAYMENT_TYPES.BankAccount
                  )}>
                  Transferencia
                </Button>
              )}

            {event.eventPaymentLocationsEnabled &&
              ticketType.paymentLocationsEnabled &&
              PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.PaymentLocation && (
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.paymentTypeButton}
                  disabled={publicUpdateEventInscriptionPending}
                  endIcon={
                    publicUpdateEventInscriptionPending &&
                    nextPaymentType === PAYMENT_TYPES.PaymentLocation && (
                      <CircularProgress color='primary' size={16} />
                    )
                  }
                  onClick={handleChangePaymentType(
                    DIALOG_STATES.PAYMENT_LOCATION_DIALOG,
                    PAYMENT_TYPES.PaymentLocation
                  )}>
                  Pago en Efectivo
                </Button>
              )}

            {event.mercadoPagoEnabled &&
              ticketType.mercadoPagoEnabled &&
              PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.MercadoPago && (
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.paymentTypeButton}
                  disabled={publicUpdateEventInscriptionPending}
                  endIcon={
                    publicUpdateEventInscriptionPending &&
                    nextPaymentType === PAYMENT_TYPES.MercadoPago && (
                      <CircularProgress color='primary' size={16} />
                    )
                  }
                  onClick={handleChangePaymentType(
                    DIALOG_STATES.MERCADO_PAGO_DIALOG,
                    PAYMENT_TYPES.MercadoPago
                  )}>
                  Mercado Pago
                </Button>
              )}

            {!!ticketType.paymentUrl && PAYMENT_TYPES[paymentType] !== PAYMENT_TYPES.PaymentUrl && (
              <Button
                color='primary'
                variant='contained'
                className={classes.paymentTypeButton}
                disabled={publicUpdateEventInscriptionPending}
                endIcon={
                  publicUpdateEventInscriptionPending &&
                  nextPaymentType === PAYMENT_TYPES.PaymentUrl && (
                    <CircularProgress color='primary' size={16} />
                  )
                }
                onClick={handleChangePaymentType(
                  DIALOG_STATES.PAYMENT_URL_DIALOG,
                  PAYMENT_TYPES.PaymentUrl
                )}>
                Link de pago
              </Button>
            )}
          </div>
        </div>
      )}

      {dialogState === DIALOG_STATES.BANK_ACCOUNT_DIALOG && (
        <div className={classes.mainContainer}>
          <Typography color='primary' variant='h6' className={classes.title}>
            Realizá la transferencia por {priceFormat(saleOrder?.totalAmount ?? 0)}
            &nbsp;a la cuenta indicada y luego sube el comprobante en&nbsp;
            <strong>Estado de mi inscripción.</strong>
          </Typography>
          <Typography color='primary' variant='h6' className={classes.paymentType}>
            Detalle de la cuenta
          </Typography>
          <div className={clsx(classes.paymentMethodContainer, classes.paymentOptions)}>
            {renderBankAccounts(activeBankAccounts)}
          </div>
        </div>
      )}

      {dialogState === DIALOG_STATES.MERCADO_PAGO_DIALOG && (
        <div className={clsx(classes.mainContainer, classes.mercadoPagoPaymentCredencialContainer)}>
          <Typography variant='h6'>
            {firstName} {lastName} - DNI {idNumber}
          </Typography>
          <div className={classes.paymentText}>
            <Typography variant='h6'>
              El importe a pagar por tu inscripción es de&nbsp;
              {priceFormat(saleOrder?.totalAmount ?? 0)}, te redireccionaremos a Mercado Pago para
              completar la transacción.
            </Typography>
            <Typography color='primary' variant='caption' className={classes.helperText}>
              *El pago puede demorar hasta una hora en impactar en el estado de tu inscripción.
            </Typography>
          </div>

          <Button
            color='primary'
            variant='contained'
            className={classes.mercadoPagoPaymentButton}
            endIcon={
              (preferenceUrlPending || updateSaleOrderPending) && (
                <CircularProgress size={16} color='primary' />
              )
            }
            disabled={preferenceUrlPending || updateSaleOrderPending}
            onClick={handleGetPreferenceUrl}>
            Pagar
          </Button>
        </div>
      )}

      {dialogState === DIALOG_STATES.PAYMENT_LOCATION_DIALOG && (
        <div className={classes.mainContainer}>
          {getDescriptionFor(paymentType)}

          <Typography color='primary' variant='h6' className={classes.paymentType}>
            Lugares habilitados
          </Typography>
          <div className={clsx(classes.paymentMethodContainer, classes.paymentOptions)}>
            {renderPaymentLocations(activePaymentLocations)}
          </div>
        </div>
      )}

      {dialogState === DIALOG_STATES.PAYMENT_URL_DIALOG && (
        <div className={classes.mainContainer}>
          {getDescriptionFor(paymentType)}

          <Typography color='primary' variant='h6' className={classes.paymentType}>
            Link de pago
          </Typography>
          <div className={clsx(classes.paymentMethodContainer, classes.paymentOptions)}>
            <a
              href={ticketType.paymentUrl}
              target='_blank'
              rel='noreferrer'
              className={classes.paymentUrl}>
              {ticketType.paymentUrl}
            </a>
          </div>
        </div>
      )}
    </SectionLayoutDialog>
  )
}

export default PaymentMethodDialog
