import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 310
  },
  card: {
    width: 290,
    height: 310
  }
}))
