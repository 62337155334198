import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { BankAccountCard, SectionLayoutDialog } from 'shared'
import { createEventBankAccount, loadEventBankAccounts } from 'state/modules/events'

import { useStyles } from './CreateBankAccountDialog.style'

const CARD_STATES = {
  CARD_VIEW: 'cardView',
  CARD_FORM: 'cardForm'
}

const bankAccount = {
  alias: '',
  number: '',
  fullName: '',
  idNumber: '',
  bankId: '',
  isActive: true,
  state: CARD_STATES.CARD_FORM
}

const resetValues = () => [{ ...bankAccount }]

const CreateBankAccountDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const formikProps = useFormikContext()
  const { event } = useSelector((state) => state.events)

  const [bankAccountToCreate, setBankAccountToCreate] = useState(resetValues)

  const handleSave = async ({ state, bank, radioValue, ...values }) => {
    if (radioValue === 'alias') {
      values.number = ''
    } else if (radioValue === 'number') {
      values.alias = ''
    }

    const newBankAccount = {
      ...values,
      bankName: bank.label,
      bankId: bank.value,
      state: CARD_STATES.CARD_VIEW
    }

    bankAccountToCreate.pop()
    setBankAccountToCreate([...bankAccountToCreate, newBankAccount])

    const createdSuccess = await dispatch(createEventBankAccount(event.id, newBankAccount))

    if (!createdSuccess) return

    event.eventBankAccountsEnabled = true
    await dispatch(loadEventBankAccounts(event.id))

    formikProps.setFieldValue('paymentMethod', true)
    handleOnClose()
  }

  const handleOnClose = () => {
    onClose()
    setBankAccountToCreate(resetValues)
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={handleOnClose}
      title='PAGO OFFLINE - TRANSFERENCIA'
      className={classes.dialog}
      contentClassName={classes.container}>
      <main className={classes.mainContainer}>
        <div className={classes.bankAccountsContainer}>
          {bankAccountToCreate.map((x, index) => (
            <BankAccountCard
              key={index}
              bankAccount={x}
              {...formikProps}
              title={'Agregá una cuenta para recibir los pagos'}
              cardState={CARD_STATES.CARD_FORM}
              showCheckActive={false}
              onSubmit={handleSave}
            />
          ))}
        </div>
      </main>
    </SectionLayoutDialog>
  )
}

export default CreateBankAccountDialog
