import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  fileViewer: {
    overflowY: 'auto',
    height: '80vh',
    '& .bPJfhN ': {
      overflowY: 'hidden'
    },
    '& #pdf-download': {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      height: '86vh'
    }
  }
}))
