import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 670,
      maxWidth: 670,
      minHeight: '380px !important'
    }
  },
  subTitle: {
    fontWeight: 600,
    marginTop: theme.spacing(3)
  },
  eventName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(3)
  },
  subTitleContainer: {
    marginBottom: theme.spacing(2)
  },
  formGroup: {
    marginTop: theme.spacing(3),
    '& h6': {
      fontSize: '.875rem'
    }
  },
  formControl: {
    marginRight: 0,
    width: '43%'
  },
  gender: {
    width: '27%'
  },
  option: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  copyButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 10),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    '&.right': {
      alignSelf: 'flex-end'
    }
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid #C8C8C8`,
    padding: theme.spacing(),
    paddingRight: 0
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    paddingLeft: theme.spacing(3)
  },
  question: {
    fontSize: '.875rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 1),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    '& button': {
      width: '40%'
    }
  },
  duplicateCategoriesContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 80,
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  categoryItem: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(1, 0),
    justifyContent: 'space-between'
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      margin: '0 auto'
    }
  },
  rangeAgeLabel: {
    width: '32%'
  },
  duplicateName: {
    marginRight: 0,
    width: '40%'
  }
}))
