import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './WarningDialog.style'

const WarningDialog = ({ open, onClose, onSubmit }) => {
  const classes = useStyles()

  const handleSubmit = () => {
    onSubmit()
    onClose()
  }

  return (
    <SectionLayoutDialog
      open={open}
      title={'¡ADVERTENCIA!'}
      onClose={onClose}
      className={classes.dialog}>
      <Typography className={classes.text}>
        Recordá que la comisión de MercadoPago elegida debe coincidir con la configurada en tu
        cuenta MercadoPago.
        <br />
        <span className={classes.TextSpan}>
          Podés revisar la misma en la sección Tu Negocio {'>'} Costos {'>'} Cobros Online.
        </span>
      </Typography>
      <Typography className={classes.textEnd}>Asegurate que coincidan!</Typography>
      <Button color='primary' variant='contained' className={classes.button} onClick={handleSubmit}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default WarningDialog
