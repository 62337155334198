import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    },
    [theme.breakpoints.up('lg')]: {
      width: '45%'
    }
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(2),
    '& fieldset': {
      height: 43,
      borderColor: theme.palette.primary.main
    }
  },
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 60,
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },
    padding: theme.spacing(3, 0),
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  customFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  link: {
    marginTop: theme.spacing(),
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.85rem',
    cursor: 'pointer',
    flexShrink: 0
  },
  textFileField: {
    width: '100%',

    '& fieldset': {
      border: 'none'
    },

    '& input': {
      height: 4,
      fontSize: '.825rem',
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: '#000000'
      }
    },

    [theme.breakpoints.up('md')]: {
      minWidth: 'auto'
    }
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(2),
    height: 38,
    '& button': {
      minWidth: 38,
      height: 38,
      boxShadow: 'none'
    },
    [theme.breakpoints.up('md')]: {
      width: '48%'
    }
  },
  attachIcon: {
    transform: 'rotate(45deg)',
    width: 20,
    height: 20
  },
  error: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(1.5)
  }
}))
