import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#ffffff',
    position: 'relative'
  },
  containerCover: {
    backgroundColor: '#ffffff',
    position: 'relative',
    padding: 0
  }
}))
