import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  profileImage: {
    width: 100,
    height: 100,
    borderColor: `1px solid ${theme.palette.background.default}`
  },
  containerTitle: {
    flexGrow: 1,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
    padding: ' 0 15px'
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    textDecoration: 'none'
  },
  name: {
    color: theme.palette.grey[500]
  },
  followButton: {
    flexShrink: 0,
    position: 'static'
  }
}))
