import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, IconButton, TextField, Typography } from '@material-ui/core'
import { FileCopyOutlined as FileCopyOutlinedIcon, Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Formik, useFormikContext } from 'formik'
import { SectionLayoutDialog } from 'shared'
import { sendMercadoPagoAuthorization } from 'state/modules/events'
import * as Yup from 'yup'

import { useStyles } from './CreateEventMercadoPagoAuthorizationDialog.style'

const DIALOG_STATES = {
  DIALOG_INITIAL: 'dialogInitial',
  DIALOG_SUBMITTED: 'dialogSubmitted'
}

const CreateEventMercadoPagoAuthorizationDialog = ({
  onCopyUrl,
  copyPending,
  onClose,
  setOnlinePaymentPendingState,
  ...dialogProps
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { setValues } = useFormikContext()
  const { event } = useSelector((state) => state.events)

  const [dialogStatus, setDialogStatus] = useState(DIALOG_STATES.DIALOG_INITIAL)

  const values = {
    eMail: ''
  }
  const validation = Yup.object().shape({
    eMail: Yup.string()
      .required('Debe ingresar el email para vincular la cuenta')
      .email('El email ingresado no tiene un formato válido')
  })

  const onSubmit = async ({ eMail }) => {
    const data = await dispatch(sendMercadoPagoAuthorization(event.id, eMail))

    if (data) {
      event.mercadoPagoCode = data.eventMercadoPagoCode
      event.mercadoPagoEnabled = false

      setValues((values) => ({
        ...values,
        mercadoPagoCode: data.eventMercadoPagoCode,
        mercadoPagoEnabled: false
      }))

      setOnlinePaymentPendingState()
      setDialogStatus(DIALOG_STATES.DIALOG_SUBMITTED)
    }
  }

  const handleOnClose = () => {
    onClose()
    setDialogStatus(DIALOG_STATES.DIALOG_INITIAL)
  }

  return (
    <SectionLayoutDialog
      title='VINCULAR CUENTA MP'
      {...dialogProps}
      onClose={handleOnClose}
      className={classes.dialog}
      contentClassName={classes.container}>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validation}
        onSubmit={onSubmit}>
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          errors,
          handleSubmit,
          isValid,
          isSubmitting
        }) => (
          <form
            onSubmit={handleSubmit}
            className={clsx(
              classes.form,
              dialogStatus === DIALOG_STATES.DIALOG_SUBMITTED && DIALOG_STATES.DIALOG_SUBMITTED
            )}>
            {dialogStatus === DIALOG_STATES.DIALOG_INITIAL && (
              <>
                <Typography variant='h6' color='primary' className={classes.title}>
                  Te enviaremos un email con enlace a Mercado Pago para que completes la Vinculación
                  de tu cuenta.
                </Typography>
                <TextField
                  color='primary'
                  variant='outlined'
                  name='eMail'
                  autoFocus
                  value={values.eMail}
                  error={touched.eMail && Boolean(errors.eMail)}
                  helperText={errors.eMail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder='Ingresá el email del titular de la cuenta'
                  className={classes.textField}
                  size='small'
                />

                <Typography className={classes.helperText}>
                  <InfoIcon />
                  Recordá que la cuenta debe ser cuenta vendedor
                </Typography>

                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  className={classes.button}
                  endIcon={isSubmitting && <CircularProgress size={15} />}
                  disabled={!isValid || isSubmitting}>
                  Vincular
                </Button>
              </>
            )}

            {dialogStatus === DIALOG_STATES.DIALOG_SUBMITTED && (
              <>
                <Typography variant='h6' className={classes.title}>
                  Se envió el email con el enlace para completar la vinculación a:
                </Typography>
                <Typography className={classes.eMail} color='primary'>
                  {values.eMail}
                </Typography>
                <div className={classes.pendingBindContainer}>
                  <IconButton color='primary' className={classes.copyButton} onClick={onCopyUrl}>
                    {copyPending ? <CircularProgress size={14} /> : <FileCopyOutlinedIcon />}
                  </IconButton>
                  <Typography className={classes.copyText}>Copiar enlace</Typography>
                </div>
                <Button
                  className={classes.button}
                  color='primary'
                  variant='contained'
                  onClick={handleOnClose}>
                  Aceptar
                </Button>
              </>
            )}
          </form>
        )}
      </Formik>
    </SectionLayoutDialog>
  )
}

export default CreateEventMercadoPagoAuthorizationDialog
