import React from 'react'
import { Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './CreateOrganizationInfoDialog.style'

const CreateOrganizationInfoDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='CREAR ORGANIZACIÓN'
      className={classes.dialog}>
      <div className={classes.container}>
        <Typography>Ya tienes creada una organización a tu cargo.</Typography>
        <Typography>Solo puedes ser la persona referente de una sola organización.</Typography>
      </div>
    </SectionLayoutDialog>
  )
}

export default CreateOrganizationInfoDialog
