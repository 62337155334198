import React from 'react'
import { Element, scroller } from 'react-scroll'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './SectionBlock.style'

const SectionBlock = ({
  id,
  containerClassName,
  buttonClassName,
  children,
  nextSectionId,
  onActiveAnimation = () => {},
  scrollAnimationProps = {}
}) => {
  const classes = useStyles()

  const scrollToNextSection = () => {
    scroller.scrollTo(nextSectionId, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuad',
      ...scrollAnimationProps
    })

    onActiveAnimation()
  }
  return (
    <Element id={id} className={clsx(classes.section, containerClassName)}>
      {children}
      {!!nextSectionId && (
        <IconButton className={clsx(classes.button, buttonClassName)} onClick={scrollToNextSection}>
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
    </Element>
  )
}

export default SectionBlock
