import axios from 'axios'

const JWT_KEY = process.env.REACT_APP_JWT_KEY

axios.interceptors.request.use(
  (config) => {
    const { headers } = config

    if (!headers.common.authorization) {
      const token = window.localStorage.getItem(JWT_KEY)

      if (token) {
        const { auth_token: authToken } = JSON.parse(token)
        config.headers.authorization = `Bearer ${authToken}`
      }
    }

    const headerKey = 'X-TimeOffset'

    if (!headers.common[headerKey]) {
      const timeOffset = new Date().getTimezoneOffset()
      config.headers.common[headerKey] = -timeOffset
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const setAuthorizationHeader = (token = null) => {
  if (token) axios.defaults.headers.common.authorization = `Bearer ${token}`
  else delete axios.defaults.headers.common.authorization
}

export default class BaseProxy {
  constructor() {
    this._baseUrl = `${process.env.REACT_APP_API_URL}/api`
  }

  _makeUrlParams(params) {
    let queryString = ''
    const entries = Object.entries(params)
    for (let i = 0; i < entries.length; ++i) {
      const separator = queryString === '' ? '?' : '&'
      const entry = entries[i]
      if (Array.isArray(entry[1])) {
        for (let j = 0; j < entry[1].length; ++j) {
          queryString += `${separator}${entry[0]}=${entry[1][j]}`
        }
      } else if (entry[1]) {
        queryString += `${separator}${entry[0]}=${entry[1]}`
      }
    }

    return queryString
  }
}
