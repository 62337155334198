import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import noImage from 'assets/img/no-image.jpg'
import clsx from 'clsx'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import {
  EditableImage,
  EditableMultipleSelection,
  EditableText,
  Footer,
  MainLayout,
  NavBar,
  PageContainer,
  ShareUrlMenuDialog
} from 'shared'
import { addUserRole, verifyUserLogged, verifyUserNotLogged } from 'state/modules/app'
import {
  deleteImage as deleteImageOrganization,
  follow,
  load,
  loadPastEvents,
  loadReset,
  loadUpcomingEvents,
  setImage,
  updateOrganization
} from 'state/modules/organizations'
import { USER_ROLE } from 'utils/constants'
import { normalizeUrl, searchDisciplines } from 'utils/functions'
import { useQuery } from 'utils/hooks'
import { showSnackbarError } from 'utils/snackbar'

import {
  EventCard,
  FormEditDialog,
  InvitationToManageOrganizationDialog,
  PlaceHolderLoader,
  SectionBlock,
  SectionInformation,
  ServiceLetterFileDialog,
  UnPublishedEventsSection
} from './components'
import useStyles from './ProfilePage.style'

const ProfilePage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const {
    organization,
    pending,
    upcomingEvents,
    pastEvents,
    upcomingEventsPending,
    pastEventsPending,
    followPending,
    error: asyncError,
    loadActiveServiceLetterReportPending
  } = useSelector((state) => state.organizations)
  const { user, userData } = useSelector((state) => state.auth)
  const { handleOrId } = useParams()
  const classes = useStyles()
  const activeLoading = (!pending && !organization) || pending
  const query = useQuery()
  const invitationId = query.get('invitationId')
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openTooltip, setOpenTooltip] = useState(false)
  const [openInvitationToManageOrganizationDialog, setOpenInvitationToManageOrganizationDialog] =
    useState(false)
  const [openFormEditDialog, setOpenFormEditDialog] = useState(false)

  const [openServiceLetterFileDialog, setOpenServiceLetterFileDialog] = useState(false)

  useEffect(() => {
    dispatch(load(handleOrId))
    return () => {
      dispatch(loadReset())
    }
  }, [dispatch, handleOrId, user])

  useEffect(() => {
    if (organization) {
      dispatch(loadUpcomingEvents(organization.id))
      dispatch(loadPastEvents(organization.id))
    }
  }, [organization])

  useEffect(() => {
    if (invitationId && organization) {
      if (user) verifyInvitationToManageOrganization(userData, invitationId, organization)
      else
        dispatch(
          verifyUserLogged({ state: true, redirectUrl: `${location.pathname}${location.search}` })
        )
    }
  }, [user, userData, invitationId, organization])

  const verifyInvitationToManageOrganization = (userData, invitationId, organization) => {
    if (!userData) return

    const activeInvitation =
      userData.id === invitationId &&
      organization.userAccounts.some(
        (x) => x.userAccountId === invitationId && x.joinedAt && !x.isAdmin
      )

    if (!activeInvitation) return

    if (!userData.isOrganizer)
      dispatch(
        addUserRole({
          state: true,
          role: USER_ROLE.ORGANIZER,
          organizationHandleOrId: handleOrId,
          successCallback: () => setOpenInvitationToManageOrganizationDialog(true),
          rejectCallback: () =>
            history.replace(
              `${ROUTES.ORGANIZATIONS.PROFILE}/${organization.handle || organization.id}`
            )
        })
      )
    else setOpenInvitationToManageOrganizationDialog(true)
  }

  useEffect(() => {
    showMessage(asyncError)
  }, [asyncError])

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const renderLoading = () => (
    <div className={classes.containerBody}>
      <PlaceHolderLoader />
    </div>
  )

  const showMessage = (error) => {
    if (error) showSnackbarError(error)
  }

  const handleSave = ({ field, value, type }) => {
    if (type === 'url') value = normalizeUrl(value)

    const dataUpdate = field ? { [field]: value } : value
    const organizationUpdate = { ...organization, ...dataUpdate }
    dispatch(updateOrganization(organizationUpdate, field))
  }

  const handleSaveHandle = async ({ field, value }) => {
    const dataUpdate = field ? { [field]: value } : value
    const organizationUpdate = { ...organization, ...dataUpdate }

    const data = await dispatch(updateOrganization(organizationUpdate, field))

    history.replace(`${ROUTES.ORGANIZATIONS.PROFILE}/${data.handle}`)
  }
  const handleSaveImage = ({ value, type }) => {
    dispatch(setImage(organization.id, value, type))
  }

  const handleDeleteImage = ({ type }) => {
    dispatch(deleteImageOrganization(organization, type))
  }

  const handleFollow = () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(follow(organization))
    }
  }

  const renderOrganization = () => {
    const profileImageUrl =
      Array.isArray(organization.profileImages) && organization.profileImages.length > 0
        ? organization.profileImages[0]
        : noImage

    const backgroundImageUrl =
      Array.isArray(organization.backgroundImages) && organization.backgroundImages.length > 0
        ? organization.backgroundImages[0]
        : noImage

    const {
      loggedUserMetadata,
      followersCount,
      updatedAt,
      createdAt,
      handle,
      id,
      title,
      createdById
    } = organization

    const { canEdit, isFollowing } = loggedUserMetadata
    const myOrganization = canEdit && isFollowing

    const isReference = !!user && canEdit && createdById === user.id

    return (
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <EditableImage
              entity={organization}
              field='backgroundImages'
              onSave={handleSaveImage}
              defaultImage={noImage}
              canEdit={canEdit}
              inputClassName={classes.featureImage}
              type='background'
              onDelete={handleDeleteImage}>
              <div
                className={classes.featureImage}
                style={{ backgroundImage: `url(${backgroundImageUrl})` }}
              />
            </EditableImage>
            <div className={classes.organizationInfoContainer}>
              <EditableImage
                entity={organization}
                field='profileImages'
                onSave={handleSaveImage}
                canEdit={canEdit}
                defaultImage={noImage}
                inputClassName={classes.profileImage}
                size={{ width: 30, height: 30, fontsize: 13 }}
                type='profile'
                onDelete={handleDeleteImage}>
                <div
                  className={classes.profileImage}
                  style={{ backgroundImage: `url(${profileImageUrl})` }}
                />
              </EditableImage>
              <div className={classes.titleContainer}>
                <Typography variant='h2' className={classes.title} color='primary'>
                  {organization.name}
                </Typography>
                <EditableText
                  entity={organization}
                  field='handle'
                  onSave={handleSaveHandle}
                  inputClassName={classes.handle}
                  type='text'
                  placeholder='Handle'>
                  <Typography variant='h3' className={classes.handle} color='primary'>
                    {`@${organization.handle || organization.id}`}
                  </Typography>
                </EditableText>
              </div>
              <EditableMultipleSelection
                entity={organization}
                field='disciplines'
                subField='name'
                isMultiline={true}
                selectClassName={classes.asyncSelect}
                loadOptions={searchDisciplines}
                onSave={handleSave}
                placeholder='Seleccione una disciplina'
                type='array'>
                <div className={classes.sportsContainer}>
                  {organization.disciplines.length ? (
                    organization.disciplines.map((d) => (
                      <div className={classes.sport} key={d.id}>
                        {d.name}
                      </div>
                    ))
                  ) : (
                    <Typography color='primary' variant='h6'>
                      SIN DISCIPLINA
                    </Typography>
                  )}
                </div>
              </EditableMultipleSelection>
            </div>
          </div>
        </div>
        <div className={classes.containerActionBar}>
          <div className={classes.mainContainer}>
            <div className={classes.actionBar}>
              <div className={classes.actionBarButtonContainer}>
                <Typography className={classes.infoLabel} color='primary'>
                  {`${followersCount} Seguidores`}
                </Typography>
                <div>
                  {!isFollowing ? (
                    <Button
                      variant='outlined'
                      color='primary'
                      disabled={followPending}
                      className={classes.buttonFollow}
                      onClick={() => {
                        handleFollow()
                      }}>
                      Seguir
                    </Button>
                  ) : (
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        onClose={handleTooltipClose}
                        open={openTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title='No puedes dejar de seguir a tu propia organización'>
                        <Button
                          variant='contained'
                          color='primary'
                          disabled={followPending}
                          className={classes.buttonFollowing}
                          onClick={() => {
                            if (myOrganization) {
                              handleTooltipOpen()
                            } else {
                              handleFollow()
                            }
                          }}>
                          Siguiendo
                        </Button>
                      </Tooltip>
                    </ClickAwayListener>
                  )}
                  <ShareUrlMenuDialog
                    id={id}
                    updatedAt={updatedAt || createdAt}
                    type='organization'
                    copyMessage='El link del perfil de la organización fue copiado al portapapeles'
                    slugUrl={handle || id}
                    title={title}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.containerBody}>
          <div className={classes.mainContainer}>
            <div className={classes.rowContainer}>
              <div className={classes.leftColumn}>
                {canEdit && <UnPublishedEventsSection organizationId={id} />}
                <SectionBlock>
                  <div className={classes.upcomingContainer}>
                    <div
                      className={clsx(
                        classes.upcomingMainContainer,
                        !!upcomingEvents.length && 'hasEvents'
                      )}>
                      <div className={classes.upcomingHeader}>
                        <Typography color='primary' variant='h6' className={classes.upcomingTitle}>
                          Próximos eventos del organizador
                        </Typography>

                        {isDesktop && canEdit && (
                          <Button
                            variant='contained'
                            color='secondary'
                            to={`${ROUTES.EVENTS.CREATE}?organizationIdOrHandle=${
                              organization.handle || organization.id
                            }`}
                            component={Link}
                            className={classes.button}>
                            Crear evento
                          </Button>
                        )}
                      </div>

                      <div className={classes.upcomingEventsContainer}>
                        {upcomingEventsPending ? (
                          <CircularProgress color='primary' size={32} />
                        ) : upcomingEvents.length > 0 ? (
                          upcomingEvents.map((event) => <EventCard key={event.id} event={event} />)
                        ) : (
                          <Typography variant='body1' color='primary'>
                            No hay próximos eventos para el organizador
                          </Typography>
                        )}
                      </div>
                    </div>

                    {!isDesktop && canEdit && (
                      <Button
                        variant='contained'
                        color='secondary'
                        to={`${ROUTES.EVENTS.CREATE}?organizationIdOrHandle=${
                          organization.handle || organization.id
                        }`}
                        component={Link}
                        className={classes.button}>
                        Crear evento
                      </Button>
                    )}
                  </div>
                </SectionBlock>
                <SectionBlock title={'Eventos anteriores'}>
                  {pastEventsPending ? (
                    <CircularProgress color='primary' size={32} />
                  ) : pastEvents.length > 0 ? (
                    pastEvents.map((event) => <EventCard key={event.id} event={event} previous />)
                  ) : (
                    <Typography variant='body1' color='primary'>
                      No hay eventos anterior para el organizador
                    </Typography>
                  )}
                </SectionBlock>
              </div>
              <div className={classes.rightColumn}>
                {isReference && (
                  <>
                    <FormEditDialog
                      organization={organization}
                      open={openFormEditDialog}
                      onClose={() => setOpenFormEditDialog(false)}
                    />
                    <Button
                      color='primary'
                      variant='outlined'
                      className={classes.editButton}
                      onClick={() => setOpenFormEditDialog(true)}>
                      Editar Organización
                    </Button>
                  </>
                )}
                <SectionInformation organization={organization} handleSave={handleSave} />

                {canEdit && organization.hasActiveContract && (
                  <>
                    <Button
                      color='secondary'
                      variant='outlined'
                      className={classes.serviceLetterButton}
                      disabled={loadActiveServiceLetterReportPending}
                      endIcon={
                        loadActiveServiceLetterReportPending && (
                          <CircularProgress size={16} color='primary' />
                        )
                      }
                      onClick={() => setOpenServiceLetterFileDialog(true)}>
                      Carta de servicio
                    </Button>
                    <ServiceLetterFileDialog
                      open={openServiceLetterFileDialog}
                      onClose={() => setOpenServiceLetterFileDialog(false)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <InvitationToManageOrganizationDialog
          open={openInvitationToManageOrganizationDialog}
          onClose={() => setOpenInvitationToManageOrganizationDialog(false)}
        />
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode>
        {!activeLoading ? renderOrganization() : renderLoading()}
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default ProfilePage
