import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './FormNotifyDialog.style'

const FormNotifyDialog = ({ open, onClose, errors, title, subtitle }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      title={title}
      className={classes.dialog}
      contentClassName={classes.content}>
      <div className={classes.container}>
        <Typography color='primary' variant='h6' className={classes.subtitle}>
          {subtitle}
        </Typography>
        <ul className={classes.list}>
          {errors.filter(Boolean).map((e, index) => (
            <li key={index} className={classes.item} title={e.label}>
              {e.label}
            </li>
          ))}
        </ul>

        <Button color='primary' variant='contained' onClick={onClose} className={classes.buttonEnd}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default FormNotifyDialog
