import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    '&.hidden': {
      display: 'none'
    }
  },
  titleContainer: {
    textAlign: 'center',
    paddingBottom: theme.spacing(5),
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      textAlign: 'center',
      height: '100%',
      paddingBottom: theme.spacing(5)
    }
  },
  title: {
    color: theme.palette.primary.contrastText
  },
  radioButton: {
    width: 24,
    height: 24,
    marginTop: theme.spacing(10),
    color: theme.palette.primary.contrastText,
    '&.Mui-checked': {
      color: theme.palette.primary.contrastText
    },
    '& svg': {
      width: 24,
      height: 24
    }
  }
}))
