import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0,
    '&.secondary': {
      backgroundColor: `${theme.utils.rgba(theme.palette.secondary.main, 0.7)} !important`
    }
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 827,
      maxWidth: 827,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 400
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16),
    padding: theme.spacing(0, 2),
    '&.secondary': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.16)
    }
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(6),
    '&.center': {
      paddingLeft: 0
    }
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    padding: theme.spacing(4, 0),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  }
}))
