import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(),
    paddingRight: theme.spacing(2),
    fontSize: '1rem',
    '&.hide': {
      margin: '0 auto',
      padding: 0,
      minWidth: 40,
      alignItems: 'center',
      display: 'flex',
      '& svg': {
        width: 28,
        height: 40
      }
    },
    '&:disabled': {
      backgroundColor: '#696969',
      color: '#ffff'
    },
    '&.readOnly': {
      flexGrow: 1,
      width: 200
    }
  },
  text: {
    fontSize: '1rem',
    padding: theme.spacing()
  },
  containerButton: {}
}))

export default useStyles
