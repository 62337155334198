import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    '& > div > div': {
      minHeight: 200
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem'
    },
    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  distanceField: {
    marginBottom: theme.spacing(3)
  },
  deleteDialogBody: {
    minHeight: 120,
    marginBottom: theme.spacing(3),
    '& h6': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    }
  },
  field: {
    fontWeight: 600
  },
  button: {
    alignSelf: 'center',
    margin: theme.spacing(3, 0),
    width: '38%',
    minWidth: 200
  },
  actionButton: {
    minWidth: 40,
    padding: '6px'
  }
}))
