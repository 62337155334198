import React from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Transition } from 'utils'

import { useStyles } from './QuerySuccessDialog.style'

const QuerySuccessDialog = ({ open, onClose }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      TransitionComponent={Transition}
      fullWidth
      BackdropProps={{
        classes: {
          root: clsx(classes.backDrop)
        }
      }}
      PaperProps={{
        classes: {
          root: clsx(classes.dialog)
        }
      }}>
      <DialogTitle className={classes.titleContainer}>
        <IconButton color='primary' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.content}>
          <Typography color='primary' variant='h5' className={classes.message}>
            Muchas gracias!!!
            <br />
            tus datos fueron enviados
            <br />
            nos contactaremos.
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default QuerySuccessDialog
