import React from 'react'

import { RowInscription } from '../RowInscription'

import { useStyles } from './InscriptionList.style'

const InscriptionList = ({
  title = 'TODAS LAS CATEGORÍAS',
  listInscription,
  category,
  hiddenHeader
}) => {
  const classes = useStyles()

  const newListInscription = listInscription
    .map((inscription, index) => ({ ...inscription, index }))
    .sort((a, b) => {
      if (a.loggedUserMetadata !== null && b.loggedUserMetadata === null) return -1
      if (a.loggedUserMetadata === null && b.loggedUserMetadata !== null) return 1
      return a.index - b.index
    })

  return (
    <div className={classes.container}>
      <table className={classes.table}>
        <tbody>
          {newListInscription.map((inscription, index) => (
            <RowInscription key={index} {...{ inscription, category }} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InscriptionList
