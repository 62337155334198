import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import noImage from 'assets/img/user-mock.png'
import { ROUTES } from 'routes'
import { verifyUserNotLogged } from 'state/modules/app'
import { follow } from 'state/modules/userAccounts'

import useStyles from './UserAccountCard.style'

const UserAccountCard = ({ userAccount }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { disciplines, fullName, followersCount, id, areaLevel1Name, countryName, username } =
    userAccount
  const [followPending, setFollowPending] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const imageUrl =
    Array.isArray(userAccount.profileImages) && userAccount.profileImages.length > 0
      ? userAccount.profileImages[0]
      : noImage

  const myProfile = id === user?.id || false
  const location = [areaLevel1Name, countryName].filter(Boolean).join(', ')
  const countDisciplines = disciplines.length - 1
  const disciplinesToShow =
    countDisciplines <= 0
      ? disciplines
      : [
          ...disciplines.slice(0, 1),
          {
            id: 'add',
            name: `Y ${countDisciplines} más`,
            title: disciplines
              .map((e) => e.name)
              .slice(1, disciplines.length)
              .join(', ')
          }
        ]

  const handleFollow = async () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      setFollowPending(true)
      await dispatch(follow(userAccount))
      setFollowPending(false)
    }
  }

  return (
    <div className={classes.container}>
      <Link
        className={classes.mainContainer}
        to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${userAccount.id}`}>
        <div className={classes.headerContainer}>
          <div className={classes.imageContainer} style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className={classes.titleContainer}>
            <Typography variant='h6' className={classes.title} title={fullName}>
              {fullName}
            </Typography>
            <Typography className={classes.username} variant='h6' title={`@${username || id}`}>
              @{username || id}
            </Typography>
          </div>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.sports}>
            {disciplinesToShow?.length
              ? disciplinesToShow.map((d) => (
                  <div key={d.id} className={classes.sport} title={d.title}>
                    {d.name}
                  </div>
                ))
              : 'Sin disciplinas'}
          </div>
          <div className={classes.informationContainer}>
            <div className={classes.address} title={location}>
              <Typography> {location || 'No proporcionado'}.</Typography>
            </div>
          </div>
        </div>
      </Link>

      <div className={classes.actions}>
        <Link to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${id}`} className={classes.profileLink}>
          Ver perfil
        </Link>

        {!myProfile && (
          <div className={classes.actionButtonsContainer}>
            {!user || !userAccount?.loggedUserMetadata?.isFollowing ? (
              <Button
                color='primary'
                variant='contained'
                disabled={followPending}
                className={classes.actionButton}
                onClick={() => {
                  handleFollow()
                }}>
                Seguir
              </Button>
            ) : (
              <Button
                color='primary'
                variant='outlined'
                disabled={followPending}
                className={classes.actionButton}
                onClick={() => {
                  handleFollow()
                }}>
                Siguiendo
              </Button>
            )}

            <Typography className={classes.followers} title={followersCount}>
              ({followersCount}) seguidores
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default UserAccountCard
