import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 900,
      maxWidth: 900
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      width: 920,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 400
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600
  },
  subTitle: {
    marginBottom: theme.spacing(2)
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    padding: theme.spacing(4, 3),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  infoContainer: {
    margin: '0 auto',
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 600
    }
  },
  field: {
    color: '#696969',
    fontWeight: 500,
    '& strong': {
      color: '#131313',
      fontWeight: 600
    }
  },
  actionButton: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: '40%',
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('md')]: {
        width: 210
      }
    }
  }
}))
