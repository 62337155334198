import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#ffffff',
    width: 280,
    display: 'inline-block',
    margin: theme.spacing(2)
  },
  headContainer: {
    height: 50,
    display: 'flex',
    flexDirection: 'row'
  },
  dateContainer: {
    width: 50,
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  day: {
    fontSize: '1.75em',
    lineHeight: '1.125em'
  },
  month: {
    fontSize: '.9em',
    lineHeight: '.8em',
    textTransform: 'uppercase'
  },
  sportsContainer: {
    flexGrow: 1,
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: theme.spacing()
  },
  actionButton: {
    border: 'none',
    width: theme.spacing(12),
    '&:hover': {
      border: 'none'
    },
    '&:disabled': {
      border: 'none',
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto'
    }
  },
  actionButtonLabel: {
    display: 'flex',
    flexDirection: 'row',
    '& em': {
      fontSize: '0.75em',
      fontWeight: 200,
      fontStyle: 'normal',
      fontFamily: theme.typography.fontFamily,
      marginLeft: theme.spacing()
    },

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& em': {
        marginLeft: theme.spacing(1)
      },
      '& span': {
        marginLeft: theme.spacing(1)
      }
    }
  },
  sport: {
    fontSize: '1em',
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  mainContainer: {
    textDecoration: 'none'
  },
  imageContainer: {
    height: 160,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    backgroundPosition: 'center'
  },
  bodyContainer: {
    height: 160,
    backgroundColor: '#E6FFEA',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    flexGrow: 1
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.125em',
    fontWeight: 600,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    textAlign: 'left',
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  address: {
    color: theme.palette.primary.main,
    fontSize: '1em',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  location: {
    color: theme.palette.primary.main,
    fontSize: '1em',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  footerContainer: {
    backgroundColor: '#E6FFEA',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  shareButton: {
    color: theme.palette.primary.main,
    borderRadius: 50
  }
}))

export default useStyles
