import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  emptyCategoryMessageContainer: {
    width: '85%',
    margin: '50px auto',
    marginTop: theme.spacing(4)
  },
  button: {
    alignSelf: 'center',
    padding: theme.spacing(1, 8),
    width: 200
  }
}))
