import React from 'react'
import clsx from 'clsx'
import { Formik } from 'formik'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './ActionDialog.style'

const ActionDialog = ({
  className,
  formClassName,
  title,
  open,
  onClose,
  children,
  action,
  values,
  contentClassName,
  validationSchema
}) => {
  const classes = useStyles()

  const onSubmit = async (e, formProps) => {
    const submitSucces = await action(e, formProps)
    if (submitSucces) onClose()
  }

  return (
    <SectionLayoutDialog
      title={title}
      open={open}
      onClose={onClose}
      className={className}
      contentClassName={contentClassName}>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {(props) => {
          const { handleSubmit } = props

          return (
            <form onSubmit={handleSubmit} className={clsx(classes.bodyContainer, formClassName)}>
              {typeof children === 'function' ? children(props) : children}
            </form>
          )
        }}
      </Formik>
    </SectionLayoutDialog>
  )
}

export default ActionDialog
