import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 940,
      maxWidth: 940
    },
    '&.confirmDialog': {
      minHeight: '300px !important',
      [theme.breakpoints.up('md')]: {
        width: 650,
        maxWidth: 650
      }
    }
  },
  formContainer: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '90%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      margin: '0 auto',
      '&.alert': {
        paddingTop: theme.spacing(2)
      }
    }
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& span': {
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },

    '& input': {
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  formControl: {},
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(),
    '& span': {
      fontWeight: 500
    }
  },
  textLink: {
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    marginTop: theme.spacing(5)
  },

  underlineText: {
    fontWeight: 600,
    textDecoration: 'underline',
    marginTop: theme.spacing(5)
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid #C8C8C8`,
    padding: theme.spacing(),
    justifyContent: 'space-between',
    paddingRight: 0,
    '& > * ': {
      width: '30%'
    }
  },
  quota: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(2),
    '& input': {
      maxWidth: 145
    },
    '& fieldset': {
      maxWidth: 200
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(7),
      '& > div > p': {
        maxWidth: 150
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
    marginTop: theme.spacing(8)
  },
  button: {
    padding: theme.spacing(1, 9)
  },
  labelQuota: {
    marginTop: theme.spacing(3)
  },
  optionDisabled: {
    '& p, span': {
      color: '#969696'
    }
  },
  distanceQuota: {
    [theme.breakpoints.up('md')]: {
      '& > p': {
        maxWidth: 250
      }
    }
  },
  errorMessage: {
    padding: theme.spacing(2, 0),
    fontSize: '.75rem'
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      margin: '0 auto'
    }
  },
  actionButtonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(8)
  },
  actionButton: {
    width: '35%',
    maxWidth: 180
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 150,
    justifyContent: 'space-between'
  },
  alertButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(5, 0),
    gap: theme.spacing(4)
  },
  unbindNotifyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 180,
    justifyContent: 'space-between'
  },
  emptyMessageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(5),
    gap: theme.spacing()
  },
  errorIcon: {
    marginTop: 2
  }
}))
