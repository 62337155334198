import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { AttachFileOutlined as AttachFileOutlinedIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { OrganizationsProxy } from 'services'
import { Field, FileViewerDialog, NumericField } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { FILE_EXTENSIONS, FILE_FORMATS } from 'utils/constants'
import { getFileExtesion, getFileName } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './CustomField.style'

const CustomField = ({
  fieldName = '',
  type,
  options = [],
  id,
  setFieldValue,
  error,
  value: valueField = '',
  handleChange,
  handleBlur,
  touched,
  fieldId
}) => {
  const classes = useStyles()
  const fileInputRef = useRef(null)

  const [loadingFile, setLoadingFile] = useState(false)
  const [value, setValue] = useState('')
  const [openFileViewerDialog, setOpenFileViewerDialog] = useState(false)

  const resetValues = useCallback(() => {
    setFieldValue(fieldId, id)

    switch (type) {
      case 'List': {
        const option = options.find((o) => o.name === valueField)
        return option ? { value: option.id, label: option.name } : ''
      }
      default:
        return valueField
    }
  }, [options, valueField, type, fieldId, setFieldValue, id])

  useEffect(() => {
    setValue(resetValues())
  }, [resetValues])

  const handleOnchangeFile = () => {
    const { current } = fileInputRef
    current.click()
  }

  const handleFile = async (event) => {
    if (event.target.files.length === 0) return

    try {
      setLoadingFile(true)
      const file = event.target.files[0]
      const { name, type: fileType } = file

      if (!FILE_FORMATS.includes(fileType)) {
        showSnackbarError({
          message:
            'El formato del archivo no es válido. Por favor seleccione un archivo con formato JPG, JPEG, PNG, DOCX, XLSX o PDF'
        })
        return
      }

      const fileProxy = new OrganizationsProxy()
      const { filename } = await fileProxy.postCustomFieldFile(id, file)
      setValue(name)
      setFieldValue(fieldName, filename)

      event.target.value = ''
    } catch (error) {
      showSnackbarError({ message: 'Ocurrió un error al subir el archivo' })
    } finally {
      setLoadingFile(false)
    }
  }

  const loadOptions = async (inputValue) =>
    options
      .filter((e) => e.name.toLowerCase().includes(inputValue.toLowerCase()))
      .map((x) => ({ value: x.id, label: x.name }))

  const handleSelectCustomField = () => {
    switch (type) {
      case 'Text':
      case 'Number': {
        const isTextArea = type === 'Text'
        return (
          <div>
            <Field
              variant='outlined'
              size='small'
              component={isTextArea ? TextField : NumericField}
              placeholder='Escriba aquí su respuesta'
              className={clsx(isTextArea ? classes.textAreaEdit : classes.textField)}
              multiline={isTextArea}
              value={valueField}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!error && touched}
              helperText={error}
              autoComplete='off'
              name={fieldName}
            />
          </div>
        )
      }
      case 'Boolean':
        return (
          <>
            <RadioGroup
              row
              aria-label='required'
              name={fieldName}
              value={valueField}
              onBlur={handleBlur}
              className={classes.radioContainer}
              onChange={handleChange}>
              <FormControlLabel value='Si' control={<Radio color='primary' />} label='Si' />
              <FormControlLabel value='No' control={<Radio color='primary' />} label='No' />
            </RadioGroup>
            {error && (
              <Typography color='error' variant='caption' className={classes.error}>
                {error}
              </Typography>
            )}
          </>
        )

      case 'File': {
        const fileName = getFileName(value, id)
        const fileExtension = getFileExtesion(fileName)

        return (
          <>
            <div className={classes.customFileContainer}>
              <div className={classes.fileContainer}>
                <input
                  type='file'
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept={FILE_EXTENSIONS.join(', ')}
                  onChange={handleFile}
                />
                <TextField
                  variant='outlined'
                  size='small'
                  autoComplete='off'
                  title={value}
                  value={value}
                  onClick={handleOnchangeFile}
                  placeholder='Adjunte un archivo'
                  className={classes.textField}
                />
                <Button
                  variant='contained'
                  color='primary'
                  title='Seleccione un archivo'
                  onClick={handleOnchangeFile}>
                  {loadingFile ? (
                    <CircularProgress title='subiendo archivo' size={20} color='secondary' />
                  ) : (
                    <AttachFileOutlinedIcon color='secondary' className={classes.attachIcon} />
                  )}
                </Button>
              </div>
              {value && !loadingFile && (
                <>
                  <Typography
                    className={classes.link}
                    color='primary'
                    onClick={() => setOpenFileViewerDialog(true)}>
                    Ver archivo
                  </Typography>
                  <FileViewerDialog
                    open={openFileViewerDialog}
                    title={fileName}
                    fileUrl={value}
                    fileExtension={fileExtension}
                    onClose={() => setOpenFileViewerDialog(false)}
                  />
                </>
              )}
            </div>

            {error && (
              <Typography
                color={touched ? 'error' : 'initial'}
                variant='caption'
                className={classes.error}>
                {error}
              </Typography>
            )}
          </>
        )
      }

      case 'List':
        return (
          <>
            <StyledSelect
              cacheOptions
              name={fieldName}
              placeholder='Seleccione una opción'
              value={value}
              onBlur={handleBlur}
              loadOptions={loadOptions}
              defaultOptions={
                options.map((option) => ({ value: option.id, label: option.name })) || []
              }
              onChange={(value) => setFieldValue(fieldName, value.label)}
              colorOptions='secondary'
              className={classes.select}
            />
            {error && (
              <Typography
                color={touched ? 'error' : 'initial'}
                variant='caption'
                className={classes.error}>
                {error}
              </Typography>
            )}
          </>
        )

      default:
        return <></>
    }
  }

  return <>{handleSelectCustomField()}</>
}

export default CustomField
