import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 190,
      color: theme.palette.primary.main
    }
  },
  textDescription: {
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    '& a': {
      fontWeight: 600,
      color: '#4285F4'
    }
  },
  section: {
    padding: theme.spacing(2, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.background.section,
    borderRadius: 10,

    '& > div': {
      backgroundColor: 'transparent !important',
      padding: 0,
      margin: 0
    },

    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none'
    }
  },
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    fontSize: '1.25rem'
  }
}))
