import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { setSearchTabIndex } from 'state/modules/app'
import {
  resetSearch as eventsResetSearch,
  setSearchParams as eventsSetSearchParams
} from 'state/modules/events'
import {
  resetSearch as organizationsResetSearch,
  setSearchParams as organizationsSetSearchParams
} from 'state/modules/organizations'
import {
  resetSearch as userAccountsResetSearch,
  setSearchParams as userAccountsSetSearchParams
} from 'state/modules/userAccounts'
import { ANALYTICS_EVENTS, USER_ROLE } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { FeatureBanner } from '../FeatureBanner'
import { TabList } from '../TabList'

import useStyles from './SearchWidget.style'

const SEARCH_STATES = {
  SIMPLE_SEARCH: 'simpleSearch',
  MULTIPLE_SEARCH: 'multipleSearch'
}
const tabLabels = ['Eventos', 'Deportistas', 'Organizaciones ']

const SearchWidget = ({
  mini,
  setEventSearchActive,
  eventSearchActive,
  showSearchPopupEl = null,
  setShowSearchPopupEl = () => {},
  mode = SEARCH_STATES.SIMPLE_SEARCH
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { tabIndex, activeRole } = useSelector((state) => state.app)
  const { searchParams: eventsSearchParams } = useSelector((state) => state.events)
  const { searchParams: organizationsSearchParams } = useSelector((state) => state.organizations)
  const { searchParams: userAccountsSearchParams } = useSelector((state) => state.userAccounts)

  const [selectedTab, setSelectedTab] = useState(tabIndex)
  const [showFeatureBanner, setShowFeatureBanner] = useState(false)
  const [searchParams, setSearchParams] = useState({
    events: eventsSearchParams,
    organizations: organizationsSearchParams,
    userAccounts: userAccountsSearchParams
  })

  const color = activeRole === USER_ROLE.ORGANIZER ? 'secondary' : 'primary'

  const visitedSearchPage = [
    ROUTES.EVENTS.SEARCH,
    ROUTES.USERS_ACCOUNTS.SEARCH,
    ROUTES.ORGANIZATIONS.SEARCH
  ].includes(location.pathname)

  const resetValues = (params) =>
    setSearchParams({
      ...params
    })

  useEffect(() => {
    resetValues({
      events: eventsSearchParams,
      organizations: organizationsSearchParams,
      userAccounts: userAccountsSearchParams
    })
    dispatch(setSearchTabIndex(selectedTab))
  }, [
    eventsSearchParams,
    organizationsSearchParams,
    userAccountsSearchParams,
    dispatch,
    selectedTab
  ])

  const submitEventsSearch = (e) => {
    e.preventDefault()
    if (eventsSearchParams !== searchParams.events) {
      dispatch(eventsResetSearch())
      dispatch(eventsSetSearchParams({ ...searchParams.events, page: 1 }))
    }

    dispatch(setSearchTabIndex(0))

    history.push(ROUTES.EVENTS.SEARCH)
  }

  const submitOrganizationsSearch = (e) => {
    e.preventDefault()
    if (organizationsSearchParams !== searchParams.organizations) {
      dispatch(organizationsResetSearch())
      dispatch(organizationsSetSearchParams({ ...searchParams.organizations, page: 1 }))
    }

    history.push(ROUTES.ORGANIZATIONS.SEARCH)
  }
  const submitUserAccountsSearch = (e) => {
    e.preventDefault()
    if (userAccountsSearchParams !== searchParams.userAccounts) {
      dispatch(userAccountsResetSearch())
      dispatch(userAccountsSetSearchParams({ ...searchParams.userAccounts, page: 1 }))
    }

    history.push(ROUTES.USERS_ACCOUNTS.SEARCH)
  }

  const redirectToOrganizationLandingPage = () => {
    history.push(ROUTES.LANDING.ORGANIZATION)
    trackEvent(ANALYTICS_EVENTS.ACCESS.LANDING_ORGANIZATION_FROM_MAIN_PAGE)
  }

  const redirectToTimingLandingPage = () => history.push(ROUTES.LANDING.TIMING)

  const renderEventsSearch = () => {
    return (
      <form onSubmit={submitEventsSearch} className={clsx(classes.form, color)}>
        <input
          type='text'
          placeholder='Nombre o deporte'
          autoComplete='off'
          className={clsx(
            classes.inputField,
            classes.searchField,
            mini && classes.inputFieldMini,
            color
          )}
          value={searchParams.events.searchText}
          onChange={(e) => {
            const events = {
              ...searchParams.events,
              searchText: e.target.value
            }
            setSearchParams({
              ...searchParams,
              events: {
                ...events
              }
            })
          }}
        />
        <div className={clsx(classes.checkboxContainer, color)}>
          <FormControlLabel
            classes={{
              root: clsx(
                classes.formControlLabel,
                mini && classes.formControlLabelMini,
                location.pathname !== ROUTES.ROOT && classes.customInputField,
                color
              )
            }}
            control={
              <Checkbox
                color='primary'
                classes={{
                  root: clsx(
                    classes.checkbox,
                    mini && classes.checkboxMini,
                    location.pathname !== ROUTES.ROOT && 'customCheck',
                    color
                  )
                }}
                checked={searchParams.events.withResultsOnly || false}
                onChange={(e) => {
                  const events = {
                    ...searchParams.events,
                    withResultsOnly: e.target.checked
                  }
                  setSearchParams({
                    ...searchParams,
                    events: {
                      ...events
                    }
                  })
                }}
              />
            }
            label='Solo con resultados'
            labelPlacement='start'
          />

          <IconButton
            type='submit'
            className={clsx(classes.searchButton, color)}
            onClick={() => {
              setShowSearchPopupEl(null)
            }}>
            <SearchIcon />
          </IconButton>
        </div>
      </form>
    )
  }

  const renderRunnersSearch = () => {
    return (
      <form onSubmit={submitUserAccountsSearch} className={clsx(classes.form, color)}>
        <input
          type='text'
          placeholder='Nombre'
          autoComplete='off'
          className={clsx(
            classes.inputField,
            classes.searchField,
            mini && classes.inputFieldMini,
            color
          )}
          value={searchParams.userAccounts.searchText}
          onChange={(e) => {
            const userAccounts = {
              ...searchParams.userAccounts,
              searchText: e.target.value
            }
            setSearchParams({
              ...searchParams,
              userAccounts: {
                ...userAccounts
              }
            })
          }}
        />

        <IconButton
          type='submit'
          className={clsx(classes.searchButton, color)}
          onClick={() => {
            setShowSearchPopupEl(null)
          }}>
          <SearchIcon />
        </IconButton>
      </form>
    )
  }

  const renderOrganizationsSearch = () => {
    return (
      <form onSubmit={submitOrganizationsSearch} className={clsx(classes.form, color)}>
        <input
          type='text'
          placeholder='Nombre'
          autoComplete='off'
          className={clsx(
            classes.inputField,
            classes.searchField,
            mini && classes.inputFieldMini,
            color
          )}
          value={searchParams.organizations.searchText}
          onChange={(e) => {
            const organizations = {
              ...searchParams.organizations,
              searchText: e.target.value
            }
            setSearchParams({
              ...searchParams,
              organizations: {
                ...organizations
              }
            })
          }}
        />
        <IconButton
          type='submit'
          className={clsx(classes.searchButton, color)}
          onClick={() => {
            setShowSearchPopupEl(null)
          }}>
          <SearchIcon />
        </IconButton>
      </form>
    )
  }

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 0:
        return renderEventsSearch()
      case 1:
        return renderRunnersSearch()
      case 2:
        return renderOrganizationsSearch()
      default:
        return null
    }
  }

  const renderFull = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div>
            <div
              className={classes.titleContainer}
              onAnimationEnd={() => setShowFeatureBanner(true)}>
              <Typography variant='h2' className={classes.title}>
                Encontrá tu próximo desafío
              </Typography>
            </div>
            {showFeatureBanner && <FeatureBanner />}
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              color='secondary'
              variant='contained'
              className={classes.organiceButton}
              onClick={redirectToOrganizationLandingPage}>
              Organizá
            </Button>
            <Button
              color='secondary'
              variant='outlined'
              className={classes.timingButton}
              onClick={redirectToTimingLandingPage}>
              Cronometrá
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const renderEventsSearchSimple = () => (
    <form onSubmit={submitEventsSearch} className={classes.eventSearchForm}>
      <input
        placeholder='Buscar eventos'
        className={classes.eventInput}
        value={searchParams.events.searchText}
        onChange={(e) => {
          const events = {
            ...searchParams.events,
            searchText: e.target.value
          }
          setSearchParams({
            ...searchParams,
            events: {
              ...events
            }
          })
        }}
      />
      <IconButton
        type='submit'
        className={classes.eventSearchButton}
        onClick={() => setShowSearchPopupEl(null)}>
        <SearchIcon />
      </IconButton>
    </form>
  )

  const renderMini = () => {
    return (
      <div className={clsx(classes.miniWidgetContainer, eventSearchActive && 'fullWidth')}>
        <div className={classes.miniSearchContainer}>
          {mode === SEARCH_STATES.MULTIPLE_SEARCH && (
            <>
              <TabList
                tabLabels={tabLabels}
                onSelectTab={setSelectedTab}
                selectedTab={selectedTab}
                mini
              />
              <div className={classes.tabContent}>{renderSelectedTab()}</div>
            </>
          )}

          {mode === SEARCH_STATES.SIMPLE_SEARCH && renderEventsSearchSimple()}
        </div>

        <div className={classes.miniSearchButtonContainer}>
          {!eventSearchActive && !visitedSearchPage ? (
            <IconButton
              aria-label='Menu'
              className={clsx(
                classes.miniSearchButton,
                showSearchPopupEl && classes.miniSearchButtonActive
              )}
              onClick={() => setEventSearchActive((state) => !state)}>
              <SearchIcon className={classes.menuIcon} />
            </IconButton>
          ) : (
            !visitedSearchPage && renderEventsSearchSimple()
          )}

          <Popover
            PaperProps={{
              className: classes.searchPopup
            }}
            elevation={0}
            open={!!showSearchPopupEl}
            onClose={() => setShowSearchPopupEl(null)}
            anchorEl={showSearchPopupEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <IconButton
              color='primary'
              className={classes.closeButton}
              size='small'
              onClick={() => setShowSearchPopupEl(null)}>
              <CloseIcon />
            </IconButton>
            <div className={classes.searchContainer}>
              <div className={classes.tabsContainer}>
                <TabList
                  tabLabels={tabLabels}
                  onSelectTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
                <div className={classes.tabContent}>{renderSelectedTab()}</div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    )
  }

  return mini ? renderMini() : renderFull()
}

export default SearchWidget
