import { makeStyles } from '@material-ui/core'
import ribbon from 'assets/img/ribbon.png'
import ribbonGrayed from 'assets/img/ribbon-grayed.png'

export const useStyle = makeStyles((theme) => ({
  resultMainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap'
    }
  },
  title: {
    fontSize: '0.9em',
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  resultBody: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    '& p': {
      width: 180,
      fontSize: '.9em'
    },
    [theme.breakpoints.up('lg')]: {
      '& p': {
        width: 'auto',
        fontSize: '1em'
      },
      '& div': {
        display: 'flex'
      }
    }
  },
  buttonResult: {
    marginLeft: 100
  },
  linkResult: {
    textDecoration: 'underline',
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  position: {
    width: '80px',
    minHeight: '3em',
    textAlign: 'left',
    paddingLeft: 10,
    background: theme.palette.secondary.main,
    marginRight: 20,
    display: 'flex',
    '& h6': {
      color: '#FFFFFF',
      fontSize: '2em'
    }
  },

  positionRelevant: {
    fontWeight: 600
  },

  medalImage: {
    position: 'relative',
    width: 50,
    height: 40,
    marginLeft: -theme.spacing(6),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    [theme.breakpoints.up('lg')]: {
      marginLeft: -theme.spacing(6)
    }
  },
  medalRibbon: {
    backgroundImage: `url(${ribbon})`
  },
  medalRibbonGrayed: {
    backgroundImage: `url(${ribbonGrayed})`
  },
  eventName: {
    maxWidth: 450,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))
