import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  privacyPolicyContent: {
    padding: theme.spacing(2)
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    },
    fontWeight: 500,
    paddingBottom: theme.spacing(1)
  },
  reason: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.125rem'
    },
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    fontWeight: 500
  },
  subTitle: {
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing()
  },
  text: {
    fontWeight: 500,
    '& a': {
      color: theme.palette.primary.main
    }
  }
}))
