import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useLocation } from 'react-router-dom'
import { ROUTES } from 'routes'
import { Authenticated } from 'shared'
import { verifyUserLogged } from 'state/modules/app'
import { verifyToken } from 'state/modules/auth'

export const PrivateRoute = ({ component: Component, authenticatedProps, ...rest }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!user) {
      const isAuthenticated = dispatch(verifyToken())
      if (!isAuthenticated) {
        const verifyParams = { state: true, redirectUrl: location.pathname || ROUTES.ROOT }
        dispatch(verifyUserLogged(verifyParams))
      }
    }
  }, [dispatch, location, user])

  return (
    <Authenticated {...authenticatedProps}>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </Authenticated>
  )
}
