import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import { createEventDiscountCode, loadEventDiscountCodes } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { DiscountCodeForm } from '../DiscountCodeForm'

import { useStyles } from './CreateDiscountCodeDialog.style'

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const CreateDiscountCodeDialog = ({ open, onClose = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { event } = useSelector((state) => state.events)

  const values = {
    name: '',
    totalCount: '',
    fixedAmount: '',
    percentage: '',
    discountType: '',
    validFrom: null,
    validTo: null
  }

  const handleCreateDiscountCode = async ({
    name,
    validFrom,
    validTo,
    fixedAmount,
    percentage,
    discountType,
    ...restProps
  }) => {
    const discountCode = {
      ...restProps,
      discountType,
      name: name.trim(),
      fixedAmount: discountType === DISCOUNT_TYPE.FIXED_AMOUNT ? fixedAmount : 0,
      percentage: discountType === DISCOUNT_TYPE.PERCENTAGE ? percentage : 0,
      validFrom: moment(validFrom).isValid() ? moment(validFrom).format() : null,
      validTo: moment(validTo).isValid() ? moment(validTo).format() : null
    }

    const createDiscountCodeSuccess = await dispatch(
      createEventDiscountCode(discountCode, event.id)
    )

    if (createDiscountCodeSuccess) {
      await dispatch(loadEventDiscountCodes(event.id))

      showSnackbarSuccess('¡Se añadió el código de descuento con éxito!')
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='CREAR CÓDIGO'
      className={classes.dialog}>
      <DiscountCodeForm values={values} onSubmit={handleCreateDiscountCode} />
    </SectionLayoutDialog>
  )
}

export default CreateDiscountCodeDialog
