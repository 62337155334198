import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0),
      width: '80%'
    }
  }
}))
