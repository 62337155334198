import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3)
  },

  mainContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(6),
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(3),
    fontSize: '1.125rem'
  },
  emptyText: {
    fontWeight: 600,
    fontSize: '.875rem'
  },

  button: {
    borderRadius: 20,
    width: 280,
    display: 'none', // remove after
    height: 40,
    marginRight: theme.spacing(3),
    boxShadow: 'none',
    marginTop: theme.spacing(4),
    alignSelf: 'center',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1.125rem'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      marginTop: theme.spacing(2)
    }
  }
}))
