import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.15)',
    height: 185,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(5, 0),
      justifyContent: 'space-between',
      width: '92%'
    }
  },
  mainContainer: {
    textDecoration: 'none'
  },
  headerContainer: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 60,
    paddingRight: 6,
    display: 'flex',
    alignItems: 'center'
  },
  imageContainer: {
    width: 60,
    height: 60,
    borderRadius: 50,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
    marginRight: 6,
    marginLeft: -25,
    [theme.breakpoints.up('lg')]: {
      borderRadius: 50
    }
  },
  bodyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '85%',
    maxHeight: theme.spacing(5),
    margin: '10px auto'
  },
  informationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontSize: '.9em',
    fontWeight: 600,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1em'
    }
  },
  handle: {
    color: theme.palette.primary.contrastText,
    fontSize: '.9em',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1em'
    }
  },
  titleContainer: {
    paddingLeft: theme.spacing(),
    width: '80%'
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.8em',
    paddingTop: 4,
    paddingRight: theme.spacing(),
    '& p': {
      textTransform: 'none',
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      color: '#696969',
      WebkitBoxOrient: 'vertical'
    },

    '&.disabled': {
      '& p': {
        color: '#696969 !important'
      }
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '.9em'
    }
  },
  sports: {
    display: 'flex',
    color: '#696969',
    fontSize: '.8em',
    textTransform: 'uppercase'
  },
  sport: {
    paddingRight: 2
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    width: '85%',
    justifyContent: 'space-between',
    margin: '10px auto'
  },
  actionButton: {
    textTransform: 'none',
    width: 80,
    height: 20,
    '& span': {
      fontSize: '0.725rem'
    }
  },
  profileLink: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '.725rem'
  },
  actionContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  followersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  followers: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(0.5),
    color: '#696969',
    fontSize: '.625rem'
  }
}))

export default useStyles
