import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 680,
      maxWidth: 680
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem'
    },
    '&.disabled': {
      '& h6': {
        color: 'rgba(0, 0, 0, 0.26)'
      }
    },
    '&.radio': {
      margin: theme.spacing(3, 0)
    },
    '& input': {
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  ageSection: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '& > div ': {
      width: '45%'
    }
  },
  radioButtonContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  radioRoot: {
    color: theme.palette.primary.main
  },
  actionButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 10),
    margin: theme.spacing(4, 0),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(2)
    }
  }
}))
