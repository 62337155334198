import axios from 'axios'

import BaseProxy from './BaseProxy'

export class AuthProxy extends BaseProxy {
  async login(username, password) {
    const response = await axios.post(`${this._baseUrl}/auth/login`, {
      username,
      password
    })

    return response.data
  }

  async facebookLogin(fbId, accessToken) {
    const response = await axios.post(`${this._baseUrl}/auth/facebook-login`, {
      fbId,
      accessToken
    })

    return response.data
  }

  async googleLogin(credentials) {
    const response = await axios.post(`${this._baseUrl}/auth/google-login`, {
      credentials
    })

    return response.data
  }
}

export default AuthProxy
