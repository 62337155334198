import React, { useState } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import {
  EditOutlined as EditOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon
} from '@material-ui/icons'
import { Formik } from 'formik'

const EditableSection = ({ children, canEdit, onSave, entity, validationSchema }) => {
  const [activeEdit, setActiveEdit] = useState(false)
  const [savePending, setSavePending] = useState(false)
  const [enableSection, setEnableSection] = useState(true)

  const handleOnSave = async (values, props) => {
    setSavePending(true)
    const onSaveSuccess = await onSave({ value: values }, props)
    setSavePending(false)

    if (onSaveSuccess !== false) setActiveEdit(!activeEdit)
  }

  const buttonComponent = (handleSubmit, isValid, props) => (
    <>
      {canEdit &&
        (!activeEdit ? (
          <IconButton color='primary' {...props} onClick={() => setActiveEdit(!activeEdit)}>
            <EditOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton
            disabled={!isValid || savePending || !enableSection}
            color='primary'
            {...props}
            onClick={() => handleSubmit()}>
            {savePending ? <CircularProgress size={18} /> : <SaveOutlinedIcon />}
          </IconButton>
        ))}
    </>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={entity}
      validationSchema={validationSchema}
      onSubmit={handleOnSave}>
      {(params) => {
        const { handleSubmit, isValid, resetForm } = params

        const cancelEdit = () => {
          resetForm()
          setActiveEdit(false)
        }

        return (
          <>
            {children({
              ...params,
              activeEdit,
              canEdit,
              handleStatusSection: setEnableSection,
              cancelEdit,
              setActiveEdit,
              saveLoading: savePending,
              enableSection,
              buttonComponent: (props) => buttonComponent(handleSubmit, isValid, props)
            })}
          </>
        )
      }}
    </Formik>
  )
}

export default EditableSection
