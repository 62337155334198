import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  slickSlider: {
    textAlign: 'center'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(6)
    },
    [theme.breakpoints.up('lg')]: {
      width: 1280
    }
  },
  title: {
    fontSize: '1.75em',
    fontWeight: 600,
    letterSpacing: '.125em',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  noData: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },
  eventListContainer: {
    margin: '0 auto'
  },
  cardContainer: {
    '& > div': {}
  }
}))

export default useStyles
