import React, { useEffect } from 'react'
import { numericFormatter } from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { BankAccountCard } from 'pages/Events/InscriptionPage/components/BankAccountCard'
import { ROUTES } from 'routes'
import { PaymentLocationCard } from 'shared'
import { tryInscriptionRegister } from 'state/modules/app'
import {
  loadActiveBankAccounts,
  loadActivePaymentLocations,
  resetValue
} from 'state/modules/events'
import { Transition } from 'utils'
import { PRICE_FORMAT, STRING_EMPTY } from 'utils/constants'

import { useStyles } from './SuccessInscriptionPendingDialog.style'

const ORDER_PAYMENT = {
  MERCADO_PAGO: { value: 'MercadoPago', label: 'Mercado Pago' },
  PAYMENT_LOCATION: { value: 'PaymentLocation', label: 'Pago en Efectivo' },
  BANK_ACCOUNT: { value: 'BankAccount', label: 'Transferencia' },
  PAYMENT_URL: { value: 'PaymentUrl', label: 'Link de pago' }
}

const SuccessInscriptionPendingDialog = ({ open, onClose, inscription = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { slugUrlOrId } = useParams()

  const {
    event,
    eventInscription,
    activeInscriptionForm,
    activeBankAccounts,
    activePaymentLocations
  } = useSelector((state) => state.events)

  const { user } = useSelector((state) => state.auth)
  const hoursPattern = /^\d+$/

  useEffect(() => {
    if (eventInscription?.paymentType === ORDER_PAYMENT.PAYMENT_LOCATION.value) {
      dispatch(loadActivePaymentLocations(event.id))
    } else if (eventInscription?.paymentType === ORDER_PAYMENT.BANK_ACCOUNT.value) {
      dispatch(loadActiveBankAccounts(event.id))
    }

    return () => {
      dispatch(resetValue('activeBankAccounts'))
      dispatch(resetValue('activePaymentLocations'))
    }
  }, [eventInscription])

  const timeFormat = (time) => {
    if (!time) return null

    const { 0: hours, 1: minutes } = time.split(':')

    if (hoursPattern.test(hours)) return `${hours}:${minutes}`

    const numberHours = Number(hours)
    const days = Math.floor(numberHours)
    const restHours = numberHours % 1
    const totalHours = days * 24 + Math.round(restHours * 100)

    return `${totalHours}:${minutes}`
  }

  const handleOpenSignUpDialog = () => {
    dispatch(tryInscriptionRegister({ inscription, state: true }))
    onClose()
  }

  const redirectEvent = () => history.push(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)

  const renderSuccessInscription = () => {
    const { name, organization } = event
    const {
      firstName,
      lastName,
      isThirdParty,
      idNumber,
      paymentType,
      saleOrder,
      category,
      distance,
      ticketTypeId
    } = eventInscription
    const { paymentConfirmationPeriod } = activeInscriptionForm ?? {}
    const beginText = isThirdParty
      ? `Preinscribiste a ${firstName} ${lastName}`
      : 'Te preinscribiste'
    const helperText = isThirdParty
      ? `El número de preinscripción es el DNI ${idNumber}`
      : `Tu número de preinscripción es tu DNI ${idNumber}`

    const ticketType = activeInscriptionForm?.ticketTypes.find((x) => x.id === ticketTypeId)

    const handleLabelPaymentType = () => {
      if (
        paymentType === ORDER_PAYMENT.BANK_ACCOUNT.value ||
        paymentType === ORDER_PAYMENT.PAYMENT_LOCATION.value
      ) {
        return (
          <Typography className={classes.subTitle}>
            Para completar la inscripción deberás realizar el pago de&nbsp;
            {numericFormatter(Number(saleOrder?.totalAmount).toString(), PRICE_FORMAT)}&nbsp;
            {paymentType === ORDER_PAYMENT.BANK_ACCOUNT.value
              ? 'por transferencia'
              : 'en alguno de los siguientes lugares definidos por el organizador,'}
            &nbsp;
            {paymentConfirmationPeriod
              ? `dentro de las ${timeFormat(paymentConfirmationPeriod).split(':')[0]}hs, `
              : STRING_EMPTY}
            y subir el comprobante en el perfil del evento accediendo desde Estado de mi inscripción
            o haciendo click&nbsp;
            <span onClick={redirectEvent} className={classes.link}>
              aquí
            </span>
            .
          </Typography>
        )
      } else if (paymentType === ORDER_PAYMENT.PAYMENT_URL.value) {
        return (
          <Typography className={classes.subTitle}>
            Para completar la inscripción deberás realizar el pago de&nbsp;
            {numericFormatter(Number(saleOrder?.totalAmount).toString(), PRICE_FORMAT)}&nbsp; en el
            siguiente link:{' '}
            <a href={ticketType?.paymentUrl} target='_blank' rel='noreferrer'>
              {ticketType?.paymentUrl}
            </a>
            {paymentConfirmationPeriod
              ? `, dentro de las ${timeFormat(paymentConfirmationPeriod).split(':')[0]}hs`
              : STRING_EMPTY}
            . Luego sube una captura del comprobante de pago.
            <br />
            <p>
              Para subir el comprobante de pago, sigue los siguientes pasos:
              <br />
              1. Ingresa al perfil del evento.
              <br />
              2. Haz clic en &ldquo;Estado de mi inscripción&rdquo;.
              <br />
              3. Se te solicitará ingresar tu DNI.
              <br />
              4. Una vez que hayas ingresado podrás subir el comprobante de pago. <br /> Recuerda
              que el componente debe estar en formato .jpg o .pdf
            </p>
          </Typography>
        )
      }
    }

    return (
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContainer}>
          <div className={classes.infoContainer}>
            <Typography className={classes.subTitle}>
              {beginText} al evento <span>{name}</span>
              &nbsp;organizado por&nbsp;
              <span>{organization.name}</span> en {category.name} {distance.name}.
            </Typography>
            <br />
            <Typography className={classes.subTitle}>{helperText}.</Typography>
            <br />
            {handleLabelPaymentType()}
            <br />
            {activeBankAccounts.map((x, index) => (
              <BankAccountCard key={x.id} bankAccount={x} title={`Cuenta bancaria ${index + 1}`} />
            ))}

            {activePaymentLocations.map((x, index) => (
              <PaymentLocationCard
                key={x.id}
                paymentLocation={x}
                title={`Lugar de pago ${index + 1}`}
              />
            ))}

            <br />
            <Typography className={classes.subTitle}>
              *Recibirás un email con esta información más detalles del evento.
            </Typography>
            {!user && (
              <>
                <br />
                <Typography className={classes.resgisterText}>
                  Con un paso más ahorrás tiempo en tu próxima inscripción.{' '}
                  <a className={classes.link} onClick={handleOpenSignUpDialog}>
                    Crear cuenta.
                  </a>
                </Typography>
              </>
            )}
            <Button
              onClick={onClose}
              color='primary'
              variant='contained'
              className={classes.acceptButton}>
              Aceptar
            </Button>
          </div>
        </div>
      </DialogContent>
    )
  }

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      TransitionComponent={Transition}
      open={open}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title}>
            PREINSCRIPCIÓN EXITOSA
          </Typography>
        </div>
      </DialogTitle>
      {eventInscription ? renderSuccessInscription() : <CircularProgress size={16} />}
    </Dialog>
  )
}

export default SuccessInscriptionPendingDialog
