import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { useStyles } from './QuestionBlock.style'

const QuestionBlock = ({ question, response }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => {
    setExpanded(!expanded)

    if (!expanded) trackEvent(ANALYTICS_EVENTS.ACTION.EXPAND_FREQUENTLY_QUESTION_FOR_VIEW)
  }

  return (
    <Accordion expanded={expanded} onChange={handleChange} className={classes.accordion}>
      <AccordionSummary
        expandIcon={!expanded ? <AddIcon /> : <RemoveIcon />}
        aria-controls='question-content'
        id='question-header'
        className={classes.headerContainer}>
        <Typography className={classes.heading}>{question}</Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.details}>
        <Typography className={classes.response}>{response}</Typography>
      </AccordionDetails>
    </Accordion>
  )
}

export default QuestionBlock
