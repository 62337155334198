import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: theme.spacing(2)
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    backgroundColor: '#fff',
    margin: theme.spacing(5, 0),
    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none'
    }
  },
  bodyContainer: {
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      minWidth: 1190,
      width: '85%',
      maxWidth: 1344
    }
  },
  bannerContainer: {
    padding: theme.spacing(5, 0)
  }
}))

export default useStyles
