import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './EditEventMercadoPagoAuthorizationDialog.style'
import { NotificationDialog } from './NoticationDialog'

const ACTION_TYPE = {
  TRY_BIND_ACTION: 'tryBindAction',
  UNBIND_ACTION: 'unbindAction'
}

const NOTIFY_DIALOG_STATES = {
  REPLACE_METHOD_NOTIFY: 'replaceMethodNotify',
  REPLACE_METHOD_CONFLICT_NOTIFY: 'replaceMethodConflictNotify',
  DELETE_METHOD_NOTIFY: 'deleteMethodNotify'
}

const EditEventMercadoPagoAuthorizationDialog = ({
  open,
  onClose,
  onOpenCreateMercadoPagoAuthorization,
  onDeleteMercadoPagoAuthorization,
  ticketOnlyMercadoPago
}) => {
  const classes = useStyles()

  const {
    deleteMercadoPagoAuthorizationPeding,
    paymentLocations,
    eventBankAccounts,
    activeInscriptionForm
  } = useSelector((state) => state.events)
  const { values: formState, setValues } = useFormikContext()

  const [optionRadioSelected, setOptionRadioSelected] = useState(ACTION_TYPE.UNBIND_ACTION)
  const [openNotificationDialog, setOpenNotificationDialog] = useState(false)
  const [notifyDialogProps, setNotifyDialogProps] = useState({})

  const handleActionSelected = async () => {
    if (optionRadioSelected === ACTION_TYPE.UNBIND_ACTION) {
      const result = ticketOnlyMercadoPago()

      if (result) {
        setNotifyDialogProps({ state: NOTIFY_DIALOG_STATES.DELETE_METHOD_NOTIFY })

        return setOpenNotificationDialog(true)
      }

      onDeleteMercadoPagoAuthorization()
      handleOnClose()
    } else if (optionRadioSelected === ACTION_TYPE.TRY_BIND_ACTION) {
      const hasMercadoPagoTicket =
        !!activeInscriptionForm &&
        !activeInscriptionForm.isDraft &&
        formState.ticketTypes.some((x) => x.mercadoPagoEnabled)

      if (hasMercadoPagoTicket) {
        const hasActivePaymentLocation = paymentLocations.some((x) => x.isActive)
        const hasActiveBankAccount = eventBankAccounts.some((x) => x.isActive)

        const hasMercadoPagoTickets = formState.ticketTypes.some(
          (x) =>
            (hasActivePaymentLocation && x.paymentLocationsEnabled) ||
            (hasActiveBankAccount && x.bankAccountsEnabled)
        )

        setNotifyDialogProps({
          ...notifyDialogProps,
          onConfirm: handleChangeCreateMercadoPago,
          state: hasMercadoPagoTickets
            ? NOTIFY_DIALOG_STATES.REPLACE_METHOD_NOTIFY
            : NOTIFY_DIALOG_STATES.REPLACE_METHOD_CONFLICT_NOTIFY
        })

        setOpenNotificationDialog(true)

        return
      }

      handleChangeCreateMercadoPago()
    }
  }

  const handleChangeCreateMercadoPago = () => {
    setOpenNotificationDialog(false)
    onOpenCreateMercadoPagoAuthorization(setValues)
    handleOnClose()
  }

  const handleOnClose = () => {
    setOptionRadioSelected(ACTION_TYPE.UNBIND_ACTION)
    onClose()
  }

  return (
    <SectionLayoutDialog
      title='EDITAR PAGO ONLINE'
      open={open}
      onClose={handleOnClose}
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography color='primary' variant='h6' className={classes.text}>
        Al desvincular la cuenta asociada deberás vincular una nueva o quitar pago online.
      </Typography>
      <div>
        <Typography color='primary' variant='h6' className={classes.text}>
          ¿Qué deseas hacer?
        </Typography>
        <RadioGroup
          aria-label='allowUnregisteredUsers'
          name='allowUnregisteredUsers'
          value={optionRadioSelected}
          className={classes.formcontrol}
          onChange={(e) => setOptionRadioSelected(e.target.value)}>
          <FormControlLabel
            value={ACTION_TYPE.TRY_BIND_ACTION}
            control={<Radio color='primary' className={classes.radio} />}
            classes={{ label: classes.optionLabel }}
            label='Vincular una nueva cuenta.'
          />
          <FormControlLabel
            value={ACTION_TYPE.UNBIND_ACTION}
            classes={{ label: classes.optionLabel }}
            control={<Radio color='primary' className={classes.radio} />}
            label='Quitar pago online'
          />
        </RadioGroup>
      </div>
      <Button
        className={classes.button}
        color='primary'
        variant='contained'
        endIcon={
          deleteMercadoPagoAuthorizationPeding && <CircularProgress color='primary' size={15} />
        }
        onClick={handleActionSelected}>
        Aceptar
      </Button>
      <NotificationDialog
        open={openNotificationDialog}
        {...notifyDialogProps}
        backDropClassName={classes.backDrop}
        onClose={() => setOpenNotificationDialog(false)}
      />
    </SectionLayoutDialog>
  )
}

export default EditEventMercadoPagoAuthorizationDialog
