import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import { normalizeUrl } from 'utils/functions'
import * as Yup from 'yup'

import { useStyles } from './CreateInscriptionDialog.style'

const CreateInscriptionDialog = ({
  open,
  onClose,
  title,
  eventSlugUrl,
  onSave,
  onOpenModalityEventDialog
}) => {
  const classes = useStyles()
  const [insertExternalUrlMode, setInsertExternalUrlMode] = useState(false)

  const handleOnClose = () => {
    onClose()
    setInsertExternalUrlMode(false)
  }

  const onSubmit = async (values) => {
    values.externalInscriptionUrl = normalizeUrl(values.externalInscriptionUrl)

    await onSave({ value: { externalInscriptionUrl: values.externalInscriptionUrl } })
    onClose()
  }

  const handleOpenModalityEvent = () => {
    onOpenModalityEventDialog()
    onClose()
  }

  const validation = Yup.object().shape({
    externalInscriptionUrl: Yup.string()
      .required('Debe ingresar la url para continuar')
      .transform((value) =>
        !!value && !value.includes('http://') && !value.includes('https://')
          ? `http://${value}`
          : value
      )
      .url('La url ingresada no es valida')
  })

  const values = {
    externalInscriptionUrl: ''
  }

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      open={open}
      onClose={handleOnClose}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title}>
            {title}
          </Typography>
          <IconButton onClick={handleOnClose}>
            <CloseIcon color='secondary' />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {!insertExternalUrlMode ? (
          <div className={classes.bodyContainer}>
            <Typography variant='h6' align='center' className={classes.subTitle}>
              Creando el formulario con Sportmetric tenés acceso:
            </Typography>
            <div className={classes.infoContainer}>
              <ul className={classes.infoText}>
                <li>
                  <DoneIcon color='secondary' />
                  &nbsp;Gestión y administración total de los inscriptos a tu evento.
                </li>
                <li>
                  <DoneIcon color='secondary' />
                  &nbsp;Notificaciones automáticas y personalizadas a tus inscriptos.
                </li>
                <li>
                  <DoneIcon color='secondary' />
                  &nbsp;Constante mejora en la experiencia de usuario.
                </li>
                <li>
                  <DoneIcon color='secondary' />
                  <Typography>
                    &nbsp;Fácil importación a&nbsp;
                    <Link
                      target='_blank'
                      rel='noopener noreferrer'
                      to={ROUTES.LANDING.TIMING}
                      className={classes.timingLink}>
                      Crono.
                    </Link>
                  </Typography>
                </li>
                <li className={clsx(classes.paymentTypeInfo, 'align-start')}>
                  <DoneIcon color='secondary' />

                  <Typography>
                    Para los tickets con pago offline (transferencia, Link de pago o pago en
                    efectivo) el costo es del 2% + IVA por inscripto y pre inscripto, para lo cual
                    se emitirá liquidación mensual a la organización.
                    <br />
                    Para los tickets con pago online (Mercado Pago) el costo es del 4% + IVA por
                    inscripto y se incluirá en el mismo ticket.
                  </Typography>
                </li>
              </ul>
            </div>
            <Button
              color='primary'
              variant='contained'
              onClick={handleOpenModalityEvent}
              className={classes.createButton}>
              Crear con Sportmetric
            </Button>
            <Typography className={classes.linkText}>
              O puedes&nbsp;
              <strong onClick={() => setInsertExternalUrlMode(true)}>agregar link externo</strong>
            </Typography>
          </div>
        ) : (
          <div className={classes.linkExternalContainer}>
            <Typography
              color='primary'
              align='center'
              variant='h6'
              className={classes.linkExternalSubtitle}>
              Crear link de inscripción externo
            </Typography>
            <Formik initialValues={values} validationSchema={validation} onSubmit={onSubmit}>
              {({
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                errors,
                touched,
                isSubmitting,
                isValid
              }) => (
                <form onSubmit={handleSubmit} className={classes.linkForm}>
                  <div className={classes.linkBodyContainer}>
                    <Typography>Pegue aquí su link de inscripción</Typography>
                    <TextField
                      color='primary'
                      variant='outlined'
                      type='text'
                      autoComplete='off'
                      name='externalInscriptionUrl'
                      onBlur={handleBlur}
                      error={!!errors.externalInscriptionUrl && touched.externalInscriptionUrl}
                      helperText={errors.externalInscriptionUrl}
                      onChange={handleChange}
                      value={values.externalInscriptionUrl}
                      className={classes.textField}
                    />
                    <Typography color='primary' variant='h6' className={classes.helperText}>
                      Los usuarios podrán acceder a él desde el perfil del evento
                    </Typography>
                  </div>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    endIcon={isSubmitting && <CircularProgress color='primary' size={16} />}
                    disabled={isSubmitting || !isValid}
                    className={classes.saveUrlButton}>
                    Guardar
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateInscriptionDialog
