import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '330px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '80%'
    }
  },

  message: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6)
  },
  button: {
    alignSelf: 'center',
    padding: theme.spacing(1, 8)
  }
}))
