import React from 'react'
import { Button, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './FeatureProductCard.style'

const FeatureProductCard = ({
  title,
  subTitle,
  list,
  price,
  actionTextButton,
  actionButton,
  textDescription,
  textAnotation,
  className
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.mainContainer}>
        <div className={classes.containerHeader}>
          <Typography variant='h4' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='h2' color='secondary' className={classes.subTitle}>
            {subTitle}
          </Typography>
        </div>
        {list ? (
          <ul className={classes.list}>
            {list.map(({ text }, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        ) : (
          <div className={classes.textDescriptionContainer}>
            <Typography className={classes.textDescription}>
              {textDescription?.primary}
              <br /> <br />
              {textDescription?.secondary}
            </Typography>
          </div>
        )}
      </div>
      <Typography className={classes.textAction} variant='h1'>
        {price}
      </Typography>
      <Button
        variant='contained'
        size='large'
        target='_blank'
        href={actionButton}
        className={classes.buyButton}>
        {actionTextButton}
      </Button>

      {textAnotation && (
        <div className={classes.containerTextAnotation}>
          <div>
            <Typography>{textAnotation}</Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default FeatureProductCard
