import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import {
  Close as CloseIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import { SearchWidget } from 'pages/HomePage/components'
import { BannerSection, Footer, MainLayout, NavBar, PageContainer } from 'shared'
import {
  loadSearchMetadata,
  search,
  setSearchParams as organizationsSetSearchParams
} from 'state/modules/organizations'

import { FilterWidget, OrganizationCard } from './components'
import useStyles from './SearchPage.style'

const SEARCH_STATES = {
  SIMPLE_SEARCH: 'simpleSearch',
  MULTIPLE_SEARCH: 'multipleSearch'
}

const SearchPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const {
    searchParams: organizationsSearchParams,
    searchOrganizations,
    searchOrganizationsLastPage,
    pending,
    searchMetadata,
    searchMetadataPending
  } = useSelector((state) => state.organizations)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [showSearchPopupEl, setShowSearchPopupEl] = useState(null)

  const bannersPosition = isMobile ? 4 : 6

  useEffect(() => {
    dispatch(search(organizationsSearchParams))
    dispatch(loadSearchMetadata(organizationsSearchParams))
  }, [organizationsSearchParams, dispatch, user])

  const doOrganizationsSearch = (newSearchParams) =>
    dispatch(organizationsSetSearchParams(newSearchParams))

  return (
    <MainLayout>
      <NavBar
        showWidget
        widget={(props) => (
          <SearchWidget
            mini
            showSearchPopupEl={showSearchPopupEl}
            mode={SEARCH_STATES.MULTIPLE_SEARCH}
            setShowSearchPopupEl={setShowSearchPopupEl}
            {...props}
          />
        )}
      />
      <div className={classes.actionsContainer}>
        {!!organizationsSearchParams.searchText && (
          <Typography
            color='primary'
            variant='h6'
            className={classes.text}
            title={`A continuación se muestran los resultados para la búsqueda: ${organizationsSearchParams.searchText}`}>
            A continuación se muestran los resultados para la búsqueda:{' '}
            {organizationsSearchParams.searchText}
          </Typography>
        )}

        <div className={classes.topActionsContainer}>
          <Button variant='text' color='primary' onClick={() => setFilterDrawerOpen(true)}>
            <span>Filtrar</span>
            <FilterListIcon />
          </Button>

          <IconButton color='primary' onClick={(e) => setShowSearchPopupEl(e.currentTarget)}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      <PageContainer customContainerClassName={classes.container}>
        <div className={classes.filterContainer}>
          <div className={classes.filterWrapper}>
            <FilterWidget
              searchParams={organizationsSearchParams}
              onSearchParamsChanged={doOrganizationsSearch}
              searchMetadata={searchMetadata}
              searchMetadataPending={searchMetadataPending}
            />
          </div>
        </div>
        <div className={classes.resultsContainer}>
          {!!searchOrganizations.length && (
            <InfiniteScroll
              dataLength={searchOrganizations.length}
              hasMore={!searchOrganizationsLastPage}
              next={() =>
                doOrganizationsSearch({
                  ...organizationsSearchParams,
                  page: organizationsSearchParams.page + 1
                })
              }
              style={{ overflow: 'inherit' }}
              className={classes.listContainer}>
              {searchOrganizations.map((o, index) => (
                <>
                  {index === bannersPosition && (
                    <div className={classes.bannerContainer}>
                      <BannerSection />
                    </div>
                  )}

                  <div className={classes.cardContainer}>
                    <OrganizationCard key={o.id} organization={o} />
                  </div>
                </>
              ))}
            </InfiniteScroll>
          )}

          {pending && (
            <div className={classes.containerLoader}>
              <CircularProgress size={40} color='secondary' />
            </div>
          )}

          <div className={classes.endContainer}>
            {!pending && !searchOrganizations.length && (
              <div className={classes.messageContainer}>
                <Typography variant='h5' color='primary'>
                  No hay resultados cargados para la búsqueda que indicaste.
                </Typography>
                <Typography color='primary'>
                  Para obtener más resultados te recomendamos que modifiques los parámetros de
                  búsqueda.
                </Typography>
              </div>
            )}

            <div className={classes.buttonsContainer}>
              {!pending && !searchOrganizationsLastPage && (
                <div className={classes.buttonContainer}>
                  <Button
                    color='primary'
                    variant='contained'
                    className={classes.button}
                    onClick={() => {
                      doOrganizationsSearch({
                        ...organizationsSearchParams,
                        page: organizationsSearchParams.page + 1
                      })
                    }}>
                    Ver más
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </PageContainer>
      {isMobile && (
        <Drawer
          ModalProps={{
            keepMounted: true
          }}
          open={filterDrawerOpen}
          onClose={() => setFilterDrawerOpen(false)}
          anchor='left'>
          <div className={classes.filterWrapper}>
            <div className={classes.filterDrawerHeader}>
              <IconButton color='primary' onClick={() => setFilterDrawerOpen(false)}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </div>
            <FilterWidget
              searchParams={organizationsSearchParams}
              onSearchParamsChanged={doOrganizationsSearch}
              searchMetadata={searchMetadata}
              searchMetadataPending={searchMetadataPending}
            />
          </div>
        </Drawer>
      )}
      <Footer />
    </MainLayout>
  )
}

export default SearchPage
