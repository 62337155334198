import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { follow } from 'state/modules/userAccounts'

import { useStyles } from './RowInscription.style'

const LinkLabel = ({ children, inscription = {} }) => {
  const classes = useStyles()

  if (inscription.loggedUserMetadata) {
    return (
      <Link to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${inscription.id}`} className={classes.link}>
        {children}
      </Link>
    )
  }
  return children
}

const RowInscription = ({ inscription, category }) => {
  const classes = useStyles()
  const [followPending, setFollowPending] = useState(false)
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const handleFollow = (inscription) => async () => {
    setFollowPending(true)
    await dispatch(follow(inscription, true))
    setFollowPending(false)
  }

  return (
    <tr className={classes.col}>
      <td className={clsx(classes.nameCol, !category && 'shared')}>
        <LinkLabel inscription={inscription}>{inscription.fullName}</LinkLabel>
      </td>
      {!category && <td>{inscription.distanceAndCategory}</td>}
      <td>
        {user.id !== inscription.id &&
          inscription.loggedUserMetadata &&
          (inscription.loggedUserMetadata.isFollowing ? (
            <Button
              color='primary'
              disabled={followPending}
              className={classes.buttonFollowing}
              onClick={handleFollow(inscription)}>
              Siguiendo
            </Button>
          ) : (
            <Button
              color='primary'
              disabled={followPending}
              variant='contained'
              className={classes.button}
              endIcon={followPending && <CircularProgress size={10} color='primary' />}
              onClick={handleFollow(inscription)}>
              Seguir
            </Button>
          ))}
      </td>
    </tr>
  )
}

export default RowInscription
