import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './PlaceholderLoader.styles'

const PlaceHolderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            title='Cargando...'
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='16' y='20' rx='0' ry='0' width={60} height={18} />

            <rect
              className={classes.profileLoading}
              x='0'
              y='55'
              rx='10'
              ry='10'
              width='100%'
              height='100%'
            />

            <rect x='16' y='270' width={45} height={25} rx='10' ry='10' />
            <rect x='75' y='270' width={45} height={25} rx='10' ry='10' />
            <rect x='135' y='270' width={45} height={25} rx='10' ry='10' />

            <rect x='20%' y='320' width='55%' height={18} rx='10' ry='10' />
            <rect x='10%' y='360' width='75%' height={18} rx='10' ry='10' />

            <rect x='15%' y='400' width='65%' rx='10' ry='10' height={50} />
            <rect x='15%' y='470' width='65%' rx='10' ry='10' height={50} />
            <rect x='28%' y='540' width='40%' height={18} rx='10' ry='10' />

            <rect x='5%' y='600' width='90%' rx='10' ry='10' height={250} />
            <rect x='5%' y='880' width='90%' rx='10' ry='10' height={200} />
            <rect x='5%' y='1110' width='90%' rx='10' ry='10' height={200} />
            <rect x='5%' y='1350' width='90%' rx='10' ry='10' height={300} />
            <rect x='5%' y='1690' width='90%' rx='10' ry='10' height={300} />

            <rect x='5%' y='2040' width='90%' rx='10' ry='10' height={500} />
          </ContentLoader>
        ) : (
          <ContentLoader
            className={classes.containerHorizontal}
            title='Cargando...'
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect
              className={classes.profileLoading}
              x='0'
              y='10'
              rx='10'
              ry='10'
              width='50%'
              height='100%'
            />

            <rect x='58%' y='30' rx='10' ry='10' width={130} height={25} />

            <rect x='58%' y='190' rx='10' ry='10' width='30%' height={15} />

            <rect x='58%' y='220' rx='10' ry='10' width='35%' height={15} />

            <rect x='84%' y='330' rx='10' ry='10' width='13%' height={50} />
            <rect x='84%' y='410' rx='10' ry='10' width='13%' height={50} />
            <rect x='86.5%' y='480' rx='10' ry='10' width='8%' height={18} />

            <rect x='40' y='530' width='70%' height={120} rx='10' ry='10' />

            <rect x='40' y='670' width='70%' height={160} rx='10' ry='10' />

            <rect x='40' y='850' width='70%' height={160} rx='10' ry='10' />

            <rect x='40' y='1030' width='70%' height={120} rx='10' ry='10' />

            <rect x='40' y='1170' width='70%' height={220} rx='10' ry='10' />

            <rect x='40' y='1410' width='70%' height={300} rx='10' ry='10' />

            <rect x='74%' y='530' width='23%' height={1180} rx='10' ry='10' />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default PlaceHolderLoader
