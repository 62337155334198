import { makeStyles } from '@material-ui/core'
import notbookCronosImage from 'assets/img/landing-notbook-cronos.jpg'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: '0 auto'
    }
  },

  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(15),
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(18),
      gap: theme.spacing()
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    fontSize: '1.75rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.625rem'
    }
  },

  titleSpan: {
    fontFamily: theme.typography.fontFamilyAlt,
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.75rem',
    width: 200,
    [theme.breakpoints.up('md')]: {
      width: 300,
      fontSize: '2.625rem'
    }
  },
  subTitle: {
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.875rem'
    }
  },
  content: {
    display: 'flex',
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.spacing(6)
  },

  desktopImage: {
    backgroundRepeat: 'no-repeat',
    transition: 'opacity ease-in-out 400ms',
    backgroundSize: 'contain',
    backgroundImage: `url(${notbookCronosImage})`,
    flexShrink: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: '32vw',
      width: '50%',
      maxWidth: 880,
      maxHeight: 580
    }
  },
  cronosContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  cronoInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },

  timingLink: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-end',
    fontWeight: 500,
    fontSize: '1.125rem',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  cronoTitle: {
    fontWeight: 700,
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: -10,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  cronoDescription: {
    fontWeight: 500,
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  '@keyframes backgroundAnimation': {
    '0%': {
      display: 'inline',
      backgroundPosition: '0 0'
    },
    '100%': {
      background: `linear-gradient(to right, #ffff 50%, ${theme.palette.secondary.main} 50%)`,
      backgroundPosition: '-100% 0',
      backgroundSize: '200%'
    }
  }
}))
