import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './PlaceholderLoading.style'

const PlaceholderLoading = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] > breakpoints.md ? (
          <ContentLoader
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='0' y='40' className={classes.title} width='100%' height='100%' />
            <rect x='300' y='185' className={classes.subTitle} width='100%' height='100%' />
            <rect x='0' y='240' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='280' width='40%' height={14} />
            <rect x='0' y='320' width='10%' height={14} />
            <rect x='450' y='320' width='10%' height={14} />
            <rect x='0' y='360' width='15%' height={40} />
            <rect x='200' y='360' width='12%' height={40} />
            <rect x='450' y='360' width='15%' height={40} />
            <rect x='650' y='360' width='12%' height={40} />
            <rect x='0' y='450' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='490' width='100%' height={14} />
            <rect x='0' y='520' width='70%' height={14} />
            <rect x='0' y='570' width='100%' height={40} />
            <rect x='900' y='650' width='100%' height={35} />
            <rect x='0' y='730' width='100%' height={200} />
            <rect x='0' y='960' width='100%' height={14} />
            <rect x='0' y='990' width='60%' height={14} />
            <rect x='0' y='1040' width='25%' height={35} />
            <rect x='0' y='1120' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1160' width='100%' height={40} />
            <rect x='0' y='1220' width='20%' height={40} />
            <rect x='260' y='1230' width='15%' height={16} />
            <rect x='0' y='1320' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1360' width='100%' height={150} />
            <rect x='0' y='1600' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1650' width='100%' height={14} />
            <rect x='0' y='1680' width='70%' height={14} />
            <rect x='0' y='1730' width='10%' height={14} />
            <rect x='600' y='1730' width='10%' height={14} />
            <rect x='300' y='1730' width='10%' height={14} />
            <rect x='0' y='1770' width='10%' height={14} />
            <rect x='600' y='1770' width='10%' height={14} />
            <rect x='300' y='1770' width='10%' height={14} />
            <rect x='0' y='1810' width='10%' height={14} />
            <rect x='600' y='1810' width='10%' height={14} />
            <rect x='300' y='1810' width='10%' height={14} />
            <rect x='0' y='1880' className={classes.section} width='100%' height='100%' />
            <rect x='0' y='1920' width='100%' height={14} />
            <rect x='0' y='1970' width='65%' height={40} />
            <rect x='900' y='1970' width='65%' height={40} />
            <rect x='0' y='2050' width='100%' height={14} />
            <rect x='0' y='2080' width='80%' height={14} />
            <rect x='0' y='2130' width='20%' height={40} />

            <rect x='0' y='2230' width='25%' height={140} />
            <rect x='400' y='2230' width='25%' height={140} />
            <rect x='800' y='2230' width='25%' height={140} />

            <rect x='250' y='2430' width='15%' height={40} />
            <rect x='500' y='2430' width='15%' height={40} />
            <rect x='750' y='2430' width='15%' height={40} />
          </ContentLoader>
        ) : (
          <ContentLoader
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='40' y='20' className={classes.date} width='100%' height='100%' />
            <rect x='30' y='60' className={classes.title} width='100%' height='100%' />
            <rect x='80' y='120' className={classes.subTitle} width='100%' height='100%' />
            <rect x='80' y='160' className={classes.subTitle} width='100%' height='100%' />
            <rect x='30' y='210' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='250' width='85%' height={14} />
            <rect x='30' y='280' width='65%' height={14} />
            <rect x='30' y='320' width='20%' height={14} />
            <rect x='30' y='350' width='40%' height={40} />
            <rect x='220' y='350' width='40%' height={40} />
            <rect x='30' y='430' width='20%' height={14} />
            <rect x='30' y='460' width='40%' height={40} />
            <rect x='220' y='460' width='40%' height={40} />
            <rect x='30' y='550' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='580' width='85%' height={14} />
            <rect x='30' y='610' width='65%' height={14} />
            <rect x='30' y='650' width='85%' height={130} />
            <rect x='193' y='800' width='45%' height={40} />
            <rect x='30' y='870' width='85%' height={200} />
            <rect x='30' y='1090' width='85%' height={14} />
            <rect x='30' y='1120' width='65%' height={14} />
            <rect x='30' y='1170' width='60%' height={40} />
            <rect x='30' y='1270' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='1300' width='85%' height={40} />
            <rect x='120' y='1380' width='40%' height={40} />
            <rect x='80' y='1440' width='60%' height={14} />
            <rect x='30' y='1510' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='1560' width='85%' height={150} />

            <rect x='30' y='1760' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='1800' width='20%' height={14} />
            <rect x='165' y='1800' width='20%' height={14} />
            <rect x='300' y='1800' width='20%' height={14} />
            <rect x='30' y='1860' width='20%' height={14} />
            <rect x='165' y='1860' width='20%' height={14} />
            <rect x='300' y='1860' width='20%' height={14} />
            <rect x='30' y='1920' width='20%' height={14} />
            <rect x='165' y='1920' width='20%' height={14} />
            <rect x='300' y='1920' width='20%' height={14} />
            <rect x='30' y='2010' className={classes.section} width='100%' height='100%' />
            <rect x='30' y='2050' width='85%' height={14} />
            <rect x='30' y='2080' width='65%' height={14} />
            <rect x='30' y='2130' width='85%' height={40} />
            <rect x='120' y='2220' width='40%' height={40} />
            <rect x='30' y='2300' width='85%' height={14} />
            <rect x='30' y='2330' width='65%' height={14} />
            <rect x='30' y='2390' width='60%' height={40} />
            <rect x='30' y='2500' width='85%' height={140} />
            <rect x='30' y='2700' width='85%' height={140} />
            <rect x='30' y='2900' width='85%' height={140} />
            <rect x='100' y='3150' width='50%' height={40} />
            <rect x='100' y='3250' width='50%' height={40} />
            <rect x='100' y='3350' width='50%' height={40} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default PlaceholderLoading
