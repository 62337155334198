import React from 'react'
import { Link } from 'react-router-dom'
import { Button, IconButton, Typography } from '@material-ui/core'
import {
  FavoriteBorder as FavoriteBorderIcon,
  LocationOnOutlined as LocationOnOutlinedIcon,
  Share as ShareIcon
} from '@material-ui/icons'
import noImage from 'assets/img/no-image.jpg'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'

import useStyles from './EventCard.style'

const EventCard = ({ event, previous }) => {
  const classes = useStyles()
  const { isSuspended, hasResults, externalInscriptionUrl, from, disciplines, slugUrl } = event
  const fromLocal = moment(from)
  const canJoin = moment(from) > moment() // TODO: Add this feature to the backend
  const imageUrl =
    Array.isArray(event.profileImages) && event.profileImages.length > 0
      ? event.profileImages[0]
      : noImage
  const countDisciplines = disciplines.length - 3
  const disciplinesToShow =
    countDisciplines <= 0
      ? disciplines
      : [
          ...disciplines.slice(0, 3),
          {
            id: 'add',
            name: `Y ${countDisciplines} más`,
            title: disciplines
              .map((e) => e?.name)
              .slice(3, disciplines.length)
              .join(', ')
          }
        ]
  const showInscriptionButton =
    event && !hasResults && canJoin && !isSuspended && !!externalInscriptionUrl
  return (
    <Link
      className={clsx(classes.container, previous && classes.simpleContainer)}
      to={`${ROUTES.EVENTS.PROFILE}/${slugUrl}`}>
      <div className={classes.cardHeader}>
        <div className={`${classes.date}  ${isSuspended && 'suspended'}`}>
          {fromLocal.format('DD MMM YYYY')}
        </div>
        {!previous && (
          <div className={classes.sportsContainer}>
            {event.disciplines.map((d) => (
              <div className={`${classes.sport} ${isSuspended && 'suspended'}`} key={d.id}>
                {d.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {!previous && (
        <div
          className={classes.imageContainer}
          style={{
            backgroundImage: `url(${imageUrl})`
          }}>
          {isSuspended && (
            <div className={classes.containerSuspended}>
              <Typography className={classes.textSuspended}>
                EVENTO SUSPENDIDO POR EL ORGANIZADOR
              </Typography>
            </div>
          )}
        </div>
      )}
      <div className={clsx(classes.bodyContainer, previous && classes.simpleBodyContainer)}>
        <div className={classes.bodyHeader}>
          <div className={`${classes.date} ${isSuspended && 'suspended'}`}>
            {fromLocal.format('DD MMM YYYY')}
          </div>
          {!previous && (
            <div className={classes.sportsContainer}>
              {disciplinesToShow.map((d) => (
                <div
                  className={`${classes.sport} ${isSuspended && 'suspended'} ${d.title && 'add'}`}
                  key={d.id}
                  title={d.title}>
                  {d.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <h2 className={`${classes.title} ${isSuspended && 'suspended'}`}>{event.name}</h2>
        {!previous && (
          <div className={`${classes.location} ${isSuspended && 'suspended'}`}>
            <LocationOnOutlinedIcon />
            {event.address}
          </div>
        )}
        {!previous && (
          <div className={`${classes.organizer} ${isSuspended && 'suspended'}`}>
            {event.organization && event.organization.name}
          </div>
        )}
      </div>
      <div className={classes.actions}>
        {!previous && (
          <div className={classes.iconButtonsContainer}>
            <IconButton
              color='primary'
              className={`${classes.actionIconButton} ${isSuspended && 'suspended'}`}>
              <FavoriteBorderIcon />
            </IconButton>
            <IconButton
              color='primary'
              className={`${classes.actionIconButton}  ${isSuspended && 'suspended'}`}>
              <ShareIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.actionButtonsContainer}>
          {event && event.hasResults && (
            <Button
              color='primary'
              variant='outlined'
              className={`${classes.actionButton}  ${isSuspended && 'suspended'}`}>
              Resultados
            </Button>
          )}
          {showInscriptionButton && (
            <Button
              color='primary'
              variant='outlined'
              className={`${classes.actionButton}  ${isSuspended && 'suspended'}`}>
              Inscribirse
            </Button>
          )}
        </div>
      </div>
    </Link>
  )
}

export default EventCard
