import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { Footer, MainLayout, PageContainer } from 'shared'
import { recoverAccount } from 'state/modules/auth'

import { ForgotPasswordForm } from './components'
import { useStyles } from './ForgotPasswordPage.style'

const ForgotPasswordPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { error } = useSelector((state) => state.auth)

  const [recoveryComplete, setRecoveryComplete] = useState(false)
  const [emailRecover, setEmailRecover] = useState('')

  const handleSubmit = async ({ email }, { setErrors }) => {
    const successfulRecover = await dispatch(recoverAccount(email))

    if (successfulRecover) {
      setRecoveryComplete(true)
      setEmailRecover(email)
    } else if (error.response.status === 400) {
      setErrors({ email: error.response.data })
    }
  }

  return (
    <MainLayout>
      <PageContainer customContainerClassName={classes.container}>
        <Link to={ROUTES.ROOT}>
          <div className={classes.logo}></div>
        </Link>
        <Typography variant='h2' className={classes.title}>
          Recuperar contraseña
        </Typography>
        <div className={classes.mainContainer}>
          <div className={classes.formContainer}>
            {!recoveryComplete ? (
              <ForgotPasswordForm
                onSubmit={handleSubmit}
                recoveryError={error && error.response && error.response.data}
              />
            ) : (
              <Typography>
                Te enviamos un correo a la siguiente dirección&nbsp;
                <strong>{emailRecover}</strong>. Sigue los pasos para recuperar tu contraseña
              </Typography>
            )}
          </div>
        </div>
      </PageContainer>
      <Footer altMode />
    </MainLayout>
  )
}

export default ForgotPasswordPage
