import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  editMode: {
    display: 'flex',
    alignItems: 'baseline',
    '& svg': {
      width: 20
    }
  },
  containerEdit: {
    display: 'flex',
    alignItems: 'center'
  },
  formNetwork: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'hidden'
  },
  textField: {
    width: '90%',
    marginRight: theme.spacing(1)
  },
  bottonIcon: {
    padding: theme.spacing(),
    alignSelf: 'center'
  }
}))
