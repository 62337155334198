import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { TrendingFlatOutlined as TrendingFlatOutlinedIcon } from '@material-ui/icons'
import { EllipseStatus, LayoutDialog } from 'shared'

import { useStyles } from './ChangeStateDialog.style'

const ChangeStateDialog = ({ open, onClose, data, onCancel, handleUpdateState }) => {
  const classes = useStyles()

  const inscriptionState = {
    Accepted: {
      label: 'Inscripto',
      value: 'green'
    },
    Pending: {
      label: 'Pre inscripto',
      value: 'yellow'
    },
    Cancelled: {
      label: 'Anulado',
      value: 'red'
    },
    Expired: {
      label: 'Vencido',
      value: 'grey'
    }
  }

  return (
    <>
      <LayoutDialog
        title='Cambio de estado de inscripción'
        open={open}
        className={classes.dialog}
        contentClassName={classes.bodyContainer}
        titleClassName={classes.title}>
        <div className={classes.mainContainer}>
          <Typography className={classes.subtitle}>
            Estás cambiando el estado de los siguientes deportistas:
          </Typography>
          <div className={classes.inscriptionContainer}>
            {data.map((element, index) => (
              <Typography key={index} className={classes.option} color='primary' variant='h6'>
                <strong
                  title={`${element.lastName} ${element.firstName}`}
                  className={classes.inscriptionFullName}>
                  {element.lastName} {element.firstName}
                </strong>
                <span title={inscriptionState[element.state].label} className={classes.prevState}>
                  {inscriptionState[element.state].label}
                </span>
                <EllipseStatus
                  className={classes.circleState}
                  status={inscriptionState[element.state].value}
                />
                <TrendingFlatOutlinedIcon className={classes.arrowIcon} />
                <EllipseStatus
                  className={classes.circleState}
                  status={inscriptionState[element.newState].value}
                />
                <span title={inscriptionState[element.newState].label} className={classes.newState}>
                  {inscriptionState[element.newState].label}
                </span>
              </Typography>
            ))}
          </div>
          <Typography className={classes.mailInfo}>
            Se enviará un mail a los deportistas comunicando el cambio de estado.
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                onCancel()
                onClose()
              }}
              className={classes.button}>
              Cancelar
            </Button>
            {data.length > 0 && (
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  handleUpdateState()
                  onClose()
                }}
                className={classes.button}>
                Aceptar
              </Button>
            )}
          </div>
        </div>
      </LayoutDialog>
    </>
  )
}

export default ChangeStateDialog
