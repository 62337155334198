import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import * as Yup from 'yup'

import useStyles from './SignInForm.style'

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Ingresa tu e-mail o nombre de usuario'),
  password: Yup.string().required('Ingresa tu contraseña')
})

const NOT_ACTIVATED_USER_ACCOUNT_MESSAGE = 'La cuenta aún no ha sido activada'

const SignInForm = ({ onSubmit, loginError, welcomeUser, onActivationManual }) => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)

  const values = {
    username: welcomeUser ? welcomeUser.eMail : '',
    password: ''
  }

  const activeManualAvailable = loginError?.message === NOT_ACTIVATED_USER_ACCOUNT_MESSAGE

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid
        }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            {welcomeUser && (
              <Typography variant='body1' className={classes.welcomeText}>
                ¡Bienvenido <strong>{welcomeUser.fullName}</strong>, ya eres un usuario de
                Sportmetric! Para continuar, inicia sesión
              </Typography>
            )}

            <TextField
              name='username'
              value={values.username}
              onChange={handleChange}
              placeholder='ejemplo@gmail.com'
              onBlur={handleBlur}
              label='E-mail o nombre de usuario'
              helperText={errors.username}
              error={touched.username && Boolean(errors.username)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
            />
            <TextField
              name='password'
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              label='Contraseña'
              helperText={errors.password}
              error={touched.password && Boolean(errors.password)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge='end'>
                      {showPassword ? (
                        <Visibility color='primary' />
                      ) : (
                        <VisibilityOff color='primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            {loginError && (
              <Typography variant='caption' color='error'>
                {loginError.message}
              </Typography>
            )}

            {activeManualAvailable ? (
              <div className={classes.infoContainer}>
                <Typography className={classes.infoText}>¿No recibiste nuestro e-mail?</Typography>
                <a href='#' onClick={onActivationManual(values)}>
                  Hace click aquí.
                </a>
              </div>
            ) : (
              <Link to={ROUTES.FORGOT_PASSWORD} className={classes.link}>
                ¿Olvidaste tu contraseña?
              </Link>
            )}

            <div className={classes.buttonContainer}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!isValid || isSubmitting}>
                  Iniciar sesión
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default SignInForm
