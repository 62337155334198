import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import clsx from 'clsx'

import { LocationPickerDialog } from '../LocationPickerDialog'

import useStyles from './LocationPicker.style'

const LocationPicker = ({
  onLocationChanged,
  latitude,
  longitude,
  mode = 'readOnly',
  mini,
  disabled,
  buttonClassName,
  variant = 'contained',
  onConfirm
}) => {
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <div className={classes.containerButton}>
        {mode === 'write' ? (
          <>
            <Button
              onClick={() => setDialogOpen(true)}
              className={clsx(classes.button, mini && 'hide')}
              disabled={disabled}
              color='primary'
              variant={variant}>
              {mini ? <RoomOutlinedIcon /> : <span>Ubicar en mapa</span>}
            </Button>
          </>
        ) : (
          <Button
            href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&zoom=20`}
            target='_blank'
            className={clsx(classes.button, 'readOnly', buttonClassName)}
            color='primary'
            variant={variant}>
            <span>Ver ubicación</span>
          </Button>
        )}
      </div>
      <LocationPickerDialog
        open={dialogOpen}
        center={{ lat: latitude, lng: longitude }}
        zoom={0}
        onClose={() => setDialogOpen(false)}
        onLocationChanged={onLocationChanged}
        onConfirm={onConfirm}
        mode={mode}
      />
    </>
  )
}

export default LocationPicker
