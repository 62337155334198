import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Formik, useFormikContext } from 'formik'
import moment from 'moment'
import { CustomTextField, NumericField } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { deleteEventBankAccount, loadEventBankAccounts } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { DeleteBankAccountDialog } from '../DeleteBankAccountDialog'
import { EditBankAccountDialog } from '../EditBankAccountDialog'
import { PaymentNotifyDialog } from '../PaymentNotifyDialog'

import { useStyles } from './BankAccountCard.style'

const CARD_STATES = {
  CARD_VIEW: 'cardView',
  CARD_FORM: 'cardForm'
}

const FORM_TYPE = {
  FORM_CREATE: 'formCreate',
  FORM_UPDATE: 'formUpdate'
}

const RADIO_STATE = {
  RADIO_ALIAS: 'alias',
  RADIO_NUMBER: 'number'
}

const DIALOG_STATES = {
  OFFLINE_CONFIRM_DIALOG: 'offlineConfirmDialog',
  OFFLINE_INFO_DIALOG: 'offlineInfoDialog'
}

const paymentNotifyInitalState = {
  open: false,
  state: DIALOG_STATES.OFFLINE_CONFIRM_DIALOG,
  onConfirm: () => {}
}

const BankAccountCard = ({
  bankAccount,
  title,
  cardState = CARD_STATES.CARD_VIEW,
  showActionbuttons,
  showCheckActive,
  saveChange,
  onSubmit,
  formType = FORM_TYPE.FORM_CREATE,
  onCloseOfflineBankAccountConfig = () => {},
  onOpenCloseOffPaymentConfig = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { values, setFieldValue } = useFormikContext() ?? {}
  const { banks, eventBankAccounts, event, paymentLocations } = useSelector((state) => state.events)

  const { alias, number, fullName, idNumber, bankName, isActive } = bankAccount

  const [checkStateValue, setCheckStateValue] = useState(isActive)
  const [openEditBankAccount, setOpenEditBankAccount] = useState(false)
  const [openDeleteBankAccount, setOpenDeleteBankAccount] = useState(false)
  const [paymentNotifyProps, setPaymentNotifyProps] = useState(paymentNotifyInitalState)

  const radioValueState = number ? RADIO_STATE.RADIO_NUMBER : RADIO_STATE.RADIO_ALIAS

  const bankAccountContent = () => (
    <main className={classes.bankAccountContent}>
      {alias ? (
        <Typography className={classes.offLineText}>Alias: {alias}</Typography>
      ) : (
        <Typography className={classes.offLineText}>CBU/CVU: {number}</Typography>
      )}
      <Typography className={clsx(classes.offLineText, classes.ellipsisText)} title={fullName}>
        Titular: {fullName}
      </Typography>
      <Typography className={classes.offLineText}>CUIT/CUIL: {idNumber}</Typography>
      <Typography className={classes.offLineText}>Banco/Billetera Virtual: {bankName}</Typography>
    </main>
  )

  const handleChangeStateBank = async (e) => {
    const checkValue = e.target.checked
    if (!checkValue) {
      const hasErrors = handlebankAccountValidate(handleConfirm)
      if (hasErrors) return
    }

    handleSaveState(checkValue)
  }

  const handlebankAccountValidate = (onConfirm) => {
    const hasActiveBankAccounts = eventBankAccounts.filter((x) => x.isActive).length === 1
    if (!hasActiveBankAccounts) return

    const hasActivePaymentLocation = paymentLocations.some((x) => x.isActive)
    const hasBankAccountTickets = values.ticketTypes
      .filter((x) => x.bankAccountsEnabled)
      .every(
        (x) =>
          (!!values.mercadoPagoCode && x.mercadoPagoEnabled) ||
          (hasActivePaymentLocation && x.paymentLocationsEnabled)
      )
    if (hasBankAccountTickets) return

    const activeInscriptions = moment().isBetween(
      moment(
        `${moment(values.openFromDate).format('YYYY-MM-DD')} ${moment(values.openFromTime).format(
          'HH:mm'
        )}`
      ),
      moment(
        `${moment(values.openToDate).format('YYYY-MM-DD')} ${moment(values.openToTime).format(
          'HH:mm'
        )}`
      )
    )

    setPaymentNotifyProps({
      open: true,
      state: activeInscriptions
        ? DIALOG_STATES.OFFLINE_INFO_DIALOG
        : DIALOG_STATES.OFFLINE_CONFIRM_DIALOG,
      onConfirm: () => onConfirm(false),
      pending: true
    })

    return true
  }

  const handleConfirm = (checkValue) => {
    handleSaveState(checkValue)
    setFieldValue(
      'ticketTypes',
      values.ticketTypes.filter((x) => !x.bankAccountsEnabled)
    )

    setPaymentNotifyProps(paymentNotifyInitalState)
  }

  const onOpenDeleteBankAccount = () => {
    const hasErrors = handlebankAccountValidate(handleOpenDeleteBankAccount)
    if (hasErrors) return

    handleOpenDeleteBankAccount()
  }

  const handleOpenDeleteBankAccount = () => {
    onCloseOfflineBankAccountConfig()
    setOpenDeleteBankAccount(true)
    setPaymentNotifyProps((state) => ({ ...state, ...paymentNotifyInitalState }))
  }

  const onDeleteBankAccount = async () => {
    const deleteSuccess = await dispatch(deleteEventBankAccount(event.id, bankAccount.id))

    if (deleteSuccess) {
      if (paymentNotifyProps.pending) {
        setFieldValue(
          'ticketTypes',
          values.ticketTypes.filter((x) => !x.bankAccountsEnabled)
        )
        setPaymentNotifyProps(paymentNotifyInitalState)
      }

      showSnackbarSuccess('¡La cuenta bancaria fue eliminada con exito!')
      dispatch(loadEventBankAccounts(event.id))

      onOpenCloseOffPaymentConfig()
      setOpenDeleteBankAccount(false)
    }
  }

  const handleSaveState = async (isActive) => {
    if (saveChange) await onSubmit({ ...bankAccount, isActive })

    bankAccount.isActive = isActive
    setCheckStateValue(isActive)
  }

  const filterBanks = (observerOptions) => async (inputValue) => {
    if (Array.isArray(observerOptions)) {
      return observerOptions.filter((e) => e.label.toLowerCase().includes(inputValue.toLowerCase()))
    }
  }

  const bankAccountForm = () => {
    const bankAccountValidate = Yup.object().shape({
      alias: Yup.string().when('radioValue', {
        is: (radioValue) => radioValue === 'alias',
        then: (schema) =>
          schema
            .nullable()
            .required('Debe ingresar el alias de la cuenta')
            .max(20, 'El alias tiene un máximo de  20 caracteres')
            .min(6, 'El Alias debe tener al menos 6 caracteres')
            .matches(/[A-Za-z0-9.-]+$/, 'El alias ingresado no es válido')
      }),
      number: Yup.string().when('radioValue', {
        is: (radioValue) => radioValue === 'number',
        then: (schema) =>
          schema
            .required('Debe ingresar el CBU/CVU de la cuenta')
            .nullable()
            .max(22, 'El CBU/CVU tiene un máximo de 22 caracteres')
            .min(22, 'El CBU/CVU debe tener 22 caracteres')
            .matches(/^[0-9]+$/, 'El CBU ingresado no es válido')
      }),
      fullName: Yup.string()
        .nullable()
        .required('Debe ingresar el nombre del titular de la cuenta')
        .max(100, 'El nombre del titular tiene un máximo de 100 caracteres')
        .matches(/^[A-Za-z]+(?:\s{0,2}[A-Za-z]+)*$/, 'El Nombre ingresado no es válido'),
      idNumber: Yup.string()
        .nullable()
        .required('Debe ingresar el CUIT/CUIL')
        .max(11, 'El CUIT/CUIL tiene un máximo de 11 caracteres')
        .min(11, 'El CUIT/CUIL debe tener 11 caracteres')
        .matches(/^[0-9]+$/, 'El CUIT/CUIL ingresado no es válido'),
      bank: Yup.mixed().nullable().required('Debe ingresar el banco')
    })

    const optionsBanks = banks.map((e) => ({
      value: e.id,
      label: e.name
    }))

    const bankAccountValues = {
      ...bankAccount,
      bank: bankAccount.bankId
        ? {
            label: bankAccount.bankName,
            value: bankAccount.bankId
          }
        : '',
      radioValue: radioValueState
    }

    return (
      <Formik
        initialValues={bankAccountValues}
        onSubmit={onSubmit}
        validationSchema={bankAccountValidate}
        enableReinitialize>
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          setFieldValue,
          setValues
        }) => (
          <form onSubmit={handleSubmit} className={classes.cardForm}>
            <div className={classes.formGroup}>
              <RadioGroup
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    radioValue: e.target.value,
                    [e.target.value]: ''
                  }))
                }}
                value={values.radioValue}>
                <FormControlLabel
                  value={RADIO_STATE.RADIO_ALIAS}
                  control={<Radio color='primary' className={classes.radio} />}
                  classes={{ label: classes.labelRadio }}
                  label='Alias'
                />
                <FormControlLabel
                  value={RADIO_STATE.RADIO_NUMBER}
                  control={<Radio color='primary' className={classes.radio} />}
                  classes={{ label: classes.labelRadio }}
                  label='CBU/CVU (número de 22 digitos)*'
                />
              </RadioGroup>
              {values.radioValue === RADIO_STATE.RADIO_ALIAS ? (
                <CustomTextField
                  className={classes.inputText}
                  value={values.alias}
                  variant='outlined'
                  autoComplete='off'
                  placeholder='Ingrese el alias de la cuenta'
                  name='alias'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.alias && Boolean(errors.alias)}
                  helperText={errors.alias}
                />
              ) : (
                <CustomTextField
                  className={classes.inputText}
                  value={values.number}
                  variant='outlined'
                  autoComplete='off'
                  placeholder={'Ingrese el número de CBU/CVU de la cuenta'}
                  name='number'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.number && Boolean(errors.number)}
                  helperText={errors.number}
                />
              )}
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                Nombre del titular*
              </Typography>
              <CustomTextField
                className={classes.inputText}
                value={values.fullName}
                variant='outlined'
                autoComplete='off'
                placeholder='Ingrese el nombre del titular de la cuenta'
                name='fullName'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={errors.fullName}
              />
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                CUIT/CUIL*
              </Typography>
              <NumericField
                className={classes.inputNumeric}
                value={values.idNumber}
                variant='outlined'
                autoComplete='off'
                placeholder={'Número de CUIT/CUIL'}
                name='idNumber'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.idNumber && Boolean(errors.idNumber)}
                helperText={errors.idNumber}
              />
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                Banco o Billetera virtual*
              </Typography>
              <StyledSelect
                autoWidth={true}
                defaultOptions={optionsBanks}
                value={values.bank}
                placeholder='Seleccione el banco o la billetera virtual'
                colorOptions='primary'
                className={classes.select}
                onChange={(option) => setFieldValue('bank', option)}
                loadOptions={filterBanks(optionsBanks)}
              />
              {errors.bank && (
                <Typography variant='caption' color='error' className={classes.errorMessage}>
                  {errors.bank}
                </Typography>
              )}
            </div>

            {formType === FORM_TYPE.FORM_CREATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting}
                className={classes.submitButton}>
                Agregar
              </Button>
            )}

            {formType === FORM_TYPE.FORM_UPDATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting}
                className={clsx(classes.submitButton, classes.saveButton)}>
                Guardar
              </Button>
            )}
          </form>
        )}
      </Formik>
    )
  }

  return (
    <div className={classes.card}>
      <header className={classes.cardHeader}>
        {showCheckActive && (
          <>
            <Checkbox
              color='primary'
              className={classes.checkbox}
              checked={checkStateValue}
              onChange={handleChangeStateBank}
            />
            <PaymentNotifyDialog
              {...paymentNotifyProps}
              onClose={() => setPaymentNotifyProps(paymentNotifyInitalState)}
            />
          </>
        )}

        {title && (
          <Typography
            color='primary'
            className={clsx({
              [classes.offLineTextTitle]: cardState === CARD_STATES.CARD_VIEW,
              [classes.offLineTextTitleForm]: cardState === CARD_STATES.CARD_FORM
            })}>
            {title}
          </Typography>
        )}

        {showActionbuttons && (
          <>
            <IconButton
              color='primary'
              size='small'
              className={classes.button}
              onClick={() => {
                onCloseOfflineBankAccountConfig()
                setOpenEditBankAccount(true)
              }}>
              <EditOutlinedIcon />
            </IconButton>

            <EditBankAccountDialog
              open={openEditBankAccount}
              title={title}
              bankAccount={bankAccount}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenEditBankAccount(false)
              }}
            />
            <IconButton
              color='primary'
              size='small'
              className={classes.button}
              onClick={onOpenDeleteBankAccount}>
              <DeleteOutlineIcon />
            </IconButton>
            <DeleteBankAccountDialog
              open={openDeleteBankAccount}
              bankAccount={bankAccount}
              title={title}
              onDelete={onDeleteBankAccount}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenDeleteBankAccount(false)
                setPaymentNotifyProps(paymentNotifyInitalState)
              }}
            />
          </>
        )}
      </header>
      {cardState === CARD_STATES.CARD_VIEW && bankAccountContent()}

      {cardState === CARD_STATES.CARD_FORM && bankAccountForm()}
    </div>
  )
}

export default BankAccountCard
