import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    zIndex: '5000 !important'
  },
  bodyContainer: {
    width: '100% !important',
    margin: 0
  },
  table: {
    width: '100%',
    borderSpacing: '0 !important'
  },
  content: {
    overflow: 'auto'
  },
  head: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16)
  },
  headCol: {
    fontSize: '1rem',
    fontWeight: 600,
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(),
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      padding: theme.spacing(1, 4),
      width: 350
    }
  },
  tableBody: {
    backgroundColor: '#ffff',
    '& > :nth-child(2n)': {
      backgroundColor: '#D9D9D95E'
    }
  },
  row: {
    height: 40,
    width: '100%'
  },
  col: {
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    fontSize: '1rem',
    width: 150,
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'Ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 4),
      width: 350,
      maxWidth: 350
    }
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    margin: theme.spacing(3, 0),
    justifyContent: 'center'
  },
  button: {
    width: 200,
    height: 40,
    '& span': {
      fontSize: '1rem',
      fontWeight: 500
    }
  }
}))
