import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 900,
      maxWidth: 900
    }
  },

  formContainer: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '90%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      margin: '0 auto'
    }
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& span': {
      fontWeight: 500,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },

    '& input': {
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(),
    '& span': {
      fontWeight: 500
    }
  },
  textLink: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(5)
  },
  underlineText: {
    fontWeight: 600,
    textDecoration: 'underline',
    marginTop: theme.spacing(5)
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid #C8C8C8`,
    padding: theme.spacing(),
    paddingRight: 0,
    justifyContent: 'space-between',
    '& > * ': {
      width: '30%'
    }
  },

  quota: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(2),
    '& input': {
      maxWidth: 145
    },
    '& fieldset': {
      maxWidth: 200
    },
    [theme.breakpoints.up('md')]: {
      '& > div > p': {
        maxWidth: 150
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, 0),
    marginTop: theme.spacing(8)
  },
  button: {
    padding: theme.spacing(1, 9)
  },
  labelQuota: {
    marginTop: theme.spacing(3)
  },
  optionDisabled: {
    '& p, span': {
      color: '#969696'
    }
  },
  distanceQuota: {
    [theme.breakpoints.up('md')]: {
      '& > p': {
        maxWidth: 250
      }
    }
  },
  errorMessage: {
    padding: theme.spacing(2, 0),
    fontSize: '.75rem'
  }
}))
