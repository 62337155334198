import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { EditableSection, EditableText } from 'shared'
import {
  deleteRulesDocument,
  downloadRulesDocument,
  uploadRulesDocument
} from 'state/modules/events'
import { STRING_EMPTY } from 'utils/constants'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './RulesSection.style'

const RulesSection = ({ event, canEdit, onSave, isMobile, ...sectionProps }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const eventRulesInputFileRef = useRef()
  const { uploadRulesDocumentPending, downloadRulesDocumentPending, deleteRulesDocumentPending } =
    useSelector((state) => state.events)

  const values = {
    ...event,
    rulesFileNameOrUrl: event.rulesDocumentFile?.fileName.split('rules-')[1] || event.rulesUrl
  }

  const validationSchema = Yup.object().shape({
    rulesUrl: Yup.string()
      .transform((value) =>
        !!value && !value.includes('http://') && !value.includes('https://')
          ? `http://${value}`
          : value
      )
      .nullable()
      .trim()
      .url('Debe ingresar una url valida')
  })

  const { rulesUrl = '' } = event

  const handleChangeRulesEvent = (setFieldValue, values) => async (e, onClearField) => {
    const text = e.target.value

    const deleteRulesFile =
      values.rulesDocumentFile && values.rulesFileNameOrUrl?.length > text?.length

    if (deleteRulesFile) await onDeleteRulesFile(setFieldValue, values)(onClearField)
    else setFieldValue('rulesUrl', e.target.value)
  }

  const handleInputFile = () => eventRulesInputFileRef.current.click()

  const onDeleteRulesFile = (setFieldValue, values) => async (onClearField) => {
    try {
      setFieldValue('sectionState', false)

      if (values.rulesDocumentFile) {
        setFieldValue('rulesDocumentFile', null)
        onClearField()
        setFieldValue('rulesFileNameOrUrl', '')
        setFieldValue('rulesUrl', '')
      }
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setFieldValue('sectionState', true)
    }
  }

  const handleDownloadRulesDocument = async () => {
    await dispatch(downloadRulesDocument(event))
  }

  const handleOnchangeFile = (setValues) => async (e) => {
    if (e.target.files.length === 0) return

    const file = e.target.files[0]
    const { type: documentType, size } = file

    e.target.value = ''

    if (documentType !== 'application/pdf') {
      showSnackbarError({ message: 'El formato del documento no es válido' })
    } else if (size / 1048576 > 20) {
      showSnackbarError({ message: 'El tamaño del archivo no debe superar los 20MB' })
    } else {
      setValues((values) => ({
        ...values,
        rulesFileNameOrUrl: file.name,
        rulesDocumentFile: file,
        rulesFileName: file.name,
        rulesUrl: STRING_EMPTY
      }))
    }
  }

  const handleOnSave = async ({ value }) => {
    const sendRulesDocumentFileNotification =
      !value.rulesUrl && event.rulesDocumentFile?.fileName !== value.rulesFileName

    if (sendRulesDocumentFileNotification) {
      value.rulesUrl = values.rulesUrl === STRING_EMPTY ? null : STRING_EMPTY

      if (value.rulesDocumentFile) {
        await dispatch(uploadRulesDocument(event.id, value.rulesDocumentFile))
      } else {
        await dispatch(deleteRulesDocument(event.id))
      }
    }

    await onSave({ value })

    showSnackbarSuccess('¡Se actualizaron las reglas del evento!')

    return true
  }

  return (
    <EditableSection
      entity={values}
      canEdit={canEdit}
      validationSchema={validationSchema}
      onSave={handleOnSave}
      {...sectionProps}>
      {({
        activeEdit,
        values,
        errors,
        setValues,
        handleBlur,
        isValid,
        setFieldValue,
        setActiveEdit,
        handleSubmit,
        saveLoading
      }) => (
        <SectionBlock
          title='Reglamento del evento'
          className={classes.eventRulesSection}
          hiddenSection={!canEdit && !event.rulesDocumentFile && !event.rulesUrl}>
          <EditableText
            entity={values}
            field='rulesFileNameOrUrl'
            activeEditSection={activeEdit}
            type={values.rulesDocumentFile ? 'text' : 'url'}
            handleBlur={handleBlur}
            fullWidth
            onChange={handleChangeRulesEvent(setFieldValue, values)}
            externalChange={values.rulesDocumentFile}
            inputClassName={classes.eventinputLink}
            placeHolder='Pegue aquí el link o adjunte archivo del reglamento de su evento'
            errors={errors.rulesUrl}>
            {rulesUrl ? (
              <a
                href={rulesUrl}
                className={classes.eventLink}
                rel='noreferrer'
                title={rulesUrl}
                target='_blank'>
                {rulesUrl}
              </a>
            ) : (
              !values.rulesDocumentFile &&
              canEdit &&
              !activeEdit && (
                <Typography
                  color='primary'
                  className={classes.addEventRulesLink}
                  onClick={() => setActiveEdit(true)}>
                  + Añadir reglamento
                </Typography>
              )
            )}
          </EditableText>

          {activeEdit ? (
            <div className={classes.uploadRulesDocumentContainer}>
              <input
                type='file'
                style={{ display: 'none' }}
                accept='.pdf'
                onChange={handleOnchangeFile(setValues)}
                ref={eventRulesInputFileRef}
              />
              <div className={classes.buttonContainer}>
                {(!!values.rulesUrl || !!values.rulesDocumentFile) && (
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={
                      uploadRulesDocumentPending ||
                      deleteRulesDocumentPending ||
                      saveLoading ||
                      !isValid
                    }
                    endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
                    onClick={handleSubmit}
                    className={clsx(classes.eventRulesButton, classes.eventRulesUploadButton)}>
                    Guardar
                  </Button>
                )}

                {!values.rulesUrl && !values.rulesDocumentFile && (
                  <Button
                    color='primary'
                    variant='contained'
                    endIcon={
                      uploadRulesDocumentPending && <CircularProgress color='primary' size={16} />
                    }
                    disabled={uploadRulesDocumentPending || deleteRulesDocumentPending}
                    className={clsx(classes.eventRulesButton, classes.eventRulesUploadButton)}
                    onClick={handleInputFile}>
                    Adjuntar archivo
                  </Button>
                )}
              </div>
            </div>
          ) : (
            !values.rulesUrl &&
            values.rulesDocumentFile && (
              <Button
                color='primary'
                variant='contained'
                endIcon={
                  downloadRulesDocumentPending && <CircularProgress color='inherit' size={16} />
                }
                onClick={handleDownloadRulesDocument}
                className={classes.eventRulesButton}>
                Descargar reglamento
              </Button>
            )
          )}
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default RulesSection
