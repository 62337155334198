import React, { useEffect, useMemo, useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'

import { useStyles } from './MultiFormatSelector.style'

const MultiFormatSelector = ({ entity = {}, onChange, errors, handleBlur }) => {
  const classes = useStyles()
  const [selectedFormat, setSelectedFormat] = useState('')
  const [optionList, setOptionList] = useState([])
  const formatList = useMemo(
    () => [
      {
        label: 'Archivo adjunto',
        value: 'File',
        helperTextTitle: 'Instrucciones para el archivo adjunto',
        placeholder: 'Brindale información útil al usuarió sobre lo que tiene que adjuntar. '
      },
      { label: 'Lista desplegable', value: 'List' },
      { label: 'Número', value: 'Number' },
      {
        label: 'Si/No',
        value: 'Boolean',
        helperTextTitle: 'Pregunta',
        placeholder: 'Debe poder contestarse con si ó no'
      },
      { label: 'Texto', value: 'Text' }
    ],
    []
  )

  useEffect(() => {
    setSelectedFormat(formatList.find(({ value }) => value === entity.type) || '')
  }, [entity, formatList])

  useEffect(() => {
    const handleAddOptions = () => {
      const canAddOption =
        entity.options.filter((e) => !!e.name?.trim()).length !== 1 || entity.options.length !== 2

      return canAddOption ? [...entity.options, { name: '' }] : [...entity.options]
    }

    const options = !entity.options.length ? [{ name: '' }, { name: '' }] : handleAddOptions()
    setOptionList(options)
  }, [entity])

  const handleOnChangeOption = (option) => {
    setSelectedFormat(option)
    onChange('type', option.value)
  }

  const handleChangeValueOption = (event, index) => {
    optionList[index] = { name: event.target.value }

    const filterOptionList = optionList.filter((e) => !!e.name?.trim())
    const canAddOption = filterOptionList.length !== 1 || optionList.length !== 2

    const options = canAddOption ? filterOptionList : optionList

    onChange('options', [...options])
  }

  const filterFormatList = async (inputValue) =>
    formatList.filter(({ label }) => label.toLowerCase().includes(inputValue.toLowerCase()))

  return (
    <>
      <Typography color='primary' variant='h6'>
        Tipo de campo*
      </Typography>
      <StyledSelect
        defaultOptions={formatList}
        name='selectedFormat'
        loadOptions={filterFormatList}
        value={selectedFormat}
        onBlur={handleBlur}
        onChange={handleOnChangeOption}
        colorOptions='secondary'
        placeholder='Seleccione un formato'
        className={classes.select}
      />
      {errors.type && (
        <Typography className={classes.errorMessage} color='error' variant='caption'>
          {errors.type}
        </Typography>
      )}

      {!!selectedFormat && (
        <div className={classes.additionalField}>
          <Typography color='primary' variant='h6'>
            {selectedFormat.helperTextTitle || ' Datos extras'}
          </Typography>
          <TextField
            className={classes.textArea}
            variant='outlined'
            size='small'
            multiline
            fullWidth
            autoComplete='off'
            name='helperText'
            onBlur={handleBlur}
            value={entity.helperText}
            onChange={(event) => onChange('helperText', event.target.value)}
            placeholder={
              selectedFormat.placeholder ||
              'Es optativo agregar alguna instrucción o dato extra sobre lo que debe responder el deportista.'
            }
          />
          {selectedFormat.value === 'List' &&
            optionList.map((el, index) => (
              <TextField
                key={index}
                variant='outlined'
                size='small'
                fullWidth
                name={`options[${index}]`}
                value={el.name}
                autoComplete='off'
                onBlur={handleBlur}
                helperText={
                  Array.isArray(errors.options)
                    ? errors.options[index]?.name
                    : index === 0 && errors.options
                }
                error={
                  Array.isArray(errors.options)
                    ? !!errors.options[index]?.name
                    : index === 0 && !!errors.options
                }
                onChange={(e) => handleChangeValueOption(e, index)}
                className={classes.textField}
                placeholder={`Opción ${index + 1}`}
              />
            ))}
        </div>
      )}
    </>
  )
}

export default MultiFormatSelector
