import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 50,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      paddingTop: 80
    }
  },
  mainContainer: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      margin: '20px 0px',
      width: 1280,
      justifyContent: 'space-between'
    }
  },

  sectionBlock: {
    backgroundColor: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.up('lg')]: {
      width: 1150,
      gap: theme.spacing(4),
      padding: `${theme.spacing(4)}px ${theme.spacing(7)}px`
    }
  },

  formGroup: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: 0,
      justifyContent: 'space-between'
    }
  },

  organizationFormGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 450
    }
  },
  paragraph: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  combo: {
    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  input: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      width: 450
    },

    '& fieldset': {
      height: 48,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& input': {
      height: 23,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    },
    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '1rem',
      transform: 'translate(14px, 11px) scale(1)'
    },

    '&.multiline': {
      '& textarea': {
        minHeight: '195px'
      }
    }
  },
  buttonsContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },

    '& > label': {
      alignSelf: 'flex-start'
    },
    '& > button ': {
      height: 48,
      borderRadius: 8,
      width: 248,
      '& span': {
        fontSize: '1.25rem',
        fontWeight: 500,
        lineHeight: '20px'
      }
    }
  },

  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    transform: 'translate(14px, 13px) scale(1)'
  },
  select: {
    height: 48,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: 450
    },

    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(0.5)
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    }
  },
  formControl: {
    marginRight: 0
  },
  checkLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '.875rem'
  },
  termsAndConditionsLabel: {
    color: ' #000000',
    fontWeight: 400,
    fontSize: '.75rem'
  },
  termsAndConditionsLink: {
    color: theme.palette.primary.main
  },
  checkbox: {
    '& svg': {
      width: 20,
      height: 20,
      color: theme.palette.primary.main
    }
  },
  infoIcon: {
    width: 23,
    height: 23
  },
  tooltip: {
    width: 300,
    backgroundColor: theme.palette.tooltip.main
  },
  adminToltip: {
    backgroundColor: theme.palette.tooltip.main,
    width: 300,
    maxWidth: 300,
    padding: `${theme.spacing(1, 2)} !important`,
    [theme.breakpoints.up('md')]: {
      width: 530,
      maxWidth: 530,
      '&.large': {
        width: 635,
        maxWidth: 635
      }
    }
  },
  tooltipTitle: {
    fontSize: '.75rem'
  },
  adminTooltipButton: {
    top: '30px !important',
    right: '10px !important',
    '& svg': {
      width: 13,
      height: 13
    },
    [theme.breakpoints.up('md')]: {
      top: '5px !important',
      right: '20px !important'
    }
  },
  tooltipButton: {
    right: '3px !important',
    top: '25px !important',
    [theme.breakpoints.up('md')]: {
      top: '15px !important'
    }
  },
  tooltipPopper: {
    zIndex: 4000
  },
  tooltipLink: {
    color: theme.palette.primary.contrastText
  },
  tooltipLinkBlue: {
    color: theme.palette.primary.main
  },
  comboInput: {
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },
    [theme.breakpoints.up('md')]: {
      width: 450
    },
    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    }
  },
  closeIcon: {
    width: 20,
    height: 20
  },
  acceptLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(),
    fontSize: '.875rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginRight: -theme.spacing(10)
    }
  },
  disciplinesTitle: {
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem'
  },
  acceptDisabledLink: {
    textDecoration: 'underline',
    marginTop: theme.spacing(),
    fontSize: '.875rem',
    fontWeight: 600,
    color: '#696969',
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      marginRight: -theme.spacing(10)
    }
  },
  checkContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  adminTitle: {
    fontSize: '.875rem',
    fontWeight: 600,
    textDecoration: 'underline'
  },
  helperText: {
    marginLeft: theme.spacing(1.5)
  },
  link: {
    textDecoration: 'underline',
    fontSize: '.875rem',
    fontWeight: 600,
    cursor: 'pointer'
  },
  deleteIcon: {
    width: 20,
    height: 20
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1, 0),
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 450
    }
  },

  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing()
  },
  disciplinesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 450
    }
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(2)
    }
  }
}))

export default useStyles
