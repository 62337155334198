import { store } from 'react-notifications-component'

function getErrorMessage(error) {
  if (error && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message
  }

  return error.message
}

export const showSnackbarError = (error, title = 'Error', timeout = 2000) => {
  store.addNotification({
    title,
    message: getErrorMessage(error),
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: timeout,
      onScreen: true
    }
  })
}

export const showSnackbarSuccess = (message, title = 'Excelente', timeout = 2000) => {
  store.addNotification({
    title,
    message,
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: timeout,
      onScreen: true
    }
  })
}
