import React, { useState } from 'react'
import { NavBar } from 'pages/Landing/OrganizationPage/components/NavBar'
import { MainLayout, PageContainer } from 'shared'

import { OnboardingCover, OnboardingStepper } from './components'
import { useStyles } from './OnboardingPage.style'

const ONBOARDING_STATES = {
  ONBOARDING_COVER: 'onboardingCover',
  ONBOARDING_STEPPER: 'onboardingStepper'
}

const OnboardingPage = () => {
  const [onboardingState, setOnboardingState] = useState(ONBOARDING_STATES.ONBOARDING_COVER)
  const classes = useStyles()

  return (
    <MainLayout>
      <PageContainer
        customContainerClassName={
          onboardingState === ONBOARDING_STATES.ONBOARDING_COVER
            ? classes.containerCover
            : classes.container
        }>
        <NavBar />
        {onboardingState === ONBOARDING_STATES.ONBOARDING_COVER && (
          <OnboardingCover
            handleChangeState={() => setOnboardingState(ONBOARDING_STATES.ONBOARDING_STEPPER)}
          />
        )}
        {onboardingState === ONBOARDING_STATES.ONBOARDING_STEPPER && <OnboardingStepper />}
      </PageContainer>
    </MainLayout>
  )
}

export default OnboardingPage
