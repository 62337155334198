import { makeStyles } from '@material-ui/styles'
import logoImage from 'assets/img/logo.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    paddingTop: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      minHeight: '89.5vh'
    }
  },
  logo: {
    backgroundImage: `url(${logoImage})`,
    width: 251,
    height: 26
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 500
  },
  formContainer: {
    backgroundColor: 'white',
    boxShadow: '0 0 1px 0 #aaaaaa',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(6)
  }
}))
