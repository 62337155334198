import { makeStyles } from '@material-ui/core'
import backgroundImage from 'assets/img/Landing_HomePC.jpg'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '80vh',
    position: 'relative',
    marginTop: theme.spacing(7),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      marginTop: theme.spacing(10),
      width: 1280,
      margin: '0 auto'
    },
    '&.secondary': {
      marginTop: '24px !important',
      padding: '0 !important',
      [theme.breakpoints.up('md')]: {
        marginTop: '38px !important'
      },
      [theme.breakpoints.up('lg')]: {
        width: 'auto'
      }
    }
  },
  stepper: {
    marginTop: theme.spacing(20),
    padding: theme.spacing(3, 0)
  },
  stepContent: {
    border: 'none'
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignSelf: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(6),
    width: '100%',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      width: 'auto',
      justifyContent: 'flex-start'
    }
  },
  connector: {
    '& span': {
      borderTopWidth: 3
    }
  },
  activeConnector: {
    '& span': {
      borderColor: theme.palette.primary.main,
      borderTopWidth: 3
    }
  },
  backLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '.875rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem'
    }
  },
  nextButton: {
    borderRadius: 10,
    width: 160,

    '& span': {
      fontSize: '1rem'
    },

    [theme.breakpoints.up('md')]: {
      width: 270
    }
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    alignItems: 'flex-start',
    minHeight: 400,
    [theme.breakpoints.up('md')]: {
      minHeight: 500
    }
  },
  step: {
    padding: 0,
    position: 'relative'
  },
  stepIcon: {
    '& text': {
      fontWeight: 700
    }
  },

  activeStep: {
    '& text': {
      fill: theme.palette.secondary.main
    }
  },
  stepTitle: {
    fontWeight: 700,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  stepSubTitle: {
    fontWeight: 400,
    lineHeight: '20px',
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  iconContainer: {
    padding: 0
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(7)
    }
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4)
    }
  },
  formControlLabel: {
    fontWeight: 500,
    marginLeft: theme.spacing(3),
    color: theme.palette.primary.main,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    }
  },
  webLabel: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25rem'
    }
  },
  control: {
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  womanRunnerIcon: {
    position: 'absolute',
    top: -150,
    zIndex: 1,
    left: 16,

    '&.position-1': {
      left: -20,
      top: -147
    },
    '&.position-2': {
      left: -33
    },
    '&.position-3': {
      left: -23
    },
    '&.position-4': {
      left: -14
    },
    '&.position-5': {
      left: -68
    }
  },
  menRunnerIcon: {
    position: 'absolute',
    top: -150,
    zIndex: 10,
    left: 0,
    '&.position-1': {
      left: -3
    },
    '&.position-2': {
      left: -45
    },
    '&.position-3': {
      left: -30
    },
    '&.position-4': {
      left: -22
    },
    '&.position-5': {
      left: -60
    }
  },
  finishLineIcon: {
    position: 'absolute',
    top: -100,

    left: -68,
    zIndex: 10
  },
  stepHelperText: {
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    fontSize: '.875rem'
  },
  addDisciplineLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '1rem',
    marginBottom: -theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    }
  },
  backgroundImage: {
    display: 'flex',
    background: `url(${backgroundImage})`,
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '31.6vw',
    [theme.breakpoints.up('lg')]: {
      height: '31vw'
    }
  },
  finishContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  socialNetworkContainer: {
    backgroundColor: theme.utils.rgba('#DFE0E0E5', 0.8),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    margin: theme.spacing(0, 2),
    boxShadow: '0px 4px 4px 0px #00000040',
    padding: theme.spacing(1, 2),
    marginTop: -theme.spacing(11),
    [theme.breakpoints.up(503)]: {
      marginTop: -theme.spacing(9)
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
      marginTop: '-25vw',
      padding: theme.spacing(1.5, 4)
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: -theme.spacing(50)
    }
  },
  socialNetworkTitle: {
    fontWeight: 700,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.1875rem'
    }
  },
  socialNetworkSubtitle: {
    fontSize: '.875rem',
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem'
    }
  },
  finishFormControl: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  instagramIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 28,
    borderRadius: 7,
    padding: 2,
    height: 28,
    marginTop: theme.spacing(),
    marginRight: theme.spacing(),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2.5)
    }
  },
  facebookIcon: {
    marginLeft: -theme.spacing(2),
    marginRight: theme.spacing(),
    height: '43px !important',
    width: '43px !important',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2.5)
    }
  },
  youtubeIcon: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(),
    height: '61px !important',
    width: '61px !important',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(0.5)
    }
  },
  formControl: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: '#fff',
      paddingRight: theme.spacing(),
      transform: 'translate(14px, 12px) scale(1)'
    },

    '& input': {
      fontWeight: 500
    },
    '& > div': {
      backgroundColor: theme.palette.primary.contrastText,
      height: 40
    }
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    margin: theme.spacing(0, 2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      width: 700
    }
  },
  commentTitle: {
    fontSize: '.875rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem'
    }
  },
  textArea: {
    '& > div': {
      color: theme.palette.primary.main,
      backgroundColor: '#DFE0E0'
    },
    '& textarea': {
      minHeight: 160
    }
  },
  sendButton: {
    marginTop: theme.spacing(4),
    borderRadius: 10,
    width: 160,
    height: 40,
    '& span': {
      fontSize: '1.125rem'
    },
    [theme.breakpoints.up('md')]: {
      width: 248,
      height: 46
    }
  },
  addDisciplineContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'flex-start'
  },
  addLink: {
    marginTop: theme.spacing(),
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '.875rem'
  },
  addDisabledLink: {
    marginTop: theme.spacing(),
    textDecoration: 'underline',
    fontSize: '.875rem',
    color: theme.palette.disabled.main
  },
  disciplineTextField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  }
}))
