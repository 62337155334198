import React from 'react'
import Draggable from 'react-draggable'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  useTheme
} from '@material-ui/core'
import { Transition } from 'utils'

const paperComponent = (props) => (
  <Draggable
    defaultPosition={{ x: 0, y: 0 }}
    handle='#draggable-dialog-title'
    cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
)

const ConfirmDialog = ({
  openDialog,
  setOpen,
  message,
  type,
  onAction,
  titleClassName,
  title = 'Mensaje de confirmación'
}) => {
  const theme = useTheme()

  const typeDialog = () => {
    switch (type) {
      case 'info':
        return {
          color: theme.palette.primary.main,
          action: 'Continuar'
        }
      case 'delete':
        return {
          color: '#f44336',
          action: 'Eliminar'
        }
      default:
        return {
          color: theme.palette.primary.main,
          action: 'Aceptar'
        }
    }
  }

  const { color, action } = typeDialog()

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      onClose={() => setOpen(false)}
      PaperComponent={paperComponent}
      aria-labelledby='draggable-dialog-title'
      aria-describedby='alert-dialog-slide-description'>
      <DialogTitle
        style={{ cursor: 'move' }}
        id='draggable-dialog-title'
        className={titleClassName}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false)
            onAction()
          }}
          style={{ color }}>
          {action}
        </Button>
        {type !== 'info' && (
          <Button onClick={() => setOpen(false)} color='primary'>
            Cancelar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
