import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.4),
    padding: 0
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    '& > div > div': {
      minHeight: 300,
      backgroundColor: theme.palette.primary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      width: 555,
      maxWidth: 555,
      margin: '0 auto'
    }
  },
  dialogContent: {
    padding: 0
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -theme.spacing(5)
  },
  message: {
    fontWeight: 600,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.625rem'
    }
  }
}))
