import { makeStyles } from '@material-ui/styles'
import logoImage from 'assets/img/icon_sportmetric_simple.png'
import image from 'assets/img/waterMark-Sportmetric.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(4),
    width: '100%',
    minHeight: 50,
    [theme.breakpoints.up('md')]: {
      minHeight: 80
    }
  },
  containerAlt: {
    backgroundColor: 'white'
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1260
    },
    '&.showFooterContent': {
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row-reverse',
        marginTop: theme.spacing(4)
      }
    }
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${logoImage})`,
    width: 69,
    height: 69,
    marginTop: -theme.spacing(8.5),
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      order: 1,
      marginTop: -theme.spacing(17)
    }
  },
  watermark: {
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundImage: `url(${image})`,
      width: 210,
      height: 30,
      marginTop: -3
    }
  },
  footerBlock: {
    display: 'flex',
    paddingLeft: 0,
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(2),
    '& h6': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        fontWeight: 500,
        marginTop: -3
      }
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '90%',
      justifyContent: 'space-evenly'
    }
  },
  list: {
    listStyle: 'none',
    '& li': {
      width: 180,
      [theme.breakpoints.up('md')]: {
        width: 'auto'
      }
    },
    '& a': {
      color: theme.palette.primary.main,
      fontSize: '.9rem',
      textDecoration: 'none',
      fontWeight: 400
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
      width: '60%',
      justifyContent: 'space-evenly'
    }
  },
  followContainer: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'flex-end',
    '& p': {
      fontWeight: 500,
      marginBottom: theme.spacing()
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      marginTop: 5
    }
  },
  facebookIcon: {
    marginTop: -18
  },
  instagramIcon: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: -theme.spacing(0.5)
    }
  }
}))

export default useStyles
