import React, { useState } from 'react'
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { CustomizedTooltip, StyledSwitch } from 'shared'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './StateSection.style'

const EVENT_STATES = [
  { value: 'Active', label: 'Activo' },
  { value: 'Cancelled', label: 'Cancelado' },
  { value: 'Suspended', label: 'Suspendido' }
]

const StateSection = () => {
  const classes = useStyles()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { values, handleBlur, handleChange, errors } = useFormikContext()

  const [openTooltip, setOpenTooltip] = useState({})
  return (
    <SectionBlock title='Estado del Evento'>
      <div className={classes.content}>
        <FormControl variant='outlined'>
          <InputLabel id='discipline-select-outlined-label' className={classes.labelSelect}>
            Seleccione el estado de su evento
          </InputLabel>
          <Select
            value={values.state}
            onBlur={handleBlur}
            onChange={handleChange}
            name='state'
            className={classes.select}>
            {EVENT_STATES.map((x, i) => (
              <MenuItem value={x.value} key={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>

          <Typography variant='caption' className={classes.helperText}>
            {errors.state || 'Seleccione el estado para su evento '}
          </Typography>
        </FormControl>
        <div className={classes.eventPublishedContainer}>
          <CustomizedTooltip
            title='Si el switch está activado, el evento es visible para todos los usuarios.'
            position={isDesktop ? 'top' : 'bottom-start'}
            autoOpenTooltip={openTooltip}
            disabledClickAway
            className={classes.tooltip}
            buttonClassName={classes.tooltipCloseButton}>
            {({ handleTooltip }) => (
              <IconButton color='primary' size='small' onClick={handleTooltip}>
                <InfoIcon />
              </IconButton>
            )}
          </CustomizedTooltip>
          <Typography variant='h6' className={classes.eventPublushedTitle}>
            Evento publicado
          </Typography>
          <StyledSwitch
            checked={values.isPublished}
            onChange={(e) => {
              if (!values.isPublished) setOpenTooltip({ state: true })
              handleChange(e)
            }}
            name='isPublished'
          />
        </div>
      </div>
    </SectionBlock>
  )
}

export default StateSection
