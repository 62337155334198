import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer } from 'shared'

import { useStyles } from './JobPage.style'

const JobPage = () => {
  const classes = useStyles()

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode customContainerClassName={classes.mainContainer}>
        <div className={classes.container}>
          <Typography color='primary' className={classes.title} variant='h3'>
            ¿Querés ser asistente de cronometraje Sportmetric?
          </Typography>
          <div className={classes.bodyContainer}>
            <Typography className={classes.textDescription}>
              Buscamos personas con buenas energías, de hasta 40 años y con ganas de aprender, que
              le gusten los deportes -cronometrados, obvio- y que tenga ganas de hacerse de un
              ingreso extra por trabajar con muchas pilas los fines de semana.
            </Typography>
            <Button
              color='primary'
              variant='contained'
              target='_blank'
              href='https://docs.google.com/forms/d/e/1FAIpQLSc-2f-XO0DPVaj_WyL2cdagGRrUvdbRajMQrUx-t1_BXg88ZA/viewform'
              className={classes.button}>
              Quiero postularme
            </Button>
          </div>
        </div>
      </PageContainer>

      <Footer />
    </MainLayout>
  )
}

export default JobPage
