import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      maxWidth: 1920
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      width: 920,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 480
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(7)
  },
  subTitle: {
    color: '#696969'
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    padding: theme.spacing(4, 3),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  linkExternalContainer: {
    padding: theme.spacing(6, 4),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 650
    }
  },
  linkExternalSubtitle: {
    fontWeight: 600
  },
  helperText: {
    fontSize: '.875rem'
  },
  infoContainer: {
    margin: '24px auto',
    width: '100%',
    marginBottom: 0,
    [theme.breakpoints.up('lg')]: {
      width: 600
    }
  },
  infoText: {
    fontWeight: 500,
    color: '#000000',
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    flexDirection: 'column',
    '& p': {
      fontWeight: 500,
      color: '#000000',
      fontSize: 15
    },
    '& li': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      marginBottom: theme.spacing(2),
      '&.align-start': {
        alignItems: 'flex-start'
      }
    },
    '& svg': {
      fontSize: 20
    }
  },
  createButton: {
    fontSize: '.9125rem',
    alignSelf: 'center',
    padding: theme.spacing(1, 3)
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(),
    '& input': {
      height: 2
    },
    '& fieldset': {
      height: 43,
      borderColor: theme.palette.primary.main
    }
  },
  linkText: {
    marginTop: theme.spacing(4),
    fontSize: '.9rem',
    alignSelf: 'center',
    '& strong': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  timingLink: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  linkForm: {
    display: 'flex',
    flexDirection: 'column'
  },
  linkBodyContainer: {
    margin: theme.spacing(7, 0),
    marginBottom: theme.spacing(9)
  },
  saveUrlButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 9)
  },
  paymentTypeInfo: {
    '& p': {
      marginLeft: theme.spacing(0.5)
    }
  }
}))
