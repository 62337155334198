import React from 'react'
import AsyncSelect from 'react-select/async'
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core'

export const StyledSelect = (props) => {
  const theme = useTheme()
  const {
    placeholderColor,
    placeholder = 'Seleccioná una opción',
    colorOptions = 'secondary',
    noOptionsMessage = 'Sin opciones',
    onBlur,
    name,
    isDisabled,
    loadingMessage = 'Cargando...'
  } = props

  return (
    <AsyncSelect
      {...props}
      theme={(defaultTheme) => ({
        ...defaultTheme,
        colors: {
          ...defaultTheme.colors,
          ...theme.comboBox.palette[colorOptions]
        }
      })}
      onBlur={() => onBlur && onBlur({ target: { name } })}
      noOptionsMessage={() => noOptionsMessage}
      loadingMessage={() => loadingMessage}
      placeholder={placeholder}
      components={{
        ...props.components,
        IndicatorSeparator: () => null,
        SeparatorIncator: () => null,
        ClearIndicator: () => null,
        LoadingIndicator: () => loadingComponent()
      }}
      styles={{
        ...props.styles,
        option: (provided, state) => {
          const buttonOption = state.value === 'button' && {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            textAlign: 'center'
          }

          const optionError = state.data.state === 'error' && {
            backgroundColor: '#FF2929',
            color: theme.palette.primary.contrastText,
            '&:hover': {
              backgroundColor: theme.utils.rgba('#FF2929', 0.8)
            }
          }

          const StyledOption = {
            color: state.isSelected && theme.palette[colorOptions].contrastText,
            '&:hover': {
              color: theme.palette[colorOptions].contrastText
            }
          }

          return {
            ...provided,
            ...StyledOption,
            ...buttonOption,
            ...optionError
          }
        },
        dropdownIndicator: (provided, { selectProps }) => ({
          ...provided,
          height: 36,
          display: !selectProps.isLoading ? 'block' : 'none'
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          backgroundColor: !isDisabled ? theme.palette.primary.main : theme.palette.disabled.main
        }),
        placeholder: (provided) => ({
          ...provided,
          color: placeholderColor
        })
      }}
    />
  )
}

const loadingComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.LoadingIndicatorContainer}>
      <CircularProgress color='secondary' size={18} />
    </div>
  )
}

export const useStyles = makeStyles((theme) => ({
  editMode: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 20
    }
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  editModeAlign: {
    alignItems: 'flex-start'
  },
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column'
    }
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }
  },
  disciplinesContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  disciplineContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  selectContainer: {
    width: '100%'
  },
  select: {
    color: theme.palette.primary.main,
    '& svg': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(7)
    }
  },
  error: {
    marginTop: theme.spacing(),
    marginLeft: theme.spacing()
  },
  button: {
    width: '50%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: 0
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  linkEmpty: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(),
    cursor: 'pointer',
    fontWeight: 600,
    alignSelf: 'flex-start',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  link: {
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    cursor: 'pointer',
    fontWeight: 600,
    alignSelf: 'flex-start',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  input: {
    margin: 0,
    marginTop: theme.spacing(3),
    width: '100%',
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    [theme.breakpoints.up('lg')]: {
      width: 247
    }
  },
  LoadingIndicatorContainer: {
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
