import React, { useEffect, useState } from 'react'
import { numericFormatter } from 'react-number-format'
import { useSelector } from 'react-redux'
import { Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { CustomizedTooltip, SnackbarMessage } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { PRICE_FORMAT, STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './TicketSection.style'

const ORDER_PAYMENT = {
  MERCADO_PAGO: { value: 'MercadoPago', label: 'Mercado Pago', title: 'Mercado Pago' },
  PAYMENT_LOCATION: {
    value: 'PaymentLocation',
    label: 'Pago en efectivo',
    title: 'Pago en efectivo'
  },
  BANK_ACCOUNT: { value: 'BankAccount', label: 'Transferencia', title: 'Pago con transferencia' },
  PAYMENT_URL: { value: 'PaymentUrl', label: 'Link de pago', title: 'Link de pago' }
}

const FORM_STATE = {
  read: 'read',
  create: 'create'
}

const TICKET_FREE_PRICE = 0

const TicketSection = ({ formMode, formData, autoHidden, viewAdmin }) => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const { event } = useSelector((state) => state.events)

  const { values: formState, setValues, setFieldValue } = useFormikContext()

  const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

  const getActivePaymentMethods = (ticketType) =>
    [
      {
        ...ORDER_PAYMENT.MERCADO_PAGO,
        active:
          ticketType?.mercadoPagoEnabled &&
          (formMode === FORM_STATE.read || event.mercadoPagoEnabled)
      },
      {
        ...ORDER_PAYMENT.BANK_ACCOUNT,
        active:
          ticketType?.bankAccountsEnabled &&
          (formMode === FORM_STATE.read || event.eventBankAccountsEnabled)
      },
      {
        ...ORDER_PAYMENT.PAYMENT_LOCATION,
        active:
          ticketType?.paymentLocationsEnabled &&
          (formMode === FORM_STATE.read || event.eventPaymentLocationsEnabled)
      },
      {
        ...ORDER_PAYMENT.PAYMENT_URL,
        active: !!ticketType?.paymentUrl
      }
    ].filter((x) => Boolean(x.active))

  const handleChangeTicket = (ticketType) => {
    const activePaymentMethods = getActivePaymentMethods(ticketType)

    setValues((values) => ({
      ...values,
      activePaymentMethods,
      paymentType:
        values.ticketType?.value !== ticketType.value
          ? (!formData.isFree &&
              ticketType?.price !== TICKET_FREE_PRICE &&
              activePaymentMethods.length === 1 &&
              activePaymentMethods[0]) ||
            ''
          : values.paymentType,
      changeTicketType: { state: values.ticketType?.value !== ticketType.value },
      ticketType,
      discountCode: null,
      discountCodeName: ''
    }))
  }

  const getTicketTypeOptions = (ticketTypes) =>
    ticketTypes.filter(
      (x) =>
        formData.isFree ||
        x.price === TICKET_FREE_PRICE ||
        (x.mercadoPagoEnabled && (formMode === FORM_STATE.read || event.mercadoPagoEnabled)) ||
        (x.paymentLocationsEnabled &&
          (formMode === FORM_STATE.read || event.eventPaymentLocationsEnabled)) ||
        (x.bankAccountsEnabled &&
          (formMode === FORM_STATE.read || event.eventBankAccountsEnabled)) ||
        !!x.paymentUrl
    )

  useEffect(() => {
    const activeTicketTypesOptions = getTicketTypeOptions(formState.activeTicketTypes)

    if (formState.changeCategory && !activeTicketTypesOptions.length) setOpenSnackbarMessage(true)
  }, [formState])

  const getVariationPriceFor = (ticket) =>
    !formData.isFree &&
    ticket.mercadoPagoEnabled &&
    (formMode === FORM_STATE.read || event.mercadoPagoEnabled) &&
    ticket.mercadoPagoPrice !== ticket.price &&
    ((ticket.paymentLocationsEnabled &&
      (formMode === FORM_STATE.read || event.eventPaymentLocationsEnabled)) ||
      (ticket.bankAccountsEnabled &&
        (formMode === FORM_STATE.read || event.eventBankAccountsEnabled)) ||
      !!ticket.paymentUrl)

  const searchTicketType = async (searchText) =>
    activeTicketTypesOptions.filter((x) => x.label.toLowerCase().includes(searchText.toLowerCase()))

  const ticketFormat = (ticket) => ({
    ...ticket,
    value: ticket.id,
    label: `${ticket.name}
    ${getVariationPriceFor(ticket) ? 'desde' : ''} 
    ${
      formData.isFree
        ? 'Sin costo'
        : numericFormatter(Number(ticket.price).toString(), {
            ...PRICE_FORMAT,
            fixedDecimalScale: !Number.isInteger(ticket.price)
          })
    }`
  })

  const activeTicketTypesOptions = getTicketTypeOptions(formState.activeTicketTypes).map(
    ticketFormat
  )

  const ticketOption = formState.ticketType ? ticketFormat(formState.ticketType) : STRING_EMPTY
  const hasVariationPrice = !!formState.ticketType && getVariationPriceFor(formState.ticketType)
  const hasActiveTicketTypes = !!activeTicketTypesOptions.length
  const hasActiveTicketTypesValid =
    !!formState.distance && !!formState.category && !hasActiveTicketTypes

  return (
    <SectionBlock
      title='Ticket'
      body='Seleccioná el Ticket de tu preferencia'
      className={clsx(classes.container, hasActiveTicketTypesValid && 'disabled')}>
      <div className={classes.selectContainer}>
        <StyledSelect
          className={clsx(classes.select, hasActiveTicketTypesValid && 'disabled')}
          onChange={handleChangeTicket}
          isDisabled={hasActiveTicketTypesValid}
          value={ticketOption}
          defaultOptions={activeTicketTypesOptions}
          loadOptions={searchTicketType}
        />
        {!formData.isFree && hasVariationPrice && (
          <CustomizedTooltip
            arrow
            position={!isDesktop ? 'top-start' : 'right'}
            autoHide={autoHidden}
            className={classes.tooltip}
            defaultState={true}
            autoOpenTooltip={formState.changeTicketType}
            disabledClickAway={true}
            title='El importe puede variar de acuerdo a la tasa de servicio de la modalidad de pago.'
            arrowClassName={classes.arrowTooltip}
            popperClassName={clsx(viewAdmin ? classes.viewAdminPopper : classes.popper)}
            focusedTooltipClassName={viewAdmin && classes.focusedTooltip}>
            <Typography></Typography>
          </CustomizedTooltip>
        )}
        <SnackbarMessage
          message={
            <Typography align='center' color='primary' variant='h6' className={classes.message}>
              No hay ticket para la categoría/distancia seleccionada. <br /> Contactá al
              Organizador.
            </Typography>
          }
          open={openSnackbarMessage}
          className={classes.messageContainer}
          onClose={() => {
            setOpenSnackbarMessage(false)
            setFieldValue('changeCategory', false)
          }}
        />
      </div>
    </SectionBlock>
  )
}

export default TicketSection
