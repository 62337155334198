import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '380px !important',
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700,
      margin: '0 auto'
    }
  },
  container: {
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: '0 auto'
    }
  },
  text: {
    fontWeight: 600
  },
  optionLabel: {
    fontSize: '.875rem',
    fontWeight: 600
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main,
      width: 15,
      height: 15
    }
  },
  formcontrol: {
    margin: theme.spacing(1, 0)
  },
  button: {
    alignSelf: 'center',
    width: 200,
    height: 40
  },
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
  }
}))
