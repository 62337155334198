import React from 'react'
import { Switch, withStyles } from '@material-ui/core'

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: 43,
    height: 19,
    padding: 0,
    margin: theme.spacing(1),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 9
  },
  switchBase: {
    padding: '3px 5px',

    '&$checked': {
      transform: 'translateX(14px)',
      color: theme.palette.primary.main,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '1px solid #0600AD'
    }
  },
  thumb: {
    width: 18,
    height: 13,

    color: theme.palette.primary.contrastText,
    borderRadius: 9
  },
  track: {
    borderRadius: 9,
    backgroundColor: '#969696',
    opacity: 1
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

export default StyledSwitch
