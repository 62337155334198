import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '88%',
      flexDirection: 'row',
      margin: '0 auto',
      justifyContent: 'space-between'
    }
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5)
  },
  errorMessage: {
    marginLeft: theme.spacing(1.5)
  },
  input: {
    marginBottom: theme.spacing(),
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.secondary.contrastText
    },

    '&.multiline': {
      '& textarea': {
        zIndex: 1,
        fontWeight: 500,
        '&::-webkit-scrollbar': {
          width: 12,
          [theme.breakpoints.up('md')]: {
            width: 16
          }
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 5px grey',
          borderRadius: 10,
          backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 10,
          background: theme.utils.rgba(theme.palette.primary.main, 0.45)
        }
      }
    }
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },

  location: {
    display: 'flex',
    alignItems: 'center',

    height: '100%',
    width: '100%',
    '& input': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      flexGrow: 1,
      width: '100%',
      height: 40,
      padding: theme.spacing(2),
      '&:focus': {
        outline: 'none'
      }
    },
    '&.disabled': {
      borderColor: theme.palette.disabled.main
    },
    '& button': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderRadius: '0px 5px 5px 0px',
      borderStyle: 'solid',
      borderLeft: 'none !important',
      height: 40,
      width: '100%',
      backgroundColor: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText
      }
    }
  },
  locationInput: {
    height: 40,
    '& input': {
      height: 7,
      zIndex: 1,
      fontWeight: 500,
      color: `${theme.palette.secondary.contrastText} !important`
    },
    '& fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
      height: 45,
      borderRight: 'none',
      borderRadius: '5px 0px 0px 5px',
      backgroundColor: theme.palette.primary.contrastText
    },
    '& label': {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '&.disabled': {
      '& fieldset': {
        borderColor: `${theme.palette.disabled.main} !important`
      },
      '& label': {
        color: `${theme.palette.disabled.main} !important`
      }
    }
  },
  select: {
    height: 40,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(1.25),
      fontWeight: 500
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  labelSelect: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingRight: theme.spacing(),
    backgroundColor: theme.palette.primary.contrastText,
    transform: 'translate(14px, 13px) scale(1)',
    '&.error': {
      color: theme.palette.error.main
    }
  },
  datePickerContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column'
  },
  datetimePickerContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    height: 47
  },
  inputDatePicker: {
    margin: 0,
    minWidth: 120,

    '& input': {
      height: 4,
      backgroundColor: theme.palette.primary.contrastText,
      fontWeight: 500,
      fontSize: '.9rem'
    },
    '& fieldset': {
      height: 45,
      padding: theme.spacing(1.1, 1.8),

      borderColor: theme.palette.primary.main,
      borderRadius: 3
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      transform: 'translate(14px, 11px) scale(1)',
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing(2.25)
    },
    '& > div > div': {
      marginLeft: 0
    },
    '& div > button': {
      width: 40,
      height: 40,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    [theme.breakpoints.up('sm')]: {
      width: 150
    }
  },
  inputTimePicker: {
    width: '40%',
    height: 40,
    minWidth: 120,
    '& > div > div': {
      marginLeft: 0
    },
    '& input': {
      fontSize: '0.85rem',
      height: 24,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      padding: theme.spacing(1.1, 1.8),
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    '& label': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText,
      fontWeight: 500,
      paddingRight: theme.spacing(),
      transform: 'translate(14px, 11px) scale(1)'
    },

    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 3
    },
    '&::placeholder': {
      color: theme.palette.disabled.main
    },
    '& div > button': {
      width: 40,
      height: 40,
      borderRadius: 0,
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main
      }
    },

    [theme.breakpoints.up('sm')]: {
      width: 150
    }
  },
  dateEventContainer: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  label: {
    fontWeight: 500,
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem'
    }
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  checkLabel: {
    fontSize: '.875rem'
  },
  checkbox: {
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  combo: {
    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0)
  },
  phoneForm: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      flexDirection: 'row'
    }
  },
  whatsAppContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      marginRight: -theme.spacing(17)
    }
  },
  whatsAppTitle: {
    fontSize: '.875rem',
    flexShrink: 0,
    fontWeight: 600
  },
  checkMiniLabel: {
    fontSize: '.75rem'
  }
}))
