import React from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import { Marker } from 'shared'

function rad2degr(rad) {
  return (rad * 180) / Math.PI
}

function degr2rad(degr) {
  return (degr * Math.PI) / 180
}

function latRad(lat) {
  const sin = Math.sin((lat * Math.PI) / 180)
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
}

function getLatLngCenter(coordinates) {
  let sumX = 0
  let sumY = 0
  let sumZ = 0

  for (let i = 0; i < coordinates.length; i++) {
    const lat = degr2rad(coordinates[i].lat)
    const lng = degr2rad(coordinates[i].lng)
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng)
    sumY += Math.cos(lat) * Math.sin(lng)
    sumZ += Math.sin(lat)
  }

  const avgX = sumX / coordinates.length
  const avgY = sumY / coordinates.length
  const avgZ = sumZ / coordinates.length

  // convert average x, y, z coordinate to latitude and longtitude
  let lng = Math.atan2(avgY, avgX)
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY)
  let lat = Math.atan2(avgZ, hyp)

  lat = !isNaN(lat) ? rad2degr(lat) : 0
  lng = !isNaN(lng) ? rad2degr(lng) : 0
  return { lat, lng }
}

function zoom(mapPx, worldPx, fraction) {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
}

function getBoundsZoom(latLngArray) {
  if (!latLngArray || !Array.isArray(latLngArray) || latLngArray.length === 0) {
    return 0
  }

  const eastest = latLngArray[0].lng
  const northest = latLngArray[0].lat
  const westest = latLngArray[0].lng
  const southest = latLngArray[0].lat
  const ne = { lat: northest, lng: eastest }
  const sw = { lat: southest, lng: westest }
  for (let i = 1; i < latLngArray.length; ++i) {
    const latLng = latLngArray[i]
    ne.lat = Math.max(ne.lat, latLng.lat)
    ne.lng = Math.min(ne.lng, latLng.lng)

    sw.lat = Math.min(sw.lat, latLng.lat)
    sw.lng = Math.max(sw.lng, latLng.lng)
  }

  const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI
  const lngDiff = ne.lng - sw.lng
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360
  const latZoom = zoom(512, 512, latFraction)
  const lngZoom = zoom(512, 512, lngFraction)
  return Math.min(latZoom, lngZoom, 21)
}

const MapViewer = ({ className, coordinates, center, zoom, children }) => {
  const latLngArray = coordinates.filter(
    (c) => !isNaN(c.lat) && !isNaN(c.lng) && c.lat !== 0 && c.lng !== 0
  )
  const finalCenter = center || getLatLngCenter(latLngArray)
  const finalZoom = zoom || getBoundsZoom(latLngArray)
  const hasCoordinates = Array.isArray(latLngArray) && latLngArray.length > 0
  return (
    hasCoordinates && (
      <div className={className}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }}
          center={finalCenter}
          zoom={finalZoom}
          defaultZoom={18}>
          {latLngArray.map((c) => (
            <Marker key={`${c.lat}${c.lng}`} lat={c.lat} lng={c.lng} />
          ))}
        </GoogleMapReact>
        {children}
      </div>
    )
  )
}

MapViewer.propTypes = {
  className: PropTypes.string,
  coordinates: PropTypes.array.isRequired,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  zoom: PropTypes.number
}

export default MapViewer
