import { makeStyles } from '@material-ui/styles'
import logoImage from 'assets/img/logo.png'

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 auto',
    width: '100%'
  },
  headerContainer: {
    backgroundColor: 'rgba(41, 255, 89, 0.7)',
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h6': {
      fontSize: '1.1rem',
      fontWeight: 600
    }
  },
  logo: {
    backgroundImage: `url(${logoImage})`,
    width: 251,
    height: 26
  },
  title: {
    marginTop: theme.spacing(6),
    margin: '0 10px',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 500
  },
  hiddenContainer: {
    display: 'none'
  },
  socialLoginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    minHeight: 230,
    justifyContent: 'center',
    paddingBottom: theme.spacing(6)
  },
  socialButton: {
    fontSize: '1.25rem',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2)
  },
  emailMessageContainer: {
    width: '90%',
    margin: '30px auto'
  },
  infoContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(6),
    '& > a': {
      marginTop: theme.spacing(2),
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  },
  messageTitle: {
    fontWeight: 600
  },
  messageContainer: {
    width: '90%',
    color: '#000000',
    margin: '0 auto'
  }
}))

export default useStyles
