import { makeStyles } from '@material-ui/core/styles'
import swimming from 'assets/img/landing-swimming.jpg'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    minHeight: 850,
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    gap: theme.spacing(5),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      paddingBottom: 0,
      height: '30vw',
      minHeight: 'auto'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      height: 380
    }
  },
  content: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    minHeight: 400,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: 'auto',
      paddingLeft: theme.spacing(10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 900,
    fontSize: '2.3rem',
    lineHeight: '38px',
    [theme.breakpoints.up(1210)]: {
      lineHeight: '55px',
      fontSize: '3.625rem'
    },
    '& span': {
      color: theme.palette.secondary.main
    }
  },
  sportImage: {
    width: '100%',
    height: '66.5vw',
    backgroundSize: 'contain',
    backgroundImage: `url(${swimming})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      height: '30vw',
      width: '45%'
    },
    [theme.breakpoints.up('lg')]: {
      height: 380,
      width: 580
    }
  },
  mainContainer: {
    display: 'flex',
    minHeight: 850,
    justifyContent: 'space-between',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      flexDirection: 'row-reverse',
      paddingTop: 0,
      height: '30vw',
      minHeight: 'auto',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1280,
      padding: 0,
      height: 380
    }
  }
}))
