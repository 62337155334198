import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useStyle } from './ResetPasswordForm.style'

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Ingresa tu e-mail')
    .email('El email ingresado no posee un formato válido'),
  newPassword: Yup.string()
    .min(8, 'La contraseña es muy corta')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/,
      'La contraseña debe tener al menos una letra, un número, y una letra mayúscula.'
    )
    .required('Ingrese su nueva contraseña'),
  newPasswordConfirmation: Yup.string()
    .required('Repite tu contraseña')
    .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
})

const ResetPasswordForm = ({ onSubmit, resetError }) => {
  const classes = useStyle()
  const [showPassword, setShowPassword] = useState(false)
  const values = {
    email: '',
    newPassword: '',
    newPasswordConfirmation: ''
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid
        }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <Typography variant='caption' className={classes.label}>
              E-Mail
            </Typography>
            <TextField
              name='email'
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email}
              error={touched.email && Boolean(errors.email)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
            />
            {resetError && (
              <Typography variant='caption' color='error'>
                {resetError}
              </Typography>
            )}
            <Typography variant='caption' className={classes.label}>
              Nueva Contraseña
            </Typography>
            <TextField
              name='newPassword'
              type={showPassword ? 'text' : 'password'}
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.newPassword}
              error={touched.newPassword && Boolean(errors.newPassword)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography variant='caption' className={classes.label}>
              Confirme su nueva contraseña
            </Typography>
            <TextField
              name='newPasswordConfirmation'
              type={showPassword ? 'text' : 'password'}
              value={values.newPasswordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.newPasswordConfirmation}
              error={touched.newPasswordConfirmation && Boolean(errors.newPasswordConfirmation)}
              fullWidth
              required
              variant='outlined'
              size='small'
              margin='normal'
              className={classes.input}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div className={classes.buttonContainer}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  disabled={!isValid || isSubmitting}>
                  Cambiar Contraseña
                </Button>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ResetPasswordForm
