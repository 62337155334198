import React from 'react'
import { FileViewerContent, SectionLayoutDialog } from 'shared'

import { useStyles } from './FileViewerDialog.style'

const FileViewerDialog = ({
  fileExtension = '',
  isLocalFile = false,
  fileUrl,
  title,
  ...dialogProps
}) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      {...dialogProps}
      title={title}
      className={classes.dialog}
      contentClassName={classes.container}
      direction='down'
      fullScreen>
      <FileViewerContent
        fileExtension={fileExtension}
        fileUrl={fileUrl}
        isLocalFile={isLocalFile}
      />
    </SectionLayoutDialog>
  )
}

export default FileViewerDialog
