import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },

  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 1200,
      maxWidth: 1200,
      margin: '0 auto'
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: 70,
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16),
    paddingLeft: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2),
      paddingLeft: theme.spacing(9)
    }
  },
  container: {
    '& > div > div': {
      minHeight: 400,
      position: 'relative'
    }
  },
  resultHeaderContainer: {
    backgroundColor: theme.palette.primary.main,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '1rem',
    flexGrow: 1,
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem'
    }
  },
  content: {
    width: '100%',
    position: 'relative',
    padding: 0,
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',

      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.utils.rgba(theme.palette.primary.main, 0.45)
    }
  },
  fileViewer: {
    overflowY: 'auto',
    '& .bPJfhN ': {
      overflowY: 'hidden'
    },
    '& #pdf-download': {
      display: 'none'
    },
    height: '100%'
  },
  downloadResultButton: {
    position: 'absolute',
    right: 40,
    bottom: 30,
    borderRadius: 30,
    '& span': {
      fontWeight: 600
    },
    [theme.breakpoints.up('md')]: {
      width: 230,
      height: 35
    }
  }
}))
