import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem'
    },

    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  deleteDialogBody: {
    minHeight: 120,
    marginBottom: theme.spacing(3),
    '& h6': {
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    }
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(4),
    '& > div': {
      padding: 0
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  checkContainer: {
    flexDirection: 'row-reverse',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
    '& span': {
      fontWeight: 500,
      fontSize: '.8rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    padding: theme.spacing(1, 8)
  }
}))
