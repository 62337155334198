import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { EditableRichText } from 'shared'
import { STRING_EMPTY } from 'utils/constants'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './CircuitSection.style'

const CircuitSection = () => {
  const classes = useStyles()
  const { values, errors, setFieldValue, setValues } = useFormikContext()

  const { event } = useSelector((state) => state.events)

  const [activeEdit, setActiveEdit] = useState(false)

  const handleChangeRichText = (e) =>
    setValues((values) => ({
      ...values,
      circuit: e?.getHTML() || STRING_EMPTY,
      circuitText: e?.getText().trim() || STRING_EMPTY
    }))

  return (
    <SectionBlock title='Circuito/s'>
      {event.circuit || activeEdit ? (
        <EditableRichText
          entity={values}
          altMode
          setStatusSection={(state) => setFieldValue('sectionState', state)}
          field='circuit'
          repositoryId={values.id}
          activeModeEdit={true}
          className={classes.editor}
          error={errors.circuit || errors.circuitText}
          onChange={handleChangeRichText}
        />
      ) : (
        <Typography
          color='primary'
          variant='h6'
          className={classes.addLink}
          onClick={() => setActiveEdit(true)}>
          + Añadir circuito
        </Typography>
      )}
    </SectionBlock>
  )
}

export default CircuitSection
