import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { FileViewerContent, SectionLayoutDialog } from 'shared'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './ViewFileReceiptDialog.style'

const ViewFileReceiptDialog = ({
  fileUrl,
  fileExtension,
  disabledViewReceipt,
  reviewed,
  onUpdatePayment = () => {},
  onDeletePayment
}) => {
  const classes = useStyles()

  const [openViewerRecieptDialog, setOpenViewerRecieptDialog] = useState(false)
  const [deletePaymentPending, setDeletePaymentPending] = useState(false)
  const [receiptReviewed, setReceiptReviewed] = useState(reviewed)

  useEffect(() => {
    if (openViewerRecieptDialog && !receiptReviewed) handleUpdateStatePayment()
  }, [openViewerRecieptDialog])

  const handleDeletePayment = async () => {
    try {
      setDeletePaymentPending(true)

      await onDeletePayment()

      setOpenViewerRecieptDialog(false)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setDeletePaymentPending(false)
    }
  }

  const handleUpdateStatePayment = async () => {
    const updateStateSucess = await onUpdatePayment()

    if (updateStateSucess) setReceiptReviewed(true)
  }

  return (
    <div title={disabledViewReceipt && 'Esta inscripción no tiene comprobante de pago.'}>
      <Button
        color='primary'
        variant='contained'
        className={clsx(
          classes.button,
          !disabledViewReceipt && receiptReviewed && classes.receiptReviewedButton
        )}
        disabled={disabledViewReceipt}
        onClick={() => setOpenViewerRecieptDialog(true)}>
        <InsertDriveFileOutlinedIcon />
      </Button>

      <SectionLayoutDialog
        open={openViewerRecieptDialog}
        onClose={() => setOpenViewerRecieptDialog(false)}
        fullScreen
        title='COMPROBANTE DE PAGO'
        className={classes.dialog}>
        <div className={classes.mainContainer}>
          <Typography color='primary' variant='h6' className={classes.receiptTitle}>
            Comprobante de pago
          </Typography>
          <FileViewerContent
            fileUrl={fileUrl}
            fileExtension={fileExtension}
            cropperClassName={classes.cropper}
            fileViewerClassName={classes.fileViewContainer}
          />
          <Button
            color='primary'
            variant='contained'
            className={classes.deleteButton}
            disabled={deletePaymentPending}
            endIcon={deletePaymentPending && <CircularProgress color='primary' size={16} />}
            onClick={handleDeletePayment}>
            Eliminar
          </Button>
          <Typography color='primary' className={classes.helperText}>
            Si eliminas el comprobante, notificá al deportista para que suba el comprobante
            correcto.
          </Typography>
        </div>
      </SectionLayoutDialog>
    </div>
  )
}

export default ViewFileReceiptDialog
