import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import {
  FileCopyOutlined as FileCopyOutlinedIcon,
  RotateRight as RotateRightIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { EventsProxy } from 'services'
import { CustomizedTooltip } from 'shared'
import {
  deleteMercadoPagoAuthorization,
  loadEventMercadoPagoAuthorization,
  setPaymentParams
} from 'state/modules/events'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { CreateEventMercadoPagoAuthorizationDialog } from '../CreateEventMercadoPagoAuthorizationDialog'
import { EditEventMercadoPagoAuthorizationDialog } from '../EditEventMercadoPagoAuthorizationDialog'
import { PaymentBlock } from '../PaymentBlock'

import { useStyles } from './OnlinePaymentBlock.style'

const ONLINE_PAYMENT_STATE = {
  initial: 'initial',
  pending: false,
  enabled: true
}

const OnlinePaymentBlock = ({ onlinePaymentEnabled, paymentStates, ticketOnlyMercadoPago }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { values: formState, setValues } = useFormikContext()

  const { event, mercadoPagoAuthorization, mercadoPagoAuthorizationPending, paymentParams } =
    useSelector((state) => state.events)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  const { mercadoPagoCode, mercadoPagoEnabled, id } = event

  const [onlinePaymentStatus, setOnlinePaymentStatus] = useState(ONLINE_PAYMENT_STATE.initial)
  const [openEditMercadoPagoAuthorization, setOpenEditMercadoPagoAuthorization] = useState(false)
  const [copyTextPending, setCopyTextPending] = useState(false)
  const [openCreateMercadoPagoAuthorization, setOpenCreateMercadoPagoAuthorization] =
    useState(false)

  useEffect(() => {
    dispatch(setPaymentParams({ ...paymentParams, mercadoPagoSelected: !!mercadoPagoCode }))
  }, [])

  useEffect(() => {
    setOnlinePaymentStatus(
      !mercadoPagoCode || formState.isFree ? ONLINE_PAYMENT_STATE.initial : mercadoPagoEnabled
    )
  }, [formState.isFree])

  useEffect(() => {
    if (event.mercadoPagoEnabled) dispatch(loadEventMercadoPagoAuthorization(id))
  }, [event])

  const handleChangeMercadoPagoSelected = (setOpenTooltip) => (e) => {
    dispatch(setPaymentParams({ ...paymentParams, [e.target.name]: e.target.checked }))
    setOpenTooltip(e.target.checked)
  }

  const tryGetMercadoPagoAuthorization = async () => {
    const data = await dispatch(loadEventMercadoPagoAuthorization(event.id))

    if (data) {
      event.mercadoPagoEnabled = true
      setValues((values) => ({ ...values, mercadoPagoEnabled: true }))

      setOnlinePaymentStatus(ONLINE_PAYMENT_STATE.enabled)
    }
  }

  const getMercadoPagoAuthorizationUrl = async () => {
    const proxy = new EventsProxy()
    const url = await proxy.getMercadoPagoAuthorizationUrl(id)
    return url
  }

  const handleDeleteMercadoPagoAuthorization = async () => {
    const deleteSuccess = await dispatch(deleteMercadoPagoAuthorization(event.id))

    if (deleteSuccess) {
      setOnlinePaymentStatus(ONLINE_PAYMENT_STATE.initial)

      event.mercadoPagoCode = null
      event.mercadoPagoEnabled = false
      setValues((values) => ({
        ...values,
        mercadoPagoCode: null,
        mercadoPagoEnabled: false,
        ticketTypes: values.ticketTypes.map((x) => ({ ...x, mercadoPagoEnabled: false }))
      }))

      dispatch(setPaymentParams({ ...paymentParams, mercadoPagoSelected: false }))

      showSnackbarSuccess('¡La cuenta fue desvinculada con éxito!')
    }
  }

  const copyMercadoPagoUrl = async () => {
    try {
      setCopyTextPending(true)

      const url = await getMercadoPagoAuthorizationUrl()
      await navigator.clipboard.writeText(url)

      showSnackbarSuccess('Se copió la url al portapapeles')
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setCopyTextPending(false)
    }
  }

  return (
    <PaymentBlock>
      <div
        className={clsx(
          classes.payment,
          onlinePaymentStatus === ONLINE_PAYMENT_STATE.pending && paymentStates.PAYMENT_PENDING
        )}>
        <Typography className={classes.paymentTitle}>
          <strong>Pago Online</strong> el sistema verifica y confirma el pago automáticamente (el
          deportista queda inscripto al completar el proceso).
        </Typography>
        <div className={classes.optionsContainer}>
          <div className={classes.option}>
            {onlinePaymentStatus === ONLINE_PAYMENT_STATE.enabled ? (
              <div className={classes.formControl}>
                <Typography className={classes.label}>Mercado Pago.</Typography>
              </div>
            ) : (
              <CustomizedTooltip
                title='Este medio de pago tiene comisión.'
                arrow
                position={!isDesktop ? 'top-start' : 'right'}
                arrowClassName={classes.arrowTooltip}
                className={classes.tooltip}>
                {({ setOpenTooltip }) => (
                  <FormControlLabel
                    className={classes.formControl}
                    classes={{ label: classes.label }}
                    control={
                      <Checkbox
                        checked={paymentParams.mercadoPagoSelected}
                        color='primary'
                        name='mercadoPagoSelected'
                        onChange={handleChangeMercadoPagoSelected(setOpenTooltip)}
                        disabled={!onlinePaymentEnabled}
                        className={clsx(
                          classes.checkbox,
                          !onlinePaymentEnabled && paymentStates.PAYMENT_DISABLED
                        )}
                      />
                    }
                    label='Mercado Pago.'
                  />
                )}
              </CustomizedTooltip>
            )}

            {onlinePaymentStatus === ONLINE_PAYMENT_STATE.pending && (
              <div className={classes.pendingBindContainer}>
                <Typography className={classes.pendingBindText}>Vinculación pendiente</Typography>
                <IconButton
                  color='primary'
                  onClick={copyMercadoPagoUrl}
                  disabled={!onlinePaymentEnabled}
                  className={classes.copyButton}>
                  {copyTextPending ? (
                    <CircularProgress size={14} />
                  ) : (
                    <FileCopyOutlinedIcon className={classes.copyIcon} />
                  )}
                </IconButton>
                <Typography className={classes.copyText}>Copiar enlace</Typography>
              </div>
            )}
          </div>
          {onlinePaymentStatus === ONLINE_PAYMENT_STATE.pending && (
            <div className={classes.pendingContainer}>
              <Typography>
                Si ya autorizaste la cuenta de Mercado Pago, hacé click para refrescar
              </Typography>
              <IconButton
                color='primary'
                size='small'
                disabled={mercadoPagoAuthorizationPending || !onlinePaymentEnabled}
                onClick={tryGetMercadoPagoAuthorization}>
                {!mercadoPagoAuthorizationPending ? (
                  <RotateRightIcon />
                ) : (
                  <CircularProgress size={14} color='primary' />
                )}
              </IconButton>
            </div>
          )}
        </div>

        {onlinePaymentStatus === ONLINE_PAYMENT_STATE.enabled && !!mercadoPagoAuthorization && (
          <div className={classes.userContainer}>
            <Typography className={classes.text}>
              Nombre de la cuenta: {mercadoPagoAuthorization.mpUserName}
            </Typography>
            <Typography className={classes.text}>
              DNI: {mercadoPagoAuthorization.mpIdNumber}
            </Typography>
          </div>
        )}
      </div>

      <CreateEventMercadoPagoAuthorizationDialog
        open={openCreateMercadoPagoAuthorization}
        onClose={() => setOpenCreateMercadoPagoAuthorization(false)}
        copyPending={copyTextPending}
        setOnlinePaymentPendingState={() => setOnlinePaymentStatus(ONLINE_PAYMENT_STATE.pending)}
        onCopyUrl={copyMercadoPagoUrl}
      />
      <EditEventMercadoPagoAuthorizationDialog
        open={openEditMercadoPagoAuthorization}
        onDeleteMercadoPagoAuthorization={handleDeleteMercadoPagoAuthorization}
        onOpenCreateMercadoPagoAuthorization={() => setOpenCreateMercadoPagoAuthorization(true)}
        onClose={() => setOpenEditMercadoPagoAuthorization(false)}
        ticketOnlyMercadoPago={ticketOnlyMercadoPago}
      />
      {onlinePaymentStatus === ONLINE_PAYMENT_STATE.initial && (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={() => setOpenCreateMercadoPagoAuthorization(true)}
          disabled={!paymentParams.mercadoPagoSelected || !onlinePaymentEnabled}>
          Configurar pago Online
        </Button>
      )}

      {onlinePaymentStatus === ONLINE_PAYMENT_STATE.pending && (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={() => setOpenCreateMercadoPagoAuthorization(true)}
          disabled={!paymentParams.mercadoPagoSelected || !onlinePaymentEnabled}>
          Volver a vincular
        </Button>
      )}

      {onlinePaymentStatus === ONLINE_PAYMENT_STATE.enabled && (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={() => setOpenEditMercadoPagoAuthorization(true)}
          disabled={!onlinePaymentEnabled}>
          Editar pago Online
        </Button>
      )}
    </PaymentBlock>
  )
}

export default OnlinePaymentBlock
