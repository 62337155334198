import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import moment from 'moment'
import { toggleShowInfoContact, tryInscriptionRegister } from 'state/modules/app'
import {
  googleSignIn,
  googleSignUp,
  manualActivation,
  setSignUpData,
  signUp
} from 'state/modules/auth'
import { Transition } from 'utils'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { SignUpForm } from '../SignUpForm'

import { useStyles } from './SignUpDialog.style'

const OTHER_OPTION = { label: 'Otra', value: 'other' }

const SignUpDialog = ({
  open,
  onClose,
  className,
  state = false,
  data = {},
  handleOpenSignInDialog = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { signUpError, signUpData } = useSelector((state) => state.auth)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [userValues, setUserValues] = useState('')
  const [signUpComplete, setSignUpComplete] = useState(false)
  const [activeManualActivation, setActiveManualActivation] = useState(false)

  const handleSubmit = async (values) => {
    const { aceptTerms, ...restProps } = values

    const formDataToSubmit = state
      ? {
          areaLevel1Id: data.areaLevel1Id,
          areaLevel2Id: data.areaLevel2Id !== OTHER_OPTION.value ? values.areaLevel2Id : null,
          areaLevel2Other:
            data.areaLevel2Other !== OTHER_OPTION.value ? null : values.areaLevel2Other,
          birthdate: data.birthdate,
          countryId: data.countryId,
          idNumber: data.idNumber,
          eMail: values.eMail,
          firstName: values.firstName,
          isAthlete: values.isAthlete,
          isOrganizer: values.isOrganizer,
          isTimekeeper: values.isTimekeeper,
          lastName: values.lastName,
          password: values.password,
          passwordConfirmation: values.passwordConfirmation,
          positionPage: values.positionPage,
          signUpWithGoogle: values.signUpWithGoogle,
          username: values.username
        }
      : {
          ...restProps,
          areaLevel2Id: values.areaLevel2Id !== OTHER_OPTION.value ? values.areaLevel2Id : null,
          areaLevel2Other:
            values.areaLevel2Id !== OTHER_OPTION.value ? null : values.areaLevel2Other,
          birthdate: moment(values.birthdate).format('YYYY-MM-DD')
        }

    if (!signUpData) {
      const signUpSuccess = await dispatch(signUp(formDataToSubmit))
      if (signUpSuccess) {
        dispatch(tryInscriptionRegister(null))
        dispatch(toggleShowInfoContact(true))
        setUserValues(formDataToSubmit)
        setSignUpComplete(true)
        window.fbq('track', 'RegisterCompleted', {
          signUpAt: moment().format(),
          username: formDataToSubmit.username,
          eMail: formDataToSubmit.eMail
        })
      }
    } else {
      const loginSuccess = await dispatch(googleSignUp(formDataToSubmit, signUpData))
      if (loginSuccess) {
        window.fbq('track', 'RegisterCompletedWithGoogle', {
          signUpAt: moment().format(),
          username: formDataToSubmit.username,
          eMail: formDataToSubmit.eMail
        })
        onClose()
      }
    }
  }

  const handleManualActivation = async (e) => {
    e.preventDefault()
    const manualActivationData = await dispatch(
      manualActivation({ usernameOrEmail: userValues.eMail ?? userValues.username })
    )

    if (manualActivationData) {
      setActiveManualActivation(true)
      setUserValues(manualActivationData)
    }
  }

  const handleGoogleSignUp = async (credentialResponse) => {
    const loginSuccess = await dispatch(googleSignIn(credentialResponse.credential))
    if (loginSuccess) {
      onClose()
    } else {
      await dispatch(setSignUpData(credentialResponse.credential))
    }
  }

  const handleOnClose = () => {
    if (!signUpComplete) trackEvent(ANALYTICS_EVENTS.DROPOUT.USER_REGISTER)

    onClose()
  }

  const stepActivationView = () => (
    <div className={classes.activationContainer}>
      {!activeManualActivation ? (
        <>
          <div className={classes.emailMessageContainer}>
            <Typography className={classes.textInfo}>
              Dirígete a tu casilla de correo <strong>{userValues.eMail}</strong> <br /> y sigue los
              pasos para activar tu cuenta.
              <br />
              No olvides consultar tu casilla de correo no deseado.
            </Typography>
          </div>
          <div className={classes.infoContainer}>
            <strong>¿No recibiste nuestro e-mail?</strong>
            <a href='#' onClick={handleManualActivation} className={classes.linkActivation}>
              Hace click <span>aquí.</span>
            </a>
          </div>
        </>
      ) : (
        <>
          <div className={classes.emailMessageContainer}>
            <Typography color='primary' variant='h6' className={classes.messageTitle}>
              Muchas gracias por avisarnos!
            </Typography>
          </div>
          <div className={classes.messageContainer}>
            <Typography>
              Estamos validando la activación de tu cuenta. En breve nos comunicaremos con vos a{' '}
              <strong>{userValues.eMail}</strong>
            </Typography>
          </div>
        </>
      )}
    </div>
  )

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: clsx(!signUpComplete ? classes.dialog : classes.dialogActivationUser, className)
        }
      }}
      fullWidth
      fullScreen={!isDesktop}
      TransitionComponent={Transition}
      className={!signUpComplete ? classes.container : classes.containerActiveUser}>
      {!signUpComplete ? (
        <DialogContent className={classes.content}>
          <SignUpForm
            onSubmit={handleSubmit}
            signUpError={signUpError}
            signUpData={signUpData}
            handleGoogleSignUp={handleGoogleSignUp}
            handleOpenSignInDialog={handleOpenSignInDialog}
            state={state}
          />
        </DialogContent>
      ) : (
        <>
          <DialogTitle className={classes.dialogTitle}>
            <div className={classes.headerContainer}>
              <Typography
                color='primary'
                align='center'
                variant='h6'
                className={clsx(classes.title, !onClose && 'center')}>
                ACTIVACION DE CUENTA
              </Typography>
              <IconButton color='primary' onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={classes.content}>{stepActivationView()}</DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default SignUpDialog
