import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: '700px !important',
      maxWidth: '700px !important'
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: '80%'
    }
  },
  subtitle: {
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    fontWeight: 600
  },
  item: {
    color: '#FF4B4B',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    fontWeight: 600
  },
  buttonEnd: {
    alignSelf: 'center',
    margin: theme.spacing(3, 0),
    width: 200,
    padidng: theme.spacing(1, 5)
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 200
  },
  list: {
    minHeight: 90
  }
}))
