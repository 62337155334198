import React from 'react'
import { useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './PaymentNotifyDialog.style'

const DIALOG_STATES = {
  MERCADO_PAGO_CONFIRM_DIALOG: 'mercadoPagoConfirmDialog',
  MERCADO_PAGO_INFO_DIALOG: 'mercadoPagoInfoDialog',
  OFFLINE_CONFIRM_DIALOG: 'offlineConfirmDialog',
  OFFLINE_INFO_DIALOG: 'offlineInfoDialog',
  PAYMENT_INFO_DIALOG: 'paymentInfoDialog'
}

const PaymentNotifyDialog = ({
  open,
  onClose,
  state = DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG,
  onConfirm,
  items = [],
  title = 'NOTIFICACIÓN'
}) => {
  const classes = useStyles()
  const { createInscriptionFormsPending, updateInscriptionFormsPending, activeInscriptionForm } =
    useSelector((state) => state.events)

  const availableInscription =
    !!activeInscriptionForm &&
    !activeInscriptionForm.isDraft &&
    moment().isBetween(moment(activeInscriptionForm.openFrom), moment(activeInscriptionForm.openTo))

  return (
    <SectionLayoutDialog
      title={title}
      open={open}
      className={clsx(
        classes.dialog,
        state === DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG &&
          DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG
      )}
      contentClassName={classes.container}>
      {state === DIALOG_STATES.MERCADO_PAGO_CONFIRM_DIALOG &&
        (activeInscriptionForm && !activeInscriptionForm.isDraft ? (
          <>
            <div>
              <Typography color='primary' variant='h6' className={classes.text}>
                Está pendiente la vinculación con la nueva cuenta de MP.
                <br />
                Hasta que concretes este proceso sólo se mostrarán el/los tickets que contengan
                medios de pago activos.
              </Typography>

              {!!items.length && (
                <div className={classes.content}>
                  <Typography color='primary' variant='h6' className={classes.text}>
                    Los siguientes tickets no estarán disponibles:
                  </Typography>
                  <ul className={classes.itemList}>
                    {items.map((x, index) => (
                      <li color='primary' variant='h6' key={index} className={classes.item}>
                        {x.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <Button
              color='primary'
              variant='contained'
              disabled={createInscriptionFormsPending || updateInscriptionFormsPending}
              endIcon={
                (createInscriptionFormsPending || updateInscriptionFormsPending) && (
                  <CircularProgress size={16} />
                )
              }
              className={classes.button}
              onClick={onConfirm}>
              Aceptar
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.title} color='primary' variant='h6' align='center'>
              Aún tienes pendiente la vinculación de tu cuenta de Mercado Pago
              <br />
              ¿Deseas crear el formulario sin medios de pago online?
            </Typography>
            <div className={classes.buttonsContainer}>
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={onClose}>
                Cancelar
              </Button>
              <Button
                color='primary'
                variant='contained'
                disabled={createInscriptionFormsPending || updateInscriptionFormsPending}
                endIcon={
                  (createInscriptionFormsPending || updateInscriptionFormsPending) && (
                    <CircularProgress size={16} />
                  )
                }
                className={classes.button}
                onClick={onConfirm}>
                Crear
              </Button>
            </div>
          </>
        ))}

      {state === DIALOG_STATES.MERCADO_PAGO_INFO_DIALOG &&
        (activeInscriptionForm && !activeInscriptionForm.isDraft ? (
          <div className={classes.mainContainer}>
            <Typography className={classes.subtitle} color='primary' variant='h6' align='center'>
              Esta pendiente la vinculación con la cuenta de Mercado Pago.
              <br />
              Hasta que concretes este proceso o actives otra forma de pago el formulario quedará
              deshabilitado.
            </Typography>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onConfirm}>
              Aceptar
            </Button>
          </div>
        ) : (
          <div className={classes.mainContainer}>
            <Typography className={classes.title} color='primary' variant='h6' align='center'>
              No se puede crear el formulario de inscripción porque tienes pendiente la vinculación
              con la cuenta de Mercado Pago.
            </Typography>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onClose}>
              Aceptar
            </Button>
          </div>
        ))}

      {state === DIALOG_STATES.OFFLINE_CONFIRM_DIALOG && (
        <>
          <Typography className={classes.title} color='primary' variant='h6' align='center'>
            Este medio de pago se encuentra asociado a los tickets creados al deshabilitarlo se
            borrarán todos los tickets.
            <br />
            ¿Desea continuar?
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onConfirm}>
              Continuar
            </Button>
          </div>
        </>
      )}

      {state === DIALOG_STATES.OFFLINE_INFO_DIALOG && (
        <div className={classes.mainContainer}>
          <Typography className={classes.title} color='primary' variant='h6' align='center'>
            No se puede deshabilitar el medio de pago porque se encuentra asociado a los tickets
            creados y las inscripciones estan abiertas
          </Typography>
          <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
            Aceptar
          </Button>
        </div>
      )}

      {state === DIALOG_STATES.PAYMENT_INFO_DIALOG && (
        <>
          <Typography color='primary' variant='h6' className={classes.textAlert}>
            No se puede deshabilitar el medio <br /> de pago porque se encuentra asociado a los
            <br /> tickets creados{availableInscription && ' y las inscripciones están abiertas'}.
          </Typography>
          <Typography color='primary' variant='h6' className={classes.subtext}>
            (Para poder realizar esta acción deberá primero habilitar otro medio de pago.)
          </Typography>
          <Button
            className={classes.acceptButton}
            color='primary'
            variant='contained'
            onClick={onClose}>
            Aceptar
          </Button>
        </>
      )}
    </SectionLayoutDialog>
  )
}

export default PaymentNotifyDialog
