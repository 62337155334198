import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  editMode: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: 20
    }
  },
  containerEdit: {
    display: 'flex',
    alignItems: 'baseline'
  },
  editModeAlign: {
    alignItems: 'flex-start'
  },
  containerEditAlign: {
    alignItems: 'baseline',
    width: '100%'
  },
  datePiker: {
    '& fieldset': {
      border: ` 1px solid ${theme.palette.primary.main}`
    },
    '& input': {
      width: 150,
      color: theme.palette.primary.main,
      backgroundColor: '#ffff'
    },
    '& div > button': {
      color: theme.palette.primary.main
    },
    '& div > div': {
      marginLeft: 0
    }
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    '&.fullWidth': {
      width: '100%'
    }
  },
  deleteRulesButton: {
    height: 40,
    marginTop: theme.spacing(2),
    minWidth: 40
  }
}))

export default useStyles
