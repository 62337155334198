import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bannerBlock: {
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.7),
    display: 'flex',
    height: 180,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-around',
      flexDirection: 'row',
      padding: theme.spacing(6)
    }
  },
  bannerBlockText: {
    marginBottom: theme.spacing(),
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    textAlign: 'center',
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      fontSize: '1.8rem',
      textAlign: 'left',
      width: '50%'
    }
  },
  bannerButton: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    }
  }
}))
