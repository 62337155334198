import React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { FeatureProductCard } from '../FeatureProductCard'

import { useStyles } from './SectionFeatureProduct.style'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const SectionFeatureProduct = () => {
  const classes = useStyles()

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const featureProducts = [
    {
      title: 'CRONO',
      subTitle: 'DEMO',
      list: [
        { text: 'Tres categorías disponibles.' },
        { text: 'Hasta 30 participantes por carrera.' },
        { text: 'Una licencia.' }
      ],
      price: 'GRATIS',
      actionTextButton: 'INICIAR PRUEBA',
      actionButton: 'https://sportmetric.net/download.html'
    },
    {
      title: 'CRONO',
      subTitle: 'FULL',
      list: [
        { text: 'Categorías ilimitadas.' },
        { text: 'Participantes ilimitados.' },
        { text: 'Backup online.' },
        { text: 'Inscripción y consulta de resultados en red.' },
        { text: 'Tres licencias.' },
        { text: 'Función imprimir ticket con tiempo y clasificación.(*)' }
      ],
      price: 'PAGO ANUAL',
      actionTextButton: isDesktop ? 'CONSULTAR COSTO' : 'CONSULTAR',
      actionButton: 'mailto:crono@sportmetric.net',
      textAnotation:
        '(*) Requiere impresora térmica portátil, no incluida en el precio de la licencia.'
    }
  ]

  return (
    <div className={classes.mainContainer}>
      {featureProducts.map((featureProduct, index) => (
        <FeatureProductCard {...featureProduct} key={index} />
      ))}
      <div className={classes.cronosPCImagen} />
    </div>
  )
}

export default SectionFeatureProduct
