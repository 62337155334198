import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 360,
    height: '100%',
    overflow: 'hidden',
    transition: 'width 0.5s linear',
    position: 'relative'
  },
  containerClosed: {
    width: 26,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 40
  },
  drawer: {
    width: 350,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%',
    '& > div': {
      position: 'relative',
      zIndex: 0
    },
    marginLeft: 10
  },
  drawerOpen: {
    width: 350,
    visibility: 'visible !important'
  },
  drawerClose: {
    overflowX: 'hidden',
    transform: 'translateX(-100%) !important',
    visibility: 'visible !important',
    transition: 'transform  1s linear, width 7s ease-in !important'
  },
  drawerPaper: {
    width: '100%',
    height: '100%'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),

    '&.adminMode': {
      flexDirection: 'column-reverse',
      paddingTop: theme.spacing(4)
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: 0,
      width: '100%',
      gap: theme.spacing(3),
      '&.results': {
        flexDirection: 'column',
        background: theme.palette.background.section,
        borderRadius: 10,
        border: `1px solid ${theme.palette.primary.main}`,
        position: 'relative',
        '& > div': {
          width: 'auto',
          backgroundColor: 'transparent',
          marginTop: 0
        },
        '& > div > div': {
          backgroundColor: 'transparent'
        },
        overflowX: 'hidden'
      }
    },
    '&.suspended': {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex'
      }
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  },
  openButton: {
    left: 0,
    top: 150,
    zIndex: 10,
    backgroundColor: theme.palette.alt.main,
    color: theme.palette.primary.main,
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  lineLeft: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    width: 2,
    position: 'absolute',
    marginTop: 25,
    left: 10
  },
  tooltip: {
    color: theme.palette.secondary.contrastText,
    fontSize: '.75rem',
    [theme.breakpoints.up('md')]: {
      backgroundColor: theme.palette.alt.main,
      width: 70,
      maxWidth: 70,
      borderRadius: 0,
      padding: 5
    }
  },
  arrowTooltip: {
    color: theme.palette.alt.main
  },
  tooltipPopper: {
    zIndex: 1300
  }
}))
