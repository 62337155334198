import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { AppBar, Toolbar, Typography, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { USER_ROLE } from 'utils/constants'

import { RoleRequiredDialog } from '../RoleRequiredDialog'
import { UserNotLoggedDialog } from '../UserNotLoggedDialog'
import { UserSession } from '../UserSession'

import useStyles from './NavBar.style'

const ACTIVE_ROLE_COLORS = [
  {
    value: 'primary',
    rol: USER_ROLE.ATHLETE
  },
  { value: 'secondary', rol: USER_ROLE.ORGANIZER }
]

const NavBar = ({
  transparent,
  widget,
  showWidget,
  setOpenUserOptions,
  darkMode,
  altMode,
  hiddenUserSessión
}) => {
  const classes = useStyles()
  const location = useLocation()
  const { user } = useSelector((state) => state.auth)
  const { activeRole } = useSelector((state) => state.app)

  const animateSubtitle = location.pathname === ROUTES.ROOT

  const [animateTitle, setAnimateTitle] = useState(animateSubtitle)
  const [eventSearchActive, setEventSearchActive] = useState(false)

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const roleColor = ACTIVE_ROLE_COLORS.find((x) => x.rol === activeRole)

  const getLogoClassName = () => {
    if (darkMode) return classes.logoCondensedDark

    if (!isDesktop && !user && !eventSearchActive) return classes.logo

    if (!isDesktop || eventSearchActive) {
      return !altMode || !transparent ? classes.logoCondensed : classes.logoCondensedAlt
    }

    if (altMode && transparent) return classes.blueLogo

    return classes.logo
  }

  return (
    <AppBar
      position='fixed'
      className={clsx(
        classes.appBar,
        transparent ? classes.appBarTransparent : roleColor?.value,
        darkMode && classes.darkAppBar
      )}>
      <Toolbar className={clsx(classes.toolbar, darkMode && classes.toolbarAlt)}>
        <div className={classes.logoContainer} onClick={() => setEventSearchActive(false)}>
          <NavLink to={ROUTES.ROOT} className={classes.navLinkContainer}>
            <div className={clsx(getLogoClassName(), 'mini')} />

            {!darkMode && (isDesktop || (!user && !eventSearchActive)) && (
              <div className={classes.titleContainer}>
                <Typography
                  onAnimationEnd={() => setAnimateTitle(false)}
                  className={clsx(
                    animateTitle
                      ? classes.titleAnimation
                      : altMode && transparent
                      ? classes.altTitle
                      : classes.title,
                    'mini'
                  )}>
                  {!activeRole || activeRole === USER_ROLE.ATHLETE
                    ? 'Encontrá tu próximo desafío'
                    : 'Profesionalizamos el deporte'}
                </Typography>
              </div>
            )}
          </NavLink>
        </div>

        <div className={classes.widgetContainer}>
          {showWidget && widget({ eventSearchActive, setEventSearchActive })}
        </div>

        {!hiddenUserSessión && (
          <UserSession
            user={user}
            roleColor={roleColor}
            hiddenUsername={!isDesktop && eventSearchActive}
            setOpenUserOptions={setOpenUserOptions}
            transparent={transparent}
          />
        )}
      </Toolbar>

      <UserNotLoggedDialog />

      <RoleRequiredDialog />
    </AppBar>
  )
}

export default NavBar
