import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  numericField: {
    '& input': {
      appearance: 'textfield',
      '&::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0
      }
    }
  }
}))
