import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  appBarTransparent: {
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    height: 120,
    boxShadow: 'none',
    zIndex: 1000
  },
  toolbar: {
    position: 'absolute',
    top: 30,
    left: 16,
    padding: 0,
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',

    [theme.breakpoints.up('md')]: {
      height: 120,
      left: 80,
      top: 50
    }
  },
  link: {
    flexGrow: 1
  },
  logo: {
    width: 210,
    [theme.breakpoints.up('md')]: {
      width: 271,
      height: 53,
      backgroundPosition: 'center',
      '&.mini': {
        width: 230,
        height: 20
      }
    }
  }
}))
