import React from 'react'
import { Typography } from '@material-ui/core'
import { LayoutDialog } from 'shared'

import { useStyles } from './InfoContactDialog.style'

const InfoContactDialog = ({ openDialog, onClose, event }) => {
  const classes = useStyles()

  const { phone } = event

  return (
    <LayoutDialog
      title='TELÉFONO DE CONTACTO'
      open={openDialog}
      onClose={onClose}
      className={classes.dialog}>
      <div className={classes.content}>
        <Typography color='primary' variant='h6'>
          Por cualquier consulta sobre el evento comunicate con este teléfono:
        </Typography>
        <Typography className={classes.phone}>{phone}</Typography>
      </div>
    </LayoutDialog>
  )
}

export default InfoContactDialog
