import React from 'react'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './NumericField.style'

const NumericField = ({ className, onChange, pattern, ...restProps }) => {
  const classes = useStyles()

  const handleChange = (e) => {
    const numberPattern = pattern ?? /^\d*\.*-*\+?\d*$/

    const { value } = e.target
    e.target.value = value?.replace(',', '.')

    if (!numberPattern.test(e.target.value)) return

    onChange(e)
  }

  return (
    <TextField
      {...restProps}
      type='text'
      inputMode='numeric'
      onChange={handleChange}
      className={clsx(classes.numericField, className)}
    />
  )
}

export default NumericField
