import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.contrastText, 0.6),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: 1027,
      maxWidth: 1027,
      margin: '0 auto'
    }
  },
  dialogActivationUser: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650,
      margin: '0 auto'
    }
  },
  content: {
    padding: 0
  },
  emailMessageContainer: {
    width: '90%',
    margin: '30px auto'
  },
  infoContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.spacing(6),
    gap: 30,
    '& > a': {
      marginTop: theme.spacing(2),
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  },
  messageTitle: {
    fontWeight: 600
  },
  messageContainer: {
    width: '90%',
    color: '#000000',
    margin: '0 auto'
  },
  container: {
    marginTop: theme.spacing(10),
    zIndex: '1000 !important'
  },
  googleLoginContainer: {
    justifyContent: 'center',
    '& > div > div > div > div': {
      borderRadius: 10,
      width: 210,
      border: `1px solid ${theme.palette.primary.main} !important`
    }
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: 130,
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    alignItems: 'center'
  },
  signUpOptionsContainer: {
    width: '100%',
    height: 130,
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  signUpTitle: {
    fontSize: '1.25rem'
  },
  signUpButton: {
    borderRadius: 10,
    height: 46,
    width: 210,
    backgroundColor: theme.palette.primary.contrastText,

    '& span': {
      fontSize: '1rem'
    }
  },
  signInOptionsContainer: {
    padding: theme.spacing(0, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dialogTitle: {
    padding: 0
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(6),
    '&.center': {
      paddingLeft: 0
    }
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16),
    padding: theme.spacing(0, 2)
  },
  activationContainer: {
    minHeight: 300
  },
  textInfo: {
    fontSize: 16,
    fontWeight: 500
  },
  linkActivation: {
    fontWeight: 700,
    textDecoration: 'none',
    '& span': {
      textDecoration: 'underline'
    }
  }
}))
