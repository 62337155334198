import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.utils.rgba('#D9D9D966', 0.4),
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(3, 7),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 20
  },
  emptyMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5)
  },

  emptyMessage: {
    color: '#FF4B4B',
    fontWeight: 600,
    paddingLeft: theme.spacing(2)
  },
  createCodeButton: {
    width: 240,
    height: 41,
    alignSelf: 'center',
    '& span': {
      fontWeight: 500,
      fontSize: '.975rem'
    }
  },
  table: {
    borderSpacing: theme.spacing(0, 0.7),
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10)
  },
  row: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    color: '#000',
    position: 'relative',
    fontWeight: 600
  },
  column: {
    padding: theme.spacing(3, 2),
    textAlign: 'left',
    color: '#696969',
    fontSize: '1rem',
    fontWeight: 600
  },
  bodyColumn: {
    color: '#696969',
    fontWeight: 500,
    padding: theme.spacing(2.5, 2),
    textAlign: 'left'
  }
}))
