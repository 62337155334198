import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FileViewerContent, SectionLayoutDialog } from 'shared'
import { loadActiveServiceLetterReport } from 'state/modules/organizations'

import { useStyles } from './ServiceLetterFileDialog.style'

const ServiceLetterFileDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { organization } = useSelector((state) => state.organizations)
  const [pdfUrl, setPdfUrl] = useState(null)

  useEffect(() => {
    getActiveServiceLetterReport()
  }, [])

  const getActiveServiceLetterReport = async () => {
    const data = await dispatch(loadActiveServiceLetterReport(organization.id))
    const pdf = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
    setPdfUrl(pdf)
  }

  return (
    <SectionLayoutDialog open={open} onClose={onClose} title='Carta de servicio'>
      <FileViewerContent
        fileExtension='application/pdf'
        fileUrl={pdfUrl}
        fileViewerClassName={classes.fileViewer}
      />
    </SectionLayoutDialog>
  )
}

export default ServiceLetterFileDialog
