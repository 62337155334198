import React from 'react'
import { Typography } from '@material-ui/core'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './GeneratorProfileSection.style'

const GeneratorProfileSection = ({ ...sectionBlockProps }) => {
  const classes = useStyles()

  return (
    <SectionBlock
      nextSectionId='organizersUsSection'
      containerClassName={classes.container}
      {...sectionBlockProps}>
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            GENERÁ EL <span className={classes.crea}>PERFIL</span>
            <br />
            DE TU
            <br />
            <span>ORGANIZACIÓN</span>
            <br />Y <span>LOGRÁ</span> MAYOR
            <br />
            <span>VISIBILIDAD.</span>
          </Typography>
        </div>
        <div className={classes.motocrossImage} />
      </div>
    </SectionBlock>
  )
}

export default GeneratorProfileSection
