import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Formik } from 'formik'
import {
  DeleteBankAccountDialog,
  EditBankAccountDialog,
  PaymentNotifyDialog
} from 'pages/Events/InscriptionPage/components'
import { CustomTextField, NumericField } from 'shared'
import { StyledSelect } from 'shared/EditableMultipleSelection/EditableMultipleSelection.style'
import { deleteEventBankAccount, loadEventBankAccounts } from 'state/modules/events'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './BankAccountCard.style'

const CARD_STATES = {
  CARD_VIEW: 'cardView',
  CARD_FORM: 'cardForm'
}

const FORM_TYPE = {
  FORM_CREATE: 'formCreate',
  FORM_UPDATE: 'formUpdate'
}

const RADIO_STATE = {
  RADIO_ALIAS: 'alias',
  RADIO_NUMBER: 'number'
}

const DIALOG_STATES = {
  PAYMENT_INFO_DIALOG: 'paymentInfoDialog'
}

const paymentNotifyInitalState = {
  open: false,
  state: DIALOG_STATES.OFFLINE_CONFIRM_DIALOG,
  onConfirm: () => {}
}

const BankAccountCard = ({
  bankAccount,
  title,
  cardState = CARD_STATES.CARD_VIEW,
  showActionbuttons,
  showCheckActive,
  showCopyButton,
  saveChange,
  onSubmit,
  formType = FORM_TYPE.FORM_CREATE,
  onCloseOfflineBankAccountConfig = () => {},
  onOpenCloseOffPaymentConfig = () => {},
  values = {},
  setValues = () => {},
  setFieldValue = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { banks, eventBankAccounts, event } = useSelector((state) => state.events)

  const { alias, number, fullName, idNumber, bankName, isActive, id } = bankAccount

  const [checkStateValue, setCheckStateValue] = useState(isActive)
  const [openEditBankAccount, setOpenEditBankAccount] = useState(false)
  const [openDeleteBankAccount, setOpenDeleteBankAccount] = useState(false)
  const [paymentNotifyProps, setPaymentNotifyProps] = useState(paymentNotifyInitalState)
  const [copyToClipboardPending, setCopyToClipboardPending] = useState(false)
  const [pending, setPending] = useState(false)
  const [updateBankAccountPending, setUpdateBankAccountPending] = useState(false)
  const radioValueState = number ? RADIO_STATE.RADIO_NUMBER : RADIO_STATE.RADIO_ALIAS

  const copyTextToClipboard = async () => {
    try {
      setCopyToClipboardPending(true)

      await navigator.clipboard.writeText(alias || number)

      showSnackbarSuccess(`¡Se copió el ${alias ? 'alias' : 'CBU/CVU'} al portapapeles con éxito!`)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setCopyToClipboardPending(false)
    }
  }

  const bankAccountContent = () => (
    <main className={classes.bankAccountContent}>
      <div className={classes.section}>
        {alias ? (
          <Typography
            className={clsx(classes.offLineText, {
              [classes.offLineTextDisabled]: !isActive
            })}>
            Alias: {alias}
          </Typography>
        ) : (
          <Typography
            className={clsx(classes.offLineText, {
              [classes.offLineTextDisabled]: !isActive
            })}>
            CBU/CVU: {number}
          </Typography>
        )}
        {showCopyButton && (
          <IconButton
            color='primary'
            className={classes.copyButton}
            onClick={copyTextToClipboard}
            disabled={copyToClipboardPending}>
            {!copyToClipboardPending ? (
              <FileCopyOutlinedIcon color='primary' />
            ) : (
              <CircularProgress color='primary' size={16} />
            )}
          </IconButton>
        )}
      </div>
      <Typography
        className={clsx(classes.offLineText, classes.ellipsisText, {
          [classes.offLineTextDisabled]: !isActive
        })}
        title={fullName}>
        Titular: {fullName}
      </Typography>
      <Typography
        className={clsx(classes.offLineText, {
          [classes.offLineTextDisabled]: !isActive
        })}>
        CUIT/CUIL: {idNumber}
      </Typography>
      <Typography
        className={clsx(classes.offLineText, {
          [classes.offLineTextDisabled]: !isActive
        })}>
        Banco/Billetera Virtual: {bankName}
      </Typography>
    </main>
  )

  const handleChangeStateBank = async (e) => {
    const checkValue = e.target.checked
    if (!checkValue) {
      const hasErrors = handlebankAccountValidate()
      if (hasErrors) return
    }

    handleSaveState(checkValue)
  }

  const handlebankAccountValidate = () => {
    const hasActiveBankAccounts = eventBankAccounts.filter((x) => x.isActive).length === 1

    const hasBankAccountTickets = values.ticketTypes.some(
      (x) =>
        x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentLocationsEnabled &&
        !x.paymentUrl
    )
    if (!hasActiveBankAccounts || !hasBankAccountTickets || !bankAccount.isActive) return

    setPaymentNotifyProps({
      open: true,
      state: DIALOG_STATES.PAYMENT_INFO_DIALOG,
      pending: true
    })

    return true
  }

  const onOpenDeleteBankAccount = () => {
    const hasErrors = handlebankAccountValidate()
    if (hasErrors) return

    handleOpenDeleteBankAccount()
  }

  const handleOpenDeleteBankAccount = () => {
    onCloseOfflineBankAccountConfig()
    setOpenDeleteBankAccount(true)
    setPaymentNotifyProps((state) => ({ ...state, ...paymentNotifyInitalState }))
  }

  const onDeleteBankAccount = async () => {
    const deleteSuccess = await dispatch(deleteEventBankAccount(event.id, bankAccount.id))

    if (!deleteSuccess) return

    await dispatch(loadEventBankAccounts(event.id))

    event.eventBankAccountsEnabled = eventBankAccounts.filter((x) => x.isActive).length > 1

    const hasActiveBankAccounts = eventBankAccounts.filter((x) => x.isActive).length === 1

    const hasBankAccountTickets = values.ticketTypes.some(
      (x) =>
        x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentLocationsEnabled &&
        !x.paymentUrl
    )

    if (!hasBankAccountTickets && hasActiveBankAccounts) {
      setFieldValue(
        'ticketTypes',
        values.ticketTypes.map((x) => ({ ...x, bankAccountsEnabled: false }))
      )
    }

    setFieldValue('paymentMethod', false)
    showSnackbarSuccess('¡La cuenta bancaria fue eliminada con exito!')

    onOpenCloseOffPaymentConfig()
    setOpenDeleteBankAccount(false)
  }

  const handleSaveState = async (isActive) => {
    setUpdateBankAccountPending(true)
    if (saveChange) await onSubmit({ ...bankAccount, isActive })

    event.eventBankAccountsEnabled =
      isActive || eventBankAccounts.filter((x) => x.isActive).length > 1
    bankAccount.isActive = isActive

    setCheckStateValue(isActive)

    await dispatch(loadEventBankAccounts(event.id))

    const hasActiveBankAccounts = eventBankAccounts.filter((x) => x.isActive).length === 0

    const hasBankAccountTickets = values.ticketTypes.some(
      (x) =>
        x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentLocationsEnabled &&
        !x.paymentUrl
    )

    if (!hasBankAccountTickets && hasActiveBankAccounts) {
      setFieldValue(
        'ticketTypes',
        values.ticketTypes.map((x) => ({ ...x, bankAccountsEnabled: false }))
      )
    }

    setUpdateBankAccountPending(false)
  }

  const filterBanks = (observerOptions) => async (inputValue) => {
    if (Array.isArray(observerOptions)) {
      return observerOptions.filter((e) => e.label.toLowerCase().includes(inputValue.toLowerCase()))
    }
  }

  const handleAliasUniqueness = (value) => {
    return !eventBankAccounts.some((e) => e.alias === value && e.id !== id)
  }

  const handleCbuOrCvuUniqueness = (number) => {
    return !eventBankAccounts.some((b) => b.number === number && b.id !== id)
  }

  const bankAccountForm = () => {
    const bankAccountValidate = Yup.object().shape({
      alias: Yup.string().when('radioValue', {
        is: (radioValue) => radioValue === 'alias',
        then: (schema) =>
          schema
            .nullable()
            .required('Debe ingresar el alias de la cuenta')
            .max(20, 'El alias tiene un máximo de  20 caracteres')
            .min(6, 'El Alias debe tener al menos 6 caracteres')
            .matches(/^[A-Za-z0-9.-]+$/, 'El alias ingresado no es válido')
            .test('alias-validation', 'Ya existe una cuenta bancaria con este alias', (value) =>
              handleAliasUniqueness(value)
            )
      }),
      number: Yup.string().when('radioValue', {
        is: (radioValue) => radioValue === 'number',
        then: (schema) =>
          schema
            .required('Debe ingresar el CBU/CVU de la cuenta')
            .nullable()
            .max(22, 'El CBU/CVU tiene un máximo de 22 caracteres')
            .min(22, 'El CBU/CVU debe tener 22 caracteres')
            .matches(/^[0-9]+$/, 'El CBU ingresado no es válido')
            .test('cbu/cvu-validation', 'Ya existe una cuenta bancaria con este CBU/CVU', (value) =>
              handleCbuOrCvuUniqueness(value)
            )
      }),
      fullName: Yup.string()
        .nullable()
        .required('Debe ingresar el nombre del titular de la cuenta')
        .max(100, 'El nombre del titular tiene un máximo de 100 caracteres')
        .matches(/^(?!.*\s{2})[A-Za-zñÑ\s]+$/, 'El Nombre ingresado no es válido'),
      idNumber: Yup.string()
        .nullable()
        .required('Debe ingresar el CUIT/CUIL')
        .matches(
          /^[0-9]+$/,
          'El CUIT/CUIL no es válido, debe tener 11 dígitos sin espacios ni guiones'
        )
        .max(11, 'El CUIT/CUIL tiene un máximo de 11 caracteres')
        .min(11, 'El CUIT/CUIL debe tener 11 caracteres'),
      bank: Yup.mixed().nullable().required('Debe ingresar el banco')
    })

    const optionsBanks = banks.map((e) => ({
      value: e.id,
      label: e.name
    }))

    const bankAccountValues = {
      ...bankAccount,
      bank: bankAccount.bankId
        ? {
            label: bankAccount.bankName,
            value: bankAccount.bankId
          }
        : '',
      radioValue: radioValueState
    }

    const handleSaveBankAccount = async (values) => {
      try {
        setPending(true)
        await onSubmit(values)
      } catch (error) {
        showSnackbarError(error)
      } finally {
        setPending(false)
      }
    }

    return (
      <Formik
        enableReinitialize
        initialValues={bankAccountValues}
        onSubmit={handleSaveBankAccount}
        validationSchema={bankAccountValidate}>
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          setFieldValue,
          setValues
        }) => (
          <form onSubmit={handleSubmit} className={classes.cardForm}>
            <div className={classes.formGroup}>
              <RadioGroup
                onChange={(e) => {
                  setValues((values) => ({
                    ...values,
                    radioValue: e.target.value,
                    [e.target.value]: ''
                  }))
                }}
                value={values.radioValue}>
                <FormControlLabel
                  value={RADIO_STATE.RADIO_ALIAS}
                  control={<Radio color='primary' className={classes.radio} />}
                  classes={{ label: classes.labelRadio }}
                  label='Alias'
                />
                <FormControlLabel
                  value={RADIO_STATE.RADIO_NUMBER}
                  control={<Radio color='primary' className={classes.radio} />}
                  classes={{ label: classes.labelRadio }}
                  label='CBU/CVU (número de 22 dígitos)*'
                />
              </RadioGroup>
              {values.radioValue === RADIO_STATE.RADIO_ALIAS ? (
                <CustomTextField
                  className={classes.inputText}
                  value={values.alias}
                  variant='outlined'
                  autoComplete='off'
                  placeholder='Ingrese el Alias de la cuenta'
                  name='alias'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.alias && Boolean(errors.alias)}
                  helperText={errors.alias}
                />
              ) : (
                <CustomTextField
                  className={classes.inputText}
                  value={values.number}
                  variant='outlined'
                  autoComplete='off'
                  placeholder={'Ingrese el número de CBU/CVU de la cuenta'}
                  name='number'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={touched.number && Boolean(errors.number)}
                  helperText={errors.number}
                />
              )}
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                Nombre del titular*
              </Typography>
              <CustomTextField
                className={classes.inputText}
                value={values.fullName}
                variant='outlined'
                autoComplete='off'
                placeholder='Ingrese el nombre del titular de la cuenta'
                name='fullName'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.fullName && Boolean(errors.fullName)}
                helperText={errors.fullName}
              />
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                CUIT/CUIL*
              </Typography>
              <NumericField
                className={classes.inputNumeric}
                value={values.idNumber}
                variant='outlined'
                autoComplete='off'
                placeholder={'Número de 11 dígitos sin espacios ni guiones'}
                name='idNumber'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.idNumber && Boolean(errors.idNumber)}
                helperText={errors.idNumber}
              />
            </div>

            <div className={classes.formGroup}>
              <Typography color='primary' className={classes.label}>
                Banco o Billetera virtual*
              </Typography>
              <StyledSelect
                autoWidth={true}
                defaultOptions={optionsBanks}
                value={values.bank}
                placeholder='Seleccione el nombre del Banco o Billetera'
                colorOptions='primary'
                className={classes.select}
                onChange={(option) => setFieldValue('bank', option)}
                loadOptions={filterBanks(optionsBanks)}
              />
              {errors.bank && (
                <Typography variant='caption' color='error' className={classes.errorMessage}>
                  {errors.bank}
                </Typography>
              )}
            </div>

            {formType === FORM_TYPE.FORM_CREATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={pending && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting || pending}
                className={classes.submitButton}>
                Agregar
              </Button>
            )}

            {formType === FORM_TYPE.FORM_UPDATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={pending && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting || pending}
                className={clsx(classes.submitButton, classes.saveButton)}>
                Guardar
              </Button>
            )}
          </form>
        )}
      </Formik>
    )
  }

  return (
    <div className={classes.card}>
      <header className={classes.cardHeader}>
        {showCheckActive && (
          <>
            <Checkbox
              color='primary'
              className={clsx(classes.checkbox, {
                [classes.checkboxDisabled]: !isActive
              })}
              checked={checkStateValue}
              onChange={handleChangeStateBank}
              disabled={updateBankAccountPending}
            />
            <PaymentNotifyDialog
              {...paymentNotifyProps}
              onClose={() => setPaymentNotifyProps({ ...paymentNotifyProps, open: false })}
            />
          </>
        )}

        {title && (
          <Typography
            color='primary'
            className={clsx(classes.offLineTextTitle, !isActive && 'disabled', {
              [classes.offLineTextTitleForm]: cardState === CARD_STATES.CARD_FORM
            })}>
            {title}
          </Typography>
        )}

        {showActionbuttons && (
          <>
            <IconButton
              color='primary'
              size='small'
              className={clsx(classes.button, {
                [classes.disabled]: !isActive
              })}
              onClick={() => {
                onCloseOfflineBankAccountConfig()
                setOpenEditBankAccount(true)
              }}>
              <EditOutlinedIcon />
            </IconButton>

            <EditBankAccountDialog
              open={openEditBankAccount}
              title={title}
              bankAccount={bankAccount}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenEditBankAccount(false)
              }}
            />
            <IconButton
              color='primary'
              size='small'
              className={clsx(classes.button, {
                [classes.disabled]: !isActive
              })}
              onClick={onOpenDeleteBankAccount}>
              <DeleteOutlineIcon />
            </IconButton>
            <DeleteBankAccountDialog
              open={openDeleteBankAccount}
              bankAccount={bankAccount}
              title={title}
              onDelete={onDeleteBankAccount}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenDeleteBankAccount(false)
                setPaymentNotifyProps(paymentNotifyInitalState)
              }}
            />
          </>
        )}
      </header>
      {cardState === CARD_STATES.CARD_VIEW && bankAccountContent()}

      {cardState === CARD_STATES.CARD_FORM && bankAccountForm()}
    </div>
  )
}

export default BankAccountCard
