import { makeStyles } from '@material-ui/styles'
import markerIcon from 'assets/img/marker.png'

export const useStyles = makeStyles((theme) => ({
  marker: {
    backgroundImage: `url(${markerIcon})`,
    width: 20,
    height: 32,
    marginTop: -32,
    marginLeft: -10
  }
}))

export default useStyles
