import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(),
    borderBottom: 'none !important',

    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(3)
    }
  },
  profileImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 150,
    flexShrink: 0,
    height: 150,
    [theme.breakpoints.up('md')]: {
      marginTop: -theme.spacing(4)
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing()
  },
  title: {
    fontSize: '1rem'
  },
  buttonOpenEdit: {
    width: 285,
    height: 43,
    borderRadius: 20,
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(4),
    '& span': {
      fontSize: 20,
      fontWeight: 700
    }
  },
  sportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start'
    }
  },
  sport: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 500
  }
}))
