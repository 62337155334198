import * as types from './types'

const initialState = {
  tabIndex: 0,
  userLogged: { state: false },
  showInfoContact: false,
  userNotLogged: { state: false },
  addRoleState: null,
  activeRole: null,
  administratorAccount: {
    organization: null
  },
  userRole: null,
  inscriptionRegister: null
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SEARCH_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      }
    case types.VERIFY_USER_LOGGED:
      return {
        ...state,
        userLogged: action.userLogged
      }
    case types.TOGGLE_SHOW_INFO_CONTACT:
      return {
        ...state,
        showInfoContact: action.showInfoContact
      }
    case types.VERIFY_USER_NOT_LOGGED:
      return {
        ...state,
        userNotLogged: action.userNotLogged
      }
    case types.SET_ACTIVE_ROLE:
      return {
        ...state,
        activeRole: action.activeRole
      }
    case types.SET_ADMINISTRATOR_ACCOUNT:
      return {
        ...state,
        administratorAccount: action.administratorAccount
      }
    case types.VERIFY_USER_ROLE:
      return {
        ...state,
        userRole: action.userRole
      }
    case types.ADD_USER_ROLE:
      return {
        ...state,
        addRoleState: action.addRoleState
      }
    case types.TRY_INSCRIPTION_REGISTER:
      return {
        ...state,
        inscriptionRegister: action.inscription
      }

    default:
      return state
  }
}
