import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    marginRight: theme.spacing(3)
  },
  image: {
    width: 40,
    height: 40,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    border: '1px solid #C8C8C8',
    flexShrink: 0,
    '&.pendingConfirm': {
      filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))'
    }
  },
  nameContainer: {
    flexGrow: 1,
    maxWidth: 350,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  name: {
    fontSize: '1.125em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontWeight: 600,
    '&.pendingConfirm': {
      color: '#696969'
    }
  },
  banner: {
    backgroundColor: theme.palette.suspended.main,
    borderRadius: '50%',
    opacity: 0.5,
    width: '100%',
    height: '100%'
  },
  button: {
    backgroundColor: '#FF9D29',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: '#FF9D29'
    }
  }
}))

export default useStyles
