import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#ffff',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    minHeight: theme.spacing(15),
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(0, 10)
    }
  },
  eventName: {
    textAlign: 'center',
    padding: theme.spacing(2, 4),
    fontSize: '1rem',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem',
      padding: theme.spacing(2, 13)
    }
  },

  dateContainer: {
    padding: theme.spacing(0, 5),
    color: '#ffff',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    '& h6': {
      fontWeight: 500,
      flexGrow: 1,
      fontSize: '1.25rem',
      textTransform: 'uppercase'
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      position: 'absolute',
      backgroundColor: '#ffff',
      flexShrink: 0,
      color: theme.palette.primary.main
    }
  },
  bodyContainer: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
  },
  mainContainer: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      width: 1100,
      padding: theme.spacing(0, 6),
      margin: '0 auto'
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '& h5': {
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.up('lg')]: {
        textAlign: 'left'
      }
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(7)
    }
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    padding: theme.spacing(2),
    '& svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },
  messageContainer: {
    backgroundColor: '#ffff',
    height: '69.2vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 20px',
    textAlign: 'center'
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '68.5vh',
    justifyContent: 'center',
    alignItems: 'center',
    ' & h5': {
      fontWeight: 600
    }
  }
}))
