import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { loadUserData } from 'state/modules/auth'
import { updateUserAccount } from 'state/modules/userAccounts'

import { useStyles } from './SelectUserRoleDialog.style'

const SelectUserRoleDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { userData } = useSelector((state) => state.auth)

  const [selectedRoles, setSelectedRoles] = useState({
    isAthlete: false,
    isOrganizer: false
  })

  const [loading, setLoading] = useState(false)

  const handleChangeRole = (e) =>
    setSelectedRoles({ ...selectedRoles, [e.target.name]: e.target.checked })

  const handleSaveRole = async () => {
    setLoading(true)
    const updateUserAccountSuccess = await dispatch(
      updateUserAccount({ ...userData, ...selectedRoles })
    )

    if (updateUserAccountSuccess) {
      await dispatch(loadUserData(userData.id))

      setLoading(false)
      onClose()
    }
  }

  return (
    <SectionLayoutDialog open={open} title='SELECCIONAR ROL' className={classes.dialog}>
      <Typography variant='h6' color='primary' className={classes.title}>
        Hemos creado dos roles con funcionalidades específicas para cada actividad. Esto nos permite
        brindarte una experiencia personalizada y adecuada a tus necesidades.
      </Typography>
      <Typography variant='h6' color='primary' className={classes.subtitle}>
        Por favor, seleccioná de las siguientes opciones el rol que tendrá tu cuenta&nbsp;
        <span>(puedes elegir más de uno).</span>
      </Typography>

      <div className={classes.checkContainer}>
        <FormControlLabel
          value={true}
          onChange={handleChangeRole}
          name='isAthlete'
          className={classes.checkboxRoles}
          control={<Checkbox checked={selectedRoles.isAthlete} name='isAthlete' color='primary' />}
          label={
            <Typography variant='body1' className={classes.rolesParagraph}>
              Rol deportista, elige esta opción si practicas deportes y estás interesado en
              competir.
            </Typography>
          }
        />

        <FormControlLabel
          value={true}
          onChange={handleChangeRole}
          name='isOrganizer'
          className={classes.checkboxRoles}
          control={
            <Checkbox checked={selectedRoles.isOrganizer} name='isOrganizer' color='primary' />
          }
          label={
            <Typography variant='body1' className={classes.rolesParagraph}>
              Rol organizador, elige esta opción si organizas o te interesa organizar eventos
              deportivos.
            </Typography>
          }
        />
      </div>

      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={handleSaveRole}
        endIcon={loading && <CircularProgress size={16} color='primary' />}
        disabled={(!selectedRoles.isAthlete && !selectedRoles.isOrganizer) || loading}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default SelectUserRoleDialog
