import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SocialIcon } from 'react-social-icons'
import { Typography, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { CountriesProxy } from 'services'
import { EditableMultipleSelection, EditableSocialNetwork, EditableText } from 'shared'
import { USER_ROLE } from 'utils/constants'
import { filterCities, filterProvinces, getCountries } from 'utils/functions'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SectionInformation.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
const OTHER_OPTION = { label: 'Otra', value: 'other' }

const SectionInformation = ({ userAccount, hasError, handleSave, canEdit, errors }) => {
  const classes = useStyles()
  const theme = useTheme()
  const socialSize = { height: 48, width: 48 }
  const {
    idNumber: dni,
    birthdate,
    countryName,
    areaLevel1Name,
    areaLevel2Name,
    countryId,
    areaLevel2Other,
    areaLevel1Id
  } = userAccount

  const { activeRole } = useSelector((state) => state.app)

  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])

  const years = moment().diff(birthdate, 'years')
  const instagramUrl = userAccount ? userAccount.instagramUrl : null
  const facebookUrl = userAccount ? userAccount.facebookUrl : null
  const youTubeUrl = userAccount ? userAccount.youTubeUrl : null
  const location = [areaLevel1Name, areaLevel2Name || areaLevel2Other, countryName]
    .filter(Boolean)
    .join(', ')
  const empty = { value: '', label: '--Seleccione--' }

  const loadCountriesOptions = async (inputValue) => {
    if (!provinces.length) {
      getProvince(countryId)
    }
    if (!cities.length && areaLevel1Id) {
      getCity(areaLevel1Id)
    }
    return await getCountries()(inputValue)
  }

  useEffect(() => {
    if (!userAccount.countryId) {
      getProvince(ARGENTINA_COUNTRY.value)
    }
  }, [userAccount])

  const getProvince = async (id, entity, i) => {
    try {
      setProvinces([])
      setCities([])

      const proxy = new CountriesProxy()

      if (entity) {
        entity.areaLevel1Id = { ...empty }
        entity.areaLevel2Id = { ...empty }
      }

      if (id) {
        const data = await proxy.getAreaLevel1(id)

        if (Array.isArray(data)) {
          const values = data.map((d) => ({ value: d.id, label: d.name }))

          if (values.length) {
            if (id !== ARGENTINA_COUNTRY.value) {
              setProvinces([empty, ...values])
            } else {
              setProvinces(values)
            }
          }
        }
      }
    } catch (error) {}

    return entity
  }

  const getCity = async (id, entity, i) => {
    try {
      setCities([])

      const proxy = new CountriesProxy()

      if (entity) {
        entity.areaLevel2Id = { ...empty }
        entity.areaLevel2Other = ''
      }

      if (id) {
        const data = await proxy.getAreaLevel2(id)

        if (Array.isArray(data)) {
          const values = [...data.map((d) => ({ value: d.id, label: d.name })), OTHER_OPTION]

          setCities(values)
        }
      }
    } catch (error) {}

    return entity
  }

  return (
    <SectionBlock
      title={'Información'}
      sectionClassName={clsx(canEdit && activeRole === USER_ROLE.ORGANIZER && classes.section)}>
      {canEdit && (
        <>
          <Typography variant='h4' color='primary' className={classes.infoTitle}>
            dni
          </Typography>
          <EditableText
            entity={userAccount}
            field='idNumber'
            formClassName={classes.formText}
            inputClassName={classes.inputEdit}
            onSave={handleSave}
            validation={hasError}
            placeHolder='Ingrese su DNI'
            type='text'>
            <Typography className={classes.infoText} color='primary' variant='h4'>
              {dni || 'No proporcionado'}
            </Typography>
          </EditableText>
        </>
      )}
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Edad
      </Typography>

      <EditableText
        entity={userAccount}
        field='birthdate'
        type='date'
        onSave={handleSave}
        validation={hasError}
        dateFormat='YYYY-MM-DD'
        inputClassName={classes.datePicker}
        formClassName={classes.formText}>
        <Typography variant='h4' color='primary' className={classes.infoText}>
          {(userAccount && userAccount.birthdate && `${years} años`) || 'No proporcionado'}
        </Typography>
      </EditableText>

      {(!activeRole || activeRole === USER_ROLE.ATHLETE) && (
        <>
          <Typography variant='h4' color='primary' className={classes.infoTitle}>
            Altura
          </Typography>
          <EditableText
            entity={userAccount}
            field='height'
            onSave={handleSave}
            inputClassName={classes.inputEdit}
            formClassName={classes.formText}
            placeHolder='Ingrese su estatura'
            type='number'
            validation={hasError}>
            <Typography variant='h4' color='primary' className={classes.infoText}>
              {(userAccount && userAccount.height && `${userAccount.height} cm`) ||
                'No proporcionado'}
            </Typography>
          </EditableText>
          <Typography variant='h4' color='primary' className={classes.infoTitle}>
            Peso
          </Typography>
          <EditableText
            entity={userAccount}
            field='weight'
            onSave={handleSave}
            inputClassName={classes.inputEdit}
            formClassName={classes.formText}
            placeHolder='Ingrese su peso'
            validation={hasError}
            type='number'>
            <Typography variant='h4' color='primary' className={classes.infoText}>
              {(userAccount && userAccount.weight && `${userAccount.weight} Kg`) ||
                'No proporcionado'}
            </Typography>
          </EditableText>
        </>
      )}
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Ubicación
      </Typography>
      <EditableMultipleSelection
        entity={{ ...userAccount }}
        onSave={handleSave}
        selectClassName={classes.asyncSelectContainer}
        validation={hasError}
        resetOptions={() => getProvince(userAccount.countryId ?? ARGENTINA_COUNTRY.value)}
        type='list'
        field={['countryId', 'areaLevel1Id', 'areaLevel2Id']}
        errors={errors}
        items={[
          {
            label: 'countryName',
            value: 'countryId',
            load: loadCountriesOptions,
            onChange: getProvince,
            optionEmpty: ARGENTINA_COUNTRY,
            dynamic: true
          },
          {
            label: 'areaLevel1Name',
            value: 'areaLevel1Id',
            load: filterProvinces(provinces),
            onChange: getCity,
            options: provinces,
            optionEmpty: empty,
            static: true
          },
          {
            label: 'areaLevel2Name',
            value: 'areaLevel2Id',
            load: filterCities(cities),
            options: cities,
            optionEmpty: empty,
            otherOptionValue: OTHER_OPTION,
            otherOption: 'areaLevel2Other',
            static: true
          }
        ]}>
        <Typography variant='h4' color='primary' className={classes.infoText}>
          {(userAccount && location) || 'No proporcionado'}
        </Typography>
      </EditableMultipleSelection>
      {(!activeRole || activeRole === USER_ROLE.ATHLETE) && (
        <>
          <Typography variant='h4' color='primary' className={classes.infoTitle}>
            Redes Sociales
          </Typography>
          <div className={classes.socialLinks}>
            <EditableSocialNetwork onSave={handleSave} entity={userAccount}>
              <SocialIcon
                url={instagramUrl || undefined}
                network='instagram'
                style={socialSize}
                bgColor={'transparent'}
                fgColor={instagramUrl ? theme.palette.primary.main : '#c3c3c3'}
                target='_blank'
              />

              <SocialIcon
                network='facebook'
                url={facebookUrl || undefined}
                style={socialSize}
                bgColor={'transparent'}
                fgColor={facebookUrl ? theme.palette.primary.main : '#c3c3c3'}
                target='_blank'
              />
              <SocialIcon
                network='youtube'
                url={youTubeUrl || undefined}
                style={socialSize}
                bgColor='transparent'
                fgColor={youTubeUrl ? theme.palette.primary.main : '#c3c3c3'}
                target='_blank'
              />
            </EditableSocialNetwork>
          </div>
        </>
      )}
    </SectionBlock>
  )
}

export default SectionInformation
