import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './ConflictCreateDistanceDialog.style'

const ConflictCreateDistanceDialog = ({ onClose, open }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      title='TU EVENTO NO TIENE CATEGORÍAS AÚN'
      open={open}
      className={classes.dialog}>
      <div className={classes.emptyCategoryMessageContainer}>
        <Typography color='primary' variant='h6' align='center'>
          Para poder crear distancias necesitas tener categorías en tu evento.
        </Typography>
      </div>
      <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
        Aceptar
      </Button>
    </SectionLayoutDialog>
  )
}

export default ConflictCreateDistanceDialog
