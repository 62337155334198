import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadCalendar } from 'state/modules/userAccounts'

import { RunnerCalendar } from '../RunnerCalendar'
import { SectionBlock } from '../SectionBlock'
import { SectionCalendarPlaceHolderLoader } from '../SectionCalendarPlaceHolderLoader'

import { useStyles } from './SectionCalendar.style'

const SectionCalendar = ({ userAccountId, firstName }) => {
  const { calendarEvents, pendingCalendar } = useSelector((state) => state.userAccounts)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(loadCalendar(userAccountId))
  }, [dispatch, userAccountId])

  return (
    <SectionBlock title={`Calendario de ${firstName}`} className={classes.firstName}>
      {!pendingCalendar ? (
        calendarEvents?.map((o) => <RunnerCalendar key={o.id} nextEvent={o} />)
      ) : (
        <SectionCalendarPlaceHolderLoader />
      )}
    </SectionBlock>
  )
}

export default SectionCalendar
