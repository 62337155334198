import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Transition } from 'utils'

import { useStyles } from './SectionLayoutDialog.style'

const SectionLayoutDialog = ({
  open,
  onClose,
  title,
  children: content,
  className,
  contentClassName,
  mounted,
  containerClassName,
  fullScreen,
  mainContainerClassName,
  direction = 'up',
  backDropClassName,
  color = 'primary'
}) => {
  const classes = useStyles()

  const [contentScrolling, setContentScrolling] = useState(false)

  return (
    <Dialog
      keepMounted={mounted}
      open={open}
      onClose={onClose}
      fullWidth
      BackdropProps={{
        classes: {
          root: clsx(classes.backDrop, backDropClassName, color)
        }
      }}
      fullScreen={fullScreen}
      PaperProps={{
        classes: {
          root: clsx(classes.dialog, className)
        }
      }}
      TransitionProps={{ direction }}
      TransitionComponent={Transition}
      className={clsx(classes.container, containerClassName)}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={clsx(classes.headerContainer, color)}>
          <Typography
            color='primary'
            align='center'
            variant='h6'
            className={clsx(classes.title, !onClose && 'center')}>
            {title}
          </Typography>
          {onClose && (
            <IconButton color='primary' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
      </DialogTitle>
      <DialogContent
        className={clsx(classes.dialogContent, mainContainerClassName)}
        onScroll={() => setContentScrolling({ state: true })}>
        <div className={clsx(classes.bodyContainer, contentClassName)}>
          {typeof content === 'function' ? content(contentScrolling) : content}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SectionLayoutDialog
