import React from 'react'
import { Button, Dialog, IconButton, Typography } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@material-ui/icons'
import { Transition } from 'utils'

import { useStyles } from './ManagementDialog.style'

const ManagementDialog = ({ open, onClose, children: body, onChangeDialog, title }) => {
  const classes = useStyles()

  return (
    <>
      <Button
        color='primary'
        variant='outlined'
        className={classes.button}
        onClick={() => onChangeDialog((state) => !state)}>
        Administrar {title}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        BackdropProps={{
          classes: {
            root: classes.backDrop
          }
        }}
        PaperProps={{
          classes: {
            root: classes.dialog
          }
        }}
        TransitionComponent={Transition}>
        <div className={classes.headerContainer}>
          <div className={classes.titleContainer}>
            <Typography variant='h5' color='primary' className={classes.title}>
              Administrador de {title}
            </Typography>
          </div>
          <IconButton onClick={onClose} color='secondary'>
            <CloseIcon fontSize='large' />
          </IconButton>
        </div>
        {body}
        <IconButton onClick={onClose} className={classes.backButton}>
          <ArrowBackIcon size='medium' color='primary' />
        </IconButton>
      </Dialog>
    </>
  )
}

export default ManagementDialog
