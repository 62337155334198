import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import noImage from 'assets/img/no-image.jpg'
import clsx from 'clsx'
import { ROUTES } from 'routes'

import { InvitationConfirmDialog } from '../InvitationConfirmDialog'

import useStyles from './OrganizationWidget.style'

const OrganizationWidget = ({ organization, pendingConfirm, pendingInvitation }) => {
  const classes = useStyles()
  const [openInvitationConfirmDialog, setOpenInvitationConfirmDialog] = useState(false)

  const { name, loggedUserMetadata, id, handle } = organization
  const imageUrl =
    Array.isArray(organization.profileImages) && organization.profileImages.length > 0
      ? organization.profileImages[0]
      : noImage

  return (
    <div className={classes.container}>
      <Link
        className={classes.mainContainer}
        to={`${ROUTES.ORGANIZATIONS.PROFILE}/${handle || id}`}>
        <div className={classes.image} style={{ backgroundImage: `url(${imageUrl})` }}>
          {pendingConfirm && <div className={classes.banner} />}
        </div>
        <div className={classes.nameContainer}>
          <Typography
            variant='caption'
            color='primary'
            title={name}
            className={clsx(classes.name, pendingConfirm && 'pendingConfirm')}>
            {name}
          </Typography>
        </div>
      </Link>
      {loggedUserMetadata && loggedUserMetadata.canEdit && pendingInvitation && (
        <>
          <Button
            className={classes.button}
            variant='contained'
            onClick={() => setOpenInvitationConfirmDialog(true)}>
            Invitación pendiente
          </Button>
          <InvitationConfirmDialog
            open={openInvitationConfirmDialog}
            organization={organization}
            onClose={() => setOpenInvitationConfirmDialog(false)}
          />
        </>
      )}
    </div>
  )
}

export default OrganizationWidget
