import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '280px !important',
    [theme.breakpoints.up('md')]: {
      width: 750,
      maxWidth: 750
    }
  },
  container: {
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 3),
    '& p': {
      fontWeight: 500
    }
  }
}))
