import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '40px auto'
  },
  info: {
    fontWeight: 600
  },
  buttonContainer: {
    margin: '10px auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionButton: {
    minWidth: 150,
    width: '35%'
  }
}))
