import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { LayoutDialog } from 'shared'

import { useStyles } from './UpdateConfirmDialog.style'

const UpdateConfirmDialog = ({
  title,
  open,
  onClose,
  message,
  onCancel = () => {},
  onSave = () => {}
}) => {
  const classes = useStyles()

  const handleCancelConfirm = () => {
    onCancel()
    onClose()
  }

  const handleSave = async () => {
    await onSave()
    onClose()
  }

  return (
    <LayoutDialog title={title} open={open} onClose={handleCancelConfirm}>
      <div className={classes.container}>
        <Typography align='center' color='primary' className={classes.info}>
          {message}
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color='primary'
          variant='contained'
          className={classes.actionButton}
          onClick={handleCancelConfirm}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.actionButton}
          onClick={handleSave}>
          Modificar
        </Button>
      </div>
    </LayoutDialog>
  )
}

export default UpdateConfirmDialog
