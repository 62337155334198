import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  richField: {
    '& .ql-toolbar': {
      border: `1px solid ${theme.palette.primary.main}`,
      '&.error': {
        border: '1px solid #f44336'
      }
    },
    '& .ql-container': {
      border: `1px solid ${theme.palette.primary.main}`,
      minHeight: 200,
      fontFamily: theme.typography.fontFamily,
      '&.error': {
        border: '1px solid #f44336'
      }
    },
    '& .ql-editor': {
      minHeight: 200,
      fontSize: '1rem',
      '&:focus': {
        border: `1px solid ${theme.palette.primary.main}`
      },
      '&.error': {
        border: '1px solid #f44336'
      }
    },
    '& .ql-blank': {
      '&:before': {
        fontSize: '1rem',
        fontFamily: theme.typography.fontFamily,
        color: '#000',
        fontWeight: 400,
        fontStyle: 'normal'
      }
    },
    '& p, h1, h2, h3, h4, h5, h6': {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'anywhere'
    },
    '& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"], .ql-snow .ql-size .ql-picker-item[data-value="16px"]':
      {
        '&:before': {
          content: '"16px"',
          fontSize: '16px !important'
        }
      },
    '& .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="18px"], .ql-snow .ql-size .ql-picker-item[data-value="18px"]':
      {
        '&:before': {
          content: '"18px"',
          fontSize: '18px !important'
        }
      }
  },
  richFieldAlt: {
    backgroundColor: theme.palette.primary.contrastText
  },
  hiddenField: {
    '&  div': {
      border: 'none !important',
      padding: 0
    },
    '& .ql-container': {
      minHeight: 'auto'
    },
    '& .ql-editor': {
      minHeight: 'auto'
    },
    '& .ql-blank': {
      '&:before': {
        left: 0
      }
    },
    '& .ql-toolbar': {
      display: 'none'
    }
  },
  loadingMessage: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    marginLeft: theme.spacing()
  }
}))
