import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  additionalField: {
    margin: theme.spacing(2, 0)
  },
  textField: {
    margin: theme.spacing(1.5, 0)
  },
  textArea: {
    '& textarea': {
      minHeight: 45,
      fontSize: '0.85rem',
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    }
  },
  addOptionLink: {
    cursor: 'pointer',
    fontSize: '0.8rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0
    },
    '&::placeholder': {
      color: theme.palette.secondary.contrastText
    }
  },
  errorMessage: {
    marginLeft: theme.spacing(2)
  }
}))
