import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700,
      margin: '0 auto'
    },
    color: 'red'
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto'
    }
  },
  button: {
    alignSelf: 'center',
    width: 230,
    height: 40
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  title: {
    fontSize: 14,
    color: '#EF6262',
    paddingLeft: 0,
    fontWeight: 600
  },
  text: {
    fontSize: 14,
    color: 'black',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 500
    }
  },
  textBottom: {
    fontSize: 13,
    color: 'black',
    margin: '20px 0px 50px'
  }
}))
