import React, { useRef, useState } from 'react'
// import { Cropper } from 'react-cropper'
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
  withWidth
} from '@material-ui/core'
import {
  CameraAltOutlined as CameraAltOutlinedIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PhotoCamera as PhotoCameraIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Authenticated, CropperDialog } from 'shared'
import { dataUrlToFile } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'

import { ConfirmDialog } from '../ConfirmDialog'

import { useStyles } from './EditableImage.style'

const EditableImage = ({
  children,
  entity,
  defaultImage,
  field,
  canEdit,
  inputClassName,
  className,
  onSave,
  onDelete,
  size,
  type,
  isSuspended,
  mode
}) => {
  const [hasImage, setHasImage] = useState(Array.isArray(entity[field]))
  const [openCropper, setOpenCropper] = useState(false)
  const resetImage = () => (Array.isArray(entity[field]) && entity[field][0]) || defaultImage

  const [newImageUrl, setNewImageUrl] = useState(resetImage())
  const [initialSrcCropper, setInitialSrcCropper] = useState(null)

  const [showEditLayer, setShowEditLayer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const inputFileRef = useRef()
  const classes = useStyles()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const onCrop = async (cropper, originalFile) => {
    const imageType = 'image/jpeg'
    const imageDataUrl = cropper.getCroppedCanvas().toDataURL(imageType)
    const imageFile = await dataUrlToFile(imageDataUrl, originalFile.fileName, imageType)

    setNewImageUrl(imageDataUrl)
    setOpenCropper(false)

    onSave({ value: imageFile, type })
  }

  const handleFile = (onFile) => (event) => {
    if (event.target.files.length === 0) return

    const file = event.target.files[0]
    const { type: imageType } = file
    const validImageFormats = ['image/jpg', 'image/jpeg']
    event.target.value = ''

    if (!validImageFormats.includes(imageType)) {
      showSnackbarError({
        message:
          'El formato de imagen no es válido, por favor seleccione una imagen con formato JPG o JPEG.'
      })
      return
    }

    onFile({ value: file, type })
  }

  const handleClose = () => setAnchorEl(null)

  const handleDeleteFile = () => {
    setHasImage(false)
    onDelete({ type })
    handleClose()
    setNewImageUrl(defaultImage)
  }

  const handleOnchangeFile = () => {
    const { current } = inputFileRef
    current.click()
  }

  const handleActionEditMiniButton = (e) => {
    if (hasImage) setAnchorEl(e.currentTarget)
    else handleOnchangeFile()
  }

  const handleCancelEdit = () => {
    setOpenCropper(false)
    setInitialSrcCropper(null)
  }

  const handleOpenCropper = (file) => {
    const urlImage = URL.createObjectURL(file.value)
    setInitialSrcCropper(urlImage)
    setOpenCropper(true)
  }

  const renderModeButtonEditImage = () => {
    return (
      <div className={clsx(classes.container, className)}>
        <div
          style={{ backgroundImage: `url(${newImageUrl})` }}
          className={`${inputClassName} ${showEditLayer && 'hide'}`}>
          {initialSrcCropper && (
            <CropperDialog
              openDialog={openCropper}
              onClose={handleCancelEdit}
              defaultImage={initialSrcCropper}
              title='Portada del Evento'
              onSave={onCrop}
            />
          )}
          <input
            ref={inputFileRef}
            type='file'
            style={{ display: 'none' }}
            accept='.JPG, .JPEG'
            onChange={handleFile(handleOpenCropper)}
          />
          <Button
            color='primary'
            variant='contained'
            className={classes.editMiniButton}
            onClick={handleActionEditMiniButton}>
            {isDesktop && 'Reemplazar imagen '}
            <CameraAltOutlinedIcon className={classes.addPhotoIcon} />
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={!!anchorEl}
            onClose={handleClose}>
            <MenuItem onClick={handleOnchangeFile}>Subir foto</MenuItem>
            <MenuItem onClick={() => setOpenDialog(true)}>Eliminar</MenuItem>
          </Menu>
        </div>
      </div>
    )
  }

  const imageEdit = () => {
    return (
      <Authenticated>
        {mode === 'button' ? (
          renderModeButtonEditImage()
        ) : (
          <label
            role='button'
            className={classes.profileImageButton}
            onMouseOver={() => setShowEditLayer(true)}
            onMouseOut={() => setShowEditLayer(false)}>
            <div
              style={{ backgroundImage: `url(${newImageUrl})` }}
              className={`${inputClassName} ${showEditLayer && 'hide'}`}>
              {isSuspended && children?.props?.children}
            </div>
            <input
              ref={inputFileRef}
              type='file'
              style={{ display: 'none' }}
              accept='.JPG, .JPEG'
              onChange={handleFile(onSave)}
            />
            <div className={`${inputClassName} ${classes.editLayer} ${showEditLayer && 'hover'}`}>
              <IconButton className={classes.editButton} onClick={handleOnchangeFile}>
                <EditIcon />
              </IconButton>
              <div>
                <PhotoCameraIcon
                  className={classes.cameraIcon}
                  style={size && { width: size.width, height: size.height }}
                />
                <Typography align='center' style={size && { fontSize: size.fontsize }}>
                  Haz click para modificar la foto
                </Typography>
              </div>
              {hasImage && (
                <IconButton onClick={() => setOpenDialog(true)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          </label>
        )}
        <ConfirmDialog
          message='¿Está seguro que desea eliminar esta imagen?'
          openDialog={openDialog}
          onAction={handleDeleteFile}
          type='delete'
          setOpen={setOpenDialog}
        />
      </Authenticated>
    )
  }

  const imageOnly = () => <>{children}</>
  return canEdit ? imageEdit() : imageOnly()
}

export default withWidth()(EditableImage)
