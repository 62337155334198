import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: '85%',
      maxWidth: 1344
    }
  },
  bodyContainer: {
    flexDirection: 'column',
    display: 'flex'
  },
  sectionContainer: {
    backgroundColor: '#ffff',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    margin: theme.spacing(5, 0),
    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none'
    }
  },
  endContainer: {
    marginTop: theme.spacing(2),
    alignSelf: 'center',
    height: 150
  },
  backButton: {
    margin: '20px auto',
    padding: 15,
    alignSelf: 'center',
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  }
}))
