import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, DialogContent, Tab, useMediaQuery, useTheme } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import noImage from 'assets/img/no-image.jpg'
import noProfileImage from 'assets/img/user-mock.png'

import { FollowedCard } from '../FollowedCard'
import { FollowedCardPlaceHolderLoader } from '../FollowedCardPlaceHolderLoader'

import { DialogTitle, useStyles } from './FollowedDialog.style'

const FollowedDialog = ({
  open,
  handleClose,
  followedOrganizations,
  followedUserAccounts,
  myProfile
}) => {
  const [value, setValue] = useState('1')
  const [scroll] = useState('paper')
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { followedOrganizationsPending, followedUserAccountsPending } = useSelector(
    (state) => state.userAccounts
  )
  const organizationsFollowedCount = followedOrganizations.length || 0
  const userAccountsFollowedCount = followedUserAccounts.length || 0

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      className={classes.container}
      scroll={scroll}>
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        Seguidos
      </DialogTitle>
      <DialogContent>
        <TabContext value={value} dividers={scroll === 'paper'}>
          <TabList
            centered
            className={classes.tabs}
            onChange={handleChange}
            indicatorColor='primary'
            variant='fullWidth'
            textColor='primary'>
            <Tab
              className={classes.tab}
              label={`Deportistas (${userAccountsFollowedCount})`}
              color='primary'
              value='1'
              selected
            />
            <Tab
              className={classes.tab}
              label={`Organizaciones (${organizationsFollowedCount})`}
              value='2'
            />
          </TabList>
          <TabPanel value='1' color='primary' style={{ padding: 0 }}>
            {!followedUserAccountsPending ? (
              followedUserAccounts.map(
                ({ relatedUserAccount: userAccount, loggedUserIsFollowing }) => (
                  <FollowedCard
                    type='USERS_ACCOUNTS'
                    name={userAccount.fullName}
                    defaultImage={noProfileImage}
                    isFollowing={myProfile || loggedUserIsFollowing}
                    profileImage={userAccount.profileImages}
                    nickName={userAccount.username}
                    key={userAccount.id}
                    id={userAccount.id}
                  />
                )
              )
            ) : (
              <FollowedCardPlaceHolderLoader />
            )}
          </TabPanel>
          <TabPanel value='2' style={{ padding: 0 }}>
            {!followedOrganizationsPending ? (
              followedOrganizations.map(({ organization, loggedUserIsFollowing }) => (
                <FollowedCard
                  type='ORGANIZATIONS'
                  name={organization.name}
                  isFollowing={myProfile || loggedUserIsFollowing}
                  defaultImage={noImage}
                  nickName={organization.handle}
                  profileImage={organization.profileImages}
                  key={organization.id}
                  id={organization.id}
                />
              ))
            ) : (
              <FollowedCardPlaceHolderLoader />
            )}
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}

export default FollowedDialog
