import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700
    }
  },
  text: {
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.primary.main,
    width: 550,
    alignItems: 'center',
    textAlign: 'center'
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 70,
    '& button': {
      width: 200,
      borderRadius: 10,
      height: 40
    }
  }
}))
