import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  customTextField: {
    appearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    width: '100%',
    '& input': {
      height: 18,
      fontSize: '.825rem',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  }
}))
