import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    fontSize: '1.125rem',
    '&.secondary': {
      color: theme.palette.primary.main
    }
  },
  icon: {
    color: theme.palette.primary.main,
    '&.primary': {
      color: theme.palette.primary.contrastText
    }
  }
}))
