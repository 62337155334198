import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import {
  createCategory,
  createDistance,
  deleteCategory,
  deleteDistance,
  loadCategories,
  loadDistances,
  loadInscriptionsFormsCloneSuggestions,
  resetInscriptionFormsCloneSuggestions
} from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './ReUseFormDialog.style'

const ReUseFormDialog = ({ onSave }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [openReUseFormDialog, setOpenReUseFormDialog] = useState(false)
  const [reUseFormPending, setReUseFormPending] = useState(false)

  const [inscriptionFormIdSelected, setInscriptionFormIdSelected] = useState('')

  const {
    inscriptionFormsCloneSuggestions,
    inscriptionFormsCloneSuggestionsLastPage,
    inscriptionFormsCloneSuggestionsPageNumber,
    inscriptionFormsCloneSuggestionsPending,
    categories,
    distances,
    activeInscriptionForm,
    event
  } = useSelector((state) => state.events)

  useEffect(() => {
    dispatch(loadInscriptionsFormsCloneSuggestions(event.organization.id))

    return () => {
      dispatch(resetInscriptionFormsCloneSuggestions())
    }
  }, [dispatch])

  const optionLabel = (event) => `${moment(event.from).format('DD/MM/YYYY')} - ${event.name}`

  const actionReUseFormSuccess = () => {
    setOpenReUseFormDialog(false)
    showSnackbarSuccess('Se reutilizó la configuración seleccionada')
  }

  const handleReUseForm = async () => {
    setReUseFormPending(true)

    for (const distance of distances) {
      await dispatch(deleteDistance(event.id, distance.id))
    }

    for (const category of categories) {
      await dispatch(deleteCategory(event.id, category.id))
    }

    const eventCategories = []
    const eventDistances = []

    const inscriptionFormData = inscriptionFormsCloneSuggestions.find(
      (form) => form.id === inscriptionFormIdSelected
    )

    const {
      items,
      welcomeMessage = '',
      showInscriptionsFrom,
      quota,
      validateAgeFrom,
      id,
      ticketTypes,
      paymentConfirmationPeriod,
      ...restProps
    } = inscriptionFormData

    const { validateAge, validateGender } = items[0]

    for (const index in items) {
      let eventCategory = eventCategories.find((x) => x.name === items[index].eventCategory.name)
      let eventDistance = eventDistances.find((x) => x.name === items[index].eventDistance.name)

      if (!eventCategory) {
        const category = {
          ...items[index].eventCategory,
          name: items[index].eventCategory.name.trim(),
          shortName: (items[index].eventCategory.shortName ?? '').trim()
        }

        eventCategory = await dispatch(createCategory(event.id, category))
        eventCategories.push({ ...eventCategory })
      }

      if (!eventDistance) {
        eventDistance = await dispatch(createDistance(event.id, items[index].eventDistance))
        eventDistances.push({ ...eventDistance })
      }

      const { id, inscriptionsCount, inscriptionFormId, ...restProps } = items[index]
      items[index] = {
        ...restProps,
        eventCategory,
        eventDistance,
        eventCategoryId: eventCategory.id,
        eventDistanceId: eventDistance.id
      }
    }

    const copyForm = {
      ...restProps,
      items,
      validateGender,
      validateAge,
      openFromDate: null,
      openFromTime: null,
      openToDate: null,
      openToTime: null,
      quota: quota || '',
      validateAgeFrom:
        activeInscriptionForm?.validateAgeFrom ?? moment(event.from).format('YYYY-MM-DD'),
      validationDate:
        !activeInscriptionForm ||
        moment(moment(event.from).format('YYYY-MM-DD')).isSame(
          moment(activeInscriptionForm.validateAgeFrom)
        ),
      id: activeInscriptionForm?.id,
      welcomeMessage: welcomeMessage ?? '',
      isFree: event.isFree,
      showInscriptionsFrom: showInscriptionsFrom || '',
      checkedShowInscriptionsFrom: showInscriptionsFrom === 0,
      inscriptionsCount: 0,
      ticketTypes: [],
      paymentConfirmationPeriod: null
    }

    await dispatch(loadDistances(event.id))
    await dispatch(loadCategories(event.id))
    onSave(copyForm)
    actionReUseFormSuccess()

    setReUseFormPending(false)
  }

  return (
    <>
      {(!event.activeInscriptionFormId || event.activeInscriptionForm.isDraft) &&
        !!inscriptionFormsCloneSuggestions.length && (
          <div className={classes.reUtilContainer}>
            <Button
              variant='contained'
              color='secondary'
              className={classes.reUtilButton}
              onClick={() => setOpenReUseFormDialog(true)}>
              Reutilizar Formulario
            </Button>

            <div className={classes.infoContainer}>
              <InfoIcon color='primary' />
              <Typography color='primary'>
                Puedes reutlizar los formularios de tus eventos anteriores y editarlos.
              </Typography>
            </div>
            <SectionLayoutDialog
              className={classes.dialog}
              open={openReUseFormDialog}
              contentClassName={classes.content}
              onClose={() => setOpenReUseFormDialog(false)}
              title='REUTILIZAR FORMULARIO'>
              <Typography color='primary' variant='h6'>
                Seleccione el evento del cuál quiere reutilizar el formulario
              </Typography>
              <RadioGroup
                className={classes.optionsContainer}
                value={inscriptionFormIdSelected}
                onChange={(e) => setInscriptionFormIdSelected(e.target.value)}>
                {inscriptionFormsCloneSuggestions.map(({ id, event }) => (
                  <FormControlLabel
                    value={id}
                    key={id}
                    color='primary'
                    className={classes.option}
                    control={<Radio color='primary' className={classes.radioButton} />}
                    label={
                      <Typography
                        color='primary'
                        variant='h6'
                        title={optionLabel(event)}
                        className={classes.label}>
                        {optionLabel(event)}
                      </Typography>
                    }
                  />
                ))}

                {inscriptionFormsCloneSuggestionsPending && (
                  <CircularProgress size={16} className={classes.loading} />
                )}

                {!inscriptionFormsCloneSuggestionsPending &&
                  !inscriptionFormsCloneSuggestionsLastPage && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.showButton}
                      onClick={() =>
                        dispatch(
                          loadInscriptionsFormsCloneSuggestions(
                            event.organization.id,
                            inscriptionFormsCloneSuggestionsPageNumber + 1
                          )
                        )
                      }>
                      Ver más
                    </Button>
                  )}
              </RadioGroup>
              <div className={classes.buttonContainer}>
                <Button
                  color='primary'
                  variant='contained'
                  endIcon={reUseFormPending && <CircularProgress size={16} color='primary' />}
                  disabled={!inscriptionFormIdSelected || reUseFormPending}
                  onClick={handleReUseForm}>
                  Reutilizar
                </Button>
              </div>
            </SectionLayoutDialog>
          </div>
        )}
      {!event.activeInscriptionFormId &&
        !inscriptionFormsCloneSuggestions.length &&
        !inscriptionFormsCloneSuggestionsPending && (
          <div className={classes.alertContainer}>
            <InfoIcon color='primary' />
            <Typography color='primary' variant='h6'>
              Para poder crear el formulario deberás completar todos los campos obligatorios.
            </Typography>
          </div>
        )}
    </>
  )
}

export default ReUseFormDialog
