import React from 'react'
import { Divider, Typography } from '@material-ui/core'

import { useStyles } from './TermsAndConditionsContent.style'

const TermsAndConditionsContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <Typography className={classes.title} variant='h2' color='primary'>
          CONTRATO DE ACEPTACION DE TÉRMINOS Y CONDICIONES Y PRESTACIÓN DE SERVICIOS.
        </Typography>

        <Typography className={classes.text}>
          Usted está por comenzar a utilizar nuestros servicios, y para ello resulta indispensable
          que lea atentamente los Términos y Condiciones de Uso para Usuarios, que se expondrán a
          continuación. Luego de ello, para poder operar en nuestra plataforma, deberá de manera
          indefectible aceptar los mismos, prestando así conformidad y entendimiento respecto de lo
          aquí expresado.
        </Typography>
      </div>

      <Divider />

      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          PRIMERO: CONSENTIMIENTO Y ACEPTACION EXPRESA DEL USUARIO.
        </Typography>

        <Typography className={classes.text}>
          Al aceptar los Términos y Condiciones establecidos, usted está manifestando libre y
          expresamente su conformidad con los mismos, por lo que luego de ello, no podrá realizar
          reclamo alguno, respecto de todo lo que en el presente contrato se encuentre pautado y
          regulado.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          SEGUNDO: GENERALIDADES DEL SERVICIO.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC ARGENTINA S.A.S. (en adelante “SPORTMETRIC”), C.U.I.T 30-71842438-7, es una
          empresa cuyo objeto comercial recae en prestar servicios de gestión de compra y venta de
          Entradas para eventos de distinta índole. Dicha compra y venta de entradas puede ser
          realizada tanto mediante forma virtual, como también físicamente en puntos de venta
          dispuestos al efecto por el Productor del evento.
        </Typography>
        <Typography className={classes.text}>
          La función de SPORTMETRIC, debe quedar en claro, no resulta ser la de Organizadora de
          Eventos, sino que simplemente limita su función a realizar de intermediaria entre el
          ORGANIZADOR de un Evento (en adelante “ORGANIZADOR”) y el Usuario Final (en adelante
          “USUARIO”), quien compra una entrada para asistir a determinado evento, o bien quien sea
          el poseedor de la misma.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC, le brinda al ORGANIZADOR, por cuenta, mandato y orden de esta, el servicio de
          venta de Entradas para un determinado Evento. Dicha venta de entradas se realizará tanto
          de forma virtual como física en caso de ser requerida.
        </Typography>
        <Typography className={classes.text}>
          Es por ello, que el único y exclusivo responsable por la organización, modificación,
          realización, calidad, licitud, etc., del evento para el cual un USUARIO adquirió una
          entrada, es el ORGANIZADOR del evento. SPORTMETRIC no tendrá responsabilidad alguna por
          todo lo que tenga que ver con ello.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          TERCERO: USO DE LA PLATAFORMA.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO debe utilizar esta plataforma de manera pura y exclusivamente personal. Sin
          perjuicio de ello, SPORTMETRIC no se responsabilizará por el uso que los USUARIOS le
          puedan dar a las entradas compradas, ni por el motivo final de la compra realizada.
        </Typography>
        <Typography className={classes.text}>
          La plataforma de SPORTMETRIC, pondrá al servicio de los USUARIOS, toda la información
          relacionada al Evento publicado por el ORGANIZADOR, como así también el sistema virtual de
          venta de entradas, de acuerdo a las condiciones definidas para cada evento, para que los
          USUARIOS puedan adquirir los tickets del evento que les interese.
        </Typography>
        <Typography className={classes.text}>
          La Plataforma virtual de SPORTMETRIC, como así también su sistema y toda información
          proporcionada, almacenada y demás datos sensibles del Usuario, es de la exclusiva
          propiedad de la empresa, y no están permitidas las descargas de los contenidos que obren
          en ella. En caso de que algún incumplimiento de estos sea detectado, se accionara
          legalmente en el marco de las leyes internacionales y nacionales.
        </Typography>
        <Typography className={classes.text}>
          Los USUARIOS que operen en la Plataforma Virtual de SPORTMETRIC con fines ilícitos, que
          lesionen derechos e intereses de terceros, o que de cualquier forma puedan dañar,
          inutilizar, sobrecargar, deteriorar o impedir la normal utilización de la misma, los
          equipos informáticos o los documentos, archivos y toda clase de contenidos, serán pasibles
          de la cancelación de su cuenta. Así también, en caso de ser necesario, podrán ser objetó
          de acciones civiles o bien penales por parte de la Empresa.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          CUARTO: PAUTAS DE VENTA Y COBRO.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC, hace las veces en su plataforma de intermediario entre un ORGANIZADOR que
          pone a la venta entradas para un determinado Evento, y los USUARIOS finales, quienes
          compran entradas por medio de nuestros servicios para acceder a dichos eventos. Dicha
          venta de entradas, se realiza de manera virtual por intermedio de la Plataforma Virtual
          dispuesta por la empresa al efecto. Sin perjuicio de ello, SPORTMETRIC y el ORGANIZADOR
          podrán pactar, ofrecerles a los USUARIOS venta de entradas en puntos de venta físicos,
          transferencias o links de pagos externos a SPORTMETRIC.
        </Typography>
        <Typography className={classes.text}>
          En este sentido, para la venta On-Line, SPORTMETRIC utiliza para el cobro de las mismas,
          la plataforma virtual de MERCADOPAGO, motivo por el cual los cobros de las entradas
          compradas por los USUARIOS se automatizan directamente, derivándose de manera automática
          los importes que le corresponda a cada parte. Esto sería: 1) Lo correspondiente al valor
          neto de la entrada se deriva automáticamente a la cuenta del ORGANIZADOR que pone a la
          venta el evento; 2) Lo correspondiente al porcentaje que cobra SPORTMETRIC por la
          prestación del servicio de compra-venta de entradas se dirige automáticamente a la cuenta
          MERCADOPAGO de esta; 3) El porcentaje correspondiente a la plataforma de pago, se deriva
          automáticamente a la cuenta propia de MERCADOPAGO.
        </Typography>
        <Typography className={classes.text}>
          Es importante destacar que SPORTMETRIC no percibirá de ninguna manera el dinero total
          correspondiente a la venta de una entrada, sino que solo percibirá lo correspondiente a la
          comisión que le es debida por cada entrada vendida por su sistema de gestión de venta, a
          través de la modalidad Online. Para el resto de las operaciones por fuera de MERCADOPAGO
          integrado en SPORTMETRIC, la empresa no tiene ningún tipo de injerencia ni responsabilidad
          más allá de proveer al USUARIO los datos consignados por el ORGANIZADOR para el fin de
          adquirir la entrada.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          QUINTO: RESPONSABILIDAD DEL USUARIO RESPECTO AL PAGO.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO será el único responsable por los datos que figuren, introducidos por este, en
          el detalle de su orden, motivo por el cual se recomienda revisar los mismos previo a
          confirmar una operación de compra de entrada. Se le recuerda a los USUARIOS que, una vez
          enviada la orden de compra, no se permitirán cambios, devoluciones o reembolso respecto de
          la entrada adquirida. Lo antes mencionado queda sujeto a excepciones que pueda determinar
          el ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Por otra parte, la efectivización de la transacción realizada por venta Online a través de
          MERCADOPAGO y la concreta disposición de la entrada al USUARIO, quedarán estrictamente
          sujeta a la aceptación de la operación de pago por parte de la empresa emisora de la
          tarjeta utilizada por el USUARIO para el pago, como así también a los términos y
          condiciones dispuestos por MERCADOPAGO, como plataforma de pago utilizada.
        </Typography>
        <Typography className={classes.text}>
          Para el caso de los medios de venta físicos de cobro en efectivo, transferencia o links de
          pagos que proponga el ORGANIZADOR, se aclara que SPORTMETRIC solamente brindará el sistema
          de Gestión de la generación de la entrada propiamente dicha. El ORGANIZADOR asume la
          responsabilidad de la venta y cobro de entradas en efectivo, transferencia o links de
          cobros como así también del personal que se disponga al efecto.
        </Typography>
        <Typography className={classes.text}>
          El USUARIO manifiesta aceptar que el ORGANIZADOR es el único y exclusivo responsable por
          la definición o aumento de precios, las características y calidad del evento y la correcta
          ejecución del mismo, como así también los daños que puedan producirse durante el curso y/o
          suspensión del EVENTO. El USUARIO expresa asimismo y reconoce que respecto a todo lo
          mencionado, SPORTMETRIC queda exento de cualquier tipo de reclamo.
        </Typography>
        <Typography className={classes.text}>
          El USUARIO será exclusivo responsable respecto de la prohibición que opera sobre las
          entradas adquiridas de revenderlas, y/o utilizarlas para otro fin comercial que no sea
          simplemente el de asistir personalmente al evento. Al respecto, SPORTMETRIC se reserva el
          derecho de anular la validez de entrada en el caso de detectar que la compra realizada
          pudiera haberse concretado con fines distintos a los de la simple participación del
          USUARIO en el evento, o bien con cualquier otro fin ilícito.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          SEXTO: ACEPTACION DEL PAGO POR LA PLATAFORMA “MERCADOPAGO”.
        </Typography>

        <Typography className={classes.text}>
          La confirmación de la compra del USUARIO, ya sea a través de medios de acreditación
          instantánea (según definición de la Plataforma MERCADOPAGO) o cupón de pago, está sujeta a
          la autorización de la empresa emisora de ésta y a MERCADOPAGO, siendo este último el
          agente intermediario de pagos y devoluciones entre la tarjeta o cupón de pago y el
          USUARIO.
        </Typography>
        <Typography className={classes.text}>
          Además, MERCADOPAGO o la entidad emisora de la tarjeta pueden cancelar la compra en
          cualquier momento si consideran que hay un riesgo de seguridad o fraude. SPORTMETRIC no
          realiza estos controles de seguridad, ni fraude y está sujeto a lo que MERCADOPAGO o la
          entidad emisora de la tarjeta dispongan sin que esto obligue a SPORTMETRIC a reservar la
          entrada o mantener el precio en caso de que el ORGANIZADOR lo cambiara, o garantizar cupo
          en el caso de que una entrada fuera cancelada automáticamente por MERCADOPAGO o la entidad
          emisora de la tarjeta.
        </Typography>
        <Typography className={classes.text}>
          Queda a total responsabilidad del USUARIO, en virtud de lo mencionado, también conocer los
          Términos y Condiciones que para todo ello disponga MERCADOPAGO en su plataforma de cobros.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          SEPTIMO: RESPONSABILIDAD DEL USUARIO POR LA COMPRA DE LA ENTRADA.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO que realice, operaciones de compra de entradas estará aceptando los Términos y
          Condiciones aquí mencionados, lo que significará que el mismo los ha leído y entendido,
          incluyendo sin limitación: (i) la descripción y especificación del evento para el cual
          adquirió las Entradas, (ii) los plazos y condiciones de la entrega, (iii) los precios y
          condiciones de pago, (iv) los costos y cargos adicionales. Una vez confirmada su compra no
          se permitirán cambios, reembolsos y/o devoluciones por este motivo. Además, debe estar al
          tanto de la política de devolución establecida por el ORGANIZADOR. SPORTMETRIC no está
          obligado a proveer esta política en ningún lugar de la plataforma. El único responsable de
          comunicar sus políticas de devolución es el ORGANIZADOR del evento.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          OCTAVO: CONDICIONES DE ENTREGA DE LA ENTRADA
        </Typography>

        <Typography className={classes.text}>
          Una vez realizada y aprobada la compra de la ENTRADA por el USUARIO, se activará en su
          cuenta de la plataforma de SPORTMETRIC su “Inscripción”, el cual, junto a su Documento
          Nacional de Identidad, será el documento que le permitirá ingresar al evento. Salvo
          definiciones adicionales del ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, recibirá vía electrónica, más precisamente por correo electrónico, un aviso de
          cortesía de que su “Preinscripción/Inscripción/Anulación” fue generado y el estado de la
          misma se encuentra disponible en la plataforma SPORTMETRIC. El ORGANIZADOR es el único
          responsable de mantener actualizado los diferentes estados de la información y el estado
          de cada USUARIO exceptuando aquellos cobros por vía Online donde MERCADOPAGO y/o la
          entidad emisora de la tarjeta detecten alguna irregularidad.
        </Typography>
        <Typography className={classes.text}>
          Dicha confirmación de estado de Inscripción, dependerá también de la forma de pago elegida
          por el USUARIO.
        </Typography>
        <Typography className={classes.text}>
          De esa manera, para el caso de que el USUARIO realice la compra a través de los medios
          disponibles al momento de realizar la operación, recibirá en su correo electrónico
          consignado al efecto, que la Inscripción y su estado ya se encuentra disponible en la
          plataforma, una vez procesado y autorizado el pago, por el ORGANIZADOR como así también
          por la empresa emisora y por MERCADOPAGO.
        </Typography>
        <Typography className={classes.text}>
          Si el USUARIO elige como método de pago un cupón de pago de las entidades de cobro
          dispuestas al efecto, se deberá esperar el plazo de acreditación (dependerá de la entidad
          de cobro). Una vez acreditado el pago y procesado por los medios antes mencionados, el
          USUARIO recibirá la confirmación en la forma indicada.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          NOVENO: POLITICA DE DEVOLUCIÓN
        </Typography>

        <Typography className={classes.text}>
          El USUARIO al aceptar los presentes términos y condiciones, presta conformidad respecto de
          que SPORTMETRIC queda exento de la devolución del valor de las entradas, en caso de
          suspensión del evento organizado por el ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          En tal sentido, expresa conocer que deberá someterse a la Política de Devolución ofrecida
          por el ORGANIZADOR, único responsable de todo lo relacionado con el evento y su
          realización. SPORTMETRIC no está obligado a mostrar la política de devolución del
          Organizador en ningún lugar de su plataforma. Tampoco podrá reclamársele a SPORTMETRIC en
          caso de que el ORGANIZADOR decida por el motivo que fuere no realizar la devolución por la
          suspensión, cambio o cualquier contratiempo con el evento a realizarse.
        </Typography>
        <Typography className={classes.text}>
          Sin perjuicio de ello, SPORTMETRIC aclara que, respecto de la devolución de las entradas
          vendidas, y si así lo decidiera el ORGANIZADOR, existe la posibilidad de automatizar la
          devolución de dichos importes al USUARIO que haya adquirido la entrada dentro del plazo
          estipulado por MERCADOPAGO luego de efectivizada la compra, mediante la plataforma
          utilizada (Dichos plazos son establecidos por MERCADOPAGO). De esta manera, y para el caso
          de que el ORGANIZADOR decida realizar la devolución mediante esta forma, deberá informarlo
          al USUARIO y requerirle toda la información necesaria al efecto, quedando SPORTMETRIC
          exento de todo reclamo.
        </Typography>
        <Typography className={classes.text}>
          Pasado el plazo estipulado por MERCADOPAGO, de la compra efectuada por el USUARIO, el
          ORGANIZADOR deberá hacerse cargo de realizar las devoluciones por sus propios medios,
          responsabilizándose expresamente por ello, en dicho caso el USUARIO expresa aceptar lo
          mencionado y se compromete a no reclamar importe alguno a SPORTMETRIC.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMO: DESLINDE DE RESPONSABILIDAD DE SPORTMETRIC.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO entiende y acepta, que SPORTMETRIC quedara excluido de cualquier tipo de
          responsabilidad que pueda surgir por cuestiones relativas al fin último que tenga la
          realización del evento organizado por el ORGANIZADOR, como ser fines ilícitos, delictivos,
          evasivos en términos impositivos, o cualquier otro fin que exceda el de entretenimiento.
          Así, tampoco será responsable por todo lo relacionado con la realización del mismo, sus
          modificaciones, su calidad, habilitación, accidentes/daños/lesiones dentro del evento, y
          demás contratiempos e inconvenientes imputables al ORGANIZADOR, como así también todo lo
          relativo al lugar físico donde se realice el evento, aceptando esto el USUARIO y dejando
          expresa constancia que todo cuanto tenga por reclamar en el marco de dichas cuestiones
          deberá realizarse al ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, SPORTMETRIC también quedará exento de responsabilidad por aquellos USUARIOS que
          utilicen las entradas con otro fin más que el de asistir al evento, como ser, por ejemplo,
          la reventa ilegal de entradas.
        </Typography>
        <Typography className={classes.text}>
          Es menester recordar en este punto que la única responsabilidad que tiene SPORTMETRIC
          respecto de las entradas, es la de poner al servicio de el ORGANIZADOR y el USUARIO un
          canal de intermediario para que el oferente de un evento pueda poner a la venta las
          entradas para el mismo y el USUARIO pueda adquirirlas, respondiendo solo por el sistema o
          plataforma de gestión de ventas y su correcto funcionamiento.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, SPORTMETRIC queda totalmente ajena, y así lo reconoce el USUARIO, por cualquier
          daño y perjuicio que puedan generarle al USUARIO el uso de la plataforma, en lo
          relacionado a la presencia de virus o cualquier otro elemento en los contenidos que en la
          plataforma se muestran y que puedan generar alteraciones en los sistemas operativos,
          informativos o dispositivos móviles/computadoras de los USUARIOS en los que utilicen la
          plataforma.
        </Typography>
      </div>

      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOPRIMERO: ATENCIÓN AL CLIENTE.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC pone a disposición del cliente, un canal de comunicación para todas aquellas
          dudas o consultas que puedan surgir respecto de los presentes términos y condiciones, como
          así también para realizar consultas respecto de cualquier problema que se genere en la
          operatividad de la plataforma de compra y venta de entradas.
        </Typography>
        <Typography className={classes.text}>
          Dicho canal habilitado será el siguiente correo electrónico: info@sportmetric.net
        </Typography>
      </div>

      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEGUNDO: INFORMACION ALMACENADA DE DATOS PERSONALES DE USUARIOS.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO, con la aceptación de estos términos y condiciones, presta su consentimiento
          libre y voluntario respecto del almacenamiento de ciertos datos personales.
        </Typography>
        <Typography className={classes.text}>
          Para realizar las operaciones de Inscripción y compra de entradas dentro de la plataforma
          de SPORTMETRIC, el USUARIO debe ante todo registrarse o ingresar sus datos en el
          formulario de inscripción con el fin de ingresar los datos personales pertinentes a modo
          de Declaración Jurada para que la plataforma pueda almacenarlos y realizar el proceso de
          tratamiento de los mismos para generar la entrada a la persona que está realizando
          libremente la Inscripción y la compra de la misma con el fin de asistir a un evento
          organizado por un ORGANIZADOR.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOTERCERO: INFORMACION ALMACENADA DE DATOS DE MEDIOS DE PAGO DE LOS USUARIOS
        </Typography>

        <Typography className={classes.text}>
          Es dable destacar que SPORTMETRIC no almacena en su plataforma todos aquellos datos
          relacionados a los medios de pago utilizados por los USUARIOS al momento de realizar el
          proceso de compra de entradas.
        </Typography>
        <Typography className={classes.text}>
          Esto es así, dado que como ya hemos explicado, SPORTMETRIC no utiliza en su sistema de
          gestión de venta de entradas, un sistema propio de pagos, sino que utiliza la plataforma
          de MERCADOPAGO para todo lo relacionado con el cobro de las entradas.
        </Typography>
        <Typography className={classes.text}>
          En virtud de ello, el USUARIO acepta y deja expresa constancia que para todo lo que sea
          relacionado con el almacenamiento de datos de medios de pago, deberán atenerse a los
          términos y condiciones que para ello impone MERCADOPAGO, quedando SPORTMETRIC ajeno y
          excluido de cualquier tipo de responsabilidad respecto al almacenamiento de dichos datos.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOCUARTO: UTILIZACIÓN DE LA INFORMACION ALMACENADA.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO presta su consentimiento, para el almacenamiento de los datos personales
          ingresados en la plataforma de SPORTMETRIC, y acepta y declara conocer que los mismos sean
          utilizados para el tratamiento automatizado de dichos datos e información, como así
          también su utilización para el correcto funcionamiento de los servicios actuales o futuros
          tendientes a validar las órdenes de compra, y para toda mejora que se pueda realizar en la
          prestación del servicio otorgado por SPORTMETRIC, todo ello conforme lo establecido en la
          Ley de Protección de Datos Personales N° 25.326.
        </Typography>
        <Typography className={classes.text}>
          En el marco de la mencionada Ley, el USUARIO también tendrá el derecho de solicitar,
          previa acreditación fehaciente de su identidad, y obtener toda la información relativa a
          sus datos que se encuentren almacenados dentro de la plataforma de SPORTMETRIC. Dicho
          derecho podrá ser ejercido de forma gratuita a intervalos no menores a 6 (SEIS) meses,
          todo ello según lo estipulado en el Art. 14 de la citada norma legal.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOQUINTO: MODIFICACION, ELIMINACION O SUPRESION DE DATOS ALMACENADOS.
        </Typography>

        <Typography className={classes.text}>
          De acuerdo a lo estipulado en el Art. 27, Inc. 3 de la Ley 25.326, el USUARIO, tendrá la
          libre disponibilidad de sus datos, por lo cual podrá solicitarle en cualquier momento a
          SPORTMETRIC, la modificación, eliminación o supresión de los datos personales que obren en
          su plataforma.
        </Typography>
        <Typography className={classes.text}>
          Dicha solicitud deberá ser enviada por el USUARIO al canal de comunicación dispuesto por
          SPORTMETRIC para la atención al cliente, acreditando fehacientemente su identidad, y
          explicando el motivo por el cual lo requiere.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEXTO: CONTENIDOS Y SITIOS ENLAZADOS
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC podrá incluir dentro de su plataforma, dispositivos técnicos de enlace,
          directorios e incluso instrumentos de búsqueda que permitan al USUARIO acceder a otras
          páginas y/o portales de Internet (en adelante, “SITIOS ENLAZADOS”).
        </Typography>
        <Typography className={classes.text}>
          En base a ello, el USUARIO tendrá la libre opción de acceder o no a los SITIOS ENLAZADOS,
          motivo por el cual acepta y deja expresa constancia que SPORTMETRIC no será responsable de
          los contenidos y servicios suministrados en los mismos.
        </Typography>
        <Typography className={classes.text}>
          Sin perjuicio de ello, en el caso de que un USUARIO detecte o considere que existe un
          SITIO ENLAZADO con contenidos ilícitos o inadecuados podrá comunicarselo a SPORTMETRIC,
          sin que en ningún caso esta comunicación conlleve la obligación de SPORTMETRIC de retirar
          el correspondiente enlace.
        </Typography>
        <Typography className={classes.text}>
          La existencia de SITIOS ENLAZADOS en la plataforma, de ningún modo significa que
          SPORTMETRIC posea con todos ellos acuerdo o asociación, ni con sus responsables o
          titulares, como así tampoco significa que SPORTMETRIC los esté promocionando a todos,
          recomendando ni publicitando.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC, nada tiene que ver con las manifestaciones, contenidos o servicios provistos
          por dichos sitios, y por lo tanto se encuentra totalmente ajeno a los mismos,
          comprometiéndose el USUARIO a no reclamar nada por los contenidos o servicios que puedan
          divulgar o prestar dichos sitios.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC no conoce los contenidos y servicios de los SITIOS ENLAZADOS y, por tanto, no
          se hace responsable por los daños producidos por la ilicitud, calidad, desactualización,
          indisponibilidad, error e inutilidad de los contenidos y/o servicios que brinden, ni por
          cualquier otro daño que puedan generar.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMOSEPTIMO: POLITICA DE PRIVACIDAD.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC utilizará los datos personales de los USUARIOS registrados con el único fin de
          validar los procesos y las órdenes de compra, como así también para mejorar sus servicios
          prestados y la comercialización de las entradas vendidas. También serán utilizados con el
          fin de llevar un control y recuento de las entradas vendidas de un evento, información que
          podrá ser solicitada a SPORTMETRIC por el ORGANIZADOR.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC se compromete a no traspasar los datos mencionados a terceros ajenos a la
          prestación del servicio brindado, salvo a aquellas personas o empresas relacionadas con
          SPORTMETRIC, como ser el ORGANIZADOR de un evento.
        </Typography>
        <Typography className={classes.text}>
          SPORTMETRIC podrá generar bases de datos propias con la información ingresada a la
          plataforma por los USUARIOS, para ser utilizados exclusivamente con fines comerciales y de
          promoción.
        </Typography>
        <Typography className={classes.text}>
          El USUARIO acepta y deja expresa constancia que toda la información solicitada por
          SPORTMETRIC, es proveída por éste de manera voluntaria y consentida, como así también
          entendiendo y aceptando la política de privacidad y la totalidad de las cláusulas del
          presente contrato.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DÉCIMOCTAVO: JURISDICCION Y LEY APLICABLE.
        </Typography>

        <Typography className={classes.text}>
          Los presentes Términos y Condiciones se regirán en lo sucesivo por las Leyes vigentes en
          la República Argentina.
        </Typography>
        <Typography className={classes.text}>
          Asimismo, en el caso de existir cualquier conflicto que derive del presente, su contenido,
          interpretación, alcance o cumplimiento, será sometido exclusivamente a los Tribunales
          Ordinarios de la Ciudad de San Salvador de Jujuy, renunciando el USUARIO a cualquier otra
          jurisdicción aplicable al caso concreto.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          DECIMONOVENO: INDEMNIDAD.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO se obliga con la aceptación de los presentes términos y condiciones a mantener
          indemne al/los titular/es de la plataforma de SPORTMETRIC, sus funcionarios, directores y
          empleados, incluyendo honorarios de abogados, frente a cualquier reclamo de terceros
          basado en su utilización de esta Plataforma y/o de sus contenidos, o por violación del
          presente contrato.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMO: DOMICILIO.
        </Typography>

        <Typography className={classes.text}>
          Se fija como domicilio de SPORTMETRIC en “Las Espuelas 1473 - BAJO LA VIÑA”, Ciudad de San
          Salvador de Jujuy, Provincia Jujuy, República Argentina.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMAPRIMERA: VIGENCIA Y CAMBIOS EN LOS TERMINOS y CONDICIONES.
        </Typography>

        <Typography className={classes.text}>
          SPORTMETRIC se reserva el derecho de hacer modificaciones, reemplazar, remover o extender
          los presentes Términos y Condiciones en cualquier momento y hacerlas entrar en vigencia de
          manera inmediata sin la obligación de dar previo aviso al USUARIO.
        </Typography>
      </div>
      <div className={classes.block}>
        <Typography variant='h4' className={classes.subTitle}>
          VIGESIMOSEGUNDA: ACEPTACIÓN DE LOS TERMINOS Y CONDICIONES.
        </Typography>

        <Typography className={classes.text}>
          El USUARIO acepta, de manera libre y voluntaria, los presentes términos y condiciones,
          dejando expresa constancia que es su voluntad someterse a ellos, habiendo entendido los
          mismos.
        </Typography>
      </div>
    </div>
  )
}

export default TermsAndConditionsContent
