import React from 'react'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer, PrivacyPolicyContent } from 'shared'

import { useStyles } from './PrivacyPolicyPage.style'

const PrivacyPolicyPage = () => {
  const classes = useStyles()
  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer customContainerClassName={classes.pageContainer}>
        <div className={classes.container}>
          <PrivacyPolicyContent />
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default PrivacyPolicyPage
