import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '450px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5),
      width: '60%',
      margin: '0 auto',
      marginBottom: theme.spacing(3)
    }
  }
}))
