import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import clsx from 'clsx'
import { Formik } from 'formik'
import { OrganizationsProxy } from 'services'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { QuerySuccessDialog } from '../QuerySuccessDialog'

import { QueryTextField, useStyles } from './QueryForm.style'

const QueryForm = () => {
  const classes = useStyles()

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const values = {
    name: '',
    organizationName: '',
    eMail: '',
    query: ''
  }

  const validation = Yup.object().shape({
    name: Yup.string()
      .required('Debe ingresar su nombre')
      .max(256, 'El tamaño máximo del nombre es de 256 caracteres')
      .trim(),
    organizationName: Yup.string()
      .required('Debe ingresar el nombre de su organización')
      .max(100, 'El nombre de la organización debe tener como máximo 100 caracteres')
      .trim(),
    eMail: Yup.string()
      .required('Debe ingresar su email')
      .email('El formato de email ingresado no es válido')
      .min(5, 'El email debe tener al menos 5 caracteres')
      .max(254, 'El email debe tener como máximo 254 caracteres')
      .trim(),
    query: Yup.string()
      .required('Debe ingresar su consulta')
      .max(150, 'La consulta tiene un máximo de 150 caracteres')
      .trim()
  })

  const onSubmit = async ({ name, organizationName, eMail, query }, { resetForm }) => {
    try {
      setLoading(true)

      const values = {
        name: name.trim(),
        organizationName: organizationName.trim(),
        eMail: eMail.trim(),
        query: query.trim()
      }

      const proxy = new OrganizationsProxy()
      await proxy.postSendQuery(values)

      resetForm()
      setOpenSuccessDialog(true)
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={values}
      validationSchema={validation}>
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        errors,
        touched,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <div className={classes.formGroup}>
            <QueryTextField
              value={values.name}
              variant='outlined'
              name='name'
              label='*Nombre:'
              fullWidth
              error={touched.name && !!errors.name}
              onChange={handleChange}
              autoComplete='off'
              onBlur={handleBlur}
              helperText={errors.name}
              className={clsx(classes.textField, classes.nameTextField)}
            />
          </div>

          <div className={classes.formGroup}>
            <QueryTextField
              value={values.organizationName}
              variant='outlined'
              label='*Organización:'
              fullWidth
              autoComplete='off'
              name='organizationName'
              error={touched.organizationName && !!errors.organizationName}
              onChange={handleChange}
              className={clsx(classes.textField, classes.organizationTextField)}
              onBlur={handleBlur}
              helperText={errors.organizationName}
            />
          </div>

          <div className={classes.formGroup}>
            <QueryTextField
              value={values.eMail}
              label='*Email:'
              fullWidth
              autoComplete='off'
              variant='outlined'
              name='eMail'
              className={clsx(classes.textField, classes.eMailTextField)}
              error={touched.eMail && !!errors.eMail}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.eMail}
            />
          </div>

          <div className={classes.formGroup}>
            <QueryTextField
              value={values.query}
              label='*Consulta:'
              fullWidth
              multiline
              autoComplete='off'
              variant='outlined'
              name='query'
              rowsMax={4}
              className={clsx(classes.textArea, classes.queryTextArea)}
              error={touched.query && !!errors.query}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.query}
            />
          </div>

          <Button
            type='submit'
            className={classes.sendButton}
            disabled={isSubmitting || loading}
            endIcon={loading && <CircularProgress size={16} color='primary' />}>
            Enviar
          </Button>

          <QuerySuccessDialog
            open={openSuccessDialog}
            onClose={() => setOpenSuccessDialog(false)}
          />
        </form>
      )}
    </Formik>
  )
}

export default QueryForm
