import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { ROUTES } from 'routes'

import { useStyle } from './RunnerResult.style'

const RunnerResult = ({ runner }) => {
  const classes = useStyle()
  const fromLocal = moment(runner.eventFrom)
  const { position, circuitCategory, eventSlugUrl } = runner
  const { id: idCircuitCategory } = circuitCategory
  const firstPositions = position >= 1 && position <= 3

  const getMedalClass = (position) => {
    if (firstPositions) {
      if (position === 1) return classes.medalRibbon
      else return classes.medalRibbonGrayed
    }
  }

  const medal = getMedalClass(position) || null

  return (
    <div className={classes.resultMainContainer}>
      <div className={classes.position}>
        <Typography variant='h6' className={clsx(position === 1 && classes.positionRelevant)}>
          {runner.position}°
        </Typography>
      </div>
      {medal && <div className={clsx(classes.medalImage, medal)}></div>}
      <div className={classes.resultBody}>
        <Typography variant='h6' className={classes.title} color='primary'>
          {fromLocal.format('DD MMM YY').replace('.', '')}
        </Typography>
        <div>
          <Typography className={classes.eventName} color='primary' title={runner.eventName}>
            {runner.eventName}
          </Typography>
          <Typography color='primary'> {runner.from}</Typography>
        </div>
      </div>

      <div className={classes.buttonResult}>
        <Link
          to={`${ROUTES.EVENTS.PROFILE}/${eventSlugUrl}/${idCircuitCategory}`}
          className={classes.linkResult}>
          ver resultados
        </Link>
      </div>
    </div>
  )
}

export default RunnerResult
