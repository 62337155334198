import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './AccountSportmetricDialog.style'

const AccountSportmetricDialog = ({ open, onClose, setOpenSignInDialog, setOpenSignUpDialog }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      containerClassName={classes.container}
      contentClassName={classes.bodyContainer}
      onClose={onClose}
      title='CUENTA SPORTMETRIC'>
      <div className={classes.infoContainer}>
        <Typography className={classes.textInitial}>
          Para poder crear una organización es necesario tener cuenta Sportmetric con rol
          organizador.
        </Typography>
        <div className={classes.butonContainer}>
          <Typography>¿Sos usuario de Sportmetric?</Typography>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => {
              setOpenSignInDialog(true)
              onClose()
            }}>
            Iniciar sesión
          </Button>
        </div>
        <div className={classes.butonContainer}>
          <Typography>Para crear una cuenta</Typography>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              setOpenSignUpDialog(true)
              onClose()
            }}>
            Registrate
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default AccountSportmetricDialog
