import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '380px !important',
    [theme.breakpoints.up('md')]: {
      width: '700px !important',
      maxWidth: '700px !important'
    }
  },
  container: {
    width: '85%'
  },
  subTitle: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2)
    },
    '& strong': {
      color: theme.palette.primary.main
    }
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto',
    marginTop: theme.spacing(2),
    width: '60%',

    gap: theme.spacing()
  },
  button: {
    height: 40,
    width: 220
  }
}))
