import React, { useState } from 'react'
import { Media } from 'react-breakpoints'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'

import useStyles from './SectionBlock.style'

const SectionBlock = ({
  children,
  className,
  sectionClassName,
  title,
  body,
  hiddenBody = false,
  hiddenExpandedLink = false
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(true)
  const [isTextRevealed, setIsTextRevealed] = useState(!hiddenBody || (body && body.length < 200))

  const renderMini = () => (
    <div className={clsx(classes.sectionContainer, expanded && sectionClassName)}>
      <div className={classes.header}>
        <Typography variant='h3' className={className}>
          {title}
          {!expanded && (
            <IconButton
              onClick={() => setExpanded((state) => !state)}
              aria-expanded={expanded}
              style={{ flexShrink: 0 }}
              aria-label='show more'>
              <ExpandMoreIcon color='primary' />
            </IconButton>
          )}
        </Typography>

        {!hiddenExpandedLink && expanded && (
          <Typography
            className={classes.linkResult}
            color='primary'
            onClick={() => setExpanded((state) => !state)}>
            OCULTAR
          </Typography>
        )}
      </div>
      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <Typography variant='body1'>{body}</Typography>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        {children}
      </Collapse>
    </div>
  )
  const render = () => (
    <div className={clsx(classes.sectionContainer, sectionClassName)}>
      <Typography className={className} variant='h3' title={title}>
        {title}
      </Typography>
      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <Typography variant='body1'>{body}</Typography>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? renderMini() : render()
      }
    </Media>
  )
}

export default SectionBlock
