import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: 370
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  select: {
    width: '60%',
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  textField: {
    '& input': {
      height: 5
    }
  },
  button: {
    alignSelf: 'center',
    width: 220,
    height: 37,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  stateContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  item: {
    textTransform: 'uppercase',
    fontWeight: 500
  },
  inscriptionState: {
    display: 'flex',
    gap: theme.spacing(),
    fontWeight: 500,
    fontSize: '1rem'
  },
  stateMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),

    borderRadius: 10,
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.4),
    padding: theme.spacing(3, 5),
    '&.Accepted': {
      paddingLeft: '18%',
      [theme.breakpoints.up('md')]: {
        paddingLeft: 180
      }
    }
  },
  paymentMethodLink: {
    textDecoration: 'underline',
    fontSize: '.875rem',
    cursor: 'pointer',
    marginRight: theme.spacing()
  },
  uploadReceiptButton: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    maxWidth: 190,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },

  state: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center'
    }
  },
  saleOrderState: {
    fontWeight: 500
  },
  retryInscription: {
    alignSelf: 'center',
    marginTop: theme.spacing(2),
    width: 190,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  link: {
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  helperText: {
    fontWeight: 500
  }
}))
