import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './ConflictUpdateDistanceDialog.style'

const ConflictUpdateDistanceDialog = ({ open, onClose, onRemoveDistances }) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      title='NOTIFICACIÓN'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography color='primary' variant='h6' align='center' className={classes.text}>
        Tus distancias se encuentran ya asociadas con categorías, si aplicas este cambio se borrarán
        todas las distancias creadas.
      </Typography>
      <div className={classes.buttonContainer}>
        <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={onRemoveDistances}>
          Aplicar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default ConflictUpdateDistanceDialog
