import { makeStyles } from '@material-ui/core'
import notbookCronos from 'assets/img/landing-notbook-cronos.jpg'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      gap: 0,
      flexDirection: 'row'
    }
  },
  cronosPCImagen: {
    backgroundImage: `url(${notbookCronos})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    display: 'none',

    width: '100%',
    height: '20vw',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      marginTop: theme.spacing(16)
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
      justifyContent: 'center',
      backgroundPosition: 'center'
    },
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(14)
    }
  }
}))
