import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './SectionCalendarPlaceHolderLoader.style'

const SectionCalendarPlaceHolderLoader = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.sm ? (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <rect x='10' y='10' rx='3' ry='3' className={classes.subtitle} />
            <rect x='10' y='40' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='55' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='70' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='89' rx='3' ry='3' width='20%' height={6} />
            <rect x='200' y='89' rx='3' ry='3' width='30%' height={6} />
            <rect x='10' y='119' rx='3' ry='3' className={classes.subtitle} />
            <rect x='10' y='149' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='164' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='179' rx='3' ry='3' width='70%' height={6} />
            <rect x='10' y='198' rx='3' ry='3' width='20%' height={6} />
            <rect x='200' y='198' rx='3' ry='3' width='30%' height={6} />
          </ContentLoader>
        ) : (
          <ContentLoader
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect x='10' y='40' rx='3' ry='3' className={classes.date} />
            <rect x='130' y='40' rx='3' ry='3' className={classes.body} />
            <rect x='130' y='60' rx='3' ry='3' className={classes.body} />
            <rect x='650' y='50' rx='3' ry='3' width='10%' height={8} />

            <rect x='10' y='90' rx='3' ry='3' className={classes.date} />
            <rect x='130' y='90' rx='3' ry='3' className={classes.body} />
            <rect x='130' y='110' rx='3' ry='3' className={classes.body} />
            <rect x='650' y='100' rx='3' ry='3' width='10%' height={8} />

            <rect x='10' y='150' rx='3' ry='3' className={classes.date} />
            <rect x='130' y='150' rx='3' ry='3' className={classes.body} />
            <rect x='130' y='170' rx='3' ry='3' className={classes.body} />
            <rect x='650' y='160' rx='3' ry='3' width='10%' height={8} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default SectionCalendarPlaceHolderLoader
