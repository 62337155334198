import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '450px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      width: '60%'
    }
  },
  paymentsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  acceptButton: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  offLineTextLink: {
    fontWeight: 600,
    fontSize: '1rem',
    marginTop: theme.spacing(),
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  infoDialog: {
    display: 'flex',
    width: 400,
    marginBottom: 20
  },
  textInfoDialog: {
    fontWeight: 600,
    fontSize: 15
  }
}))
