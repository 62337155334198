import FileDownload from 'js-file-download'
import { EventsProxy, SaleOrdersProxy } from 'services'
import { NOT_FOUND_CODE } from 'utils/constants'

import * as types from './types'

// Action Creators
export const loadAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadSuccess = (event) => ({
  type: types.LOAD_SUCCESS,
  event
})

export const loadFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})
export const loadReset = () => ({
  type: types.EVENT_RESET
})
export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (event) => ({
  type: types.CREATE_SUCCESS,
  event
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const setImageAttempt = () => ({
  type: types.SET_IMAGE_ATTEMPT
})

export const setImageSuccess = (event) => ({
  type: types.SET_IMAGE_SUCCESS,
  event
})

export const setImageFailure = (error) => ({
  type: types.SET_IMAGE_FAILURE,
  error
})

export const searchAttempt = () => ({
  type: types.SEARCH_ATTEMPT
})

export const searchSuccess = (eventsPage) => ({
  type: types.SEARCH_SUCCESS,
  eventsPage
})

export const searchFailure = (error) => ({
  type: types.SEARCH_FAILURE,
  error
})

export const setSearchParams = (searchParams) => ({
  type: types.SET_SEARCH_PARAMS,
  searchParams
})

export const resetSearch = () => ({
  type: types.RESET_SEARCH
})

export const searchMetadataAttempt = () => ({
  type: types.SEARCH_METADATA_ATTEMPT
})

export const searchMetadataSuccess = (searchMetadata) => ({
  type: types.SEARCH_METADATA_SUCCESS,
  searchMetadata
})

export const searchMetadataFailure = (error) => ({
  type: types.SEARCH_METADATA_FAILURE,
  error
})

export const loadResultsAttempt = () => ({
  type: types.LOAD_RESULTS_ATTEMPT
})

export const loadResultsSuccess = (eventResults) => ({
  type: types.LOAD_RESULTS_SUCCESS,
  eventResults
})

export const loadResultsFailure = (error) => ({
  type: types.LOAD_RESULTS_FAILURE,
  error
})

export const uploadResultsAttempt = () => ({
  type: types.UPLOAD_RESULTS_ATTEMPT
})

export const uploadResultsSuccess = () => ({
  type: types.UPLOAD_RESULTS_SUCCESS
})

export const uploadResultsFailure = (error) => ({
  type: types.UPLOAD_RESULTS_FAILURE,
  error
})

export const deleteResultsAttempt = () => ({
  type: types.DELETE_RESULTS_ATTEMPT
})

export const deleteResultsSuccess = () => ({
  type: types.DELETE_RESULTS_SUCCESS
})

export const deleteResultsFailure = (error) => ({
  type: types.DELETE_RESULTS_FAILURE,
  error
})

export const suspendAttempt = () => ({
  type: types.SUSPEND_ATTEMPT
})

export const suspendSuccess = (event) => ({
  type: types.SUSPEND_SUCCESS,
  event
})

export const suspendFailure = (error) => ({
  type: types.SUSPEND_FAILURE,
  error
})

export const resetSearchParams = () => ({
  type: types.RESET_SEARCH_PARAMS
})

export const resetValue = (value) => ({
  type: types.RESET_VALUE,
  value
})

export const downloadResultsTemplateAttempt = () => ({
  type: types.DOWNLOAD_RESULTS_TEMPLATE_ATTEMPT
})

export const downloadResultsTemplateSuccess = () => ({
  type: types.DOWNLOAD_RESULTS_TEMPLATE_SUCCESS
})

export const downloadResultsTemplateFailure = (error) => ({
  type: types.DOWNLOAD_RESULTS_TEMPLATE_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const updateSuccess = (event) => ({
  type: types.UPDATE_SUCCESS,
  event
})

export const reactAttempt = () => ({
  type: types.REACT_ATTEMPT
})

export const reactFailure = (error) => ({
  type: types.REACT_FAILURE,
  error
})

export const reactSuccess = (event) => ({
  type: types.REACT_SUCCESS,
  event
})

export const deleteAttempt = () => ({
  type: types.DELETE_ATTEMPT
})

export const deleteFailure = (error) => ({
  type: types.DELETE_FAILURE,
  error
})

export const deleteSuccess = () => ({
  type: types.DELETE_SUCCESS
})

export const updateCalendarAttempt = () => ({
  type: types.UPDATE_CALENDAR_ATTEMPT
})

export const updateCalendarSuccess = (event) => ({
  type: types.UPDATE_CALENDAR_SUCCESS,
  event
})

export const updateCalendarFailure = (error) => ({
  type: types.UPDATE_CALENDAR_FAILURE,
  error
})

export const togglePublishedAttempt = () => ({
  type: types.TOGGLE_PUBLISHED_ATTEMPT
})

export const togglePublishedSuccess = (event) => ({
  type: types.TOGGLE_PUBLISHED_SUCCESS,
  event
})

export const togglePublishedFailure = (error) => ({
  type: types.TOGGLE_PUBLISHED_FAILURE,
  error
})
export const deleteImageAttempt = () => ({
  type: types.DELETE_IMAGE_ATTEMPT
})
export const deleteImageSuccess = (event) => ({
  type: types.DELETE_IMAGE_SUCCESS,
  event
})
export const deleteImageFailure = (error) => ({
  type: types.DELETE_IMAGE_FAILURE,
  error
})

export const downloadResultsSheetAttempt = () => ({
  type: types.DOWNLOAD_RESULTS_SHEET_ATTEMPT
})
export const downloadResultsSheetSuccess = () => ({
  type: types.DOWNLOAD_RESULTS_SHEET_SUCCESS
})
export const downloadResultsSheetFailure = (error) => ({
  type: types.DOWNLOAD_RESULTS_SHEET_FAILURE,
  error
})

export const downloadInscriptionsInsuranceSheetAttempt = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_ATTEMPT
})
export const downloadInscriptionsInsuranceSheetSuccess = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_SUCCESS
})
export const downloadInscriptionsInsuranceSheetFailure = (error) => ({
  type: types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_FAILURE,
  error
})

export const downloadInscriptionsRunnersSheetAttempt = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_ATTEMPT
})
export const downloadInscriptionsRunnersSheetSuccess = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_SUCCESS
})
export const downloadInscriptionsRunnersSheetFailure = (error) => ({
  type: types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_FAILURE,
  error
})

export const downloadInscriptionsAcceptedSheetAttempt = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_ATTEMPT
})
export const downloadInscriptionsAcceptedSheetSuccess = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_SUCCESS
})
export const downloadInscriptionsAcceptedSheetFailure = (error) => ({
  type: types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_FAILURE,
  error
})

export const downloadResultsReportAttempt = () => ({
  type: types.DOWNLOAD_RESULTS_REPORT_ATTEMPT
})
export const downloadResultsReportSuccess = () => ({
  type: types.DOWNLOAD_RESULTS_REPORT_SUCCESS
})
export const downloadResultsReportFailure = (error) => ({
  type: types.DOWNLOAD_RESULTS_REPORT_FAILURE,
  error
})

export const loadLastResultForMeAttempt = () => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_ATTEMPT
})
export const loadLastResultForMeSuccess = (lastResultEvents) => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_SUCCESS,
  lastResultEvents
})
export const loadLastResultForMeFailure = (error) => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_FAILURE,
  error
})
export const resetLastResultForMe = () => ({
  type: types.RESET_LAST_RESULT_FOR_ME
})
export const loadUpcomingForMeAttempt = () => ({
  type: types.LOAD_UPCOMING_FOR_ME_ATTEMPT
})
export const loadUpcomingForMeSuccess = (upcomingEvents) => ({
  type: types.LOAD_UPCOMING_FOR_ME_SUCCESS,
  upcomingEvents
})
export const loadUpcomingForMeFailure = (error) => ({
  type: types.LOAD_UPCOMING_FOR_ME_FAILURE,
  error
})
export const resetUpcomingForMe = () => ({
  type: types.RESET_UPCOMING_FOR_ME
})
export const loadUpcomingAllAttempt = () => ({
  type: types.LOAD_UPCOMING_ALL_ATTEMPT
})
export const loadUpcomingAllSuccess = (upcomingEventsAll) => ({
  type: types.LOAD_UPCOMING_ALL_SUCCESS,
  upcomingEventsAll
})
export const loadUpcomingAllFailure = (error) => ({
  type: types.LOAD_UPCOMING_ALL_FAILURE,
  error
})
export const resetUpcomingAll = () => ({
  type: types.RESET_UPCOMING_ALL
})
export const loadLastResultAllAttempt = () => ({
  type: types.LOAD_LAST_RESULT_ALL_ATTEMPT
})
export const loadLastResultAllSuccess = (lastResultEventsAll) => ({
  type: types.LOAD_LAST_RESULT_ALL_SUCCESS,
  lastResultEventsAll
})
export const loadLastResultAllFailure = (error) => ({
  type: types.LOAD_LAST_RESULT_ALL_FAILURE,
  error
})
export const resetLastResultAll = () => ({
  type: types.RESET_LAST_RESULT_ALL
})
export const loadLastResultForMeAllAttempt = () => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_ALL_ATTEMPT
})
export const loadLastResultForMeAllSuccess = (lastResultEventsAll) => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_ALL_SUCCESS,
  lastResultEventsAll
})
export const loadLastResultForMeAllFailure = (error) => ({
  type: types.LOAD_LAST_RESULT_FOR_ME_ALL_FAILURE,
  error
})
export const resetLastResultForMeAll = () => ({
  type: types.RESET_LAST_RESULT_FOR_ME_ALL
})
export const loadUpcomingForMeAllAttempt = () => ({
  type: types.LOAD_UPCOMING_FOR_ME_ALL_ATTEMPT
})
export const loadUpcomingForMeAllSuccess = (upcomingEventsAll) => ({
  type: types.LOAD_UPCOMING_FOR_ME_ALL_SUCCESS,
  upcomingEventsAll
})
export const loadUpcomingForMeAllFailure = (error) => ({
  type: types.LOAD_UPCOMING_FOR_ME_ALL_FAILURE,
  error
})
export const resetUpcomingForMeAll = () => ({
  type: types.RESET_UPCOMING_FOR_ME_ALL
})
export const loadCreatedByMeAttempt = () => ({
  type: types.LOAD_CREATED_BY_ME_ATTEMPT
})
export const loadCreatedByMeSuccess = (createdEvents) => ({
  type: types.LOAD_CREATED_BY_ME_SUCCESS,
  createdEvents
})
export const loadCreatedByMeFailure = (error) => ({
  type: types.LOAD_CREATED_BY_ME_FAILURE,
  error
})
export const resetCreatedByMe = () => ({
  type: types.RESET_CREATED_BY_ME
})
export const loadCreatedByMeAllAttempt = () => ({
  type: types.LOAD_CREATED_BY_ME_ALL_ATTEMPT
})
export const loadCreatedByMeAllSuccess = (createdEventsAll) => ({
  type: types.LOAD_CREATED_BY_ME_ALL_SUCCESS,
  createdEventsAll
})
export const loadCreatedByMeAllFailure = (error) => ({
  type: types.LOAD_CREATED_BY_ME_ALL_FAILURE,
  error
})
export const resetCreatedByMeAll = () => ({
  type: types.RESET_CREATED_BY_ME_ALL
})
export const loadActiveInscriptionFormAttempt = () => ({
  type: types.LOAD_ACTIVE_INSCRIPTION_FORM_ATTEMPT
})
export const loadActiveInscriptionFormSuccess = (activeInscriptionForm) => ({
  type: types.LOAD_ACTIVE_INSCRIPTION_FORM_SUCCESS,
  activeInscriptionForm
})
export const loadActiveInscriptionFormFailure = (error) => ({
  type: types.LOAD_ACTIVE_INSCRIPTION_FORM_FAILURE,
  error
})

export const createInscriptionFormsAttempt = () => ({
  type: types.CREATE_INSCRIPTION_FORMS_ATTEMPT
})
export const createInscriptionFormsSuccess = (inscriptionForm) => ({
  type: types.CREATE_INSCRIPTION_FORMS_SUCCESS,
  inscriptionForm
})
export const createInscriptionFormsFailure = (error) => ({
  type: types.CREATE_INSCRIPTION_FORMS_FAILURE,
  error
})
export const updateInscriptionFormsAttempt = () => ({
  type: types.UPDATE_INSCRIPTION_FORMS_ATTEMPT
})
export const updateInscriptionFormsSuccess = (inscriptionForm) => ({
  type: types.UPDATE_INSCRIPTION_FORMS_SUCCESS,
  inscriptionForm
})
export const updateInscriptionFormsFailure = (error) => ({
  type: types.UPDATE_INSCRIPTION_FORMS_FAILURE,
  error
})
export const deleteInscriptionFormsAttempt = () => ({
  type: types.DELETE_INSCRIPTION_FORMS_ATTEMPT
})
export const deleteInscriptionFormsSuccess = () => ({
  type: types.DELETE_INSCRIPTION_FORMS_SUCCESS
})
export const deleteInscriptionFormsFailure = (error) => ({
  type: types.DELETE_INSCRIPTION_FORMS_FAILURE,
  error
})
export const resetInscriptionForms = () => ({
  type: types.RESET_INSCRIPTION_FORMS
})
export const eventInscriptionAttempt = () => ({
  type: types.EVENT_INSCRIPTION_ATTEMPT
})
export const eventInscriptionSuccess = (inscription) => ({
  type: types.EVENT_INSCRIPTION_SUCCESS,
  inscription
})
export const eventInscriptionFailure = (error) => ({
  type: types.EVENT_INSCRIPTION_FAILURE,
  error
})
export const listInscriptionAttempt = () => ({
  type: types.LOAD_LIST_INSCRIPTION_ATTEMPT
})
export const listInscriptionSuccess = (listInscriptionPage) => ({
  type: types.LOAD_LIST_INSCRIPTION_SUCCESS,
  listInscriptionPage
})
export const listInscriptionFailure = (error) => ({
  type: types.LOAD_LIST_INSCRIPTION_FAILURE,
  error
})
export const resetListInscription = () => ({
  type: types.RESET_LIST_INSCRIPTION
})
export const deleteInscriptionRegisteredListAttempt = () => ({
  type: types.DELETE_LIST_INSCRIPTION_ATTEMPT
})
export const deleteInscriptionRegisteredListSuccess = () => ({
  type: types.DELETE_LIST_INSCRIPTION_SUCCESS
})
export const deleteInscriptionRegisteredListFailure = (error) => ({
  type: types.DELETE_LIST_INSCRIPTION_FAILURE,
  error
})
export const updateEventInscriptionAttempt = () => ({
  type: types.UPDATE_EVENT_INSCRIPTION_ATTEMPT
})
export const updateEventInscriptionSuccess = (inscription) => ({
  type: types.UPDATE_EVENT_INSCRIPTION_SUCCESS,
  inscription
})
export const updateEventInscriptionFailure = (error) => ({
  type: types.UPDATE_EVENT_INSCRIPTION_FAILURE,
  error
})
export const listInscriptionSearchMetadataAttempt = () => ({
  type: types.LIST_INSCRIPTION_SEARCH_METADATA_ATTEMPT
})
export const listInscriptionSearchMetadataSuccess = (listInscriptionPage) => ({
  type: types.LIST_INSCRIPTION_SEARCH_METADATA_SUCCESS,
  listInscriptionPage
})
export const listInscriptionSearchMetadataFailure = (error) => ({
  type: types.LIST_INSCRIPTION_SEARCH_METADATA_FAILURE,
  error
})

export const uploadRulesDocumentAttempt = () => ({
  type: types.UPLOAD_RULES_DOCUMENT_ATTEMPT
})
export const uploadRulesDocumentSuccess = () => ({
  type: types.UPLOAD_RULES_DOCUMENT_SUCCESS
})
export const uploadRulesDocumentFailure = (error) => ({
  type: types.UPLOAD_RULES_DOCUMENT_FAILURE,
  error
})

export const downloadRulesDocumentAttempt = () => ({
  type: types.DOWNLOAD_RULES_DOCUMENT_ATTEMPT
})
export const downloadRulesDocumentSuccess = () => ({
  type: types.DOWNLOAD_RULES_DOCUMENT_SUCCESS
})
export const downloadRulesDocumentFailure = (error) => ({
  type: types.DOWNLOAD_RULES_DOCUMENT_FAILURE,
  error
})

export const deleteRulesDocumentAttempt = () => ({
  type: types.DELETE_RULES_DOCUMENT_ATTEMPT
})
export const deleteRulesDocumentSuccess = () => ({
  type: types.DELETE_RULES_DOCUMENT_SUCCESS
})
export const deleteRulesDocumentFailure = (error) => ({
  type: types.DELETE_RULES_DOCUMENT_FAILURE,
  error
})

export const loadEventInscriptionsForCurrentUserAttempt = () => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_ATTEMPT
})
export const loadEventInscriptionsForCurrentUserSuccess = (eventInscriptionsForCurrentUser) => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_SUCCESS,
  eventInscriptionsForCurrentUser
})
export const loadEventInscriptionsForCurrentUserFailure = (error) => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_FAILURE,
  error
})

export const resetEventInscriptionsForCurrentUser = () => ({
  type: types.RESET_EVENT_INSCRIPTIONS_FOR_CURRENT_USER
})

export const loadEventInscriptionsAllForCurrentUserAttempt = () => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_ATTEMPT
})
export const loadEventInscriptionsAllForCurrentUserSuccess = (eventInscriptionsAll) => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_SUCCESS,
  eventInscriptionsAll
})
export const loadEventInscriptionsAllForCurrentUserFailure = (error) => ({
  type: types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_FAILURE,
  error
})

export const resetEventInscriptionsAllForCurrentUser = () => ({
  type: types.RESET_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER
})

export const downloadInscriptionsSheetAttempt = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_SHEET_ATTEMPT
})
export const downloadInscriptionsSheetSuccess = () => ({
  type: types.DOWNLOAD_INSCRIPTIONS_SHEET_SUCCESS
})
export const downloadInscriptionsSheetFailure = (error) => ({
  type: types.DOWNLOAD_INSCRIPTIONS_SHEET_FAILURE,
  error
})

export const acceptOrganizationAttempt = () => ({
  type: types.ACCEPT_ORGANIZATION_ATTEMPT
})
export const acceptOrganizationSuccess = () => ({
  type: types.ACCEPT_ORGANIZATION_SUCCESS
})
export const acceptOrganizationFailure = (error) => ({
  type: types.ACCEPT_ORGANIZATION_FAILURE,
  error
})

export const rejectOrganizationAttempt = () => ({
  type: types.REJECT_ORGANIZATION_ATTEMPT
})
export const rejectOrganizationSuccess = () => ({
  type: types.REJECT_ORGANIZATION_SUCCESS
})
export const rejectOrganizationFailure = (error) => ({
  type: types.REJECT_ORGANIZATION_FAILURE,
  error
})

export const loadInscriptionsFormsCloneSuggestionsAttempt = () => ({
  type: types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_ATTEMPT
})
export const loadInscriptionsFormsCloneSuggestionsSuccess = (inscriptionFormsCloneSuggestions) => ({
  type: types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_SUCCESS,
  inscriptionFormsCloneSuggestions
})
export const loadInscriptionsFormsCloneSuggestionsFailure = (error) => ({
  type: types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_FAILURE,
  error
})
export const resetInscriptionFormsCloneSuggestions = () => ({
  type: types.RESET_INSCRIPTION_FORMS_CLONE_SUGGESTIONS
})

export const loadCategoriesCloneSuggestionsAttempt = () => ({
  type: types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_ATTEMPT
})
export const loadCategoriesCloneSuggestionsSuccess = (categoriesCloneSuggestions) => ({
  type: types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_SUCCESS,
  categoriesCloneSuggestions
})
export const loadCategoriesCloneSuggestionsFailure = (error) => ({
  type: types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_FAILURE,
  error
})
export const resetCategoriesCloneSuggestions = () => ({
  type: types.RESET_CATERORIES_CLONE_SUGGESTIONS
})

export const loadCategoriesAttempt = () => ({
  type: types.LOAD_CATEGORIES_ATTEMPT
})

export const loadCategoriesSuccess = (categories) => ({
  type: types.LOAD_CATEGORIES_SUCCESS,
  categories
})

export const loadCategoriesFailure = (error) => ({
  type: types.LOAD_CATEGORIES_FAILURE,
  error
})

export const createCategoryAttempt = () => ({
  type: types.CREATE_CATEGORY_ATTEMPT
})

export const createCategorySuccess = (category) => ({
  type: types.CREATE_CATEGORY_SUCCESS,
  category
})

export const createCategoryFailure = (error) => ({
  type: types.CREATE_CATEGORY_FAILURE,
  error
})

export const updateCategoryAttempt = () => ({
  type: types.UPDATE_CATEGORY_ATTEMPT
})

export const updateCategorySuccess = (category) => ({
  type: types.UPDATE_CATEGORY_SUCCESS,
  category
})

export const updateCategoryFailure = (error) => ({
  type: types.UPDATE_CATEGORY_FAILURE,
  error
})

export const deleteCategoryAttempt = () => ({
  type: types.DELETE_CATEGORY_ATTEMPT
})

export const deleteCategorySuccess = () => ({
  type: types.DELETE_CATEGORY_SUCCESS
})

export const deleteCategoryFailure = (error) => ({
  type: types.DELETE_CATEGORY_FAILURE,
  error
})

export const loadDistancesAttempt = () => ({
  type: types.LOAD_DISTANCES_ATTEMPT
})

export const loadDistancesSuccess = (distances) => ({
  type: types.LOAD_DISTANCES_SUCCESS,
  distances
})

export const loadDistancesFailure = (error) => ({
  type: types.LOAD_DISTANCES_FAILURE,
  error
})

export const createDistanceAttempt = () => ({
  type: types.CREATE_DISTANCE_ATTEMPT
})

export const createDistanceSuccess = (distance) => ({
  type: types.CREATE_DISTANCE_SUCCESS,
  distance
})

export const createDistanceFailure = (error) => ({
  type: types.CREATE_DISTANCE_FAILURE,
  error
})

export const updateDistanceAttempt = () => ({
  type: types.UPDATE_DISTANCE_ATTEMPT
})

export const updateDistanceSuccess = (distance) => ({
  type: types.UPDATE_DISTANCE_SUCCESS,
  distance
})

export const updateDistanceFailure = (error) => ({
  type: types.UPDATE_DISTANCE_FAILURE,
  error
})

export const deleteDistanceAttempt = () => ({
  type: types.DELETE_DISTANCE_ATTEMPT
})

export const deleteDistanceSuccess = () => ({
  type: types.DELETE_DISTANCE_SUCCESS
})

export const deleteDistanceFailure = (error) => ({
  type: types.DELETE_DISTANCE_FAILURE,
  error
})

export const loadMercadoPagoAuthorizationAttempt = () => ({
  type: types.LOAD_MERCADO_PAGO_AUTHORIZATION_ATTEMPT
})
export const loadMercadoPagoAuthorizationSuccess = (mercadoPagoAuthorization) => ({
  type: types.LOAD_MERCADO_PAGO_AUTHORIZATION_SUCCESS,
  mercadoPagoAuthorization
})
export const loadMercadoPagoAuthorizationFailure = (error) => ({
  type: types.LOAD_MERCADO_PAGO_AUTHORIZATION_FAILURE,
  error
})

export const sendMercadoPagoAuthorizationAttempt = () => ({
  type: types.SEND_MERCADO_PAGO_AUTHORIZATION_ATTEMPT
})
export const sendMercadoPagoAuthorizationSucess = () => ({
  type: types.SEND_MERCADO_PAGO_AUTHORIZATION_SUCCESS
})
export const sendMercadoPagoAuthorizationFailure = (error) => ({
  type: types.SEND_MERCADO_PAGO_AUTHORIZATION_FAILURE,
  error
})

export const deleteMercadoPagoAuthorizationAttempt = () => ({
  type: types.DELETE_MERCADO_PAGO_AUTHORIZATION_ATTEMPT
})
export const deleteMercadoPagoAuthorizationSuccess = () => ({
  type: types.DELETE_MERCADO_PAGO_AUTHORIZATION_SUCCESS
})
export const deleteMercadoPagoAuthorizationFailure = (error) => ({
  type: types.DELETE_MERCADO_PAGO_AUTHORIZATION_FAILURE,
  error
})

export const loadEventBankAccountsAttempt = () => ({
  type: types.LOAD_EVENT_BANK_ACCOUNTS_ATTEMPT
})
export const loadEventBankAccountsSuccess = (data) => ({
  type: types.LOAD_EVENT_BANK_ACCOUNTS_SUCCESS,
  data
})
export const loadEventBankAccountsFailure = (error) => ({
  type: types.LOAD_EVENT_BANK_ACCOUNTS_FAILURE,
  error
})

export const createEventBankAccountAttempt = () => ({
  type: types.CREATE_EVENT_BANK_ACCOUNT_ATTEMPT
})
export const createEventBankAccountSuccess = (paymentLocation) => ({
  type: types.CREATE_EVENT_BANK_ACCOUNT_SUCCESS,
  paymentLocation
})
export const createEventBankAccountFailure = (error) => ({
  type: types.CREATE_EVENT_BANK_ACCOUNT_FAILURE,
  error
})

export const updateEventBankAccountAttempt = () => ({
  type: types.UPDATE_EVENT_BANK_ACCOUNT_ATTEMPT
})
export const updateEventBankAccountSuccess = (paymentLocation) => ({
  type: types.UPDATE_EVENT_BANK_ACCOUNT_SUCCESS,
  paymentLocation
})
export const updateEventBankAccountFailure = (error) => ({
  type: types.UPDATE_EVENT_BANK_ACCOUNT_FAILURE,
  error
})

export const deleteEventBankAccountAttempt = () => ({
  type: types.DELETE_EVENT_BANK_ACCOUNT_ATTEMPT
})
export const deleteEventBankAccountSuccess = (bankAccount) => ({
  type: types.DELETE_EVENT_BANK_ACCOUNT_SUCCESS,
  bankAccount
})
export const deleteEventBankAccountFailure = (error) => ({
  type: types.DELETE_EVENT_BANK_ACCOUNT_FAILURE,
  error
})

export const loadPaymentLocationsAttempt = () => ({
  type: types.LOAD_PAYMENT_LOCATIONS_ATTEMPT
})
export const loadPaymentLocationsSuccess = (paymentLocations) => ({
  type: types.LOAD_PAYMENT_LOCATIONS_SUCCESS,
  paymentLocations
})
export const loadPaymentLocationsFailure = (error) => ({
  type: types.LOAD_PAYMENT_LOCATIONS_FAILURE,
  error
})

export const createPaymentLocationAttempt = () => ({
  type: types.CREATE_PAYMENT_LOCATION_ATTEMPT
})
export const createPaymentLocationSuccess = (paymentLocation) => ({
  type: types.CREATE_PAYMENT_LOCATION_SUCCESS,
  paymentLocation
})
export const createPaymentLocationFailure = (error) => ({
  type: types.CREATE_PAYMENT_LOCATION_FAILURE,
  error
})

export const updatePaymentLocationAttempt = () => ({
  type: types.UPDATE_PAYMENT_LOCATION_ATTEMPT
})
export const updatePaymentLocationSuccess = (paymentLocation) => ({
  type: types.UPDATE_PAYMENT_LOCATION_SUCCESS,
  paymentLocation
})
export const updatePaymentLocationFailure = (error) => ({
  type: types.UPDATE_PAYMENT_LOCATION_FAILURE,
  error
})

export const deletePaymentLocationAttempt = () => ({
  type: types.DELETE_PAYMENT_LOCATION_ATTEMPT
})
export const deletePaymentLocationSuccess = (paymentLocation) => ({
  type: types.DELETE_PAYMENT_LOCATION_SUCCESS,
  paymentLocation
})
export const deletePaymentLocationFailure = (error) => ({
  type: types.DELETE_PAYMENT_LOCATION_FAILURE,
  error
})

export const loadBanksAttempt = () => ({
  type: types.LOAD_BANKS_ATTEMPT
})
export const loadBanksSuccess = (data) => ({
  type: types.LOAD_BANKS_SUCCESS,
  data
})
export const loadBanksFailure = (error) => ({
  type: types.LOAD_BANKS_FAILURE,
  error
})

export const loadEventInscriptionAttempt = () => ({
  type: types.LOAD_EVENT_INSCRIPTION_ATTEMPT
})
export const loadEventInscriptionSuccess = (data) => ({
  type: types.LOAD_EVENT_INSCRIPTION_SUCCESS,
  data
})
export const loadEventInscriptionFailure = (error) => ({
  type: types.LOAD_EVENT_INSCRIPTION_FAILURE,
  error
})

export const setPaymentParams = (paymentParams) => ({
  type: types.SET_PAYMENT_PARAMS,
  paymentParams
})

export const loadActivePaymentLocationsAttempt = () => ({
  type: types.LOAD_ACTIVE_PAYMENT_LOCATIONS_ATTEMPT
})

export const loadActivePaymentLocationsSuccess = (activePaymentLocations) => ({
  type: types.LOAD_ACTIVE_PAYMENT_LOCATIONS_SUCCESS,
  activePaymentLocations
})

export const loadActivePaymentLocationsFailure = (error) => ({
  type: types.LOAD_ACTIVE_PAYMENT_LOCATIONS_FAILURE,
  error
})

export const loadTicketTypeFeesAttempt = () => ({
  type: types.LOAD_TICKET_TYPE_FEES_ATTEMPT
})
export const loadTicketTypeFeesSuccess = (data) => ({
  type: types.LOAD_TICKET_TYPE_FEES_SUCCESS,
  data
})
export const loadTicketTypeFeesFailure = (error) => ({
  type: types.LOAD_TICKET_TYPE_FEES_FAILURE,
  error
})

export const loadActiveBankAccountsAttempt = () => ({
  type: types.LOAD_ACTIVE_BANK_ACCOUNTS_ATTEMPT
})
export const loadActiveBankAccountsSuccess = (activeBankAccounts) => ({
  type: types.LOAD_ACTIVE_BANK_ACCOUNTS_SUCCESS,
  activeBankAccounts
})
export const loadActiveBankAccountsFailure = (error) => ({
  type: types.LOAD_ACTIVE_BANK_ACCOUNTS_FAILURE,
  error
})

export const loadEventInscriptionByIdNumberAndCountryAttempt = () => ({
  type: types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_ATTEMPT
})
export const loadEventInscriptionByIdNumberAndCountrySuccess = (eventInscription) => ({
  type: types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_SUCCESS,
  eventInscription
})
export const loadEventInscriptionByIdNumberAndCountryFailure = (error) => ({
  type: types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_FAILURE,
  error
})

export const publicUpdateEventInscriptionAttempt = () => ({
  type: types.PUBLIC_UPDATE_EVENT_INSCRIPTION_ATTEMPT
})
export const publicUpdateEventInscriptionSuccess = (eventInscription) => ({
  type: types.PUBLIC_UPDATE_EVENT_INSCRIPTION_SUCCESS,
  eventInscription
})
export const publicUpdateEventInscriptionFailure = (error) => ({
  type: types.PUBLIC_UPDATE_EVENT_INSCRIPTION_FAILURE,
  error
})

export const loadCancelledEventInscriptionAttempt = () => ({
  type: types.LOAD_CANCELLED_EVENT_INSCRIPTION_ATTEMPT
})
export const loadCancelledEventInscriptionSuccess = (eventInscription) => ({
  type: types.LOAD_CANCELLED_EVENT_INSCRIPTION_SUCCESS,
  eventInscription
})
export const loadCancelledEventInscriptionFailure = (error) => ({
  type: types.LOAD_CANCELLED_EVENT_INSCRIPTION_FAILURE,
  error
})

export const deleteSaleOrderPaymentAttempt = () => ({
  type: types.DELETE_SALE_ORDER_PAYMENT_ATTEMPT
})
export const deleteSaleOrderPaymentSuccess = (payment) => ({
  type: types.DELETE_SALE_ORDER_PAYMENT_SUCCESS,
  payment
})
export const deleteSaleOrderPaymentFailure = (error) => ({
  type: types.DELETE_SALE_ORDER_PAYMENT_FAILURE,
  error
})

export const updateSaleOrderPaymentAttempt = () => ({
  type: types.UPDATE_SALE_ORDER_PAYMENT_ATTEMPT
})
export const updateSaleOrderPaymentSuccess = (payment) => ({
  type: types.UPDATE_SALE_ORDER_PAYMENT_SUCCESS,
  payment
})
export const updateSaleOrderPaymentFailure = (error) => ({
  type: types.UPDATE_SALE_ORDER_PAYMENT_FAILURE,
  error
})

export const createEventDiscountCodeAttempt = () => ({
  type: types.CREATE_EVENT_DISCOUNT_CODE_ATTEMPT
})
export const createEventDiscountCodeSuccess = (discountCode) => ({
  type: types.CREATE_EVENT_DISCOUNT_CODE_SUCCESS,
  discountCode
})
export const createEventDiscountCodeFailure = (error) => ({
  type: types.CREATE_EVENT_DISCOUNT_CODE_FAILURE,
  error
})

export const updateEventDiscountCodeAttempt = () => ({
  type: types.UPDATE_EVENT_DISCOUNT_CODE_ATTEMPT
})
export const updateEventDiscountCodeSuccess = (discountCode) => ({
  type: types.UPDATE_EVENT_DISCOUNT_CODE_SUCCESS,
  discountCode
})
export const updateEventDiscountCodeFailure = (error) => ({
  type: types.UPDATE_EVENT_DISCOUNT_CODE_FAILURE,
  error
})

export const deleteEventDiscountCodeAttempt = () => ({
  type: types.DELETE_EVENT_DISCOUNT_CODE_ATTEMPT
})
export const deleteEventDiscountCodeSuccess = (discountCode) => ({
  type: types.DELETE_EVENT_DISCOUNT_CODE_SUCCESS,
  discountCode
})
export const deleteEventDiscountCodeFailure = (error) => ({
  type: types.DELETE_EVENT_DISCOUNT_CODE_FAILURE,
  error
})

export const loadEventDiscountCodesAttempt = () => ({
  type: types.LOAD_EVENT_DISCOUNT_CODES_ATTEMPT
})
export const loadEventDiscountCodesSuccess = (eventDiscountCodes) => ({
  type: types.LOAD_EVENT_DISCOUNT_CODES_SUCCESS,
  eventDiscountCodes
})
export const loadEventDiscountCodesFailure = (error) => ({
  type: types.LOAD_EVENT_DISCOUNT_CODES_FAILURE,
  error
})

export const loadEventResultsReportUrlAttempt = () => ({
  type: types.LOAD_EVENT_RESULTS_REPORT_URL_ATTEMPT
})
export const loadEventResultsReportUrlSuccess = (eventResultsReportUrl) => ({
  type: types.LOAD_EVENT_RESULTS_REPORT_URL_SUCCESS,
  eventResultsReportUrl
})
export const loadEventResultsReportUrlFailure = (error) => ({
  type: types.LOAD_EVENT_RESULTS_REPORT_URL_FAILURE,
  error
})

export const downloadEventCategoriesAttempt = () => ({
  type: types.DOWNLOAD_EVENT_CATEGORIES_ATTEMPT
})
export const downloadEventCategoriesSuccess = (data) => ({
  type: types.DOWNLOAD_EVENT_CATEGORIES_SUCCESS,
  data
})
export const downloadEventCategoriesFailure = (error) => ({
  type: types.DOWNLOAD_EVENT_CATEGORIES_FAILURE,
  error
})

// Thunks
export const load = (id) => async (dispatch) => {
  try {
    dispatch(loadAttempt())

    const proxy = new EventsProxy()
    const event = await proxy.get(id)
    dispatch(loadSuccess(event))
    return true
  } catch (error) {
    dispatch(loadFailure(error))
    return false
  }
}

export const create = (eventData) => async (dispatch) => {
  try {
    dispatch(createAttempt())
    const proxy = new EventsProxy()
    const event = await proxy.post(eventData)

    dispatch(createSuccess(event))
    return event
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const setImage = (id, file) => async (dispatch) => {
  try {
    dispatch(setImageAttempt())

    const proxy = new EventsProxy()
    const event = await proxy.putImage(id, file)
    dispatch(setImageSuccess(event))
    return true
  } catch (error) {
    dispatch(setImageFailure(error))
    return false
  }
}

export const search =
  (searchParams, pageSize = 12, orderBy = 'id', order = 'desc') =>
  async (dispatch, getState) => {
    try {
      const { page } = searchParams
      const { searchEventsPageNumber } = getState().events
      dispatch(setSearchParams(searchParams))
      if (page !== 1 && page === searchEventsPageNumber) return true

      dispatch(searchAttempt())
      const proxy = new EventsProxy()
      const eventsPage = await proxy.getSearch(searchParams, page, pageSize, orderBy, order)

      if (page === 1) {
        dispatch(resetSearch())
      }
      dispatch(searchSuccess(eventsPage))
      return true
    } catch (error) {
      dispatch(searchFailure(error))
      return false
    }
  }

export const loadSearchMetadata = (searchParams) => async (dispatch) => {
  try {
    dispatch(searchMetadataAttempt())
    const proxy = new EventsProxy()
    const searchMetadata = await proxy.getSearchMetadata(searchParams)
    dispatch(searchMetadataSuccess(searchMetadata))
  } catch (error) {
    dispatch(searchMetadataFailure(error))
  }
}

export const loadResults = (id) => async (dispatch) => {
  try {
    dispatch(loadResultsAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getResults(id)
    dispatch(loadResultsSuccess(data))
  } catch (error) {
    dispatch(loadResultsFailure(error))
  }
}

export const uploadResults = (id, file) => async (dispatch) => {
  try {
    dispatch(uploadResultsAttempt())
    const proxy = new EventsProxy()
    await proxy.putImportResults(id, file)
    dispatch(uploadResultsSuccess())
  } catch (error) {
    dispatch(uploadResultsFailure(error))
  }
}

export const deleteResults = (id) => async (dispatch) => {
  try {
    dispatch(deleteResultsAttempt())
    const proxy = new EventsProxy()
    await proxy.deleteResults(id)
    dispatch(deleteResultsSuccess())
  } catch (error) {
    dispatch(deleteResultsFailure(error))
  }
}

export const suspend = (event, suspensionReason) => async (dispatch) => {
  try {
    const { id, isSuspended } = event
    dispatch(suspendAttempt())
    const proxy = new EventsProxy()

    if (!isSuspended) {
      await proxy.putSuspend(id, { ...event, suspensionReason })
    } else {
      await proxy.putUnSuspend(id)
    }
    event.isSuspended = !isSuspended
    event.suspensionReason = suspensionReason
    dispatch(suspendSuccess(event))
    return true
  } catch (error) {
    dispatch(suspendFailure(error))
    return false
  }
}

export const downloadResultsTemplate = () => async (dispatch) => {
  try {
    dispatch(downloadResultsTemplateAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadResultsTemplate()
    FileDownload(data, 'template.xlsx')
    dispatch(downloadResultsTemplateSuccess())
  } catch (error) {
    dispatch(downloadResultsTemplateFailure(error))
  }
}
export const updateEvent = (event) => async (dispatch) => {
  try {
    const { id } = event
    dispatch(updateAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.put(id, event)
    dispatch(updateSuccess(data))
    return data
  } catch (error) {
    dispatch(updateFailure(error))
  }
}

export const reactEvent = (event) => async (dispatch) => {
  try {
    const {
      id,
      loggedUserMetadata: { like }
    } = event

    dispatch(reactAttempt())
    const proxy = new EventsProxy()
    if (like) {
      await proxy.putDisLike(id)
      event.likesCount--
    } else {
      await proxy.putLike(id)
      event.likesCount++
    }
    event.loggedUserMetadata.like = !like
    dispatch(reactSuccess(event))
  } catch (error) {
    dispatch(reactFailure(error))
  }
}
export const deleteEvent = (id) => async (dispatch) => {
  try {
    dispatch(deleteAttempt())
    const proxy = new EventsProxy()
    await proxy.delete(id)
    dispatch(deleteSuccess())
    return true
  } catch (error) {
    dispatch(deleteFailure(error))
    return false
  }
}
export const updateCalendar = (event) => async (dispatch) => {
  try {
    const {
      id,
      loggedUserMetadata: { inCalendar }
    } = event
    dispatch(updateCalendarAttempt())
    const proxy = new EventsProxy()
    if (inCalendar) {
      await proxy.removeFromCalendar(id)
    } else {
      await proxy.addToCalendar(id)
    }
    event.loggedUserMetadata.inCalendar = !inCalendar
    dispatch(updateCalendarSuccess(event))
  } catch (error) {
    dispatch(updateCalendarFailure(error))
  }
}
export const togglePublished = (event) => async (dispatch) => {
  try {
    const { id, isPublished } = event
    const proxy = new EventsProxy()
    dispatch(togglePublishedAttempt())
    if (!isPublished) {
      await proxy.putPublish(id)
    } else {
      await proxy.putUnPublish(id)
    }
    event.isPublished = !isPublished
    dispatch(togglePublishedSuccess(event))
    return true
  } catch (error) {
    dispatch(togglePublishedFailure(error))
    return false
  }
}

export const deleteImage = (event) => async (dispatch) => {
  try {
    const { id } = event
    dispatch(deleteImageAttempt())
    const proxy = new EventsProxy()
    await proxy.deleteImage(id)
    event.profileImages = null
    dispatch(deleteImageSuccess(event))
  } catch (error) {
    dispatch(deleteImageFailure(error))
  }
}

export const downloadResultsSheet = (event) => async (dispatch) => {
  try {
    const { id, name } = event
    dispatch(downloadResultsSheetAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadResultsSheet(id)
    FileDownload(data, `${name}_sheet.xlsx`)
    dispatch(downloadResultsSheetSuccess())
  } catch (error) {
    dispatch(downloadResultsSheetFailure(error))
  }
}

export const downloadInscriptionsInsuranceSheet = (event) => async (dispatch) => {
  try {
    const { id, name } = event
    dispatch(downloadInscriptionsInsuranceSheetAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadInscriptionsInsuranceSheet(id)
    FileDownload(data, `${name}_inscriptions_insurance_sheet.xlsx`)
    dispatch(downloadInscriptionsInsuranceSheetSuccess())
  } catch (error) {
    dispatch(downloadInscriptionsInsuranceSheetFailure(error))
  }
}

export const downloadInscriptionsRunnersSheetSheet = (event) => async (dispatch) => {
  try {
    const { id, name } = event
    dispatch(downloadInscriptionsRunnersSheetAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadInscriptionsRunnersSheet(id)
    FileDownload(data, `${name}_inscriptions_runners_sheet.xlsx`)
    dispatch(downloadInscriptionsRunnersSheetSuccess())
  } catch (error) {
    dispatch(downloadInscriptionsRunnersSheetFailure(error))
  }
}

export const downloadInscriptionsAcceptedSheet = (event) => async (dispatch) => {
  try {
    const { id, name } = event
    dispatch(downloadInscriptionsAcceptedSheetAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadInscriptionsAcceptedSheet(id)
    FileDownload(data, `${name}_inscriptions_accepted_sheet.xlsx`)
    dispatch(downloadInscriptionsAcceptedSheetSuccess())
  } catch (error) {
    dispatch(downloadInscriptionsAcceptedSheetFailure(error))
  }
}

export const downloadResultsReport = (event) => async (dispatch) => {
  try {
    const { id, name } = event
    dispatch(downloadResultsReportAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadResultsReport(id)
    FileDownload(data, `${name}_report.pdf`)
    dispatch(downloadResultsReportSuccess())
  } catch (error) {
    dispatch(downloadResultsReportFailure(error))
  }
}

export const loadLastResultForMe =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadLastResultForMeAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getLastResultForMe(page, pageSize)
      if (page === 1) {
        dispatch(resetLastResultForMe())
      }
      dispatch(loadLastResultForMeSuccess(data))
    } catch (error) {
      dispatch(loadLastResultForMeFailure(error))
    }
  }

export const loadUpcomingForMe =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadUpcomingForMeAttempt())

      const proxy = new EventsProxy()
      const data = await proxy.getUpcomingForMe(page, pageSize)

      if (page === 1) {
        dispatch(resetUpcomingForMe())
      }
      dispatch(loadUpcomingForMeSuccess(data))
    } catch (error) {
      dispatch(loadUpcomingForMeFailure(error))
    }
  }

export const loadUpcomingForMeAll =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadUpcomingForMeAllAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getUpcomingForMe(page, pageSize)
      if (page === 1) {
        dispatch(resetUpcomingForMeAll())
      }
      dispatch(loadUpcomingForMeAllSuccess(data))
    } catch (error) {
      dispatch(loadUpcomingForMeAllFailure(error))
    }
  }

export const loadLastResultForMeAll =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadLastResultForMeAllAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getLastResultForMe(page, pageSize)
      if (page === 1) {
        dispatch(resetLastResultForMeAll())
      }
      dispatch(loadLastResultForMeAllSuccess(data))
    } catch (error) {
      dispatch(loadLastResultForMeAllFailure(error))
    }
  }
export const loadUpcomingAll =
  (page = 1, pageSize = 8, searchObj = {}) =>
  async (dispatch) => {
    try {
      dispatch(loadUpcomingAllAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getUpcomingEvents(searchObj, page, pageSize)
      if (page === 1) {
        dispatch(resetUpcomingAll())
      }
      dispatch(loadUpcomingAllSuccess(data))
    } catch (error) {
      dispatch(loadUpcomingAllFailure(error))
    }
  }
export const loadLastResultAll =
  (page = 1, pageSize = 8, searchObj = {}) =>
  async (dispatch) => {
    try {
      dispatch(loadLastResultAllAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getPastEvents(searchObj, page, pageSize)
      if (page === 1) {
        dispatch(resetLastResultAll())
      }
      dispatch(loadLastResultAllSuccess(data))
    } catch (error) {
      dispatch(loadLastResultAllFailure(error))
    }
  }

export const loadCreatedByMe =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadCreatedByMeAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getCreatedByMe(page, pageSize)
      if (page === 1) {
        dispatch(resetCreatedByMe())
      }

      dispatch(loadCreatedByMeSuccess(data))
    } catch (error) {
      dispatch(loadCreatedByMeFailure(error))
    }
  }

export const loadCreatedByMeAll =
  (page = 1, pageSize = 8) =>
  async (dispatch) => {
    try {
      dispatch(loadCreatedByMeAllAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getCreatedByMe(page, pageSize)

      if (page === 1) {
        dispatch(resetCreatedByMeAll())
      }
      dispatch(loadCreatedByMeAllSuccess(data))
    } catch (error) {
      dispatch(loadCreatedByMeAllFailure(error))
    }
  }

export const loadActiveInscriptionForm = (event) => async (dispatch) => {
  try {
    dispatch(loadActiveInscriptionFormAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getActiveInscriptionForm(event.id)

    dispatch(loadActiveInscriptionFormSuccess(data))
  } catch (error) {
    dispatch(loadActiveInscriptionFormFailure(error))
  }
}

export const createInscriptionForms = (event, form) => async (dispatch) => {
  try {
    dispatch(createInscriptionFormsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.postInscriptionForms(event.id, form)

    dispatch(createInscriptionFormsSuccess(data))

    return true
  } catch (error) {
    dispatch(createInscriptionFormsFailure(error))
  }
}

export const updateInscriptionForms = (event, form) => async (dispatch) => {
  try {
    dispatch(updateInscriptionFormsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putInscriptionForms(event.id, form)

    dispatch(updateInscriptionFormsSuccess(data))

    return true
  } catch (error) {
    dispatch(updateInscriptionFormsFailure(error))
  }
}

export const deleteInscriptionForms = (eventId, formId) => async (dispatch) => {
  try {
    dispatch(deleteInscriptionFormsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.deleteInscriptionForms(eventId, formId)

    dispatch(deleteInscriptionFormsSuccess(data))

    return true
  } catch (error) {
    dispatch(deleteInscriptionFormsFailure(error))
  }
}

export const eventInscription = (form) => async (dispatch) => {
  try {
    dispatch(eventInscriptionAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.eventInscription(form)

    dispatch(eventInscriptionSuccess(data))

    return data
  } catch (error) {
    dispatch(eventInscriptionFailure(error))
    return false
  }
}

export const loadListInscriptions =
  (
    eventId,
    searchText = '',
    page = 1,
    state = '',
    orderState = '',
    pageSize = 25,
    orderBy,
    orderDirection
  ) =>
  async (dispatch) => {
    try {
      dispatch(listInscriptionAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getListInscription(
        eventId,
        page,
        state,
        orderState,
        pageSize,
        searchText,
        orderBy,
        orderDirection
      )

      dispatch(listInscriptionSuccess(data))
    } catch (error) {
      dispatch(listInscriptionFailure(error))
    }
  }

export const deleteInscriptionRegisteredList = (id) => async (dispatch, getState) => {
  try {
    dispatch(deleteInscriptionRegisteredListAttempt())
    const { listInscription, listInscriptionPageCurrentPage } = getState().events
    const proxy = new EventsProxy()
    const data = await proxy.deleteListInscription(id)

    dispatch(deleteInscriptionRegisteredListSuccess(data))

    if (listInscription.length === 1 && listInscriptionPageCurrentPage > 1) {
      return true
    }
  } catch (error) {
    dispatch(deleteInscriptionRegisteredListFailure(error))
  }
}

export const loadListInscriptionSearchMetadata =
  (eventId, searchText = '', state = '') =>
  async (dispatch) => {
    try {
      dispatch(listInscriptionSearchMetadataAttempt())
      const proxy = new EventsProxy()
      const searchMetadata = await proxy.getListInscriptionSearchMetadata(
        eventId,
        searchText,
        state
      )
      dispatch(listInscriptionSearchMetadataSuccess(searchMetadata))
    } catch (error) {
      dispatch(listInscriptionSearchMetadataFailure(error))
    }
  }

export const uploadRulesDocument = (eventId, rulesDocument) => async (dispatch) => {
  try {
    dispatch(uploadRulesDocumentAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.putRulesDocument(eventId, rulesDocument)
    dispatch(uploadRulesDocumentSuccess())
    return data
  } catch (error) {
    dispatch(uploadRulesDocumentFailure(error))
  }
}

export const downloadRulesDocument = (event) => async (dispatch) => {
  try {
    dispatch(downloadRulesDocumentAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadRulesDocument(event.id)
    FileDownload(data, event.rulesDocumentFile.fileName.split('rules-')[1] || 'Reglas_del_evento')
    dispatch(downloadRulesDocumentSuccess())
    return true
  } catch (error) {
    dispatch(downloadRulesDocumentFailure(error))
  }
}

export const updateEventInscription = (form) => async (dispatch, getState) => {
  try {
    dispatch(updateEventInscriptionAttempt())
    const { listInscription, listInscriptionPageCurrentPage } = getState().events
    const proxy = new EventsProxy()
    const data = await proxy.putEventInscription(form)

    dispatch(updateEventInscriptionSuccess(data))
    if (
      listInscription.length === 1 &&
      listInscriptionPageCurrentPage > 1 &&
      !listInscription.filter((e) => e.state === data.state).length
    ) {
      return { page: listInscriptionPageCurrentPage - 1 }
    }

    return true
  } catch (error) {
    dispatch(updateEventInscriptionFailure({ ...error, id: form.id }))
    return false
  }
}

export const loadEventInscriptionsForCurrentUser =
  (id, page = 1) =>
  async (dispatch) => {
    try {
      dispatch(loadEventInscriptionsForCurrentUserAttempt())

      const proxy = new EventsProxy()
      const data = await proxy.getEventInscriptionForCurrentUser(id, page)
      dispatch(loadEventInscriptionsForCurrentUserSuccess(data))
      return true
    } catch (error) {
      dispatch(loadEventInscriptionsForCurrentUserFailure(error))
    }
  }
export const loadEventInscriptionsAllForCurrentUser =
  (id, page = 1) =>
  async (dispatch) => {
    try {
      dispatch(loadEventInscriptionsAllForCurrentUserAttempt())
      const proxy = new EventsProxy()
      const data = await proxy.getEventInscriptionForCurrentUser(id, page)

      if (page === 1) {
        dispatch(resetEventInscriptionsAllForCurrentUser())
      }

      dispatch(loadEventInscriptionsAllForCurrentUserSuccess(data))
      return true
    } catch (error) {
      dispatch(loadEventInscriptionsAllForCurrentUserFailure(error))
    }
  }

export const downloadInscriptionsSheet = (event) => async (dispatch) => {
  try {
    dispatch(downloadInscriptionsSheetAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadInscriptionsSheet(event.id)
    FileDownload(data, `${event.name}_inscriptions_sheet.xlsx`)
    dispatch(downloadInscriptionsSheetSuccess())
  } catch (error) {
    dispatch(downloadInscriptionsSheetFailure(error))
  }
}

export const acceptOrganization = (eventId, organizationId) => async (dispatch) => {
  try {
    dispatch(acceptOrganizationAttempt())

    const proxy = new EventsProxy()
    await proxy.acceptOrganization(eventId, organizationId)

    dispatch(acceptOrganizationSuccess())
  } catch (error) {
    dispatch(acceptOrganizationFailure(error))
  }
}

export const rejectOrganization = (eventId, organizationId) => async (dispatch) => {
  try {
    dispatch(rejectOrganizationAttempt())

    const proxy = new EventsProxy()
    await proxy.rejectOrganization(eventId, organizationId)

    dispatch(rejectOrganizationSuccess())
  } catch (error) {
    dispatch(rejectOrganizationFailure(error))
  }
}

export const deleteRulesDocument = (eventId) => async (dispatch) => {
  try {
    dispatch(deleteRulesDocumentAttempt())

    const proxy = new EventsProxy()
    await proxy.deleteRulesDocument(eventId)

    dispatch(deleteRulesDocumentSuccess())
    return true
  } catch (error) {
    dispatch(deleteRulesDocumentFailure(error))
    return false
  }
}

export const loadInscriptionsFormsCloneSuggestions =
  (organizationId, page = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      dispatch(loadInscriptionsFormsCloneSuggestionsAttempt())

      const proxy = new EventsProxy()
      const data = await proxy.getInscriptionFormsClonesuggestions(organizationId, page, pageSize)

      if (page === 1) {
        dispatch(resetInscriptionFormsCloneSuggestions())
      }

      dispatch(loadInscriptionsFormsCloneSuggestionsSuccess(data))
    } catch (error) {
      dispatch(loadInscriptionsFormsCloneSuggestionsFailure(error))
    }
  }

export const loadCategoriesCloneSuggestions = (eventId, organizationId) => async (dispatch) => {
  try {
    dispatch(loadCategoriesCloneSuggestionsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getCategoriesCloneSuggestions(eventId, organizationId)

    dispatch(loadCategoriesCloneSuggestionsSuccess(data))
    return true
  } catch (error) {
    dispatch(loadCategoriesCloneSuggestionsFailure(error))
    return false
  }
}

export const loadCategories = (id) => async (dispatch) => {
  try {
    dispatch(loadCategoriesAttempt())
    const proxy = new EventsProxy()
    const categories = await proxy.getCategories(id)

    dispatch(loadCategoriesSuccess(categories))
    return categories.results
  } catch (error) {
    dispatch(loadCategoriesFailure(error))
  }
}

export const createCategory = (eventId, category) => async (dispatch) => {
  try {
    dispatch(createCategoryAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.postCategory(eventId, category)
    dispatch(createCategorySuccess(data))
    return data
  } catch (error) {
    dispatch(createCategoryFailure({ name: category.name }))
  }
}

export const updateCategory = (eventId, category) => async (dispatch) => {
  try {
    dispatch(updateCategoryAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putCategory(eventId, category)

    dispatch(updateCategorySuccess(data))
    return data
  } catch (error) {
    dispatch(updateCategoryFailure({ name: category.name }))
  }
}

export const deleteCategory = (eventId, categoryId) => async (dispatch) => {
  try {
    dispatch(deleteCategoryAttempt())
    const proxy = new EventsProxy()
    await proxy.deleteCategory(eventId, categoryId)
    dispatch(deleteCategorySuccess())
    return true
  } catch (error) {
    dispatch(deleteCategoryFailure(error))
  }
}

export const loadDistances = (id) => async (dispatch) => {
  try {
    dispatch(loadDistancesAttempt())
    const proxy = new EventsProxy()
    const distances = await proxy.getDistances(id)

    dispatch(loadDistancesSuccess(distances))

    return distances.results
  } catch (error) {
    dispatch(loadDistancesFailure(error))
  }
}

export const createDistance = (eventId, distance) => async (dispatch) => {
  try {
    dispatch(createDistanceAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.postDistance(eventId, distance)
    dispatch(createDistanceSuccess(data))
    return data
  } catch (error) {
    dispatch(createDistanceFailure({ name: distance.name }))
  }
}

export const updateDistance = (eventId, distance) => async (dispatch) => {
  try {
    dispatch(updateDistanceAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.putDistance(eventId, distance)
    dispatch(updateDistanceSuccess(data))
    return data
  } catch (error) {
    dispatch(updateDistanceFailure({ name: distance.name }))
  }
}

export const deleteDistance = (eventId, distanceId) => async (dispatch) => {
  try {
    dispatch(deleteDistanceAttempt())
    const proxy = new EventsProxy()
    await proxy.deleteDistance(eventId, distanceId)
    dispatch(deleteDistanceSuccess())
    return true
  } catch (error) {
    dispatch(deleteDistanceFailure(error))
  }
}

export const loadEventMercadoPagoAuthorization = (eventId) => async (dispatch) => {
  try {
    dispatch(loadMercadoPagoAuthorizationAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getMercadoPagoAuthorization(eventId)

    dispatch(loadMercadoPagoAuthorizationSuccess(data))
    return data
  } catch (error) {
    dispatch(loadMercadoPagoAuthorizationFailure(error))
  }
}

export const sendMercadoPagoAuthorization = (eventId, eMail) => async (dispatch) => {
  try {
    dispatch(sendMercadoPagoAuthorizationAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.sendMercadoPagoAuthorization(eventId, eMail)

    dispatch(sendMercadoPagoAuthorizationSucess(data))
    return data
  } catch (error) {
    dispatch(sendMercadoPagoAuthorizationFailure(error))
  }
}

export const deleteMercadoPagoAuthorization = (eventId) => async (dispatch) => {
  try {
    dispatch(deleteMercadoPagoAuthorizationAttempt())

    const proxy = new EventsProxy()
    await proxy.deleteMercadoPagoAuthorization(eventId)

    dispatch(deleteMercadoPagoAuthorizationSuccess())
    return true
  } catch (error) {
    dispatch(deleteMercadoPagoAuthorizationFailure(error))
    return false
  }
}

export const loadEventBankAccounts = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventBankAccountsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getEventBankAccounts(eventId)

    dispatch(loadEventBankAccountsSuccess(data))
    return data
  } catch (error) {
    dispatch(loadEventBankAccountsFailure(error))
  }
}

export const createEventBankAccount = (eventId, bankAccount) => async (dispatch) => {
  try {
    dispatch(createEventBankAccountAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.postEventBankAccount(eventId, bankAccount)

    dispatch(createEventBankAccountSuccess(data))
    return data
  } catch (error) {
    dispatch(createEventBankAccountFailure(error))
  }
}

export const updateEventBankAccount = (eventId, bankAccount) => async (dispatch) => {
  try {
    dispatch(updateEventBankAccountAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putEventBankAccount(eventId, bankAccount)

    dispatch(updateEventBankAccountSuccess(data))
    return data
  } catch (error) {
    dispatch(updateEventBankAccountFailure(error))
  }
}

export const deleteEventBankAccount = (eventId, bankAccountId) => async (dispatch) => {
  try {
    dispatch(deleteEventBankAccountAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.deleteEventBankAccount(eventId, bankAccountId)

    dispatch(deleteEventBankAccountSuccess(data))
    return data
  } catch (error) {
    dispatch(deleteEventBankAccountFailure(error))
  }
}

export const loadPaymentLocations = (eventId) => async (dispatch) => {
  try {
    dispatch(loadPaymentLocationsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getPaymentLocations(eventId)

    dispatch(loadPaymentLocationsSuccess(data))
    return data
  } catch (error) {
    dispatch(loadPaymentLocationsFailure(error))
  }
}

export const createPaymentLocation = (eventId, paymentLocation) => async (dispatch) => {
  try {
    dispatch(createPaymentLocationAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.postPaymentLocation(eventId, paymentLocation)

    dispatch(createPaymentLocationSuccess(data))
    return data
  } catch (error) {
    dispatch(createPaymentLocationFailure(error))
  }
}

export const updatePaymentLocation = (eventId, paymentLocation) => async (dispatch) => {
  try {
    dispatch(updatePaymentLocationAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putPaymentLocation(eventId, paymentLocation)

    dispatch(updatePaymentLocationSuccess(data))
    return data
  } catch (error) {
    dispatch(updatePaymentLocationFailure(error))
  }
}

export const deletePaymentLocation = (eventId, paymentLocationId) => async (dispatch) => {
  try {
    dispatch(deletePaymentLocationAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.deletePaymentLocation(eventId, paymentLocationId)

    dispatch(deletePaymentLocationSuccess(data))
    return data
  } catch (error) {
    dispatch(deletePaymentLocationFailure(error))
  }
}

export const loadBanks = () => async (dispatch) => {
  try {
    dispatch(loadBanksAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getBanks()

    dispatch(loadBanksSuccess(data))
    return data
  } catch (error) {
    dispatch(loadBanksFailure(error))
  }
}

export const loadEventInscription = (id) => async (dispatch) => {
  try {
    dispatch(loadEventInscriptionAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getEventInscription(id)

    dispatch(loadEventInscriptionSuccess(data))
    return data
  } catch (error) {
    dispatch(loadEventInscriptionFailure(error))
  }
}

export const loadActivePaymentLocations = (id) => async (dispatch) => {
  try {
    dispatch(loadActivePaymentLocationsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getActivePaymentLocations(id)

    dispatch(loadActivePaymentLocationsSuccess(data))

    return data
  } catch (error) {
    dispatch(loadActivePaymentLocationsFailure(error))
  }
}

export const loadTicketTypeFees = () => async (dispatch) => {
  try {
    dispatch(loadTicketTypeFeesAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getTicketTypeFees()

    dispatch(loadTicketTypeFeesSuccess(data))
    return data
  } catch (error) {
    dispatch(loadTicketTypeFeesFailure(error))
  }
}

export const loadActiveBankAccounts = (id) => async (dispatch) => {
  try {
    dispatch(loadActiveBankAccountsAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getActiveBankAccounts(id)

    dispatch(loadActiveBankAccountsSuccess(data))
    return data
  } catch (error) {
    dispatch(loadActiveBankAccountsFailure(error))
  }
}

export const loadEventInscriptionByIdNumberAndCountry = (eventInscription) => async (dispatch) => {
  try {
    dispatch(loadEventInscriptionByIdNumberAndCountryAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getEventInscriptionByIdNumberAndCountry(eventInscription)

    dispatch(loadEventInscriptionByIdNumberAndCountrySuccess(data))
    return data
  } catch (error) {
    if (error?.response?.status === NOT_FOUND_CODE)
      dispatch(
        loadEventInscriptionByIdNumberAndCountryFailure({
          message: 'No se encontró la inscripción en el evento, con el dni y país ingresados'
        })
      )
  }
}

export const publicUpdateEventInscription = (eventInscription) => async (dispatch) => {
  try {
    dispatch(publicUpdateEventInscriptionAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putEventInscriptionPublicUpdate(eventInscription)

    dispatch(publicUpdateEventInscriptionSuccess(data))
    return data
  } catch (error) {
    dispatch(publicUpdateEventInscriptionFailure(error))
  }
}

export const loadCancelledEventInscription = (eventInscriptionId) => async (dispatch) => {
  try {
    dispatch(loadCancelledEventInscriptionAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getCancelledEventInscription(eventInscriptionId)
    dispatch(loadCancelledEventInscriptionSuccess(data))

    return data
  } catch (error) {
    dispatch(loadCancelledEventInscriptionFailure(error))
  }
}

export const deleteSaleOrderPayment = (saleOrderId, saleOrderPaymentId) => async (dispatch) => {
  try {
    dispatch(deleteSaleOrderPaymentAttempt())

    const proxy = new SaleOrdersProxy()
    const data = await proxy.deleteSaleOrderPayment(saleOrderId, saleOrderPaymentId)
    dispatch(deleteSaleOrderPaymentSuccess(data))

    return data
  } catch (error) {
    dispatch(deleteSaleOrderPaymentFailure(error))
  }
}

export const updateSaleOrderPayment = (saleOrderPayment) => async (dispatch) => {
  try {
    dispatch(updateSaleOrderPaymentAttempt())

    const proxy = new SaleOrdersProxy()
    const data = await proxy.updateSaleOrderPayment(saleOrderPayment)
    dispatch(updateSaleOrderPaymentSuccess(data))

    return data
  } catch (error) {
    dispatch(updateSaleOrderPaymentFailure(error))
  }
}

export const createEventDiscountCode = (eventDiscountCode, eventId) => async (dispatch) => {
  try {
    dispatch(createEventDiscountCodeAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.postEventDiscountCode(eventDiscountCode, eventId)

    dispatch(createEventDiscountCodeSuccess(data))

    return data
  } catch (error) {
    dispatch(createEventDiscountCodeFailure(error))
  }
}

export const loadEventDiscountCodes = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventDiscountCodesAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getEventDiscountCodes(eventId)

    dispatch(loadEventDiscountCodesSuccess(data))
    return data
  } catch (error) {
    dispatch(loadEventDiscountCodesFailure(error))
  }
}

export const updateEventDiscountCode = (eventDiscountCode, eventId) => async (dispatch) => {
  try {
    dispatch(updateEventDiscountCodeAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.putEventDiscountCode(eventDiscountCode, eventId)

    dispatch(updateEventDiscountCodeSuccess(data))

    return data
  } catch (error) {
    dispatch(updateEventDiscountCodeFailure(error))
  }
}

export const deleteEventDiscountCode = (eventDiscountCodeId, eventId) => async (dispatch) => {
  try {
    dispatch(deleteEventDiscountCodeAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.deleteEventDiscountCode(eventDiscountCodeId, eventId)

    dispatch(deleteEventDiscountCodeSuccess(data))

    return data
  } catch (error) {
    dispatch(deleteEventDiscountCodeFailure(error))
  }
}

export const loadEventResultsReportUrl = (eventId) => async (dispatch) => {
  try {
    dispatch(loadEventResultsReportUrlAttempt())
    const proxy = new EventsProxy()
    const data = await proxy.getDownloadResultsReport(eventId)
    const pdf = URL.createObjectURL(
      new Blob([data], {
        type: 'application/pdf'
      })
    )
    dispatch(loadEventResultsReportUrlSuccess(pdf))
  } catch (e) {
    dispatch(loadEventResultsReportUrlFailure(e))
  }
}

export const downloadEventCategories = (event) => async (dispatch) => {
  try {
    dispatch(downloadEventCategoriesAttempt())

    const proxy = new EventsProxy()
    const data = await proxy.getDownloadEventCategories(event.id)

    FileDownload(data, `Categorías_${event.name}.xlsx`)

    dispatch(downloadEventCategoriesSuccess(data))

    return true
  } catch (error) {
    dispatch(downloadEventCategoriesFailure(error))
  }
}
