import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  snackbar: {
    position: 'absolute',
    top: 'calc(50% - 31px)',
    height: 63,
    left: 0,
    zIndex: 1,
    width: '85%',
    [theme.breakpoints.up('sm')]: {
      left: '37%',
      width: '75%'
    },
    [theme.breakpoints.up('md')]: {
      left: '50%',
      width: 'auto'
    }
  },
  container: {
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    color: theme.palette.primary.main,
    fontWeight: 600,
    borderRadius: 50,
    maxWidth: 500
  },
  mainContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.16),
    display: 'flex',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2, 5),
    gap: theme.spacing(3)
  },
  iconSuccess: {
    height: 31,
    width: 31
  }
}))
