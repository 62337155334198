import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { SectionLayoutDialog } from 'shared'
import { verifyUserLogged, verifyUserNotLogged } from 'state/modules/app'

import { useStyles } from './UserNotLoggedDialog.style'

const DEFAULT_SUBTITLE = (
  <Typography variant='h6'>
    Tenés que tener una cuenta de <strong>Sportmetric</strong>&nbsp; para poder acceder a esta
    sección.
    <br />
    <br />
    Para este y más beneficios:
  </Typography>
)

const UserNotLoggedDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { userNotLogged } = useSelector((state) => state.app)

  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    if (userNotLogged?.state) {
      setOpenDialog(true)
    }
  }, [userNotLogged])

  const handleClose = () => {
    if (userNotLogged?.state) {
      dispatch(verifyUserNotLogged({ state: false }))
    }

    if (userNotLogged?.redirectUrl === location.pathname) {
      history.push(ROUTES.ROOT)
    }
    setOpenDialog(false)
  }

  const onChangePage = (nameBox) => {
    if (userNotLogged?.state) {
      dispatch(verifyUserNotLogged({ state: false }))
    }

    dispatch(verifyUserLogged({ state: true, nameBox, redirectUrl: userNotLogged.redirectUrl }))
    setOpenDialog(false)
  }

  return (
    <SectionLayoutDialog
      open={openDialog}
      onClose={handleClose}
      title='NOTIFICACIÓN'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography className={classes.subTitle}>
        {userNotLogged?.dialogSubTitle ?? DEFAULT_SUBTITLE}
      </Typography>
      <div className={classes.loginContainer}>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={() => onChangePage('/sign-in')}>
          Iniciar sesión
        </Button>
        <Typography variant='h6'>ó</Typography>
        <Button
          color='primary'
          className={classes.button}
          variant='outlined'
          onClick={() => onChangePage('/sign-up')}>
          Registrate
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default UserNotLoggedDialog
