import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography, useMediaQuery } from '@material-ui/core'

import { OrganizationContainer } from '../OrganizationContainer'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ManageOrganizationsSection.style'

const AUTO_SLIDE_FROM = 3

const ManageOrganizationsSection = () => {
  const classes = useStyles()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)

  return (
    <SectionBlock title={!isDesktop && 'Administrar organizaciones'}>
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.block}>
            {isDesktop && (
              <Typography color='primary' variant='h6' className={classes.title}>
                Administrar organizaciones
              </Typography>
            )}
            {!organizationsAdminAll.length && (
              <Typography color='primary' className={classes.emptyText}>
                Aún no tienes organizaciones a tu cargo.
              </Typography>
            )}
          </div>
          <Button variant='contained' color='secondary' className={classes.button}>
            Solicitar Administrar
          </Button>
        </div>
        <OrganizationContainer
          organizations={organizationsAdminAll}
          automaticMove={organizationsAdminAll.length > AUTO_SLIDE_FROM}
        />
      </div>
    </SectionBlock>
  )
}

export default ManageOrganizationsSection
