import { makeStyles } from '@material-ui/core'
import next from 'assets/img/slider-next.png'
import previous from 'assets/img/slider-prev.png'

export const useStyles = makeStyles((theme) => ({
  paginatorContainer: {
    height: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center'
    }
  },
  navigation: {
    display: 'flex'
  },
  previousImage: {
    backgroundImage: `url(${previous})`
  },
  nextImage: {
    backgroundImage: `url(${next})`
  },
  imageContainer: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      marginLeft: 30
    }
  },
  numberPage: {
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0
    }
  }
}))
