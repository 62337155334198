import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyle } from './SectionFollowedPlaceholderLoader.style'

export const SectionFollowedPlaceholderLoader = () => {
  const classes = useStyle()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <circle cx='40' cy='50' r='35' />
            <rect x='100' y='45' rx='3' ry='3' className={classes.followed} />
            <circle cx='40' cy='130' r='35' />
            <rect x='100' y='125' rx='3' ry='3' className={classes.followed} />
            <circle cx='40' cy='215' r='35' />
            <rect x='100' y='210' rx='3' ry='3' className={classes.followed} />
          </ContentLoader>
        ) : (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <circle cx='40' cy='50' r='35' />
            <rect x='100' y='45' rx='3' ry='3' className={classes.followed} />
            <circle cx='40' cy='130' r='35' />
            <rect x='100' y='125' rx='3' ry='3' className={classes.followed} />
            <circle cx='40' cy='215' r='35' />
            <rect x='100' y='210' rx='3' ry='3' className={classes.followed} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default SectionFollowedPlaceholderLoader
