import React, { useState } from 'react'
import { SocialIcon } from 'react-social-icons'
import { Typography, useMediaQuery } from '@material-ui/core'
import { ReactComponent as PinardLogo } from 'assets/icons/pinard-logo.svg'
import { ReactComponent as InstagramIcon } from 'assets/icons/white-instagram-icon.svg'
import { TermsAndConditionsOrganizationContent } from 'pages/Organizations/CreatePage/components'
import { PrivacyPolicyContent, TermsAndConditionsDialog } from 'shared'

import { NavBar } from '../NavBar'
import { QueryForm } from '../QueryForm'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './QueriesSection.style'

const QueriesSection = ({ ...sectionProps }) => {
  const classes = useStyles()

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openTerms, setOpenTerms] = useState(false)
  const [openPolicy, setOpenPolicy] = useState(false)

  return (
    <SectionBlock {...sectionProps} containerClassName={classes.container}>
      <NavBar altMode />

      <div className={classes.mainContainer}>
        <div className={classes.imagesMini}>
          <div className={classes.sportImagesMini}>
            <div className={classes.motorcycling} />
            <div className={classes.swimming} />
            <div className={classes.triathlon} />
          </div>
        </div>
        <div className={classes.content}>
          <div className={classes.headerContainer}>
            <Typography variant='h3' className={classes.title}>
              ¿Tenés dudas?
            </Typography>
            <Typography variant='h5' className={classes.subTitle}>
              Si querés que te contemos más sobre cómo potenciar tus eventos con nuestras
              herramientas, completá el formulario de contacto y dejá tu consulta.
            </Typography>
          </div>

          <div className={classes.formContainer}>
            <QueryForm />
          </div>
        </div>

        <div className={classes.sportImages}>
          <div className={classes.mountainBike} />
          <div className={classes.running} />
          <div className={classes.bikeRace} />
          {isDesktop && <div className={classes.swimming} />}
          {isDesktop && <div className={classes.motorcycling} />}
        </div>
      </div>
      <footer className={classes.footer}>
        <div className={classes.section}>
          <div className={classes.netwoorkSocialContainer}>
            <a
              href='https://www.instagram.com/sportmetric.oficial/'
              target='_blank'
              className={classes.instagramIcon}
              rel='noopener noreferrer'>
              <InstagramIcon />
            </a>
            <SocialIcon
              className={classes.facebookIcon}
              network='facebook'
              bgColor='transparent'
              fgColor='#fff'
              url='https://www.facebook.com/sportmetric.net/'
              target='_blank'
            />
          </div>
          {isDesktop && (
            <div className={classes.developmentInfo}>
              <Typography className={classes.developmentText}>Desarrollado por</Typography>
              <PinardLogo />
            </div>
          )}
          <div className={classes.policyAndConditionsContainer}>
            <Typography className={classes.policyAndConditions}>
              <span onClick={() => setOpenPolicy(true)}>Políticas</span>&nbsp;
              <span onClick={() => setOpenTerms(true)}>y condiciones de uso</span>
            </Typography>
            <TermsAndConditionsDialog
              handleClose={() => setOpenTerms(false)}
              open={openTerms}
              color='secondary'
              content={TermsAndConditionsOrganizationContent}
              title='Condiciones de uso'
            />
            <TermsAndConditionsDialog
              handleClose={() => setOpenPolicy(false)}
              open={openPolicy}
              color='secondary'
              content={PrivacyPolicyContent}
              title='Política de privacidad'
            />
          </div>
        </div>

        {!isDesktop && (
          <div className={classes.developmentInfo}>
            <Typography className={classes.developmentText}>Desarrollado por</Typography>
            <PinardLogo className={classes.pinardLogo} />
          </div>
        )}
      </footer>
    </SectionBlock>
  )
}

export default QueriesSection
