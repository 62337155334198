import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 800,
      maxWidth: 800,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 400
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(6)
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    padding: theme.spacing(4, 2.5),
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 7)
    }
  },
  infoContainer: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  subTitle: {
    color: '#000000',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 400
    }
  },
  welcomeText: {
    padding: theme.spacing(3, 0),
    color: '#696969',
    alignSelf: 'flex-start',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 1)
    }
  },
  paragraph: {
    padding: theme.spacing(1, 0),
    color: '#000000',
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      paddingLeft: 310
    }
  },
  helperText: {
    color: '#000000',
    fontWeight: 500,
    padding: theme.spacing(0, 2),
    '& a': {
      color: '#000000'
    }
  },
  acceptButton: {
    alignSelf: 'center',
    width: 210,
    height: 40,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 600
  },
  resgisterText: {
    backgroundColor: theme.palette.tooltip.main,
    padding: 10,
    borderRadius: 10,
    width: '100%',
    fontWeight: 400,
    marginRight: 80,
    [theme.breakpoints.up('md')]: {
      width: 630
    }
  }
}))
