import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    width: '100% !important',
    margin: 0
  },
  container: {
    width: '100%',
    margin: '0 auto',
    '& > div > div': {
      minHeight: '350px !important'
    }
  },
  infoContainer: {
    width: '100%',
    margin: '0 auto'
  },
  textInitial: {
    fontSize: 16,
    fontWeight: 600,
    width: '100%',
    padding: 30,
    [theme.breakpoints.up('md')]: {
      marginLeft: 100,
      width: 650
    }
  }
}))
