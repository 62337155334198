import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { PaymentLocationCard, SectionLayoutDialog } from 'shared'
import { loadActivePaymentLocations } from 'state/modules/events'

import { useStyles } from './PaymentLocationDialog.style'

const PaymentLocationDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activePaymentLocations, event, activePaymentLocationsPending } = useSelector(
    (state) => state.events
  )

  useEffect(() => {
    if (open) dispatch(loadActivePaymentLocations(event.id))
  }, [open])

  const hasMultiplePaymentLocations = activePaymentLocations.length > 1

  return (
    <SectionLayoutDialog
      title='PUNTOS FISICOS PARA PAGO EN EFECTIVO'
      open={open}
      onClose={onClose}
      className={classes.dialog}
      contentClassName={classes.container}>
      {activePaymentLocationsPending && <CircularProgress color='primary' size={20} />}

      <div className={classes.paymentLocationsContainer}>
        {activePaymentLocations.map((x, index) => (
          <PaymentLocationCard
            key={x.id}
            paymentLocation={x}
            title={`Lugar de pago ${hasMultiplePaymentLocations ? index + 1 : ''}`}
          />
        ))}
      </div>
    </SectionLayoutDialog>
  )
}

export default PaymentLocationDialog
