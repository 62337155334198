import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { Error as ErrorIcon, Info as InfoIcon, Visibility, VisibilityOff } from '@material-ui/icons'
import clsx from 'clsx'
import { Formik } from 'formik'
import { ROUTES } from 'routes'
import { CustomizedTooltip, FileViewerContent, SectionLayoutDialog } from 'shared'
import {
  createServiceLetterReport,
  downloadServiceLetterReport,
  loadServiceLetterReport,
  updateOrganization
} from 'state/modules/organizations'
import { normalizeUrl } from 'utils/functions'
import * as Yup from 'yup'

import { useStyles } from './SignServiceLetterDialog.style'

const SignServiceLetterDialog = ({ open, onClose }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const history = useHistory()
  const { event } = useSelector((state) => state.events)
  const { organization } = event

  const organizer = organization.userAccounts?.find(
    (x) => x.userAccountId === organization.createdById
  )

  const [firstContent, setFirstContent] = useState(true)
  const [formToSing, setFormToSign] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(null)

  const handleOnClose = () => {
    setFirstContent(true)
    setFormToSign(false)
    onClose()
  }

  const handleConfirm = async () => {
    setFormToSign(true)
  }

  const handleUpdateAddress = async (values) => {
    const formSubmitData = {
      id: organization.id,
      name: organization.name,
      handle: organization.handle,
      referentName: organization.referentName,
      eMail: organization.eMail,
      countryId: organization.countryId,
      idNumber: organization.idNumber,
      disciplines: organization.disciplines,
      phone: organization.phone,
      areaLevel1Id: organization.areaLevel1Id,
      youTubeUrl: normalizeUrl(organization.youTubeUrl),
      instagramUrl: normalizeUrl(organization.instagramUrl),
      facebookUrl: normalizeUrl(organization.facebookUrl),
      web: normalizeUrl(organization.web),
      userAccounts: organization.userAccounts,
      address: values.address
    }

    const updateSuccess = await dispatch(updateOrganization(formSubmitData))

    if (updateSuccess) {
      const resultServiceLetterReport = await dispatch(loadServiceLetterReport(organization.id))
      const pdf = URL.createObjectURL(
        new Blob([resultServiceLetterReport], {
          type: 'application/pdf'
        })
      )
      setPdfUrl(pdf)
      setFirstContent(false)
    }
  }

  const handleDownloadServiceLetterReport = () =>
    dispatch(downloadServiceLetterReport(organization.id))

  const handleSave = async (values, { setFieldError }) => {
    const { adress, ...formSubmit } = values

    const postSuccess = await dispatch(createServiceLetterReport(event.organization.id, formSubmit))

    if (postSuccess) {
      setFormToSign(false)
      history.push(`${ROUTES.EVENTS.INSCRIPTION}/${event.slugUrl}`)
    } else {
      setFieldError('password', 'La contraseña ingresada es incorrecta')
    }
  }
  const validateSchema = Yup.object().shape({
    username: Yup.string().required('Ingresa tu e-mail o nombre de usuario'),
    password: Yup.string().required('Ingresa tu contraseña'),
    address: Yup.string()
  })

  const addressValidateSchema = Yup.object().shape({
    address: Yup.string()
      .required('Ingresa el domicilio legal de la organización')
      .min(25, 'El domicilio tiene como minimo de 25 caracteres')
      .max(150, 'El domicilio tiene como máximo 150 caracteres')
      .matches(/\d/, 'La dirección debe contener al menos un número')
  })

  const initialValues = {
    username: organizer.userAccountEMail,
    password: '',
    address: organization.address ?? ''
  }

  return (
    <SectionLayoutDialog
      title='Firma carta de servicio'
      open={open}
      onClose={handleOnClose}
      className={clsx({
        [classes.dialog]: firstContent || formToSing,
        [classes.dialogServerLetter]: !firstContent && !formToSing
      })}
      mainContainerClassName={clsx(!firstContent && !formToSing && classes.fileMainContainer)}>
      {firstContent ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleUpdateAddress}
          validationSchema={addressValidateSchema}>
          {({
            values,
            touched,
            errors,
            handleSubmit,
            handleChange,
            handleBlur,
            isValid,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className={classes.cardForm}>
              <Typography className={classes.label}>
                Para poder habilitar la modalidad de pago en el formulario de inscripción, es
                necesario que leas y firmes <b>Nuestra carta de servicio</b>. Primero necesitamos
                que completes el domicilio legal de tu organización a continuación.
              </Typography>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.inputAddress}
                  name='address'
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label='Domicilio legal'
                  fullWidth
                  required
                  variant='outlined'
                  size='small'
                  margin='none'
                  helperText={errors.address || 'Ej Roma 340, cp 5000 Córdoba, Córdoba, Argentina'}
                  error={touched.address && Boolean(errors.address)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {touched.name && Boolean(errors.name) ? (
                          <ErrorIcon color='error' />
                        ) : (
                          <CustomizedTooltip
                            position='top'
                            disabledClickAway
                            className={classes.tooltip}
                            buttonClassName={classes.tooltipButton}
                            focusedTooltipClassName={classes.tooltipPopper}
                            title={
                              <Typography className={classes.tooltipTitle}>
                                Debes ingresar la dirección completa indicando calle y Número,
                                Código Postal, Ciudad, provincia y País
                              </Typography>
                            }>
                            {({ handleTooltip }) => (
                              <IconButton
                                onClick={handleTooltip}
                                onMouseDown={(event) => event.preventDefault()}
                                edge='end'
                                color='primary'>
                                <InfoIcon className={classes.infoIcon} />
                              </IconButton>
                            )}
                          </CustomizedTooltip>
                        )}
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className={classes.buttonContainerSave}>
                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                  disabled={!isValid || isSubmitting}>
                  Siguiente
                </Button>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <>
          {!formToSing ? (
            <FileViewerContent
              fileExtension='application/pdf'
              fileUrl={pdfUrl}
              fileViewerClassName={classes.fileViewer}
            />
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSave}
              validationSchema={validateSchema}>
              {({
                values,
                touched,
                errors,
                handleSubmit,
                handleChange,
                handleBlur,
                isValid,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className={classes.cardForm}>
                  <Typography className={classes.labelForm}>
                    Volve a ingresar tu contraseña de Sportmetric para firmar la carta de servicio.
                  </Typography>
                  <div className={classes.form}>
                    <TextField
                      name='username'
                      value={values.username}
                      onChange={handleChange}
                      placeholder='ejemplo@gmail.com'
                      onBlur={handleBlur}
                      label='E-mail o nombre de usuario'
                      helperText={errors.username}
                      error={touched.username && Boolean(errors.username)}
                      fullWidth
                      required
                      disabled={true}
                      variant='outlined'
                      size='small'
                      margin='normal'
                      className={classes.input}
                    />
                    <TextField
                      name='password'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Contraseña'
                      helperText={errors.password}
                      error={touched.password && Boolean(errors.password)}
                      fullWidth
                      required
                      variant='outlined'
                      size='small'
                      margin='normal'
                      className={classes.input}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(event) => event.preventDefault()}
                              edge='end'>
                              {showPassword ? (
                                <Visibility color='primary' />
                              ) : (
                                <VisibilityOff color='primary' />
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className={classes.buttonContainerSave}>
                    <Button
                      color='primary'
                      variant='contained'
                      type='submit'
                      endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                      disabled={!isValid || isSubmitting}>
                      Firmar
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </>
      )}
      {!formToSing && !firstContent && (
        <div className={classes.buttonContainer}>
          <Button
            variant='contained'
            color='primary'
            className={classes.createButton}
            onClick={handleConfirm}>
            Firmar
          </Button>
          {!firstContent && (
            <Typography className={classes.link} onClick={handleDownloadServiceLetterReport}>
              Descargar
            </Typography>
          )}
        </div>
      )}
    </SectionLayoutDialog>
  )
}

export default SignServiceLetterDialog
