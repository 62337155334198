import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('lg')]: {
      margin: '30px auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 1280
    }
  },
  sectionBlock: {
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    minHeight: 600,
    margin: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 600
    }
  },
  sectionHeaderContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    '& h6': {
      fontWeight: 600
    }
  },
  sectionBodyContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 6)
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '&.disabled': {
      '& h6': {
        color: 'rgba(0, 0, 0, 0.26)'
      }
    },
    '& h6': {
      fontSize: '.8rem'
    },
    '& input': {
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  radioButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    color: theme.palette.primary.main
  },
  radioRoot: {
    color: theme.palette.primary.main
  },
  createButton: {
    width: '50%',
    float: 'right',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: '40%'
    }
  },
  table: {
    width: '100%',
    paddingTop: theme.spacing(3)
  },

  col: {
    borderBottom: '0.5px solid #C8C8C8',
    width: '80%',
    color: '#969696'
  },
  ageSection: {
    '& > div ': { width: '45%' },
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  messageText: {
    margin: theme.spacing(5, 0),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left'
    }
  },
  deleteDialogContainer: {
    '& > div > div': {
      minHeight: 'auto'
    }
  }
}))
