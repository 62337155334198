import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 670,
      maxWidth: 670
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '90%',
      margin: '0 auto'
    }
  },
  radioContainer: {
    margin: theme.spacing(4, 0)
  },
  radio: {
    color: theme.palette.primary.main,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  label: {
    fontWeight: 600
  },
  acceptButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 10),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}))
