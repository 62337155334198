import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { SectionLayoutDialog } from 'shared'
import { verifyUserRole } from 'state/modules/app'
import { USER_ROLE } from 'utils/constants'

import { useStyles } from './RoleRequiredDialog.style'

const RoleRequiredDialog = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { userRole } = useSelector((state) => state.app)
  const { user } = useSelector((state) => state.auth)

  const [openRoleRequiredDialog, setOpenRoleRequiredDialog] = useState(false)

  useEffect(() => {
    if (userRole?.state) {
      setOpenRoleRequiredDialog(true)
    }
  }, [userRole])

  const handleOnClose = () => {
    if (userRole?.redirectUrl === location.pathname) {
      history.push(`${ROUTES.USERS_ACCOUNTS.PROFILE}/${user.id}`)
    }

    if (userRole?.state) {
      dispatch(verifyUserRole(null))
    }

    setOpenRoleRequiredDialog(false)
  }

  return (
    <SectionLayoutDialog
      open={openRoleRequiredDialog}
      onClose={handleOnClose}
      title={`ROL ${userRole?.role.label} REQUERIDO`.toUpperCase()}
      className={classes.dialog}>
      {userRole?.role === USER_ROLE.ORGANIZER && (
        <Typography className={classes.content}>
          Para poder realizar esta acción debes primero agregar en tu cuenta Sportmetric el rol
          organizador.
        </Typography>
      )}
    </SectionLayoutDialog>
  )
}

export default RoleRequiredDialog
