import FileDownload from 'js-file-download'
import OrganizationsProxy from 'services/OrganizationsProxy'

import * as types from './types'

// Action Creators
export const loadAttempt = () => ({
  type: types.LOAD_ATTEMPT
})

export const loadSuccess = (organization) => ({
  type: types.LOAD_SUCCESS,
  organization
})

export const loadFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})

export const createAttempt = () => ({
  type: types.CREATE_ATTEMPT
})

export const createSuccess = (organization) => ({
  type: types.CREATE_SUCCESS,
  organization
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  error
})

export const setImageAttempt = () => ({
  type: types.SET_IMAGE_ATTEMPT
})

export const setImageSuccess = (organization) => ({
  type: types.SET_IMAGE_SUCCESS,
  organization
})

export const setImageFailure = (error) => ({
  type: types.SET_IMAGE_FAILURE,
  error
})

export const searchAttempt = () => ({
  type: types.SEARCH_ATTEMPT
})

export const searchSuccess = (organizationsPage) => ({
  type: types.SEARCH_SUCCESS,
  organizationsPage
})

export const searchFailure = (error) => ({
  type: types.SEARCH_FAILURE,
  error
})

export const setSearchParams = (searchParams) => ({
  type: types.SET_SEARCH_PARAMS,
  searchParams
})

export const resetSearch = () => ({
  type: types.RESET_SEARCH
})

export const searchMetadataAttempt = () => ({
  type: types.SEARCH_METADATA_ATTEMPT
})

export const searchMetadataSuccess = (searchMetadata) => ({
  type: types.SEARCH_METADATA_SUCCESS,
  searchMetadata
})

export const searchMetadataFailure = (error) => ({
  type: types.SEARCH_METADATA_FAILURE,
  error
})

export const loadUpcomingEventsAttempt = () => ({
  type: types.LOAD_UPCOMING_EVENTS_ATTEMPT
})

export const loadUpcomingEventsSuccess = (upcomingEventsPage) => ({
  type: types.LOAD_UPCOMING_EVENTS_SUCCESS,
  upcomingEventsPage
})

export const loadUpcomingEventsFailure = (error) => ({
  type: types.LOAD_UPCOMING_EVENTS_FAILURE,
  error
})

export const loadPastEventsAttempt = () => ({
  type: types.LOAD_PAST_EVENTS_ATTEMPT
})

export const loadPastEventsSuccess = (pastEventsPage) => ({
  type: types.LOAD_PAST_EVENTS_SUCCESS,
  pastEventsPage
})

export const loadPastEventsFailure = (error) => ({
  type: types.LOAD_PAST_EVENTS_FAILURE,
  error
})

export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const updateSuccess = (organization) => ({
  type: types.UPDATE_SUCCESS,
  organization
})

export const loadReset = () => ({
  type: types.LOAD_RESET
})

export const followAttempt = () => ({
  type: types.FOLLOW_ATTEMPT
})

export const followSuccess = (organization) => ({
  type: types.FOLLOW_SUCCESS,
  organization
})

export const followFailure = (error) => ({
  type: types.FOLLOW_FAILURE,
  error
})

export const loadUnPublishedEventsAttempt = () => ({
  type: types.LOAD_UN_PUBLISHED_EVENTS_ATTEMPT
})

export const loadUnPublishedEventsSuccess = (unPublishedEventsPage) => ({
  type: types.LOAD_UN_PUBLISHED_EVENTS_SUCCESS,
  unPublishedEventsPage
})

export const loadUnPublishedEventsFailure = (error) => ({
  type: types.LOAD_UN_PUBLISHED_EVENTS_FAILURE,
  error
})

export const deleteImageAttempt = () => ({
  type: types.DELETE_IMAGE_ATTEMPT
})

export const deleteImageSuccess = (organization) => ({
  type: types.DELETE_IMAGE_SUCCESS,
  organization
})

export const deleteImageFailure = (error) => ({
  type: types.DELETE_IMAGE_FAILURE,
  error
})

export const resetSearchParams = () => ({
  type: types.RESET_SEARCH_PARAMS
})

export const loadCustomFieldsAttempt = () => ({
  type: types.LOAD_CUSTOM_FIELDS_ATTEMPT
})

export const loadCustomFieldsSuccess = (customFields) => ({
  type: types.LOAD_CUSTOM_FIELDS_SUCCESS,
  customFields
})

export const loadCustomFieldsFailure = (error) => ({
  type: types.LOAD_CUSTOM_FIELDS_FAILURE,
  error
})

export const createCustomFieldAttempt = () => ({
  type: types.CREATE_CUSTOM_FIELD_ATTEMPT
})

export const createCustomFieldSuccess = (customField) => ({
  type: types.CREATE_CUSTOM_FIELD_SUCCESS
})

export const createCustomFieldFailure = (error) => ({
  type: types.CREATE_CUSTOM_FIELD_FAILURE,
  error
})

export const updateCustomFieldAttempt = () => ({
  type: types.UPDATE_CUSTOM_FIELD_ATTEMPT
})

export const updateCustomFieldSuccess = (customField) => ({
  type: types.UPDATE_CUSTOM_FIELD_SUCCESS
})

export const updateCustomFieldFailure = (error) => ({
  type: types.UPDATE_CUSTOM_FIELD_FAILURE,
  error
})

export const deleteCustomFieldAttempt = () => ({
  type: types.DELETE_CUSTOM_FIELD_ATTEMPT
})

export const deleteCustomFieldSuccess = () => ({
  type: types.DELETE_CUSTOM_FIELD_SUCCESS
})

export const deleteCustomFieldFailure = (error) => ({
  type: types.DELETE_CUSTOM_FIELD_FAILURE,
  error
})

export const loadOrganizationsCloneSuggestionsAttempt = () => ({
  type: types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_ATTEMPT
})
export const loadOrganizationsCloneSuggestionsSuccess = (organizationsCloneSuggestions) => ({
  type: types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_SUCCESS,
  organizationsCloneSuggestions
})
export const loadOrganizationsCloneSuggestionsFailure = (error) => ({
  type: types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_FAILURE,
  error
})

export const acceptAdminUserAttempt = () => ({
  type: types.ACCEPT_ADMIN_USER_ATTEMPT
})
export const acceptAdminUserSuccess = (state) => ({
  type: types.ACCEPT_ADMIN_USER_SUCCESS,
  state
})
export const acceptAdminUserFailure = (error) => ({
  type: types.ACCEPT_ADMIN_USER_FAILURE,
  error
})

export const rejectAdminUserAttempt = () => ({
  type: types.REJECT_ADMIN_USER_ATTEMPT
})
export const rejectAdminUserSuccess = (state) => ({
  type: types.REJECT_ADMIN_USER_SUCCESS,
  state
})
export const rejectAdminUserFailure = (error) => ({
  type: types.REJECT_ADMIN_USER_FAILURE,
  error
})

export const sendOnboardingInformationAttempt = () => ({
  type: types.SEND_ONBOARDING_INFORMATION_ATTEMPT
})
export const sendOnboardingInformationSuccess = (data) => ({
  type: types.SEND_ONBOARDING_INFORMATION_SUCCESS,
  data
})
export const sendOnboardingInformationFailure = (error) => ({
  type: types.SEND_ONBOARDING_INFORMATION_FAILURE,
  error
})

export const loadServiceLetterReportAttempt = () => ({
  type: types.LOAD_SERVICE_LETTER_REPORT_ATTEMPT
})
export const loadServiceLetterReportSuccess = (data) => ({
  type: types.LOAD_SERVICE_LETTER_REPORT_SUCCESS,
  data
})
export const loadServiceLetterReportFailure = (error) => ({
  type: types.LOAD_SERVICE_LETTER_REPORT_FAILURE,
  error
})

export const createServiceLetterReportAttempt = () => ({
  type: types.CREATE_SERVICE_LETTER_REPORT_ATTEMPT
})
export const createServiceLetterReportSuccess = (data) => ({
  type: types.CREATE_SERVICE_LETTER_REPORT_SUCCESS,
  data
})
export const createServiceLetterReportFailure = (error) => ({
  type: types.CREATE_SERVICE_LETTER_REPORT_FAILURE,
  error
})

export const downloadServiceLetterReportAttempt = () => ({
  type: types.DOWNLOAD_SERVICE_LETTER_REPORT_ATTEMPT
})
export const downloadServiceLetterReportSuccess = () => ({
  type: types.DOWNLOAD_SERVICE_LETTER_REPORT_SUCCESS
})
export const downloadServiceLetterReportFailure = (error) => ({
  type: types.DOWNLOAD_SERVICE_LETTER_REPORT_FAILURE,
  error
})

export const loadActiveServiceLetterReportAttempt = () => ({
  type: types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_ATTEMPT
})
export const loadActiveServiceLetterReportSuccess = () => ({
  type: types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_SUCCESS
})
export const loadActiveServiceLetterReportFailure = (error) => ({
  type: types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_FAILURE,
  error
})

export const resetValue = (value) => ({
  type: types.RESET_VALUE,
  value
})

// Thunks
export const load = (id) => async (dispatch) => {
  try {
    dispatch(loadAttempt())

    const proxy = new OrganizationsProxy()
    const organization = await proxy.get(id)
    dispatch(loadSuccess(organization))
    return organization
  } catch (error) {
    dispatch(loadFailure(error))
    return false
  }
}

export const create = (organizationData) => async (dispatch) => {
  try {
    dispatch(createAttempt())

    const proxy = new OrganizationsProxy()
    const organization = await proxy.post(organizationData)

    dispatch(createSuccess(organization))
    return true
  } catch (error) {
    dispatch(createFailure(error))
    throw error
  }
}

export const setImage = (id, file, type) => async (dispatch) => {
  try {
    dispatch(setImageAttempt())

    const proxy = new OrganizationsProxy()
    const organization = await proxy.putImage(id, file, type)
    dispatch(setImageSuccess(organization))
    return true
  } catch (error) {
    dispatch(setImageFailure(error))
    return false
  }
}

export const search =
  (searchParams, pageSize = 12, orderBy = 'id', order = 'desc') =>
  async (dispatch, getState) => {
    try {
      const { page } = searchParams

      dispatch(setSearchParams(searchParams))
      const { searchOrganizationsPageNumber } = getState().organizations
      if (page !== 1 && searchOrganizationsPageNumber === page) return true

      dispatch(searchAttempt())

      const proxy = new OrganizationsProxy()
      const organizationsPage = await proxy.getSearch(searchParams, page, pageSize, orderBy, order)
      if (page === 1) {
        dispatch(resetSearch())
      }
      dispatch(searchSuccess(organizationsPage))
      return true
    } catch (error) {
      dispatch(searchFailure(error))
      return false
    }
  }

export const loadSearchMetadata = (searchParams) => async (dispatch) => {
  try {
    dispatch(searchMetadataAttempt())
    const proxy = new OrganizationsProxy()
    const searchMetadata = await proxy.getSearchMetadata(searchParams)
    dispatch(searchMetadataSuccess(searchMetadata))
  } catch (error) {
    dispatch(searchMetadataFailure(error))
  }
}

export const loadUpcomingEvents =
  (organizationId, page = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      dispatch(loadUpcomingEventsAttempt())
      const proxy = new OrganizationsProxy()
      const upcomingEventsPage = await proxy.getEvents(organizationId, 'Upcoming', page, pageSize)

      dispatch(loadUpcomingEventsSuccess(upcomingEventsPage))
    } catch (error) {
      dispatch(loadUpcomingEventsFailure(error))
    }
  }

export const loadPastEvents =
  (organizationId, page = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      dispatch(loadPastEventsAttempt())

      const proxy = new OrganizationsProxy()
      const pastEventsPage = await proxy.getEvents(organizationId, 'Past', page, pageSize)

      dispatch(loadPastEventsSuccess(pastEventsPage))
    } catch (error) {
      dispatch(loadPastEventsFailure(error))
    }
  }

export const updateOrganization = (organization, field) => async (dispatch) => {
  try {
    const { id } = organization
    dispatch(updateAttempt())
    const proxy = new OrganizationsProxy()
    const data = await proxy.put(id, organization)
    dispatch(updateSuccess(data))
    return data
  } catch (error) {
    error.response.data.field = field
    error.response.data[field] = organization[field]
    dispatch(updateFailure(error))
  }
}

export const follow = (organization) => async (dispatch) => {
  try {
    const {
      id,
      loggedUserMetadata: { isFollowing }
    } = organization
    dispatch(followAttempt())
    const proxy = new OrganizationsProxy()
    if (isFollowing) {
      await proxy.unFollow(id)
      organization.followersCount--
    } else {
      await proxy.follow(id)
      organization.followersCount++
    }
    organization.loggedUserMetadata.isFollowing = !isFollowing
    dispatch(followSuccess(organization))
  } catch (error) {
    dispatch(followFailure(error))
  }
}

export const loadUnPublishedEvents =
  (id, page = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      dispatch(loadUnPublishedEventsAttempt())
      const proxy = new OrganizationsProxy()
      const unPublishedEventsPage = await proxy.getUnPublishedEvents(id, page, pageSize)
      dispatch(loadUnPublishedEventsSuccess(unPublishedEventsPage))
    } catch (error) {
      dispatch(loadUnPublishedEventsFailure(error))
    }
  }
export const deleteImage = (organization, type) => async (dispatch) => {
  try {
    const { id } = organization
    dispatch(deleteImageAttempt())
    const proxy = new OrganizationsProxy()
    await proxy.deleteImage(id, type)
    organization[`${type}Images`] = null
    dispatch(deleteImageSuccess(organization))
  } catch (error) {
    dispatch(deleteImageFailure(error))
  }
}

export const loadCustomFields = (id) => async (dispatch) => {
  try {
    dispatch(loadCustomFieldsAttempt())
    const proxy = new OrganizationsProxy()
    const customFields = await proxy.getCustomFields(id)
    dispatch(loadCustomFieldsSuccess(customFields))
  } catch (error) {
    dispatch(loadCustomFieldsFailure(error))
  }
}

export const createCustomField = (organizationId, customField) => async (dispatch) => {
  try {
    dispatch(createCustomFieldAttempt())
    const proxy = new OrganizationsProxy()
    const data = await proxy.postCustomField(organizationId, customField)
    dispatch(createCustomFieldSuccess(data))
    return data
  } catch (error) {
    dispatch(createCustomFieldFailure({ name: customField.name }))
  }
}

export const updateCustomField = (organizationId, customField) => async (dispatch) => {
  try {
    dispatch(updateCustomFieldAttempt())
    const proxy = new OrganizationsProxy()
    await proxy.putCustomField(organizationId, customField)
    dispatch(updateCustomFieldSuccess())
    return true
  } catch (error) {
    dispatch(updateCustomFieldFailure({ name: customField.name }))
  }
}

export const deleteCustomField = (organizationId, customFieldId) => async (dispatch) => {
  try {
    dispatch(deleteCustomFieldAttempt())
    const proxy = new OrganizationsProxy()
    await proxy.deleteCustomField(organizationId, customFieldId)
    dispatch(deleteCustomFieldSuccess())
    return true
  } catch (error) {
    dispatch(deleteCustomFieldFailure(error))
  }
}

export const loadOrganizationsCloneSuggestions = () => async (dispatch) => {
  try {
    dispatch(loadOrganizationsCloneSuggestionsAttempt())
    const proxy = new OrganizationsProxy()
    const data = await proxy.getOrganizationsCategoriesCloneSuggestions()

    dispatch(loadOrganizationsCloneSuggestionsSuccess(data))
    return data
  } catch (error) {
    dispatch(loadOrganizationsCloneSuggestionsFailure(error))
  }
}

export const acceptAdminUser = (id) => async (dispatch) => {
  try {
    dispatch(acceptAdminUserAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.acceptAdminUser(id)

    dispatch(acceptAdminUserSuccess(data))

    return data
  } catch (error) {
    dispatch(acceptAdminUserFailure(error))
  }
}

export const rejectAdminUser = (id) => async (dispatch) => {
  try {
    dispatch(rejectAdminUserAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.rejectAdminUser(id)

    dispatch(rejectAdminUserSuccess(data))

    return data
  } catch (error) {
    dispatch(rejectAdminUserFailure(error))
  }
}

export const sendOnboardingInformation = (id, onboardingInformation) => async (dispatch) => {
  try {
    dispatch(sendOnboardingInformationAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.sendOnboardingInformation(id, onboardingInformation)
    dispatch(sendOnboardingInformationSuccess(data))

    return data
  } catch (error) {
    dispatch(sendOnboardingInformationFailure(error))
  }
}

export const loadServiceLetterReport = (id) => async (dispatch) => {
  try {
    dispatch(loadServiceLetterReportAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.getServiceLetterReport(id)
    dispatch(loadServiceLetterReportSuccess(data))
    return data
  } catch (error) {
    dispatch(loadServiceLetterReportFailure(error))
  }
}

export const createServiceLetterReport = (id, values) => async (dispatch) => {
  try {
    dispatch(createServiceLetterReportAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.postOrganizationContract(id, values)
    dispatch(createServiceLetterReportSuccess(data))
    return data
  } catch (error) {
    dispatch(createServiceLetterReportFailure(error))
  }
}

export const downloadServiceLetterReport = (id) => async (dispatch) => {
  try {
    dispatch(downloadServiceLetterReportAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.getServiceLetterReport(id)
    FileDownload(data, `${id}_service_letter_report.pdf`)
    dispatch(downloadServiceLetterReportSuccess())
    return data
  } catch (error) {
    dispatch(downloadServiceLetterReportFailure(error))
  }
}

export const loadActiveServiceLetterReport = (id) => async (dispatch) => {
  try {
    dispatch(loadActiveServiceLetterReportAttempt())

    const proxy = new OrganizationsProxy()
    const data = await proxy.getActiveServiceLetterReport(id)

    dispatch(loadActiveServiceLetterReportSuccess(data))
    return data
  } catch (error) {
    dispatch(loadActiveServiceLetterReportFailure(error))
  }
}
