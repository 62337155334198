import React, { useState } from 'react'
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import debounce from 'lodash.debounce'
import { OrganizationsProxy } from 'services'
import { EditableSection } from 'shared'
import { showSnackbarError } from 'utils/snackbar'

import { OrganizationWidget } from '../OrganizationWidget'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './OrganizationsSection.style'

const OrganizationsSection = ({
  event,
  eventOwner,
  afterEvent,
  hasEventResults,
  isAdmin,
  onSave,
  canEdit,
  ...sectionProps
}) => {
  const classes = useStyles()

  const { organizations, isSuspended } = event

  const [organizationOptions, setOrganizationOptions] = useState([])
  const [organizationsPending, setOrganizationsPending] = useState(false)

  const organizationList = organizations
    .filter((e) => !e.rejectedAt && e.organization.id !== eventOwner.id)
    .filter((e) => isAdmin || e.organization.loggedUserMetadata?.canEdit || !!e.acceptedAt)
    .sort((x, y) => (!x.acceptedAt || !y.acceptedAt ? -1 : x.acceptedAt - y.acceptedAt))

  const fetchOrganizations = async (searchText = '', setFieldValue) => {
    setFieldValue('usernameOrEmail', searchText)

    if (!searchText || searchText.length < 3) {
      setOrganizationOptions([])
      return
    }

    try {
      setOrganizationsPending(true)

      const proxy = new OrganizationsProxy()
      const data = await proxy.getEventOrganizationSuggestions(searchText, 1, 20)

      setOrganizationOptions(data.results)

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOrganizationsPending(false)
    }
  }

  const onOrganizationsChange = (setFieldValue) => (options) =>
    setFieldValue('organizations', options)

  const debounceFetchOrganizations = (inputValue, setFieldValue) =>
    fetchOrganizations(inputValue, setFieldValue)

  const debounceOnChange = debounce(debounceFetchOrganizations, 700)

  const handleSaveOrganizations = async (values) => {
    const organizations = [
      { organization: values.value.organization },
      ...values.value.organizations.map((organization) => ({ organization }))
    ]

    await onSave({ value: { organizations } })
    return true
  }

  return (
    <EditableSection
      entity={{
        ...event,
        organizations: organizationList.map((o) => ({
          ...o,
          id: o.organization.id,
          name: o.organization.name
        }))
      }}
      onSave={handleSaveOrganizations}
      {...sectionProps}>
      {({
        activeEdit,
        values,
        setFieldValue,
        setActiveEdit,
        touched,
        errors,
        saveLoading,
        handleSubmit
      }) => (
        <SectionBlock title={!isAdmin ? 'Organizado por' : 'Organiza'}>
          <OrganizationWidget organization={event.organization} />

          {!activeEdit ? (
            <div className={classes.organizationsContainer}>
              {organizationList.map(({ organization, acceptedAt }) => (
                <OrganizationWidget
                  key={organization.id}
                  organization={organization}
                  pendingInvitation={!isSuspended && !acceptedAt}
                  pendingConfirm={eventOwner.loggedUserMetadata?.canEdit && !acceptedAt}
                />
              ))}

              {canEdit && (
                <Typography
                  color='primary'
                  variant='h6'
                  className={classes.link}
                  onClick={() => setActiveEdit(true)}>
                  + Añadir organización
                </Typography>
              )}
            </div>
          ) : (
            <div className={classes.container}>
              <FormControl variant='outlined' className={classes.formControl}>
                <Autocomplete
                  multiple
                  id='organization-autocomplete'
                  options={organizationOptions.filter(
                    (option) => option.id !== values.organization.id
                  )}
                  getOptionLabel={(option) => option.handle || option.name}
                  selectOnFocus
                  filterSelectedOptions
                  onInputChange={(e) => debounceOnChange(e?.target.value, setFieldValue)}
                  onChange={(event, optionSelected) =>
                    onOrganizationsChange(setFieldValue)(optionSelected)
                  }
                  filterOptions={(x) => x}
                  clearText='Quitar todo'
                  openText='Abrir desplegable'
                  closeText='Cerrar desplegable'
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.handle || option.name}
                        key={option.id}
                        title={option.handle || option.name}
                        {...getTagProps({ index })}
                        color='primary'
                        className={classes.option}
                      />
                    ))
                  }
                  getOptionSelected={(option) =>
                    values.organizations.some((o) => o.id === option.id)
                  }
                  value={values.organizations}
                  className={classes.combo}
                  loadingText={<Typography align='center'> Cargando...</Typography>}
                  noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.comboInput}
                      name='organizations'
                      label='Nombre o alias de la organización*'
                      variant='outlined'
                      error={touched.organizations && Boolean(errors.organizations)}
                      helperText={
                        errors.organizations || 'Escriba nombre o alias del co-organizador.'
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: organizationsPending ? (
                          <CircularProgress color='primary' size={16} />
                        ) : (
                          params.InputProps.endAdornment
                        )
                      }}
                    />
                  )}
                />
              </FormControl>
              <Button
                variant='contained'
                color='primary'
                className={classes.saveButton}
                disabled={saveLoading}
                onClick={handleSubmit}
                endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
                type='submit'>
                Guardar
              </Button>
            </div>
          )}
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default OrganizationsSection
