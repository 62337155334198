import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  eventinputLink: {
    paddingBottom: theme.spacing(2),
    width: '100%',
    '& input': {
      height: 19,
      color: '#4285F4',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  eventLink: {
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 600,
    color: 'rgba(66, 133, 244, 1)',
    overflow: 'hidden',
    textOverflow: 'Ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('lg')]: {
      width: 370
    },
    '&.text': {
      fontWeight: 400,
      color: theme.palette.secondary.contrastText
    }
  }
}))
