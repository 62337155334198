import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600
  },
  infoContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textInitial: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center'
  },
  butonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    '& button': {
      borderRadius: 10,
      width: 180,
      marginTop: 10,
      marginBottom: 20,
      height: 40
    }
  },
  labelRadio: {
    color: theme.palette.primary.main,
    fontWeight: 700
  }
}))
