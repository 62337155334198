import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 300,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: 200
    }
  },
  date: {
    width: '10%',
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: '6%'
    }
  },
  body: {
    width: '30%',
    height: 7
  },
  position: {
    width: '25%',
    height: 45,
    [theme.breakpoints.up('sm')]: {
      width: '10%'
    }
  }
}))
