import React from 'react'
import { Snackbar } from '@material-ui/core'
import clsx from 'clsx'
import { Transition } from 'utils'

import { useStyles } from './SnackbarMessage.style'

const SnackbarMessage = ({ open, onClose, className, message }) => {
  const classes = useStyles()
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      className={clsx(classes.snackbar, className)}
      autoHideDuration={3000}
      TransitionComponent={Transition}>
      <div className={classes.container}>
        <div className={classes.mainContainer}>{message}</div>
      </div>
    </Snackbar>
  )
}

export default SnackbarMessage
