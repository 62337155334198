import * as types from './types'

// InitialState
const initialState = {
  event: null,
  pending: false,

  searchParams: {
    searchText: '',
    from: null,
    to: null,
    withResultsOnly: false,
    page: 1
  },
  searchEvents: [],
  searchEventsPageNumber: 1,
  searchEventsLastPage: false,

  searchMetadataPending: false,
  searchMetadata: null,

  settingImage: false,

  eventResults: null,
  eventResultsPending: false,

  updateEventPending: false,
  updateEventError: null,

  uploadResultsPending: false,
  downloadResultsTemplatePending: false,
  deleteResultsPending: false,
  pendingReact: false,
  deletePending: false,
  updateCalendarPending: false,
  error: null,
  deleteImagePending: false,
  togglePublishedPending: false,
  downloadResultsSheetPending: false,
  downloadResultsReportPending: false,

  downloadInscriptionsInsurancePending: false,
  downloadInscriptionsRunnersPending: false,
  downloadInscriptionsAcceptedPending: false,

  lastResultPageNumber: 1,
  lastResultLastPage: false,
  lastResultEvents: [],
  lastResultEventsPending: false,

  lastResultEventsAll: [],
  lastResultAllLastPage: false,
  lastResultventsAllPending: false,
  upcomingPageNumber: 1,

  upcomingLastPage: false,
  upcomingEvents: [],
  upcomingEventsAll: [],
  upcomingEventsPending: false,

  createdEvents: [],
  createdLastPage: false,
  createdEventsPending: false,
  createdPageNumber: 1,

  createdEventsAll: [],
  createdEventsAllPending: false,
  createdAllLastPage: false,
  createdEventsAllPageNumber: 1,

  activeInscriptionForm: null,
  activeInscriptionFormPending: false,
  activeInscriptionFormError: null,

  createInscriptionFormsPending: false,
  createInscriptionFormsError: null,

  updateInscriptionFormsPending: false,
  updateInscriptionFormsError: null,

  deleteInscriptionFormsPending: false,
  deleteInscriptionFormsError: null,

  eventInscriptionPending: false,
  eventInscription: null,
  eventInscriptionError: null,

  listInscriptionPending: false,
  listInscriptionError: null,
  listInscription: [],
  listInscriptionPageCount: 1,
  listInscriptionPageCurrentPage: 1,

  deleteListInscriptionPending: false,
  deleteListInscriptionError: null,

  listInscriptionSearchMetadataPending: false,
  listInscriptionSearchMetadataError: null,
  listInscriptionSearchMetadata: {
    stateItems: [],
    orderStateItems: []
  },

  uploadRulesDocumentPending: false,
  uploadRulesDocumentError: null,

  downloadRulesDocumentPending: false,
  downloadRulesDocumentError: null,

  deleteRulesDocumentPending: false,
  deleteRulesDocumentError: null,

  updateEventInscriptionPending: false,
  updateEventInscriptionError: null,

  eventInscriptionsForCurrentUser: null,
  eventInscriptionsForCurrentUserPending: false,
  eventInscriptionsForCurrentUserError: null,

  eventInscriptionAllForUser: [],
  eventInscriptionAllForUserPageNumber: 1,
  eventInscriptionAllForUserPageLastPage: false,
  eventInscriptionAllForUserPending: false,
  eventInscriptionAllForUserError: null,

  downloadInscriptionsSheetPending: false,
  downloadInscriptionsSheetError: null,

  acceptOrganizationPending: false,
  acceptOrganizationError: null,

  rejectOrganizationPending: false,
  rejectOrganizationError: null,

  inscriptionFormsCloneSuggestions: [],
  inscriptionFormsCloneSuggestionsPending: false,
  inscriptionFormsCloneSuggestionsError: null,
  inscriptionFormsCloneSuggestionsPageNumber: 1,
  inscriptionFormsCloneSuggestionsLastPage: false,

  categoriesCloneSuggestions: [],
  categoriesCloneSuggestionsPending: false,
  categoriesCloneSuggestionsError: null,

  categories: [],
  categoriesPending: false,
  categoriesError: null,

  createCategoryPending: false,
  createCategoryError: null,

  updateCategoryPending: false,
  updateCategoryError: null,

  deleteCategoryPending: false,
  deleteCategoryError: null,

  distances: [],
  distancesPending: false,
  distancesError: null,

  createDistancePending: false,
  createDistanceError: null,

  updateDistancePending: false,
  updateDistanceError: null,

  deleteDistancePending: false,
  deleteDistanceError: null,

  sendMercadoPagoAuthorizationPending: false,
  sendMercadoPagoAuthorizationError: null,

  mercadoPagoAuthorization: null,
  mercadoPagoAuthorizationPending: false,
  mercadoPagoAuthorizationError: null,

  deleteMercadoPagoAuthorizationPending: false,
  deleteMercadoPagoAuthorizationError: null,

  eventBankAccounts: [],
  eventBankAccountsPending: false,
  eventBankAccountsError: null,

  createEventBankAccountPending: false,
  createEventBankAccountError: null,

  updateEventBankAccountPending: false,
  updateEventBankAccountError: null,

  deleteEventBankAccountPending: false,
  deleteEventBankAccountError: null,

  paymentLocations: [],
  paymentLocationsPending: false,
  paymentLocationsError: null,

  createPaymentLocationPending: false,
  createPaymentLocationError: null,

  updatePaymentLocationPending: false,
  updatePaymentLocationError: null,

  deletePaymentLocationPending: false,
  deletePaymentLocationError: null,

  banks: [],
  banksPending: false,
  banksError: null,

  paymentParams: {
    mercadoPagoSelected: false,
    paymentCashSelected: false,
    paymentBankSelected: false,
    paymentUrlSelected: false
  },

  activePaymentLocations: [],
  activePaymentLocationsPending: false,
  activePaymentLocationsError: null,

  activeBankAccounts: [],
  activeBankAccountsPending: false,
  activeBankAccountsError: null,

  ticketTypeFees: [],
  ticketTypeFeesPending: false,
  ticketTypeFeesError: null,

  publicUpdateEventInscriptionPending: false,
  publicUpdateEventInscriptionError: null,

  cancelledEventInscription: null,
  cancelledEventInscriptionError: null,
  cancelledEventInscriptionPending: false,

  deleteSaleOrderPaymentPending: false,
  deleteSaleOrderPaymentError: null,

  updateSaleOrderPaymentPending: false,
  updateSaleOrderPaymentError: null,

  eventDiscountCodes: [],
  eventDiscountCodesPending: false,
  eventDiscountCodesError: null,

  createEventDiscountCodePending: false,
  createEventDiscountCodeError: null,

  updateEventDiscountCodePending: false,
  updateEventDiscountCodeError: null,

  deleteEventDiscountCodePending: false,
  deleteEventDiscountCodeError: null,

  eventResultsReportUrl: null,
  eventResultsReportUrlError: null,
  eventResultsReportUrlPending: false,

  downloadEventCategoriesPending: false,
  downloadEventCategoriesError: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        event: null,
        error: null,
        pending: true
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        event: action.event,
        pending: false
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        event: null,
        pending: false,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        event: action.event,
        pending: false
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.SET_IMAGE_ATTEMPT:
      return {
        ...state,
        pending: true,
        error: null,
        settingImage: true
      }
    case types.SET_IMAGE_SUCCESS:
      return {
        ...state,
        settingImage: false,
        pending: false,
        event: action.event
      }
    case types.SET_IMAGE_FAILURE:
      return {
        ...state,
        pending: false,
        settingImage: false,
        error: action.error
      }
    case types.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.searchParams
      }
    case types.SEARCH_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        searchEvents: [...state.searchEvents, ...action.eventsPage.results],
        searchEventsPageNumber: action.eventsPage.currentPage,
        searchEventsLastPage: action.eventsPage.pageCount <= action.eventsPage.currentPage,
        pending: false
      }
    case types.SEARCH_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.RESET_SEARCH:
      return {
        ...state,
        pending: false,
        error: null,
        searchEvents: [],
        searchEventsPageNumber: 1
      }
    case types.RESET_VALUE:
      return {
        ...state,
        [action.value]: initialState[action.value]
      }
    case types.SEARCH_METADATA_ATTEMPT:
      return {
        ...state,
        searchMetadataPending: true
      }
    case types.SEARCH_METADATA_SUCCESS:
      return {
        ...state,
        searchMetadataPending: false,
        searchMetadata: action.searchMetadata
      }
    case types.SEARCH_METADATA_FAILURE:
      return {
        ...state,
        searchMetadataPending: false,
        error: action.error
      }
    case types.LOAD_RESULTS_ATTEMPT:
      return {
        ...state,
        eventResults: null,
        error: null,
        eventResultsPending: true
      }
    case types.LOAD_RESULTS_SUCCESS:
      return {
        ...state,
        eventResults: action.eventResults,
        eventResultsPending: false
      }
    case types.LOAD_RESULTS_FAILURE:
      return {
        ...state,
        eventResultsPending: false,
        error: action.error
      }
    case types.UPLOAD_RESULTS_ATTEMPT:
      return {
        ...state,
        error: null,
        uploadResultsPending: true
      }
    case types.UPLOAD_RESULTS_SUCCESS:
      return {
        ...state,
        uploadResultsPending: false
      }
    case types.UPLOAD_RESULTS_FAILURE:
      return {
        ...state,
        uploadResultsPending: false,
        error: action.error
      }
    case types.DELETE_RESULTS_ATTEMPT:
      return {
        ...state,
        error: null,
        deleteResultsPending: true
      }
    case types.DELETE_RESULTS_SUCCESS:
      return {
        ...state,
        deleteResultsPending: false
      }
    case types.DELETE_RESULTS_FAILURE:
      return {
        ...state,
        deleteResultsPending: false,
        error: action.error
      }
    case types.SUSPEND_ATTEMPT:
      return {
        ...state,
        event: null,
        error: null,
        pending: true
      }
    case types.SUSPEND_SUCCESS:
      return {
        ...state,
        event: action.event,
        pending: false
      }
    case types.SUSPEND_FAILURE:
      return {
        ...state,
        event: null,
        pending: false,
        error: action.error
      }
    case types.DOWNLOAD_RESULTS_TEMPLATE_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadResultsTemplatePending: true
      }
    case types.DOWNLOAD_RESULTS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadResultsTemplatePending: false
      }
    case types.DOWNLOAD_RESULTS_TEMPLATE_FAILURE:
      return {
        ...state,
        downloadResultsTemplatePending: false,
        error: action.error
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        updateEventPending: true
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        updateEventPending: false,
        error: action.error
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        updateEventPending: false,
        event: action.event
      }
    case types.EVENT_RESET:
      return {
        ...state,
        eventResults: null,
        event: null,
        error: null
      }
    case types.REACT_ATTEMPT:
      return {
        ...state,
        pendingReact: true
      }
    case types.REACT_FAILURE:
      return {
        ...state,
        error: action.error,
        pendingReact: false
      }
    case types.REACT_SUCCESS:
      return {
        ...state,
        event: action.event,
        pendingReact: false
      }
    case types.DELETE_ATTEMPT:
      return {
        ...state,
        deletePending: true
      }
    case types.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false
      }
    case types.DELETE_SUCCESS:
      return {
        ...state,
        event: null,
        deletePending: false
      }
    case types.UPDATE_CALENDAR_ATTEMPT:
      return {
        ...state,
        updateCalendarPending: true
      }
    case types.UPDATE_CALENDAR_FAILURE:
      return {
        ...state,
        error: action.error,
        updateCalendarPending: false
      }
    case types.UPDATE_CALENDAR_SUCCESS:
      return {
        ...state,
        event: action.event,
        updateCalendarPending: false
      }
    case types.TOGGLE_PUBLISHED_ATTEMPT:
      return {
        ...state,
        togglePublishedPending: true
      }
    case types.TOGGLE_PUBLISHED_SUCCESS:
      return {
        ...state,
        event: action.event,
        togglePublishedPending: false
      }
    case types.TOGGLE_PUBLISHED_FAILURE:
      return {
        ...state,
        error: action.error,
        togglePublishedPending: false
      }
    case types.DELETE_IMAGE_ATTEMPT:
      return {
        ...state,
        deleteImagePending: true
      }
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        event: action.event,
        deleteImagePending: false
      }
    case types.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        deleteImagePending: false
      }
    case types.RESET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          searchText: '',
          from: null,
          to: null,
          withResultsOnly: false,
          page: 1
        },
        searchEvents: [],
        searchEventsLastPage: false,
        searchEventsPageNumber: 1
      }
    case types.DOWNLOAD_RESULTS_SHEET_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadResultsSheetPending: true
      }
    case types.DOWNLOAD_RESULTS_SHEET_SUCCESS:
      return {
        ...state,
        downloadResultsSheetPending: false
      }
    case types.DOWNLOAD_RESULTS_SHEET_FAILURE:
      return {
        ...state,
        downloadResultsSheetPending: false,
        error: action.error
      }
    case types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadInscriptionsInsurancePending: true
      }
    case types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_SUCCESS:
      return {
        ...state,
        downloadInscriptionsInsurancePending: false
      }
    case types.DOWNLOAD_INSCRIPTIONS_INSURANCE_SHEET_FAILURE:
      return {
        ...state,
        downloadInscriptionsInsurancePending: false,
        error: action.error
      }
    case types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadInscriptionsRunnersPending: true
      }
    case types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_SUCCESS:
      return {
        ...state,
        downloadInscriptionsRunnersPending: false
      }
    case types.DOWNLOAD_INSCRIPTIONS_RUNNERS_SHEET_FAILURE:
      return {
        ...state,
        downloadInscriptionsRunnersPending: false,
        error: action.error
      }
    case types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadInscriptionsAcceptedPending: true
      }
    case types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_SUCCESS:
      return {
        ...state,
        downloadInscriptionsAcceptedPending: false
      }
    case types.DOWNLOAD_INSCRIPTIONS_ACCEPTED_SHEET_FAILURE:
      return {
        ...state,
        downloadInscriptionsAcceptedPending: false,
        error: action.error
      }
    case types.DOWNLOAD_RESULTS_REPORT_ATTEMPT:
      return {
        ...state,
        error: null,
        downloadResultsReportPending: true
      }
    case types.DOWNLOAD_RESULTS_REPORT_SUCCESS:
      return {
        ...state,
        downloadResultsReportPending: false
      }
    case types.DOWNLOAD_RESULTS_REPORT_FAILURE:
      return {
        ...state,
        downloadResultsReportPending: false,
        error: action.error
      }
    case types.LOAD_LAST_RESULT_FOR_ME_ATTEMPT:
      return {
        ...state,
        lastResultEventsPending: true
      }
    case types.LOAD_LAST_RESULT_FOR_ME_SUCCESS:
      return {
        ...state,
        lastResultEvents: [...state.lastResultEvents, ...action.lastResultEvents.results],
        lastResultPageNumber: action.lastResultEvents.currentPage + 1,
        lastResultLastPage:
          action.lastResultEvents.pageCount <= action.lastResultEvents.currentPage,
        lastResultEventsPending: false
      }
    case types.LOAD_LAST_RESULT_FOR_ME_FAILURE:
      return {
        ...state,
        lastResultEvents: [],
        lastResultEventsPending: false
      }
    case types.RESET_LAST_RESULT_FOR_ME:
      return {
        ...state,
        lastResultEvents: [],
        lastResultPageNumber: 1,
        lastResultLastPage: false
      }
    case types.LOAD_UPCOMING_FOR_ME_ATTEMPT:
      return {
        ...state,
        upcomingEventsPending: true
      }
    case types.LOAD_UPCOMING_FOR_ME_SUCCESS:
      return {
        ...state,
        upcomingEvents: [...state.upcomingEvents, ...action.upcomingEvents.results],
        upcomingPageNumber: action.upcomingEvents.currentPage + 1,
        upcomingLastPage: action.upcomingEvents.pageCount <= action.upcomingEvents.currentPage,
        upcomingEventsPending: false
      }
    case types.LOAD_UPCOMING_FOR_ME_FAILURE:
      return {
        ...state,
        upcomingEvents: [],
        upcomingEventsPending: false
      }
    case types.RESET_UPCOMING_FOR_ME:
      return {
        ...state,
        upcomingEvents: [],
        upcomingPageNumber: 1,
        upcomingLastPage: false
      }
    case types.LOAD_UPCOMING_ALL_ATTEMPT:
      return {
        ...state,
        upcomingEventsAllPending: true
      }

    case types.LOAD_UPCOMING_ALL_SUCCESS:
      return {
        ...state,
        upcomingEventsAll: [...state.upcomingEventsAll, ...action.upcomingEventsAll.results],
        upcomingAllPageNumber: action.upcomingEventsAll.currentPage + 1,
        upcomingAllLastPage:
          action.upcomingEventsAll.pageCount <= action.upcomingEventsAll.currentPage,
        upcomingEventsAllPending: false
      }

    case types.LOAD_UPCOMING_ALL_FAILURE:
      return {
        ...state,
        upcomingEventsAll: [],
        upcomingEventsAllPending: false
      }

    case types.RESET_UPCOMING_ALL:
      return {
        ...state,
        upcomingEventsAll: [],
        upcomingAllPageNumber: 1,
        upcomingAllLastPage: false
      }
    case types.LOAD_LAST_RESULT_ALL_ATTEMPT:
      return {
        ...state,
        lastResultEventsAllPending: true
      }
    case types.LOAD_LAST_RESULT_ALL_SUCCESS:
      return {
        ...state,
        lastResultEventsAll: [...state.lastResultEventsAll, ...action.lastResultEventsAll.results],
        lastResultAllPageNumber: action.lastResultEventsAll.currentPage + 1,
        lastResultAllLastPage:
          action.lastResultEventsAll.pageCount <= action.lastResultEventsAll.currentPage,
        lastResultEventsAllPending: false
      }
    case types.LOAD_LAST_RESULT_ALL_FAILURE:
      return {
        ...state,
        lastResultEventsAll: [],
        lastResultEventsAllPending: false
      }

    case types.RESET_LAST_RESULT_ALL:
      return {
        ...state,
        lastResultEventsAll: [],
        lastResultAllPageNumber: 1,
        lastResultAllLastPage: false
      }
    case types.LOAD_LAST_RESULT_FOR_ME_ALL_ATTEMPT:
      return {
        ...state,
        lastResultEventsAllPending: true
      }
    case types.LOAD_LAST_RESULT_FOR_ME_ALL_SUCCESS:
      return {
        ...state,
        lastResultEventsAll: [...state.lastResultEventsAll, ...action.lastResultEventsAll.results],
        lastResultAllPageNumber: action.lastResultEventsAll.currentPage + 1,
        lastResultAllLastPage:
          action.lastResultEventsAll.pageCount <= action.lastResultEventsAll.currentPage,
        lastResultEventsAllPending: false
      }
    case types.LOAD_LAST_RESULT_FOR_ME_ALL_FAILURE:
      return {
        ...state,
        lastResultEventsAll: [],
        lastResultEventsAllPending: false
      }

    case types.RESET_LAST_RESULT_FOR_ME_ALL:
      return {
        ...state,
        lastResultEventsAll: [],
        lastResultAllPageNumber: 1,
        lastResultAllLastPage: false
      }

    case types.LOAD_UPCOMING_FOR_ME_ALL_ATTEMPT:
      return {
        ...state,
        upcomingEventsAllPending: true
      }

    case types.LOAD_UPCOMING_FOR_ME_ALL_SUCCESS:
      return {
        ...state,
        upcomingEventsAll: [...state.upcomingEventsAll, ...action.upcomingEventsAll.results],
        upcomingAllPageNumber: action.upcomingEventsAll.currentPage + 1,
        upcomingAllLastPage:
          action.upcomingEventsAll.pageCount <= action.upcomingEventsAll.currentPage,
        upcomingEventsAllPending: false
      }

    case types.LOAD_UPCOMING_FOR_ME_ALL_FAILURE:
      return {
        ...state,
        upcomingEventsAll: [],
        upcomingEventsAllPending: false
      }

    case types.RESET_UPCOMING_FOR_ME_ALL:
      return {
        ...state,
        upcomingEventsAll: [],
        upcomingAllPageNumber: 1,
        upcomingAllLastPage: false
      }
    case types.LOAD_CREATED_BY_ME_ATTEMPT:
      return {
        ...state,
        createdEventsPending: true
      }
    case types.LOAD_CREATED_BY_ME_SUCCESS:
      return {
        ...state,
        createdEvents: [...state.createdEvents, ...action.createdEvents.results],
        createdEventsPending: false,
        createdPageNumber: action.createdEvents.currentPage + 1,
        createdLastPage: action.createdEvents.pageCount <= action.createdEvents.currentPage
      }
    case types.LOAD_CREATED_BY_ME_FAILURE:
      return {
        ...state,
        createdEvents: [],
        createdEventsPending: false
      }
    case types.RESET_CREATED_BY_ME:
      return {
        ...state,
        createdEvents: [],
        createdPageNumber: 1,
        createdLastPage: false
      }
    case types.LOAD_CREATED_BY_ME_ALL_ATTEMPT:
      return {
        ...state,
        createdEventsAllPending: true
      }
    case types.LOAD_CREATED_BY_ME_ALL_SUCCESS:
      return {
        ...state,
        createdEventsAll: [...state.createdEventsAll, ...action.createdEventsAll.results],
        createdEventsAllPending: false,
        createdAllPageNumber: action.createdEventsAll.currentPage + 1,
        createdAllLastPage: action.createdEventsAll.pageCount <= action.createdEventsAll.currentPage
      }
    case types.LOAD_CREATED_BY_ME_ALL_FAILURE:
      return {
        ...state,
        createdEventsAll: [],
        createdEventsAllPending: false,
        error: action.error
      }
    case types.RESET_CREATED_BY_ME_ALL:
      return {
        ...state,
        createdEventsAll: [],
        createdAllPageNumber: 1,
        createdAllLastPage: false
      }
    case types.LOAD_ACTIVE_INSCRIPTION_FORM_ATTEMPT:
      return {
        ...state,
        activeInscriptionFormPending: true,
        activeInscriptionForm: null,
        activeInscriptionFormError: null
      }
    case types.LOAD_ACTIVE_INSCRIPTION_FORM_SUCCESS:
      return {
        ...state,
        activeInscriptionForm: action.activeInscriptionForm,
        activeInscriptionFormPending: false
      }
    case types.LOAD_ACTIVE_INSCRIPTION_FORM_FAILURE:
      return {
        ...state,
        activeInscriptionForm: null,
        activeInscriptionFormPending: false,
        activeInscriptionFormError: action.error
      }
    case types.CREATE_INSCRIPTION_FORMS_ATTEMPT:
      return {
        ...state,
        createInscriptionFormsPending: true,
        createInscriptionFormsError: null
      }
    case types.CREATE_INSCRIPTION_FORMS_SUCCESS:
      return {
        ...state,
        createInscriptionFormsPending: false,
        activeInscriptionForm: action.inscriptionForm,
        createInscriptionFormsError: null
      }

    case types.CREATE_INSCRIPTION_FORMS_FAILURE:
      return {
        ...state,
        createInscriptionFormsPending: false,
        createInscriptionFormsError: action.error
      }

    case types.UPDATE_INSCRIPTION_FORMS_ATTEMPT:
      return {
        ...state,
        updateInscriptionFormsPending: true,
        updateInscriptionFormsError: null
      }
    case types.UPDATE_INSCRIPTION_FORMS_SUCCESS:
      return {
        ...state,
        updateInscriptionFormsPending: false,
        activeInscriptionForm: action.inscriptionForm
      }
    case types.UPDATE_INSCRIPTION_FORMS_FAILURE:
      return {
        ...state,
        updateInscriptionFormsPending: false,
        updateInscriptionFormsError: action.error
      }
    case types.DELETE_INSCRIPTION_FORMS_ATTEMPT:
      return {
        ...state,
        deleteInscriptionFormsPending: true,
        deleteInscriptionFormsError: null
      }
    case types.DELETE_INSCRIPTION_FORMS_SUCCESS:
      return {
        ...state,
        deleteInscriptionFormsPending: false,
        activeInscriptionForm: null
      }
    case types.DELETE_INSCRIPTION_FORMS_FAILURE:
      return {
        ...state,
        deleteInscriptionFormsPending: false,
        deleteInscriptionFormsError: action.error
      }
    case types.RESET_INSCRIPTION_FORMS:
      return {
        ...state,
        activeInscriptionForm: null,
        activeInscriptionFormPending: false,
        activeInscriptionFormError: null,
        createInscriptionFormsError: null,
        updateInscriptionFormsError: null,
        deleteInscriptionFormsError: null,
        eventInscriptionError: null,
        paymentLocations: [],
        eventBankAccounts: [],
        mercadoPagoAuthorization: null,
        paymentParams: initialState.paymentParams
      }
    case types.EVENT_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        eventInscriptionPending: true,
        inscriptionError: null
      }
    case types.EVENT_INSCRIPTION_SUCCESS:
      return {
        ...state,
        eventInscription: action.inscription,
        eventInscriptionPending: false
      }
    case types.EVENT_INSCRIPTION_FAILURE:
      return {
        ...state,
        eventInscriptionPending: false,
        eventInscriptionError: action.error
      }
    case types.LOAD_LIST_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        listInscriptionPending: true,
        listInscriptionError: null
      }
    case types.LOAD_LIST_INSCRIPTION_SUCCESS:
      return {
        ...state,
        listInscriptionPending: false,
        listInscription: action.listInscriptionPage.results,
        listInscriptionPageCount: action.listInscriptionPage.pageCount,
        listInscriptionPageCurrentPage: action.listInscriptionPage.currentPage
      }
    case types.LOAD_LIST_INSCRIPTION_FAILURE:
      return {
        ...state,
        listInscriptionPending: false,
        listInscriptionError: action.error
      }

    case types.RESET_LIST_INSCRIPTION:
      return {
        ...state,
        updateEventInscriptionError: null,
        eventInscriptionError: null
      }
    case types.LIST_INSCRIPTION_SEARCH_METADATA_ATTEMPT:
      return {
        ...state,
        listInscriptionMetadataPending: true,
        listInscriptionSearchMetadataError: null
      }
    case types.LIST_INSCRIPTION_SEARCH_METADATA_SUCCESS:
      return {
        ...state,
        listInscriptionSearchMetadataPending: false,
        listInscriptionSearchMetadata: action.listInscriptionPage
      }
    case types.LIST_INSCRIPTION_SEARCH_METADATA_FAILURE:
      return {
        ...state,
        listInscriptionMetadataPending: false,
        listInscriptionSearchMetadataError: action.error
      }
    case types.UPLOAD_RULES_DOCUMENT_ATTEMPT:
      return {
        ...state,
        uploadRulesDocumentPending: true,
        uploadRulesDocumentError: null
      }
    case types.UPLOAD_RULES_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadRulesDocumentPending: false
      }
    case types.UPLOAD_RULES_DOCUMENT_FAILURE:
      return {
        ...state,
        uploadRulesDocumentError: action.error,
        uploadRulesDocumentPending: false
      }
    case types.DOWNLOAD_RULES_DOCUMENT_ATTEMPT:
      return {
        ...state,
        downloadRulesDocumentPending: true
      }
    case types.DOWNLOAD_RULES_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadRulesDocumentPending: false
      }
    case types.DOWNLOAD_RULES_DOCUMENT_FAILURE:
      return {
        ...state,
        downloadRulesDocumentError: action.error,
        downloadRulesDocumentPending: false
      }
    case types.DELETE_RULES_DOCUMENT_ATTEMPT:
      return {
        ...state,
        deleteRulesDocumentPending: true,
        deleteRulesDocumentError: null
      }
    case types.DELETE_RULES_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteRulesDocumentPending: false
      }
    case types.DELETE_RULES_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteRulesDocumentError: action.error,
        deleteRulesDocumentPending: false
      }
    case types.UPDATE_EVENT_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        updateEventInscriptionPending: true,
        updateEventInscriptionError: null
      }
    case types.UPDATE_EVENT_INSCRIPTION_SUCCESS:
      return {
        ...state,
        updateEventInscriptionPending: false,
        eventInscription: action.eventInscription
      }
    case types.UPDATE_EVENT_INSCRIPTION_FAILURE:
      return {
        ...state,
        updateEventInscriptionPending: false,
        updateEventInscriptionError: action.error
      }
    case types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_ATTEMPT:
      return {
        ...state,
        eventInscriptionsForCurrentUserPending: true,
        eventInscriptionsForCurrentUserError: null
      }
    case types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_SUCCESS:
      return {
        ...state,
        eventInscriptionsForCurrentUser: action.eventInscriptionsForCurrentUser,
        eventInscriptionAllForUser:
          action.eventInscriptionsForCurrentUser.allInscriptionsPaged.results,
        eventInscriptionAllForUserPageNumber:
          action.eventInscriptionsForCurrentUser.allInscriptionsPaged.currentPage + 1,
        eventInscriptionAllForUserPageLastPage:
          action.eventInscriptionsForCurrentUser.allInscriptionsPaged.pageCount <=
          action.eventInscriptionsForCurrentUser.allInscriptionsPaged.currentPage,
        eventInscriptionsForCurrentUserPending: false
      }
    case types.LOAD_EVENT_INSCRIPTIONS_FOR_CURRENT_USER_FAILURE:
      return {
        ...state,
        eventInscriptionsForCurrentUserError: action.error,
        eventInscriptionsForCurrentUserPending: false
      }
    case types.RESET_EVENT_INSCRIPTIONS_FOR_CURRENT_USER:
      return {
        ...state,
        eventInscriptionsForCurrentUser: null,
        eventInscriptionsForCurrentUserPending: false,
        eventInscriptionsForCurrentUserError: null,
        eventInscriptionAllForUser: [],
        eventInscriptionAllForUserPageLastPage: false,
        eventInscriptionAllForUserPageNumber: 1
      }
    case types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_ATTEMPT:
      return {
        ...state,
        eventInscriptionAllForUserPending: true,
        eventInscriptionAllForUserError: null
      }
    case types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_SUCCESS:
      return {
        ...state,
        eventInscriptionAllForUser: [
          ...state.eventInscriptionAllForUser,
          ...action.eventInscriptionsAll.allInscriptionsPaged.results
        ],
        eventInscriptionAllForUserPageNumber:
          action.eventInscriptionsAll.allInscriptionsPaged.currentPage + 1,
        eventInscriptionAllForUserPageLastPage:
          action.eventInscriptionsAll.allInscriptionsPaged.pageCount <=
          action.eventInscriptionsAll.allInscriptionsPaged.currentPage,
        eventInscriptionAllForUserPending: false
      }
    case types.LOAD_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER_FAILURE:
      return {
        ...state,
        eventInscriptionAllForUserError: action.error,
        eventInscriptionAllForUserPending: false
      }
    case types.RESET_EVENT_INSCRIPTIONS_ALL_FOR_CURRENT_USER:
      return {
        ...state,
        eventInscriptionAllForUser: [],
        eventInscriptionAllForUserError: null,
        eventInscriptionAllForUserPending: false,
        eventInscriptionAllForUserPageNumber: 1,
        eventInscriptionAllForUserPageLastPage: false
      }
    case types.DOWNLOAD_INSCRIPTIONS_SHEET_ATTEMPT:
      return {
        ...state,
        downloadInscriptionsSheetPending: true,
        downloadInscriptionsSheetError: null
      }
    case types.DOWNLOAD_INSCRIPTIONS_SHEET_SUCCESS:
      return {
        ...state,
        downloadInscriptionsSheetPending: false
      }
    case types.DOWNLOAD_INSCRIPTIONS_SHEET_FAILURE:
      return {
        ...state,
        downloadInscriptionsSheetPending: false,
        downloadInscriptionsSheetError: action.error
      }
    case types.ACCEPT_ORGANIZATION_ATTEMPT:
      return {
        ...state,
        acceptOrganizationPending: true,
        acceptOrganizationError: null
      }
    case types.ACCEPT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        acceptOrganizationPending: false
      }
    case types.ACCEPT_ORGANIZATION_FAILURE:
      return {
        ...state,
        acceptOrganizationPending: false,
        acceptOrganizationError: action.error,
        error: action.error
      }
    case types.REJECT_ORGANIZATION_ATTEMPT:
      return {
        ...state,
        rejectOrganizationPending: true,
        rejectOrganizationError: null
      }
    case types.REJECT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        rejectOrganizationPending: false
      }
    case types.REJECT_ORGANIZATION_FAILURE:
      return {
        ...state,
        rejectOrganizationPending: false,
        rejectOrganizationError: action.error,
        error: action.error
      }
    case types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_ATTEMPT:
      return {
        ...state,
        inscriptionFormsCloneSuggestionsPending: true,
        inscriptionFormsCloneSuggestionsError: null
      }
    case types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        inscriptionFormsCloneSuggestionsPending: false,
        inscriptionFormsCloneSuggestions: [
          ...state.inscriptionFormsCloneSuggestions,
          ...action.inscriptionFormsCloneSuggestions.results
        ],
        inscriptionFormsCloneSuggestionsPageNumber:
          action.inscriptionFormsCloneSuggestions.currentPage,
        inscriptionFormsCloneSuggestionsLastPage:
          action.inscriptionFormsCloneSuggestions.pageCount <=
          action.inscriptionFormsCloneSuggestions.currentPage
      }
    case types.LOAD_INSCRIPTION_FORMS_CLONE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        inscriptionFormsCloneSuggestionsPending: false,
        inscriptionFormsCloneSuggestionsError: action.error
      }
    case types.RESET_INSCRIPTION_FORMS_CLONE_SUGGESTIONS:
      return {
        ...state,
        inscriptionFormsCloneSuggestions: [],
        inscriptionFormsCloneSuggestionsPending: false,
        inscriptionFormsCloneSuggestionsError: null,
        inscriptionFormsCloneSuggestionsPageNumber: 1,
        inscriptionFormsCloneSuggestionsLastPage: false
      }

    case types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_ATTEMPT:
      return {
        ...state,
        categoriesCloneSuggestions: [],
        categoriesCloneSuggestionsPending: true,
        categoriesCloneSuggestionsError: null
      }
    case types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        categoriesCloneSuggestions: action.categoriesCloneSuggestions,
        categoriesCloneSuggestionsPending: false
      }
    case types.LOAD_CATEGORIES_CLONE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        categoriesCloneSuggestionsError: action.error,
        categoriesCloneSuggestionsPending: false
      }
    case types.RESET_CATERORIES_CLONE_SUGGESTIONS:
      return {
        ...state,
        categoriesCloneSuggestions: [],
        categoriesCloneSuggestionsPending: false,
        categoriesCloneSuggestionsError: null
      }
    case types.LOAD_CATEGORIES_ATTEMPT:
      return {
        ...state,
        categoriesPending: true,
        categories: []
      }
    case types.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories.results,
        categoriesPending: false
      }
    case types.LOAD_CATEGORIES_FAILURE:
      return {
        ...state,
        categoriesPending: false,
        error: action.error,
        categories: []
      }
    case types.CREATE_CATEGORY_ATTEMPT:
      return {
        ...state,
        createCategoryError: null,
        createCategoryPending: true
      }
    case types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategoryPending: false,
        categories: [action.category, ...state.categories]
      }
    case types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createCategoryPending: false,
        createCategoryError: action.error
      }
    case types.UPDATE_CATEGORY_ATTEMPT:
      return {
        ...state,
        updateCategoryError: null,
        updateCategoryPending: true
      }
    case types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategoryPending: false
      }
    case types.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        updateCategoryPending: false,
        updateCategoryError: action.error
      }
    case types.DELETE_CATEGORY_ATTEMPT:
      return {
        ...state,
        deleteCategoryError: null,
        deleteCategoryPending: true
      }
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategoryPending: false
      }
    case types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        deleteCategoryPending: false,
        deleteCategoryError: action.error
      }
    case types.LOAD_DISTANCES_ATTEMPT:
      return {
        ...state,
        distancesPending: true,
        distances: []
      }
    case types.LOAD_DISTANCES_SUCCESS:
      return {
        ...state,
        distances: action.distances.results,
        distancesPending: false
      }
    case types.LOAD_DISTANCES_FAILURE:
      return {
        ...state,
        distancesPending: false,
        error: action.error,
        distances: []
      }
    case types.CREATE_DISTANCE_ATTEMPT:
      return {
        ...state,
        createDistanceError: null,
        createDistancePending: true
      }
    case types.CREATE_DISTANCE_SUCCESS:
      return {
        ...state,
        createDistancePending: false,
        distances: [action.distance, ...state.distances]
      }
    case types.CREATE_DISTANCE_FAILURE:
      return {
        ...state,
        createDistancePending: false,
        createDistanceError: action.error
      }
    case types.UPDATE_DISTANCE_ATTEMPT:
      return {
        ...state,
        updateDistanceError: null,
        updateDistancePending: true
      }
    case types.UPDATE_DISTANCE_SUCCESS:
      return {
        ...state,
        updateDistancePending: false
      }
    case types.UPDATE_DISTANCE_FAILURE:
      return {
        ...state,
        updateDistancePending: false,
        updateDistanceError: action.error
      }
    case types.DELETE_DISTANCE_ATTEMPT:
      return {
        ...state,
        deleteDistanceError: null,
        deleteDistancePending: true
      }
    case types.DELETE_DISTANCE_SUCCESS:
      return {
        ...state,
        deleteDistancePending: false
      }
    case types.DELETE_DISTANCE_FAILURE:
      return {
        ...state,
        deleteDistancePending: false,
        deleteDistanceError: action.error
      }
    case types.LOAD_MERCADO_PAGO_AUTHORIZATION_ATTEMPT:
      return {
        ...state,
        mercadoPagoAuthorization: null,
        mercadoPagoAuthorizationError: null,
        mercadoPagoAuthorizationPending: true
      }
    case types.LOAD_MERCADO_PAGO_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        mercadoPagoAuthorization: action.mercadoPagoAuthorization,
        mercadoPagoAuthorizationPending: false
      }
    case types.LOAD_MERCADO_PAGO_AUTHORIZATION_FAILURE:
      return {
        ...state,
        mercadoPagoAuthorizationError: action.error,
        mercadoPagoAuthorizationPending: false
      }
    case types.SEND_MERCADO_PAGO_AUTHORIZATION_ATTEMPT:
      return {
        ...state,
        sendMercadoPagoAuthorizationError: null,
        sendMercadoPagoAuthorizationPending: true
      }
    case types.SEND_MERCADO_PAGO_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        sendMercadoPagoAuthorizationPending: false
      }
    case types.SEND_MERCADO_PAGO_AUTHORIZATION_FAILURE:
      return {
        ...state,
        deleteMercadoPagoAuthorizationError: action.error,
        deleteMercadoPagoAuthorizationPending: false
      }
    case types.DELETE_MERCADO_PAGO_AUTHORIZATION_ATTEMPT:
      return {
        ...state,
        deleteMercadoPagoAuthorizationError: null,
        deleteMercadoPagoAuthorizationPending: true
      }
    case types.DELETE_MERCADO_PAGO_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        mercadoPagoAuthorization: null,
        deleteMercadoPagoAuthorizationPending: false
      }
    case types.DELETE_MERCADO_PAGO_AUTHORIZATION_FAILURE:
      return {
        ...state,
        deleteMercadoPagoAuthorizationError: action.error,
        deleteMercadoPagoAuthorizationPending: false
      }
    case types.LOAD_EVENT_BANK_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        eventBankAccountsError: null,
        eventBankAccountsPending: true
      }
    case types.LOAD_EVENT_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        eventBankAccounts: action.data,
        eventBankAccountsPending: false
      }
    case types.LOAD_EVENT_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        eventBankAccountsError: action.error,
        eventBankAccountsPending: false
      }
    case types.CREATE_EVENT_BANK_ACCOUNT_ATTEMPT:
      return {
        ...state,
        createEventBankAccountPending: true,
        createEventBankAccountError: null
      }
    case types.CREATE_EVENT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        createEventBankAccountPending: false,
        createEventBankAccountError: null
      }
    case types.CREATE_EVENT_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        createEventBankAccountPending: false,
        createEventBankAccountError: action.error
      }
    case types.UPDATE_EVENT_BANK_ACCOUNT_ATTEMPT:
      return {
        ...state,
        updateEventBankAccountPending: true,
        updateEventBankAccountError: null
      }
    case types.UPDATE_EVENT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        updateEventBankAccountPending: false
      }
    case types.UPDATE_EVENT_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        updateEventBankAccountError: action.error,
        updateEventBankAccountPending: false
      }
    case types.DELETE_EVENT_BANK_ACCOUNT_ATTEMPT:
      return {
        ...state,
        deleteEventBankAccountPending: true,
        deleteEventBankAccountError: null
      }
    case types.DELETE_EVENT_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteEventBankAccountPending: false
      }
    case types.DELETE_EVENT_BANK_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteEventBankAccountPending: false,
        deleteEventBankAccountError: action.error
      }
    case types.LOAD_PAYMENT_LOCATIONS_ATTEMPT:
      return {
        ...state,
        paymentLocationsPending: true,
        paymentLocationsError: null
      }
    case types.LOAD_PAYMENT_LOCATIONS_SUCCESS:
      return {
        ...state,
        paymentLocations: action.paymentLocations,
        paymentLocationsPending: false
      }
    case types.LOAD_PAYMENT_LOCATIONS_FAILURE:
      return {
        ...state,
        paymentLocationsError: action.error,
        paymentLocationsPending: false
      }
    case types.CREATE_PAYMENT_LOCATION_ATTEMPT:
      return {
        ...state,
        createPaymentLocationPending: true,
        createPaymentLocationError: null
      }
    case types.CREATE_PAYMENT_LOCATION_SUCCESS:
      return {
        ...state,
        createPaymentLocationPending: false,
        createPaymentLocationError: null
      }
    case types.CREATE_PAYMENT_LOCATION_FAILURE:
      return {
        ...state,
        createPaymentLocationPending: false,
        createPaymentLocationError: action.error
      }
    case types.UPDATE_PAYMENT_LOCATION_ATTEMPT:
      return {
        ...state,
        updatePaymentLocationPending: true,
        updatePaymentLocationError: null
      }
    case types.UPDATE_PAYMENT_LOCATION_SUCCESS:
      return {
        ...state,
        updatePaymentLocationPending: false
      }
    case types.UPDATE_PAYMENT_LOCATION_FAILURE:
      return {
        ...state,
        updatePaymentLocationError: action.error,
        updatePaymentLocationPending: false
      }

    case types.DELETE_PAYMENT_LOCATION_ATTEMPT:
      return {
        ...state,
        deletePaymentLocationPending: true,
        deletePaymentLocationError: null
      }
    case types.DELETE_PAYMENT_LOCATION_SUCCESS:
      return {
        ...state,
        deletePaymentLocationPending: false
      }
    case types.DELETE_PAYMENT_LOCATION_FAILURE:
      return {
        ...state,
        deletePaymentLocationPending: false,
        deletePaymentLocationError: action.error
      }
    case types.LOAD_BANKS_ATTEMPT:
      return {
        ...state,
        banksError: null,
        banksPending: true
      }
    case types.LOAD_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.data,
        banksPending: false
      }
    case types.LOAD_BANKS_FAILURE:
      return {
        ...state,
        banksError: action.error,
        banksPending: false
      }
    case types.LOAD_EVENT_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        eventInscriptionError: null,
        eventInscriptionPending: true
      }
    case types.LOAD_EVENT_INSCRIPTION_SUCCESS:
      return {
        ...state,
        eventInscription: action.data,
        eventInscriptionPending: false
      }
    case types.LOAD_EVENT_INSCRIPTION_FAILURE:
      return {
        ...state,
        eventInscriptionError: action.error,
        eventInscriptionPending: false
      }
    case types.SET_PAYMENT_PARAMS:
      return {
        ...state,
        paymentParams: action.paymentParams
      }

    case types.LOAD_ACTIVE_PAYMENT_LOCATIONS_ATTEMPT:
      return {
        ...state,
        activePaymentLocations: [],
        activePaymentLocationsPending: true,
        activePaymentLocationsError: null
      }
    case types.LOAD_ACTIVE_PAYMENT_LOCATIONS_SUCCESS:
      return {
        ...state,
        activePaymentLocations: action.activePaymentLocations,
        activePaymentLocationsPending: false
      }
    case types.LOAD_ACTIVE_PAYMENT_LOCATIONS_FAILURE:
      return {
        ...state,
        activePaymentLocationsError: action.error,
        activePaymentLocationsPending: false
      }
    case types.LOAD_TICKET_TYPE_FEES_ATTEMPT:
      return {
        ...state,
        ticketTypeFeesError: null,
        ticketTypeFeesPending: true
      }
    case types.LOAD_TICKET_TYPE_FEES_SUCCESS:
      return {
        ...state,
        ticketTypeFees: action.data,
        ticketTypeFeesPending: false
      }
    case types.LOAD_TICKET_TYPE_FEES_FAILURE:
      return {
        ...state,
        ticketTypeFeesError: action.error,
        ticketTypeFeesPending: false
      }

    case types.LOAD_ACTIVE_BANK_ACCOUNTS_ATTEMPT:
      return {
        ...state,
        activeBankAccountsPending: true,
        activeBankAccountsError: null
      }
    case types.LOAD_ACTIVE_BANK_ACCOUNTS_SUCCESS:
      return {
        ...state,
        activeBankAccounts: action.activeBankAccounts,
        activeBankAccountsPending: false
      }
    case types.LOAD_ACTIVE_BANK_ACCOUNTS_FAILURE:
      return {
        ...state,
        activeBankAccountsError: action.error,
        activeBankAccountsPending: false
      }
    case types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_ATTEMPT:
      return {
        ...state,
        eventInscriptionPending: true,
        eventInscriptionError: null
      }
    case types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_SUCCESS:
      return {
        ...state,
        eventInscriptionPending: false,
        eventInscription: action.eventInscription
      }
    case types.LOAD_EVENT_INSCRIPTION_BY_ID_NUMBER_AND_COUNTRY_FAILURE:
      return {
        ...state,
        eventInscriptionError: action.error,
        eventInscriptionPending: false
      }
    case types.PUBLIC_UPDATE_EVENT_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        publicUpdateEventInscriptionPending: true,
        publicUpdateEventInscriptionError: null
      }
    case types.PUBLIC_UPDATE_EVENT_INSCRIPTION_SUCCESS:
      return {
        ...state,
        eventInscription: action.eventInscription,
        publicUpdateEventInscriptionPending: false
      }
    case types.PUBLIC_UPDATE_EVENT_INSCRIPTION_FAILURE:
      return {
        ...state,
        publicUpdateEventInscriptionError: action.error,
        publicUpdateEventInscriptionPending: false
      }
    case types.LOAD_CANCELLED_EVENT_INSCRIPTION_ATTEMPT:
      return {
        ...state,
        cancelledEventInscriptionPending: true,
        cancelledEventInscriptionError: null
      }

    case types.LOAD_CANCELLED_EVENT_INSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelledEventInscription: action.eventInscription,
        cancelledEventInscriptionPending: false
      }

    case types.LOAD_CANCELLED_EVENT_INSCRIPTION_FAILURE:
      return {
        ...state,
        cancelledEventInscriptionPending: false,
        cancelledEventInscriptionError: action.error
      }

    case types.DELETE_SALE_ORDER_PAYMENT_ATTEMPT:
      return {
        ...state,
        deleteSaleOrderPaymentPending: true,
        deleteSaleOrderPaymentError: null
      }
    case types.DELETE_SALE_ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        deleteSaleOrderPaymentPending: false
      }
    case types.DELETE_SALE_ORDER_PAYMENT_FAILURE:
      return {
        ...state,
        deleteSaleOrderPaymentPending: false,
        deleteSaleOrderPaymentError: action.error
      }

    case types.UPDATE_SALE_ORDER_PAYMENT_ATTEMPT:
      return {
        ...state,
        updateSaleOrderPaymentPending: true,
        updateSaleOrderPaymentError: null
      }

    case types.UPDATE_SALE_ORDER_PAYMENT_SUCCESS:
      return {
        ...state,
        updateSaleOrderPaymentPending: false
      }
    case types.UPDATE_SALE_ORDER_PAYMENT_FAILURE:
      return {
        ...state,
        updateSaleOrderPaymentPending: false,
        updateSaleOrderPaymentError: action.error
      }

    case types.LOAD_EVENT_DISCOUNT_CODES_ATTEMPT:
      return {
        ...state,
        eventDiscountCodesPending: true,
        eventDiscountCodesError: null
      }
    case types.LOAD_EVENT_DISCOUNT_CODES_SUCCESS:
      return {
        ...state,
        eventDiscountCodes: action.eventDiscountCodes,
        eventDiscountCodesPending: false
      }
    case types.LOAD_EVENT_DISCOUNT_CODES_FAILURE:
      return {
        ...state,
        eventDiscountCodesPending: false,
        eventDiscountCodesError: action.error
      }

    case types.CREATE_EVENT_DISCOUNT_CODE_ATTEMPT:
      return {
        ...state,
        createEventDiscountCodePending: true,
        createEventDiscountCodeError: null
      }
    case types.CREATE_EVENT_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        createEventDiscountCodePending: false
      }
    case types.CREATE_EVENT_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        createEventDiscountCodeError: action.error,
        createEventDiscountCodePending: false
      }

    case types.UPDATE_EVENT_DISCOUNT_CODE_ATTEMPT:
      return {
        ...state,
        updateEventDiscountCodePending: true,
        updateEventDiscountCodeError: null
      }
    case types.UPDATE_EVENT_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        updateEventDiscountCodePending: false
      }
    case types.UPDATE_EVENT_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        updateEventDiscountCodeError: action.error,
        updateEventDiscountCodePending: false
      }
    case types.DELETE_EVENT_DISCOUNT_CODE_ATTEMPT:
      return {
        ...state,
        deleteEventDiscountCodePending: true,
        deleteEventDiscountCodeError: null
      }
    case types.DELETE_EVENT_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        deleteEventDiscountCodePending: false
      }

    case types.DELETE_EVENT_DISCOUNT_CODE_FAILURE:
      return {
        ...state,
        deleteEventDiscountCodeError: action.error,
        deleteEventDiscountCodePending: false
      }

    case types.LOAD_EVENT_RESULTS_REPORT_URL_ATTEMPT:
      return {
        ...state,
        eventResultsReportUrlPending: true,
        eventResultsReportUrlError: null
      }

    case types.LOAD_EVENT_RESULTS_REPORT_URL_SUCCESS:
      return {
        ...state,
        eventResultsReportUrl: action.eventResultsReportUrl,
        eventResultsReportUrlPending: false
      }

    case types.LOAD_EVENT_RESULTS_REPORT_URL_FAILURE:
      return {
        ...state,
        eventResultsReportUrlPending: false,
        eventResultsReportUrlError: action.error
      }

    case types.DOWNLOAD_EVENT_CATEGORIES_ATTEMPT:
      return {
        ...state,
        downloadEventCategoriesPending: true,
        downloadEventCategoriesError: null
      }
    case types.DOWNLOAD_EVENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        downloadEventCategoriesPending: false
      }
    case types.DOWNLOAD_EVENT_CATEGORIES_FAILURE:
      return {
        ...state,
        downloadEventCategoriesPending: false,
        downloadEventCategoriesError: action.error
      }
    default:
      return state
  }
}
