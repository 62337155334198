import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyles } from './LoadingPlaceholder.style'

export const LoadingPlaceholder = () => {
  const classes = useStyles()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            className={classes.containerHorizontal}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect className={classes.card} x='0' y='0' rx='0' ry='0' width='100%' height='100%' />
            <rect className={classes.card} x='0' y='320' rx='0' ry='0' width='100%' height='100%' />
            <rect className={classes.card} x='0' y='640' rx='0' ry='0' width='100%' height='100%' />
            <rect className={classes.card} x='0' y='960' rx='0' ry='0' width='100%' height='100%' />
          </ContentLoader>
        ) : (
          <ContentLoader
            className={classes.containerVertical}
            backgroundColor={bgColor}
            foregroundColor={fgColor}>
            <rect
              className={classes.cardWide}
              x='0'
              y='0'
              rx='0'
              ry='0'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.cardWide}
              x='0'
              y='250'
              rx='0'
              ry='0'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.cardWide}
              x='0'
              y='500'
              rx='0'
              ry='0'
              width='100%'
              height='100%'
            />
            <rect
              className={classes.cardWide}
              x='0'
              y='750'
              rx='0'
              ry='0'
              width='100%'
              height='100%'
            />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default LoadingPlaceholder
