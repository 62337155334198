import { makeStyles } from '@material-ui/styles'
import sliderNext from 'assets/img/slider-next.png'
import sliderPrev from 'assets/img/slider-prev.png'

export const useStyles = makeStyles((theme) => ({
  prev: {
    position: 'absolute',
    cursor: 'pointer',
    width: 17,
    height: 32,
    backgroundImage: `url(${sliderPrev})`,
    '&:hover': {
      backgroundImage: `url(${sliderPrev})`
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: '5%'
    }
  },
  next: {
    position: 'absolute',
    cursor: 'pointer',
    width: 17,
    height: 32,
    backgroundImage: `url(${sliderNext})`,
    '&:hover': {
      backgroundImage: `url(${sliderNext})`
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: '5%'
    }
  }
}))

export default useStyles
