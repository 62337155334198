import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '80%',
    margin: '10px auto'
  },
  observationContainer: {
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    minHeight: 250
  },
  observation: {
    width: '80%',
    marginTop: theme.spacing(5),
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between'
  },
  observationSelectFormGroup: {
    marginRight: 30,
    width: 100,
    '& > h5': {
      fontWeight: 500,
      marginBottom: theme.spacing(2)
    }
  },
  initialMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    margin: theme.spacing(2, 0)
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      color: theme.palette.primary.main,
      '& h6': {
        fontSize: '.875rem',
        color: theme.palette.secondary.contrastText,
        fontWeight: 400
      }
    },
    '&.column': {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  formGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: '48%'
      },
      flexDirection: 'row'
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem',
      marginBottom: 2
    },

    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  fieldContainer: {
    minHeight: 50,
    width: '100%',
    '& > div': {
      paddingBottom: theme.spacing()
    },
    '& > span': {
      marginLeft: theme.spacing()
    },

    [theme.breakpoints.up('md')]: {
      width: '48%',
      minHeight: 'auto'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },

    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  textField: {
    width: '100%',
    '& fieldset': {
      height: 43,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
      borderColor: theme.palette.primary.main
    }
  },
  input: {
    marginBottom: theme.spacing(),
    flexGrow: 1,
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '&.datePicker': {
      '& > div': {
        paddingRight: 0,
        height: 40
      },
      '& button': {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: '#ffff',
        width: 40,
        height: 40
      },
      '& input': {
        padding: theme.spacing(1.1, 1.8)
      }
    },
    '&.multiline': {
      '& textarea': {
        minHeight: '100px'
      }
    }
  },
  comboxsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
    minHeight: 130,
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto',
      alignItems: 'start',
      flexDirection: 'row'
    }
  },
  helperText: {
    marginTop: theme.spacing(),
    fontSize: '0.8125rem',
    fontStyle: 'italic',
    fontWeight: 700,
    color: '#4285F4'
  },
  separatorContainer: {
    marginTop: theme.spacing(3)
  },
  checkContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing()
  },
  buttonsContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100,
    alignItems: 'center'
  },
  textAreaEdit: {
    width: '100%',
    '& > div > textArea': {
      minHeight: 50,
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },
    padding: theme.spacing(2, 0),
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },

  button: {
    alignSelf: 'center',
    margin: theme.spacing(6, 0),
    padding: theme.spacing(1, 2.5)
  },
  backButton: {
    alignSelf: 'center',
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  },

  observationTextFieldContainer: {
    flexGrow: 1,
    '& > h5': {
      fontWeight: 500
    }
  },
  tosParagraph: {
    color: 'rgba(150, 150, 150, 1)',
    fontSize: '0.8125rem',
    '& a': {
      color: theme.palette.link.main,
      fontWeight: 600
    }
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0
    },
    backgroundColor: '#D9D9D9',
    padding: theme.spacing(1, 2.5)
  },
  alertParagraph: {
    color: '#FF4B4B',
    fontWeight: 700,
    alignItems: 'center',
    fontSize: 14,
    marginLeft: theme.spacing(1)
  },
  message: {
    color: '#696969',
    fontSize: 14
  },
  optionCategory: {
    display: 'flex'
  },
  optionCategoryName: {
    width: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(),
    '&.withQuota': {
      width: '50%',
      textAlign: 'left'
    }
  },
  citiesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    '& > div': {
      width: '100%'
    }
  },
  otherOptionText: {
    marginTop: 22,
    width: '100%'
  },
  optionOtherContainer: {
    width: '160px !important',
    flexShrink: 0
  },
  welcomeMessage: {
    fontWeight: 600,
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    '& a': {
      fontWeight: 600,
      color: '#4285F4'
    }
  },
  helperTextPhone: {
    fontSize: '.875rem !important',
    fontWeight: 600,
    marginTop: '10px'
  },
  phone: {
    '& input': {
      color: theme.palette.primary.main
    }
  },
  viewAdminPopper: {
    zIndex: 1300
  },
  popper: {
    zIndex: 1000
  },
  section: {
    padding: 0,
    paddingTop: theme.spacing(2),
    marginBottom: 0
  }
}))
