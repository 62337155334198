import React from 'react'
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import GoogleMapReact from 'google-map-react'
import { Marker } from 'shared'

import useStyles from './LocationPickerDialog.style'

const LocationPickerDialog = ({
  onConfirm,
  onClose,
  center,
  open,
  onLocationChanged,
  zoom = 8,
  mode
}) => {
  const classes = useStyles()

  const handleConfirm = () => {
    onConfirm(center)
    onClose()
  }

  const getMapOptions = (maps) => ({
    streetViewControl: true,
    scaleControl: true,
    fullscreenControl: false,
    gestureHandling: 'greedy',
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID]
    },
    zoomControl: true
  })

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title' fullScreen>
      {mode === 'write' ? (
        <>
          <AppBar position='relative'>
            <Toolbar>
              <Typography variant='h6' style={{ flex: 1 }}>
                Seleccione una ubicación
              </Typography>
              <IconButton
                onClick={onClose}
                className={classes.closeButton}
                edge='end'
                aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <DialogContentText>Selecciona un punto en el mapa</DialogContentText>

            <div className={classes.mapContainer}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                }}
                options={getMapOptions}
                defaultCenter={center}
                defaultZoom={zoom}
                onClick={onLocationChanged}>
                <Marker lat={center.lat} lng={center.lng} />
              </GoogleMapReact>
            </div>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleConfirm} color='primary' variant='contained'>
              Confirmar
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <AppBar position='relative'>
            <Toolbar>
              <Typography variant='h6' style={{ flex: 1 }}>
                Ver ubicación
              </Typography>
              <IconButton
                onClick={onClose}
                className={classes.closeButton}
                edge='end'
                aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <div className={classes.mapContainer}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                }}
                options={getMapOptions}
                defaultCenter={center}
                defaultZoom={zoom}>
                <Marker lat={center.lat} lng={center.lng} />
              </GoogleMapReact>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose()
              }}
              color='primary'
              variant='contained'>
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default LocationPickerDialog
