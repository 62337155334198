import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { EditableImage, EditableMultipleSelection } from 'shared'
import { searchDisciplines } from 'utils/functions'

import { FormEditDialog } from '../FormEditDialog'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SectionProfile.style'

const SectionProfile = ({
  profileImageUrl,
  userAccount,
  noProfileImage,
  handleSaveImage,
  handleDeleteImage,
  canEdit,
  handleSave = () => {}
}) => {
  const classes = useStyles()

  const [openFormEditDialog, setOpenFormEditDialog] = useState(false)

  return (
    <SectionBlock sectionClassName={classes.section} hiddenExpandedLink>
      <div className={classes.mainContainer}>
        <EditableImage
          entity={userAccount}
          field='profileImages'
          onSave={handleSaveImage}
          canEdit={canEdit}
          defaultImage={noProfileImage}
          inputClassName={classes.profileImage}
          size={{ width: 30, height: 30, fontsize: 13 }}
          type='profile'
          onDelete={handleDeleteImage}>
          <div
            className={classes.profileImage}
            style={{ backgroundImage: `url(${profileImageUrl})` }}
          />
        </EditableImage>
        <Typography color='primary' variant='h6' className={classes.title}>
          {userAccount.fullName}
        </Typography>
        <Typography color='primary' variant='h6' className={classes.title}>
          @{userAccount.username}
        </Typography>
        <div className={classes.informationContainer}>
          <EditableMultipleSelection
            entity={userAccount}
            field='disciplines'
            onSave={handleSave}
            selectClassName={classes.asyncSelect}
            subField='name'
            placeholder='Seleccione sus disciplinas'
            isMultiline={true}
            loadOptions={searchDisciplines}>
            <div className={classes.sportsContainer}>
              {userAccount.disciplines.length ? (
                userAccount.disciplines.map((d) => (
                  <div className={classes.sport} key={d.id}>
                    {d.name}
                  </div>
                ))
              ) : (
                <Typography color='primary' variant='h6'>
                  SIN DISCIPLINA
                </Typography>
              )}
            </div>
          </EditableMultipleSelection>
        </div>
        {canEdit && (
          <Button
            color='primary'
            variant='outlined'
            className={classes.buttonOpenEdit}
            onClick={() => setOpenFormEditDialog(true)}>
            Editar perfil
          </Button>
        )}
      </div>
      <FormEditDialog
        open={openFormEditDialog}
        dialogClassName={classes.editDialog}
        onClose={() => setOpenFormEditDialog(false)}
        values={userAccount}
      />
    </SectionBlock>
  )
}

export default SectionProfile
