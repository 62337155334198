import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './DeleteItemNotifyDialog.style'

const DELETE_ITEM_NOTIFY_DIALOG_STATES = {
  DELETE_NOT_ALLOWED: 'deleteNotAllowed',
  DELETE_CONFIRM: 'deleteConfirm',
  DELETE_CATEGORY_CONFIRM: 'deleteCategoryConfirm',
  DELETE_DISTANCE_CONFIRM: 'deleteDistanceConfirm',
  DELETE_CATEGORY_NOT_ALLOWED: 'deleteCategoryNotAllowed',
  DELETE_DISTANCE_NOT_ALLOWED: 'deleteDistanceNotAllowed'
}

const DeleteItemNotifyDialog = ({
  open,
  onClose,
  onSubmit,
  state = DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CONFIRM,
  onAccept,
  category = {},
  distance = {}
}) => {
  const classes = useStyles()

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='NOTIFICACIÓN'
      className={classes.dialog}>
      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CATEGORY_CONFIRM && (
        <div className={classes.alertContainer}>
          <Typography color='primary' variant='h6' align='center' className={classes.text}>
            Esta categoría no se puede eliminar porque es la única asociada a uno o más tickets,
            para eliminarla debes modificar primero el ticket.
          </Typography>

          <Button color='primary' variant='contained' className={classes.button} onClick={onAccept}>
            Aceptar
          </Button>
        </div>
      )}

      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CATEGORY_NOT_ALLOWED && (
        <div className={classes.unbindNotifyContainer}>
          <Typography variant='h6' color='primary' className={classes.text}>
            La siguiente categoría:
            <br />
            <ul className={classes.list}>
              <li>{category.name}</li>
            </ul>
            se encuentra asociada a uno o más tickets creados, al deshabilitarla se borrará de todos
            los tickets en los que figure.
            <br />
            <br />
            ¿Deseas continuar?
          </Typography>
          <div className={classes.alertButtonContainer}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onSubmit}>
              Continuar
            </Button>
          </div>
        </div>
      )}

      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_DISTANCE_CONFIRM && (
        <div className={classes.alertContainer}>
          <Typography color='primary' variant='h6' align='center' className={classes.text}>
            Esta distancia no se puede eliminar porque es la única asociada a uno o más tickets,
            para eliminarla debes modificar primero el ticket.
          </Typography>

          <Button color='primary' variant='contained' className={classes.button} onClick={onAccept}>
            Aceptar
          </Button>
        </div>
      )}

      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_DISTANCE_NOT_ALLOWED && (
        <div className={classes.unbindNotifyContainer}>
          <Typography variant='h6' color='primary' className={classes.text}>
            La siguiente distancia:
            <br />
            <ul className={classes.list}>
              <li>{distance.name}</li>
            </ul>
            &nbsp;se encuentra asociada a uno o más tickets creados, al deshabilitarla se borrará de
            todos los tickets en los que figure.
            <br /> <br />
            ¿Deseas continuar?
          </Typography>
          <div className={classes.alertButtonContainer}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onSubmit}>
              Continuar
            </Button>
          </div>
        </div>
      )}

      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_CONFIRM && (
        <div className={classes.alertContainer}>
          <Typography color='primary' variant='h6' align='center' className={classes.text}>
            <strong>
              {category.name} {distance.name}
            </strong>
            &nbsp;no se puede eliminar porque es la única asociada a uno o más tickets, para
            eliminarla debes modificar primero el ticket.
          </Typography>

          <Button color='primary' variant='contained' className={classes.button} onClick={onAccept}>
            Aceptar
          </Button>
        </div>
      )}

      {state === DELETE_ITEM_NOTIFY_DIALOG_STATES.DELETE_NOT_ALLOWED && (
        <div className={classes.unbindNotifyContainer}>
          <Typography variant='h6' color='primary' align='center' className={classes.text}>
            <strong>
              {category.name} {distance.name}
            </strong>
            &nbsp;se encuentra asociado a uno o más tickets creados, al deshabilitarlo se borrará de
            todos los tickets en los que figure.
            <br />
            ¿Deseas continuar?
          </Typography>
          <div className={classes.alertButtonContainer}>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onClose}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              className={classes.button}
              onClick={onSubmit}>
              Continuar
            </Button>
          </div>
        </div>
      )}
    </SectionLayoutDialog>
  )
}

export default DeleteItemNotifyDialog
