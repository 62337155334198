import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  slickSlider: {
    width: '100%',
    margin: '0 auto',
    display: 'inline-block'
  },
  radioButton: {
    marginTop: theme.spacing(6),
    color: theme.palette.primary.main
  }
}))
