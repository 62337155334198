import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    backgroundColor: '#ffff',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    position: 'sticky',
    top: 120,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '90vh'
  },
  filterSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: '1.25em',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&.underlined': {
      borderBottomStyle: 'solid',
      borderBottomWidth: 1
    }
  },
  form: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  searchSection: {
    display: 'flex',
    alignItems: 'center',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    height: 36,
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  inputField: {
    '&:input': {
      borderColor: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.125rem',
      color: theme.palette.primary.main,
      height: 20
    },
    '&:focus': {
      outline: 'none'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      padding: theme.spacing(2),
      marginBottom: 0
    }
  },
  searchField: {
    borderColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      height: 20
    }
  },
  searchButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    },
    margin: theme.spacing(2.8, 0),
    '& svg': {
      width: 20,
      height: 36
    },
    height: '100%',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'stretch',
      margin: 0
    }
  },
  filterTagSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterTag: {
    display: 'flex',
    fontSize: '1.125em',
    fontFamily: theme.typography.fontFamily,
    width: '100%',
    alignItems: 'center',
    '& p': {
      flexGrow: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  },
  closeTag: {
    color: theme.palette.primary.main,
    width: '100%'
  },
  searchText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(),
    fontSize: '1.75em'
  },
  dateField: {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  filterList: {
    listStyle: 'none',
    paddingLeft: theme.spacing(2),
    '& li': {
      lineHeight: '1.75em',
      color: theme.palette.primary.main,
      fontWeight: 500,
      display: 'flex'
    },
    '& li button': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1em',
      fontWeight: '500',
      padding: 0,
      margin: 0,
      color: theme.palette.primary.main,
      border: 'none',
      background: 'none',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'pointer'
    }
  }
}))

export default useStyles
