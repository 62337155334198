import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 1000,
      maxWidth: 1000,
      minHeight: '380px !important'
    }
  },
  content: {
    marginTop: theme.spacing(7),
    fontSize: '1.25rem',
    fontWeight: 500
  }
}))
