import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SectionLayoutDialog } from 'shared'
import { createEventDiscountCode, loadEventDiscountCodes } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { DiscountCodeForm } from '../DiscountCodeForm'

import { useStyles } from './DuplicateDiscountCodeDialog.style'

const FORM_STATES = {
  FORM_UPDATE: 'update'
}

const DuplicateDiscountCodeDialog = ({ open, onClose, discountCode = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event } = useSelector((state) => state.events)

  const handleSaveDuplicate = async (values) => {
    const createDuplicateSuccess = await dispatch(createEventDiscountCode(values, event.id))

    if (createDuplicateSuccess) {
      await dispatch(loadEventDiscountCodes(event.id))

      showSnackbarSuccess('¡Se agrego el código de descuento con éxito!')
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      title='CÓDIGO DUPLICADO'
      open={open}
      onClose={onClose}
      className={classes.dialog}
      contentClassName={classes.container}>
      <DiscountCodeForm
        values={discountCode}
        onSubmit={handleSaveDuplicate}
        mode={FORM_STATES.FORM_UPDATE}
      />
    </SectionLayoutDialog>
  )
}

export default DuplicateDiscountCodeDialog
