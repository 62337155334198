import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { EditableRichText } from 'shared'
import { STRING_EMPTY } from 'utils/constants'

import { useStyles } from './ScheduleDateItem.style'

const ScheduleDateItem = ({
  eventId,
  activeEdit,
  setActiveSectionEdit,
  handleStatusSection,
  saveLoading,
  canEdit,
  enableSection
}) => {
  const classes = useStyles()

  const { values: scheduleItem, setFieldValue, errors, handleSubmit } = useFormikContext()

  return (
    <div className={classes.itemContainer}>
      <div className={classes.headerContainer}>
        <Typography color='primary' variant='h6' className={classes.scheduleTitle}>
          {scheduleItem.title}
        </Typography>

        {canEdit && !scheduleItem.description && !activeEdit && (
          <Typography
            color='primary'
            variant='h6'
            className={classes.addDescriptionLink}
            onClick={() => {
              setActiveSectionEdit(true)
            }}>
            Agregar detalle
          </Typography>
        )}
      </div>

      {(activeEdit || !!scheduleItem.description) && (
        <div className={clsx(classes.editorContainer, activeEdit && 'edit')}>
          <EditableRichText
            entity={scheduleItem}
            placeholder={
              'Escriba el cronograma de su evento lo más detallado posible, recuerde detallar todos los horarios de  cada actividad y aclarar dirección o lugar,  ejemplo: \n08:00 hs Acreditación – Hotel Aconcagua \n08:30 hs Charla Técnica – Hotel Aconcagua \n09:15  hs Largada Categorías Mayores  \n10:30  hs Largada Categorías Infantiles \n12:00  hs Entrega de Premios \n \n Si tiene el cronograma en formato imagen puede subirla en esta sección utilizando el botón  añadir imagen'
            }
            repositoryId={eventId}
            field='description'
            className={classes.editor}
            error={errors.description || errors.descriptionText}
            setStatusSection={handleStatusSection}
            activeModeEdit={activeEdit}
            onChange={(e) => {
              setFieldValue('description', e?.getHTML() || STRING_EMPTY)
              setFieldValue('descriptionText', e?.getText().trim() || STRING_EMPTY)
            }}
          />
        </div>
      )}

      {activeEdit && (
        <Button
          color='primary'
          variant='contained'
          type='submit'
          disabled={saveLoading || !enableSection}
          endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
          onClick={handleSubmit}
          className={classes.saveButton}>
          Guardar
        </Button>
      )}
    </div>
  )
}

export default ScheduleDateItem
