import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.4),
    padding: 0
  },
  container: {
    '& > div > div': {
      height: 737,
      width: 660
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    paddingLeft: theme.spacing(2)
  },
  dialogContent: {
    padding: 0,
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',

      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: theme.utils.rgba(theme.palette.primary.main, 0.45)
    },
    height: 800,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 30
    }
  },
  bodyContainer: {
    padding: theme.spacing(3, 2),
    display: 'flex',
    justifyContent: 'space-around'
  },
  userStateContainer: {
    height: 60,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h6': {
      fontWeight: 600
    }
  },
  info: {
    fontWeight: 500,
    fontSize: '.9rem',
    '& strong': {
      color: theme.utils.rgba(theme.palette.primary.main, 0.47)
    }
  },
  userState: {
    display: 'flex',
    alignItems: 'center'
  },
  tabs: {
    backgroundColor: theme.palette.primary.main,
    borderTop: 'none',
    height: 100,
    [theme.breakpoints.up('sm')]: {
      height: 'auto'
    }
  },
  tab: {
    color: '#ffff',
    textTransform: 'none',
    fontSize: '.9rem',
    fontWeight: 500,
    height: 65,
    [theme.breakpoints.up('sm')]: {
      height: 'auto'
    },
    '&:focus': {
      fontWeight: 600,
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  },
  tabHidden: {
    display: 'none'
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center'
  },
  button: {
    padding: theme.spacing(1, 8),
    alignSelf: 'center',
    margin: theme.spacing(2, 0),
    marginBottom: theme.spacing(5)
  },
  allInscriptionsContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  subtitle: {
    display: 'grid',
    gridTemplateColumns: '0.8fr 1fr',
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    width: '100%',
    height: 50,
    '&.enrolled': {
      height: 60
    }
  },
  subtitleText: {
    display: 'flex',
    justifyContent: 'start',
    fontWeight: 600,
    padding: '7px 0 0 35px',
    fontSize: 14
  }
}))
