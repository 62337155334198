import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  col: {
    height: 40
  },
  button: {
    height: 20,
    width: 70,
    '& span': {
      fontSize: '.7rem'
    }
  },
  link: {
    color: theme.palette.link.main,
    textDecoration: 'none',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  },
  buttonFollowing: {
    height: 20,
    width: 70,
    '& span': {
      fontSize: '.7rem',
      fontWeight: 700
    }
  }
}))
