import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { Typography } from '@material-ui/core'
import { SliderArrow } from 'shared'

import { EventCard } from '../EventCard'
import { EventLoadingPlaceholder } from '../EventLoadingPlaceholder'

import { useStyles } from './EventContainer.style'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const EventContainer = ({
  title,
  fetchNewPage,
  isLoading,
  events,
  allPageUrl,
  showOptions,
  typeCard,
  prevArrowPosition,
  nextArrowPosition,
  autoplay = false,
  infinite = false,
  autoplaySpeed = 5000,
  slidesToShow = 4
}) => {
  const slickRef = useRef(null)
  const classes = useStyles()

  const settings = {
    className: classes.slickSlider,
    dots: false,
    arrows: false,
    infinite,
    speed: 500,
    slidesToShow,
    autoplaySpeed,
    autoplay,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ],
    onEdge: (direction) => {
      if (direction === 'left') {
        fetchNewPage()
      }
    }
  }
  return (
    <div className={classes.eventContainer}>
      <Typography color='primary' variant='h6' align='center' className={classes.title}>
        {title}
      </Typography>

      {!isLoading && events.length === 0 && (
        <div className={classes.noData}>
          <Typography variant='h5'>No se han encontrado eventos</Typography>
        </div>
      )}
      <div className={classes.listContainer}>
        {!isLoading ? (
          <Slider {...settings} ref={slickRef}>
            {events.map((e) => (
              <EventCard typeCard={typeCard} reLoadEvents={fetchNewPage} event={e} key={e.id} />
            ))}
          </Slider>
        ) : (
          <div className={classes.loadingContainer}>
            <EventLoadingPlaceholder />
          </div>
        )}

        <div className={classes.linkContainer}>
          {showOptions && (
            <SliderArrow
              mode='prev'
              position={prevArrowPosition}
              onClick={() => slickRef.current?.slickPrev()}
            />
          )}

          {showOptions && (
            <Link to={allPageUrl} color='primary' className={classes.showAllLink}>
              Ver todos
            </Link>
          )}

          {showOptions && (
            <SliderArrow
              mode='next'
              position={nextArrowPosition}
              onClick={() => slickRef.current?.slickNext()}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EventContainer
