import { makeStyles } from '@material-ui/core'
import sportmetricTeamImage from 'assets/img/landing-sportmetric-team.jpeg'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: '0 auto'
    }
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    height: 280,
    alignItems: 'flex-end',

    [theme.breakpoints.up(507)]: {
      height: 230
    },
    [theme.breakpoints.up('md')]: {
      height: 330
    }
  },
  content: {
    padding: theme.spacing(4, 0),
    paddingBottom: theme.spacing(7),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '36px',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      lineHeight: '55.5px'
    }
  },

  image: {
    backgroundImage: `url(${sportmetricTeamImage})`,
    height: '90vw',
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    [theme.breakpoints.up(540)]: {
      height: 480,
      width: 496
    },
    [theme.breakpoints.up('md')]: {
      height: '42vw',
      maxHeight: 490,
      width: '50%',
      maxWidth: 510
    }
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: -10,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  missing: {
    color: theme.palette.primary.main,
    width: 100,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '36px',
    '& svg': {
      animationDelay: '1500ms !important'
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      lineHeight: '55.5px',
      width: 160
    }
  },
  profesionalice: {
    color: theme.palette.primary.main,
    width: 260,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '36px',

    '& svg': {
      animationDelay: '3500ms !important'
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      lineHeight: '55.5px',
      width: 395
    }
  },
  sport: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.75rem',
    lineHeight: '36px',
    width: 130,

    '& svg': {
      animationDelay: '5500ms !important'
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      width: 200,
      lineHeight: '55.5px'
    }
  },

  '@keyframes backgroundAnimation': {
    '0%': {
      display: 'inline',
      backgroundPosition: '0 0'
    },
    '100%': {
      background: `linear-gradient(to right, #ffff 50%, ${theme.palette.secondary.main} 50%)`,
      backgroundPosition: '-100% 0',
      backgroundSize: '200%'
    }
  }
}))
