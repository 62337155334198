import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import defaultUserImage from 'assets/img/blue-user-mock.png'
import profileOrganizer from 'assets/img/profile-organizer.jpg'
import timeKeeperProfile from 'assets/img/time-keeper-profile.jpg'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { setActiveRole, setAdministratorAccount } from 'state/modules/app'
import { loadOrganizationsAdminAll } from 'state/modules/userAccounts'
import { USER_ROLE } from 'utils/constants'
import { useQuery } from 'utils/hooks'

import { AddUserRoleDialog } from '../AddUserRoleDialog'
import { MenuOption } from '../MenuOption'

import { useStyles } from './UserRoleSection.style'

const ACTION_TYPE = {
  ROLE_ADD: 'roleAdd',
  ROLE_CHANGE: 'roleChange'
}

const addUserRoleDialogPropsInitialValues = {
  open: false,
  onclose: () => {},
  role: {}
}

const USER_SECTION = {
  ADD_USER_ROLE: 'addUserRole',
  CHANGE_USER_ROLE: 'changeUserRole',
  MANAGE_ORGANIZATIONS: 'manageOrganizations'
}

const UserRoleSection = ({ userData = {}, roleColor = {}, onMenuClose = () => {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const verifyOrganizerRol = query.get('verifyOrganizerRol')
  const invitationId = query.get('invitationId')
  const { activeRole, administratorAccount, addRoleState } = useSelector((state) => state.app)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)

  const [activeUserSection, setActiveUserSection] = useState('')

  const [addUserRoleDialogProps, setAddUserRoleDialogProps] = useState(
    addUserRoleDialogPropsInitialValues
  )

  useEffect(() => {
    dispatch(loadOrganizationsAdminAll())
  }, [userData])

  useEffect(() => {
    if (addRoleState?.state) handleOpenAddUserRole(USER_ROLE.ORGANIZER)()
  }, [verifyOrganizerRol, userData, invitationId])

  const { isAthlete, isOrganizer, isTimekeeper, profileImages, username } = userData
  const userImage = Array.isArray(profileImages) ? profileImages[0] : defaultUserImage

  const handleChangeRol = (rol) => () => {
    dispatch(setActiveRole(rol))
    onMenuClose()
    history.push(`${ROUTES.USERS_ACCOUNTS.PROFILE}/${userData.id}`)
  }

  const handleOpenAddUserRole = (role) => () => {
    setAddUserRoleDialogProps({ ...addUserRoleDialogProps, open: true, role })
  }

  const handleChangeAdministratorOrganization = (organization) => (e) => {
    dispatch(setAdministratorAccount({ ...administratorAccount, organization }))
    onMenuClose()
    history.push(`${ROUTES.ORGANIZATIONS.PROFILE}/${organization.handle || organization.id}`)
  }

  return (
    <div className={classes.container}>
      {activeRole === USER_ROLE.ORGANIZER && !!organizationsAdminAll.length && (
        <div
          onTouchMove={() => setActiveUserSection(USER_SECTION.MANAGE_ORGANIZATIONS)}
          onMouseOver={() => setActiveUserSection(USER_SECTION.MANAGE_ORGANIZATIONS)}
          onClick={() => setActiveUserSection(USER_SECTION.MANAGE_ORGANIZATIONS)}
          className={clsx(
            classes.section,
            activeUserSection === USER_SECTION.MANAGE_ORGANIZATIONS && roleColor.value
          )}>
          <div
            className={clsx(
              classes.changeRolHeader,
              activeUserSection === USER_SECTION.MANAGE_ORGANIZATIONS && roleColor.value
            )}>
            <Typography color='primary' variant='h6' className={classes.changeRolTitle}>
              Administrar organización
            </Typography>
          </div>
          <div className={classes.organizationsSection}>
            {organizationsAdminAll
              .sort((a, b) =>
                (a.organization.handle || a.organization.name).localeCompare(
                  b.organization.handle || b.organization.name
                )
              )
              .map((x) => (
                <MenuOption
                  key={x.id}
                  actionType={ACTION_TYPE.ROLE_CHANGE}
                  color={roleColor.value}
                  handleChange={handleChangeAdministratorOrganization(x.organization)}
                  checked={administratorAccount.organization?.id === x.organization.id}
                  imageClassName={classes.image}
                  icon={profileOrganizer}
                  title={`@${x.organization.handle || x.organization.name}`}
                />
              ))}
          </div>
        </div>
      )}
      <div
        onTouchMove={() => setActiveUserSection(USER_SECTION.CHANGE_USER_ROLE)}
        onMouseOver={() => setActiveUserSection(USER_SECTION.CHANGE_USER_ROLE)}
        onClick={() => setActiveUserSection(USER_SECTION.CHANGE_USER_ROLE)}
        className={clsx(
          classes.section,
          activeUserSection === USER_SECTION.CHANGE_USER_ROLE && roleColor.value
        )}>
        {[isAthlete, isOrganizer, isTimekeeper].filter(Boolean).length > 1 && (
          <div
            className={clsx(
              classes.changeRolHeader,
              activeUserSection === USER_SECTION.CHANGE_USER_ROLE && roleColor.value
            )}>
            <Typography color='primary' variant='h6' className={classes.changeRolTitle}>
              Cambiar Rol
            </Typography>
          </div>
        )}

        {isAthlete && activeRole !== USER_ROLE.ATHLETE && (
          <MenuOption
            color={roleColor.value}
            title={`@${username}`}
            handleChange={handleChangeRol(USER_ROLE.ATHLETE)}
            role={`Rol ${USER_ROLE.ATHLETE.label}`}
            imageClassName={classes.image}
            icon={userImage}
            actionType={ACTION_TYPE.ROLE_CHANGE}
          />
        )}

        {isOrganizer && activeRole !== USER_ROLE.ORGANIZER && (
          <MenuOption
            color={roleColor.value}
            actionType={ACTION_TYPE.ROLE_CHANGE}
            title={`@${username}`}
            handleChange={handleChangeRol(USER_ROLE.ORGANIZER)}
            role={`Rol ${USER_ROLE.ORGANIZER.label}`}
            imageClassName={classes.image}
            icon={profileOrganizer}
          />
        )}

        {isTimekeeper && activeRole !== USER_ROLE.TIMEKEEPER && (
          <MenuOption
            color={roleColor.value}
            actionType={ACTION_TYPE.ROLE_CHANGE}
            title={`@${username}`}
            handleChange={handleChangeRol(USER_ROLE.TIMEKEEPER)}
            role={`Rol ${USER_ROLE.TIMEKEEPER.label}`}
            imageClassName={classes.image}
            icon={timeKeeperProfile}
          />
        )}
      </div>
      <div
        onTouchMove={() => setActiveUserSection(USER_SECTION.ADD_USER_ROLE)}
        onMouseOver={() => setActiveUserSection(USER_SECTION.ADD_USER_ROLE)}
        onClick={() => setActiveUserSection(USER_SECTION.ADD_USER_ROLE)}
        className={clsx(
          classes.section,
          activeUserSection === USER_SECTION.ADD_USER_ROLE && roleColor.value
        )}>
        {[!isAthlete, !isOrganizer].some(Boolean) && (
          <div
            className={clsx(
              classes.headerSection,
              activeUserSection === USER_SECTION.ADD_USER_ROLE && roleColor.value
            )}>
            <Typography variant='h6' color='primary' className={classes.title}>
              Agregar Rol
            </Typography>
          </div>
        )}

        <AddUserRoleDialog
          {...addUserRoleDialogProps}
          userData={userData}
          onClose={() => setAddUserRoleDialogProps(addUserRoleDialogPropsInitialValues)}
        />

        {!isAthlete && (
          <MenuOption
            color={roleColor.value}
            title={USER_ROLE.ATHLETE.label}
            handleChange={handleOpenAddUserRole(USER_ROLE.ATHLETE)}
            imageClassName={classes.image}
            icon={userImage}
          />
        )}

        {!isTimekeeper && (
          <MenuOption
            color={roleColor.value}
            hidden={true}
            title={USER_ROLE.TIMEKEEPER.label}
            imageClassName={classes.image}
            icon={timeKeeperProfile}
          />
        )}

        {!isOrganizer && (
          <MenuOption
            color={roleColor.value}
            handleChange={handleOpenAddUserRole(USER_ROLE.ORGANIZER)}
            title={USER_ROLE.ORGANIZER.label}
            imageClassName={classes.image}
            icon={profileOrganizer}
          />
        )}
      </div>
    </div>
  )
}

export default UserRoleSection
