import React from 'react'
import ReactBreakpoints from 'react-breakpoints'
import ReactDOM from 'react-dom'
import ReactNotification from 'react-notifications-component'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { GoogleOAuthProvider } from '@react-oauth/google'
import moment from 'moment'
import { initFacebookSdk } from 'services/FacebookService'
import { ScrollToTop } from 'utils'

import theme from './config/theme'
import store from './state/store'
import { App } from './App'

import 'react-notifications-component/dist/theme.css'
import './index.css'
// globally set moment localization
// const locale = (window.navigator.userLanguage || window.navigator.language).substring(0, 2)
// require(`moment/locale/${locale}`)
// moment.locale(locale)

const locale = 'es' // (window.navigator.userLanguage || window.navigator.language).substring(0, 2)
require(`moment/locale/${locale}`)
moment.locale(locale)

const breakpoints = {
  xs: 320,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
}

initFacebookSdk()
  .then(startApp())
  .catch((e) => {
    console.error('Error loading Facebook', e)
    startApp()
  })

function startApp() {
  const root = (
    <Provider store={store}>
      <ReactNotification />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <HashRouter>
              <ScrollToTop />
              <ReactBreakpoints breakpoints={breakpoints}>
                <App />
              </ReactBreakpoints>
            </HashRouter>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </GoogleOAuthProvider>
    </Provider>
  )

  ReactDOM.render(root, document.getElementById('root'))
}
