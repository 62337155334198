import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 160
  },
  select: {
    width: '100%',
    height: 40
  },
  title: {
    width: '20%',
    height: 15,
    [theme.breakpoints.up('sm')]: {
      width: '12%'
    }
  },
  subtitle: {
    width: '80%',
    height: 11,
    [theme.breakpoints.up('sm')]: {
      width: '90%'
    }
  }
}))
