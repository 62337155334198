import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Typography,
  useMediaQuery
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import clsx from 'clsx'
import { EllipseStatus } from 'shared'
import {
  loadEventInscriptionsAllForCurrentUser,
  loadEventInscriptionsForCurrentUser,
  resetEventInscriptionsForCurrentUser
} from 'state/modules/events'

import { InscriptionList } from '../InscriptionList'

import { useStyles } from './StateInscriptionDialog.style'

const StateInscriptionDialog = ({ open, onClose, title, inscriptionsCount }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tabSelected, setTabSelected] = useState('1')
  const { user } = useSelector((state) => state.auth)
  const {
    event,
    eventInscriptionsForCurrentUserPending,
    eventInscriptionsForCurrentUser,
    eventInscriptionAllForUserPageLastPage,
    eventInscriptionAllForUserPageNumber,
    eventInscriptionAllForUser,
    eventInscriptionAllForUserPending
  } = useSelector((state) => state.events)

  const containerRef = useRef(null)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const activeLoading =
    (!eventInscriptionsForCurrentUser &&
      !eventInscriptionsForCurrentUserPending &&
      !eventInscriptionAllForUser?.length) ||
    eventInscriptionsForCurrentUserPending

  useEffect(() => {
    if (user && event) {
      dispatch(loadEventInscriptionsForCurrentUser(event.id))
    }

    return () => {
      dispatch(resetEventInscriptionsForCurrentUser())
    }
  }, [dispatch, event, user])

  useEffect(() => {
    if (!eventInscriptionsForCurrentUser || !eventInscriptionsForCurrentUser.inscription) {
      setTabSelected('2')
    } else {
      setTabSelected('1')
    }
  }, [eventInscriptionsForCurrentUser])

  const handleTabsChange = (event, newValue) => setTabSelected(newValue)

  const renderLoading = () => (
    <div className={classes.loadingContainer}>
      <CircularProgress color='primary' size={46} />
    </div>
  )
  const handleMoreShow = async () => {
    await dispatch(
      loadEventInscriptionsAllForCurrentUser(event.id, eventInscriptionAllForUserPageNumber)
    )
  }

  const renderContentDialog = () => {
    const { inscription, inscriptionsSameCategory } = eventInscriptionsForCurrentUser

    const stateInscription = (state) => {
      switch (state) {
        case 'Pending':
          return (
            <>
              <EllipseStatus status='yellow' />
              &nbsp;PREINSCRIPTO
            </>
          )
        case 'Accepted':
          return (
            <>
              <EllipseStatus status='green' />
              &nbsp;INSCRIPTO
            </>
          )
        case 'Cancelled':
          return (
            <>
              <EllipseStatus status='red' />
              &nbsp;ANULADO
            </>
          )

        default:
          return <></>
      }
    }

    return (
      <>
        {inscription && (
          <div className={classes.bodyContainer}>
            <div className={classes.userStateContainer}>
              <Typography color='primary' variant='h6'>
                MI ESTADO
              </Typography>
              <Typography color='primary' variant='h6' className={classes.userState}>
                {stateInscription(inscription.state)}
              </Typography>
            </div>
            <div className={classes.userStateContainer}>
              <Typography className={classes.info}>
                <strong>Distancia:</strong>&nbsp;
                {inscription.distance.name}
              </Typography>
              <Typography className={classes.info}>
                <strong>Categoría:</strong>&nbsp;
                {inscription.category.name}
              </Typography>
            </div>
          </div>
        )}

        <TabContext id='scroll-dialog-description' value={tabSelected}>
          <TabList
            className={classes.tabs}
            centered
            onChange={handleTabsChange}
            indicatorColor='secondary'
            variant='fullWidth'
            textColor='secondary'>
            <Tab
              className={clsx(classes.tab, !inscriptionsSameCategory && classes.tabHidden)}
              label={`Mi categoría (${inscriptionsSameCategory?.length})`}
              color='secondary'
              value='1'
              selected
            />

            <Tab
              className={classes.tab}
              label={`Todos los inscriptos (${inscriptionsCount})`}
              color='secondary'
              value='2'
              selected
            />
          </TabList>
          <div className={clsx(classes.subtitle, inscription && 'enrolled')}>
            <Typography color='primary' className={classes.subtitleText}>
              Nombre
            </Typography>
            {tabSelected === '2' && (
              <Typography color='primary' className={classes.subtitleText}>
                Distancia y categoría
              </Typography>
            )}
          </div>
          <DialogContent className={classes.dialogContent} ref={containerRef}>
            <TabPanel value='1' color='primary' style={{ padding: 0 }}>
              {inscriptionsSameCategory && (
                <InscriptionList
                  title={`${inscription.category.name} - ${inscription.distance.name}`}
                  category
                  listInscription={
                    inscriptionsSameCategory.map((e) => ({
                      fullName: `${e.lastName} ${e.firstName}`,
                      id: e.inscriptionUserId,
                      loggedUserMetadata: e.loggedUserMetadata
                    })) || []
                  }
                />
              )}
            </TabPanel>

            <TabPanel value='2' color='primary' className={classes.allInscriptionsContainer}>
              {!!eventInscriptionAllForUser.length && (
                <>
                  <InscriptionList
                    hiddenHeader={!inscriptionsSameCategory}
                    listInscription={eventInscriptionAllForUser.map((e) => ({
                      fullName: `${e.lastName} ${e.firstName}`,
                      distanceAndCategory: `${e.distance.name}-${e.category.name}`,
                      id: e.inscriptionUserId,
                      loggedUserMetadata: e.loggedUserMetadata
                    }))}
                  />
                  {!eventInscriptionAllForUserPageLastPage && !eventInscriptionAllForUserPending && (
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.button}
                      onClick={handleMoreShow}>
                      Ver más
                    </Button>
                  )}
                  {eventInscriptionAllForUserPending && (
                    <div className={classes.loadingContainer}>
                      <CircularProgress size={30} />
                    </div>
                  )}
                </>
              )}
            </TabPanel>
          </DialogContent>
        </TabContext>
      </>
    )
  }
  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      fullWidth
      fullScreen={isMobile}
      open={open}
      maxWidth='md'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      onClose={onClose}
      scroll='paper'
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography
            color='primary'
            align='center'
            variant='h6'
            className={classes.title}
            title={title}>
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon color='secondary' />
          </IconButton>
        </div>
      </DialogTitle>
      {activeLoading ? renderLoading() : renderContentDialog()}
    </Dialog>
  )
}

export default StateInscriptionDialog
