import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1rem'
  },
  subtitle: {
    fontSize: '1.1rem',
    fontWeight: 600,
    color: '#696969'
  },
  mailInfo: {
    fontWeight: 600,
    color: '#696969'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      width: '85%',
      margin: '0 auto'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 800,
      maxWidth: 800,
      margin: '0 auto'
    }
  },
  span: {
    color: 'blue'
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'space-between',
    marginRight: theme.spacing(),
    marginTop: 20
  },
  button: {
    width: 210
  },
  inscriptionContainer: {
    minHeight: 150
  },
  option: {
    fontWeight: 600,
    alignItems: 'center',
    '& span': {
      fontSize: '1rem',
      color: '#696969',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  circleState: {
    flexShrink: 0,
    width: 11,
    height: 11
  },
  inscriptionFullName: {
    fontWeight: 600,
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  prevState: {
    width: 110
  },
  newState: {
    width: 150
  },
  arrowIcon: {
    color: 'black',
    width: 50,
    height: 30
  }
}))
