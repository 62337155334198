import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { deleteEventDiscountCode, loadEventDiscountCodes } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './DeleteDiscountCodeDialog.style'

const DeleteDiscountCodeDialog = ({ open, onClose, discountCode = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { event } = useSelector((state) => state.events)

  const [deleteEventDiscountCodePending, setDeleteEventDiscountCodePending] = useState(false)

  const handleDelete = async () => {
    setDeleteEventDiscountCodePending(true)

    const deleteSuccess = await dispatch(deleteEventDiscountCode(discountCode.id, event.id))

    if (deleteSuccess) {
      await dispatch(loadEventDiscountCodes(event.id))
      showSnackbarSuccess('¡Código de descuento eliminado con éxito!')
      setDeleteEventDiscountCodePending(false)
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      title='ELIMINAR CÓDIGO'
      className={classes.dialog}
      contentClassName={classes.container}>
      <Typography className={classes.title} color='primary' variant='h6'>
        ¿Está seguro que desea eliminar el código de descuento {discountCode.name}?
      </Typography>

      <div className={classes.buttonsContainer}>
        <Button
          color='primary'
          variant='contained'
          onClick={onClose}
          className={classes.button}
          disabled={deleteEventDiscountCodePending}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          disabled={deleteEventDiscountCodePending}
          onClick={handleDelete}
          endIcon={deleteEventDiscountCodePending && <CircularProgress color='primary' size={16} />}
          className={classes.button}>
          Eliminar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default DeleteDiscountCodeDialog
