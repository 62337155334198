import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  container: {
    width: '85%',
    [theme.breakpoints.up('md')]: {
      width: '80%'
    }
  },
  text: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    alignSelf: 'center',
    width: '80%'
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    justifyContent: 'space-between'
  },
  button: {
    width: '35%',
    minWidth: 160
  }
}))
