import React from 'react'
import { useFormikContext } from 'formik'
import { SectionLayoutDialog } from 'shared'

import { TicketForm } from '../TicketForm'

import { useStyles } from './CreateTicketDialog.style'

const STATE_FORM = 'create'

const CreateTicketDialog = ({ open, onClose, items, onSubmit, eventIsFree }) => {
  const classes = useStyles()
  const { values: formState } = useFormikContext()

  const values = {
    name: '',
    quota: '',
    price: '',
    mercadoPagoEnabled: false,
    bankAccountsEnabled: false,
    paymentLocationsEnabled: false,
    bearCost: false,
    saleFrom: null,
    saleTo: null,
    saleToDate: null,
    saleToTime: null,
    distancesOrCategoriesItems: {},
    associatedBy: formState.associatedBy,
    eventIsFree,
    mercadoPagoPrice: 0,
    mercadoPagoFee: 0,
    sportmetricFeeAmount: 0,
    sportmetricFeePercentage: 0,
    paymentUrl: ''
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='CREAR TICKET'
      className={classes.dialog}
      contentClassName={classes.container}>
      {(activeScrolling) => (
        <TicketForm
          values={values}
          onSubmit={onSubmit}
          items={items}
          eventIsFree={eventIsFree}
          state={STATE_FORM}
          activeScrolling={activeScrolling}
        />
      )}
    </SectionLayoutDialog>
  )
}

export default CreateTicketDialog
