import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons'
import FavoriteIcon from '@material-ui/icons/Favorite'
import moment from 'moment'
import { ROUTES } from 'routes'

import { useStyle } from './RunnerCalendar.style'

const RunnerCalendar = ({ nextEvent }) => {
  const classes = useStyle()
  const { from, likesCount, name, countryName, areaLevel1Name, slugUrl } = nextEvent
  const fromLocal = moment(from)
  const like = false
  const locationEvent = [areaLevel1Name, countryName].filter((e) => !!e).join(', ')
  return (
    <div className={classes.container}>
      <Typography color='primary' className={classes.calendarDate}>
        {fromLocal.format('DD MMM YYYY').replace('.', '')}
      </Typography>
      <div>
        <div className={classes.event}>
          <Typography title={name} variant='h6' color='primary'>
            {name}
          </Typography>
        </div>
        <div className={classes.areaLevel}>
          <Typography variant='h6' color='primary'>
            {locationEvent}
          </Typography>
        </div>
      </div>
      <div className={classes.social}>
        <div className={classes.containerLike}>
          {like ? (
            <FavoriteIcon color='primary' className={classes.iconFavorite} />
          ) : (
            <FavoriteBorderIcon color='primary' className={classes.iconFavorite} />
          )}
          <Typography style={{ fontSize: '1.2em' }} variant='h6' color='primary'>
            {likesCount}
          </Typography>
        </div>
        <div className={classes.containerLink}>
          <Link className={classes.linkResult} to={`${ROUTES.EVENTS.PROFILE}/${slugUrl}`}>
            ver evento
          </Link>
        </div>
      </div>
    </div>
  )
}

export default RunnerCalendar
