import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  form: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  numericGroup: {
    width: 210,
    marginTop: theme.spacing()
  },
  label: {
    fontSize: '.875rem'
  },
  groupBlock: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(10),
      '& > div': {
        width: 210
      },
      flexDirection: 'row'
    }
  },
  textField: {
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& input': {
      height: 3,
      paddingLeft: theme.spacing(),
      fontSize: '.825rem'
    }
  },
  percentageField: {
    '& > div': {
      paddingLeft: theme.spacing()
    }
  },
  inputDateField: {
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(3),
    width: '100%',
    '& input': {
      fontSize: '0.85rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40
    },
    '& div > button': {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: '#ffff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffff'
      },
      width: 40,
      height: 40
    }
  },
  dateField: {
    '& input': {
      padding: theme.spacing(1.1, 1.8)
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main,
      width: 14,
      height: 14
    }
  },
  radioLabel: {
    fontWeight: 600,
    fontSize: '.875rem'
  },
  validDiscountTitle: {
    textDecoration: 'underline',
    fontWeight: 600,
    fontSize: '.875rem',
    marginBottom: theme.spacing()
  },
  button: {
    alignSelf: 'center',
    width: 205,
    height: 41,
    marginBottom: theme.spacing()
  },
  percentageAdornment: {
    margin: 0,
    '& p': {
      fontSize: '.875rem'
    }
  },
  messageError: {
    marginLeft: 14
  },
  validDateHelperText: {
    fontSize: '.875rem',
    marginTop: theme.spacing(0.5)
  }
}))
