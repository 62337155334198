import React from 'react'
import { TextField } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './CustomTextField.style'

const CustomTextField = ({ className, ...restProps }) => {
  const classes = useStyles()

  return (
    <TextField
      {...restProps}
      type='text'
      className={clsx(classes.customTextField, className)}
      onWheel={(e) => e.target.blur()}
    />
  )
}

export default CustomTextField
