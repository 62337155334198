import React from 'react'
import { Divider, Typography } from '@material-ui/core'

import { useStyles } from './PrivacyPolicyContent.style'

const PrivacyPolicyContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.privacyPolicyContent}>
      <Typography variant='h2' color='primary' className={classes.title}>
        El uso de la plataforma Sportmetric implica la aceptación de esta Política de Privacidad
      </Typography>
      <Typography variant='h4' className={classes.reason}>
        Con la finalidad de llevar a cabo la prestación de los servicios ofrecidos a través
        Sportmetric, tratara los datos personales de sus clientes de forma automatizada.
      </Typography>
      <Divider />
      <Typography variant='h6' className={classes.subTitle}>
        Confidencialidad
      </Typography>
      <Typography className={classes.text}>
        Sportmetric queda liberado de responsabilidad y culpa si el Organizador, sponsors o
        cualquier persona ajena a Sportmetric revelaran sus datos personales.
        <br />
        <br />
        Sportmetric garantiza que tratara confidencialmente sus datos personales, así como que el
        servidor en el que se almacenaran y trataran los datos, gozan de las medidas de seguridad
        establecidas en la normativa Argentina sobre protección de datos. Cuando recogemos su
        información personal (es decir nombre, dirección, número de teléfono, etc.) es para hacer un
        esfuerzo de mejorar su experiencia en las inscripciones y de notificarle sobre
        actualizaciones del programa, servicios y respuestas, más allá de la actual organización
        para su evento, es nuestra política no divulgar cualquiera de su información personal en
        cualquier momento, a menos que usted nos autorizara a lo contrario.
        <br />
        <br />
        Sportmetric no vende, no negocia, ni alquila su información personal a terceros, asimismo
        Sportmetric no se responsabilizará si el organizador realiza estas acciones con su
        información personal.
        <br />
        <br />A medida que pase el tiempo, se agregará y compartirá la información (colectiva) de
        los miembros como grupo, para generar informes y estudios de mercado para los socios del
        negocio. Estos informes no identificarán a miembros individuales sino al conjunto.
        <br />
        Para suministrar un excelente servicio y para que los usuarios puedan realizar inscripciones
        en forma ágil y segura, Sportmetric requiere cierta información de carácter personal,
        incluyendo dirección de e-mail. La recolección de información nos permite ofrecer a los
        usuarios servicios y funcionalidades que se adecuen mejor a sus necesidades y personalizar
        nuestros servicios para hacer que sus experiencias con Sportmetric sean lo más cómodas
        posible. La información personal que recabamos tiene las siguientes finalidades:
        <br />
        <br />
        Ayudar al inscripto y Organizador a entrar en contacto directo en la oportunidad que
        corresponda según la modalidad de la compra. En este caso, Sportmetric suministrará a los
        interesados sus datos personales (nombre, teléfonos, localidad y correo electrónico), a
        través de correo electrónico o a través del sitio.
        <br />
        <br />
        Desarrollar estudios internos sobre los intereses, comportamientos y demografía de los
        usuarios para comprender mejor sus necesidades e intereses y ofrecer mejores servicios o
        proveerles información relacionada.
      </Typography>
      <Typography variant='h6' className={classes.subTitle}>
        Menores de edad
      </Typography>
      <Typography className={classes.text}>
        Nuestros servicios sólo están disponibles para aquellas personas que tengan capacidad legal
        para contratar, quienes no tengan esa capacidad por minoría de edad, la responsabilidad en
        la determinación de los servicios y contenidos a los que acceden los menores de edad
        corresponde a los mayores a cuyo cargo se encuentren.
      </Typography>
      <Typography variant='h6' className={classes.subTitle}>
        Uso de cookies
      </Typography>
      <Typography className={classes.text}>
        Nuestro sistema utiliza cookies para personalizar y para mejorar su experiencia de compras.
        <br />
        <br />
        Una cookie es un archivo de texto muy pequeño que un servidor Web pueden guardar en el disco
        duro de un equipo para almacenar algún tipo de información sobre el usuario. Solo puede ser
        leída por el sitio Web que lo envió al equipo.
        <br />
        <br />
        Normalmente los sitios Web utilizan las cookies para obtener información estadística sobre
        sus páginas Web, y analizar el comportamiento de sus clientes.
        <br />
        <br />
        Una cookie no es un archivo ejecutable ni puede propagar o contener un virus, y no puede
        tener una longitud superior a 4000 caracteres.
        <br /> Sportmetric utiliza cookies con la exclusiva finalidad de elaborar estadísticas de
        utilización de sus sitios Web. La información que Sportmetric almacena en su equipo mediante
        este mecanismo consiste únicamente identificarle a usted como un usuario concreto y permite
        guardar sus preferencias personales y la fecha en la que se depositó la cookie. Esto
        permitirá reconocerle en sus próximas visitas a nuestro sitio Web. Estas cookies no
        contienen ninguna información confidencial o personal,permiten solo realzar su experiencia
        de usuario en Sportmetric.
        <br />
        Usted puede configurar su navegador para aceptar, o no las cookies que recibe o para que el
        navegador le avise cuando un servidor quiere guardar una cookie.
        <br />
        <br />
        El usuario y el visitante del sitio Web de Sportmetric conoce y acepta que Sportmetric podrá
        utilizar un sistema de seguimiento mediante la utilización de cookies.
      </Typography>
      <Typography variant='h6' className={classes.subTitle}>
        Seguridad
      </Typography>
      <Typography className={classes.text}>
        Sportmetric ha adoptado los niveles de seguridad de protección de los datos personales
        legalmente requeridos, y ha instalado todos los medios y medidas técnicas a su alcance para
        evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales
        facilitados. No obstante ello, el usuario debe ser consciente de que las medidas de
        seguridad en Internet no son inexpugnables como así también que no le corresponde ninguna
        responsabilidad a Sportmetric por la filtración y/o distorsión y/o mal uso de los datos
        recolectados.
        <br />
        Sportmetric podrá suspender o inhabilitar definitivamente a aquellos usuarios que utilicen
        este servicio con los fines de promocionar sus artículos a la venta o cualquier otro fin
        distinto al previsto por este servicio y que sean denunciados por aquellas personas que
        reciben tales mensajes.
        <br />
        Cualquier persona que no desee recibir estas comunicaciones por e-mail, deberá requerirlo.
      </Typography>
      <Typography variant='h6' className={classes.subTitle}>
        Cambie su información personal
      </Typography>
      <Typography className={classes.text}>
        Nuestro sistema le permite a los usuarios cambiar y modificar la información suministrada
        anteriormente a través de la sección “Mi perfil”.
      </Typography>
      <Typography variant='h6' className={classes.subTitle}>
        Cambios y aceptación de la política
      </Typography>
      <Typography className={classes.text}>
        La utilización de Sportmetric, en cualquiera de sus secciones, implica la aceptación de
        nuestra política de privacidad. Si el usuario no está de acuerdo con esta política, por
        favor no utilice el sistema. De igual forma, si el usuario continúa haciendo uso de
        Sportmetric después de que hayamos introducido cualquier modificación a esta política, ello
        implicara su aceptación automática de esas modificaciones.
        <br />
        <br />
        Sportmetric se reserva el derecho a modificar la presente política para adaptarla a
        novedades legislativas o jurisprudenciales, así como a prácticas de la industria. en dichos
        supuestos, Sportmetric anunciará en el sistema los cambios introducidos los que tendrán
        vigencia desde el mismo momento de su publicación on-line, por lo que se recomienda a los
        usuarios leer las presentes condiciones cada vez que accedan al sistema.
        <br />
        <br />
        Si usted tiene algunas preguntas o preocupaciones con respecto a nuestra política de
        privacidad, por favor siéntase libre de contactarnos en&nbsp;
        <a href='mailto:info@sportmetric.net' target='_blank' rel='noopener noreferrer'>
          info@sportmetric.net
        </a>
      </Typography>
    </div>
  )
}

export default PrivacyPolicyContent
