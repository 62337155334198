import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Typography } from '@material-ui/core'
import { LayoutDialog } from 'shared'

import { useStyles } from './MercadoPagoCancelledPaymentDialog.style'

const MercadoPagoCancelledPaymentDialog = ({ open, onAccept }) => {
  const { event } = useSelector((state) => state.events)
  const { name, organization } = event

  const classes = useStyles()

  return (
    <LayoutDialog
      title='PROBLEMAS CON EL PAGO'
      open={open}
      className={classes.dialog}
      titleClassName={classes.title}
      contentClassName={classes.container}>
      <main className={classes.mainContainer}>
        <Typography variant='h6' className={classes.text}>
          La inscripción al evento <span>{name}</span> organizado por{' '}
          <span>{organization.name}</span> no pudo concretarse por problemas en el pago.
        </Typography>
        <Typography variant='h6' className={classes.text}>
          Si consideras que esto es un error por favor verificá tu estado de inscripción antes de
          retomar la inscripción.
        </Typography>
        <Typography variant='h6' className={classes.text}>
          Te enviaremos por email un link con el formulario completo, para que retomes la
          inscripción, podrás si es necesario modificar la modalidad de pago.
        </Typography>
        <Typography variant='h6' className={classes.textBottom}>
          *La inscripción queda sujeta a la disponibilidad de cupo.
        </Typography>
        <Button color='primary' variant='contained' onClick={onAccept} className={classes.button}>
          Aceptar
        </Button>
      </main>
    </LayoutDialog>
  )
}

export default MercadoPagoCancelledPaymentDialog
