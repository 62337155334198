import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.25)
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700
    }
  },
  container: {
    width: '85%',
    [theme.breakpoints.up('sm')]: {
      width: '75%'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing()
  },
  title: {
    fontWeight: 600
  },
  paymentType: {
    fontWeight: 600
  },
  paymentMethodContainer: {
    borderRadius: 10,
    marginTop: theme.spacing(),
    padding: theme.spacing(2, 4),
    paddingLeft: '15%',
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.4),
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
  },
  changePaymentType: {
    margin: theme.spacing(2, 0)
  },
  otherPaymentTypeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  paymentOptions: {
    marginBottom: theme.spacing(5)
  },
  paymentTypeButton: {
    minWidth: 220,
    height: 40
  },
  mercadoPagoPaymentCredencialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    marginTop: theme.spacing(4)
  },
  mercadoPagoPaymentButton: {
    alignSelf: 'center',
    width: 200,
    height: 40
  },
  paymentUrl: {
    color: theme.palette.primary.main
  },
  selectPaymentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  selectPaymentHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  selectPaymentTitle: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  selectPaymentHelperText: {
    fontSize: '.925rem'
  },
  paymentText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  helperText: {
    fontWeight: 500
  }
}))
