import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { Footer, MainLayout, PageContainer } from 'shared'
import { verifyUserLogged } from 'state/modules/app'
import { resetPassword } from 'state/modules/auth'

import { ResetPasswordForm } from './components'
import { useStyle } from './ResetPasswordPage.style'

const ResetPasswordPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const { token } = useParams()
  const dispatch = useDispatch()
  const [resetComplete, setResetComplete] = useState(false)
  const { error } = useSelector((state) => state.auth)

  const handleSubmit = async ({ newPassword, email }) => {
    const resetPasswordModel = {
      email,
      token,
      newPassword
    }
    const resetPasswordSuccess = await dispatch(resetPassword(resetPasswordModel))
    if (resetPasswordSuccess) {
      setResetComplete(true)
      setTimeout(() => {
        resetPasswordSuccessAction()
      }, 3000)
    }
  }

  const resetPasswordSuccessAction = () => {
    history.push(ROUTES.ROOT)
    dispatch(verifyUserLogged({ state: true }))
  }

  return (
    <MainLayout>
      <PageContainer customContainerClassName={classes.container}>
        <Link to={ROUTES.ROOT}>
          <div className={classes.logo}></div>
        </Link>
        <Typography variant='h2' className={classes.title}>
          Recuperar Contraseña
        </Typography>

        <div className={classes.formContainer}>
          {!resetComplete ? (
            <ResetPasswordForm
              onSubmit={handleSubmit}
              resetError={error && error.response && error.response.data}
            />
          ) : (
            <>
              <Typography>
                ¡La contraseña se ha actualizado con éxito! Aguarda un momento mientras te dirigimos
                a la pantalla de Inicio de Sesión...
              </Typography>
              <Typography>
                Si no te redirige automáticamente, haz clic en&nbsp;
                <Link to='#' onClick={resetPasswordSuccessAction}>
                  Iniciar sesión
                </Link>
              </Typography>
            </>
          )}
        </div>
      </PageContainer>
      <Footer altMode />
    </MainLayout>
  )
}

export default ResetPasswordPage
