import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  readMoreBox: {
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative'
  },
  readMoreBoxRevealed: {
    maxHeight: 'none',
    overflow: 'visible'
  },
  readMoreButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    '& button': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '1.125em',
      width: '100%',
      border: 'none',
      background: 'none',
      color: theme.palette.primary.main,
      textAlign: 'left',
      textDecoration: 'underline'
    }
  },
  sectionContainer: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #E6E6E6',
    marginBottom: theme.spacing(1),
    '& h3': {
      fontWeight: 600,
      fontSize: '1.25em',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      color: theme.palette.primary.main
    },
    '& > div': {
      '& p': {
        color: theme.palette.primary.main
      }
    }
  }
}))

export default useStyles
