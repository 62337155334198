import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SocialIcon } from 'react-social-icons'
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core'
import { Instagram as InstagramIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import moment from 'moment'
import { CountriesProxy, UserAccountsProxy } from 'services'
import { SectionLayoutDialog } from 'shared'
import { updateUserAccount } from 'state/modules/userAccounts'
import { STRING_EMPTY, USER_ROLE } from 'utils/constants'
import { getCountries, removeDiacritics, searchDisciplines } from 'utils/functions'
import { showSnackbarError } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './FormEditDialog.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
const OTHER_OPTION = { label: 'Otra', value: 'other' }

const FormEditDialog = ({ open, onClose, values }) => {
  const socialSize = { height: 48, width: 48 }
  const { userAccount } = useSelector((state) => state.userAccounts)
  const { activeRole } = useSelector((state) => state.app)

  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()

  const [countries, setCountries] = useState([])
  const [provinces, setProvinces] = useState([])
  const [cities, setCities] = useState([])
  const [checkSocialMedia, setCheckSocialMedia] = useState({
    facebookUrl: !!values.facebookUrl,
    instagramUrl: !!values.instagramUrl,
    youTubeUrl: !!values.youTubeUrl
  })
  const [disciplines, setDisciplines] = useState([])

  const empty = { value: '', label: '--Seleccione--' }
  const color = activeRole === USER_ROLE.ORGANIZER ? 'secondary' : 'primary'

  const formValues = {
    ...values,
    areaLevel1Id: values.areaLevel1Id ? values.areaLevel1Id : '',
    areaLevel2Other: values.areaLevel2Id ? null : values.areaLevel2Other,
    areaLevel2Id:
      values.areaLevel2Other !== null && values.areaLevel2Other !== STRING_EMPTY
        ? OTHER_OPTION.value
        : values.areaLevel2Id,
    disciplines: values.disciplines.map((x) => ({ label: x.name, value: x.id }))
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .transform((value) => removeDiacritics(value))
      .max(256, 'El nombre tiene un máximo de 256 caracteres')
      .required('Ingresa tu nombre')
      .matches(/^[a-z ,.-]+$/i, 'El nombre no debe tener caracteres especiales ni números')
      .trim(),
    lastName: Yup.string()
      .transform((value) => removeDiacritics(value))
      .max(256, 'El apellido tiene un máximo de 256 caracteres')
      .required('Ingresa tu apellido')
      .matches(/^[a-z ,.-]+$/i, 'El apellido no debe tener caracteres especiales ni números')
      .trim(),
    username: Yup.string()
      .min(3, 'El nombre de usuario debe tener como mínimo 3 caracteres') // Add
      .max(30, 'El nombre de usuario tiene un máximo 30 caracteres')
      .matches(
        /^[a-z0-9]+$/i,
        'El nombre de usuario no debe tener caracteres especiales ni espacios'
      )
      .required('Ingresa tu nombre de usuario')
      .test(
        'username-already-exists',
        'Este nombre de usuario ya está registrado.',
        async function (username) {
          if (username === userAccount?.username) return true

          try {
            const proxy = new UserAccountsProxy()
            const response = await proxy.existsUsernameEmail({
              UsernameOrEmail: username
            })

            return !response
          } catch (e) {
            return true
          }
        }
      ),
    areaLevel1Id: Yup.mixed().when('countryId', {
      is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
      then: Yup.string().required('Debe elegir una provincia')
    }),
    idNumber: Yup.string()
      .required('El DNI es obligatorio')
      .min(6, 'El DNI debe poseer como mínimo 6 caracteres')
      .max(15, 'El DNI debe poseer como máximo 15 caracteres')
      .matches(/^[a-z0-9]+$/i, 'El DNI no debe tener caracteres especiales ni espacios')
      .trim()
      .test(
        'idNumber-already-exists',
        'Este dni ya se encuentra en uso',
        async (idNumber, { parent }) => {
          if (idNumber === userAccount?.idNumber.toUpperCase()) return true
          try {
            const { countryId } = parent
            const proxy = new UserAccountsProxy()
            const response = await proxy.existsIdNumber(idNumber, countryId)
            return !response
          } catch (e) {
            return true
          }
        }
      ),
    birthdate: Yup.string()
      .nullable()
      .required('Seleccione su fecha de nacimiento')
      .test(
        'test-age',
        'La edad permitida es entre 12 y 120 años',
        (value) =>
          !!value && moment().diff(value, 'years') >= 12 && moment().diff(value, 'years') <= 120
      ),
    weight: Yup.number()
      .nullable()
      .typeError('Ingrese un número válido')
      .integer('El peso no puede contener decimales')
      .positive('El peso no puede ser negativo')
      .min(10, 'El peso no puede ser menor a 10Kg')
      .max(350, 'El peso no puede ser mayor a 350Kg'),
    height: Yup.number()
      .nullable()
      .typeError('Ingrese un número válido')
      .integer('La altura no puede contener decimales')
      .positive('La altura no puede ser negativa')
      .min(50, 'La altura no puede ser menor a 50cm')
      .max(250, 'La altura no puede ser mayor a 250cm')
  })

  useEffect(() => {
    const fetchData = async () => {
      const countriesData = await handleGetCountries()
      setCountries(countriesData)
    }
    fetchData()
    getAreaLevels()
    getDisciplines()
  }, [])

  const getDisciplines = async () => {
    const result = await searchDisciplines()
    setDisciplines(result)
  }

  const getAreaLevels = async () => {
    try {
      if (values.countryId) {
        const proxy = new CountriesProxy()
        const dataAreaLevel1 = await proxy.getAreaLevel1(values.countryId)

        if (values.countryId !== ARGENTINA_COUNTRY.value) {
          setProvinces([empty, ...dataAreaLevel1.map((d) => ({ value: d.id, label: d.name }))])
        } else {
          setProvinces(dataAreaLevel1.map((d) => ({ value: d.id, label: d.name })))
        }

        if (values.areaLevel1Id) {
          const dataAreaLevel2 = await proxy.getAreaLevel2(values.areaLevel1Id)
          setCities(dataAreaLevel2)
        }
      }
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const onDisciplinesChange = (setFieldValue) => (options) => setFieldValue('disciplines', options)

  const handleGetCountries = async () => {
    const getCountriesFunction = getCountries('')
    const countries = await getCountriesFunction('')
    return countries
  }

  const handleSave = ({ disciplines, ...restProps }) => {
    const userAccountUpdate = {
      ...restProps,
      password: null,
      disciplines: disciplines.map((x) => ({ id: x.value })),
      areaLevel2Id: restProps.areaLevel2Id === OTHER_OPTION.value ? null : restProps.areaLevel2Id
    }
    dispatch(updateUserAccount(userAccountUpdate))
    onClose()
  }

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', '', false)
        setFieldValue('areaLevel2Id', '', false)
        setFieldValue('areaLevel2Other', '', false)
      }

      setProvinces([])
      setCities([])

      if (option !== empty.value) {
        const proxy = new CountriesProxy()
        const data = await proxy.getAreaLevel1(option)
        if (Array.isArray(data) && !!data.length) {
          if (option !== ARGENTINA_COUNTRY.value) {
            setProvinces([empty, ...data.map((d) => ({ value: d.id, label: d.name }))])
          } else {
            setProvinces(data.map((d) => ({ value: d.id, label: d.name })))
          }
        }
      }
    } catch (e) {}
  }

  const onProvinceChange = (setFieldValue) => async (option) => {
    try {
      setFieldValue('areaLevel1Id', option)
      setFieldValue('areaLevel2Id', '', false)
      setFieldValue('areaLevel2Other', '', false)
      setCities([])

      if (!option) return

      const proxy = new CountriesProxy()
      const dataAreaLevel2 = await proxy.getAreaLevel2(option)
      setCities(dataAreaLevel2)
    } catch (e) {
      showSnackbarError(e)
    }
  }

  const onDateChanged = (setFieldValue) => (value) => {
    if (value && value.isValid()) {
      setFieldValue('birthdate', value.format('YYYY-MM-DD'))
    } else {
      setFieldValue('birthdate', '')
    }
  }

  const onCityChange =
    (setFieldValue) =>
    (value, other = false) => {
      if (other) {
        setFieldValue('areaLevel2Other', value)
      } else {
        setFieldValue('areaLevel2Id', value)
        setFieldValue('areaLevel2Other', '')
      }
    }

  const handleChangeIdNumber = (setFieldValue) => (value) => {
    setFieldValue('idNumber', (value ?? STRING_EMPTY).toUpperCase())
  }

  return (
    <SectionLayoutDialog
      open={open}
      color={color}
      className={classes.dialog}
      onClose={onClose}
      title='EDITAR PERFIL'>
      <div className={classes.container}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={handleSave}>
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            setFieldValue,
            setValues
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Typography color='primary' variant='h6' className={classes.title}>
                  Datos Personales
                </Typography>
                <div className={classes.row}>
                  <div className={clsx(classes.formGroup, 'row')}>
                    <div className={classes.containerInput}>
                      <TextField
                        name='firstName'
                        autoFocus
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.firstName && Boolean(errors.firstName)}
                        required
                        variant='outlined'
                        size='small'
                        margin='normal'
                        className={classes.input}
                        label='Nombre'
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.firstName}
                      </Typography>
                    </div>
                    <div className={classes.containerInput}>
                      <TextField
                        name='eMail'
                        value={values.eMail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.eMail && Boolean(errors.eMail)}
                        required
                        variant='outlined'
                        size='small'
                        margin='normal'
                        className={classes.input}
                        label='E-mail'
                        disabled={true}
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.eMail}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.formGroup}>
                    <div className={classes.containerInput}>
                      <TextField
                        name='lastName'
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.lastName && Boolean(errors.lastName)}
                        required
                        variant='outlined'
                        size='small'
                        margin='normal'
                        className={classes.input}
                        label='Apellido'
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.lastName}
                      </Typography>
                    </div>
                    <div className={classes.containerInput}>
                      <TextField
                        name='username'
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.username && Boolean(errors.username)}
                        required
                        variant='outlined'
                        size='small'
                        margin='normal'
                        className={classes.input}
                        label='Nombre de usuario'
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.username}
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <div className={classes.containerInput}>
                      <TextField
                        name='idNumber'
                        autoFocus
                        value={values.idNumber}
                        onChange={(e) => handleChangeIdNumber(setFieldValue)(e.target.value)}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.idNumber && Boolean(errors.idNumber)}
                        required
                        variant='outlined'
                        size='small'
                        margin='normal'
                        className={classes.input}
                        label='Número de DNI'
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.idNumber}
                      </Typography>
                    </div>
                    <div className={classes.containerInput}>
                      <KeyboardDatePicker
                        format='DD/MM/YYYY'
                        variant='inline'
                        autoOk
                        label='Fecha de nacimiento*'
                        name='birthdate'
                        margin='normal'
                        className={classes.inputDatePicker}
                        id='date-picker-inline'
                        inputVariant='outlined'
                        style={{ width: '100%' }}
                        value={values.birthdate}
                        onBlur={handleBlur}
                        onChange={onDateChanged(setFieldValue)}
                        KeyboardButtonProps={{
                          'aria-label': 'seleccione fecha'
                        }}
                        placeholder='ddmmaaaa'
                        error={touched.birthdate && Boolean(errors.birthdate)}
                        helperText={''}
                      />
                      <Typography color='error' variant='caption' className={classes.errorMessage}>
                        {errors.birthdate}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.rowSecond}>
                  {activeRole === USER_ROLE.ATHLETE && (
                    <div className={classes.formGroup}>
                      <FormControl variant='outlined' className={classes.formControl}>
                        <Autocomplete
                          multiple
                          id='disciplinesSelect-autocomplete'
                          options={disciplines}
                          getOptionLabel={(option) => option.label}
                          filterSelectedOptions
                          getOptionSelected={(x) =>
                            values.disciplines.some((o) => o.value === x.value)
                          }
                          selectOnFocus
                          onChange={(event, optionSelected) =>
                            onDisciplinesChange(setFieldValue)(optionSelected)
                          }
                          clearText='Quitar todo'
                          openText='Abrir desplegable'
                          closeText='Cerrar desplegable'
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant='outlined'
                                label={option.label}
                                key={option.value}
                                title={option.label}
                                {...getTagProps({ index })}
                                color='primary'
                                className={classes.option}
                              />
                            ))
                          }
                          value={values.disciplines}
                          className={classes.combo}
                          loadingText={<Typography align='center'> Cargando...</Typography>}
                          noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={clsx(classes.comboInput)}
                              name='disciplines'
                              label='Seleccione Deporte/ Disciplina'
                              variant='outlined'
                              error={Boolean(errors.disciplines)}
                              helperText={
                                errors.disciplines || 'Escriba el nombre de la disciplina'
                              }
                            />
                          )}
                        />
                      </FormControl>

                      <div className={classes.containerInput}>
                        <TextField
                          name='weight'
                          value={values.weight}
                          placeholder='No proporcionado'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={touched.weight && Boolean(errors.weight)}
                          variant='outlined'
                          size='small'
                          margin='normal'
                          className={classes.input}
                          label='Peso'
                          helperText={errors.weight}
                        />
                      </div>
                      <div className={classes.containerInput}>
                        <TextField
                          name='height'
                          placeholder='No proporcionado'
                          value={values.height}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={touched.height && Boolean(errors.height)}
                          variant='outlined'
                          size='small'
                          margin='normal'
                          className={classes.input}
                          label='Altura'
                          helperText={errors.height}
                        />
                      </div>
                    </div>
                  )}
                  <div className={clsx(classes.formGroup, color)}>
                    <FormControl variant='outlined' className={clsx(classes.formControl, color)}>
                      <InputLabel id='outlined-age-native-simple' className={classes.labelSelect}>
                        País*
                      </InputLabel>
                      <Select
                        className={classes.selectItem}
                        value={values.countryId}
                        name='countryId'
                        onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                        onBlur={handleBlur}
                        label='País*'
                        variant='outlined'
                        MenuProps={{
                          classes: { paper: classes.option }
                        }}
                        labelId='outlined-age-native-simple'>
                        <MenuItem aria-label='None' value='' />
                        {countries.map((e, i) => (
                          <MenuItem className={classes.menuItem} key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        color='error'
                        variant='caption'
                        className={!errors.countryId ? classes.inputText : classes.errorMessage}>
                        {!errors.countryId ? 'Seleccione su País' : errors.countryId}
                      </Typography>
                    </FormControl>

                    <FormControl variant='outlined' className={clsx(classes.formControl, color)}>
                      <InputLabel id='outlined-age-native-simple' className={classes.labelSelect}>
                        Seleccione su Provincia
                        {values.countryId === ARGENTINA_COUNTRY.value ? '*' : STRING_EMPTY}
                      </InputLabel>
                      <Select
                        className={classes.selectItem}
                        colorOptions='secondary'
                        value={values.areaLevel1Id}
                        name='areaLevel1Id'
                        onChange={(e) => {
                          const selectedValue = e.target?.value ?? STRING_EMPTY
                          onProvinceChange(setFieldValue)(selectedValue)
                        }}
                        onBlur={handleBlur}
                        label={`Seleccione su Provincia${
                          values.countryId === ARGENTINA_COUNTRY.value ? '*' : STRING_EMPTY
                        }`}
                        MenuProps={{
                          classes: { paper: classes.option }
                        }}
                        labelId='outlined-age-native-simple'>
                        <MenuItem aria-label='None' value='' />
                        {provinces.map((e, i) => (
                          <MenuItem className={classes.menuItem} key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.areaLevel1Id && (
                        <Typography
                          color='error'
                          variant='caption'
                          className={classes.errorMessage}>
                          {errors.areaLevel1Id}
                        </Typography>
                      )}
                    </FormControl>

                    <FormControl variant='outlined' className={clsx(classes.formControl, color)}>
                      <InputLabel id='outlined-age-native-simple' className={classes.labelSelect}>
                        Seleccione su Ciudad
                      </InputLabel>
                      <Select
                        className={classes.selectItem}
                        colorOptions='secondary'
                        value={values.areaLevel2Id}
                        name='areaLevel2Id'
                        onChange={(e) => onCityChange(setFieldValue)(e.target.value)}
                        onBlur={handleBlur}
                        label={'Seleccione su Ciudad'}
                        MenuProps={{
                          classes: { paper: classes.option }
                        }}
                        labelId='outlined-age-native-simple'>
                        <MenuItem aria-label='None' value='' />
                        {cities.map((e, i) => (
                          <MenuItem className={classes.menuItem} key={i} value={e.id}>
                            {e.name}
                          </MenuItem>
                        ))}
                        {values.areaLevel1Id && (
                          <MenuItem aria-label='Otra' value={OTHER_OPTION.value} label='Otra'>
                            {OTHER_OPTION.label}
                          </MenuItem>
                        )}
                      </Select>
                      {errors.areaLevel2Id && (
                        <Typography color='error' variant='caption'>
                          {errors.areaLevel2Id}
                        </Typography>
                      )}
                    </FormControl>
                    {values.areaLevel2Id === OTHER_OPTION.value && (
                      <TextField
                        name='areaLevel2Other'
                        value={values.areaLevel2Other}
                        onChange={(e) => onCityChange(setFieldValue)(e.target.value, true)}
                        onBlur={handleBlur}
                        fullWidth
                        error={touched.areaLevel2Other && Boolean(errors.areaLevel2Other)}
                        required
                        variant='outlined'
                        size='small'
                        color='primary'
                        margin='normal'
                        className={clsx(classes.inputOther, color)}
                        label='Nombre de la cuidad'
                        helperText={errors.areaLevel2Other || 'Escribe el nombre de tu cuidad'}
                      />
                    )}
                  </div>
                </div>

                {activeRole !== USER_ROLE.ORGANIZER && (
                  <div className={classes.row}>
                    <div className={classes.socialContainer}>
                      <div className={classes.labelContainer}>
                        <Typography variant='caption' className={classes.labelStep}>
                          Redes Sociales
                        </Typography>
                      </div>
                      <div className={classes.socialRow}>
                        <FormControlLabel
                          value={true}
                          onChange={() => {
                            setCheckSocialMedia({
                              ...checkSocialMedia,
                              facebookUrl: !checkSocialMedia.facebookUrl
                            })
                          }}
                          name='facebookUrl'
                          className={classes.checkboxRoles}
                          control={
                            <Checkbox
                              checked={checkSocialMedia.facebookUrl}
                              name='facebookUrl'
                              className={classes.checkboxSocial}
                            />
                          }
                          label={
                            <SocialIcon
                              url={values.facebookUrl || undefined}
                              network='facebook'
                              style={socialSize}
                              bgColor={'transparent'}
                              fgColor={theme.palette.primary.main}
                              target='_blank'
                              className={classes.socialIcon}
                            />
                          }
                        />
                        {checkSocialMedia.facebookUrl && (
                          <TextField
                            name='facebookUrl'
                            value={values.facebookUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={touched.facebookUrl && Boolean(errors.facebookUrl)}
                            required
                            variant='outlined'
                            size='small'
                            margin='normal'
                            className={classes.inputSocial}
                            placeholder='Facebook'
                          />
                        )}
                      </div>
                      <div className={classes.socialRow}>
                        <FormControlLabel
                          value={true}
                          onChange={() => {
                            setCheckSocialMedia({
                              ...checkSocialMedia,
                              instagramUrl: !checkSocialMedia.instagramUrl
                            })
                          }}
                          name='instagramUrl'
                          className={classes.checkboxRoles}
                          control={
                            <Checkbox
                              checked={checkSocialMedia.instagramUrl}
                              name='instagramUrl'
                              className={classes.checkboxSocial}
                            />
                          }
                          label={<InstagramIcon className={classes.instagramIcon} />}
                        />
                        {checkSocialMedia.instagramUrl && (
                          <TextField
                            name='instagramUrl'
                            value={values.instagramUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={touched.instagramUrl && Boolean(errors.instagramUrl)}
                            required
                            variant='outlined'
                            size='small'
                            margin='normal'
                            className={classes.inputSocial}
                            placeholder='Instagram'
                          />
                        )}
                      </div>
                      <div className={classes.socialRow}>
                        <FormControlLabel
                          value={true}
                          onChange={() => {
                            setCheckSocialMedia({
                              ...checkSocialMedia,
                              youTubeUrl: !checkSocialMedia.youTubeUrl
                            })
                          }}
                          name='youTubeUrl'
                          className={classes.checkboxRoles}
                          control={
                            <Checkbox
                              checked={checkSocialMedia.youTubeUrl}
                              name='youTubeUrl'
                              className={classes.checkboxSocial}
                            />
                          }
                          label={
                            <SocialIcon
                              url={values.youTubeUrl || undefined}
                              network='youtube'
                              style={socialSize}
                              bgColor={'transparent'}
                              fgColor={theme.palette.primary.main}
                              target='_blank'
                              className={classes.socialIconYoutube}
                            />
                          }
                        />
                        {checkSocialMedia.youTubeUrl && (
                          <TextField
                            name='youTubeUrl'
                            value={values.youTubeUrl}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={touched.youTubeUrl && Boolean(errors.youTubeUrl)}
                            required
                            variant='outlined'
                            size='small'
                            margin='normal'
                            className={classes.inputSocial}
                            placeholder='YouTube'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.containerButton}>
                  <Button
                    color={color}
                    variant='contained'
                    className={clsx(classes.buttonSave, color)}
                    type='submit'>
                    Guardar
                  </Button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </SectionLayoutDialog>
  )
}

export default FormEditDialog
