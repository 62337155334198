import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650,
      margin: '0 auto'
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto'
    }
  },
  button: {
    alignSelf: 'center',
    width: 230,
    height: 40
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    gap: theme.spacing(8)
  },
  title: {
    marginLeft: theme.spacing(2),
    fontWeight: 600
  }
}))
