import React from 'react'
import clsx from 'clsx'

import { useStyles } from './PageContainer.style'

const PageContainer = ({ children, customContainerClassName, altMode }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        `${classes.container} ${altMode ? 'altMode' : ''}`,
        customContainerClassName
      )}>
      {children}
    </div>
  )
}

export default PageContainer
