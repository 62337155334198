import React, { useState } from 'react'
import Slider from 'react-slick'
import { Radio, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './FeatureBanner.style'

const items = [
  { id: '1', title: 'Con tu cuenta Sportmetric tus inscripciones son mucho más ágiles' },
  { id: '2', title: 'Registrate y consultá tu historial deportivo en tu perfil' },
  { id: '3', title: 'Añadí eventos y planificá tu calendario deportivo' }
]

const FeatureBanner = ({ hiddenSlider }) => {
  const classes = useStyles()
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)

  const customPaging = (index) => (
    <Radio
      className={classes.radioButton}
      checked={currentSlideNumber === index}
      value={index}
      name='radio-buttons'
    />
  )

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 2500,
    slidesToShow: 1,
    beforeChange: (prev, next) => setCurrentSlideNumber(next),
    customPaging,
    slidesToScroll: 1
  }

  return (
    <div className={clsx(classes.container, hiddenSlider && 'hidden')}>
      <Slider {...settings}>
        {items.map(({ id, title }) => (
          <div key={id} className={classes.titleContainer}>
            <Typography className={classes.title} variant='h2'>
              {title}
            </Typography>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default FeatureBanner
