import { makeStyles } from '@material-ui/core'
import medalImage from 'assets/img/landing-medal.jpg'

export const useStyles = makeStyles((theme) => ({
  section: {
    display: 'block',
    width: '100%',
    maxHeight: 1080,
    maxWidth: 1920,
    height: '100vh',
    padding: '0px !important'
  },
  backgroundImage: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '60%',
    transition: 'opacity ease-in-out 400ms',
    backgroundSize: 'cover',
    margin: '0 auto',
    height: '100%',
    minWidth: '100%',
    backgroundImage: `url(${medalImage})`,
    display: 'flex',
    position: 'relative',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10)
    }
  },
  content: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '80%'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  title: {
    lineHeight: '38px',
    fontFamily: theme.typography.fontFamilyAlt,
    color: theme.palette.primary.contrastText,
    fontWeight: 900,
    fontSize: '2.5rem',

    '& span': {
      color: theme.palette.primary.main
    },

    '& > div  > p ': {
      fontSize: '2.5rem',
      fontFamily: theme.typography.fontFamilyAlt,
      fontWeight: 900,
      color: theme.palette.primary.main,
      lineHeight: '38px'
    },

    [theme.breakpoints.up('lg')]: {
      fontSize: '4.9375rem',
      lineHeight: '70px',
      '& > div  > p ': {
        fontSize: '4.9375rem',
        fontFamily: theme.typography.fontFamilyAlt,
        fontWeight: 900,
        lineHeight: '70px',
        color: theme.palette.primary.main
      }
    }
  },
  animationContent: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  animationBlock: {
    width: 250,
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  transitionContainer: {
    float: 'left',
    overflow: 'hidden',
    position: 'relative',
    height: 38,
    top: 0,

    [theme.breakpoints.up('lg')]: {
      overflow: 'hidden',
      position: 'relative',
      height: 70,
      top: 0
    }
  },
  transition: {
    display: 'block',
    position: 'relative',
    whiteSpace: 'nowrap',
    top: 0,
    left: 0,
    animation: '$titleTransitionMobile 6000ms 0ms',
    animationIterationCount: 'infinite',
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 900,
    fontSize: '2.5rem',

    [theme.breakpoints.up('lg')]: {
      fontSize: '4.9375rem',
      animation: '$titleTransition 6000ms 0ms',
      animationIterationCount: 'infinite'
    }
  },

  '@keyframes titleTransition': {
    '0%': { top: 0, width: 0 },
    '5%': { width: 0 },
    '15%': { width: 490 },
    '30%': { width: 490, top: 0 },
    '33%': { top: 0, width: 0 },
    '35%': { top: 0, width: 0 },

    '38%': { top: -140, width: 0 },
    '48%': { top: -140, width: 405 },
    '62%': { top: -140, width: 405 },
    '66%': { top: -140, width: 0, textIndent: 0 },

    '71%': { top: -280, width: 0, textIndent: 0 },
    '86%': { top: -280, width: 400 },
    '95%': { top: -280, width: 400 },
    '98%': { top: -280, width: 0 },
    '100%': { top: 0, width: 0, textIndent: 0 }
  },

  '@keyframes titleTransitionMobile': {
    '0%': { top: 0, width: 0 },
    '5%': { width: 0 },
    '15%': { width: 260 },
    '30%': { top: 0, width: 260 },
    '33%': { top: 0, width: 0 },
    '35%': { top: 0, width: 0 },

    '38%': { top: -75, width: 0 },
    '48%': { top: -75, width: 215 },
    '62%': { top: -75, width: 215 },
    '66%': { top: -75, width: 0, textIndent: 0 },

    '71%': { top: -150, width: 0, textIndent: 0 },
    '86%': { top: -150, width: 210 },
    '95%': { top: -150, width: 210 },
    '98%': { top: -150, width: 0 },
    '100%': { top: 0, textIndent: 0, width: 0 }
  }
}))
