import React from 'react'
import { Button } from '@material-ui/core'

import { useStyles } from './Banner.style'

const Banner = ({ title, action, titleButton }) => {
  const classes = useStyles()
  return (
    <div style={{ display: 'inline-block', width: '100%' }}>
      <div className={classes.bannerBlock}>
        <p className={classes.bannerBlockText}>{title}</p>
        <Button
          onClick={() => {
            action()
          }}
          variant='contained'
          color='primary'
          className={classes.bannerButton}>
          {titleButton}
        </Button>
      </div>
    </div>
  )
}

export default Banner
