import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  date: {
    width: '10%',
    height: 8
  },
  container: {
    width: '100%',
    height: 210,
    display: 'flex'
  },
  body: {
    width: '60%',
    height: 6,
    [theme.breakpoints.up('sm')]: {
      height: 8
    }
  },
  title: {
    width: '40%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      height: 10
    }
  },
  subtitle: {
    width: '30%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '15%',
      height: 9
    }
  },
  containerHorizontal: {
    width: '100%',
    height: 500
  }
}))
