import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { BankAccountCard, PaymentLocationCard, SectionLayoutDialog } from 'shared'
import { updateEventBankAccount, updatePaymentLocation } from 'state/modules/events'

import { CreateBankAccountDialog } from '../CreateBankAccountDialog'
import { CreatePaymentLocationDialog } from '../CreatePaymentLocationDialog'

import { useStyles } from './OfflinePaymentConfigDialog.style'

const OfflinePaymentConfigDialog = ({
  open,
  onClose,
  onOpen,
  hiddenBankAccounts,
  hiddenPaymentLocations,
  title
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event, eventBankAccounts, paymentLocations } = useSelector((state) => state.events)
  const { values, setFieldValue, setValues } = useFormikContext()

  const [openCreatePaymentLocation, setOpenCreatePaymentLocation] = useState(false)
  const [openCreateBankAccount, setOpenCreateBankAccount] = useState(false)

  const BANK_ACCOUNTS_MAX = 2
  const PAYMENT_LOCATIONS_MAX = 10

  const onOpenCreateBankAccount = () => {
    setOpenCreateBankAccount(true)
    onClose()
  }

  const onCloseCreateBankAccount = () => {
    setOpenCreateBankAccount(false)
    onOpen()
  }

  const onOpenCreatePaymentLocation = () => {
    setOpenCreatePaymentLocation(true)
    onClose()
  }

  const onCloseCreatePaymentLocation = () => {
    setOpenCreatePaymentLocation(false)
    onOpen()
  }

  const handleUpdatePaymentLocationState = async (values) =>
    await dispatch(updatePaymentLocation(event.id, values))

  const handleUpdateBankAccountState = async (values) =>
    await dispatch(updateEventBankAccount(event.id, values))

  return (
    <SectionLayoutDialog
      open={open}
      mounted
      onClose={onClose}
      title={title}
      className={classes.dialog}
      contentClassName={classes.container}>
      {(eventBankAccounts.length > 0 || paymentLocations.length > 0) && (
        <div className={classes.infoDialog}>
          <InfoIcon color='primary' />
          <Typography color='primary' variant='h6' className={classes.textInfoDialog}>
            Utilice los checkbox para habilitar o deshabilitar los medios de pago.
          </Typography>
        </div>
      )}
      {!hiddenBankAccounts && (
        <div className={clsx(classes.optionsContainer, eventBankAccounts.length && ' center')}>
          {eventBankAccounts.map((e, index) => (
            <BankAccountCard
              key={index}
              bankAccount={e}
              title={`Cuenta Bancaria ${index + 1}`}
              showActionbuttons
              showCheckActive
              saveChange
              {...{ values, setFieldValue, setValues }}
              onSubmit={handleUpdateBankAccountState}
              onCloseOfflineBankAccountConfig={onClose}
              onOpenCloseOffPaymentConfig={onOpen}
            />
          ))}

          {eventBankAccounts.length < BANK_ACCOUNTS_MAX && (
            <Typography
              color='primary'
              variant='h6'
              className={clsx(classes.offLineTextLink, !!eventBankAccounts.length && 'center')}
              onClick={onOpenCreateBankAccount}>
              {eventBankAccounts.length ? '+ Añadir cuenta Bancaria' : 'Agregar cuenta Bancaria +'}
            </Typography>
          )}
        </div>
      )}

      {!hiddenPaymentLocations && (
        <div className={classes.optionsContainer}>
          {paymentLocations.map((e, index) => (
            <PaymentLocationCard
              key={index}
              paymentLocation={e}
              showActionbuttons
              showCheckActive
              saveChange
              {...{ values, setFieldValue }}
              onSubmit={handleUpdatePaymentLocationState}
              onCloseOfflinePaymentLocationConfig={onClose}
              title={`Lugar de pago ${index + 1}`}
              onOpenCloseOffPaymentConfig={onOpen}
            />
          ))}

          {paymentLocations.length < PAYMENT_LOCATIONS_MAX && (
            <Typography
              color='primary'
              variant='h6'
              className={clsx(classes.offLineTextLink, !!paymentLocations.length && 'center')}
              onClick={onOpenCreatePaymentLocation}>
              {paymentLocations.length ? '+ Añadir punto físico' : 'Agregar punto físico +'}
            </Typography>
          )}
        </div>
      )}
      <CreatePaymentLocationDialog
        open={openCreatePaymentLocation}
        onClose={onCloseCreatePaymentLocation}
        paymentLocationCount={paymentLocations.length}
      />

      <CreateBankAccountDialog
        open={openCreateBankAccount}
        onClose={onCloseCreateBankAccount}
        bankAccountCount={eventBankAccounts.length}
      />
    </SectionLayoutDialog>
  )
}

export default OfflinePaymentConfigDialog
