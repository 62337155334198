import { makeStyles } from '@material-ui/styles'
import logoImage from 'assets/img/logo.png'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  container: {
    minHeight: '73.2vh',
    [theme.breakpoints.up('lg')]: {
      minHeight: '70vh'
    }
  },
  headerContainer: {
    backgroundColor: 'rgba(41, 255, 89, 0.7)',
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h6': {
      fontSize: '1.3rem',
      fontWeight: 600
    }
  },
  logo: {
    backgroundImage: `url(${logoImage})`,
    width: 251,
    height: 26
  },
  title: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    fontWeight: 500
  },
  formContainer: {
    backgroundColor: 'white',
    width: '100%',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4)
    }
  },
  separator: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    backgroundImage: `linear-gradient(${theme.palette.primary.main},${theme.palette.primary.main}), linear-gradient(${theme.palette.primary.main},${theme.palette.primary.main})`,
    backgroundSize: '45% 1px',
    backgroundPosition: 'center left, center right',
    backgroundRepeat: 'no-repeat'
  },
  hiddenContainer: {
    display: 'none'
  },
  socialLoginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: 150,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  },
  socialButton: {
    marginTop: theme.spacing(4),
    width: '50%'
  },
  signUpButton: {
    marginTop: theme.spacing(2),
    '& span': {
      fontSize: '1.25rem'
    },
    width: '50%'
  },
  mainContainer: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      minHeight: '57vh'
    }
  },
  hideButton: {
    display: 'none'
  }
}))

export default useStyles
