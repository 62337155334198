import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import { IconButton, Typography } from '@material-ui/core'
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './HistoryContainer.style'

const notes = [
  'Sportmetric nace en 2014 con el objetivo de proporcionar herramientas que contribuyeran en la organización de eventos deportivos. Inicialmente, se trataba de una hoja de cálculo creada por Juan Villalba con la cual realizaba medición de tiempos. Gracias a la alianza con Pinard Software e Innovación, se desarrolló un sistema de cronometraje que permite obtener resultados de la competencia tan pronto como el corredor cruza la línea de meta.',
  'En 2021 se lanzó nuestra página web que constituye un calendario deportivo gratuito y además el lugar donde se consultan los resultados de las distintas competencias permitiendo consultar resultados históricos de cada competencia y armar automáticamente el currículum deportivo de cada usuario. ',
  'Desde 2023 desarrollamos una plataforma de pago para nuestro formulario de inscripción, lo que permite al organizador llevar un mayor y mejor control del listado de inscriptos y pre inscriptos, así como también de los pagos recibidos, concentrado todo en un mismo lugar. ',
  'Diseñamos y desarrollamos toda nuestra tecnología, estamos constantemente agregando nuevas funcionalidades que simplifican la organización de un evento a la vez que ayudamos a los deportistas a encontrar su próximo desafío.'
]

const FIRST_SLICK_NUMBER = 0
const LAST_SLICK_NUMBER = 3

const HistoryContainer = () => {
  const classes = useStyles()

  const slickRef = useRef(null)
  const [currentPageNumber, setCurrentPageNumber] = useState(FIRST_SLICK_NUMBER)

  const beforeChange = (prev, next) => setCurrentPageNumber(next)

  const settings = {
    className: classes.slickSlider,
    dots: false,
    arrows: false,
    infinite: false,
    autoplay: false,
    speed: 2000,
    slidesToShow: 1,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    beforeChange,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <div className={classes.container}>
      <Slider {...settings} ref={slickRef}>
        {notes.map((x, index) => (
          <div key={index}>
            <Typography className={classes.text}>{x}</Typography>
          </div>
        ))}
      </Slider>
      <div className={classes.buttonContainer}>
        {currentPageNumber === LAST_SLICK_NUMBER && (
          <Typography color='primary' className={classes.continueLabel}>
            Continuará...
          </Typography>
        )}

        <IconButton
          className={clsx(classes.nextButton, currentPageNumber === LAST_SLICK_NUMBER && 'hidden')}
          onClick={() => slickRef.current?.slickNext()}
          color='primary'
          size='small'>
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </IconButton>

        <IconButton
          className={clsx(classes.prevButton, currentPageNumber === FIRST_SLICK_NUMBER && 'hidden')}
          onClick={() => slickRef.current?.slickPrev()}
          color='primary'
          size='small'>
          <KeyboardArrowLeftIcon className={classes.arrowIcon} />
        </IconButton>
      </div>
    </div>
  )
}

export default HistoryContainer
