import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import organizationNoImage from 'assets/img/no-image.jpg'
import userAccountNoImage from 'assets/img/user-mock.png'
import { ROUTES } from 'routes'

import { useStyle } from './FollowedWidget.style'

const FollowedWidget = ({ followed, type }) => {
  const classes = useStyle()

  const followedSelected =
    type === 'organization'
      ? {
          selected: 'ORGANIZATIONS',
          followedType: 'Organización',
          followedNoImage: organizationNoImage
        }
      : {
          selected: 'USERS_ACCOUNTS',
          followedType: 'Deportista',
          followedNoImage: userAccountNoImage
        }

  const profileImageUrl =
    Array.isArray(followed.profileImages) && followed.profileImages.length > 0
      ? followed.profileImages[0]
      : followedSelected.followedNoImage

  return (
    <Link
      to={`${ROUTES[followedSelected.selected].PROFILE}/ ${followed.id}`}
      className={classes.containerFollowed}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${profileImageUrl})`
        }}></div>
      <div className={classes.nameContainer}>
        <Typography variant='caption' color='primary' className={classes.name}>
          {followed.name || followed.fullName}
        </Typography>
        <Typography variant='h6' className={classes.followedTypeText}>
          {followedSelected.followedType}
        </Typography>
      </div>
    </Link>
  )
}

export default FollowedWidget
