import React from 'react'
import { Typography } from '@material-ui/core'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './CustomFormSection.style'

const CustomFormSection = (sectionProps) => {
  const classes = useStyles()

  return (
    <SectionBlock
      containerClassName={classes.container}
      nextSectionId='chooseOptionSection'
      {...sectionProps}>
      <div className={classes.mainContainer}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            <span className={classes.crea}>CREÁ</span> Y
            <br />
            PERSONALIZÁ
            <br />
            TU <span>FORMULARIO</span>
            <br />
            DE <span>INSCRIPCIÓN.</span>
          </Typography>
        </div>

        <div className={classes.sportImage} />
      </div>
    </SectionBlock>
  )
}

export default CustomFormSection
