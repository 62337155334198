import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dialog, DialogContent, IconButton, Typography } from '@material-ui/core'
import { Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons'
import { EditableRichText } from 'shared/EditableRichText'
import { ShareUrlMenuDialog } from 'shared/ShareUrlMenuDialog'
import { verifyUserNotLogged } from 'state/modules/app'
import { reactEvent } from 'state/modules/events'

import { useStyles } from './EventCardPreview.style'

const EventCardPreview = ({
  disciplinesToShow,
  imageUrl,
  eventDateFormat,
  organizationsToShow,
  hasCoords,
  locationEvent,
  open,
  like,
  setIsModalOpen,
  event
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)
  const { pendingReact } = useSelector((state) => state.events)

  const { likesCount, createdAt, updatedAt, slugUrl, id, name, isSuspended } = event

  const handleActionEvent = (action) => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true }))
    } else {
      dispatch(action(event))
    }
  }

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      fullScreen={true}
      open={open}
      className={classes.container}>
      <div className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.date}>{eventDateFormat}</Typography>
          <div className={classes.sportContainer}>
            {disciplinesToShow.map((d) => (
              <div className={classes.sport} key={d.id} title={d.title}>
                {d.name}
              </div>
            ))}
          </div>
        </div>
        <div
          className={classes.featureImage}
          id='feature-image'
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        <div className={classes.actionSocialBarButton}>
          <IconButton
            className={classes.actionButton}
            disabled={pendingReact}
            onClick={() => handleActionEvent(reactEvent)}>
            {like ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            {!!likesCount && <em>{likesCount}</em>}
          </IconButton>
          <ShareUrlMenuDialog
            id={id}
            updatedAt={updatedAt ?? createdAt}
            type='event'
            isHiddenLabel
            isOutlineIcon
            buttonClassName={classes.shareButton}
            copyMessage='El link del evento fue copiado al portapapeles'
            suspended={isSuspended}
            slugUrl={slugUrl}
            title={name}
          />
        </div>
        <div className={classes.nameEventContainer}>
          <Typography color='primary' variant='h5' className={classes.nameEvent} title={name}>
            {name}
          </Typography>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.textContainer}>
            <div className={classes.organizationContainer}>
              <Typography
                className={classes.organization}
                title={`Organiza ${organizationsToShow}`}>
                Organiza <span>{organizationsToShow}</span>
              </Typography>
            </div>
            <div className={classes.locationContainer}>
              <Typography component='p' title={locationEvent}>
                {locationEvent}
              </Typography>
            </div>
            <div className={classes.organizationContainer}>
              <EditableRichText
                className={classes.description}
                entity={event}
                readOnly
                field='description'
              />
            </div>
          </div>
        </DialogContent>
        <div className={classes.endContainer}>
          <Link
            className={classes.link}
            onClick={(e) => {
              e.preventDefault()
              setIsModalOpen(false)
            }}>
            Cerrar
          </Link>
        </div>
      </div>
    </Dialog>
  )
}

export default EventCardPreview
