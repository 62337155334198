import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  checkbox: {
    marginTop: -3,
    marginLeft: -45,
    '& svg': {
      width: 20,
      height: 20,
      color: theme.palette.primary.main
    }
  },
  checkboxDisabled: {
    '& svg': {
      color: 'gray'
    }
  },
  button: {
    marginTop: -5,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  disabled: {
    '& svg': {
      color: 'gray'
    }
  },
  submitButton: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  offLineText: {
    fontWeight: 500,
    fontSize: '.875rem',
    marginBottom: 5
  },
  offLineTextTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    width: 200,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    '&.disabled': {
      borderBottom: `1px solid gray`,
      color: 'gray',
      fontWeight: 600,
      marginBottom: theme.spacing(1.5),
      width: 200
    }
  },
  offLineTextDisabled: {
    color: '#9E9FA5'
  },
  offLineTextTitleForm: {
    fontWeight: 600,
    marginBottom: theme.spacing(1.5),
    width: '100%'
  },
  cardForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main,
      width: 15,
      height: 15
    }
  },
  labelRadio: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 15
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0
    }
  },
  saveButton: {
    marginTop: theme.spacing(3)
  },
  containerSelect: {
    height: 50,
    transition: 'height 0.5s ease'
  },
  ellipsisText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  inputText: {
    width: '100%',
    '& input': {
      boxSizing: 'border-box'
    }
  },
  inputNumeric: {
    width: '100%',
    '& input': {
      fontSize: '.825rem',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      },
      boxSizing: 'border-box'
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  label: {
    fontSize: 13,
    fontWeight: 500
  },
  errorMessage: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.75)
  },
  card: {
    '&.hidden': {
      display: 'none'
    }
  },
  section: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(0.5)
  },
  copyButton: {
    padding: theme.spacing(),
    '& svg': {
      width: 18,
      height: 18
    }
  }
}))
