import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  userPopup: {
    top: '80px !important',
    maxWidth: 'none',
    width: '100%',
    position: 'relative',
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'transparent',

    [theme.breakpoints.up('md')]: {
      top: '120px !important',
      width: 380
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(7)
    }
  },
  userPopupMini: {
    left: '0 !important'
  },
  userOptionsContainer: {
    width: '100%',
    backgroundColor: theme.palette.disabled.light,
    borderRadius: '0px 0px 10px 10px',
    overflowY: 'auto',
    maxHeight: '83vh',
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey'
    },

    '&.secondary': {
      '&::-webkit-scrollbar-track': {
        background: theme.utils.rgba(theme.palette.secondary.main, 0.2)
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: theme.utils.rgba(theme.palette.secondary.main, 0.45)
      }
    },
    '&.primary': {
      '&::-webkit-scrollbar-track': {
        background: theme.utils.rgba(theme.palette.primary.main, 0.2)
      },

      /* Handle */
      '&::-webkit-scrollbar-thumb': {
        background: theme.utils.rgba(theme.palette.primary.main, 0.45)
      }
    }
  },
  userProfileContainer: {
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  profile: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'flex-start'
  },
  alias: {
    fontSize: '1rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 144,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(),
      width: 164
    }
  },
  profileImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 40,
    height: 40,
    borderRadius: 70,
    flexShrink: 0
  },
  rol: {
    fontSize: '.875rem',
    fontWeight: 500
  },
  myProfileButton: {
    width: '100%',
    borderRadius: '20px',
    boxShadow: 'none',
    height: 43,
    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem',
      color: theme.palette.primary.contrastText
    },
    '&.secondary': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.secondary.main}`,
      '& span': {
        color: theme.palette.primary.main
      },
      '&:hover': {
        boxShadow: 'none'
      }
    }
  },
  signOutContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3, 0)
  },
  signOutLink: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem'
  },

  createButton: {
    width: '100%',
    borderRadius: '20px',
    boxShadow: 'none',
    height: 43,
    backgroundColor: theme.palette.secondary.main,
    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem',
      color: theme.palette.primary.main
    },
    '&:hover': {
      boxShadow: 'none'
    }
  }
}))
