import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: 'transparent',
    padding: 0
  },
  dialog: {
    height: 'calc(100% - 60px)',
    alignSelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 120px)'
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      width: '100%',
      margin: '0 auto'
    }
  },
  headerContainer: {
    height: 77,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    justifyContent: 'center',
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  button: {
    '& span': {
      fontWeight: 500
    }
  },
  backButton: {
    padding: 15,
    alignSelf: 'center',
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  }
}))
