import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  headerSection: {
    height: 43,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    transition: 'all .3s',

    '& h6': {
      fontWeight: 700
    },
    '&.primary': {
      backgroundColor: theme.palette.primary.main,
      '& h6': {
        color: theme.palette.primary.contrastText,
        fontWeight: 500
      }
    },
    '&.secondary': {
      backgroundColor: theme.palette.secondary.main,
      '& h6': {
        color: theme.palette.primary.main
      }
    }
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 70,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  section: {
    transition: 'all .3s',
    display: 'flex',
    flexDirection: 'column',
    '&.primary': {
      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.3)
    },
    '&.secondary': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.3)
    }
  },
  changeRolHeader: {
    paddingLeft: theme.spacing(4),
    height: 43,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    transition: 'all .3s',
    '& h6': {
      fontWeight: 700
    },
    '&.primary': {
      backgroundColor: theme.palette.primary.main,
      '& h6': {
        color: theme.palette.primary.contrastText,
        fontWeight: 500
      }
    },
    '&.secondary': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main
    }
  },
  changeRolTitle: {
    fontSize: '1.125rem'
  },
  organizationsSection: {}
}))
