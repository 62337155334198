import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CircularProgress, Typography } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer } from 'shared'
import { load, loadReset } from 'state/modules/events'

import { EditForm } from './components'
import { useStyles } from './EditPage.style'

const EditPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { slugUrlOrId } = useParams()

  const { pending, event, error } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const activeLoading = (!pending && !event && !error) || pending

  useEffect(() => {
    dispatch(load(slugUrlOrId))

    return () => dispatch(loadReset())
  }, [dispatch, slugUrlOrId, user])

  const renderLoading = () => <CircularProgress size={16} color='primary' />

  const renderPage = () => {
    const { name } = event

    return (
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <div className={classes.headerContainer}>
            <Typography className={classes.title} color='primary' variant='h5' title={name}>
              {name}
            </Typography>
          </div>
          <EditForm />
        </div>
      </div>
    )
  }

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer altMode>{activeLoading ? renderLoading() : renderPage()}</PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default EditPage
