import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './SectionBlock.style'

const SectionBlock = ({
  children,
  title,
  body,
  hiddenBody = false,
  className,
  bodyClassName,
  buttonComponent,
  hiddenSection
}) => {
  const classes = useStyles()
  const [isTextRevealed, setIsTextRevealed] = useState(!hiddenBody || (body && body.length < 200))

  return (
    <div
      className={clsx(
        classes.sectionContainer,
        !!className && className,
        hiddenSection && 'hidden'
      )}>
      {!!title && (
        <div className={classes.seccionHeaderContainer}>
          <Typography variant='h3' className={classes.title} id='sectionTitle'>
            {title}
          </Typography>
          {buttonComponent && buttonComponent()}
        </div>
      )}
      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <Typography variant='body1' className={bodyClassName}>
            {body}
          </Typography>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}

      {children}
    </div>
  )
}

export default SectionBlock
