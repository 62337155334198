import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { SectionLayoutDialog } from 'shared'
import { loadEventDiscountCodes, updateEventDiscountCode } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'

import { DiscountCodeForm } from '../DiscountCodeForm'

import { useStyles } from './EditDiscountCodeDialog.style'

const FORM_STATES = {
  FORM_UPDATE: 'update'
}

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const EditDiscountCodeDialog = ({ open, onClose, discountCode = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { event } = useSelector((state) => state.events)

  const handleUpdate = async ({
    name,
    discountType,
    fixedAmount,
    validFrom,
    validTo,
    percentage,
    ...restProps
  }) => {
    const discountCode = {
      ...restProps,
      name: name.trim(),
      discountType,
      fixedAmount: discountType === DISCOUNT_TYPE.FIXED_AMOUNT ? fixedAmount : 0,
      percentage: discountType === DISCOUNT_TYPE.PERCENTAGE ? percentage : 0,
      validFrom: moment(validFrom).isValid() ? moment(validFrom).format() : null,
      validTo: moment(validTo).isValid() ? moment(validTo).format() : null
    }

    const updateSuccess = await dispatch(updateEventDiscountCode(discountCode, event.id))

    if (updateSuccess) {
      await dispatch(loadEventDiscountCodes(event.id))
      showSnackbarSuccess('¡Se actualizo el código de descuento con éxito!')
      onClose()
    }
  }

  return (
    <SectionLayoutDialog
      title='EDITAR CÓDIGO'
      open={open}
      onClose={onClose}
      className={classes.dialog}
      contentClassName={classes.container}>
      <DiscountCodeForm
        values={discountCode}
        onSubmit={handleUpdate}
        mode={FORM_STATES.FORM_UPDATE}
      />
    </SectionLayoutDialog>
  )
}
export default EditDiscountCodeDialog
