import React, { useState } from 'react'
import { Button, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ActionDialog } from 'pages/Events/InscriptionPage/components'

import { useStyles } from './DeleteInscriptionDialog.style'

const DeleteInscriptionDialog = ({ action, field, title, actionName, className, handleOpen }) => {
  const classes = useStyles()

  const [openDialog, setOpenDialog] = useState(false)

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        className={classes.actionButton}
        onClick={() => {
          handleOpen(() => setOpenDialog(true))
        }}>
        <DeleteOutlineIcon />
      </Button>
      <ActionDialog
        open={openDialog}
        values={field}
        action={action}
        onClose={() => setOpenDialog(false)}
        title={title}
        className={clsx(className || classes.container)}>
        <div className={classes.deleteDialogBody}>
          <Typography variant='h6' align='center' color='primary'>
            ¿Está seguro que desea eliminar la inscripción?
          </Typography>
          <Typography align='center'>
            En caso que si, la inscripción de&nbsp;
            <Typography color='primary' className={classes.field}>
              {field.firstName} {field.lastName}
            </Typography>
            se eliminará permanentemente.
          </Typography>
        </div>

        <Button color='primary' variant='contained' type='submit' className={classes.button}>
          Eliminar
        </Button>
      </ActionDialog>
    </>
  )
}

export default DeleteInscriptionDialog
