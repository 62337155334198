import React, { useState } from 'react'
import { CircularProgress, IconButton } from '@material-ui/core'
import { FileCopyOutlined as FileCopyOutlinedIcon } from '@material-ui/icons'

const DuplicateButton = ({ onDuplicate, title, color, isDisabled }) => {
  const [duplicatePending, setDuplicatePending] = useState(false)

  const handleDuplicate = async () => {
    setDuplicatePending(true)

    await onDuplicate()

    setDuplicatePending(false)
  }

  return (
    <IconButton
      color={color}
      title={title}
      onClick={handleDuplicate}
      disabled={duplicatePending || isDisabled}>
      {!duplicatePending ? <FileCopyOutlinedIcon /> : <CircularProgress size={16} color={color} />}
    </IconButton>
  )
}

export default DuplicateButton
