import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 80,
    paddingBottom: 80,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      paddingTop: 120
    },
    '&.altMode': {
      backgroundColor: '#fff'
    }
  }
}))
