import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      height: 50,
      paddingTop: 60,
      backgroundColor: '#ffff',
      paddingBottom: 50,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
      alignItems: 'center'
    }
  },
  titleContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.7),
    height: 45,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h5': {
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        fontWeight: 400
      }
    },
    [theme.breakpoints.up('lg')]: {
      backgroundColor: 'transparent',
      alignItems: 'flex-start',
      paddingLeft: theme.spacing(6),
      width: '100%'
    }
  },
  sectionBlock: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    minHeight: 100,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('lg')]: {
      boxShadow: 'none',
      width: '10%',
      alignItems: 'center'
    }
  },
  actionButton: {
    minWidth: 50,
    padding: theme.spacing(1),
    '& svg': {
      width: 30,
      height: 30
    }
  }
}))
