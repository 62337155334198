import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  content: {
    position: 'relative',
    display: 'inline-block',
    background: 'none',
    fontSize: '48px',
    lineHeight: 'normal',
    fontWeight: 700,
    margin: 0,
    padding: 0,
    outline: 'none',
    width: 500,
    zIndex: 300,
    color: 'rgba(0, 0, 0, 0.9)',
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    '& svg': {
      position: 'absolute',
      top: '-10%',
      left: '-10%',
      right: '-10%',
      bottom: '-10%',
      zIndex: -1,
      overflow: 'hidden',
      margin: 'auto',
      padding: 0,
      width: '100%',
      height: '100%',
      fill: 'none',
      strokeWidth: 54,
      stroke: theme.palette.secondary.main,

      strokeDasharray: 400,
      strokeDashoffset: 0,
      pointerEvents: 'none',

      '&:first-of-type': {
        width: 'calc(100% + 12px)',
        height: '104%',
        transform: 'skewX(-17deg)',
        [theme.breakpoints.up('md')]: {
          width: '105.5%'
        }
      },
      '&:last-of-type': {
        width: 'calc(100% + 12px)',
        transform: 'skewX(-12deg)',
        [theme.breakpoints.up('md')]: {
          width: '104%'
        }
      }
    },

    '&.animation': {
      '& svg': {
        strokeDasharray: 400,
        strokeDashoffset: 0,
        animation: '$markAnimation 1500ms 1000ms backwards'
      }
    }
  },
  '@keyframes markAnimation': {
    '0%': {
      strokeDasharray: 400,
      strokeDashoffset: 400
    },
    '100%': {
      strokeDashoffset: 0
    }
  }
}))
