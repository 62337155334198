import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  },
  subTitle: {
    fontWeight: 600,
    marginBottom: theme.spacing()
  },
  text: {
    fontWeight: 500
  }
}))
