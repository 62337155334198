import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { ReactComponent as TriangleExclaimationIcon } from 'assets/icons/triangle-exclaimation-icon.svg'
import { LayoutDialog, LinkifyText } from 'shared'

import { useStyles } from './InfoMessageDialog.style'

const InfoMessageDialog = ({ formData, onClose, ...dialogProps }) => {
  const classes = useStyles()

  return (
    <LayoutDialog
      {...dialogProps}
      onClose={onClose}
      contentClassName={classes.contentDialog}
      className={classes.dialog}>
      <div className={classes.initialMessageContainer}>
        <div className={classes.alertContainer}>
          <TriangleExclaimationIcon />
          <Typography className={classes.alertParagraph}>
            Los datos que completes a continuación tienen carácter de Declaración Jurada y son los
            que se brindarán a la compañía de seguro, en caso de corresponder.
          </Typography>
        </div>
        {formData.welcomeMessage && (
          <LinkifyText>
            <div className={classes.alertContainer}>
              <Typography color='primary' variant='h6' className={classes.welcomeMessage}>
                {formData.welcomeMessage}
              </Typography>
            </div>
          </LinkifyText>
        )}
      </div>
      <Button variant='contained' color='primary' className={classes.button} onClick={onClose}>
        Aceptar
      </Button>
    </LayoutDialog>
  )
}

export default InfoMessageDialog
