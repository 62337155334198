import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { addUserRole } from 'state/modules/app'
import { loadUserData } from 'state/modules/auth'
import { updateUserAccount } from 'state/modules/userAccounts'
import { USER_ROLE } from 'utils/constants'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'

import { useStyles } from './AddUserRoleDialog.style'

const AddUserRoleDialog = ({ open, onClose, userData, role }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { addRoleState } = useSelector((state) => state.app)

  const [updateUserRolePending, setUpdateUserRolePending] = useState(false)

  const handleActivateRol = async () => {
    try {
      setUpdateUserRolePending(true)
      const updateUserAccountSuccess = await dispatch(
        updateUserAccount({ ...userData, [`is${role.value}`]: true })
      )

      if (updateUserAccountSuccess) {
        showSnackbarSuccess(`Se agrego el rol ${role.label} correctamente`)
        await dispatch(loadUserData(userData.id))

        if (addRoleState && addRoleState.successCallback) {
          addRoleState.successCallback()
          dispatch(addUserRole(null))
        }
      }
    } catch (error) {
      showSnackbarError(error)
    } finally {
      onClose()
      setUpdateUserRolePending(false)
    }
  }

  const handleOnClose = async () => {
    try {
      setUpdateUserRolePending(true)

      if (addRoleState && addRoleState.rejectCallback) {
        await addRoleState.rejectCallback()

        dispatch(addUserRole(null))
      }

      onClose()
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setUpdateUserRolePending(false)
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      title='AGREGAR ROL'
      className={classes.dialog}
      backDropClassName={role === USER_ROLE.ATHLETE && classes.backDrop}>
      <div className={classes.container}>
        {role === USER_ROLE.ORGANIZER && (
          <Typography color='primary' variant='h6' className={classes.content} align='center'>
            Estás agregando el <span>Rol Organizador</span>, el cual te permitirá crear una
            organización o colaborar en la administración de una organización.
          </Typography>
        )}

        {role === USER_ROLE.ATHLETE && (
          <Typography color='primary' variant='h6' className={classes.content} align='center'>
            Estás agregando el <span>Rol Deportista</span>, el cual te permitirá inscribirte en
            eventos ahorrando tiempo, ver tus logros/performance, y armar tu propio calendario de
            eventos.
          </Typography>
        )}

        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            variant='outlined'
            className={classes.button}
            onClick={handleOnClose}
            disabled={updateUserRolePending}>
            Cancelar
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={updateUserRolePending}
            endIcon={updateUserRolePending && <CircularProgress size={16} color='primary' />}
            className={classes.button}
            onClick={handleActivateRol}>
            Aceptar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default AddUserRoleDialog
