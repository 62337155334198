import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.25)
  },
  container: {
    width: '85%',
    [theme.breakpoints.up('sm')]: {
      width: '75%'
    }
  },
  mainContainer: {
    display: 'flex',
    marginTop: theme.spacing(),
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  content: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  uploadReceiptContainer: {
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,

    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 350
    }
  },
  textField: {
    '& fieldset': {
      border: 'none'
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    }
  },
  fileButton: {
    boxShadow: 'none',
    height: 40,
    minWidth: 38
  },
  attachIcon: {
    width: 20,
    height: 20,
    transform: 'rotate(45deg)'
  },
  acceptButton: {
    height: 40,
    width: 200,
    alignSelf: 'center',
    margin: theme.spacing(2, 0)
  },
  receiptButton: {
    width: 180,
    [theme.breakpoints.up('sm')]: {
      height: 40,
      width: 200
    }
  }
}))
