import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Avatar, Button, Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { verifyUserNotLogged } from 'state/modules/app'

import { useStyles } from './FollowedCard.style'

const FollowedCard = ({ name, profileImage, isFollowing, nickName, defaultImage, id, type }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector((state) => state.auth)
  const imageUrl = (Array.isArray(profileImage) && profileImage[0]) || defaultImage

  const handleRedirectToUser = () => {
    if (!user) {
      dispatch(verifyUserNotLogged({ state: true, redirectUrl: `${ROUTES[type].PROFILE}/${id}` }))
    } else {
      history.push(`${ROUTES[type].PROFILE}/${id}`)
    }
  }

  return (
    <Link to='#' className={classes.mainContainer} onClick={handleRedirectToUser}>
      <div>
        <Avatar src={imageUrl} variant='circular' style={{ position: 'static' }} />
      </div>
      <div className={classes.containerTitle}>
        <Typography color='primary' variant='h6'>
          {nickName}
        </Typography>
        <Typography className={classes.name}>{name}</Typography>
      </div>
      {!isFollowing ? (
        <Button size='small' color='primary' variant='outlined' className={classes.followButton}>
          Seguir
        </Button>
      ) : (
        <Button size='small' color='primary' variant='contained' className={classes.followButton}>
          Siguiendo
        </Button>
      )}
    </Link>
  )
}

export default FollowedCard
