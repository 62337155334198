import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.contrastText
  },
  headerContainer: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 4)
  },
  date: {
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  sportContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0)
  },
  sport: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  featureImage: {
    width: '100%',
    height: 900,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.up('md')]: {
      maxHeight: 550,
      height: 293,
      minHeight: 284
    }
  },
  nameEventContainer: {
    padding: theme.spacing(2),
    height: 60,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    display: 'flex',
    alignItems: 'center'
  },
  nameEvent: {
    fontWeight: 500,
    fontSize: '.9rem',
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing()
  },
  organizationContainer: {
    padding: theme.spacing(1, 0),
    width: '100%'
  },
  organization: {
    fontSize: '0.9rem',
    overflow: 'hidden',
    color: 'rgba(105, 105, 105, 1)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& span': {
      color: theme.palette.primary.main
    }
  },
  description: {
    fontSize: '0.9rem',
    fontWeight: 500,
    wordWrap: 'break-word',
    paddingLeft: theme.spacing()
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '50%',
    marginBottom: theme.spacing(3),
    '& p': {
      color: 'rgba(105, 105, 105, 1)',
      fontSize: '0.8rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical'
    }
  },
  endContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    padding: '20px 0px',
    marginRight: 25
  },
  link: {
    color: theme.palette.primary.main
  },
  dialog: {
    alignSelf: 'center',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      width: '600px !important',
      maxHeight: 'calc(100% - 60px) !important',
      minHeight: '600px !important',
      maxWidth: '70% !important'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      margin: '0 auto'
    },
    '& > div': {
      paddingTop: '0px !important'
    }
  },
  container: {
    padding: '0px !important',
    width: '100% !important'
  },
  dialogContent: {
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: 10,
      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: theme.utils.rgba(theme.palette.primary.main, 0.45)
    }
  },
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  actionSocialBarButton: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0, 2.5)
  },
  actionButton: {
    color: '#ffff',
    '& em': {
      fontSize: '0.75em',
      fontWeight: 200,
      fontStyle: 'normal',
      fontFamily: theme.typography.fontFamily,
      marginLeft: theme.spacing()
    },
    '&:disabled': {
      color: '#ffff'
    }
  },
  shareButton: {
    color: theme.palette.primary.contrastText,
    borderRadius: 15,
    '& svg': {
      color: theme.palette.primary.contrastText
    }
  }
}))
