import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './SectionBlock.style'

const SectionBlock = ({ children, title, body, hiddenBody = false }) => {
  const classes = useStyles()
  const [isTextRevealed, setIsTextRevealed] = useState(!hiddenBody || (body && body.length < 200))

  return (
    <div className={classes.sectionContainer}>
      <Typography variant='h3' className={classes.title}>
        {title}
      </Typography>

      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <Typography variant='body1'>{body}</Typography>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default SectionBlock
