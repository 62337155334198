import React, { useEffect, useRef, useState } from 'react'
import { Button, IconButton, TextField } from '@material-ui/core'
import {
  AccessTime as AccessTimeIcon,
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  Edit as EditIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon
} from '@material-ui/icons'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import moment from 'moment'
import { Authenticated, Field, NumericField } from 'shared'
import { normalizeUrl } from 'utils/functions'

import useStyles from './EditableText.style'

const resetValues = ({ entity, field, subField, type, timeField }) => {
  switch (type) {
    case 'text':
    case 'url':
    case 'email':
    case 'number':
      return !subField
        ? entity[field] || ''
        : {
            [field]: entity[field],
            [subField]: entity[subField]
          }

    case 'date':
      return { [field]: entity[field] ? moment(entity[field]).format('YYYY-MM-DD') : null }

    case 'datetime':
      return entity[field]
        ? {
            [field]: moment(entity[field]).format('YYYY-MM-DD'),
            [timeField]: moment(entity[timeField]).format()
          }
        : null
    default:
      break
  }
}

const EditableText = ({
  children,
  type,
  entity,
  onSave,
  field,
  timeField,
  defaultTime,
  canEdit,
  inputClassName,
  fullWidth,
  className,
  isMultiline,
  formClassName,
  placeHolder,
  inputType,
  subField,
  onChange,
  validation,
  errors,
  error,
  autoFocus = true,
  disabled,
  activeEditSection,
  handleBlur,
  dateFormat,
  showClearFieldButton,
  externalChange,
  inputMode,
  resetField
}) => {
  const classes = useStyles()
  const ref = useRef(null)

  const [editionComplete, setEditionComplete] = useState(true)
  const [height, setHeight] = useState(0)
  const [responseMessage, setResponseMessage] = useState('')
  const [value, setValue] = useState(resetValues({ entity, field, subField, type, timeField }))

  const editComplete = !activeEditSection && editionComplete

  useEffect(() => {
    if (externalChange) setValue(resetValues({ entity, field, subField, type, timeField }))
  }, [externalChange])

  useEffect(() => {
    setValue(resetValues({ entity, field, subField, type, timeField }))
  }, [resetField])

  useEffect(() => {
    setHeight(ref.current?.clientHeight)

    if (error && error?.response?.data?.field === field) {
      setEditionComplete(false)
      setValue(error.response.data[field])
    }
  }, [setHeight, error, field])

  useEffect(() => {
    if (activeEditSection === false)
      setValue(resetValues({ entity, field, subField, type, timeField }))
  }, [activeEditSection])

  const loadError = async () => {
    if (validation) {
      const response = await validation(value, type, field, subField)
      setResponseMessage(response)
    }
  }

  const clearField = () => setValue('')

  const handleChange = (e, field) => {
    if (!externalChange) {
      const value = e.target.value
      setValue((state) => (field ? { ...state, [field]: value } : value))
    }

    if (onChange) {
      if (type === 'url') e.target.value = normalizeUrl(e.target.value)

      onChange(e, clearField)
    }
  }

  const formatDate = (date) => (moment(date).isValid() ? moment(date).format(dateFormat) : date)

  const handleSave = () => {
    onSave({
      value:
        (type === 'date' && formatDate(value[field])) ||
        (type === 'number' && Number(value)) ||
        value,
      field: !subField && field,
      type
    })

    setEditionComplete(true)
  }

  const handleCancelEdit = () => {
    setValue(resetValues({ entity, field, subField, type, timeField }))
    setEditionComplete(true)
  }

  loadError()

  const hasErrors = subField ? !!errors && (!!errors[field] || !!errors[subField]) : !!errors

  return (
    <Authenticated>
      {editComplete ? (
        <div
          ref={ref}
          className={clsx(
            !canEdit && formClassName,
            classes.editMode,
            height > 36 && classes.editModeAlign
          )}>
          {children}
          {canEdit && (
            <IconButton
              size='small'
              onClick={() => {
                setEditionComplete(false)
              }}>
              <EditIcon />
            </IconButton>
          )}
        </div>
      ) : (
        <div
          className={clsx(
            classes.containerEdit,
            className,
            (isMultiline || !!responseMessage) && classes.containerEditAlign
          )}>
          {!subField ? (
            !type?.startsWith('date') ? (
              <div
                className={clsx(
                  classes.fieldContainer,
                  (fullWidth || showClearFieldButton) && 'fullWidth'
                )}>
                <Field
                  value={value}
                  variant='outlined'
                  size='small'
                  margin='normal'
                  disabled={disabled}
                  component={type === 'number' ? NumericField : TextField}
                  type={inputType ?? type}
                  name={field}
                  inputProps={{
                    inputMode
                  }}
                  onBlur={handleBlur}
                  autoFocus={autoFocus}
                  className={inputClassName}
                  color='primary'
                  multiline={isMultiline}
                  placeholder={placeHolder}
                  error={!!responseMessage || !!errors}
                  helperText={responseMessage || errors}
                  onChange={handleChange}
                />
                {showClearFieldButton && value && (
                  <Button
                    variant='contained'
                    color='primary'
                    className={classes.deleteRulesButton}
                    onClick={clearField}>
                    <DeleteOutlineOutlinedIcon />
                  </Button>
                )}
              </div>
            ) : (
              <>
                <KeyboardDatePicker
                  className={clsx(classes.datePiker, inputClassName, 'datePiker')}
                  format='DD/MM/YYYY'
                  autoOk
                  variant='inline'
                  margin='normal'
                  id='date-picker-inline'
                  inputVariant='outlined'
                  value={value[field]}
                  name={field}
                  onChange={(e) => {
                    if (onChange) onChange({ target: { value: formatDate(e), name: field } })
                    setValue((state) => ({
                      ...state,
                      [field]: formatDate(e),

                      ...(type === 'datetime' &&
                        !state[timeField] &&
                        !!e &&
                        moment(e).isValid() && {
                          [timeField]: defaultTime
                        })
                    }))
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'seleccione fecha'
                  }}
                  placeholder='dd/mm/aaaa'
                  error={!!responseMessage || !!errors}
                  helperText={responseMessage || errors}
                />
                {type === 'datetime' && (
                  <KeyboardTimePicker
                    className={clsx(inputClassName, 'timePicker')}
                    autoOk
                    name={timeField}
                    onBlur={handleBlur}
                    variant='inline'
                    ampm={false}
                    inputVariant='outlined'
                    keyboardIcon={<AccessTimeIcon />}
                    value={value[timeField]}
                    placeholder='hh:mm'
                    onChange={(e) => {
                      if (onChange) onChange({ target: { value: formatDate(e), name: timeField } })
                      setValue((state) => ({ ...state, [timeField]: formatDate(e) }))
                    }}
                    error={!!responseMessage || !!errors}
                    helperText={responseMessage || errors}
                  />
                )}
              </>
            )
          ) : (
            <>
              <Field
                value={value[field]}
                variant='outlined'
                size='small'
                margin='normal'
                disabled={disabled}
                component={type === 'number' ? NumericField : TextField}
                type={type}
                name={field}
                inputProps={{
                  inputMode
                }}
                onBlur={handleBlur}
                autoFocus
                className={inputClassName}
                color='primary'
                multiline={isMultiline}
                placeholder={placeHolder[field]}
                error={!!responseMessage || !!errors[field]}
                helperText={responseMessage || errors[field]}
                onChange={(e) => handleChange(e, field)}
              />
              <Field
                value={value[subField]}
                variant='outlined'
                size='small'
                margin='normal'
                disabled={disabled}
                component={type === 'number' ? NumericField : TextField}
                type={type}
                name={field}
                inputProps={{
                  inputMode
                }}
                onBlur={handleBlur}
                autoFocus
                className={inputClassName}
                color='primary'
                multiline={isMultiline}
                placeholder={placeHolder[subField]}
                error={!!responseMessage || !!errors[subField]}
                helperText={responseMessage || errors[subField]}
                onChange={(e) => handleChange(e, subField)}
              />
            </>
          )}
          {!activeEditSection && (
            <>
              <IconButton
                disabled={!!responseMessage || hasErrors}
                color='primary'
                onClick={handleSave}
                size='small'>
                <CheckCircleOutlineOutlinedIcon />
              </IconButton>
              <IconButton onClick={handleCancelEdit} size='small'>
                <HighlightOffOutlinedIcon />
              </IconButton>
            </>
          )}
        </div>
      )}
    </Authenticated>
  )
}

export default EditableText
