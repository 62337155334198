import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  backdrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.47)
  },
  drawer: {
    width: '100vw',
    marginTop: 61,
    paddingBottom: 100,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginTop: 91,
      width: '33vw'
    },
    [theme.breakpoints.up('xl')]: {
      width: '25vw'
    }
  },
  drawerBody: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    '& ul': {
      padding: 0
    }
  },
  closeButtonContainer: {
    textAlign: 'right'
  },
  closeButton: {
    color: theme.palette.secondary.main
  },
  closeIcon: {
    width: 35,
    height: 35,
    [theme.breakpoints.up('lg')]: {
      width: 40,
      height: 40
    }
  },
  menuList: {
    flexGrow: 1,
    listStyle: 'none',
    '& li': {
      paddingBottom: theme.spacing(2)
    }
  },
  menuItem: {
    color: theme.palette.primary.main,
    fontSize: '2rem',
    textDecoration: 'underline',
    fontWeight: 700
  },
  menuFooter: {
    flexGrow: 0,
    listStyle: 'none',
    borderTopStyle: 'solid',
    borderTopWidth: 2,
    borderTopColor: theme.palette.primary.main,
    '& li': {
      paddingTop: theme.spacing(2)
    }
  },
  smallMenuItem: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  drawerFooter: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: 0,
    paddingBottom: theme.spacing(2)
  }
}))

export default useStyles
