import React, { useState } from 'react'
import { SocialIcon } from 'react-social-icons'
import { IconButton, TextField, useTheme } from '@material-ui/core'
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  Edit as EditIcon,
  HighlightOffOutlined as HighlightOffOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Authenticated } from 'shared'
import { normalizeUrl } from 'utils/functions'

import { useStyles } from './EditableSocialNetwork.style'

const pattern =
  // eslint-disable-next-line
  /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i

const EditableSocialNetwork = ({ children, canEdit, inputClassName, onSave, entity }) => {
  const socialSize = { height: 48, width: 48 }
  const classes = useStyles()
  const theme = useTheme()
  const [editComplete, setEditComplete] = useState(true)

  const resetValues = () => ({
    facebookUrl: entity.facebookUrl,
    youTubeUrl: entity.youTubeUrl,
    twitterUrl: entity.twitterUrl,
    instagramUrl: entity.instagramUrl
  })
  const [value, setValue] = useState(resetValues)

  const { facebookUrl, youTubeUrl, instagramUrl, twitterUrl } = value

  const responseError = () => {
    const message = 'La URL ingresada no corresponde con una dirección'
    const messageError = {}

    if (!!instagramUrl && !pattern.test(normalizeUrl(instagramUrl))) {
      messageError.instagramUrlError = `${message} instagram válida`
    }

    if (!!twitterUrl && !pattern.test(normalizeUrl(twitterUrl))) {
      messageError.twitterUrlError = `${message} twitter válida`
    }

    if (!!youTubeUrl && !pattern.test(normalizeUrl(youTubeUrl))) {
      messageError.youTubeUrlError = `${message} youTube válida`
    }

    if (!!facebookUrl && !pattern.test(normalizeUrl(facebookUrl))) {
      messageError.facebookUrlError = `${message} facebook válida`
    }

    return messageError
  }

  const handleChange = (e) => setValue((state) => ({ ...state, [e.target.name]: e.target.value }))

  const handleSave = () => {
    const values = {
      facebookUrl: normalizeUrl(facebookUrl),
      twitterUrl: normalizeUrl(twitterUrl),
      youTubeUrl: normalizeUrl(youTubeUrl),
      instagramUrl: normalizeUrl(instagramUrl)
    }

    onSave({ value: values })
    setEditComplete(true)
  }

  const responseMessage = responseError()

  return (
    <Authenticated>
      {editComplete ? (
        <>
          {children}
          {canEdit && (
            <IconButton
              className={classes.bottonIcon}
              size='small'
              onClick={() => {
                setEditComplete(false)
              }}>
              <EditIcon />
            </IconButton>
          )}
        </>
      ) : (
        <div className={classes.editMode}>
          <div className={classes.form}>
            <div className={classes.formNetwork}>
              <SocialIcon
                network='instagram'
                style={socialSize}
                bgColor='transparent'
                fgColor={theme.palette.primary.main}
              />
              <TextField
                value={instagramUrl}
                variant='outlined'
                size='small'
                margin='normal'
                name='instagramUrl'
                className={clsx(classes.textField, inputClassName)}
                color='primary'
                error={!!responseMessage.instagramUrlError}
                onChange={handleChange}
                helperText={responseMessage.instagramUrlError}
              />
            </div>
            <div className={classes.formNetwork}>
              <SocialIcon
                network='twitter'
                style={socialSize}
                bgColor='transparent'
                fgColor={theme.palette.primary.main}
              />
              <TextField
                value={twitterUrl}
                variant='outlined'
                size='small'
                margin='normal'
                name='twitterUrl'
                className={clsx(classes.textField, inputClassName)}
                color='primary'
                error={!!responseMessage.twitterUrlError}
                helperText={responseMessage.twitterUrlError}
                onChange={handleChange}
              />
            </div>
            <div className={classes.formNetwork}>
              <SocialIcon
                network='facebook'
                style={socialSize}
                bgColor='transparent'
                fgColor={theme.palette.primary.main}
              />
              <TextField
                value={facebookUrl}
                variant='outlined'
                size='small'
                margin='normal'
                className={clsx(classes.textField, inputClassName)}
                color='primary'
                name='facebookUrl'
                error={!!responseMessage.facebookUrlError}
                helperText={responseMessage.facebookUrlError}
                onChange={handleChange}
              />
            </div>
            <div className={classes.formNetwork}>
              <SocialIcon
                network='youtube'
                style={socialSize}
                bgColor='transparent'
                fgColor={theme.palette.primary.main}
              />
              <TextField
                value={youTubeUrl}
                variant='outlined'
                size='small'
                margin='normal'
                name='youTubeUrl'
                className={clsx(classes.textField, inputClassName)}
                color='primary'
                error={!!responseMessage.youTubeUrlError}
                helperText={responseMessage.youTubeUrlError}
                onChange={handleChange}
              />
            </div>
          </div>

          <IconButton
            color='primary'
            onClick={handleSave}
            size='small'
            disabled={!!Object.entries(responseMessage).length}>
            <CheckCircleOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setValue(resetValues)
              setEditComplete(true)
            }}
            size='small'>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </div>
      )}
    </Authenticated>
  )
}

export default EditableSocialNetwork
