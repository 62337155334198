import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  locationContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),

    '& > div > svg': {
      width: 25,
      height: 25
    }
  },

  locationIcon: {
    marginBottom: theme.spacing()
  },

  address: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere'
  },
  locationButton: {
    backgroundColor: '#fff',
    borderRadius: 10,
    '& span': {
      fontWeight: 500
    }
  }
}))
