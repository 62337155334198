import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Typography, useMediaQuery } from '@material-ui/core'
import { loadUpcomingEventsManaged } from 'state/modules/userAccounts'

import { EventContainer } from '../EventContainer'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './UpcomingEventManageByMeSection.style'

const UpcomingEventManageByMeSection = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const { userAccountId } = useParams()
  const { upcomingEventsManaged } = useSelector((state) => state.userAccounts)

  useEffect(() => {
    dispatch(loadUpcomingEventsManaged(userAccountId))
  }, [userAccountId])

  return (
    !!upcomingEventsManaged.length && (
      <SectionBlock title={!isDesktop && 'Próximos eventos de org. administradas por mí'}>
        <div className={classes.mainContainer}>
          {isDesktop && (
            <Typography className={classes.title} color='primary' variant='h6'>
              Próximos eventos de organizaciones administradas por mí
            </Typography>
          )}
          <EventContainer
            events={upcomingEventsManaged}
            autoplay={true}
            infinite={upcomingEventsManaged.length > 3}
          />
        </div>
      </SectionBlock>
    )
  )
}

export default UpcomingEventManageByMeSection
