import React from 'react'
import { NavLink } from 'react-router-dom'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { ReactComponent as SportmetricLogo } from 'assets/icons/logo-landing.svg'
import { ReactComponent as SportmetricWhiteLogo } from 'assets/icons/logo-landing-white.svg'
import clsx from 'clsx'
import { ROUTES } from 'routes'

import { useStyles } from './NavBar.style'

const NavBar = ({ altMode, position = 'absolute', className, onCloseProps = {} }) => {
  const classes = useStyles()

  return (
    <AppBar position={position} className={clsx(classes.appBarTransparent, className)}>
      <Toolbar className={classes.toolbar}>
        <NavLink to={ROUTES.ROOT} className={classes.link}>
          <div className={classes.logoContainer}>
            {altMode ? (
              <SportmetricWhiteLogo className={clsx(classes.logo, onCloseProps.sportmetricLogo)} />
            ) : (
              <SportmetricLogo className={classes.logo} />
            )}
          </div>
        </NavLink>
        {onCloseProps.activeClose && (
          <IconButton onClick={onCloseProps.onCloseAction} className={onCloseProps.buttonClassName}>
            <CloseIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
