import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem',
    padding: '20px 35px 0 35px',
    '& span': {
      fontWeight: 600
    }
  },
  dialog: {
    width: 700
  },
  button: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  textEnd: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: '15px 35px 0 35px',
    marginBottom: 35
  }
}))
