import * as types from './types'

// InitialState
const initialState = {
  organization: null,
  pending: false,

  searchParams: {
    searchText: '',
    page: 1
  },
  searchOrganizations: [],
  searchOrganizationsPageNumber: 1,
  searchOrganizationsLastPage: false,

  organizationsCloneSuggestions: [],
  organizationsCloneSuggestionsPending: false,
  organizationsCloneSuggestionsError: null,

  searchMetadataPending: false,
  searchMetadata: null,

  unPublishedEvents: [],
  unPublishedEventsPending: false,

  upcomingEvents: [],
  upcomingEventsPending: false,

  pastEvents: [],
  pastEventsPending: false,

  settingImage: false,
  error: null,
  errorUpdate: null,
  followPending: false,
  deleteImagePending: false,

  customFields: [],
  customfieldsPending: false,
  customfieldsError: null,

  createCustomFieldPending: false,
  createCustomFieldError: null,

  updateCustomFieldPending: false,
  updateCustomFieldError: null,

  deleteCustomFieldPending: false,
  deleteCustomFieldError: null,

  acceptAdminUserPending: false,
  acceptAdminUserError: null,

  rejectAdminUserPending: false,
  rejectAdminUserError: null,

  sendOnboardingInformationPending: false,
  sendOnboardingInformationError: null,

  serviceLetterReport: null,
  loadServiceLetterReportPending: false,
  loadServiceLetterReportError: null,

  createServiceLetterReportPending: false,
  createServiceLetterReportError: null,

  activeServiceLetterReport: null,
  loadActiveServiceLetterReportPending: false,
  loadActiveServiceLetterReportError: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ATTEMPT:
      return {
        ...state,
        organization: null,
        error: null,
        pending: true
      }
    case types.LOAD_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        pending: false
      }
    case types.LOAD_FAILURE:
      return {
        ...state,
        organization: null,
        pending: false,
        error: action.error
      }
    case types.CREATE_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.CREATE_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        pending: false
      }
    case types.CREATE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.SET_IMAGE_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true,
        settingImage: true
      }
    case types.SET_IMAGE_SUCCESS:
      return {
        ...state,
        settingImage: false,
        pending: false,
        organization: action.organization
      }
    case types.SET_IMAGE_FAILURE:
      return {
        ...state,
        settingImage: false,
        pending: false,
        error: action.error
      }
    case types.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: action.searchParams
      }
    case types.SEARCH_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.SEARCH_SUCCESS:
      return {
        ...state,
        searchOrganizations: [...state.searchOrganizations, ...action.organizationsPage.results],
        searchOrganizationsPageNumber: action.organizationsPage.currentPage,
        searchOrganizationsLastPage:
          action.organizationsPage.pageCount <= action.organizationsPage.currentPage,
        pending: false
      }
    case types.SEARCH_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.RESET_SEARCH:
      return {
        ...state,
        pending: false,
        error: null,
        searchOrganizations: [],
        searchOrganizationsPageNumber: 1
      }
    case types.SEARCH_METADATA_ATTEMPT:
      return {
        ...state,
        searchMetadataPending: true
      }
    case types.SEARCH_METADATA_SUCCESS:
      return {
        ...state,
        searchMetadataPending: false,
        searchMetadata: action.searchMetadata
      }
    case types.SEARCH_METADATA_FAILURE:
      return {
        ...state,
        searchMetadataPending: false,
        error: action.error
      }
    case types.LOAD_UPCOMING_EVENTS_ATTEMPT:
      return {
        ...state,
        error: null,
        upcomingEventsPending: true
      }
    case types.LOAD_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        upcomingEvents: action.upcomingEventsPage.results,
        upcomingEventsPending: false
      }
    case types.LOAD_UPCOMING_EVENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        upcomingEventsPending: false
      }
    case types.LOAD_PAST_EVENTS_ATTEMPT:
      return {
        ...state,
        error: null,
        pastEventsPending: true
      }
    case types.LOAD_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        pastEvents: action.pastEventsPage.results,
        pastEventsPending: false
      }
    case types.LOAD_PAST_EVENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        pastEventsPending: false
      }
    case types.UPDATE_ATTEMPT:
      return {
        ...state,
        pending: true
      }
    case types.UPDATE_FAILURE:
      return {
        ...state,
        pending: false,
        errorUpdate: action.error
      }
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        pending: false,
        errorUpdate: null
      }
    case types.LOAD_RESET:
      return {
        ...state,
        organization: null,
        pastEvents: [],
        upcomingEvents: [],
        unPublishedEvents: [],
        error: null
      }
    case types.FOLLOW_ATTEMPT:
      return {
        ...state,
        followPending: true
      }
    case types.FOLLOW_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        followPending: false
      }
    case types.FOLLOW_FAILURE:
      return {
        ...state,
        error: action.error,
        followPending: false
      }
    case types.LOAD_UN_PUBLISHED_EVENTS_ATTEMPT:
      return {
        ...state,
        unPublishedEventsPending: true,
        error: null
      }
    case types.LOAD_UN_PUBLISHED_EVENTS_SUCCESS:
      return {
        ...state,
        unPublishedEvents: action.unPublishedEventsPage.results,
        unPublishedEventsPending: false
      }
    case types.LOAD_UN_PUBLISHED_EVENTS_FAILURE:
      return {
        ...state,
        unPublishedEventsPending: false,
        error: action.error
      }
    case types.DELETE_IMAGE_ATTEMPT:
      return {
        ...state,
        deleteImagePending: true
      }
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        organization: action.organization,
        deleteImagePending: false
      }
    case types.DELETE_IMAGE_FAILURE:
      return {
        ...state,
        error: action.error,
        deleteImagePending: false
      }
    case types.RESET_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          searchText: '',
          page: 1
        },
        searchOrganizations: [],
        searchOrganizationsPageNumber: 1,
        searchOrganizationsLastPage: false
      }
    case types.LOAD_CUSTOM_FIELDS_ATTEMPT:
      return {
        ...state,
        customFieldsPending: true,
        customFields: []
      }
    case types.LOAD_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: action.customFields.results,
        customFieldsPending: false
      }
    case types.LOAD_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        customFieldsPending: false,
        error: action.error,
        customFields: []
      }

    case types.CREATE_CUSTOM_FIELD_ATTEMPT:
      return {
        ...state,
        error: null,
        createCustomFieldPending: true
      }
    case types.CREATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        createCustomFieldPending: false
      }
    case types.CREATE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        createCustomFieldPending: false,
        createCustomFieldError: action.error
      }
    case types.UPDATE_CUSTOM_FIELD_ATTEMPT:
      return {
        ...state,
        updateCustomFieldError: null,
        updateCustomFieldPending: true
      }
    case types.UPDATE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        updateCustomFieldPending: false
      }
    case types.UPDATE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        updateCustomFieldPending: false,
        updateCustomFieldError: action.error
      }
    case types.DELETE_CUSTOM_FIELD_ATTEMPT:
      return {
        ...state,
        deleteCustomFieldError: null,
        deleteCustomFieldPending: true
      }
    case types.DELETE_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        deleteCustomFieldPending: false
      }
    case types.DELETE_CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        deleteCustomFieldPending: false,
        deleteCustomFieldError: action.error
      }

    case types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_ATTEMPT:
      return {
        ...state,
        organizationsCloneSuggestions: [],
        organizationsCloneSuggestionsPending: true,
        organizationsCloneSuggestionsError: null
      }
    case types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        organizationsCloneSuggestions: action.organizationsCloneSuggestions,
        organizationsCloneSuggestionsPending: false
      }
    case types.LOAD_ORGANIZATIONS_CLONE_SUGGESTIONS_FAILURE:
      return {
        ...state,
        organizationsCloneSuggestionsError: action.error,
        organizationsCloneSuggestionsPending: false
      }

    case types.ACCEPT_ADMIN_USER_ATTEMPT:
      return {
        ...state,
        acceptAdminUserPending: true,
        acceptAdminUserError: null
      }

    case types.ACCEPT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        acceptAdminUserPending: false
      }

    case types.ACCEPT_ADMIN_USER_FAILURE:
      return {
        ...state,
        acceptAdminUserPending: false,
        acceptAdminUserError: action.error
      }

    case types.REJECT_ADMIN_USER_ATTEMPT:
      return {
        ...state,
        rejectAdminUserPending: true,
        rejectAdminUserError: null
      }
    case types.REJECT_ADMIN_USER_SUCCESS:
      return {
        ...state,
        rejectAdminUserPending: false
      }
    case types.REJECT_ADMIN_USER_FAILURE:
      return {
        ...state,
        rejectAdminUserPending: false,
        rejectAdminUserError: action.error
      }

    case types.SEND_ONBOARDING_INFORMATION_ATTEMPT:
      return {
        ...state,
        sendOnboardingInformationPending: true,
        sendOnboardingInformationError: null
      }
    case types.SEND_ONBOARDING_INFORMATION_SUCCESS:
      return {
        ...state,
        sendOnboardingInformationPending: false
      }
    case types.SEND_ONBOARDING_INFORMATION_FAILURE:
      return {
        ...state,
        sendOnboardingInformationPending: false,
        sendOnboardingInformationError: action.error
      }
    case types.LOAD_SERVICE_LETTER_REPORT_ATTEMPT:
      return {
        ...state,
        loadServiceLetterReportPending: true,
        loadServiceLetterReportError: null
      }
    case types.LOAD_SERVICE_LETTER_REPORT_SUCCESS:
      return {
        ...state,
        serviceLetterReport: action.data,
        loadServiceLetterReportPending: false
      }
    case types.LOAD_SERVICE_LETTER_REPORT_FAILURE:
      return {
        ...state,
        loadServiceLetterReportPending: false,
        loadServiceLetterReportError: action.error
      }
    case types.CREATE_SERVICE_LETTER_REPORT_ATTEMPT:
      return {
        ...state,
        createServiceLetterReportPending: true,
        createServiceLetterReportError: null
      }
    case types.CREATE_SERVICE_LETTER_REPORT_SUCCESS:
      return {
        ...state,
        createServiceLetterReportPending: false
      }
    case types.CREATE_SERVICE_LETTER_REPORT_FAILURE:
      return {
        ...state,
        createServiceLetterReportPending: false,
        createServiceLetterReportError: action.error
      }

    case types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_ATTEMPT:
      return {
        ...state,
        loadActiveServiceLetterReportPending: true,
        loadActiveServiceLetterReportError: null
      }
    case types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_SUCCESS:
      return {
        ...state,
        loadActiveServiceLetterReportPending: false,
        activeServiceLetterReport: action.data
      }

    case types.LOAD_ACTIVE_SERVICE_LETTER_REPORT_FAILURE:
      return {
        ...state,
        loadActiveServiceLetterReportPending: false,
        loadActiveServiceLetterReportError: action.error
      }

    case types.RESET_VALUE:
      return {
        ...state,
        [action.value]: initialState[action.value]
      }
    default:
      return state
  }
}
