import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  schedule: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  anchorContainer: {
    display: 'none', // replace
    gap: theme.spacing(),
    alignItems: 'center'
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem',
    cursor: 'pointer'
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    fontSize: '.875rem',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 400
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 700
    }
  }
}))
