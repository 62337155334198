import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',

    '& > div': {
      backgroundColor: theme.palette.primary.contrastText
    },

    '& input': {
      height: 5,
      color: '#4285F4',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },

    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  }
}))
