import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#ffff',
    [theme.breakpoints.up('lg')]: {
      width: '78%'
    }
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    '& h6': {
      flexGrow: 1,
      fontWeight: 400
    }
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  bodyContainer: {
    padding: theme.spacing(2),
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    '& p': {
      fontSize: '.9rem',
      paddingTop: theme.spacing()
    }
  },
  content: {
    display: 'flex'
  },
  label: {
    paddingRight: theme.spacing(),
    color: ' rgba(105, 105, 105, 1)'
  },
  value: {
    color: 'rgba(0, 0, 0, 1)'
  },

  deleteDialogContainer: {
    '& > div > div': {
      minHeight: 'auto'
    }
  },
  editIcon: {
    color: theme.palette.primary.contrastText
  }
}))
