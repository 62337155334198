import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textDescription: {
    paddingTop: 10,
    textAlign: 'left',
    fontSize: '1rem',
    color: theme.palette.primary.contrastText,
    '& strong': {
      fontWeight: 600
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    '&  h3': {
      fontFamily: theme.typography.fontFamilyAlt,
      fontSize: '1.625rem',
      fontWeight: 600,
      textAlign: 'center',
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.375rem',
        textAlign: 'left'
      }
    }
  }
}))
