import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  container: {
    padding: theme.spacing(6, 0),
    width: 250,
    margin: '0 auto'
  },
  paymentLocationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  }
}))
