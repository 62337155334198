import React, { useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useStyles } from './CustomFormGroup.style'

const CustomFormGroup = ({
  className,
  label,
  value,
  onBlur = () => {},
  onFocus = () => {},
  placeholder,
  autoFocus,
  styles = { label: '', input: '' },

  ...textFieldProps
}) => {
  const classes = useStyles()

  const [focussedInput, setfocussedInput] = useState(autoFocus)

  const handleOnFocus = (e) => {
    onFocus(e)

    if (value) return

    setfocussedInput(true)
  }

  const handleOnBlur = (e) => {
    onBlur(e)

    if (value) return

    setfocussedInput(false)
  }

  const labelAndPlaceholder = !value && !focussedInput ? { placeholder } : { label }

  return (
    <div className={classes.formGroup}>
      <TextField
        {...textFieldProps}
        type='text'
        autoFocus={autoFocus}
        onFocus={handleOnFocus}
        placeholder={labelAndPlaceholder.placeholder}
        onBlur={handleOnBlur}
        value={value}
        className={clsx(classes.customTextField, styles.input)}
        onWheel={(e) => e.target.blur()}
      />
      {Boolean(labelAndPlaceholder.label) && (
        <Typography className={clsx(classes.label, styles.label)}>
          {labelAndPlaceholder.label}
        </Typography>
      )}
    </div>
  )
}

export default CustomFormGroup
