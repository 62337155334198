import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { useFormikContext } from 'formik'
import { CustomizedTooltip, NumericField } from 'shared'
import { createDistance } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { ActionDialog } from '../ActionDialog'
import { EmptyCategoriesNotifyDialog } from '../EmptyCategoriesNotifyDialog'

import { useStyles } from './CreateDistanceDialog.style'

const distance = {
  name: '',
  distance: '',
  quota: '',
  categories: {},
  categoryQuotas: {}
}

const generateValidation = (categories) => {
  const keys = Object.keys(categories)

  const schema = keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: Yup.number()
        .typeError('Ingrese un número válido')
        .integer('Ingrese un número entero')
        .max(99999999, 'Se puede ingresar hasta 8 dígitos')
        .test(
          'test-negative-quota',
          'El cupo no puede ser un número negativo',
          (value = 0) => +value >= 0
        )
    }),
    {}
  )

  return schema
}

const OPTIONS = {
  Category: { label: 'categorías', key: 'Category' },
  Distance: { label: 'distancias', key: 'Distance' }
}

const CreateDistanceDialog = ({
  open,
  onClose,
  asyncError,
  eventId,
  items,
  allowCategoriesInMultipleDistances
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { setValues, values: formState } = useFormikContext()
  const { categories, createDistancePending } = useSelector((state) => state.events)
  const { values } = useFormikContext()

  const categoryOptions = categories.filter(
    (x) => allowCategoriesInMultipleDistances || !items.some((i) => i.eventCategory.id === x.id)
  )

  const [openEmptyCategoriesDialog, setOpenEmptyCategoriesDialog] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre de la distancia es requerido')
      .trim()
      .max(100, 'El nombre de la distancia no puede exceder de 100 caracteres')
      .test(
        'unique',
        'El nombre de la distancia ya existe',
        (value) => !asyncError || value !== asyncError.name
      ),

    distance: Yup.number()
      .typeError('Ingrese un número válido')
      .required('La distancia es requerida')
      .positive('Ingrese un valor mayor a 0')
      .integer('Ingrese un número entero')
      .max(99999, 'No puede exceder la distancia máxima permitida'),
    quota: Yup.number()
      .typeError('Ingrese un número válido')
      .integer('Ingrese un número entero')
      .max(99999999, 'Se puede ingresar hasta 8 dígitos')
      .test(
        'test-negative-quota',
        'El cupo no puede ser un número negativo',
        (value = 0) => +value >= 0
      )
      .test(
        'test-min-quota',
        'El cupo distancia debe ser mayor al total de cupos por categoría',
        (value, context) => {
          if (!value) return true

          const quotaCategories = Object.values(context.parent.categoryQuotas)
            .filter(Boolean)
            .reduce((acc, e) => acc + e, 0)

          return value >= quotaCategories
        }
      ),

    categoryQuotas: Yup.lazy((value) =>
      Object.values(value).filter(Boolean).length
        ? Yup.object().shape(generateValidation(value))
        : Yup.object()
    )
  })

  const handleChangeCategory = (option, setValues, values) => (e) => {
    const checked = e.target.checked

    const categorySelected = {
      ...values.categories,
      [option.name]: checked ? option : null
    }

    const categoryQuotaSelected = {
      ...values.categoryQuotas,
      [option.name]: checked ? values.categoryQuotas[option.name] : ''
    }

    setValues({ ...values, categoryQuotas: categoryQuotaSelected, categories: categorySelected })
  }

  const handleAllSelectOptions = (setFieldValue) => {
    const allOptions = categoryOptions.reduce(
      (acc, category) => ({ ...acc, [category.name]: category }),
      {}
    )
    setFieldValue('categories', allOptions)
  }

  const handleAllRemoveOptions = (setFieldValue) => {
    setFieldValue('categoryQuotas', {})
    setFieldValue('categories', {})
  }

  const onSubmit = async ({ categories, distance, name, quota, categoryQuotas }) => {
    const categoryList = Object.values(categories).filter(Boolean)

    if (!categoryList.length) {
      setOpenEmptyCategoriesDialog(true)
      return
    }

    const distanceModel = {
      name: name.trim(),
      distance: Number(distance),
      quota: Number(quota)
    }

    const model = await dispatch(createDistance(eventId, distanceModel))

    if (model) {
      const inscriptionFormItems = categoryList.map((category) => ({
        eventCategoryId: category.id,
        eventCategory: category,
        eventDistanceId: model.id,
        eventDistance: model,
        validateGender: values.validateGender,
        validateAge: values.validateAge,
        quota: Number(categoryQuotas[category.name]) || 0
      }))

      showSnackbarSuccess('¡Distancia creada con éxito!')
      setValues((values) => ({
        ...values,
        items: [...items, ...inscriptionFormItems],
        ticketTypes:
          formState.associatedBy === OPTIONS.Category.key
            ? values.ticketTypes.map((x) => ({
                ...x,
                items: [
                  ...x.items,
                  ...inscriptionFormItems
                    .filter((r) =>
                      x.items.some(
                        (i) => i.inscriptionFormItem.eventCategoryId === r.eventCategoryId
                      )
                    )
                    .map((inscriptionFormItem) => ({ inscriptionFormItem }))
                ]
              }))
            : values.ticketTypes
      }))

      return true
    }
  }

  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      action={onSubmit}
      values={distance}
      title='CREAR DISTANCIA'
      className={classes.dialog}
      contentClassName={classes.content}
      formClassName={classes.formContainer}
      validationSchema={validationSchema}>
      {({
        handleChange,
        handleBlur,
        errors,
        touched,
        values,
        setFieldValue,
        isValid,
        isSubmitting,
        setValues
      }) => {
        const someSeletedOption = Object.values(values.categories).filter(Boolean).length

        return (
          <div className={classes.sectionContainer}>
            <div className={classes.fieldContainer}>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='caption'>
                  Nombre*
                </Typography>
                <TextField
                  variant='outlined'
                  value={values.name}
                  name='name'
                  helperText={errors.name}
                  autoComplete='off'
                  error={touched.name && !!errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size='small'
                  fullWidth
                  color='primary'
                  placeholder='Nombre de la nueva distancia'
                />
              </div>
              <div className={classes.formGroup}>
                <Typography variant='caption' color='primary'>
                  Distancia*
                </Typography>
                <NumericField
                  variant='outlined'
                  size='small'
                  color='primary'
                  name='distance'
                  helperText={errors.distance}
                  error={touched.distance && !!errors.distance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  placeholder='Distancia en metros'
                />
              </div>
              <div className={classes.formGroup}>
                <Typography variant='caption' color='primary'>
                  Cupo distancia (opcional)
                </Typography>
                <NumericField
                  variant='outlined'
                  size='small'
                  fullWidth
                  color='primary'
                  name='quota'
                  helperText={errors.quota}
                  className={classes.distanceQuota}
                  error={touched.quota && !!errors.quota}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete='off'
                  placeholder='Ej: 2000'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CustomizedTooltip
                          position='left-end'
                          arrow
                          title='La sumatoria de cupo por distancia debe ser inferior o igual al cupo total del evento.'>
                          {({ handleTooltip }) => (
                            <IconButton onClick={handleTooltip}>
                              <InfoIcon color='primary' />
                            </IconButton>
                          )}
                        </CustomizedTooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            {!!categoryOptions.length && (
              <>
                <Typography
                  color='primary'
                  variant='h6'
                  align='center'
                  className={classes.underlineText}>
                  Asociar con las siguientes categorías
                </Typography>
                <div className={classes.subtitleContainer}>
                  <Typography
                    color='primary'
                    variant='caption'
                    align='right'
                    className={classes.labelQuota}>
                    Cupo x categoría (opcional)
                  </Typography>
                  {!someSeletedOption ? (
                    <Typography
                      color='primary'
                      variant='h6'
                      onClick={() => handleAllSelectOptions(setFieldValue)}
                      className={classes.textLink}>
                      Seleccionar todas
                    </Typography>
                  ) : (
                    <Typography
                      color='primary'
                      variant='h6'
                      onClick={() => handleAllRemoveOptions(setFieldValue)}
                      className={classes.textLink}>
                      Limpiar selección
                    </Typography>
                  )}
                </div>
              </>
            )}
            {categoryOptions.map((c) => (
              <div
                className={clsx(
                  classes.checkContainer,
                  !values.categories[c.name] && classes.optionDisabled
                )}
                key={c.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={!!values.categories[c.name]}
                      onChange={handleChangeCategory(c, setValues, values)}
                      name={c.name}
                    />
                  }
                  title={c.name}
                  classes={{ label: classes.label }}
                  className={classes.formControl}
                  label={c.name}
                />
                <Typography align='left' className={classes.label} title={c.shortName}>
                  {c.shortName}
                </Typography>
                <div className={clsx(classes.formGroup, classes.quota)}>
                  <NumericField
                    variant='outlined'
                    color='primary'
                    size='small'
                    name={`categoryQuotas.${c.name}`}
                    disabled={!values.categories[c.name]}
                    errors={
                      touched.categoryQuotas &&
                      touched.categoryQuotas[c.name] &&
                      errors.categoryQuotas &&
                      errors.categoryQuotas[c.name]
                    }
                    helperText={errors.categoryQuotas && errors.categoryQuotas[c.name]}
                    placeholder='Ej: 2000'
                    onChange={handleChange}
                    value={values.categories[c.name] ? values.categoryQuotas[c.name] : ''}
                  />
                </div>
              </div>
            ))}

            <div className={classes.buttonContainer}>
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={createDistancePending && <CircularProgress size={16} color='primary' />}
                className={classes.button}
                disabled={!isValid || isSubmitting}>
                Crear
              </Button>
              <EmptyCategoriesNotifyDialog
                open={openEmptyCategoriesDialog}
                message='Para poder crear distancias necesitas asociarla con al menos una de las categorías de tu evento.'
                onClose={() => setOpenEmptyCategoriesDialog(false)}
              />
            </div>
          </div>
        )
      }}
    </ActionDialog>
  )
}

export default CreateDistanceDialog
