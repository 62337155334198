import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { ROUTES } from 'routes'
import { SectionLayoutDialog } from 'shared'
import { setActiveRole } from 'state/modules/app'
import { updateUserAccount } from 'state/modules/userAccounts'
import { USER_ROLE } from 'utils/constants'

import { useStyles } from './RequiredSportsRoleDialog.style'

const RequiredSportsRoleDialog = ({ open, onClose, onAction = () => {} }) => {
  const classes = useStyles()
  const history = useHistory()
  const { slugUrlOrId } = useParams()
  const { userData } = useSelector((state) => state.auth)
  const { pending } = useSelector((state) => state.userAccounts)
  const dispatch = useDispatch()

  const handleOnClose = () => {
    onClose()
    history.replace(`${ROUTES.EVENTS.PROFILE}/${slugUrlOrId}`)
  }

  const handleSave = async () => {
    const userAccount = {
      ...userData,
      isAthlete: true
    }

    const successUpdate = await dispatch(updateUserAccount(userAccount))
    if (successUpdate) {
      dispatch(setActiveRole(USER_ROLE.ATHLETE))
      onAction()
      onClose()
    }
  }

  return (
    <SectionLayoutDialog open={open} title={`ROL DEPORTISTA REQUERIDO`} className={classes.dialog}>
      <div className={classes.textContainer}>
        <Typography className={classes.text}>
          Para inscribirte en este evento, es necesario que actives el <b>Rol Deportista</b>, si
          haces click en continuar lo activaremos por ti para que puedas seguir con la inscripción.
        </Typography>
      </div>
      <div className={classes.buttonContainer}>
        <Button variant='outlined' color='primary' onClick={handleOnClose} disabled={pending}>
          <b>Cancelar</b>
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={handleSave}
          disabled={pending}
          endIcon={pending && <CircularProgress size={16} color='primary' />}>
          Continuar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default RequiredSportsRoleDialog
