import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { ReactComponent as CronosIcon } from 'assets/icons/cronos-logo-icon.svg'
import { ROUTES } from 'routes'

import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './EventTimingSection.style'

const EventTimingSection = ({ activeAnimation, endAnimationAction, ...sectionBlockProps }) => {
  const classes = useStyles()

  return (
    <SectionBlock
      nextSectionId='generatorProfileSection'
      containerClassName={classes.container}
      buttonClassName={classes.endButton}
      {...sectionBlockProps}>
      <NavBar />

      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <Typography variant='h2' color='primary' className={classes.title}>
            <MarkText
              activeAnimation={activeAnimation}
              className={classes.titleSpan}
              endAnimationAction={endAnimationAction}>
              CRONOMETRÁ
            </MarkText>
            &nbsp;TUS EVENTOS
          </Typography>
          <Typography variant='h3' color='primary' className={classes.subTitle}>
            Sportmetric te brinda la posibilidad de integrar sus funcionalidades con nuestro sistema
            de cronometraje, agilizando la gestión de tu evento.
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.desktopImage} />
          <div className={classes.cronosContainer}>
            <CronosIcon />
            <div className={classes.cronoInfo}>
              <Typography variant='h6' className={classes.cronoTitle}>
                sistema de cronometraje
              </Typography>
              <Typography className={classes.cronoDescription}>
                Crono es una herramienta que permite cronometrar el evento deportivo.
                <br />
                Brinda información en tiempo real de las posiciones, minimizando errores en la
                clasificación final.
                <br />
                Contamos con licencias para cronometristas, y brindamos también el servicio de
                cronometraje.
              </Typography>
              <Link
                target='_blank'
                rel='noopener noreferrer'
                to={ROUTES.LANDING.TIMING}
                className={classes.timingLink}>
                +info
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SectionBlock>
  )
}

export default EventTimingSection
