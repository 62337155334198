import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(6),
    margin: theme.spacing(2)
  },
  alertButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(4)
  },
  unbindNotifyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(6),
    margin: theme.spacing(0, 2),
    marginBottom: theme.spacing(2)
  },
  button: {
    padding: theme.spacing(1, 9)
  },
  text: {
    fontWeight: 600
  },
  list: {
    marginBlock: 0,
    fontWeight: 700
  }
}))
