import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Checkbox, CircularProgress, IconButton, Typography } from '@material-ui/core'
import {
  DeleteOutline as DeleteOutlineIcon,
  EditOutlined as EditOutlinedIcon
} from '@material-ui/icons'
import clsx from 'clsx'
import { Formik } from 'formik'
import {
  DeletePaymentLocationDialog,
  EditPaymentLocationDialog,
  PaymentNotifyDialog
} from 'pages/Events/InscriptionPage/components'
import { CustomFormGroup } from 'shared'
import { deletePaymentLocation, loadPaymentLocations } from 'state/modules/events'
import { showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import { useStyles } from './PaymentLocationCard.style'

const CARD_STATES = {
  CARD_VIEW: 'cardView',
  CARD_FORM: 'cardForm'
}

const FORM_TYPE = {
  FORM_CREATE: 'formCreate',
  FORM_UPDATE: 'formUpdate'
}

const DIALOG_STATES = {
  PAYMENT_INFO_DIALOG: 'paymentInfoDialog'
}

const paymentNotifyInitalState = {
  open: false,
  state: DIALOG_STATES.PAYMENT_INFO_DIALOG,
  onConfirm: () => {}
}

const TYPE_ACTION = 'update'

const PaymentLocationCard = ({
  title,
  cardState = CARD_STATES.CARD_VIEW,
  paymentLocation,
  showActionbuttons,
  showCheckActive,
  saveChange,
  onSubmit,
  onCloseOfflinePaymentLocationConfig = () => {},
  onOpenCloseOffPaymentConfig = () => {},
  formType = FORM_TYPE.FORM_CREATE,
  values = {},
  setFieldValue = () => {}
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { paymentLocations, event } = useSelector((state) => state.events)

  const { name, address, description, isActive } = paymentLocation

  const [openEditPaymentLocation, setOpenEditPaymentLocation] = useState(false)
  const [openDeletePaymentLocation, setOpenDeletePaymentLocation] = useState(false)
  const [paymentNotifyProps, setPaymentNotifyProps] = useState(paymentNotifyInitalState)
  const [updatePaymentLocationPending, setUpdatePaymentLocationPending] = useState(false)

  const paymentLocationContent = () => {
    return (
      <main className={classes.paymentLocationContent}>
        <Typography
          className={clsx(classes.offLineText, classes.ellipsisText, {
            [classes.offLineTextDisabled]: !isActive
          })}
          title={name}>
          {name}
        </Typography>
        <Typography
          className={clsx(classes.offLineText, classes.ellipsisText, {
            [classes.offLineTextDisabled]: !isActive
          })}
          title={address}>
          {address}
        </Typography>
        <Typography
          className={clsx(classes.offLineText, classes.ellipsisText, {
            [classes.offLineTextDisabled]: !isActive
          })}
          title={description}>
          {description}
        </Typography>
      </main>
    )
  }

  const handleChangeStatePayment = async (e) => {
    const checkValue = e.target.checked
    if (!checkValue) {
      const hasErrors = handlePaymentLocationValidate()
      if (hasErrors) return
    }

    handleSaveState(checkValue)
  }

  const handlePaymentLocationValidate = () => {
    const hasActivePaymentLocation = paymentLocations.filter((x) => x.isActive).length === 1

    const hasPaymentLocationTickets = values.ticketTypes.some(
      (x) =>
        x.paymentLocationsEnabled &&
        !x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentUrl
    )

    if (!hasPaymentLocationTickets || !hasActivePaymentLocation || !paymentLocation.isActive) return

    setPaymentNotifyProps({
      open: true,
      state: DIALOG_STATES.PAYMENT_INFO_DIALOG,
      pending: true
    })

    return true
  }

  const handleSaveState = async (isActive) => {
    setUpdatePaymentLocationPending(true)
    if (saveChange) await onSubmit({ ...paymentLocation, isActive, typeAction: TYPE_ACTION })

    event.eventPaymentLocationsEnabled =
      isActive || paymentLocations.filter((x) => x.isActive).length > 1
    paymentLocation.isActive = isActive

    await dispatch(loadPaymentLocations(event.id))

    const hasActivePaymentLocation = paymentLocations.filter((x) => x.isActive).length === 0

    const hasPaymentLocationTickets = values.ticketTypes.some(
      (x) =>
        x.paymentLocationsEnabled &&
        !x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentUrl
    )

    if (!hasPaymentLocationTickets && hasActivePaymentLocation) {
      setFieldValue(
        'ticketTypes',
        values.ticketTypes.map((x) => ({ ...x, paymentLocationsEnabled: false }))
      )
    }
    setUpdatePaymentLocationPending(false)
  }

  const onOpenDeletePaymentLocation = () => {
    const hasErrors = handlePaymentLocationValidate()
    if (hasErrors) return

    handleOpenDeletePaymentLocation()
  }

  const onDeletePaymentLocation = async () => {
    const deleteSuccess = await dispatch(deletePaymentLocation(event.id, paymentLocation.id))

    if (!deleteSuccess) return

    await dispatch(loadPaymentLocations(event.id))

    event.eventPaymentLocationsEnabled = paymentLocations.filter((x) => x.isActive).length > 1

    const hasActivePaymentLocation = paymentLocations.filter((x) => x.isActive).length === 1

    const hasPaymentLocationTickets = values.ticketTypes.some(
      (x) =>
        x.paymentLocationsEnabled &&
        !x.bankAccountsEnabled &&
        !x.mercadoPagoEnabled &&
        !x.paymentUrl
    )

    if (!hasPaymentLocationTickets && hasActivePaymentLocation) {
      setFieldValue(
        'ticketTypes',
        values.ticketTypes.map((x) => ({ ...x, paymentLocationsEnabled: false }))
      )
    }

    setFieldValue('paymentMethod', false)
    showSnackbarSuccess('¡El punto físico fue eliminado con éxito!')

    onOpenCloseOffPaymentConfig()
    setOpenDeletePaymentLocation(false)
  }

  const handleOpenDeletePaymentLocation = () => {
    onCloseOfflinePaymentLocationConfig()
    setOpenDeletePaymentLocation(true)
    setPaymentNotifyProps((state) => ({ ...state, ...paymentNotifyInitalState }))
  }

  const paymentLocationForm = () => {
    const paymentLocationValidate = Yup.object().shape({
      name: Yup.string()
        .nullable()
        .required('Debe ingresar el nombre')
        .trim()
        .max(100, 'El nombre tiene un máximo de 100 caracteres'),
      address: Yup.string()
        .nullable()
        .required('Debe ingresar la dirección')
        .trim()
        .max(100, 'La dirección tiene un máximo de 100 caracteres'),
      description: Yup.string().nullable().trim().max(256, 'Se puede ingresar hasta 256 caracteres')
    })

    return (
      <Formik
        enableReinitialize
        initialValues={paymentLocation}
        onSubmit={onSubmit}
        validationSchema={paymentLocationValidate}>
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit} className={classes.cardForm}>
            <div className={classes.formGroup}>
              <CustomFormGroup
                label='Nombre*'
                value={values.name}
                variant='outlined'
                autoComplete='off'
                placeholder='Nombre*'
                name='name'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
              />
              {errors.name && (
                <Typography variant='caption' color='error' className={classes.errorMessage}>
                  {errors.name}
                </Typography>
              )}
            </div>
            <div className={classes.formGroup}>
              <CustomFormGroup
                label='Dirección*'
                value={values.address}
                variant='outlined'
                autoComplete='off'
                placeholder='Dirección*'
                name='address'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.address && Boolean(errors.address)}
              />
              {errors.address && (
                <Typography variant='caption' color='error' className={classes.errorMessage}>
                  {errors.address}
                </Typography>
              )}
            </div>
            <div className={classes.formGroup}>
              <CustomFormGroup
                label='Información adicional (por ejemplo horario del local)'
                value={values.description}
                variant='outlined'
                autoComplete='off'
                placeholder='Información adicional (por ejemplo horario del local)'
                name='description'
                onBlur={handleBlur}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
              />
              {errors.description && (
                <Typography variant='caption' color='error' className={classes.errorMessage}>
                  {errors.description}
                </Typography>
              )}
            </div>
            {formType === FORM_TYPE.FORM_CREATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting}
                className={classes.submitButton}>
                Aceptar
              </Button>
            )}

            {formType === FORM_TYPE.FORM_UPDATE && (
              <Button
                color='primary'
                variant='contained'
                type='submit'
                endIcon={isSubmitting && <CircularProgress size={15} color='primary' />}
                disabled={!isValid || isSubmitting}
                className={clsx(classes.submitButton, classes.saveButton)}>
                Guardar
              </Button>
            )}
          </form>
        )}
      </Formik>
    )
  }

  return (
    <div className={classes.card}>
      <header className={classes.cardHeader}>
        {showCheckActive && (
          <>
            <Checkbox
              color='primary'
              className={clsx(classes.checkbox, {
                [classes.checkboxDisabled]: !isActive
              })}
              checked={isActive}
              disabled={updatePaymentLocationPending}
              onChange={handleChangeStatePayment}
            />
            <PaymentNotifyDialog
              {...paymentNotifyProps}
              onClose={() => setPaymentNotifyProps(paymentNotifyInitalState)}
            />
          </>
        )}

        <Typography
          color='primary'
          className={clsx(classes.offLineTextTitle, !isActive && 'disabled')}>
          {title}
        </Typography>

        {showActionbuttons && (
          <>
            <IconButton
              color='primary'
              size='small'
              className={clsx(classes.button, {
                [classes.disabled]: !isActive
              })}
              onClick={() => {
                onCloseOfflinePaymentLocationConfig()
                setOpenEditPaymentLocation(true)
              }}>
              <EditOutlinedIcon />
            </IconButton>
            <EditPaymentLocationDialog
              open={openEditPaymentLocation}
              title={title}
              paymentLocation={paymentLocation}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenEditPaymentLocation(false)
              }}
            />
            <IconButton
              color='primary'
              size='small'
              className={clsx(classes.button, {
                [classes.disabled]: !isActive
              })}
              onClick={onOpenDeletePaymentLocation}>
              <DeleteOutlineIcon />
            </IconButton>
            <DeletePaymentLocationDialog
              open={openDeletePaymentLocation}
              paymentLocation={paymentLocation}
              title={title}
              onDelete={onDeletePaymentLocation}
              onClose={() => {
                onOpenCloseOffPaymentConfig()
                setOpenDeletePaymentLocation(false)
                setPaymentNotifyProps({ ...paymentNotifyProps, open: false })
              }}
            />
          </>
        )}
      </header>

      {cardState === CARD_STATES.CARD_VIEW && paymentLocationContent()}

      {cardState === CARD_STATES.CARD_FORM && paymentLocationForm()}
    </div>
  )
}

export default PaymentLocationCard
