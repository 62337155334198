import React, { useState } from 'react'
import Slider from 'react-slick'
import { Radio, Typography } from '@material-ui/core'
import { ReactComponent as ImageMtb } from 'assets/img/landing-mtb.svg'
import { ReactComponent as ImageTrasunga } from 'assets/img/landing-trasyunga.svg'
import { ReactComponent as ImageUrku } from 'assets/img/landing-urku.svg'

import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './OrganizersUsSection.style'

const OrganizersUsSection = ({ activeAnimation, endAnimationAction, ...sectionProps }) => {
  const classes = useStyles()
  const [currentSlideNumber, setCurrentSlideNumber] = useState(0)

  const customPaging = (index) => (
    <Radio
      className={classes.radioButton}
      checked={currentSlideNumber === index}
      value={index}
      name='radio-buttons'
      color='secondary'
    />
  )

  const settings = {
    className: classes.slickSlider,
    dots: true,
    arrows: false,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => setCurrentSlideNumber(next),
    customPaging
  }

  const infoContent = (title, subText, image, id) => (
    <div className={classes.info} key={id}>
      <div>
        <p className={classes.sliderText}>{title}</p>
      </div>
      <Typography className={classes.sliderSubtext}>{subText}</Typography>
      {image}
    </div>
  )

  const array = [
    {
      id: '1',
      texto:
        '“Necesitábamos un canal que gestione las inscripciones online para nuestros eventos y Sportmetric fue la mejor opción, nos brindan un soporte permanente para gestionar nuestros eventos, la posibilidad de hacer cobros online facilita mucho la inscripción a nuestros corredores y nos genera una base de datos muy completa. 100% conforme con el servicio.”',
      subText: 'Alvaro - Urku Trail & Running',
      image: <ImageUrku />
    },
    {
      id: '2',
      texto:
        '“La página es bastante completa, te da muchas opciones para poder armar un formulario de inscripción lo que facilita el registro de los participantes”',
      subText: 'Patricio - Campeonato TOP 5 MTB',
      image: <ImageMtb />
    },
    {
      id: '3',
      texto:
        '“A través de la web de Sportmetric agilizamos todo el proceso referido a la inscripción y categorización de los competidores. En una competencia de la magnitud del Trasyunga, ésta no es tarea menor, por ello seguirán siendo nuestros aliados estratégicos en ediciones venideras”',
      subText: 'Pablo - Trasyunga',
      image: <ImageTrasunga />
    }
  ]

  return (
    <SectionBlock
      containerClassName={classes.container}
      buttonClassName={classes.endButton}
      nextSectionId='createMultidisciplinarySection'
      {...sectionProps}>
      <NavBar />
      <div className={classes.content}>
        <div className={classes.headerContainer}>
          <Typography color='primary' className={classes.title}>
            ORGANIZADORES QUE{' '}
            <MarkText
              activeAnimation={activeAnimation}
              className={classes.trustUs}
              endAnimationAction={endAnimationAction}>
              CONFÍAN EN NOSOTROS
            </MarkText>
          </Typography>
        </div>
        <div className={classes.infoContainer}>
          <Slider {...settings}>
            {array.map((info) => infoContent(info.texto, info.subText, info.image, info.id))}
          </Slider>
        </div>
      </div>
    </SectionBlock>
  )
}

export default OrganizersUsSection
