import React from 'react'
import { Snackbar } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { Transition } from 'utils'

import { useStyles } from './SuccessMessage.style'

const SuccessMessage = ({ message, open, onClose, className }) => {
  const classes = useStyles()

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      className={clsx(classes.snackbar, className)}
      autoHideDuration={3000}
      TransitionComponent={Transition}>
      <div className={classes.container}>
        <div className={classes.mainContainer}>
          <CheckCircleIcon color='primary' className={classes.iconSuccess} />
          {message}
        </div>
      </div>
    </Snackbar>
  )
}

export default SuccessMessage
