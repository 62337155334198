import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress } from '@material-ui/core'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, HeaderBox, MainLayout, NavBar, PageContainer } from 'shared'
import { EventCard } from 'shared/EventCard'
import { loadLastResultAll, resetLastResultAll } from 'state/modules/events'

import { useStyles } from './LatestResultEventsPage.style'

const LatestResultEventsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { lastResultEventsAll, lastResultAllLastPage, lastResultEventsAllPending } = useSelector(
    (state) => state.events
  )
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(loadLastResultAll(page))
  }, [dispatch, page])

  useEffect(() => {
    return () => {
      dispatch(resetLastResultAll())
    }
  }, [dispatch])

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer>
        <div className={classes.mainContainer}>
          <HeaderBox titles={['Últimos Resultados']} />
          <div className={classes.bodyContainer}>
            {!!lastResultEventsAll.length && (
              <InfiniteScroll
                dataLength={lastResultEventsAll.length}
                next={() => {
                  setPage((state) => state + 1)
                }}
                hasMore={!lastResultAllLastPage}
                className={classes.eventsContainer}>
                {lastResultEventsAll.map((event) => (
                  <div className={classes.cardContainer} key={event.id}>
                    <EventCard event={event} />
                  </div>
                ))}
              </InfiniteScroll>
            )}

            {lastResultEventsAllPending && (
              <div className={classes.containerLoader}>
                <CircularProgress size={40} color='secondary' />
              </div>
            )}
            <div className={classes.endContainer}>
              {!lastResultEventsAllPending && !lastResultEventsAll.length && (
                <p>No hay eventos próximos</p>
              )}
              <div className={classes.buttonsContainer}>
                {!lastResultEventsAllPending && !lastResultAllLastPage && (
                  <div className={classes.buttonContainer}>
                    <Button
                      color='primary'
                      variant='contained'
                      className={classes.button}
                      onClick={() => {
                        setPage((state) => state + 1)
                      }}>
                      Ver más
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default LatestResultEventsPage
