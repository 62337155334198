import React from 'react'
import { useMediaQuery } from '@material-ui/core'

import { EventResults } from '../EventResults'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ResultsSection.style'

const ResultsSection = ({ event, hasEventResults }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const classes = useStyles()

  return (
    <SectionBlock title={!isMobile && 'Resultados'} className={classes.section}>
      {hasEventResults && <EventResults event={event} />}
    </SectionBlock>
  )
}

export default ResultsSection
