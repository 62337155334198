import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  containerFollowed: {
    display: 'flex',
    flexDirection: 'row',
    textDecoration: 'none',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  image: {
    width: 62,
    height: 62,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    border: '1px solid #C8C8C8',
    flexShrink: 0
  },
  nameContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2)
  },
  name: {
    fontSize: '1.125em',
    fontWeight: 600
  },
  followedTypeText: {
    color: theme.palette.grey[500],
    fontSize: '.9rem'
  }
}))
