import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  select: {
    marginTop: theme.spacing(),
    color: theme.palette.primary.main,
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    '& fieldset': {
      borderColor: theme.palette.secondary.main
    }
  },
  selectItem: {
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  table: {
    marginTop: theme.spacing(2),
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    color: theme.palette.primary.main,
    '& th': {
      textAlign: 'left',
      padding: theme.spacing(0.75)
    },
    '& td': {
      textAlign: 'left',
      padding: theme.spacing(0.75),
      borderBottom: '1px solid #E6E6E6'
    },
    '& table': {
      width: '100%'
    },
    '& table th': {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  expandButtonCol: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  runnerNumberCol: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'table-cell'
    }
  },
  cityNameCol: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'table-cell'
    }
  },
  innerTableCell: {
    backgroundColor: '#E6E6E6',
    padding: 0,
    '& td': {
      borderBottom: 'none'
    }
  },
  expandButton: {
    padding: 0
  }
}))

export default useStyles
