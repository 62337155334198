import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 310
  },
  card: {
    width: 290,
    height: 310
  },
  containerVertical: {
    width: '100%',
    height: 1000,
    display: 'flex'
  },
  cardWide: {
    width: '100%',
    height: 240
  }
}))
