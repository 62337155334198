import { makeStyles } from '@material-ui/styles'
import sliderNext from 'assets/img/slider-next.png'
import sliderPrev from 'assets/img/slider-prev.png'

export const useStyles = makeStyles((theme) => ({
  prev: {
    position: 'absolute',
    top: 148,
    left: -27,
    cursor: 'pointer',
    width: 17,
    height: 32,
    backgroundImage: `url(${sliderPrev})`
  },
  next: {
    position: 'absolute',
    top: 148,
    right: -27,
    cursor: 'pointer',
    width: 17,
    height: 32,
    backgroundImage: `url(${sliderNext})`
  }
}))

export default useStyles
