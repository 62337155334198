import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  bottonIcon: {
    '& svg': {
      width: 20
    }
  },
  editMode: {
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    '& input': {
      height: 19,
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      },
      '&:disabled': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: `${theme.palette.primary.main} !important`
    },
    '&.disabled': {
      '& fieldset': {
        borderColor: '#696969 !important'
      }
    }
  },
  checkLabel: {
    fontSize: '.875rem'
  }
}))
