import React from 'react'
import clsx from 'clsx'

import { useStyles } from './EllipseStatus.style'

const EllipseStatus = ({ status = 'yellow', className, title }) => {
  const classes = useStyles()

  return <div className={clsx(classes.ellipsis, classes[status], className)} title={title} />
}

export default EllipseStatus
