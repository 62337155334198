import * as types from './types'

// InitialState
const initialState = {
  user: null,
  signUpData: null,
  pending: false,

  userData: null,
  userDataPending: false,
  organizations: [],
  organizationsPending: false,

  error: null,
  signInError: null,
  signUpError: null,

  manualActivationPending: false,
  manualActivationError: null
}

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_IN_ATTEMPT:
      return {
        ...state,
        user: null,
        error: null,
        signInError: null,
        pending: true
      }
    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.user,
        pending: false
      }
    case types.SIGN_IN_FAILURE:
      return {
        ...state,
        user: null,
        pending: false,
        signInError: action.error
      }
    case types.CLOSE_SESSION:
      return {
        ...state,
        user: null,
        userData: null,
        pending: false,
        error: null
      }
    case types.RESTORE_SESSION:
      return {
        ...state,
        user: action.user
      }
    case types.SIGN_UP_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        pending: false
      }
    case types.SIGN_UP_FAILURE:
      return {
        ...state,
        pending: false,
        signUpError: action.error
      }
    case types.SET_SIGN_UP_DATA:
      return {
        ...state,
        signUpData: action.signUpData
      }
    case types.RECOVER_ACCOUNT_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.RECOVER_ACCOUNT_SUCCESS:
      return {
        ...state,
        pending: false
      }
    case types.RECOVER_ACCOUNT_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.LOAD_WELCOME_USER_ATTEMPT:
      return {
        ...state,
        userData: null,
        error: null,
        pending: true
      }
    case types.LOAD_WELCOME_USER_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        pending: false
      }
    case types.LOAD_WELCOME_USER_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.RESET_PASSWORD_ATTEMPT:
      return {
        ...state,
        error: null,
        pending: true
      }
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false
      }
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error
      }
    case types.LOAD_ORGANIZATIONS_ATTEMPT:
      return {
        ...state,
        organizationsPending: true,
        organizations: [],
        error: null
      }
    case types.LOAD_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizationsPending: false,
        organizations: action.organizations,
        error: null
      }
    case types.LOAD_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        organizationsPending: false,
        error: action.error
      }
    case types.LOAD_USER_DATA_ATTEMPT:
      return {
        ...state,
        userDataPending: true
      }
    case types.LOAD_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        userDataPending: false
      }
    case types.LOAD_USER_DATA_FAILURE:
      return {
        ...state,
        error: action.error,
        userDataPending: false
      }
    case types.MANUAL_ACTIVATION_ATTEMPT:
      return {
        ...state,
        manualActivationError: null,
        manualActivationPending: true
      }
    case types.MANUAL_ACTIVATION_SUCCESS:
      return {
        ...state,
        manualActivationPending: false,
        signInError: null
      }
    case types.MANUAL_ACTIVATION_FAILURE:
      return {
        ...state,
        manualActivationError: action.error,
        manualActivationPending: false
      }

    default:
      return state
  }
}
