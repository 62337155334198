import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 670,
      maxWidth: 670
    }
  },
  formContainer: {
    width: '85%'
  },
  subTitle: {
    fontWeight: 600
  },
  deleteDialogBody: {
    marginBottom: theme.spacing(3),
    '& h6': {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(4)
      }
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    }
  },
  buttonContainer: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(4),
    justifyContent: 'space-between'
  },
  actionButton: {
    alignSelf: 'flex-end',
    width: '38%',
    minWidth: 150
  }
}))
