import React from 'react'
import { Media } from 'react-breakpoints'
import ContentLoader from 'react-content-loader'

import { useStyle } from './SectionResultsPlaceholderLoader.style'

const SectionResultsPlaceholderLoader = () => {
  const classes = useStyle()
  const bgColor = '#ffffff'
  const fgColor = '#eeeeee'
  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) =>
        breakpoints[currentBreakpoint] < breakpoints.md ? (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <rect x='10' y='40' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='40' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='63' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='80' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='97' rx='3' ry='3' className={classes.body} />
            <rect x='10' y='127' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='127' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='147' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='164' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='181' rx='3' ry='3' className={classes.body} />
            <rect x='10' y='211' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='211' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='234' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='251' rx='3' ry='3' className={classes.body} />
            <rect x='110' y='268' rx='3' ry='3' className={classes.body} />
          </ContentLoader>
        ) : (
          <ContentLoader
            backgroundColor={bgColor}
            foregroundColor={fgColor}
            className={classes.container}>
            <rect x='0' y='5' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='10' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='40' rx='3' ry='3' className={classes.body} />
            <rect x='670' y='35' rx='3' ry='3' width={100} height={10} />

            <rect x='0' y='60' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='65' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='95' rx='3' ry='3' className={classes.body} />
            <rect x='670' y='90' rx='3' ry='3' width={100} height={10} />

            <rect x='0' y='120' rx='3' ry='3' className={classes.position} />
            <rect x='110' y='125' rx='3' ry='3' className={classes.date} />
            <rect x='110' y='155' rx='3' ry='3' className={classes.body} />
            <rect x='670' y='150' rx='3' ry='3' width={100} height={10} />
          </ContentLoader>
        )
      }
    </Media>
  )
}

export default SectionResultsPlaceholderLoader
