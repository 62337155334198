import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  card: {
    textDecoration: 'none',
    backgroundColor: '#ffffff',
    width: 280,
    display: 'inline-block',
    margin: theme.spacing(2)
  },
  headContainer: {
    height: 160,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
    textAlign: 'left'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.125em',
    fontWeight: 600,
    flexGrow: 1,
    textAlign: 'left',
    width: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  dateContainer: {
    fontSize: '1em',
    color: theme.palette.primary.main,
    textAlign: 'left'
  },
  sport: {
    fontSize: '1em',
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  imageContainer: {
    height: 160,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    backgroundPosition: 'center'
  }
}))

export default useStyles
