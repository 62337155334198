import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { ROUTES } from 'routes'
import {
  setActiveRole,
  verifyUserLogged,
  verifyUserNotLogged,
  verifyUserRole
} from 'state/modules/app'
import { verifyExpiredToken, verifyToken } from 'state/modules/auth'
import { showSnackbarError } from 'utils/snackbar'

const Authenticated = ({ children, verifySession, verifyRole, role = {} }) => {
  const { user, userData } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (verifySession && !user) {
      const isAuthenticated = dispatch(verifyToken())
      if (!isAuthenticated) {
        const verifyParams = { state: true, redirectUrl: location.pathname || ROUTES.ROOT }
        dispatch(verifyUserNotLogged(verifyParams))
      }
    }
  }, [dispatch, location, user, verifySession])

  useEffect(() => {
    if (verifyRole && user && userData) {
      const isActiveRole = userData[`is${role.value}`]

      if (!isActiveRole) {
        const verifyParams = {
          state: true,
          redirectUrl: location.pathname || ROUTES.ROOT,
          role
        }

        dispatch(verifyUserRole(verifyParams))
      }
    }
  }, [verifyRole, user, userData])

  useEffect(() => {
    if (user) {
      const isExpiredToken = dispatch(verifyExpiredToken(user))
      if (isExpiredToken) {
        showSnackbarError({
          message: 'Tu sesión ha expirado, vuelve a iniciar sesión para continuar.'
        })

        dispatch(setActiveRole(null))
        dispatch(verifyUserLogged({ state: true }))
      }
    }
  }, [children, history, dispatch, user])

  return <>{children}</>
}

export default Authenticated
