import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    textDecoration: 'none',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      minHeight: 200,
      flexDirection: 'row',
      paddingBottom: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  },
  simpleContainer: {
    minHeight: 'auto'
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    flexShrink: 0,
    height: 200,
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 280,
      height: 160
    }
  },
  bodyContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  simpleBodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& h2': {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(3)
      }
    }
  },
  bodyHeader: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  date: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-start',
    fontSize: '1.25em',
    fontWeight: 500,
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    minWidth: theme.spacing(16),
    '&.suspended': {
      backgroundColor: theme.palette.suspended.light,
      color: theme.palette.background.default
    }
  },
  sportsContainer: {
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginLeft: theme.spacing(4)
    }
  },
  sport: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginRight: theme.spacing(2),
    '&.suspended': {
      color: '#c3c3c3'
    },
    '&.add': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.5em',
    fontWeight: 600,
    '&.suspended': {
      color: '#c3c3c3'
    }
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '&.suspended': {
      color: '#c3c3c3'
    }
  },
  organizer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(),
    textTransform: 'uppercase',
    fontSize: '.9em',
    fontWeight: 500,
    color: '#969696',
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2)
    },
    '&.suspended': {
      color: '#c3c3c3'
    }
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginTop: theme.spacing(0)
    }
  },
  iconButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  actionButtonsContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column'
    }
  },
  actionIconButton: {
    [theme.breakpoints.up('lg')]: {
      padding: '.4rem'
    },
    '&.suspended': {
      color: '#c3c3c3'
    }
  },
  actionButton: {
    textTransform: 'none',
    '&.suspended': {
      borderColor: '#c3c3c3',
      color: '#c3c3c3'
    }
  },
  containerSuspended: {
    backgroundColor: theme.palette.background.default,
    opacity: 0.75,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  textSuspended: {
    margin: 30,
    color: theme.palette.suspended.dark,
    fontWeight: 800,
    textAlign: 'center'
  }
}))

export default useStyles
