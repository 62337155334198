import UserAccountsProxy from 'services/UserAccountsProxy'
import UserProfilesProxy from 'services/UserProfilesProxy'

import * as types from './types'

export const resetSearch = () => ({
  type: types.RESET_SEARCH
})
export const setSearchParams = (searchParams) => ({
  type: types.SET_SEARCH_PARAMS,
  searchParams
})
export const searchAttempt = () => ({
  type: types.SEARCH_ATTEMPT
})
export const searchSuccess = (userAccountsPage) => ({
  type: types.SEARCH_SUCCESS,
  userAccountsPage
})

export const searchFailure = (error) => ({
  type: types.SEARCH_FAILURE,
  error
})

export const searchMetadataAttempt = () => ({
  type: types.SEARCH_METADATA_ATTEMPT
})

export const searchMetadataSuccess = (searchMetadata) => ({
  type: types.SEARCH_METADATA_SUCCESS,
  searchMetadata
})

export const searchMetadataFailure = (error) => ({
  type: types.SEARCH_METADATA_FAILURE,
  error
})
export const resetData = () => ({
  type: types.RESET_DATA
})
export const loadAttempt = () => ({
  type: types.LOAD_ATTEMPT
})
export const loadSuccess = (userAccount) => ({
  type: types.LOAD_SUCCESS,
  userAccount
})
export const loadFailure = (error) => ({
  type: types.LOAD_FAILURE,
  error
})
export const loadResultsAttempt = () => ({
  type: types.LOAD_RESULTS_ATTEMPT
})
export const loadResultsSuccess = (results) => ({
  type: types.LOAD_RESULTS_SUCCESS,
  results
})
export const loadResultsFailure = (error) => ({
  type: types.LOAD_RESULTS_FAILURE,
  error
})
export const loadOrganizationsAttempt = () => ({
  type: types.LOAD_ORGANIZATIONS_ATTEMPT
})
export const loadOrganizationsSuccess = (organizations) => ({
  type: types.LOAD_ORGANIZATIONS_SUCCESS,
  organizations
})
export const loadOrganizationsFailure = (error) => ({
  type: types.LOAD_ORGANIZATIONS_FAILURE,
  error
})
export const updateAttempt = () => ({
  type: types.UPDATE_ATTEMPT
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  error
})

export const updateSuccess = (userAccount) => ({
  type: types.UPDATE_SUCCESS,
  userAccount
})
export const setImageAttempt = () => ({
  type: types.SET_IMAGE_ATTEMPT
})

export const setImageSuccess = (userAccount) => ({
  type: types.SET_IMAGE_SUCCESS,
  userAccount
})

export const setImageFailure = (error) => ({
  type: types.SET_IMAGE_FAILURE,
  error
})
export const loadReset = () => ({
  type: types.LOAD_RESET
})
export const loadCalendarAttempt = () => ({
  type: types.LOAD_CALENDAR_ATTEMPT
})
export const loadCalendarFailure = (error) => ({
  type: types.LOAD_CALENDAR_FAILURE,
  error
})
export const loadCalendarSuccess = (calendarEvents) => ({
  type: types.LOAD_CALENDAR_SUCCESS,
  calendarEvents
})
export const followAttempt = () => ({
  type: types.FOLLOW_ATTEMPT
})
export const followSuccess = (userAccount) => ({
  type: types.FOLLOW_SUCCESS,
  userAccount
})
export const followFailure = (error) => ({
  type: types.FOLLOW_FAILURE,
  error
})
export const loadFollowedUserAccountsAttempt = () => ({
  type: types.LOAD_FOLLOWED_USER_ACCOUNTS_ATTEMPT
})
export const loadFollowedUserAccountsSuccess = (followedUserAccounts) => ({
  type: types.LOAD_FOLLOWED_USER_ACCOUNTS_SUCCESS,
  followedUserAccounts
})
export const loadFollowedUserAccountsFailure = (error) => ({
  type: types.LOAD_FOLLOWED_USER_ACCOUNTS_FAILURE,
  error
})
export const loadFollowedOrganizationsAttempt = () => ({
  type: types.LOAD_FOLLOWED_ORGANIZATIONS_ATTEMPT
})
export const loadFollowedOrganizationsSuccess = (followedOrganizations) => ({
  type: types.LOAD_FOLLOWED_ORGANIZATIONS_SUCCESS,
  followedOrganizations
})
export const loadFollowedOrganizationsFailure = (error) => ({
  type: types.LOAD_FOLLOWED_ORGANIZATIONS_FAILURE,
  error
})
export const loadOrganizationsAdminAllAttempt = () => ({
  type: types.LOAD_ORGANIZATIONS_ADMIN_ALL_ATTEMPT
})
export const loadOrganizationsAdminAllSuccess = (organizationsAdmin) => ({
  type: types.LOAD_ORGANIZATIONS_ADMIN_ALL_SUCCESS,
  organizationsAdmin
})
export const loadOrganizationsAdminAllFailure = (error) => ({
  type: types.LOAD_ORGANIZATIONS_ADMIN_ALL_FAILURE,
  error
})
export const deleteImageAttempt = () => ({
  type: types.DELETE_IMAGE_ATTEMPT
})
export const deleteImageSuccess = (userAccount) => ({
  type: types.DELETE_IMAGE_SUCCESS,
  userAccount
})
export const deleteImageFailure = (error) => ({
  type: types.DELETE_IMAGE_FAILURE,
  error
})
export const resetSearchParams = () => ({
  type: types.RESET_SEARCH_PARAMS
})

export const loadUpcomingEventsManagedAttempt = () => ({
  type: types.LOAD_UPCOMING_EVENT_MANAGED_BY_ATTEMPT
})
export const loadUpcomingEventsManagedSuccess = (upcomingEventsManaged) => ({
  type: types.LOAD_UPCOMING_EVENT_MANAGED_BY_SUCCESS,
  upcomingEventsManaged
})
export const loadUpcomingEventsManagedFailure = (error) => ({
  type: types.LOAD_UPCOMING_EVENT_MANAGED_BY_FAILURE,
  error
})

export const loadUpcomingEventsByMyOrganizationAttempt = () => ({
  type: types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_ATTEMPT
})
export const loadUpcomingEventsByMyOrganizationSuccess = (upcomingEventsByMyOrganization) => ({
  type: types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_SUCCESS,
  upcomingEventsByMyOrganization
})
export const loadUpcomingEventsByMyOrganizationFailure = (error) => ({
  type: types.LOAD_UPCOMING_EVENTS_BY_MY_ORGANIZATION_FAILURE,
  error
})

export const loadPasteventsByMyOrganizationAttempt = () => ({
  type: types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_ATTEMPT
})
export const loadPasteventsByMyOrganizationSuccess = (pastEventsByMyOrganization) => ({
  type: types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_SUCCESS,
  pastEventsByMyOrganization
})
export const loadPastEventsByMyOrganizationFailure = (error) => ({
  type: types.LOAD_PAST_EVENTS_BY_MY_ORGANIZATION_FAILURE,
  error
})

export const load = (id) => async (dispatch) => {
  try {
    dispatch(loadAttempt())

    const proxy = new UserAccountsProxy()
    const userAccount = await proxy.get(id)
    dispatch(loadSuccess(userAccount))
    return true
  } catch (error) {
    dispatch(loadFailure(error))
    return false
  }
}
export const search =
  (searchParams, pageSize = 12, orderBy = 'id', order = 'desc') =>
  async (dispatch, getState) => {
    try {
      const { page } = searchParams
      const { searchUserAccountsPageNumber } = getState().userAccounts

      dispatch(setSearchParams(searchParams))

      if (page !== 1 && page === searchUserAccountsPageNumber) return true

      dispatch(searchAttempt())

      const proxy = new UserAccountsProxy()
      const userAccountsPage = await proxy.getSearch(searchParams, page, pageSize, orderBy, order)

      if (page === 1) {
        dispatch(resetSearch())
      }

      dispatch(searchSuccess(userAccountsPage))
      return true
    } catch (error) {
      dispatch(searchFailure(error))
      return false
    }
  }

export const loadSearchMetadata = (searchParams) => async (dispatch) => {
  try {
    dispatch(searchMetadataAttempt())
    const proxy = new UserAccountsProxy()
    const searchMetadata = await proxy.getSearchMetadata(searchParams)
    dispatch(searchMetadataSuccess(searchMetadata))
  } catch (error) {
    dispatch(searchMetadataFailure(error))
  }
}
export const loadResults = (id) => async (dispatch) => {
  try {
    dispatch(loadResultsAttempt())
    const proxy = new UserAccountsProxy()
    const data = await proxy.getResults(id)
    const { results } = data
    const positionResultsOnly = results.filter((result) => !!result.position)
    dispatch(loadResultsSuccess(positionResultsOnly))
  } catch (error) {
    dispatch(loadResultsFailure(error))
  }
}
export const loadOrganizations = (id) => async (dispatch) => {
  try {
    dispatch(loadOrganizationsAttempt())
    const proxy = new UserAccountsProxy()
    const data = await proxy.getOrganizations(id)
    const { results } = data
    dispatch(loadOrganizationsSuccess(results))
  } catch (error) {
    dispatch(loadOrganizationsFailure(error))
  }
}
export const updateUserAccount = (userAccount) => async (dispatch) => {
  try {
    const { id } = userAccount
    dispatch(updateAttempt())
    const proxy = new UserAccountsProxy()
    const data = await proxy.put(id, userAccount)
    dispatch(updateSuccess(data))
    return true
  } catch (error) {
    dispatch(updateFailure(error))
  }
}

export const setImage = (id, file, type) => async (dispatch) => {
  try {
    dispatch(setImageAttempt())

    const proxy = new UserAccountsProxy()
    const userAccount = await proxy.putImage(id, file, type)

    dispatch(setImageSuccess(userAccount))
    return true
  } catch (error) {
    dispatch(setImageFailure(error))
    return false
  }
}

export const loadCalendar = (id) => async (dispatch) => {
  try {
    dispatch(loadCalendarAttempt())
    const proxy = new UserAccountsProxy()
    const { results } = await proxy.getCalendarEvents(id)
    dispatch(loadCalendarSuccess(results))
    return true
  } catch (error) {
    dispatch(loadCalendarFailure(error))
    return false
  }
}

export const follow = (userAccount, noSave) => async (dispatch) => {
  try {
    dispatch(followAttempt())
    const {
      id,
      loggedUserMetadata: { isFollowing }
    } = userAccount

    const proxy = new UserAccountsProxy()
    if (!isFollowing) {
      await proxy.putFollow(id)
      userAccount.followersCount++
    } else {
      await proxy.putUnfollow(id)
      userAccount.followersCount--
    }
    userAccount.loggedUserMetadata.isFollowing = !isFollowing
    const updateUserAccount = noSave ? null : userAccount
    dispatch(followSuccess(updateUserAccount))
  } catch (error) {
    dispatch(followFailure(error))
  }
}

export const loadFollowedUserAccounts = (userAccountId) => async (dispatch) => {
  try {
    dispatch(loadFollowedUserAccountsAttempt())
    const proxy = new UserAccountsProxy()
    const { results } = await proxy.getFollowedUserAccounts(userAccountId)
    dispatch(loadFollowedUserAccountsSuccess(results))
  } catch (error) {
    dispatch(loadFollowedUserAccountsFailure(error))
  }
}

export const loadFollowedOrganizations = (userAccountId) => async (dispatch) => {
  try {
    dispatch(loadFollowedOrganizationsAttempt())
    const proxy = new UserAccountsProxy()

    const data = await proxy.getFollowedOrganizations(userAccountId)
    dispatch(loadFollowedOrganizationsSuccess(data.results))
  } catch (error) {
    dispatch(loadFollowedUserAccountsFailure(error))
  }
}

export const loadOrganizationsAdminAll = () => async (dispatch) => {
  try {
    dispatch(loadOrganizationsAdminAllAttempt())
    const proxy = new UserAccountsProxy()
    const data = await proxy.getOrganizationsAdmin()
    dispatch(loadOrganizationsAdminAllSuccess(data))
  } catch (error) {
    dispatch(loadOrganizationsAdminAllFailure(error))
  }
}

export const deleteImage = (userAccount, type) => async (dispatch) => {
  try {
    const { id } = userAccount
    dispatch(deleteImageAttempt())
    const proxy = new UserProfilesProxy()
    await proxy.deleteImage(id, type)
    userAccount[`${type}Images`] = null
    dispatch(deleteImageSuccess(userAccount))
  } catch (error) {
    dispatch(deleteImageFailure(error))
  }
}

export const loadUpcomingEventsManaged = (id) => async (dispatch) => {
  try {
    dispatch(loadUpcomingEventsManagedAttempt())

    const proxy = new UserAccountsProxy()

    const data = await proxy.getUpcomingEventManagedBy(id)
    dispatch(loadUpcomingEventsManagedSuccess(data.results))

    return data.results
  } catch (error) {
    dispatch(loadUpcomingEventsManagedFailure(error))
  }
}

export const loadUpcomingEventsByMyOrganization = (organizationId) => async (dispatch) => {
  try {
    dispatch(loadUpcomingEventsByMyOrganizationAttempt())

    const proxy = new UserAccountsProxy()
    const data = await proxy.getUpcomingEventsByMyOrganization(organizationId)

    dispatch(loadUpcomingEventsByMyOrganizationSuccess(data.results))
    return data
  } catch (error) {
    dispatch(loadUpcomingEventsByMyOrganizationFailure(error))
  }
}

export const loadPasteventsByMyOrganization = (organizationId) => async (dispatch) => {
  try {
    dispatch(loadPasteventsByMyOrganizationAttempt())

    const proxy = new UserAccountsProxy()
    const data = await proxy.getPastEventsByMyOrganization(organizationId)

    dispatch(loadPasteventsByMyOrganizationSuccess(data.results))
    return data
  } catch (error) {
    dispatch(loadPastEventsByMyOrganizationFailure(error))
  }
}
