import React from 'react'
import { useSelector } from 'react-redux'
import { IconButton, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import moment from 'moment'
import { CustomizedTooltip } from 'shared'
import { DATE_FORMAT } from 'utils/constants'

import { ScheduleItem } from '../ScheduleItem'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ScheduleSection.style'

const ScheduleSection = () => {
  const classes = useStyles()

  const { values, errors } = useFormikContext()

  const { event } = useSelector((state) => state.events)

  const fromLocal = moment(values.fromDate)
  const toLocal = moment(values.toDate)

  const scheduleItemCount =
    !errors.toDate && !errors.fromDate && fromLocal.isSameOrBefore(toLocal)
      ? toLocal.add(1, 'day').diff(fromLocal, 'day')
      : 1

  const scheduleDefaultOptions = new Array(scheduleItemCount).fill({
    title: '',
    description: '',
    descriptionText: '',
    scheduleDate: null
  })

  const scheduleOptions = scheduleDefaultOptions.map((s, index) => {
    const scheduleDate = moment(fromLocal.format(DATE_FORMAT)).add(index, 'day')
    const scheduleState = event.schedule.find((x) => moment(x.scheduleDate).isSame(scheduleDate))

    return {
      ...s,
      ...scheduleState,
      title: `Dia ${index + 1} - ${scheduleDate.format('D [de] MMMM')}`,
      scheduleDate
    }
  })

  return (
    <SectionBlock title='Cronograma'>
      <div className={classes.schedule}>
        {!errors.fromDate &&
          !errors.toDate &&
          !!values.fromDate &&
          !!values.toDate &&
          scheduleOptions.map((x, index) => (
            <ScheduleItem key={index} eventId={event.id} scheduleItem={x} index={index} />
          ))}
      </div>
      <div className={classes.anchorContainer}>
        <Typography color='primary' variant='h6' className={classes.link}>
          + Añadir día previo al evento.
        </Typography>

        <CustomizedTooltip
          title='Añádelo, por ejemplo, si la acreditación o la prueba del circuito 
no se realizan en los días en que tiene lugar el evento.'
          position='right'
          className={classes.tooltip}
          disabledClickAway={true}>
          {({ handleTooltip }) => (
            <IconButton onClick={handleTooltip} size='small' className={classes.button}>
              <InfoIcon color='primary' />
            </IconButton>
          )}
        </CustomizedTooltip>
      </div>
    </SectionBlock>
  )
}

export default ScheduleSection
