import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Dialog, DialogContent, Typography, useMediaQuery } from '@material-ui/core'
import { GoogleLogin } from '@react-oauth/google'
import clsx from 'clsx'
import { googleSignIn, manualActivation, signIn } from 'state/modules/auth'
import { Transition } from 'utils'

import { SignInForm } from '../SignInForm'

import { useStyles } from './SignInDialog.style'

const SignInDialog = ({ open, onClose, className, handleOpenSignUpDialog }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { signInError, userData, signUpData } = useSelector((state) => state.auth)
  const { userLogged } = useSelector((state) => state.app)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const handleActivationManual =
    ({ username: usernameOrEmail }) =>
    async () => {
      const activationManualData = await dispatch(manualActivation({ usernameOrEmail }))

      if (activationManualData) onClose()
    }

  const handleSubmit = async ({ username, password }) => {
    const loginSuccess = await dispatch(signIn(username, password))
    if (loginSuccess) {
      onClose()
      if (userLogged?.redirectUrl) {
        history.push(userLogged.redirectUrl)
      }
    }
  }
  const handleGoogleSignIn = async (credentialResponse) => {
    const loginSuccess = await dispatch(googleSignIn(credentialResponse.credential))

    if (loginSuccess) onClose()
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: clsx(classes.dialog, className)
        }
      }}
      fullWidth
      fullScreen={!isDesktop}
      TransitionComponent={Transition}
      className={classes.container}>
      <DialogContent className={classes.content}>
        <div className={classes.signInOptionsContainer}>
          <SignInForm
            welcomeUser={userData}
            onSubmit={handleSubmit}
            onActivationManual={handleActivationManual}
            loginError={signInError && signInError.response && signInError.response.data}
          />
          {!signUpData && (
            <div className={classes.googleLoginContainer}>
              <GoogleLogin onSuccess={handleGoogleSignIn} onError={console.error} />
            </div>
          )}
        </div>
        <div className={classes.signUpOptionsContainer}>
          <Typography color='primary' className={classes.signUpTitle}>
            ¿Eres nuevo en Sportmetric?
          </Typography>
          <Button
            color='primary'
            className={classes.signUpButton}
            variant='outlined'
            onClick={() => handleOpenSignUpDialog()}>
            Registrate
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SignInDialog
