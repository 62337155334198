import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  ellipsis: {
    height: '20px',
    width: '20px',
    borderRadius: '50%'
  },
  yellow: {
    backgroundColor: '#FAFD5C'
  },
  green: {
    backgroundColor: '#29FF59'
  },
  red: {
    backgroundColor: '#FF3629'
  },
  grey: {
    backgroundColor: '#969696'
  }
}))
