import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  text: {
    fontWeight: 500,
    '& strong': {
      fontWeight: 600
    }
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: '750px !important',
      maxWidth: '750px !important'
    }
  }
}))
