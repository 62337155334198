import React from 'react'
import { Button, Typography, useMediaQuery, useTheme } from '@material-ui/core'

import { useStyles } from './OnboardingCover.style'

const OnboardingCover = ({ handleChangeState }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <div className={classes.mainContainer}>
      <div className={classes.WhiteBackground}></div>
      <div className={classes.infoContainer}>
        <Typography className={classes.title}>
          ¡Tu organización ha sido creada exitosamente!
          <br />
          Queremos conocerte un poco más
        </Typography>
        <p className={classes.paragraph}>
          Las siguientes preguntas nos guiarán en este proceso y nos permitirán entender cómo
          podemos ayudarte <br /> a gestionar y potenciar tus eventos.
        </p>
        <Typography className={classes.subtext}>
          {isMobile ? 'En sus marcas, listos...' : '¿Estás preparado?'}
        </Typography>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={handleChangeState}>
          Comencemos
        </Button>
      </div>
    </div>
  )
}

export default OnboardingCover
