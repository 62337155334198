import React from 'react'
import { Typography } from '@material-ui/core'

import { HistoryContainer } from '../HistoryContainer'
import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './UsSection.style'

const UsSection = ({ activeAnimation, endAnimationAction, id, ...sectionBlockProps }) => {
  const classes = useStyles()

  return (
    <SectionBlock
      containerClassName={classes.container}
      buttonClassName={classes.endButton}
      id={id}
      nextSectionId='customFormSection'
      {...sectionBlockProps}>
      <NavBar />
      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <Typography color='primary' className={classes.title}>
            NUESTRA&nbsp;
            <MarkText activeAnimation={activeAnimation} className={classes.missing}>
              MISIÓN
            </MarkText>
            &nbsp;ES
            <br />
            FOMENTAR Y&nbsp;
            <MarkText activeAnimation={activeAnimation} className={classes.profesionalice}>
              PROFESIONALIZAR
            </MarkText>
            <br />
            EL&nbsp;
            <MarkText
              activeAnimation={activeAnimation}
              className={classes.sport}
              endAnimationAction={endAnimationAction}>
              DEPORTE
            </MarkText>
          </Typography>
        </div>
        <div className={classes.content}>
          <HistoryContainer />

          <div className={classes.image} />
        </div>
      </div>
    </SectionBlock>
  )
}

export default UsSection
