import axios from 'axios'

import BaseProxy from './BaseProxy'

export class PaymentGatewayProxy extends BaseProxy {
  async postMercadoPagoPayment(values) {
    const response = await axios.post(`${this._baseUrl}/payment-gateway/mercado-pago`, values)
    return response.data
  }
}

export default PaymentGatewayProxy
