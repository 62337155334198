import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { Button, IconButton, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import noProfileImage from 'assets/img/user-mock.png'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import { loadResults } from 'state/modules/events'

import { EventResultsPlaceholderLoader } from '../EventResultsPlaceholderLoader'

import useStyles from './EventResults.style'

const ALL_OPTIONS = 'All'

const genderList = [
  { value: 'Female', label: 'Femenino' },
  { value: 'Male', label: 'Masculino' },
  { value: 'NonBinary', label: 'No binario' }
]

const initialSelectedValues = {
  circuit: '',
  category: ALL_OPTIONS,
  gender: ALL_OPTIONS,
  searchText: ''
}

const EventResults = ({ event }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { idCircuitCategory } = useParams()
  const { eventResults, eventResultsPending } = useSelector((state) => state.events)

  const [expandedRows, setExpandedRows] = useState({})
  const [resultSearchText, setResultSearchText] = useState('')
  const [selectedValues, setSelectedValues] = useState(initialSelectedValues)

  const resetValues = useCallback(() => {
    if (!eventResults) {
      setSelectedValues(initialSelectedValues)
      return
    }

    const circuitCategory =
      !!idCircuitCategory && eventResults.find((e) => e.circuitCategory.id === idCircuitCategory)

    const selectedCircuitCategory = circuitCategory
      ? {
          ...circuitCategory.circuitCategory,
          gender: circuitCategory.results[0].inscription.gender
        }
      : {
          ...eventResults[0].circuitCategory,
          categoryName: initialSelectedValues.category,
          gender: initialSelectedValues.gender
        }

    setSelectedValues({
      ...selectedValues,
      circuit: selectedCircuitCategory.circuitName,
      category: selectedCircuitCategory.categoryName,
      gender: selectedCircuitCategory.gender
    })
  }, [idCircuitCategory, eventResults])

  useEffect(() => {
    dispatch(loadResults(event.id))
  }, [dispatch, event])

  useEffect(() => {
    resetValues()
  }, [resetValues])

  const renderEventLoader = () => <EventResultsPlaceholderLoader />

  const renderEventResult = () => {
    const getCircuits = () => {
      if (!eventResults) return []

      return [...new Set(eventResults.map((x) => x.circuitCategory.circuitName))]
    }

    const getCategories = () => {
      if (!eventResults) return []

      return eventResults
        .filter((x) => x.circuitCategory.circuitName === selectedValues.circuit)
        .map((x) => ({
          name: x.circuitCategory.categoryName,
          maxLaps: Math.max(...x.results.map((x) => x.lapsCount))
        }))
    }

    const getResults = (selectedCategory) => {
      const { circuit, category } = selectedValues

      return eventResults
        .filter(
          (x) =>
            x.circuitCategory.circuitName === circuit &&
            ((selectedCategory ? selectedCategory === ALL_OPTIONS : category === ALL_OPTIONS) ||
              (selectedCategory
                ? x.circuitCategory.categoryName === selectedCategory
                : x.circuitCategory.categoryName === category))
        )
        .reduce((acc, x) => [...acc, ...x.results], [])
    }

    const getPositions = () => {
      if (eventResults) {
        const { category, gender, searchText } = selectedValues

        const results = getResults()

        const selectedPosition =
          (category !== ALL_OPTIONS && 'position') ||
          (gender !== ALL_OPTIONS && 'overallPositionByGender') ||
          'overallPosition'

        results
          .sort((x, y) => x[selectedPosition] - y[selectedPosition])
          .sort((x, y) => (x[selectedPosition] === 0 || y[selectedPosition] === 0) && -1)

        if (results.length > 0) {
          return results
            .filter((x) => gender === ALL_OPTIONS || x.inscription.gender === gender)
            .filter(
              (x) =>
                !searchText ||
                `${x.inscription.lastName} ${x.inscription.firstName}`
                  .toLowerCase()
                  .includes(searchText.toLocaleLowerCase()) ||
                +searchText === x.inscription.runnerNumber
            )
        }
      }

      return []
    }

    const getRunnerNumber = (x) =>
      x.inscription.runnerId ? (
        <Link
          variant='text'
          color='primary'
          className={classes.linkUser}
          to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${x.inscription.runnerId}`}>
          {x.inscription.runnerNumber}
        </Link>
      ) : (
        x.inscription.runnerNumber
      )

    const renderProfileImage = (x) => {
      const profileImageUrl =
        Array.isArray(x.inscription.runnerProfileImages) &&
        x.inscription.runnerProfileImages.length > 0
          ? x.inscription.runnerProfileImages[0]
          : noProfileImage

      return (
        <div
          className={classes.runnerImage}
          style={{
            backgroundImage: `url(${profileImageUrl})`
          }}
        />
      )
    }

    const getRunnerName = (x) =>
      x.inscription.runnerId ? (
        <Link
          variant='text'
          color='primary'
          className={classes.linkUser}
          to={`${ROUTES.USERS_ACCOUNTS.PROFILE}/${x.inscription.runnerId}`}>
          {renderProfileImage(x)} &nbsp; {`${x.inscription.lastName}, ${x.inscription.firstName}`}
        </Link>
      ) : (
        <Typography
          color='primary'
          className={
            classes.runnerName
          }>{`${x.inscription.lastName}, ${x.inscription.firstName}`}</Typography>
      )

    const handleOnChangeCategory = (e) => {
      const category = e.target.value
      const categoryGender = category !== ALL_OPTIONS ? selectCategoryGender(category) : ALL_OPTIONS

      setSelectedValues({ ...selectedValues, category, gender: categoryGender })
    }

    const selectCategoryGender = (categorySelected) => {
      const results = getResults(categorySelected)

      const genders = genderList.filter((x) =>
        results.some((r) => r.inscription.gender === x.value)
      )

      return genders.length > 1 ? ALL_OPTIONS : genders[0].value
    }

    const showLaps = (result) => {
      if (!categories) return ''

      const circuitCategory = categories.find((x) => x.name === result.circuitCategory.categoryName)

      return `${result.lapsCount}/${circuitCategory.maxLaps}`
    }

    const showPosition = (result) => {
      const { category, gender } = selectedValues

      const selectedPosition =
        (category !== ALL_OPTIONS && 'position') ||
        (gender !== ALL_OPTIONS && 'overallPositionByGender') ||
        'overallPosition'

      if (result[selectedPosition] === 0) return '-'

      return result[selectedPosition]
    }

    const getGenders = () => {
      if (eventResults) {
        const results = getResults()

        return genderList.filter((x) => results.some((r) => r.inscription.gender === x.value))
      }

      return []
    }

    const handleSearchText = (e) => {
      e.preventDefault()
      setSelectedValues({ ...selectedValues, searchText: resultSearchText })
    }

    const circuits = getCircuits()
    const categories = getCategories()
    const genders = getGenders()

    const positions = getPositions()

    return (
      <div>
        <form onSubmit={handleSearchText} className={classes.searchContainer}>
          <TextField
            variant='outlined'
            color='primary'
            fullWidth
            value={resultSearchText}
            onChange={(e) => setResultSearchText(e.target.value)}
            size='small'
            placeholder='Ingresa el N dorsal / nombre del competidor'
            className={classes.textField}
          />
          <Button
            color='secondary'
            variant='contained'
            className={classes.searchButton}
            type='submit'>
            <SearchIcon color='primary' />
          </Button>
        </form>
        <Typography color='primary' className={classes.helperText}>
          Seleccione la categoría para conocer los resultados de la misma
        </Typography>
        <div className={classes.selectContainer}>
          <Select
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedValues.circuit}
            onChange={(e) =>
              setSelectedValues({ ...initialSelectedValues, circuit: e.target.value })
            }
            renderValue={(value) => `Distancia (${value === ALL_OPTIONS ? 'Todos' : value})`}
            className={classes.select}
            IconComponent={(props) => <KeyboardArrowDownIcon color='primary' {...props} />}>
            {circuits.map((x, index) => (
              <MenuItem key={index} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            margin='dense'
            variant='outlined'
            value={selectedValues.category}
            onChange={handleOnChangeCategory}
            className={classes.select}
            renderValue={(value) => `Categoría (${value === ALL_OPTIONS ? 'Todos' : value})`}
            IconComponent={(props) => <KeyboardArrowDownIcon color='primary' {...props} />}>
            <MenuItem value={ALL_OPTIONS}>Todos</MenuItem>
            {categories.map((x, index) => (
              <MenuItem key={index} value={x.name}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            margin='dense'
            variant='outlined'
            className={classes.select}
            value={selectedValues.gender}
            renderValue={(value) => {
              const gender = genders.find((g) => g.value === value) || { label: 'Todos' }
              return `Género (${gender.label})`
            }}
            onChange={(e) => setSelectedValues({ ...selectedValues, gender: e.target.value })}
            IconComponent={(props) => <KeyboardArrowDownIcon color='primary' {...props} />}>
            {(selectedValues.category === ALL_OPTIONS || genders.length > 1) && (
              <MenuItem value={ALL_OPTIONS}>Todos</MenuItem>
            )}

            {genders.map((x, index) => (
              <MenuItem key={index} value={x.value}>
                {x.label}
              </MenuItem>
            ))}
          </Select>
        </div>

        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.expandButtonCol}></th>
              <th>PUESTO</th>
              <th className={classes.runnerNumberCol}>Nº</th>
              <th>COMPETIDOR/A</th>
              <th className={classes.cityNameCol}>CATEGORÍA</th>
              <th className={classes.cityNameCol}>PROCEDENCIA</th>
              <th className={classes.cityNameCol}>VUELTAS</th>
              <th>TIEMPO</th>
            </tr>
          </thead>
          <tbody>
            {positions.length > 0 &&
              positions.map((x) => (
                <Fragment key={x.id}>
                  <tr className={clsx(classes.row, expandedRows[x.id] && 'expanded')}>
                    <td className={classes.expandButtonCol}>
                      <IconButton
                        className={classes.expandButton}
                        onClick={() => {
                          setExpandedRows({
                            ...expandedRows,
                            [x.id]: !expandedRows[x.id]
                          })
                        }}>
                        {expandedRows[x.id] ? (
                          <KeyboardArrowDownIcon color='secondary' />
                        ) : (
                          <KeyboardArrowRightIcon color='primary' />
                        )}
                      </IconButton>
                    </td>
                    <td>{showPosition(x)}</td>
                    <td className={classes.runnerNumberCol}>{getRunnerNumber(x)}</td>
                    <td>{getRunnerName(x)}</td>
                    <td className={classes.cityNameCol}>{x.circuitCategory.categoryName}</td>
                    <td className={classes.cityNameCol}>{x.inscription.cityName}</td>
                    <td className={classes.cityNameCol}>{showLaps(x)}</td>
                    <td>{x.totalTime}</td>
                  </tr>

                  {expandedRows[x.id] && (
                    <tr>
                      <td colSpan={100} className={classes.innerTableCell}>
                        <table>
                          <thead>
                            <tr>
                              <th>Nº</th>
                              <th>CATEGORÍA</th>
                              <th>CIUDAD</th>
                              <th>VUELTAS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{getRunnerNumber(x)}</td>
                              <td>{x.circuitCategory.categoryName}</td>
                              <td>{x.inscription.cityName}</td>
                              <td>{showLaps(x)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            {positions.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <Typography component='p' className={classes.bodyParagraph}>
                    La combinación escogida no tiene resultados
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
  return eventResultsPending || !eventResults ? renderEventLoader() : renderEventResult()
}

export default EventResults
