import { makeStyles } from '@material-ui/core'
import Cronos from 'assets/img/Cronos.jpg'

export const useStyles = makeStyles((theme) => ({
  page: {
    paddingTop: '73px !important',
    [theme.breakpoints.up('md')]: {
      paddingTop: '125px !important'
    }
  },
  landingHeader: {
    backgroundImage: `url(${Cronos})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: '50vw',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'center',
      backgroundPosition: 'center'
    }
  },
  container: {
    margin: '0px auto',
    width: '100%',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '70%',
      width: 1280
    },
    '&.white': {
      color: '#fff'
    }
  },
  darkContainer: {
    backgroundColor: '#000000'
  },
  titleContainer: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    color: '#ffffff',
    gap: theme.spacing(3),
    alignSelf: 'flex-end',
    width: '40%',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      gap: theme.spacing(13)
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontSize: '4.5vw',
    lineHeight: '5.5vw',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      lineHeight: '7vw',
      fontSize: '5vw'
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: '80px',
      fontSize: '4.375rem'
    }
  },
  cronos: {
    height: '10vw',
    width: '30vw',
    [theme.breakpoints.up('xl')]: {
      width: 521,
      height: 152
    }
  },
  cronosText: {
    fontWeight: 500,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.75rem'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200,
    [theme.breakpoints.up('md')]: {
      minHeight: 320
    }
  },
  imageContainer: {
    height: 100,
    width: 120,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  bodyContainer: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '90%',
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  textDescription: {
    paddingTop: 10,
    fontSize: '.9rem',
    textAlign: 'left'
  },
  cardContainer: {
    width: 300,
    '&  h3': {
      fontWeight: 600
    }
  },
  backgroundContainer: {
    width: '100%',
    height: 600,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      height: 400
    }
  },
  containerInformation: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  sectionInformation: {
    width: '100%',
    marginBottom: 30,
    [theme.breakpoints.up('lg')]: {
      width: '33.33%',
      marginBottom: 0
    }
  },
  sectionTitle: {
    width: '100%',
    marginTop: 30,
    fontWeight: 500,
    marginBottom: 30,
    fontSize: '1.5rem',
    [theme.breakpoints.up('lg')]: {
      width: '45%'
    }
  },
  featureProductContainer: {
    color: '#fff',
    margin: '0px auto',
    padding: theme.spacing(0, 2),
    minHeight: '30%',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      paddingLeft: theme.spacing(2)
    },
    [theme.breakpoints.up('lg')]: {
      height: 680,
      width: 1280
    }
  },
  timmingTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7.5),
      marginBottom: theme.spacing(5),
      fontSize: '2.575rem'
    }
  },
  backgroundHeader: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      marginBottom: -92,
      margin: '0px 0px auto 0px',
      width: 1280,
      backgroundColor: theme.palette.secondary.contrastText,
      minHeight: 92
    }
  },
  cronosToEventSection: {
    color: '#fff',
    margin: theme.spacing(0, 2),
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.up('lg')]: {
      margin: '0px auto',
      width: 1280
    }
  },
  cronosToEventTitle: {
    fontSize: '1.375rem',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
      marginBottom: theme.spacing(5),
      fontSize: '2.575rem'
    }
  },
  cronosToEvent: {
    [theme.breakpoints.up('md')]: {
      width: '60% !important',
      '& > div': {
        minHeight: '100px !important'
      }
    }
  }
}))
