import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 870,
      maxWidth: 870
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  radioContainer: {
    margin: theme.spacing(4, 0)
  },
  label: {
    fontWeight: 600
  },
  buttonSave: {
    width: 250,
    height: 48,
    borderRadius: 10,
    '&.secondary': {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(3),
      '& span': {
        fontWeight: 500,
        color: theme.palette.primary.main,
        fontSize: '1.125rem'
      }
    }
  },
  containerButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  formGroup: {
    marginTop: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
    width: '100%',
    '&.row': {
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: theme.spacing(3)
      },
      gap: theme.spacing(2),
      flexDirection: 'column-reverse'
    },

    '&.secondary': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(3)
    }
  },
  row: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 60,
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      flexDirection: 'row',
      gap: theme.spacing(3)
    }
  },
  rowSecond: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column-reverse',
    minHeight: 60,
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      flexDirection: 'row',
      gap: theme.spacing(3)
    }
  },
  inputDatePicker: {
    margin: 0,
    '& input': {
      height: 3,
      fontWeight: 500
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    '& label': {
      color: theme.palette.primary.main,
      transform: 'translate(14px, 11px) scale(1)'
    },
    '& > div': {
      paddingRight: 0
    }
  },
  input: {
    margin: 0,
    height: 49,
    '& label': {
      color: theme.palette.primary.main
    },
    '& input': {
      fontWeight: 500
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  inputOther: {
    margin: 0,
    height: 45,
    '& label': {
      color: theme.palette.primary.main
    },
    '&.secondary': {
      [theme.breakpoints.up('lg')]: {
        width: 'calc(50% - 12px)'
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  inputSocial: {
    margin: 0,
    '& fieldset': {
      height: 42,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& input': {
      fontWeight: 500
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  title: {
    marginBottom: 20
  },
  selectItem: {
    height: 45,
    width: '100%',
    fontWeight: 500,
    '& input': {
      height: 3
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    '& label': {
      color: theme.palette.primary.main,
      transform: 'translate(14px, 11px) scale(1)'
    }
  },
  labelSelect: {
    color: theme.palette.primary.main,
    transform: 'translate(14px, 11px) scale(1)'
  },
  select: {
    minHeight: 45,
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
    '& > div': {
      padding: theme.spacing(1, 1.75),
      paddingTop: theme.spacing(0.5),
      fontWeight: 500
    },
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5,
      minHeight: 45
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    [theme.breakpoints.up('lg')]: {
      width: 379
    }
  },
  formControl: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      '&.secondary': {
        width: 'calc(50% - 12px)'
      }
    }
  },
  inputText: {
    color: theme.palette.helperText.main,
    marginLeft: theme.spacing(1.5),
    fontSize: 12
  },
  selectItemDisciplines: {
    maxWidth: 300,
    width: 300
  },
  socialContainer: {
    marginTop: 20
  },
  socialRow: {
    display: 'flex',
    height: 40
  },
  socialIcon: {
    '& svg': {
      width: '90% !important'
    }
  },
  socialIconYoutube: {
    '& svg': {
      width: '100% !important'
    }
  },
  checkboxSocial: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    }
  },
  labelStep: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18
  },
  instagramIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    width: 23,
    borderRadius: 7,
    padding: 2,
    height: 23,
    marginTop: theme.spacing(),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5)
  },
  labelContainer: {
    marginBottom: theme.spacing(2)
  },
  containerInput: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing()
  },
  combo: {
    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },
    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  comboInput: {
    marginTop: theme.spacing(2),
    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3,
      minHeight: 45
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: theme.spacing(0.3)
    }
  }
}))
