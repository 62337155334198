import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { useFormikContext } from 'formik'
import { PaymentLocationCard, SectionLayoutDialog } from 'shared'
import {
  createPaymentLocation,
  loadPaymentLocations,
  updatePaymentLocation
} from 'state/modules/events'

import { useStyles } from './CreatePaymentLocationDialog.style'

const CARD_STATES = {
  CARD_VIEW: 'cardView',
  CARD_FORM: 'cardForm'
}

const paymentLocation = {
  name: '',
  address: '',
  description: '',
  isActive: true,
  state: CARD_STATES.CARD_FORM
}

const TYPE_ACTION_UPDATE = 'update'

const resetValues = () => [{ ...paymentLocation }]

const CreatePaymentLocationDialog = ({ open, onClose, paymentLocationCount = 0 }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { event } = useSelector((state) => state.events)
  const { values, setFieldValue } = useFormikContext()

  const [paymentLocationsToCreate, setPaymentLocationsToCreate] = useState(resetValues)
  const [paymentLocationCreatePending, setPaymentLocationCreatePending] = useState(false)

  const PAYMENT_LOCATIONS_MAX = 10

  const paymentLocationSavedCount =
    paymentLocationCount -
    paymentLocationsToCreate.filter((x) => x.state === CARD_STATES.CARD_VIEW).length +
    1

  const canAddPaymentLocation =
    paymentLocationCount < PAYMENT_LOCATIONS_MAX && !paymentLocationCreatePending

  const canSubmitPaymentLocations = !paymentLocationsToCreate.some(
    (x) => x.state === CARD_STATES.CARD_FORM
  )

  const handleSave = async ({ state, name, address, description, typeAction, id, ...values }) => {
    const newPaymentLocation = {
      ...values,
      name: name.trim(),
      address: address.trim(),
      description: description.trim(),
      state: CARD_STATES.CARD_VIEW,
      id
    }

    if (typeAction === TYPE_ACTION_UPDATE) {
      await handleUpdatePaymentLocationState(newPaymentLocation)
    } else {
      setPaymentLocationCreatePending(true)
      paymentLocationsToCreate.pop()
      await handlePersistPaymentLocations(newPaymentLocation)
      setPaymentLocationCreatePending(false)
    }
  }

  const handleOnClose = async () => {
    await dispatch(loadPaymentLocations(event.id))
    onClose()
    setPaymentLocationsToCreate(resetValues)
  }

  const handleAddPaymentLocation = () => {
    setPaymentLocationsToCreate([...paymentLocationsToCreate, { ...paymentLocation }])
  }

  const handlePersistPaymentLocations = async (paymentLocation) => {
    const data = await dispatch(createPaymentLocation(event.id, paymentLocation))

    event.eventPaymentLocationsEnabled = data.isActive

    setPaymentLocationsToCreate([
      ...paymentLocationsToCreate,
      { ...data, state: CARD_STATES.CARD_VIEW }
    ])

    await dispatch(loadPaymentLocations(event.id))
  }

  const handleUpdatePaymentLocationState = async (values) => {
    await dispatch(updatePaymentLocation(event.id, values))
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={handleOnClose}
      title='PAGO OFFLINE - PUNTO FISICO'
      className={classes.dialog}
      contentClassName={classes.container}>
      <main className={classes.mainContainer}>
        {paymentLocationsToCreate.length > 1 && (
          <div className={classes.infoDialog}>
            <InfoIcon color='primary' className={classes.infoIcon} />
            <Typography color='primary' className={classes.textInfoDialog}>
              Utilice los checkbox para habilitar o deshabilitar los lugares de pago.
            </Typography>
          </div>
        )}
        <div className={classes.paymentsContainer}>
          {paymentLocationCreatePending ? (
            <CircularProgress color='primary' size={32} />
          ) : (
            paymentLocationsToCreate.map((x, index) => (
              <PaymentLocationCard
                key={index}
                paymentLocation={x}
                title={`Lugar de pago ${paymentLocationSavedCount + index}`}
                cardState={x.state}
                {...{ values, setFieldValue }}
                showCheckActive={x.state === CARD_STATES.CARD_VIEW}
                onSubmit={handleSave}
                saveChange
              />
            ))
          )}
        </div>
        {canSubmitPaymentLocations && canAddPaymentLocation && (
          <Typography
            onClick={handleAddPaymentLocation}
            color='primary'
            className={classes.offLineTextLink}>
            + Añadir punto físico
          </Typography>
        )}
      </main>
    </SectionLayoutDialog>
  )
}

export default CreatePaymentLocationDialog
