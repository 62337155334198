import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600
    }
  },
  container: {
    width: '75% !important',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0)
  },
  button: {
    alignSelf: 'center',
    height: 41,
    maxWidth: 200,
    width: '45%'
  }
}))
