import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7),
    marginTop: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: 0,
      justifyContent: 'space-between',
      padding: theme.spacing(0, 3),
      width: '90%'
    }
  },
  button: {
    borderRadius: 10,
    width: 250,
    height: 48,

    '& span': {
      fontSize: '1rem'
    }
  }
}))
