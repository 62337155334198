import React from 'react'
import { Typography } from '@material-ui/core'
import { ReactComponent as PinardLogo } from 'assets/icons/pinard-logo.svg'

import { useStyles } from './Footer.style'

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <div className={classes.developmentInfo}>
        <Typography className={classes.developmentText}>Desarrollado por </Typography>
        <PinardLogo className={classes.pinardLogo} />
      </div>
    </footer>
  )
}

export default Footer
