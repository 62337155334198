import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { useStyles } from './DeleteConfirmDialog.style'

const DeleteConfirmDialog = ({
  open,
  onClose,
  title,
  onDelete,
  question,
  helperText,
  actionName,
  deletePending
}) => {
  const classes = useStyles()

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      open={open}
      onClose={onClose}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title}>
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon color='secondary' />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.deleteDialogBody}>
          <Typography variant='h6' align='center' color='primary'>
            {question}
          </Typography>
          <Typography align='center'>{helperText}</Typography>
        </div>
        <Button
          className={classes.actionButton}
          color='primary'
          variant='contained'
          disabled={deletePending}
          onClick={onDelete}
          endIcon={deletePending && <CircularProgress size={16} />}>
          {actionName}
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteConfirmDialog
