import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    color: 'grey'
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5, 3),
    [theme.breakpoints.up('lg')]: {
      width: '80%',
      margin: '0 auto'
    }
  },
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 800,
      maxWidth: 800,
      margin: '0 auto'
    }
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '6rem'
  },
  textContainer: {
    width: '100%',
    height: 180
  },
  dialogQuestion: {
    fontWeight: 600,
    marginTop: 20
  }
}))
