import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  organizationsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '&.activeEdit': {
      marginTop: theme.spacing(2)
    },

    maxWidth: 350,
    '& > div': {
      marginRight: 0
    }
  },
  saveButton: {
    width: 200,
    height: 48,
    alignSelf: 'flex-start',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  link: {
    fontSize: '.875rem',
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(2)
  },
  option: {
    zIndex: '2 !important',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    color: theme.palette.secondary.contrastText,
    margin: theme.spacing(1, 0),
    marginRight: theme.spacing()
  },
  combo: {
    '& > div > label': {
      transform: 'translate(14px, 10px) scale(1)'
    },

    '& > div > div': {
      paddingBottom: `0 !important`,
      paddingTop: `0 !important`,
      paddingLeft: `${theme.spacing()} !important`,
      paddingRight: `${theme.spacing()} !important`
    },
    '& > div > div > div': {
      zIndex: 2
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  container: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column'
  },
  comboInput: {
    marginBottom: theme.spacing(),

    '& fieldset': {
      backgroundColor: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.main,
      borderRadius: 3
    },

    '& label': {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: '.875rem',
      backgroundColor: theme.palette.primary.contrastText,
      paddingRight: theme.spacing()
    },
    '& input': {
      zIndex: 1,
      fontWeight: 500
    },
    '& > p': {
      color: theme.palette.helperText.main
    }
  }
}))
