import axios from 'axios'

import BaseProxy from './BaseProxy'

export class DisciplinesProxy extends BaseProxy {
  async getSearch(search, page, pageSize, orderBy, order) {
    const urlParams = this._makeUrlParams({
      search,
      page,
      pageSize,
      orderBy,
      order
    })
    const response = await axios.get(`${this._baseUrl}/disciplines/${urlParams}`)
    return response.data
  }
}

export default DisciplinesProxy
