import React from 'react'
import { IconButton, makeStyles, Typography, withStyles } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

export const useStyles = makeStyles((theme) => ({
  container: {
    '& > div > div': {
      height: '60vh',
      width: 500
    }
  },
  tabs: {
    backgroundColor: '#ffffff',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    position: 'sticky',
    top: -9
  },
  tab: {
    color: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    textTransform: 'none',
    '&:focus': {
      fontWeight: 600,
      backgroundColor: '#ffffff',
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  }
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(2),
    fontWeight: 800,
    color: theme.palette.primary.main
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ fontWeight: 600 }} variant='h6'>
        {children}
      </Typography>
      {onClose && (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon color='primary' />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
})
