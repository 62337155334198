import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import {
  downloadInscriptionsAcceptedSheet,
  downloadInscriptionsInsuranceSheet,
  downloadInscriptionsRunnersSheetSheet,
  downloadInscriptionsSheet
} from 'state/modules/events'

import { useStyles } from './DownloadListsDialog.style'

const LIST_TYPES = {
  LIST_COMPLETE: 'completo',
  LIST_FOR_INSURANCE: 'seguro',
  LIST_ATHLETES: 'deportistas',
  LIST_REGISTRATIONS: 'inscripciones'
}

const DownloadListsDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const { event } = useSelector((state) => state.events)
  const dispatch = useDispatch()

  const [selectedList, setSelectedList] = useState(null)

  const handleOnclose = () => {
    setSelectedList(null)
    onClose()
  }

  const handleDownloadList = () => {
    switch (selectedList) {
      case LIST_TYPES.LIST_COMPLETE:
        dispatch(downloadInscriptionsSheet(event))
        break
      case LIST_TYPES.LIST_FOR_INSURANCE:
        dispatch(downloadInscriptionsInsuranceSheet(event))
        break
      case LIST_TYPES.LIST_ATHLETES:
        dispatch(downloadInscriptionsRunnersSheetSheet(event))
        break
      case LIST_TYPES.LIST_REGISTRATIONS:
        dispatch(downloadInscriptionsAcceptedSheet(event))
        break
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      className={classes.dialog}
      onClose={handleOnclose}
      title='Descargar listado de inscriptos'>
      <div className={classes.infoContainer}>
        <Typography className={classes.textInitial}>
          Seleccioná el listado de inscriptos que deseas descargar
        </Typography>
        <div className={classes.radioButtonContainer}>
          <RadioGroup
            onChange={(e) => {
              console.log(e.target.value)
              setSelectedList(e.target.value)
            }}
            value={selectedList}>
            <FormControlLabel
              value={LIST_TYPES.LIST_COMPLETE}
              control={<Radio color='primary' className={classes.radio} />}
              classes={{ label: classes.labelRadio }}
              label='Completo'
            />
            <FormControlLabel
              value={LIST_TYPES.LIST_FOR_INSURANCE}
              control={<Radio color='primary' className={classes.radio} />}
              classes={{ label: classes.labelRadio }}
              label='Para el seguro'
            />
            <FormControlLabel
              value={LIST_TYPES.LIST_ATHLETES}
              control={<Radio color='primary' className={classes.radio} />}
              classes={{ label: classes.labelRadio }}
              label='Deportista (para Crono)'
            />
            <FormControlLabel
              value={LIST_TYPES.LIST_REGISTRATIONS}
              control={<Radio color='primary' className={classes.radio} />}
              classes={{ label: classes.labelRadio }}
              label='Inscripciones (para Crono)'
            />
          </RadioGroup>
        </div>
        <div className={classes.butonContainer}>
          <Button
            color='primary'
            variant='contained'
            disabled={!selectedList}
            onClick={handleDownloadList}>
            Descargar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default DownloadListsDialog
