import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      maxWidth: 1920
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      width: 920,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 380,
      [theme.breakpoints.up('sm')]: {
        minHeight: 350
      }
    }
  },
  dialogTitle: {
    padding: 0
  },
  headerContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    height: 70,
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2)
  },
  title: {
    flexGrow: 1,
    fontWeight: 600,
    marginLeft: theme.spacing(7)
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    height: '100%',
    paddingTop: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3, 6),
      paddingTop: theme.spacing(6)
    }
  },
  deleteDialogBody: {
    minHeight: 120,
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    '& h6': {
      paddingBottom: theme.spacing(2),
      fontWeight: 600
    },
    '& p': {
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        width: '80%',
        margin: '0 auto'
      }
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing()
    }
  },
  actionButton: {
    padding: theme.spacing(1, 10)
  }
}))
