import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '100% !important',
    maxWidth: '100% !important'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  receiptTitle: {
    fontWeight: 600
  },
  button: {
    minWidth: 40,
    padding: '6px'
  },
  receiptReviewedButton: {
    backgroundColor: `${theme.utils.rgba(theme.palette.primary.main, 0.4)} !important`,
    color: `${theme.palette.primary.contrastText} !important`
  },
  deleteButton: {
    alignSelf: 'center',
    width: 300,
    height: 40
  },
  fileViewContainer: {
    height: '70vh !important'
  },
  cropper: {
    height: '57vh !important'
  },
  helperText: {
    fontSize: '.75rem'
  }
}))
