import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '400px !important',
    [theme.breakpoints.up('md')]: {
      width: 850,
      maxWidth: 850
    }
  }
}))
