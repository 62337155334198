import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  comboxsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  select: {
    width: '100%',
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      borderColor: theme.palette.primary.main,
      borderRadius: 0,
      boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)'
    }
  },
  option: {
    display: 'flex',
    height: 25,
    alignItems: 'center',
    '& > div ': {
      fontSize: '0.875rem'
    }
  },
  optionName: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(),
    '&.withType': {
      width: '50%',
      textAlign: 'left'
    }
  },

  addButton: {
    width: '50%',
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginLeft: 300,
      marginTop: 0,
      width: 300
    }
  },
  messageText: {
    fontSize: '.9rem',
    margin: theme.spacing(5, 0),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left'
    }
  },
  dialogTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  }
}))
