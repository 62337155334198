import { makeStyles } from '@material-ui/core'
import whiteLogoCondensedImage from 'assets/img/logo-condensed-white.png'
import whiteLogoImage from 'assets/img/logo-white.png'

export const useStyles = makeStyles((theme) => ({
  closeButtonContainer: {
    textAlign: 'right'
  },
  closeButton: {
    color: theme.palette.secondary.main
  },
  closeIcon: {
    width: 35,
    height: 35,
    [theme.breakpoints.up('lg')]: {
      width: 40,
      height: 40
    }
  },
  appBar: {
    transition: 'all .3s',
    right: 'auto',
    left: 'auto',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    color: theme.palette.primary.dark,
    height: 60,
    [theme.breakpoints.up('md')]: {
      height: 90,
      width: '33vw'
    },
    [theme.breakpoints.up('xl')]: {
      width: '25vw'
    }
  },
  appBarTransparent: {
    backgroundColor: 'transparent'
  },
  toolbar: {
    height: 60,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      height: 120
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(6),
      paddingRight: 0
    }
  },
  logoContainer: {
    flexGrow: 0
  },
  logoContainerGrow: {
    flexGrow: 1
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${whiteLogoImage})`,
    width: 248 - 24,
    height: 15,
    [theme.breakpoints.up('md')]: {
      width: 248,
      height: 23
    }
  },
  logoCondensed: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${whiteLogoCondensedImage})`,
    width: 37,
    height: 37
  }
}))
