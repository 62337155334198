import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { LayoutDialog } from 'shared'

import { useStyles } from './WarningStateDialog.style'

const WarningStateDialog = ({ open, onClose, onAccepted }) => {
  const classes = useStyles()
  return (
    <>
      <LayoutDialog
        title='ADVERTENCIA'
        open={open}
        className={classes.dialog}
        contentClassName={classes.bodyContainer}>
        <div>
          <div className={classes.textContainer}>
            <Typography className={classes.dialogTitle} color='initial'>
              Debe guardar los cambios antes de realizar esta acción, de lo contrario perderá los
              cambios de estado realizados.
            </Typography>
            <Typography className={classes.dialogQuestion} color='primary'>
              ¿Desea continuar sin Guardar?
            </Typography>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                onClose()
              }}>
              Cancelar
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                onAccepted()
                onClose()
              }}>
              Aceptar
            </Button>
          </div>
        </div>
      </LayoutDialog>
    </>
  )
}

export default WarningStateDialog
