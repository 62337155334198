import { makeStyles } from '@material-ui/core'
import motocrossImage from 'assets/img/motocross-landing.jpg'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    minHeight: 850,
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(6),
    gap: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      paddingTop: 0,
      height: '30vw',
      minHeight: 'auto',
      maxHeight: 380
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },

  mainContainer: {
    display: 'flex',
    minHeight: 850,
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: 0,
      height: '30vw',
      minHeight: 'auto',
      maxHeight: 380,
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1280,
      padding: 0
    }
  },
  content: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end',
    minHeight: 430,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      minHeight: 'auto',
      alignItems: 'center',
      paddingLeft: theme.spacing(10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 900,
    fontSize: '2.3rem',
    lineHeight: '38px',

    [theme.breakpoints.up('lg')]: {
      lineHeight: '58px',
      fontSize: '3.8125rem'
    },

    '& span': {
      color: theme.palette.secondary.main
    }
  },
  motocrossImage: {
    width: '100%',
    height: '66.5vw',
    backgroundSize: 'contain',
    flexShrink: 0,
    backgroundImage: `url(${motocrossImage})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      width: '45%',
      height: '30vw',
      maxWidth: 675,
      maxHeight: 380
    }
  }
}))
