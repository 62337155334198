import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    }
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(15),
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(18),
      gap: theme.spacing()
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    fontSize: '1.75rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem'
    }
  },
  trustUs: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    fontSize: '1.75rem',
    width: 340,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      width: 525
    }
  },
  content: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      padding: 0,
      width: 1200
    }
  },
  slickSlider: {
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: 550
    }
  },
  radioButton: {
    marginTop: theme.spacing(3),
    color: theme.palette.secondary.main
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: 400
  },
  sliderText: {
    fontSize: '1rem',
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilyAlt,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },
  sliderSubtext: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamilyAlt,
    fontSize: '1.125rem'
  },
  infoContainer: {
    paddingBottom: theme.spacing(20)
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: -10,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))
