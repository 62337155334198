import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 300
  },
  followed: {
    width: '60%',
    height: 10
  }
}))
