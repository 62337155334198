import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: 0
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(17),

    '& span': {
      position: 'relative',
      zIndex: 1,
      display: 'inline-block',
      padding: '0 0.1em',
      pointerEvents: 'none'
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(22)
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontSize: '1.75rem',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      width: 500
    }
  },
  mainContainer: {
    paddingBottom: theme.spacing(9),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(6),
      paddingBottom: theme.spacing(9)
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1280
    }
  },
  content: {
    borderTop: '1px solid #888A8A',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      padding: 0,
      width: 900
    }
  },
  link: {
    color: theme.palette.primary.main
  },
  sportmetricLink: {
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}))
