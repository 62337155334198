import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Typography } from '@material-ui/core'
import { Close as CloseIcon, EditOutlined as EditOutlinedIcon } from '@material-ui/icons'
import { loadCustomFields, updateCustomField } from 'state/modules/organizations'
import { showSnackbarSuccess } from 'utils/snackbar'

import { ActionCustomFieldDialog } from '../ActionCustomFieldDialog'

import { useStyles } from './CustomFieldCard.style'

const CustomFieldCard = ({ field, onDelete, organizationId, index, values, setFieldValue }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const { updateCustomFieldError } = useSelector((state) => state.organizations)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleSave = async (value, { setErrors }) => {
    const updatedField = {
      ...value,
      isRequired: value.isRequired === 'Si',
      options: (value.type === 'List' && value.options?.filter((e) => !!e.name)) || [],
      allowMultiselect: false
    }
    const updateSuccess = await dispatch(updateCustomField(organizationId, updatedField))
    if (updateSuccess) {
      await dispatch(loadCustomFields(organizationId))
      values.customFields[index] = { ...values.customFields[index], eventCustomField: updatedField }
      setFieldValue('customFields', values.customFields)
      showSnackbarSuccess('¡Campo personalizado actualizado con éxito!')
      return true
    } else {
      setErrors({ name: 'El nombre del campo ya existe' })
    }
  }

  const handleDelete = (id) => {
    onDelete(id)
    showSnackbarSuccess('¡El campo personalizado seleccionado se quitó del listado con éxito!')
  }

  const handleLabel = (type) => {
    switch (type) {
      case 'Text':
        return 'Texto'
      case 'Number':
        return 'Número'
      case 'Boolean':
        return 'Si/No'
      case 'List':
        return 'Lista desplegable'
      case 'File':
        return 'Archivo adjunto'
      default:
        return 'Texto'
    }
  }
  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <ActionCustomFieldDialog
          open={openDialog}
          title='Editar campo personalizado'
          actionName='Guardar'
          action={handleSave}
          asyncError={updateCustomFieldError}
          onClose={() => setOpenDialog(false)}
          field={{ ...field, isRequired: field.isRequired ? 'Si' : 'No' }}
        />

        <ActionCustomFieldDialog
          open={openDeleteDialog}
          className={classes.deleteDialogContainer}
          title='Eliminar campo personalizado'
          actionName='Eliminar'
          action={handleDelete}
          onClose={() => setOpenDeleteDialog(false)}
          field={{ ...field, isRequired: field.isRequired ? 'Si' : 'No' }}
        />
        <Typography variant='h6' title={field.name} className={classes.title}>
          {field.name}
        </Typography>
        <IconButton onClick={() => setOpenDialog(true)}>
          <EditOutlinedIcon className={classes.editIcon} />
        </IconButton>
        <IconButton color='secondary' onClick={() => handleDelete(field.id)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.bodyContainer}>
        <div className={classes.content}>
          <Typography className={classes.label}>Tipo:</Typography>
          <Typography className={classes.value}>{handleLabel(field.type)}</Typography>
        </div>
        <div className={classes.content}>
          <Typography className={classes.label}>Obligatorio: </Typography>
          <Typography className={classes.value}> {field.isRequired ? 'si' : 'no'}</Typography>
        </div>
      </div>
    </div>
  )
}

export default CustomFieldCard
