import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
  firstName: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '300px',
    overflowWap: 'anywhere'
  }
}))
