import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  focusedTooltip: {
    pointerEvents: 'all',
    zIndex: 1000
  },

  button: {
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 2000,
    color: theme.palette.common.white,
    '& svg': {
      width: 15,
      height: 15
    },
    '&.right-top': {
      top: 5,
      right: 20
    }
  }
}))
