import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'

import { useStyles } from './UpdateChangeDialog.style'

const UpdateChangeDialog = ({ open, onClose, ticket = {}, onUpdateChange }) => {
  const classes = useStyles()

  const getQuota = (quota, inscriptionsCount) => {
    const newQuota = quota - inscriptionsCount

    return newQuota || 'Sin limite'
  }

  return (
    <SectionLayoutDialog
      title='NOTIFICACIÓN'
      open={open}
      onClose={onClose}
      className={classes.dialog}
      backDropClassName={classes.backDrop}
      contentClassName={classes.container}>
      <div className={classes.mainContainer}>
        <Typography color='primary' variant='h6' align='center'>
          Estás modificando el valor del ticket&nbsp;
          <strong>
            {ticket.name}
            {!!ticket.quota && (
              <>
                , <br />
              </>
            )}
          </strong>
          {!!ticket.quota && (
            <>
              el nuevo cupo disponible para este ticket es&nbsp;
              {getQuota(ticket.quota, ticket.inscriptionsCount)}
              <br />
              (diferencia entre el cupo original y tickets vendidos).
            </>
          )}
        </Typography>
        <div className={classes.buttonContainer}>
          <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={() => onUpdateChange(ticket)}>
            Aceptar
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default UpdateChangeDialog
