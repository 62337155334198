import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up('lg')]: {
      margin: '30px auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: 1280
    }
  },
  sectionBlock: {
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    minHeight: 600,
    margin: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 600
    }
  },
  sectionHeaderContainer: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2),
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 2),
    '& h6': {
      fontWeight: 600
    }
  },
  sectionBodyContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 6)
    }
  },
  formGroup: {
    padding: theme.spacing(1, 0),
    '& h6': {
      fontSize: '.8rem'
    },
    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    }
  },
  createButtonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  createButton: {
    width: '50%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: '40%'
    }
  },
  table: {
    width: '100%'
  },

  col: {
    borderBottom: '0.5px solid #C8C8C8',
    width: '40%',
    color: '#969696'
  },
  deleteDialogContainer: {
    '& > div > div': {
      minHeight: 'auto'
    }
  },
  dialogTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    '& > div': {
      padding: 0
    },
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  checkContainer: {
    flexDirection: 'row-reverse',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5),
    fontWeight: 500,
    '& span': {
      fontWeight: 500,
      fontSize: '.8rem'
    }
  }
}))
