import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { CountriesProxy } from 'services'
import { LocationPicker } from 'shared'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './LocationSection.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }

const LocationSection = ({ event }) => {
  const classes = useStyles()

  const { latitude, longitude, countryName, countryId } = event

  const country = countryId
    ? {
        value: countryId,
        label: countryName
      }
    : ''

  const hasCoord = latitude !== 0 && longitude !== 0

  useEffect(() => {
    onCountryChange()(country)
  }, [])

  const getAddress = (event) =>
    [
      event.countryName,
      event.areaLevel1Name,
      event.address,
      event.latitude === 0 &&
        event.longitude === 0 &&
        'la ubicación exacta se informará próximamente'
    ]
      .filter(Boolean)
      .join(', ')

  const onCountryChange = (setValues) => async (options) => {
    try {
      if (setValues) {
        setValues((values) => ({
          ...values,
          country: options,
          countryId: options.value,
          areaLevel1: '',
          areaLevel1Id: ''
        }))
      }
    } catch (e) {}
  }

  // eslint-disable-next-line
  const validationSchema = Yup.object().shape({
    address: Yup.string()
      .nullable()
      .test(
        'test-required',
        'Debe ingresar la dirección exacta del evento',
        (value = '', { parent }) => parent.undefinedLocation || value
      )
      .trim(),
    countryId: Yup.string().nullable().required('Debe seleccionar un país'),
    areaLevel1Id: Yup.string()
      .nullable()
      .when('countryId', {
        is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
        then: (schema) => schema.required('Debe seleccionar una provincia/ Región/ Estado')
      }),
    location: Yup.string()
      .nullable()
      .test(
        'test-cord',
        'Debe seleccionar una ubicación',
        (value = '', { parent }) => parent.undefinedLocation || value
      )
  })

  // eslint-disable-next-line
  const onProvinceChange = (setValues) => async (options) =>
    setValues((values) => ({ ...values, areaLevel1: options, areaLevel1Id: options.value }))

  // eslint-disable-next-line
  const onConfirm = (setValues) => async (coord) => {
    const proxy = new CountriesProxy()
    const res = await proxy.getAddress(coord.lat, coord.lng)
    setValues((values) => ({ ...values, address: res.address || '' }))

    return res.address
  }

  // eslint-disable-next-line
  const handleResetValue = (setValues) => () =>
    setValues((values) => ({
      ...values,
      latitude: 0,
      longitude: 0,
      location: '',
      undefinedLocation: true,
      address: ''
    }))

  // eslint-disable-next-line
  const requiredProvince = (country) => country && country.value === ARGENTINA_COUNTRY.value

  return (
    <SectionBlock title='Ubicación'>
      <div className={classes.locationContainer}>
        <Typography variant='body1' className={classes.address}>
          {getAddress(event)}.
        </Typography>
      </div>

      {hasCoord && (
        <LocationPicker
          latitude={latitude}
          longitude={longitude}
          variant='outlined'
          buttonClassName={classes.locationButton}
        />
      )}
    </SectionBlock>
  )
}

export default LocationSection
