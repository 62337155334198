import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: theme.typography.h5.fontSize,
    borderBottom: '2px solid #e0e0e0',
    color: 'rgba(105, 105, 105, 1)',
    fontWeight: 600,
    '&:hover': {
      borderBottom: '2px solid rgba(6, 0, 173, 0.4)',
      color: 'rgba(6, 0, 173, 0.7)'
    },
    '&.seleted': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
    },
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.h4.fontSize
    }
  }
}))
