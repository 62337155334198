import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, DialogContent, Typography } from '@material-ui/core'
import { tryInscriptionRegister } from 'state/modules/app'

import { LayoutDialog } from '../LayoutDialog'

import { useStyles } from './SuccessInscriptionDialog.style'

const SuccessInscriptionDialog = ({ open, onClose, inscription = {} }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { eventInscription, activeInscriptionForm, event } = useSelector((state) => state.events)
  const { user } = useSelector((state) => state.auth)

  const renderSuccessInscription = () => {
    const { name, organization } = event
    const { isThirdParty, firstName, lastName, idNumber, category, distance } = eventInscription
    const { welcomeText } = activeInscriptionForm ?? {}

    const beginText = isThirdParty
      ? `Inscribiste con éxito a ${firstName} ${lastName}`
      : 'Te inscribiste con éxito'
    const helperText = isThirdParty
      ? `El número de inscripción es el DNI ${idNumber}`
      : `Tu número de inscripción es tu DNI ${idNumber}`

    const handleOpenSignUpDialog = () => {
      dispatch(tryInscriptionRegister({ inscription, state: true }))
      onClose()
    }

    return (
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContainer}>
          <div className={classes.infoContainer}>
            <Typography className={classes.subTitle}>
              {beginText} al evento <span>{name}</span> organizado por&nbsp;
              <span>{organization.name}</span> en {category.name} {distance.name}.
            </Typography>
            <br />
            <Typography className={classes.welcomeText}>{welcomeText}</Typography>
            <br />
            <Typography className={classes.subTitle}>{helperText}.</Typography>
            <br />
            <br />
            <Typography className={classes.subTitle}>
              *Recibirás un email con esta información más detalles del evento.
            </Typography>
            {!user && (
              <>
                <br />
                <Typography className={classes.resgisterText}>
                  Guardá tu información y ahorrá tiempo en tu próxima inscripción con un paso más.
                </Typography>
              </>
            )}

            {user ? (
              <Button
                onClick={onClose}
                color='primary'
                variant='contained'
                className={classes.acceptButton}>
                Aceptar
              </Button>
            ) : (
              <Button
                onClick={handleOpenSignUpDialog}
                color='primary'
                variant='contained'
                className={classes.acceptButton}>
                Crear cuenta
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    )
  }

  return (
    <LayoutDialog
      open={open}
      onClose={onClose}
      title='INSCRIPCIÓN EXITOSA'
      className={classes.dialog}
      titleClassName={classes.title}
      contentClassName={classes.container}>
      {eventInscription ? renderSuccessInscription() : <CircularProgress size={20} />}
    </LayoutDialog>
  )
}

export default SuccessInscriptionDialog
