import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E6FFEA'
  },
  containerBody: {
    backgroundColor: '#FFFFFF'
  },
  containerActionBar: {
    backgroundColor: '#CCFFD4'
  },
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1260
    },
    [theme.breakpoints.up(1550)]: {
      width: 1490
    }
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(6)
    }
  },
  featureImage: {
    height: 200,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      height: 435
    },
    [theme.breakpoints.up(1550)]: {
      height: 525
    }
  },
  profileImage: {
    marginTop: -(theme.spacing(2) + 75),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 75,
    width: 150,
    flexShrink: 0,
    height: 150,
    [theme.breakpoints.up('lg')]: {}
  },
  organizationInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      width: 1170,
      margin: '0 auto'
    },
    [theme.breakpoints.up(1550)]: {
      width: 1400
    }
  },
  titleContainer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      flexGrow: 1,
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4)
    }
  },
  title: {
    fontWeight: 600,
    fontSize: '1.5em'
  },
  handle: {
    fontWeight: 500,
    fontSize: '1.25em',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  sportsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start'
    }
  },
  sport: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  actionBar: {
    backgroundColor: '#CCFFD4',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  actionButton: {
    border: 'none',
    width: theme.spacing(12),
    '&:hover': {
      border: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto'
    }
  },
  actionButtonLabel: {
    display: 'flex',
    flexDirection: 'column',
    '& em': {
      fontSize: '1.25em',
      fontWeight: 600,
      fontStyle: 'normal'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      '& em': {
        marginLeft: theme.spacing(1)
      },
      '& span': {
        marginLeft: theme.spacing(1)
      }
    }
  },
  actionButtonLabelHidable: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'inline'
    }
  },
  actionBarButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexGrow: 1,
    '& button': {
      marginTop: theme.spacing(1)
    },
    [theme.breakpoints.up('lg')]: {
      '& button': {
        marginTop: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
      }
    }
  },
  anchorLink: {
    fontSize: '1em',
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  map: {
    height: 300,
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row'
    }
  },
  leftColumn: {
    [theme.breakpoints.up('lg')]: {
      flexGrow: 1,
      marginTop: theme.spacing(2)
    }
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      width: '33%',
      flexShrink: 0,
      borderLeft: '1px solid #E6E6E6'
    }
  },
  containerLoader: {
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.up('xl')]: {
      width: 1920
    }
  },
  asyncSelect: {
    marginBottom: theme.spacing(),
    minWidth: 260,
    '& >  div > div > div': {
      color: theme.palette.primary.main
    },
    '& > div': {
      border: 'none',
      boxShadow: 'none',
      borderRadius: 0,
      fontSize: '1rem'
    },
    '& > div:hover': {
      border: 'none',
      boxShadow: 'none'
    }
  },
  buttonFollow: {
    '&:disabled': {
      color: theme.palette.primary.main,
      backgroundColor: '#FFFFFF'
    }
  },
  buttonFollowing: {
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main
    }
  },
  editButton: {
    marginTop: theme.spacing(4),
    alignSelf: 'center',
    width: 290,
    height: 43,
    borderRadius: 20,
    '& span': {
      fontWeight: 700,
      fontSize: '1.25rem'
    }
  },
  upcomingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(4),
      alignItems: 'flex-start',
      flexDirection: 'row'
    }
  },
  upcomingMainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&.hasEvents': {
      gap: theme.spacing(4)
    }
  },
  upcomingHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(10)
  },
  upcomingTitle: {
    fontWeight: 700
  },
  button: {
    borderRadius: 20,
    width: 280,
    height: 40,
    flexShrink: 0,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(3),
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1.125rem'
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
      marginTop: -theme.spacing()
    }
  },
  serviceLetterButton: {
    width: 283,
    height: 43,
    borderRadius: 20,
    marginTop: theme.spacing(2),
    alignSelf: 'center',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: '1rem'
    }
  }
}))

export default useStyles
