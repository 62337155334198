import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  paymentList: {
    padding: 0
  },
  payment: {
    display: 'list-item',
    listStyleType: 'circle',

    '&.paymentPending': {
      minHeight: 180
    }
  },
  paymentTitle: {
    fontSize: '.975rem',
    '& strong': {
      fontWeight: 600
    }
  },
  formControl: {
    margin: 0
  },
  checkbox: {
    marginLeft: -12,
    '& svg': {
      color: theme.palette.primary.main
    },
    '&.hide': {
      display: 'none'
    },
    '&.paymentDisabled': {
      '& svg': {
        color: '#969696'
      }
    }
  },
  label: {
    fontSize: '.925rem',
    fontWeight: 600
  },
  button: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(3)
  },
  urlPaymentOption: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },

  pendingBindContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  pendingBindText: {
    color: '#FF9D29',
    fontSize: '.925rem',
    fontWeight: 600
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    marginBottom: theme.spacing()
  },
  text: {
    fontWeight: 500,
    fontSize: '.875rem'
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 400
    }
  },
  arrowTooltip: {
    color: theme.palette.tooltip.main
  },
  pendingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    gap: theme.spacing()
  },
  offLineTextLink: {
    fontWeight: 600,
    fontSize: '1rem',
    marginBottom: 5,
    textDecoration: 'underline',
    cursor: 'pointer',
    alignSelf: 'flex-start'
  },
  periodContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minHeight: 60,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(2)
    }
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2)
    }
  },
  labelSwitch: {
    fontWeight: 600,
    fontSize: '.875rem'
  },
  timeContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(3),
    gap: theme.spacing(2)
  },
  paymentConfirmationPeriodcontainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    gap: theme.spacing(),
    flexWrap: 'wrap',

    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      gap: theme.spacing(2),
      width: 'auto'
    },

    '&.hide': {
      display: 'none'
    }
  },
  timeLabel: {
    marginTop: theme.spacing(1.5),
    fontWeight: 600,
    flexShrink: 0
  },
  timeHelperText: {
    fontWeight: 600,
    alignSelf: 'center'
  },
  paymentConfirmationPeriodTooltip: {
    width: 270
  },
  periodHelperText: {
    fontSize: '.8rem',
    fontWeight: 400
  },
  textField: {
    appearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    width: 'auto',
    maxWidth: 150,
    '& input': {
      height: '18px !important',
      fontSize: '.825rem',
      color: theme.palette.primary.main,
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& > div': {
      backgroundColor: '#ffff'
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  }
}))
