import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 950,
      '& #feature-image': {
        height: '44vw'
      }
    }
  },
  cardContainer: {
    paddingLeft: theme.spacing(4)
  },
  slickSlider: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      '& > div': {
        minWidth: '1000px !important'
      }
    },
    '& > div > div': {
      marginLeft: 0
    }
  }
}))
