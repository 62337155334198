import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: 0,
    fontSize: '1rem',
    fontWeight: 600
  },
  container: {
    padding: theme.spacing(4, 2.5),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 7)
    }
  },
  dialogContent: {
    padding: 0
  },
  bodyContainer: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  infoContainer: {
    margin: '0 auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  subTitle: {
    color: '#000000',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 400
    }
  },
  welcomeText: {
    padding: theme.spacing(3, 0),
    color: '#696969',
    alignSelf: 'flex-start',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify'
  },
  acceptButton: {
    alignSelf: 'center',
    width: 210,
    height: 40,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  resgisterText: {
    backgroundColor: theme.palette.tooltip.main,
    padding: 10,
    borderRadius: 10,
    width: '100%',
    fontWeight: 500,
    marginRight: 80
  }
}))
