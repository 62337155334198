import React from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { EditableRichText, EditableSection } from 'shared'
import { STRING_EMPTY } from 'utils/constants'
import * as Yup from 'yup'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './CircuitSection.style'

const CircuitSection = ({ event, canEdit, isMobile, ...sectionProps }) => {
  const classes = useStyles()

  const { hasResults, hasResultsSheetFile } = event

  const handleChangeRichText = (setValues) => (e) =>
    setValues((values) => ({
      ...values,
      circuit: e?.getHTML() || STRING_EMPTY,
      circuitText: e?.getText().trim() || STRING_EMPTY
    }))

  const validationSchema = Yup.object().shape({
    circuitText: Yup.string().nullable().max(3000, 'Se puede ingresar hasta 3000 caracteres')
  })

  return (
    <EditableSection
      entity={event}
      canEdit={canEdit}
      validationSchema={validationSchema}
      {...sectionProps}>
      {({
        activeEdit,
        values,
        errors,
        setValues,
        handleStatusSection,
        setActiveEdit,
        handleSubmit,
        saveLoading,
        isValid,
        enableSection
      }) => (
        <SectionBlock
          title='Circuito'
          className={clsx(
            classes.eventCircuitSection,
            hasResults && hasResultsSheetFile && !isMobile && 'results'
          )}
          hiddenSection={!canEdit && !event.circuit}>
          {(values.circuit || activeEdit) && (
            <EditableRichText
              entity={values}
              setStatusSection={handleStatusSection}
              field='circuit'
              repositoryId={event.id}
              className={classes.editor}
              activeModeEdit={activeEdit}
              error={errors.circuit || errors.circuitText}
              onChange={handleChangeRichText(setValues)}
            />
          )}

          {canEdit && !activeEdit && !values.circuit && (
            <Typography
              className={classes.addEventCircuitLink}
              color='primary'
              variant='h6'
              onClick={() => setActiveEdit(true)}>
              +Añadir circuito
            </Typography>
          )}

          {activeEdit && (
            <Button
              color='primary'
              type='submit'
              variant='contained'
              disabled={saveLoading || !enableSection || !isValid}
              onClick={handleSubmit}
              endIcon={saveLoading && <CircularProgress size={16} color='primary' />}
              className={classes.saveButton}>
              Guardar
            </Button>
          )}
        </SectionBlock>
      )}
    </EditableSection>
  )
}

export default CircuitSection
