import React from 'react'
import { Typography } from '@material-ui/core'
import moment from 'moment'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './EventDateSection.style'

const EventDateSection = ({ event }) => {
  const classes = useStyles()

  const fromDateTime = moment(event.from).format('DD/MM/YYYY HH:mm')
  const toDateTime = moment(event.to).format('DD/MM/YYYY HH:mm')

  return (
    <SectionBlock>
      <div className={classes.container}>
        <Typography className={classes.dateLabel} variant='h6'>
          El evento inicia:&nbsp;
          {fromDateTime}hs
        </Typography>

        <Typography className={classes.dateLabel} variant='h6'>
          El evento finaliza:&nbsp;
          {toDateTime}hs
        </Typography>
      </div>
    </SectionBlock>
  )
}

export default EventDateSection
