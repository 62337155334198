import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import EXAMPLE_FILE_URL from 'assets/docs/ejemplo-faq.pdf'
import { ROUTES } from 'routes'
import {
  AccountSportmetricDialog,
  AddOrganizerRoleDialog,
  CreateOrganizationInfoDialog,
  FileViewerDialog,
  SignInDialog,
  SignUpDialog
} from 'shared'
import { downloadResultsTemplate } from 'state/modules/events'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { getFileExtesion, trackEvent } from 'utils/functions'

import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { QuestionBlock } from '../QuestionBlock'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './FrequentQuestionsSection.style'

const EXAMPLE_FILE_NAME = 'EJEMPLO_FAQ.pdf'

const FrequentQuestionsSection = ({ activeAnimation, endAnimationAction, ...SectionProps }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()

  const { user, userData } = useSelector((state) => state.auth)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)

  const [openAccountSportmetricDialog, setOpenAccountSportmetricDialog] = useState(false)
  const [openSignInDialog, setOpenSignInDialog] = useState(false)
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false)
  const [openAddOrganizerRoleDialog, setOpenAddOrganizerRoleDialog] = useState(false)
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false)

  const [openFileViewerDialog, setOpenFileViewerDialog] = useState(false)

  const handleDownloadResultsTemplate = (e) => {
    e.preventDefault()

    dispatch(downloadResultsTemplate())
  }

  const handleViewExample = (e) => {
    e.preventDefault()

    setOpenFileViewerDialog(true)
  }

  const redirectCreateOrganization = (e) => {
    e.preventDefault()

    if (!user) {
      setOpenAccountSportmetricDialog(true)
      return
    }

    if (!userData?.isOrganizer) {
      setOpenAddOrganizerRoleDialog(true)
      return
    }

    if (organizationsAdminAll.some((x) => x.organization.createdById === user.id)) {
      setOpenCreateOrganizationDialog(true)
      return
    }

    trackEvent(ANALYTICS_EVENTS.ACTION.CREATE_ORGANIZATION)
    history.push(ROUTES.ORGANIZATIONS.CREATE)
  }

  const fileExtension = getFileExtesion(EXAMPLE_FILE_NAME)
  const questions = [
    {
      question: '¿Tiene costo publicar mi evento en Sportmetric?',
      response: (
        <span>
          No, publicar tus eventos no tiene costo. Para poder hacerlo sólo tenés que hacerte una
          cuenta y crear tu organización haciendo click{' '}
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to='#'
            onClick={redirectCreateOrganization}
            className={classes.link}>
            aquí
          </Link>
          .
        </span>
      )
    },
    {
      question: '¿Qué ventajas tiene utilizar el formulario de inscripción de Sportmetric?',
      response:
        'El uso del formulario de inscripción de Sportmetric permite monitorear y administrar a los inscriptos; descargar las planillas de acreditación, seguro y cronometraje generadas automáticamente. Si el evento es pago te permite generar los tickets para el cobro de las inscripciones y llevar el control de los pagos recibidos en un sólo lugar.'
    },
    {
      question: '¿Tiene costo hacer un formulario de inscripción con Sportmetric?',
      response:
        'El formulario de inscripción de Spormetric no tiene costo si el evento es gratuito. La tasa de servicio sólo se aplica si el evento es pago y cuando se procesan ventas a través de los medios asociados a Sportmetric.'
    },
    {
      question: '¿Cuál es el costo de la tasa de servicio?',
      response:
        'La tasa de servicio tienen un costo del 4% + IVA sobre el valor de la inscripción si el medio de pago habilitado es online o sea con Mercado Pago y un costo del 2%+IVA sobre el valor de inscripción para el resto de los medios de pago (efectivo, transferencia, link de pago).'
    },
    {
      question: '¿Puedo utilizar un formulario de inscripción externo?',
      response:
        'Se puede utilizar un formulario de inscripción externo, mediante un enlace que permita acceder a dicho formulario. Sin embargo, el formulario externo no estará integrado con las funcionalidades de la plataforma de Sportmetric, lo que significa que no permitirá hacer seguimiento de los pagos, cambios de estado de inscriptos, entre otras cosas.'
    },
    {
      question: '¿Qué medios de pago utiliza la plataforma de inscripción de Sportmetric?',
      response:
        'La plataforma de inscripción de Sportmetric permite generar tickets de pago on-line a través de la integración dedicada entre Sportmetric y Mercado Pago, o tickets de pago off-line con transferencia, link de pago (propio del organizador) o efectivo en puntos físicos determinados por la organización. Con el pago on-line el sistema verifica y confirma el pago automáticamente (el deportista queda inscripto al completar el proceso).'
    },
    {
      question: '¿Cómo pago la tasa de servicio de Sportmetric?',
      response: (
        <span>
          Si el medio de pago configurado para la cobranza es Mercado Pago, el 4% correspondiente a
          la tasa de servicio de Sportmetric se cobrará en el valor del ticket.
          <br />
          Si el medio de pago configurado para la cobranza de las inscripciones es off-line, al
          cierre de cada mes o al finalizar el evento se enviará al organizador una factura
          correspondiente al 2% de los preinscriptos e inscriptos de ese mes. Si hubiera anulaciones
          entre el mes cobrado y el cierre del evento, se restarán en la factura final.
          <br />
          <Link to='#' className={classes.link} onClick={handleViewExample}>
            Ver ejemplo.
          </Link>
          <FileViewerDialog
            open={openFileViewerDialog}
            title={EXAMPLE_FILE_NAME}
            fileUrl={EXAMPLE_FILE_URL}
            fileExtension={fileExtension}
            onClose={() => setOpenFileViewerDialog(false)}
          />
          <br />
          Recordá que estos porcentajes se pueden trasladar al valor de la inscripción con lo cual
          la tasa de servicio no es asumida por la organización sino que es abonada por el
          deportista.
        </span>
      )
    },
    {
      question:
        '¿Puedo subir los resultados de mi evento si no fue cronometrado con CRONO de Sportmetric?',
      response: (
        <span>
          Sí, puedes cargar un archivo en formato excel / pdf o añadir enlace externo.
          <br />
          En caso de cargar un archivo excel deberás respetar el formato de la&nbsp;
          <Link to='#' onClick={handleDownloadResultsTemplate} className={classes.sportmetricLink}>
            Planilla Modelo
          </Link>
          &nbsp;de resultados para que vincule con el historial deportivo (esto permite que el
          deportista pueda ver los resultados en su perfil).
          <br />
          Si utilizas&nbsp;
          <Link
            target='_blank'
            rel='noopener noreferrer'
            to={ROUTES.LANDING.TIMING}
            className={classes.sportmetricLink}>
            Crono
          </Link>
          &nbsp;de Sportmetric tu archivo excel ya cuenta con el formato indicado para subirlo.
        </span>
      )
    },
    {
      question: '¿Qué ventajas tiene subir los resultados a Sportmetric?',
      response:
        'Permite que los asistentes a tu eventos puedan consultar los resultados una vez finalizado el mismo. Si el deportista tiene cuenta en Sportmetric podrá ver los resultados en su perfil completando su performance histórica.'
    }
  ]

  const handleOpenSignUpDialog = () => {
    setOpenSignUpDialog(true)
    setOpenSignInDialog(false)
  }

  const handleOpenSignInDialog = () => {
    setOpenSignInDialog(true)
    setOpenSignUpDialog(false)
  }

  const handleOnClose = () => {
    setOpenAddOrganizerRoleDialog(false)
    history.push(`${ROUTES.USERS_ACCOUNTS.PROFILE}/${user.id}`)
  }

  return (
    <SectionBlock
      containerClassName={classes.container}
      buttonClassName={classes.endButton}
      {...SectionProps}
      nextSectionId='queriesSection'>
      <NavBar />

      <div className={classes.mainContainer} name='frequentQuestionsSection'>
        <div className={classes.headerContainer}>
          <Typography variant='h2' color='primary'>
            <MarkText
              activeAnimation={activeAnimation}
              className={classes.title}
              endAnimationAction={endAnimationAction}>
              Preguntas Frecuentes
            </MarkText>
          </Typography>
        </div>

        <div className={classes.content}>
          {questions.map((x, index) => (
            <QuestionBlock key={index} {...x} />
          ))}
        </div>
      </div>
      <CreateOrganizationInfoDialog
        open={openCreateOrganizationDialog}
        onClose={() => setOpenCreateOrganizationDialog(false)}
      />
      <AccountSportmetricDialog
        open={openAccountSportmetricDialog}
        onClose={() => setOpenAccountSportmetricDialog(false)}
        setOpenSignInDialog={setOpenSignInDialog}
        setOpenSignUpDialog={setOpenSignUpDialog}
      />
      <SignInDialog
        open={openSignInDialog}
        onClose={() => setOpenSignInDialog(false)}
        handleOpenSignUpDialog={handleOpenSignUpDialog}
      />
      <SignUpDialog
        open={openSignUpDialog}
        onClose={() => setOpenSignUpDialog(false)}
        handleOpenSignInDialog={handleOpenSignInDialog}
      />

      <AddOrganizerRoleDialog open={openAddOrganizerRoleDialog} onClose={handleOnClose} />
    </SectionBlock>
  )
}

export default FrequentQuestionsSection
