import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  eventRulesButton: {
    alignSelf: 'center',
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start'
    }
  },
  uploadRulesDocumentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 80,
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  eventRulesUploadButton: {
    width: 200,
    height: 48,
    borderRadius: 10
  },
  eventinputLink: {
    paddingBottom: theme.spacing(2),
    width: '100%',
    '& input': {
      height: 19,
      color: '#4285F4',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    }
  },
  eventRulesSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  eventLink: {
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
    fontWeight: 600,
    color: 'rgba(66, 133, 244, 1)',
    overflow: 'hidden',
    textOverflow: 'Ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      width: 370
    }
  },
  rulesDocumentHelperText: {
    margin: theme.spacing(2, 0),
    fontSize: '.875rem'
  },
  addEventRulesLink: {
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem'
  }
}))
