import React from 'react'
import { Typography } from '@material-ui/core'

import { useStyles } from './SectionBlock.style'

const SectionBlock = ({ title, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <Typography variant='h3' className={classes.title}>
        {title}
      </Typography>

      {children}
    </div>
  )
}

export default SectionBlock
