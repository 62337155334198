import React from 'react'
import { SearchWidget } from 'pages/HomePage/components'
import { Footer, MainLayout, NavBar, PageContainer, TermsAndConditionsContent } from 'shared'

import { useStyles } from './TermsAndConditionsPage.style'

const TermsAndConditionsPage = () => {
  const classes = useStyles()

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer customContainerClassName={classes.pageContainer}>
        <div className={classes.container}>
          <TermsAndConditionsContent />
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default TermsAndConditionsPage
