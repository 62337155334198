import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IconButton, Typography } from '@material-ui/core'
import { DeleteOutline as DeleteOutlineIcon, Search as SearchIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ROUTES } from 'routes'

import { LoadingPlaceholder } from '../LoadingPlaceholder'

import useStyles from './FilterWidget.style'

const FilterWidget = ({
  searchParams,
  onSearchParamsChanged,
  searchMetadata,
  searchMetadataPending
}) => {
  const [searchInputText, setSearchInputText] = useState(searchParams.searchText)
  const loadTags = () => {
    const tags = [
      { id: 'disciplineId', list: 'disciplineItems' },
      { id: 'organizationId', list: 'organizationItems' },
      { id: 'areaLevel1Id', list: 'areaLevel1Items' }
    ].reduce((acc, el) => {
      const { id, list } = el
      if (searchParams[id]) {
        acc.push({
          id: searchParams[id],
          nameList: list,
          field: id
        })
      }
      return acc
    }, [])
    return tags
  }

  const [filterTags, setFilterTags] = useState(loadTags())
  const classes = useStyles()

  useEffect(() => {
    const newSearchParams = {
      searchText: searchParams.searchText,
      page: searchParams.page || 1
    }

    for (let i = 0; i < filterTags.length; i++) {
      const tag = filterTags[i]
      newSearchParams[tag.field] = tag.id
    }
    setSearchInputText(searchParams.searchText)
    onSearchParamsChanged(newSearchParams)
  }, [filterTags, searchParams])

  const addFilterTag = (item, field, nameList) => {
    if (searchParams.page !== 1) searchParams.page = 1
    setFilterTags((state) => {
      const list = state.filter((e) => e.field !== field)
      return [
        ...list,
        {
          ...item,
          field,
          nameList
        }
      ]
    })
  }

  const load = () => {
    const tags = { ...searchMetadata }
    for (let i = 0; i < filterTags.length; i++) {
      const tag = filterTags[i]
      if (!tag.name) {
        const searchTag = searchMetadata[tag.nameList].find((x) => x.id === tag.id)
        tag.name = searchTag && searchTag.name
      }

      tags[tag.nameList] = tags[tag.nameList]?.filter((x) => x.id !== tag.id)
    }

    return tags
  }

  const removeFilterTag = (item) => {
    if (searchParams.page !== 1) searchParams.page = 1
    const remainFilters = filterTags.filter((x) => x.id !== item.id)
    setFilterTags([...remainFilters])
  }

  const searchFilter = !!searchMetadata && load()
  const history = useHistory()
  const submitUserAccountsSearch = (e) => {
    e.preventDefault()
    onSearchParamsChanged({ ...searchParams, searchText: searchInputText, page: 1 })

    history.push(ROUTES.USERS_ACCOUNTS.SEARCH)
  }
  return (
    <div className={classes.container}>
      <Typography variant='h4' color='primary' className={classes.title}>
        Tu búsqueda
      </Typography>
      <form onSubmit={submitUserAccountsSearch} className={classes.form}>
        <div className={classes.searchSection}>
          <input
            className={clsx(classes.inputField, classes.searchField)}
            style={{ border: 0 }}
            type='text'
            autoComplete='off'
            value={searchInputText}
            onChange={(e) => {
              setSearchInputText(e.target.value)
            }}
          />
          <IconButton type='submit' className={classes.searchButton}>
            <SearchIcon />
          </IconButton>
        </div>
      </form>
      <div className={classes.filterTagSection}>
        {filterTags.map((x) => (
          <div className={classes.filterTag} key={x.id}>
            {x.name && <Typography color='primary'>{x.name}</Typography>}
            <IconButton onClick={() => removeFilterTag(x)}>
              <DeleteOutlineIcon className={classes.closeTag} />
            </IconButton>
          </div>
        ))}
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={`${classes.title} underlined`}>
          Disciplina
        </Typography>
        {searchMetadataPending ? (
          <LoadingPlaceholder />
        ) : (
          <ul className={classes.filterList}>
            {searchFilter &&
              searchFilter.disciplineItems.map((x) => (
                <li key={x.id}>
                  <button
                    title={x.name}
                    onClick={() => addFilterTag(x, 'disciplineId', 'disciplineItems')}>
                    {x.name}
                  </button>
                  <span>&nbsp;({x.userAccountsCount})</span>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className={classes.filterSection}>
        <Typography variant='h4' color='primary' className={`${classes.title} underlined`}>
          Ubicación
        </Typography>
        {searchMetadataPending ? (
          <LoadingPlaceholder />
        ) : (
          <ul className={classes.filterList}>
            {searchFilter &&
              searchFilter.areaLevel1Items.map((x) => (
                <li key={x.id}>
                  <button
                    title={x.name}
                    onClick={() => addFilterTag(x, 'areaLevel1Id', 'areaLevel1Items')}>
                    {x.name}
                  </button>
                  <span>&nbsp;({x.userAccountsCount})</span>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FilterWidget
