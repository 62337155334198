import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  title: {
    fontWeight: 600,
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  mainContainer: {
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      padding: theme.spacing(0, 4),
      margin: '0 auto'
    }
  }
}))
