import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    backgroundColor: '#ffff',
    padding: theme.spacing(4, 3),
    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4)
    }
  },
  backButton: {
    margin: '10px auto',
    padding: 15,
    alignSelf: 'center',
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    }
  }
}))
