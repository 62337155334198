import React from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core'
import { ActionDialog } from 'pages/Events/InscriptionPage/components'
import { MultiFormatSelector } from 'shared'
import * as Yup from 'yup'

import { useStyles } from './ActionCustomFieldDialog.style'

const ActionCustomFieldDialog = ({
  open,
  onClose,
  action,
  field,
  title,
  actionName,
  className,
  asyncError
}) => {
  const classes = useStyles()

  const values = {
    name: '',
    isRequired: 'Si',
    isPrivate: false,
    type: '',
    allowMultiselect: false,
    helperText: '',
    ...field,
    options: field?.options?.length ? field.options : []
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre del campo es requerido')
      .max(100, 'El nombre del campo no puede tener más de 100 caracteres')
      .test(
        'unique',
        'El nombre del campo ya existe',
        (value) => !asyncError || value !== asyncError.name
      ),
    type: Yup.string().required('El tipo del campo es requerido'),
    options: Yup.array().when('type', {
      is: (type) => type === 'List',
      then: Yup.array()
        .test(
          'options',
          'Debe ingresar al menos una opción',
          (options) => !!options.filter((e) => !!e.name?.trim()).length
        )
        .of(
          Yup.object().shape({
            name: Yup.string().max(100, 'La opción no puede tener más de 100 caracteres')
          })
        )
    })
  })

  return (
    <ActionDialog
      open={open}
      className={className}
      values={values}
      validationSchema={validationSchema}
      action={action}
      onClose={onClose}
      actionName={actionName}
      title={title}>
      {({ handleChange, handleBlur, errors, touched, values, setFieldValue, isSubmitting }) => (
        <>
          {actionName === 'Eliminar' ? (
            <div className={classes.deleteDialogBody}>
              <Typography variant='h6' align='center' color='primary'>
                ¿Está seguro que desea eliminar el campo?
              </Typography>
              <Typography align='center'>
                En caso que si, todas las configuraciones que realizó hasta ahora sobre el campo se
                elminarán permanentemente.
              </Typography>
            </div>
          ) : (
            <>
              <div className={classes.formGroup}>
                <Typography color='primary' variant='h6'>
                  Nombre *
                </Typography>
                <TextField
                  variant='outlined'
                  size='small'
                  name='name'
                  value={values.name}
                  onBlur={handleBlur}
                  autoComplete='off'
                  helperText={errors.name}
                  error={touched.name && !!errors.name}
                  onChange={handleChange}
                  placeholder='Escriba un nombre para su campo'
                  fullWidth
                />
              </div>
              <div className={classes.formGroup}>
                <MultiFormatSelector
                  entity={values}
                  errors={errors}
                  touched={touched}
                  onChange={setFieldValue}
                  handleBlur={handleBlur}
                />
              </div>
              <div className={classes.checksContainer}>
                <div className={classes.formGroup}>
                  <Typography variant='h6' color='primary'>
                    ¿Este campo es obligatorio?
                  </Typography>
                  <RadioGroup
                    row
                    aria-label='required'
                    name='isRequired'
                    value={values.isRequired}
                    onChange={handleChange}>
                    <FormControlLabel value='Si' control={<Radio color='primary' />} label='Si' />
                    <FormControlLabel value='No' control={<Radio color='primary' />} label='No' />
                  </RadioGroup>
                </div>
                <div className={classes.formGroup}>
                  <FormControlLabel
                    className={classes.checkContainer}
                    label='Solo visible para el administrador'
                    control={
                      <Checkbox
                        checked={values.isPrivate}
                        onChange={handleChange}
                        name='isPrivate'
                        color='primary'
                      />
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className={classes.buttonContainer}>
            <Button
              color='primary'
              variant='contained'
              type='submit'
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={16} color='primary' />}
              className={classes.button}>
              {actionName}
            </Button>
          </div>
        </>
      )}
    </ActionDialog>
  )
}

export default ActionCustomFieldDialog
