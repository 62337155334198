import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  payment: {
    display: 'list-item',
    listStyleType: 'circle',

    '&.paymentPending': {
      minHeight: 180
    }
  },
  paymentTitle: {
    fontSize: '.975rem',
    '& strong': {
      fontWeight: 600
    }
  },
  formControl: {
    margin: 0
  },
  checkbox: {
    marginLeft: -12,
    '& svg': {
      color: theme.palette.primary.main
    },
    '&.hide': {
      display: 'none'
    },
    '&.paymentDisabled': {
      '& svg': {
        color: '#969696'
      }
    }
  },
  label: {
    fontSize: '.925rem',
    fontWeight: 600
  },
  button: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center',
    padding: theme.spacing(2, 0)
  },
  pendingBindContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  pendingBindText: {
    color: '#FF9D29',
    fontSize: '.925rem',
    fontWeight: 600
  },
  copyButton: {
    height: 35,
    width: 35
  },
  copyIcon: {
    height: 20,
    width: 20
  },
  copyText: {
    fontWeight: 600,
    fontSize: '.825rem'
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    marginBottom: theme.spacing()
  },
  text: {
    fontWeight: 500,
    fontSize: '.875rem'
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('sm')]: {
      width: 300,
      margin: theme.spacing(0, 2)
    }
  },
  arrowTooltip: {
    color: theme.palette.tooltip.main
  },
  pendingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    gap: theme.spacing()
  }
}))
