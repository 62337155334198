import { logEvent } from 'firebase/analytics'
import moment from 'moment'
import { CountriesProxy, DisciplinesProxy, OrganizationsProxy } from 'services'
import { analytics } from 'utils/firebase'

/**
 * @param  {string} [url=window.location] url
 */
export function getAllUrlParams(url) {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // in case params look like: list[]=thing1&list[]=thing2
      let paramNum = null
      const paramName = a[0].replace(/\[\d*\]/, (v) => {
        paramNum = v.slice(1, -1)
        return ''
      })

      // set parameter value (use 'true' if empty)
      const paramValue = typeof a[1] === 'undefined' ? true : a[1]

      // if parameter name already exists
      if (obj[paramName]) {
        // convert value to array (if still string)
        if (typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]]
        }
        // if no array index number specified...
        if (paramNum === null) {
          // put the value on the end of the array
          obj[paramName].push(paramValue)
        } else {
          // if array index number specified...
          // put the value at that index number
          obj[paramName][paramNum] = paramValue
        }
      } else {
        // if param name doesn't exist yet, set it
        obj[paramName] = paramValue
      }
    }
  }

  return obj
}

export function renameObjPropNames(obj, oldName, newName) {
  // eslint-disable-next-line no-prototype-builtins
  if (!obj.hasOwnProperty(oldName)) {
    return false
  }

  obj[newName] = obj[oldName]
  delete obj[oldName]
  return true
}

/**
 * @param {userRole} - role of current user
 * @param {...roles} - gets Roles from arguments
 * @returns true if current user approach with any of
 */

export function oneOfRoles(userRole, ...roles) {
  return roles.some((role) => role === userRole)
}

export const run = (element, acceleration, offset) => {
  let timer
  let current = window.pageYOffset
  let to

  if (typeof element === 'object') {
    to = element.getBoundingClientRect().top
    if (current < to) {
      to = offset ? to - offset : to + element.getBoundingClientRect().height
    } else {
      to = to - 30
    }
  } else {
    to = 0
  }
  return function (animation) {
    // boolean
    let point
    let clear
    if (animation) return window.scrollTo(0, to) // without animation;

    if (current < to) {
      point = acceleration || 5
      clear = function (from, to) {
        if (from > to) {
          clearTimeout(timer)
        }
      }
    } else {
      point = -acceleration || -5
      clear = function (from, to) {
        if (from < to) {
          clearTimeout(timer)
        }
      }
    }

    timer = setInterval(function () {
      current += point
      window.scrollTo(0, current)
      clear(current, to)
    }, 5)
  }
}

export const defaultValue = (obj, property, value = '') => {
  if (!obj || !obj[property]) {
    return value
  }

  return obj[property]
}

// TODO: Rename to getResponsiveImageUrl(). The function will retrieve the corresponding image size according to the viewport size
export const getProfileImageUrl = (entity, size = 0, noImage) => {
  const imageUrl =
    entity && entity.profileImages && entity.profileImages.length > 0
      ? entity.profileImages[size]
      : null
  return imageUrl || noImage
}

// TODO: Rename to getResponsiveImageUrl(). The function will retrieve the corresponding image size according to the viewport size
export const getImageUrl = (imageArray, size = 0, noImage) => {
  const imageUrl = imageArray && imageArray.length > 0 ? imageArray[size] : null
  return imageUrl || noImage
}

export const isArrayNullOrEmpty = (arr) => {
  return !Array.isArray(arr) || arr.length === 0
}

export const isStringNullOrEmpty = (str) => {
  return !str || str === ''
}

export const filterAndSortCategories = (distance, gender, years, categories) => {
  const categoriesSorted = Array.isArray(categories.items)
    ? categories.items
        .map((i) => ({
          ...i,
          state:
            ((gender && i.eventCategory.gender !== 'Other' && i.eventCategory.gender !== gender) ||
              (years &&
                !i.eventCategory.ageNoLimit &&
                (i.eventCategory.ageRangeMin > years || i.eventCategory.ageRangeMax < years))) &&
            'error'
        }))
        .filter((i) => distance === i.eventDistanceId && (!i.validateGender || i.state !== 'error'))
        .filter((i) => !i.validateAge || i.state !== 'error')
        .sort((compare, comparison) => {
          const { name: compareName } = compare.eventCategory
          const { name: comparisonName } = comparison.eventCategory
          return compareName.localeCompare(comparisonName)
        })
    : []

  return [
    ...categoriesSorted.filter((c) => !c.state),
    ...categoriesSorted.filter((c) => c.state === 'error')
  ]
}

export const searchDisciplines = async (inputValue) => {
  const OTHER_OPTION = { label: 'Otras', value: 'Other' }
  const proxy = new DisciplinesProxy()
  const data = await proxy.getSearch(inputValue, 1, 10)
  if (Array.isArray(data.results)) {
    return data.results
      .map((d, index) => ({
        value: d.id,
        label: d.name,
        order: d.name !== OTHER_OPTION.label ? index : data.results.length
      }))
      .sort((a, b) => a.order - b.order)
  }
}

export const searchOrganizations = async (searchText) => {
  if (searchText.length < 3) return []

  const proxy = new OrganizationsProxy()
  const data = await proxy.getSearch({ searchText }, 1, 10)
  if (Array.isArray(data.results)) {
    return data.results.map((o) => ({ value: o.id, label: o.name, ...o }))
  }
}

export const getCountries = (empty) => async (inputValue) => {
  const proxy = new CountriesProxy()
  const data = await proxy.getCountries()
  if (Array.isArray(data)) {
    const countriesOptions = data.map((d) => ({ value: d.id, label: d.name }))

    if (empty) {
      countriesOptions.unshift(empty)
    }

    return countriesOptions.filter((d) =>
      d.label?.toLowerCase().includes(inputValue?.toLowerCase())
    )
  }
}

export const filterProvinces = (options) => async (inputValue) => {
  if (Array.isArray(options)) {
    return options.filter((d) => d.label.toLowerCase().includes(inputValue.toLowerCase()))
  }
}

export const filterCities = (options) => async (inputValue) => {
  if (Array.isArray(options)) {
    return options.filter((d) => d.label.toLowerCase().includes(inputValue.toLowerCase()))
  }
}

export const crc16 = (buffer) => {
  let crc = 0xffff
  let odd
  for (let i = 0; i < buffer.length; i++) {
    crc = crc ^ buffer[i]

    for (let j = 0; j < 8; j++) {
      odd = crc & 0x0001
      crc = crc >> 1
      if (odd) {
        crc = crc ^ 0xa001
      }
    }
  }

  return crc.toString().slice(0, 3)
}

export const normalizeUrl = (value) => {
  let normalizedUrl = value
  if (
    !!normalizedUrl?.trim() &&
    normalizedUrl.indexOf('http://') === -1 &&
    normalizedUrl.indexOf('https://') === -1
  ) {
    normalizedUrl = `https://${normalizedUrl}`
  }

  return normalizedUrl?.replaceAll(' ', '')
}

export const dataUrlToFile = async (dataUrl, fileName, contentType) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return new File([blob], fileName, { type: contentType })
}

export const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

const DATE_FORMATS = ['YYYY-MM-DD', 'DD MMM YY', 'DD MMM', 'DD']

export const getDateEventFormat = (fromDate, toDate) => {
  const fromDateFormat = fromDate.format('YYYY-MM-DD')
  const toDateFormat = toDate.format('YYYY-MM-DD')

  const toDateResult = toDate.format(DATE_FORMATS[1]).replace('.', '')

  if (moment(fromDateFormat).isSame(toDateFormat))
    return fromDate.format(DATE_FORMATS[1]).replace('.', '')

  if (moment(fromDateFormat).year() !== moment(toDateFormat).year())
    return `${fromDate.format(DATE_FORMATS[1])} - ${toDateResult}`.replace('.', '')

  if (moment(fromDateFormat).month() !== moment(toDateFormat).month())
    return `${fromDate.format(DATE_FORMATS[2])} - ${toDateResult}`.replace('.', '')
  else return `${fromDate.format(DATE_FORMATS[3])}/${toDateResult}`.replace('.', '')
}

export const getFileName = (fileUrl = '', key = '') =>
  fileUrl.split(`${key}.`)[1]?.split('.').slice(1).join('.')

export const getFileExtesion = (fileUrl = '') => fileUrl.split('.').pop()

export const getFormatDecimal = (number, fractionDigits = 0) =>
  !Number.isNaN(number) ? Number(number).toFixed(fractionDigits) : 0

export const trackEvent = (eventName) => {
  try {
    logEvent(analytics, eventName)
  } catch (error) {
    console.log(error)
  }
}

export const getFeeAmount = (total, percentageWithoutVat) => {
  const result = ((total * calculatePercentage(percentageWithoutVat)) / 100).toFixed(4)
  return result
}

export const calculatePercentage = (percentage) => {
  const result = ((percentage * 21) / 100 + percentage).toFixed(4)
  return Number(result)
}

export const validateCuit = (cuit) => {
  if (isStringNullOrEmpty(cuit)) return

  if (cuit.length !== 11) return

  const FIRST_COMBINE_DIGITS = ['20', '23', '24', '27', '30', '33', '34']

  const firstDigits = cuit.substring(0, 2)

  if (!cuit.match(/^[0-9]+$/i)) return

  if (!FIRST_COMBINE_DIGITS.includes(firstDigits)) return

  const count =
    Number(cuit[0]) * 5 +
    Number(cuit[1]) * 4 +
    Number(cuit[2]) * 3 +
    Number(cuit[3]) * 2 +
    Number(cuit[4]) * 7 +
    Number(cuit[5]) * 6 +
    Number(cuit[6]) * 5 +
    Number(cuit[7]) * 4 +
    Number(cuit[8]) * 3 +
    Number(cuit[9]) * 2 +
    Number(cuit[10]) * 1

  const division = count / 11
  if (division !== Math.floor(division)) return

  return true
}
