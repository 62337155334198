import { makeStyles } from '@material-ui/core'
import DragIcon from 'assets/icons/drag-icon.svg'
import OrientIcon from 'assets/icons/orient-icon.svg'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    width: '100%',
    padding: 0,
    paddingBottom: theme.spacing(3),
    overflow: 'hidden'
  },
  cropperContainer: {
    backgroundColor: '#000000'
  },
  cropper: {
    width: '100%',
    height: '49vw',
    '& > div > div > span': {
      backgroundColor: '#ffff'
    },
    '& .cropper-view-box': {
      outline: '1px solid #ffff',
      outlineColor: '#ffff'
    },
    '& .cropper-center': {
      '&:before': {
        content: `url(${OrientIcon})`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
          content: `url(${DragIcon})`
        }
      },
      '&:after': {
        content: 'none'
      }
    },
    '&.orient-icon': {
      '& .cropper-center': {
        '&:before': {
          content: `url(${OrientIcon})`
        }
      }
    },

    [theme.breakpoints.up('md')]: {
      height: 403
    }
  },
  actionButtonContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    marginTop: theme.spacing(3),
    '& button': {
      padding: theme.spacing(1, 2)
    },
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(3),
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  deleteButtonContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start'
    }
  },
  editButtonContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignSelf: 'flex-end'
    }
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      marginRight: theme.spacing(2),
      marginTop: 0
    }
  },
  saveButton: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 170
    }
  },
  title: {
    paddingLeft: theme.spacing(3),
    textAlign: 'left'
  }
}))
