import React, { forwardRef } from 'react'
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import clsx from 'clsx'

import { useStyles } from './TermsAndConditionsDialog.style'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const TermsAndConditionsDialog = ({
  handleClose,
  open,
  title,
  content: Content,
  color = 'primary'
}) => {
  const scroll = 'paper'
  const classes = useStyles()
  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      TransitionComponent={Transition}
      scroll={scroll}>
      <AppBar position='relative' color={color}>
        <Toolbar>
          <Typography variant='h6' className={clsx(classes.title, color)}>
            {title}
          </Typography>
          <IconButton edge='end' color={color} onClick={handleClose} aria-label='close'>
            <CloseIcon className={clsx(classes.icon, color)} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          <Content />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default TermsAndConditionsDialog
