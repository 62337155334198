import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700
    }
  },
  contentDialog: {
    [theme.breakpoints.up('md')]: {
      width: '90%'
    }
  },
  initialMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    minHeight: 220,
    marginBottom: theme.spacing(4)
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      flexShrink: 0
    },
    backgroundColor: '#D9D9D9',
    padding: theme.spacing(1, 2.5)
  },
  alertParagraph: {
    color: '#FF4B4B',
    fontWeight: 500,
    alignItems: 'center',
    fontSize: '1rem',
    marginLeft: theme.spacing(1)
  },
  welcomeMessage: {
    fontWeight: 500,
    fontSize: '1rem',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-wrap',
    textAlign: 'justify',
    '& a': {
      fontWeight: 500,
      color: '#4285F4'
    }
  },
  button: {
    width: 250,
    height: 46,
    alignSelf: 'center',
    '& span': {
      fontSize: '1rem'
    }
  }
}))
