import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { AccessTime as AccessTimeIcon } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import { Formik } from 'formik'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { CountriesProxy, OrganizationsProxy } from 'services'
import { Footer, LocationPicker, MainLayout, NavBar, PageContainer, StyledSwitch } from 'shared'
import { setActiveRole, setAdministratorAccount } from 'state/modules/app'
import { create } from 'state/modules/events'
import { loadOrganizationsAdminAll } from 'state/modules/userAccounts'
import { MAX_DATE, MIN_DATE, STRING_EMPTY, USER_ROLE } from 'utils/constants'
import { getCountries, searchDisciplines } from 'utils/functions'
import { useQuery } from 'utils/hooks'
import { showSnackbarError, showSnackbarSuccess } from 'utils/snackbar'
import * as Yup from 'yup'

import useStyles from './CreatePage.style'

const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }

const CreatePage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()

  const organizationIdOrHandle = query.get('organizationIdOrHandle')

  const { user, userData } = useSelector((state) => state.auth)
  const { administratorAccount } = useSelector((state) => state.app)
  const { pending } = useSelector((state) => state.events)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)

  const [countries, setCountries] = useState([])
  const [disciplines, setDisciplines] = useState([])
  const [provinces, setProvinces] = useState([])
  const [organizationsPending, setOrganizationsPending] = useState(false)
  const [organizationOptions, setOrganizationOptions] = useState([])

  const MAX_LENGTH_DESCRIPTION = 4000
  const MIN_LENGTH_DESCRIPTION = 100
  const MAX_ALLOWED_DAYS = 7

  const empty = { value: STRING_EMPTY, label: '--Seleccione--' }

  useEffect(() => {
    // TODO: persistir redux user -> que no se pierda al hacer f5
    if (user) {
      dispatch(loadOrganizationsAdminAll())
    }
  }, [dispatch, user])

  useEffect(() => {
    if (userData && userData.isOrganizer) {
      dispatch(setActiveRole(USER_ROLE.ORGANIZER))
    }
  }, [userData])

  useEffect(() => {
    fetchCountries()
    fetchDisciplines()

    onCountryChange()(ARGENTINA_COUNTRY.value)
  }, [])

  useEffect(() => {
    if (!!organizationIdOrHandle && organizationsAdminAll.length) {
      dispatch(
        setAdministratorAccount({
          ...administratorAccount,
          organization: organizationsAdminAll
            .map((x) => x.organization)
            .find((x) => x.id === organizationIdOrHandle || x.handle === organizationIdOrHandle)
        })
      )
    }
  }, [organizationIdOrHandle, organizationsAdminAll])

  const onDisciplinesChange = (setFieldValue) => (options) =>
    setFieldValue('disciplinesSelect', options)

  const fetchOrganizations = async (searchText = '', setFieldValue, values) => {
    setFieldValue('usernameOrEmail', searchText)

    if (!searchText || searchText.length < 3) {
      setOrganizationOptions([])
      return
    }

    try {
      setOrganizationsPending(true)

      const proxy = new OrganizationsProxy()
      const data = await proxy.getEventOrganizationSuggestions(searchText, 1, 20)

      setOrganizationOptions([...values.organizationsSelect, ...data.results])

      return data.results
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setOrganizationsPending(false)
    }
  }

  const debounceFetchOrganizations = (inputValue, setFieldValue, values) =>
    fetchOrganizations(inputValue, setFieldValue, values)

  const debounceOnChange = debounce(debounceFetchOrganizations, 700)

  const onLocationChanged = (setFieldValue) => (coord) => {
    setFieldValue('latitude', coord.lat)
    setFieldValue('longitude', coord.lng)
    setFieldValue('location', `${coord.lat} | ${coord.lng}`)
  }

  const fetchCountries = async () => setCountries(await getCountries()(''))

  const fetchDisciplines = async () => setDisciplines(await searchDisciplines(''))

  const onConfirm = (setFieldValue) => async (coord) => {
    const proxy = new CountriesProxy()
    const res = await proxy.getAddress(coord.lat, coord.lng)
    setFieldValue('address', res.address || '')
  }

  const onDateTimeChanged = (setValues, field, timeField, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,
      [field]: value ? value.format() : null,
      [timeField]:
        !values[timeField] && value && moment(value).isValid() ? defaultTime : values[timeField]
    }))

  const onTimeChanged = (setFieldValue, field) => (value) =>
    setFieldValue(field, value ? value.format() : null)

  const onOrganizationsChange = (setFieldValue) => (options) =>
    setFieldValue('organizationsSelect', options)

  const handleChangeUseOrganizationPhoneCheck = (setValues) => (e) =>
    setValues((values) => {
      const checkValue = e.target.checked

      return {
        ...values,
        useOrganizationPhone: checkValue,
        phone: checkValue ? administratorAccount.organization?.phone || values.phone : values.phone,
        hasWhatsApp: checkValue || values.hasWhatsApp
      }
    })

  const onSubmit = async (values) => {
    try {
      const {
        name,
        latitude,
        longitude,
        address,
        fromDate,
        toDate,
        disciplinesSelect,
        countryId,
        areaLevel1Id,
        organizationsSelect,
        description,
        fromTime,
        toTime,
        enableAddOrganization,
        phone,
        hasWhatsApp
      } = values

      const eventData = {
        name,
        latitude,
        longitude,
        address,
        from: moment(
          `${moment(fromDate).format('YYYY-MM-DD')} ${moment(fromTime).format('HH:mm')}`
        ).format(), // `${fromDate}T${fromTime}:00.000${values.timezone}`,
        to: moment(
          `${moment(toDate).format('YYYY-MM-DD')} ${moment(toTime).format('HH:mm')}`
        ).format(), // to: showToDate ? `${toDate}T${toTime}:00.000${values.timezone}` : null,
        disciplines: disciplinesSelect.map((d) => ({ id: d.value })),
        organizationId: administratorAccount.organization?.id,
        countryId,
        areaLevel1Id,
        organizations: enableAddOrganization
          ? organizationsSelect.map((organization) => ({ organization }))
          : [],
        description: description.trim(),
        phone,
        hasWhatsApp,
        rulesUrl: STRING_EMPTY
      }

      const newEvent = await dispatch(create(eventData))

      history.push(`${ROUTES.EVENTS.PROFILE}/${newEvent.slugUrl}?eventCreated=true`)
      showSnackbarSuccess('¡Evento creado con éxito!')
    } catch (error) {
      showSnackbarError(error)
    }
  }

  const values = {
    fromDate: null,
    fromTime: null,
    toDate: null,
    toTime: null,
    name: STRING_EMPTY,
    latitude: 0.0,
    longitude: 0.0,
    location: STRING_EMPTY,
    address: STRING_EMPTY,
    description: STRING_EMPTY,
    countryId: ARGENTINA_COUNTRY.value,
    areaLevel1Id: STRING_EMPTY,
    disciplinesSelect: [],
    organizationsSelect: [],
    enableAddOrganization: false,
    undefinedLocation: false,
    hasWhatsApp: true,
    phone: administratorAccount.organization?.phone || STRING_EMPTY,
    useOrganizationPhone:
      !!administratorAccount.organization && !!administratorAccount.organization.phone
  }

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string()
        .max(100, 'El nombre del evento tiene un máximo de 100 caracteres')
        .required('Ingrese el nombre del evento')
        .trim(),
      fromDate: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de inicio del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .when(['toDate', 'toTime', 'fromTime'], {
          is: (toDate, toTime, fromTime) =>
            !!toDate &&
            moment(toDate).isValid() &&
            !!toTime &&
            moment(toTime).isValid() &&
            !!fromTime &&
            moment(fromTime).isValid(),
          then: (schema) =>
            schema
              .test(
                'from-max',
                'La fecha y hora de inicio debe ser menor a la fecha de fin del evento',
                (value, { parent }) =>
                  moment(
                    `${moment(value).format('YYYY-MM-DD')} ${moment(parent.fromTime).format(
                      'HH:mm'
                    )}`
                  ).isBefore(
                    moment(
                      `${moment(parent.toDate).format('YYYY-MM-DD')} ${moment(parent.toTime).format(
                        'HH:mm'
                      )}`
                    )
                  )
              )
              .test(
                'to-duration-max',
                'La duración del evento no puede exceder los 7 días',
                (value, { parent }) =>
                  moment(moment(parent.toDate).format('YYYY-MM-DD')).diff(
                    moment(value).format('YYYY-MM-DD'),
                    'days'
                  ) < MAX_ALLOWED_DAYS
              )
        }),
      fromTime: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de inicio del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid()),
      toDate: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de fin del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid())
        .test('range-date', 'Fecha/hora inválida', (value) =>
          moment(value).isBetween(moment(MIN_DATE), moment(MAX_DATE), 'YYYY-MM-DD')
        )
        .when(['fromDate', 'fromTime', 'toTime'], {
          is: (fromDate, fromTime, toTime) =>
            !!toTime &&
            moment(toTime).isValid() &&
            !!fromDate &&
            moment(fromDate).isValid() &&
            !!fromTime &&
            moment(fromTime).isValid(),
          then: (schema) =>
            schema
              .test(
                'to-min',
                'La fecha y hora de cierre debe ser mayor a la fecha de inicio del evento',
                (value, { parent }) =>
                  moment(
                    `${moment(value).format('YYYY-MM-DD')} ${moment(parent.toTime).format('HH:mm')}`
                  ).isAfter(
                    moment(
                      `${moment(parent.fromDate).format('YYYY-MM-DD')} ${moment(
                        parent.fromTime
                      ).format('HH:mm')}`
                    )
                  )
              )
              .test(
                'from-duration-max',
                'La duración del evento no puede exceder los 7 días',
                (value, { parent }) =>
                  moment(moment(value).format('YYYY-MM-DD')).diff(
                    moment(parent.fromDate).format('YYYY-MM-DD'),
                    'days'
                  ) < MAX_ALLOWED_DAYS
              )
        }),
      toTime: Yup.mixed()
        .nullable()
        .required('Ingrese una fecha/hora de fin del evento')
        .test('valid-date', 'Formato de fecha/hora inválida', (value) => moment(value).isValid()),
      disciplinesSelect: Yup.array()
        .nullable()
        .test(
          'test-disciplines',
          'Debes seleccionar al menos una disciplina',
          (value) => !!value?.length
        ),
      location: Yup.string().when('undefinedLocation', {
        is: (undefinedLocation) => !undefinedLocation,
        then: (schema) =>
          schema.test('test-location', 'Debes seleccionar una ubicación', (value) => !!value)
      }),
      areaLevel1Id: Yup.string()
        .nullable()
        .when('countryId', {
          is: (countryId) => countryId === ARGENTINA_COUNTRY.value,
          then: (schema) => schema.required('Debe seleccionar una provincia / Región / Estado')
        }),
      address: Yup.string()
        .nullable()
        .when('undefinedLocation', {
          is: (undefinedLocation) => !undefinedLocation,
          then: (schema) => schema.required('Debe ingresar la dirección exacta del evento').trim()
        }),
      description: Yup.string()
        .min(MIN_LENGTH_DESCRIPTION, 'La descripción debe tener al menos 100 caracteres')
        .max(MAX_LENGTH_DESCRIPTION, 'La descripción tiene un máximo de 4000 caracteres')
        .test('test-description', 'Ingrese una descripción', (value) => !!value && !!value.trim())
        .trim(),
      phone: Yup.string()
        .required('Debe ingresar un número de teléfono')
        .nullable()
        .min(5, 'El número de teléfono debe tener al menos 5 caracteres')
        .max(20, 'El número de teléfono no puede tener más de 20 caracteres')
        .matches(/^[+]*(?:[0-9]?){5,20}[0-9]$/, 'El número de telefono ingresado no es válido')
    },
    ['fromDate', 'toDate']
  )

  const handleChangeUndefinedLocationCheck = (setValues) => (e) => {
    setValues((values) => {
      const checkboxValue = e.target.checked
      const locationValues = checkboxValue && {
        location: '',
        latitude: 0,
        longitude: 0,
        address: ''
      }

      return { ...values, ...locationValues, undefinedLocation: checkboxValue }
    })
  }

  const onCountryChange = (setFieldValue) => async (option) => {
    try {
      if (setFieldValue) {
        setFieldValue('countryId', option)
        setFieldValue('areaLevel1Id', '', false)
      }

      setProvinces([])

      const proxy = new CountriesProxy()
      const data = await proxy.getAreaLevel1(option)
      if (Array.isArray(data) && !!data.length) {
        const provincesData = data.map((d) => ({ value: d.id, label: d.name }))

        if (option === ARGENTINA_COUNTRY.value) {
          setProvinces(provincesData)
        } else {
          setProvinces([empty, ...provincesData])
        }
      }
    } catch (e) {}
  }

  const requiredProvince = (country) => country && country === ARGENTINA_COUNTRY.value

  const onFromDateChanged = (setValues, defaultTime) => (value) =>
    setValues((values) => ({
      ...values,

      fromDate: value ? value.format() : null,
      fromTime:
        !values.fromTime && value && moment(value).isValid() ? defaultTime : values.fromTime,

      ...(!!value &&
        moment(value).isValid() &&
        (!values.toDate || !moment(values.toDate).isValid()) && {
          toDate: moment(value),
          toTime: moment('23:59', 'HH:mm').format()
        })
    }))

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} altMode />

      <PageContainer customContainerClassName={classes.container}>
        <Formik
          enableReinitialize
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            setValues,
            isSubmitting,
            isValid,
            setFieldValue
          }) => {
            const validationProvince = requiredProvince(values.countryId)

            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.bodyContainer}>
                  <Typography variant='h6' className={classes.title} color='primary'>
                    Crear evento para {administratorAccount.organization?.name || STRING_EMPTY}
                  </Typography>
                  <div className={classes.mainContainer}>
                    <section className={classes.section}>
                      <div className={classes.formGroup}>
                        <TextField
                          className={classes.input}
                          name='name'
                          autoComplete='off'
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.name || 'Máximo 100 caracteres.'}
                          error={touched.name && Boolean(errors.name)}
                          fullWidth
                          label='Nombre del Evento'
                          required
                          variant='outlined'
                          size='small'
                          margin='none'
                        />
                      </div>

                      <div className={classes.formGroup}>
                        <FormControl variant='outlined' className={classes.formControl}>
                          <Autocomplete
                            multiple
                            id='disciplinesSelect-autocomplete'
                            options={disciplines}
                            getOptionLabel={(option) => option.label}
                            selectOnFocus
                            onChange={(event, optionSelected) =>
                              onDisciplinesChange(setFieldValue)(optionSelected)
                            }
                            getOptionSelected={(x) =>
                              values.disciplinesSelect.some((o) => o.value === x.value)
                            }
                            filterSelectedOptions
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant='outlined'
                                  label={option.label}
                                  key={option.value}
                                  title={option.label}
                                  {...getTagProps({ index })}
                                  color='primary'
                                  className={classes.option}
                                />
                              ))
                            }
                            clearText='Quitar todo'
                            openText='Abrir desplegable'
                            closeText='Cerrar desplegable'
                            value={values.disciplinesSelect}
                            className={classes.combo}
                            loadingText={<Typography align='center'> Cargando...</Typography>}
                            noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={clsx(classes.input, classes.comboInput)}
                                name='disciplines'
                                label='Seleccione Deporte/ Disciplina'
                                variant='outlined'
                                error={Boolean(errors.disciplinesSelect)}
                                helperText={
                                  errors.disciplinesSelect || 'Escriba el nombre de la disciplina'
                                }
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className={classes.phoneForm}>
                        <div className={classes.phoneContainer}>
                          <TextField
                            name='phone'
                            value={values.phone}
                            className={classes.input}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            autoComplete='off'
                            disabled={
                              values.useOrganizationPhone &&
                              !!administratorAccount.organization?.phone
                            }
                            variant='outlined'
                            size='small'
                            label='Teléfono de contacto*'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                color='primary'
                                checked={values.useOrganizationPhone}
                                className={classes.checkbox}
                                onChange={handleChangeUseOrganizationPhoneCheck(setValues)}
                                name='useOrganizationPhone'
                                onBlur={handleBlur}
                              />
                            }
                            label={
                              <Typography
                                variant='h6'
                                color='primary'
                                className={classes.checkMiniLabel}>
                                Es el mismo N° de teléfono que el de la organización.
                              </Typography>
                            }
                          />
                        </div>

                        <div className={classes.whatsAppContainer}>
                          <StyledSwitch
                            checked={values.hasWhatsApp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='hasWhatsApp'
                          />
                          <Typography variant='h6' className={classes.whatsAppTitle}>
                            Tiene WhatsApp
                          </Typography>
                        </div>
                      </div>
                      <div className={classes.formGroup}>
                        <TextField
                          name='description'
                          value={values.description}
                          className={`${classes.input} multiline`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            errors.description || 'Mínimo 100 caracteres máximo 4000 caracteres.'
                          }
                          error={touched.description && Boolean(errors.description)}
                          fullWidth
                          autoComplete='off'
                          multiline
                          rowsMax={7}
                          required
                          label='Descripción del evento'
                          rows={1}
                          variant='outlined'
                          size='small'
                          margin='none'
                        />
                      </div>
                      <div className={clsx(classes.formGroup, classes.dateEventContainer)}>
                        <Typography variant='caption' color='primary' className={classes.label}>
                          Fecha del evento*
                        </Typography>
                        <div className={classes.datePickerContainer}>
                          <div className={classes.datetimePickerContainer}>
                            <KeyboardDatePicker
                              className={classes.inputDatePicker}
                              format='DD/MM/YYYY'
                              autoOk
                              variant='inline'
                              name='fromDate'
                              margin='none'
                              label='Desde'
                              id='date-picker-inline'
                              inputVariant='outlined'
                              onBlur={handleBlur}
                              required
                              helperText=''
                              placeholder='dd/mm/aaaa'
                              value={values.fromDate}
                              onChange={onFromDateChanged(
                                setValues,
                                moment('00:00', 'HH:mm').format()
                              )}
                              KeyboardButtonProps={{
                                'aria-label': 'seleccione fecha'
                              }}
                              error={touched.fromDate && Boolean(errors.fromDate)}
                            />
                            <KeyboardTimePicker
                              className={classes.inputTimePicker}
                              autoOk
                              name='fromTime'
                              onBlur={handleBlur}
                              variant='inline'
                              ampm={false}
                              autoComplete='off'
                              inputVariant='outlined'
                              keyboardIcon={<AccessTimeIcon />}
                              value={values.fromTime}
                              helperText=''
                              placeholder='hh:mm'
                              error={touched.fromTime && Boolean(errors.fromTime)}
                              onChange={onTimeChanged(setFieldValue, 'fromTime')}
                            />
                          </div>
                          {(errors.fromDate || errors.fromTime) && (
                            <Typography
                              color={
                                (touched.fromDate && Boolean(errors.fromDate)) ||
                                (touched.fromTime && Boolean(errors.fromTime))
                                  ? 'error'
                                  : ''
                              }
                              className={classes.errorMessage}
                              variant='caption'>
                              {errors.fromDate || errors.fromTime}
                            </Typography>
                          )}
                        </div>
                        <div className={classes.formGroup}>
                          <div className={classes.datetimePickerContainer}>
                            <KeyboardDatePicker
                              className={classes.inputDatePicker}
                              format='DD/MM/YYYY'
                              autoOk
                              variant='inline'
                              name='toDate'
                              required
                              label='Hasta'
                              margin='none'
                              id='date-picker-inline'
                              inputVariant='outlined'
                              onBlur={handleBlur}
                              value={values.toDate}
                              onChange={onDateTimeChanged(
                                setValues,
                                'toDate',
                                'toTime',
                                moment('23:59', 'HH:mm').format()
                              )}
                              KeyboardButtonProps={{
                                'aria-label': 'seleccione fecha'
                              }}
                              placeholder='dd/mm/aaaa'
                              helperText=''
                              error={touched.toDate && Boolean(errors.toDate)}
                            />
                            <KeyboardTimePicker
                              className={classes.inputTimePicker}
                              autoOk
                              name='toTime'
                              onBlur={handleBlur}
                              variant='inline'
                              ampm={false}
                              inputVariant='outlined'
                              keyboardIcon={<AccessTimeIcon />}
                              value={values.toTime}
                              helperText=''
                              placeholder='hh:mm'
                              error={touched.toTime && Boolean(errors.toTime)}
                              onChange={onTimeChanged(setFieldValue, 'toTime')}
                            />
                          </div>
                          {(errors.toDate || errors.toTime) && (
                            <Typography
                              color={
                                (touched.toDate && Boolean(errors.toDate)) ||
                                (touched.toTime && Boolean(errors.toTime))
                                  ? 'error'
                                  : 'inherit'
                              }
                              className={classes.errorMessage}
                              variant='caption'>
                              {errors.toDate || errors.toTime}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </section>
                    <section className={classes.section}>
                      <div className={classes.formGroup}>
                        <FormControl variant='outlined'>
                          <InputLabel
                            id='country-native-simple'
                            className={clsx(classes.labelSelect, errors.countryId && 'error')}>
                            País*
                          </InputLabel>
                          <Select
                            className={classes.select}
                            value={values.countryId}
                            required
                            error={Boolean(errors.countryId) && touched.countryId}
                            onBlur={handleBlur}
                            name='countryId'
                            onChange={(e) => onCountryChange(setFieldValue)(e.target.value)}
                            color='primary'
                            variant='outlined'
                            labelId='country-native-simple'
                            label='País'>
                            {countries.map((x) => (
                              <MenuItem value={x.value} key={x.value}>
                                {x.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography
                            variant='caption'
                            className={clsx(classes.errorMessage, classes.helperText)}
                            color={errors.countryId ? 'error' : 'inherit'}>
                            {errors.countryId || 'Seleccione su País.'}
                          </Typography>
                        </FormControl>

                        {!!provinces.length && (
                          <div className={classes.formGroup}>
                            <FormControl variant='outlined'>
                              <InputLabel
                                id='area-level-1-select-outlined-label'
                                className={clsx(
                                  classes.labelSelect,
                                  Boolean(errors.areaLevel1Id) && touched.areaLevel1Id && 'error'
                                )}>
                                Provincia / Región / Estado
                                {validationProvince ? '*' : STRING_EMPTY}
                              </InputLabel>
                              <Select
                                name='areaLevel1Id'
                                labelId='area-level-1-select-outlined-label'
                                id='area-level-1-select-outlined'
                                label={`Provincia / Región / Estado${
                                  validationProvince ? '*' : STRING_EMPTY
                                }`}
                                value={values.areaLevel1Id}
                                onChange={handleChange}
                                error={Boolean(errors.areaLevel1Id) && touched.areaLevel1Id}
                                onBlur={handleBlur}
                                className={classes.select}>
                                {provinces.map((x) => (
                                  <MenuItem value={x.value} key={x.value}>
                                    {x.label}
                                  </MenuItem>
                                ))}
                              </Select>

                              <Typography
                                variant='caption'
                                className={clsx(classes.errorMessage, classes.helperText)}
                                color={
                                  errors.areaLevel1Id && touched.areaLevel1Id ? 'error' : 'inherit'
                                }>
                                {errors.areaLevel1Id || 'Seleccione una opción del desplegable.'}
                              </Typography>
                            </FormControl>
                          </div>
                        )}
                      </div>

                      <div className={classes.formGroup}>
                        <div
                          className={clsx(
                            classes.location,
                            values.undefinedLocation && 'disabled'
                          )}>
                          <TextField
                            name='location'
                            type='text'
                            autoComplete='off'
                            value={values.location}
                            disabled
                            required
                            className={clsx(
                              classes.locationInput,
                              values.undefinedLocation && 'disabled'
                            )}
                            variant='outlined'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            size='small'
                            fullWidth
                            margin='none'
                            label='Ubicación'
                            style={{ border: 0 }}
                          />
                          <LocationPicker
                            mode='write'
                            mini
                            variant='outlined'
                            disabled={values.undefinedLocation}
                            latitude={values.latitude}
                            longitude={values.longitude}
                            onLocationChanged={onLocationChanged(setFieldValue)}
                            onConfirm={onConfirm(setFieldValue)}
                          />
                        </div>

                        {!values.undefinedLocation && (
                          <Typography
                            variant='caption'
                            className={clsx(classes.helperText, classes.errorMessage)}
                            color={errors.location && touched.location ? 'error' : 'inherit'}>
                            {errors.location || 'Introduce una ubicación.'}
                          </Typography>
                        )}

                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={values.undefinedLocation}
                              className={classes.checkbox}
                              onChange={handleChangeUndefinedLocationCheck(setValues)}
                              name='undefinedLocation'
                              onBlur={handleBlur}
                            />
                          }
                          label={
                            <Typography variant='h6' color='primary' className={classes.checkLabel}>
                              No lo sé aún, se definirá pronto.
                            </Typography>
                          }
                        />
                      </div>
                      <div className={classes.formGroup}>
                        <TextField
                          className={classes.input}
                          name='address'
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label='Dirección'
                          fullWidth
                          disabled={values.undefinedLocation}
                          required
                          variant='outlined'
                          size='small'
                          margin='none'
                          helperText={
                            errors.address ||
                            (!values.undefinedLocation && 'Escriba la dirección exacta del evento.')
                          }
                          error={touched.address && Boolean(errors.address)}
                        />
                      </div>

                      <div className={classes.formGroup}>
                        <FormControlLabel
                          className={classes.formControl}
                          control={
                            <Checkbox
                              color='primary'
                              name='enableAddOrganization'
                              checked={values.enableAddOrganization}
                              className={classes.checkbox}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          }
                          label={
                            <Typography variant='h6' color='primary' className={classes.checkLabel}>
                              Comparto la organización de este evento (opcional).
                            </Typography>
                          }
                        />
                      </div>

                      {values.enableAddOrganization && (
                        <div className={classes.formGroup}>
                          <FormControl variant='outlined' className={classes.formControl}>
                            <Autocomplete
                              multiple
                              id='organization-autocomplete'
                              options={organizationOptions.filter(
                                (x) => x.id !== administratorAccount.organization?.id
                              )}
                              getOptionLabel={(option) => option.handle || option.name}
                              selectOnFocus
                              filterSelectedOptions
                              getOptionSelected={(x) =>
                                values.organizationsSelect.some((o) => o.id === x.id)
                              }
                              onInputChange={(e) =>
                                debounceOnChange(e?.target.value, setFieldValue, values)
                              }
                              onChange={(event, optionSelected) =>
                                onOrganizationsChange(setFieldValue)(optionSelected)
                              }
                              filterOptions={(x) => x}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant='outlined'
                                    label={option.handle || option.name}
                                    key={option.id}
                                    title={option.handle || option.name}
                                    {...getTagProps({ index })}
                                    color='primary'
                                    className={classes.option}
                                  />
                                ))
                              }
                              className={classes.combo}
                              loading={organizationsPending}
                              loadingText={<Typography align='center'> Cargando...</Typography>}
                              noOptionsText={<Typography align='center'> Sin opciones</Typography>}
                              clearText='Quitar todo'
                              openText='Abrir desplegable'
                              closeText='Cerrar desplegable'
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className={clsx(classes.input, classes.comboInput)}
                                  name='organizationsSelect'
                                  label='Nombre o alias de la organización*'
                                  variant='outlined'
                                  error={
                                    touched.organizationsSelect &&
                                    Boolean(errors.organizationsSelect)
                                  }
                                  helperText={
                                    errors.organizationsSelect ||
                                    'Escriba nombre o alias del co-organizador.'
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: organizationsPending ? (
                                      <CircularProgress color='primary' size={16} />
                                    ) : (
                                      params.InputProps.endAdornment
                                    )
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                      )}
                    </section>
                  </div>
                </div>

                <Button
                  color='primary'
                  variant='contained'
                  type='submit'
                  className={classes.createButton}
                  disabled={!isValid || isSubmitting}
                  endIcon={pending && <CircularProgress size={16} color='primary' />}>
                  Crear evento
                </Button>
              </form>
            )
          }}
        </Formik>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default CreatePage
