import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.7)
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    boxShadow: 'none',
    minHeight: '320px !important',
    [theme.breakpoints.up('md')]: {
      width: '580px !important',
      maxWidth: '580px !important',
      margin: '0 auto'
    }
  },
  container: {
    padding: theme.spacing(0, 2),
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(6)
  },
  content: {
    fontSize: '1rem',
    color: theme.palette.primary.main,
    '& span': {
      fontWeight: 600
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  button: {
    width: 200,
    borderRadius: 10,
    '& span': {
      fontWeight: 500
    }
  }
}))
