import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '400px !important',
    [theme.breakpoints.up('md')]: {
      width: 850,
      maxWidth: 850
    }
  },
  container: {},
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  label: {
    fontSize: '.75rem',
    '&.disabled': {
      color: '#696969'
    }
  },
  textField: {
    width: '100%',

    appearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    '& input': {
      height: 5,
      fontSize: '.875rem',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      width: 239
    }
  },
  paymentMethodContainer: {
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  underlineTitle: {
    fontWeight: 600,
    textDecoration: 'underline'
  },
  bearCostText: {
    fontSize: '.875rem',
    marginLeft: theme.spacing(3.5),
    color: '#000000'
  },
  formControl: {
    alignSelf: 'flex-start',
    display: 'flex',
    marginRight: 0,

    '& svg': {
      color: theme.palette.primary.main,
      width: 20,
      height: 20
    },

    '&.disabled': {
      '& svg': {
        color: '#696969'
      }
    }
  },
  formControlLabel: {
    fontWeight: 600,
    fontSize: '.875rem',
    color: '#3C3C3C'
  },
  mercadoPagoControl: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    marginTop: theme.spacing(2)
  },
  mercadoPagoLink: {
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&.disabled': {
      color: '#C3C3C3',
      cursor: 'auto'
    }
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(2)
    }
  },
  saleDateLabel: {
    fontSize: '.875rem',
    fontWeight: 600
  },
  inputDateField: {
    marginBottom: theme.spacing(0.5),
    '& input': {
      fontSize: '0.85rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '0.9rem'
      }
    },
    '& > div': {
      paddingRight: 0,
      height: 40,
      width: 170
    },
    '&.timePicker': {
      '&::placeholder': {
        color: 'rgba(105, 105, 105, 1)'
      }
    },

    '& div > button': {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: '#ffff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffff'
      },
      width: 40,
      height: 40
    }
  },
  datePickerContainer: {
    width: 240
  },
  dateField: {
    width: 240,
    '& input': {
      padding: theme.spacing(1.1, 1.8)
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },

  infoTooltip: {
    backgroundColor: '#FF9D29',
    fontWeight: 400,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      width: 420,
      maxWidth: 420
    }
  },
  arrowTooltip: {
    color: '#FF9D29'
  },
  infoIcon: {
    color: '#FF9D29'
  },

  saleHelperText: {
    fontSize: '.875rem',
    color: '#000000'
  },

  selectAssociateContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column'
  },
  asocciateContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    '&.disabled': {
      '& h6': {
        color: '#696969',
        cursor: 'auto'
      }
    }
  },
  associateTitle: {
    fontWeight: 600
  },
  link: {
    fontWeight: 600,
    fontSize: '.875rem',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: theme.spacing(2)
  },
  optionLabel: {
    color: '#696969',
    fontSize: '.875rem'
  },
  separator: {
    borderBottom: '0.5px solid #C8C8C8',
    padding: theme.spacing(2, 0),
    minWidth: 200
  },
  buttonContainer: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  createButton: {
    height: 41,
    width: 215,
    margin: theme.spacing(3, 0)
  },
  saleToContainer: {
    display: 'flex',
    width: 400
  },
  error: {
    marginLeft: theme.spacing(),
    marginTop: theme.spacing()
  },
  adornment: {
    '& p': {
      fontSize: '.875rem',
      color: '#000000'
    }
  },
  buttonInfoIcon: {
    color: '#FF9D29',
    zIndex: 30,
    marginLeft: -theme.spacing(1.5)
  },
  arrow: {
    color: '#FF9D29'
  },
  tooltip: {
    backgroundColor: '#FF9D29',
    fontSize: '.75rem',
    [theme.breakpoints.up('md')]: {
      width: 320,
      maxWidth: 320,
      borderEndStartRadius: 0
    }
  },
  errorItems: {
    color: '#FF4B4B',
    fontSize: 13,
    marginBottom: theme.spacing(0.5)
  },
  tooltipPopper: {
    zIndex: 1300
  },
  containerPaymentUrl: {
    display: 'flex',
    gridGap: 10
  },
  tooltipPaymentUrl: {
    fontSize: '.75rem',
    [theme.breakpoints.up('md')]: {
      backgroundColor: '#FF9D29',
      width: 320,
      maxWidth: 320,
      borderEndStartRadius: 0
    }
  },
  textFieldPaymentUrl: {
    width: '100%',

    appearance: 'textfield',
    '&::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0
    },
    '& input': {
      height: 5,
      fontSize: '.875rem',
      fontWeight: 500,
      '&::placeholder': {
        color: theme.palette.secondary.contrastText
      }
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  }
}))
