import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  readMoreBox: {
    maxHeight: 170,
    overflow: 'hidden',
    position: 'relative'
  },
  readMoreBoxRevealed: {
    maxHeight: 'none',
    overflow: 'visible'
  },
  readMoreButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    margin: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    '& button': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 'bold',
      fontSize: '1.125em',
      width: '100%',
      border: 'none',
      background: 'none',
      color: theme.palette.primary.main,
      textAlign: 'left',
      textDecoration: 'underline'
    }
  },
  sectionContainer: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #A9ABAE',
    paddingBottom: theme.spacing(3),
    '& h3': {
      fontWeight: 600,
      fontSize: '1.25em',
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
      color: theme.palette.primary.main
    },
    '& > p': {
      color: theme.palette.primary.main
    }
  },
  linkResult: {
    textDecoration: 'underline',
    fontSize: '.8em',
    cursor: 'pointer',
    fontWeight: 600
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing()
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

export default useStyles
