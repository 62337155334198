import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '300px !important',
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600
    },
    '&.confirmDialog': {
      minHeight: '300px !important',
      [theme.breakpoints.up('md')]: {
        width: 650,
        maxWidth: 650
      }
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4)
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(5)
  },
  text: {
    fontSize: '.95rem',
    fontWeight: 600,
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: '1rem',
    fontWeight: 600,
    marginTop: theme.spacing(3)
  },

  subtitle: {
    fontSize: '1rem',
    fontWeight: 600,
    marginTop: theme.spacing()
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0)
  },
  button: {
    alignSelf: 'center',
    height: 41,
    maxWidth: 200,
    width: '45%'
  },
  item: {
    color: theme.palette.primary.main,
    fontSize: '.95rem',
    fontWeight: 600
  },
  textAlert: {
    fontWeight: 700,
    textAlign: 'center',
    padding: 10
  },
  acceptButton: {
    alignSelf: 'center',
    width: 200,
    height: 40,
    marginTop: 20
  },
  subtext: {
    textAlign: 'center'
  }
}))
