// Action Types
export const SIGN_IN_ATTEMPT = 'auth/SIGN_IN_ATTEMPT'
export const SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS'
export const SIGN_IN_FAILURE = 'auth/SIGN_IN_FAILURE'

export const CLOSE_SESSION = 'auth/CLOSE_SESSION'
export const RESTORE_SESSION = 'auth/RESTORE_SESSION'

export const SIGN_UP_ATTEMPT = 'auth/SIGN_UP_ATTEMPT'
export const SIGN_UP_SUCCESS = 'auth/SIGN_UP_SUCCESS'
export const SIGN_UP_FAILURE = 'auth/SIGN_UP_FAILURE'

export const SET_SIGN_UP_DATA = 'auth/SET_SIGN_UP_DATA'

export const MANUAL_ACTIVATION_ATTEMPT = 'auth/MANUAL_ACTIVATION_ATTEMPT'
export const MANUAL_ACTIVATION_SUCCESS = 'auth/MANUAL_ACTIVATION_SUCCESS'
export const MANUAL_ACTIVATION_FAILURE = 'auth/MANUAL_ACTIVATION_FAILURE'

export const RECOVER_ACCOUNT_ATTEMPT = 'auth/RECOVER_ACCOUNT_ATTEMPT'
export const RECOVER_ACCOUNT_SUCCESS = 'auth/RECOVER_ACCOUNT_SUCCESS'
export const RECOVER_ACCOUNT_FAILURE = 'auth/RECOVER_ACCOUNT_FAILURE'

export const LOAD_WELCOME_USER_ATTEMPT = 'auth/LOAD_WELCOME_USER_ATTEMPT'
export const LOAD_WELCOME_USER_SUCCESS = 'auth/LOAD_WELCOME_USER_SUCCESS'
export const LOAD_WELCOME_USER_FAILURE = 'auth/LOAD_WELCOME_USER_FAILURE'

export const RESET_PASSWORD_ATTEMPT = 'auth/RESET_PASSWORD_ATTEMPT'
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'auth/RESET_PASSWORD_FAILURE'

export const LOAD_ORGANIZATIONS_ATTEMPT = 'auth/LOAD_ORGANIZATIONS_ATTEMPT'
export const LOAD_ORGANIZATIONS_SUCCESS = 'auth/LOAD_ORGANIZATIONS_SUCCESS'
export const LOAD_ORGANIZATIONS_FAILURE = 'auth/LOAD_ORGANIZATIONS_FAILURE'

export const LOAD_USER_DATA_ATTEMPT = 'auth/LOAD_USER_DATA_ATTEMPT'
export const LOAD_USER_DATA_SUCCESS = 'auth/LOAD_USER_DATA_SUCCESS'
export const LOAD_USER_DATA_FAILURE = 'auth/LOAD_USER_DATA_FAILURE'
