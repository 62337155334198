import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(25),
    minHeight: '82vh',
    paddingBottom: 0,
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(15.5),
      display: 'flex',
      minHeight: '90vh'
    }
  },
  text: {
    padding: theme.spacing(),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical'
  },
  actionsContainer: {
    marginTop: 80, // navbar height
    zIndex: 1,
    backgroundColor: 'white',
    position: 'fixed',
    gap: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: theme.spacing(9),
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  topActionsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterContainer: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: 340,
      flexShrink: 0
    }
  },
  filterWrapper: {
    backgroundColor: '#ffff',
    width: '80vw',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '62vw'
    },
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  filterDrawerHeader: {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingLeft: theme.spacing(2)
  },
  filterDrawerHeaderLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  closeIcon: {
    width: 48,
    height: 48
  },
  resultsContainer: {
    flexGrow: 1
  },
  containerLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100
  },
  listContainer: {
    width: '100%',
    margin: '20px auto',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('lg')]: {
      minWidth: 900,
      '& #feature-image': {
        minHeight: 100,
        height: '11.1vw',
        maxHeight: 170
      }
    }
  },
  bannerContainer: {
    width: '100%',
    height: 230,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),

    [theme.breakpoints.up('lg')]: {
      width: '73vw',
      marginLeft: '1vw'
    },
    [theme.breakpoints.up('xl')]: {
      width: 1400
    }
  },
  cardContainer: {
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2, 0),
      marginRight: theme.spacing(1.5),
      width: '31%',
      '& > div': {
        width: '100%',
        minWidth: 100,
        maxWidth: 350
      }
    }
  },
  messageContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& p ': {
        maxWidth: 625,
        [theme.breakpoints.up('lg')]: {
          textAlign: 'center'
        }
      }
    },
    '& h5': {
      fontWeight: 650,
      marginBottom: 5
    }
  },
  endContainer: {
    marginTop: theme.spacing(2),
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 950
    },
    [theme.breakpoints.up('xl')]: {
      width: 1170
    }
  },

  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  },

  buttonContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3)
  },

  button: {
    width: '50%',
    '& span': {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: '30%'
    }
  },

  backButton: {
    padding: 15,
    order: 1,
    alignSelf: 'center',
    margin: theme.spacing(4, 0),
    '&  svg': {
      fontSize: '3rem',
      width: '100%',
      fontWeight: 600,
      lineHeight: '20px'
    },
    [theme.breakpoints.up('md')]: {
      order: 0
    }
  }
}))

export default useStyles
