import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Button, Typography } from '@material-ui/core'
import { SectionLayoutDialog } from 'shared'
import { verifyUserNotLogged } from 'state/modules/app'

import { useStyles } from './InscriptionOptionsDialog.style'

const INSCRIPTION_DIALOG_SUBTITLE = (
  <Typography variant='h6'>
    Tenés que tener una cuenta de <strong>Sportmetric</strong>&nbsp; para poder inscribirte en este
    evento.
    <br />
    <br />
    Para este y más beneficios:
  </Typography>
)

const OptionsInscriptionDialog = ({ open, onClose, inscriptionFormUrl }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRedirectForm = (requiredAuthentication) => () => {
    if (requiredAuthentication) {
      dispatch(
        verifyUserNotLogged({
          state: true,
          inscriptionFormUrl,
          dialogSubTitle: INSCRIPTION_DIALOG_SUBTITLE
        })
      )
      onClose()
    } else {
      history.push(inscriptionFormUrl)
    }
  }

  return (
    <SectionLayoutDialog
      onClose={onClose}
      open={open}
      title='NOTIFICACIÓN'
      className={classes.dialog}
      contentClassName={classes.container}>
      <div className={classes.mainContainer}>
        <Typography className={classes.subTitle}>
          Si tienes una cuenta de <span>Sportmetric</span> esto agilizará la carga de datos en el
          formulario.
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={handleRedirectForm(true)}>
            Inscribirme con cuenta
          </Button>
          <Typography align='center' className={classes.paragraph}>
            ó
          </Typography>
          <Button
            color='primary'
            variant='outlined'
            className={classes.button}
            onClick={handleRedirectForm(false)}>
            Inscribirme sin cuenta
          </Button>
        </div>
      </div>
    </SectionLayoutDialog>
  )
}

export default OptionsInscriptionDialog
