import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  activeOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4),
    height: 80,
    transition: 'all .3s',
    cursor: 'pointer',
    '&.hide': {
      display: 'none' // delete line after
    },
    '&:hover': {
      '&.primary': {
        backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.3)
      },
      '&.secondary': {
        backgroundColor: theme.utils.rgba(theme.palette.secondary.main, 0.3)
      }
    }
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4),
    height: 60,
    cursor: 'pointer',
    transition: 'all .3s',
    '&.hide': {
      display: 'none' // delete line after
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  role: {
    fontSize: '.875rem',
    fontWeight: 500
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 144,
    textTransform: 'capitalize'
  },
  radio: {
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}))
