import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    width: '100% !important',
    margin: 0
  },
  container: {
    width: '100%',
    margin: '0 auto'
  },
  infoContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textInitial: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 20,
    [theme.breakpoints.up('xs')]: {
      padding: 10
    },
    [theme.breakpoints.up('md')]: {
      width: 600,
      marginLeft: 50
    }
  },
  butonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    '& button': {
      borderRadius: 10,
      width: 180,
      marginTop: 10,
      marginBottom: 20
    }
  }
}))
