import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  containerHorizontal: {
    width: '100%',
    height: 1700,
    display: 'flex',
    [theme.breakpoints.up(321)]: {
      paddingLeft: '1.5rem',
      paddingRight: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },
  containerImg: {
    width: '100%',
    height: 300,
    zIndex: 10,
    padding: 30
  },
  backgroundImage: {
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 400
    }
  },
  fullName: {
    width: 120,
    height: 8
  },
  userName: {
    width: 90,
    height: 8
  },
  followers: {
    width: '40%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '13%',
      height: 10
    }
  },
  butonFollow: {
    width: '25%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '7%',
      height: 10
    }
  },
  title: {
    width: '40%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '20%',
      height: 10
    }
  },
  subtitle: {
    width: '30%',
    height: 10,
    [theme.breakpoints.up('sm')]: {
      width: '15%',
      height: 9
    }
  },
  text: {
    width: '50%',
    height: 9,
    [theme.breakpoints.up('sm')]: {
      width: '25%',
      height: 8
    }
  },
  social: {
    width: '5%',
    height: 10
  },
  followed: {
    width: '60%',
    height: 10
  },
  position: {
    width: '25%',
    height: 45,
    [theme.breakpoints.up('sm')]: {
      width: '7%'
    }
  },
  date: {
    width: '10%',
    height: 8,
    [theme.breakpoints.up('sm')]: {
      width: '6%'
    }
  },
  body: {
    width: '30%',
    height: 7
  },
  container: {
    width: '100%',
    height: 1250,
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      width: 1200
    }
  }
}))
