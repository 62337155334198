import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '90vh',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      height: '89.5vh',
      width: 1280,
      margin: '0 auto'
    }
  },
  container: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      padding: 0,
      flexDirection: 'row'
    }
  },
  bodyContainer: {
    margin: '0 auto'
  },
  button: {
    color: '#29ff59',
    marginTop: 10
  },
  textDescription: {
    marginTop: 10,
    fontSize: '.9rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
      marginTop: 0
    }
  },
  title: {
    fontWeight: 600,
    alignSelf: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      width: '80%'
    }
  }
}))
