import React from 'react'
import { Typography } from '@material-ui/core'

import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './CoverSection.style'

const CoverSection = ({ ...sectionBlockProps }) => {
  const classes = useStyles()

  return (
    <SectionBlock
      containerClassName={classes.section}
      nextSectionId='manageEventSection'
      {...sectionBlockProps}>
      <div className={classes.backgroundImage}>
        <NavBar altMode />
        <div className={classes.content}>
          <div className={classes.titleContainer}>
            <Typography variant='h2' className={classes.title}>
              SOMOS <span>LA SOLUCIÓN </span>
              <br />
              <div className={classes.animationContent}>
                <Typography>PARA</Typography>&nbsp;
                <div className={classes.animationBlock}>
                  <b className={classes.transitionContainer}>
                    <div className={classes.transition}>
                      <div>ORGANIZAR</div>
                      <br />
                      <div>PUBLICAR</div>
                      <br />
                      <div>DIFUNDIR</div>
                    </div>
                  </b>
                </div>
              </div>
              <span>TUS EVENTOS</span> DEPORTIVOS
            </Typography>
          </div>
        </div>
      </div>
    </SectionBlock>
  )
}

export default CoverSection
