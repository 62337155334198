import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(4, 0),
    gap: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  }
}))
