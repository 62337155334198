import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 625,
      maxWidth: 625
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    gap: theme.spacing(5)
  },
  button: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5.6)
    }
  },
  offLineTextLink: {
    fontWeight: 600,
    fontSize: '1rem',
    marginBottom: 5,
    textDecoration: 'underline',
    marginLeft: -theme.spacing(4),
    '&.center': {
      marginLeft: 0
    },
    cursor: 'pointer'
  },
  infoDialog: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 40px',
    gap: theme.spacing(),
    '& svg': {
      marginTop: 2
    }
  },
  textInfoDialog: {
    fontWeight: 600
  }
}))
