import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600,
      margin: '0 auto',

      minHeight: '280px !important'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2)
  },
  phone: {
    color: '#969696'
  }
}))
