import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.3)
  },
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 650,
      maxWidth: 650
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 160,
    marginTop: theme.spacing(5)
  },
  container: {
    width: '80%',
    margin: '0 auto'
  },
  buttonContainer: {
    maxWidth: 500,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2)
  },
  button: {
    width: 200,
    height: 40
  }
}))
