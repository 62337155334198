import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.utils.rgba('#D9D9D966', 0.4),
    borderRadius: theme.spacing(1.25),
    padding: theme.spacing(3, 7),
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20
  }
}))
