import { makeStyles } from '@material-ui/core'
import biking from 'assets/img/biking.jpg'
import marathon from 'assets/img/marathon.jpg'
import motorcycling from 'assets/img/motorcycling.jpg'
import mountainBike from 'assets/img/mountain-bike.jpg'
import swimming from 'assets/img/swimming.jpg'
import triathlon from 'assets/img/triathlon.jpg'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: 0,
    paddingLeft: 0,
    backgroundColor: theme.palette.primary.main
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(3),
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: theme.spacing(18),
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(5)
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  mainContainer: {
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: '0 auto'
    }
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(8),
      width: '45%'
    }
  },
  title: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '3.1vw'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '3rem'
    }
  },
  subTitle: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.3vw'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.875rem'
    }
  },
  formContainer: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '45%',
      maxWidth: 550
    }
  },

  sportImages: {
    width: '100%',
    display: 'flex',
    height: '22.4vw',
    marginTop: theme.spacing(7),
    '& > div': {
      width: '33.33%',
      height: '100%'
    },
    [theme.breakpoints.up('md')]: {
      height: '13.3vw',
      '& > div': {
        width: '20%',
        height: '100%'
      }
    },
    [theme.breakpoints.up('lg')]: {
      height: 176
    }
  },
  mountainBike: {
    backgroundSize: 'contain',
    backgroundImage: `url(${mountainBike})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  running: {
    backgroundSize: 'contain',
    backgroundImage: `url(${marathon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  bikeRace: {
    backgroundSize: 'contain',
    backgroundImage: `url(${biking})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  swimming: {
    backgroundSize: 'contain',
    backgroundImage: `url(${swimming})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },

  motorcycling: {
    backgroundSize: 'contain',
    backgroundImage: `url(${motorcycling})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  triathlon: {
    backgroundSize: 'contain',
    backgroundImage: `url(${triathlon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  footer: {
    minHeight: 90,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: '0 auto'
    }
  },
  section: {
    minHeight: 90,
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around'
    }
  },
  netwoorkSocialContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2)
    }
  },
  facebookIcon: {
    height: '53.4px !important',
    width: '53.4px !important',
    [theme.breakpoints.up('md')]: {
      height: '91.7px !important',
      width: '91.7px !important'
    }
  },
  instagramIcon: {
    '& svg': {
      height: '25px!important',
      width: '25px!important'
    },
    marginTop: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      '& svg': {
        height: '43px!important',
        width: '43px!important'
      },
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1.3)
    }
  },
  developmentInfo: {
    display: 'flex',
    gap: theme.spacing(2),
    alignSelf: 'center',
    alignItems: 'center'
  },
  developmentText: {
    fontWeight: 600,
    fontSize: '.75rem',
    color: '#A9ABAE',
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.contrastText,
      fontSize: '.875rem'
    }
  },
  policyAndConditionsContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  policyAndConditions: {
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '.625rem',
    '& span': {
      color: theme.palette.primary.contrastText,
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: '.625rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '.875rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.875rem'
    }
  },
  imagesMini: {
    paddingTop: theme.spacing(11),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  sportImagesMini: {
    width: '100%',
    display: 'flex',
    height: '22.4vw',
    '& > div': {
      width: '33.33%',
      height: '100%'
    }
  },
  pinardLogo: {
    width: 87,
    height: 35,
    [theme.breakpoints.up('md')]: {
      width: 117,
      height: 47
    }
  }
}))
