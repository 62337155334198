import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.7),
    padding: 0
  },
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: '80%'
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      maxWidth: 1920
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1920,
      width: 920,
      margin: '0 auto'
    }
  },
  container: {
    '& > div > div': {
      minHeight: 394,
      width: 745
    }
  },

  headerContainer: {
    height: 96,
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    boxShadow: '0px 4px 4px rgba(6, 0, 173, 0.15)',
    padding: theme.spacing(0, 2),
    flexShrink: 0,
    justifyContent: 'center'
  },

  title: {
    flexGrow: 1,
    fontWeight: 600,
    paddingLeft: theme.spacing(7),
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    fontSize: '1rem'
  },

  dialogContent: {
    padding: 0
  },
  shareContainer: {
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing()
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7)
    }
  },

  subtitle: {
    fontWeight: 600,
    marginBottom: theme.spacing()
  },
  copyContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  textField: {
    width: '90%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(4),
    flexGrow: 1,
    '& input': {
      height: 2,
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    '& fieldset': {
      borderColor: theme.palette.primary.main
    },
    '& button': {
      flexShrink: 0,
      alignSelf: 'flex-end'
    }
  },

  shareButtonContainer: {
    display: 'flex',
    flexDirection: 'row'
  },

  shareButton: {
    marginRight: theme.spacing(2),
    padding: `${theme.spacing()}px !important`,
    display: 'block',
    backgroundColor: `${theme.palette.primary.main} !important`,
    '& svg': {
      width: 23,
      height: 23,
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff'
    }
  },

  actionButton: {
    border: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      border: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto'
    },
    '&.suspended': {
      color: theme.palette.suspended.main
    }
  },
  actionButtonLabelHidable: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    }
  }
}))
