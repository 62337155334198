import React, { useEffect, useState } from 'react'
import { SocialIcon } from 'react-social-icons'
import { Typography, useTheme } from '@material-ui/core'
import { CountriesProxy } from 'services'
import { EditableMultipleSelection, EditableSocialNetwork, EditableText } from 'shared'
import { filterProvinces, getCountries } from 'utils/functions'

import { SectionBlock } from '../SectionBlock'

import { useStyles } from './SectionInformation.style'

const SectionInformation = ({
  organization,
  canEdit = false,
  handleSave,
  errorUpdate,
  validation
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [provinces, setProvinces] = useState([])
  const {
    areaLevel1Name,
    countryName,
    countryId,
    instagramUrl,
    facebookUrl,
    youTubeUrl,
    web,
    phone
  } = organization

  const ARGENTINA_COUNTRY = { label: 'Argentina', value: '01fa2de7-519f-4089-a33c-d48483ea0f20' }
  const socialSize = { height: 48, width: 48 }
  const location = [areaLevel1Name, countryName].filter(Boolean).join(', ')
  const empty = { value: null, label: '--Seleccione--' }

  const loadCountriesOptions = async (inputValue) => {
    if (!provinces.length) {
      getProvinces(countryId)
    }
    return await getCountries(empty)(inputValue)
  }

  useEffect(() => {
    if (!organization.countryId) {
      getProvinces(ARGENTINA_COUNTRY.value)
    }
  }, [organization])

  const getProvinces = async (id, entity, i) => {
    try {
      const proxy = new CountriesProxy()
      setProvinces([])

      if (entity) entity.areaLevel1Id = { ...empty }

      if (id) {
        const data = await proxy.getAreaLevel1(id)

        if (Array.isArray(data)) {
          const value = data.map((d) => ({ value: d.id, label: d.name }))

          if (value.length) setProvinces([empty, ...value])
        }
      }
    } catch (error) {}
    return entity
  }

  return (
    <SectionBlock title={'Información'}>
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Email
      </Typography>
      <EditableText
        entity={organization}
        onSave={handleSave}
        canEdit={canEdit}
        field='eMail'
        validation={validation}
        type='email'
        error={errorUpdate}
        inputClassName={classes.inputEdit}
        formClassName={classes.formInputClassName}>
        <Typography
          variant='h4'
          color='primary'
          className={classes.infoText}
          title={organization.eMail || 'No proporcionado'}>
          {organization.eMail || 'No proporcionado'}
        </Typography>
      </EditableText>
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Teléfono
      </Typography>
      <EditableText
        entity={organization}
        field='phone'
        onSave={handleSave}
        canEdit={canEdit}
        type='text'
        error={errorUpdate}
        validation={validation}
        inputClassName={classes.inputEdit}
        formClassName={classes.formInputClassName}>
        <Typography variant='h4' color='primary' className={classes.infoText} title={phone}>
          {phone || 'No proporcionado'}
        </Typography>
      </EditableText>
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Sitio Web
      </Typography>
      <EditableText
        entity={organization}
        field='web'
        onSave={handleSave}
        canEdit={canEdit}
        type='url'
        validation={validation}
        error={errorUpdate}
        inputClassName={classes.inputEdit}
        formClassName={classes.formInputClassName}>
        {web ? (
          <a href={web} target='_blank' rel='noreferrer' className={classes.infoText} title={web}>
            {web}
          </a>
        ) : (
          <Typography color='primary' className={classes.infoText}>
            No proporcionado
          </Typography>
        )}
      </EditableText>
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Ubicación
      </Typography>
      <EditableMultipleSelection
        entity={organization}
        onSave={handleSave}
        selectClassName={classes.asyncSelect}
        canEdit={canEdit}
        resetOptions={() => getProvinces(organization.countryId ?? ARGENTINA_COUNTRY.value)}
        items={[
          {
            label: 'countryName',
            value: 'countryId',
            load: loadCountriesOptions,
            onChange: getProvinces,
            optionEmpty: ARGENTINA_COUNTRY,
            dynamic: true
          },
          {
            label: 'areaLevel1Name',
            value: 'areaLevel1Id',
            load: filterProvinces(provinces),
            options: provinces,
            optionEmpty: empty,
            static: true
          }
        ]}>
        <Typography variant='h4' color='primary' className={classes.infoText} title={location}>
          {location || 'No proporcionado'}
        </Typography>
      </EditableMultipleSelection>
      <Typography variant='h4' color='primary' className={classes.infoTitle}>
        Redes Sociales
      </Typography>
      <div className={classes.socialLinks}>
        <EditableSocialNetwork onSave={handleSave} canEdit={canEdit} entity={organization}>
          <SocialIcon
            url={instagramUrl || undefined}
            network='instagram'
            style={socialSize}
            bgColor={'transparent'}
            fgColor={instagramUrl ? theme.palette.primary.main : '#c3c3c3'}
            target='_blank'
          />

          <SocialIcon
            network='facebook'
            url={facebookUrl || undefined}
            style={socialSize}
            bgColor={'transparent'}
            fgColor={facebookUrl ? theme.palette.primary.main : '#c3c3c3'}
            target='_blank'
          />
          <SocialIcon
            network='youtube'
            url={youTubeUrl || undefined}
            style={socialSize}
            bgColor='transparent'
            fgColor={youTubeUrl ? theme.palette.primary.main : '#c3c3c3'}
            target='_blank'
          />
        </EditableSocialNetwork>
      </div>
    </SectionBlock>
  )
}

export default SectionInformation
