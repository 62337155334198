import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'
import { LinkifyText } from 'shared'

import useStyles from './SectionBlock.style'

const SectionBlock = ({
  children,
  className,
  title,
  body,
  hiddenBody = false,
  contentClassName
}) => {
  const classes = useStyles()
  const [isTextRevealed, setIsTextRevealed] = useState(!hiddenBody || (body && body.length < 200))

  return (
    <div className={clsx(classes.sectionContainer, className)}>
      {title && (
        <Typography variant='h3' className={classes.title}>
          {title}
        </Typography>
      )}

      {body && (
        <div className={clsx(classes.readMoreBox, isTextRevealed && classes.readMoreBoxRevealed)}>
          <LinkifyText>
            <Typography variant='body1' className={clsx(classes.body, contentClassName)}>
              {body}
            </Typography>
          </LinkifyText>
          {!isTextRevealed && (
            <div className={classes.readMoreButtonContainer}>
              <button onClick={() => setIsTextRevealed(true)}>Ver descripción completa</button>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default SectionBlock
