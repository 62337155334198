import { makeStyles } from '@material-ui/styles'
import logo from 'assets/img/logo.png'
import logoCondensedAlt from 'assets/img/logo-condensed.png'
import greenLogoCondensed from 'assets/img/logo-condensed-alt.png'
import whiteLogoCondensedImage from 'assets/img/logo-condensed-white.png'
import whiteLogoImage from 'assets/img/logo-white.png'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: 'all .3s',
    right: 'auto',
    left: 'auto',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.dark,
    height: 80,
    '&.secondary': {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up('md')]: {
      height: 120
    },
    [theme.breakpoints.up('xl')]: {
      width: 1920
    }
  },
  darkAppBar: {
    backgroundColor: `${theme.palette.secondary.contrastText} !important`,
    height: 73,
    [theme.breakpoints.up('md')]: {
      height: 125
    }
  },
  appBarTransparent: {
    backgroundColor: 'transparent'
  },
  toolbar: {
    height: 80,
    [theme.breakpoints.up('md')]: {
      height: 120,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(5)
    }
  },
  toolbarAlt: {
    height: 73,
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      height: 125,
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: theme.spacing(5)
    }
  },
  logoContainer: {
    flexGrow: 0,
    marginTop: 10
  },

  navLinkContainer: {
    textDecoration: 'none'
  },
  titleContainer: {
    marginTop: theme.spacing()
  },
  titleAnimation: {
    fontSize: 0,
    color: theme.palette.primary.contrastText,
    animation: `$titleMobile 2000ms 1500ms forwards`,
    [theme.breakpoints.up('md')]: {
      animation: `$titleAnimate 2000ms 1500ms forwards`,
      '&.mini': {
        animation: `$titleAnimateMini 2000ms 1500ms forwards`
      }
    }
  },
  title: {
    fontSize: '.85rem',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.07rem',
      '&.mini': {
        marginLeft: theme.spacing(2),
        fontSize: '.85rem'
      }
    }
  },
  '@keyframes titleAnimateMini': {
    '0%': {
      fontSize: 0
    },
    '100%': {
      marginLeft: theme.spacing(2),
      fontSize: '.85rem'
    }
  },
  '@keyframes titleMobile': {
    '0%': {
      fontSize: 0
    },
    '100%': {
      fontSize: '.85rem'
    }
  },
  '@keyframes titleAnimate': {
    '0%': {
      fontSize: 0
    },
    '100%': {
      fontSize: '1.075rem'
    }
  },
  altTitle: {
    fontSize: '.85rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.07rem !important',
      '&.mini': {
        marginLeft: theme.spacing(2),
        fontSize: '.85rem !important'
      }
    }
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${whiteLogoImage}) !important`,
    width: 248 - 24,
    height: 20,
    [theme.breakpoints.up('md')]: {
      width: 248,
      height: 26,
      backgroundPosition: 'center',
      '&.mini': {
        width: 230,
        height: 20
      }
    }
  },
  blueLogo: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${logo}) !important`,
    width: 248 - 24,
    height: 20,
    [theme.breakpoints.up('md')]: {
      width: 248,
      height: 26,
      backgroundPosition: 'center',
      '&.mini': {
        width: 230,
        height: 20
      }
    }
  },
  logoCondensed: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${whiteLogoCondensedImage}) !important`,
    width: 37,
    height: 37,
    marginBottom: theme.spacing()
  },
  logoCondensedAlt: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${logoCondensedAlt}) !important`,
    width: 37,
    height: 37,
    marginBottom: theme.spacing()
  },
  logoCondensedDark: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundImage: `url(${greenLogoCondensed}) !important`,
    width: '30px !important',
    height: '38px !important',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: '71px !important',
      height: '63px !important'
    }
  },
  widgetContainer: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'stretch',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },

  buttonContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  menuButton: {
    fontWeight: 300,
    fontSize: '1.25em',
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.contrastText,
    borderWidth: 1,
    borderStyle: 'solid',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing()
  },
  menuButtonNoBorder: {
    fontWeight: 300,
    fontSize: '1.25em',
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  },
  drawerButton: {
    padding: 0,
    color: theme.palette.primary.contrastText
  },

  menuIcon: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 48,
      height: 48
    }
  },
  avatarBg: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main
  }
}))

export default useStyles
