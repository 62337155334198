import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 260,
    [theme.breakpoints.up('lg')]: {
      width: 440
    }
  },
  input: {
    margin: 0,
    marginBottom: theme.spacing(),
    backgroundColor: '#E6E6E6',
    '& fieldset': {
      border: 'none'
    }
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: '.9rem',
    fontWeight: 500
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  bottomParagraph: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    margin: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  errorMessage: {
    marginBottom: theme.spacing(),
    marginLeft: theme.spacing()
  }
}))
