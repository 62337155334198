import React from 'react'
import { Drawer, IconButton, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'
import { CustomizedTooltip } from 'shared'

import { useStyles } from './EventDrawer.style'

const EVENT_STATES = {
  SUSPENDED: 'suspended',
  RESULTS: 'results',
  ADMIN_MODE: 'adminMode'
}

const EventDrawer = ({
  children,
  hasEventResults,
  isSuspended,
  isMobile,
  open,
  setOpen,
  canEdit,
  afterEvent
}) => {
  const classes = useStyles()

  const handleOpen = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  return (
    <>
      {hasEventResults && !isMobile ? (
        <div className={clsx(classes.container, { [classes.containerClosed]: !open })}>
          <div className={classes.lineLeft}></div>
          <CustomizedTooltip
            title={open ? 'Contraer' : 'Expandir'}
            position={'bottom'}
            className={classes.tooltip}
            arrowClassName={classes.arrow}
            popperClassName={classes.tooltipPopper}>
            {({ handleTooltip, handleTooltipClose }) => {
              return (
                <IconButton
                  className={classes.openButton}
                  aria-label='open drawer'
                  onClick={handleOpen}
                  onMouseOut={handleTooltipClose}
                  onMouseOver={handleTooltip}>
                  {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              )
            }}
          </CustomizedTooltip>
          <Drawer
            variant='persistent'
            anchor='right'
            open={open}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}>
            <div
              className={clsx(
                classes.rowContainer,
                hasEventResults && EVENT_STATES.RESULTS,
                isSuspended && EVENT_STATES.SUSPENDED
              )}>
              <Typography className={classes.title}>
                Evento {afterEvent ? 'finalizado' : 'en curso'}
              </Typography>
              {children}
            </div>
          </Drawer>
        </div>
      ) : (
        <div
          className={clsx(
            classes.rowContainer,
            hasEventResults && EVENT_STATES.RESULTS,
            isSuspended && EVENT_STATES.SUSPENDED,
            isMobile && canEdit && EVENT_STATES.ADMIN_MODE
          )}>
          {children}
        </div>
      )}
    </>
  )
}

export default EventDrawer
