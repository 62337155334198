import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: '#ffffff'
  },
  mapContainer: {
    height: '75vh'
  }
}))

export default useStyles
