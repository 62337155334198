import React, { useRef, useState } from 'react'
import { Cropper } from 'react-cropper'
import { Button } from '@material-ui/core'
import clsx from 'clsx'
import { LayoutDialog } from 'shared'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './CropperDialog.style'

import 'cropperjs/dist/cropper.css'

const CropperDialog = ({ openDialog, onClose, title, onSave, defaultImage }) => {
  const [srcCropper, setSrcCropper] = useState(defaultImage)
  const [originalFile, setOriginalFile] = useState(defaultImage)
  const [showOrientIcon, setShowOrientIcon] = useState(false)

  const cropperRef = useRef(null)
  const inputFileRef = useRef()
  const classes = useStyles()

  const resetValues = () => {
    setSrcCropper(null)
    setOriginalFile(null)
  }

  const handleFile = async (event) => {
    if (event.target.files.length === 0) return

    const file = event.target.files[0]
    const { type: imageType } = file
    const validImageFormats = ['image/jpg', 'image/jpeg']

    if (!validImageFormats.includes(imageType)) {
      showSnackbarError({
        message: 'El archivo seleccionado no tiene un formato de imagen valido'
      })
      return
    }

    event.target.value = ''
    const reader = new FileReader()
    reader.onload = () => setSrcCropper(reader.result)
    setOriginalFile(file)
    reader.readAsDataURL(file)
  }

  const getCropper = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper

    return cropper
  }

  const handleOnchangeFile = () => {
    const { current } = inputFileRef
    current.click()
  }

  const handleSave = () => onSave(getCropper(), originalFile)

  const handleCloseDialog = () => {
    onClose()
    resetValues()
  }

  const handleOnCrop = () => {
    const cropper = getCropper()
    setShowOrientIcon(cropper.cropBox?.clientWidth < 300)
  }

  return (
    <LayoutDialog
      open={openDialog}
      onClose={handleCloseDialog}
      title={title}
      titleClassName={classes.title}
      contentClassName={classes.bodyContainer}>
      <div className={classes.cropperContainer}>
        <Cropper
          src={srcCropper}
          className={clsx(classes.cropper, showOrientIcon && 'orient-icon')}
          dragMode='move'
          guides={false}
          background={false}
          ref={cropperRef}
          crop={handleOnCrop}
          viewMode={1}
          aspectRatio={2.05}
          autoCropArea={0.85}
          modal={true}
        />
      </div>
      <div className={classes.actionButtonContainer}>
        <div className={classes.editButtonContainer}>
          <Button
            color='primary'
            variant='contained'
            className={classes.button}
            onClick={handleOnchangeFile}>
            Cambiar imagen
          </Button>
          <input
            ref={inputFileRef}
            type='file'
            style={{ display: 'none' }}
            accept='.JPG, .JPEG'
            onChange={handleFile}
          />
          <Button
            color='primary'
            variant='contained'
            disabled={!originalFile}
            className={classes.saveButton}
            onClick={handleSave}>
            Guardar
          </Button>
        </div>
      </div>
    </LayoutDialog>
  )
}

export default CropperDialog
