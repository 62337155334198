import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '30%'
    }
  },
  mainContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 540
    }
  },
  containerHeader: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.secondary.contrastText,
    minHeight: 65,
    [theme.breakpoints.up('md')]: {
      minHeight: 92
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 400,
    fontSize: '1.625rem',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      lineHeight: '35px',
      fontSize: '2.375rem'
    }
  },
  subTitle: {
    fontSize: '1.625rem',
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      lineHeight: '35px',
      fontSize: '2.375rem'
    }
  },
  list: {
    listStyle: 'none',
    textAlign: 'left',
    color: 'black',
    paddingLeft: 0,
    fontWeight: 550,
    margin: 0,
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(),
    borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
    '& li': {
      fontSize: '1rem',
      paddingTop: theme.spacing(),
      paddingBottom: 18,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        fontSize: '1.375rem'
      }
    }
  },
  buyButton: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.primary.contrastText,
    alignSelf: 'flex-start',
    fontSize: '1.1rem',
    marginLeft: theme.spacing(),
    borderRadius: 14,
    padding: 0,
    marginTop: 20,
    width: 160,
    height: 40,
    '& span': {
      fontSize: '1.0625rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem'
      }
    },
    '&:hover': {
      backgroundColor: theme.utils.rgba(theme.palette.secondary.contrastText, 0.85)
    },
    [theme.breakpoints.up('md')]: {
      width: 248,
      height: 45
    }
  },
  textAction: {
    fontWeight: 700,
    color: theme.palette.secondary.contrastText,
    fontFamily: theme.typography.fontFamilyAlt,
    fontSize: '1.5rem',
    marginLeft: theme.spacing(),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      fontSize: '3.125rem'
    }
  },
  containerPrice: {
    display: 'flex',
    alignItems: 'baseline',

    '& h1, h4,h2  ': {
      fontWeight: 700
    }
  },
  textDescription: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.375rem'
    }
  },

  containerTextAnotation: {
    display: 'flex',
    width: '100%',
    marginTop: 20,
    justifyContent: 'center',
    '& div': {
      width: '90%'
    },
    '& p': {
      fontSize: '.9rem',
      width: '100%',
      color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      marginTop: 20,
      justifyContent: 'flex-start',
      '& div': {
        width: '90%'
      }
    }
  },
  textDescriptionContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(2),
    borderLeft: `1px solid ${theme.palette.secondary.contrastText}`
  }
}))
