import React from 'react'
import { Typography, useMediaQuery } from '@material-ui/core'

import { useStyles } from './HeaderBox.style'

const HeaderBox = ({ titles = [] }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  return (
    <div className={classes.headerContainer}>
      <div className={classes.titleContainer}>
        <Typography align='center' color='primary' variant='h5' id='mobileTitle'>
          {isMobile ? titles[0] : titles.join(' / ')}
        </Typography>
      </div>
    </div>
  )
}

export default HeaderBox
