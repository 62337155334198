import React from 'react'
import { numericFormatter } from 'react-number-format'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import moment from 'moment'
import { Transition } from 'utils'
import { PRICE_FORMAT } from 'utils/constants'

import { useStyles } from './ConfirmInscriptionDialog.style'

const genderDictionary = {
  Female: 'Femenino',
  Male: 'Masculino',
  NonBinary: 'No binario',
  Other: 'Otro'
}

const DISCOUNT_TYPE = {
  FIXED_AMOUNT: 'FixedAmount',
  PERCENTAGE: 'Percentage'
}

const TICKET_FREE_PRICE = 0

const ConfirmInscriptionDialog = ({
  handleOnClose,
  open,
  data = {},
  onConfirm,
  formData = {},
  title = 'Confirmá tu inscripción'
}) => {
  const classes = useStyles()

  const {
    categoryName,
    distanceName,
    firstName,
    lastName,
    idNumber,
    gender,
    birthdate,
    eMail,
    phone,
    paymentTypeName,
    ticketTypePrice,
    ticketTypeName,
    ticketType = {},
    eventDiscountCode = {},
    saleOrder = {}
  } = data

  const birthDateFormat = moment(birthdate).format('DD/MM/YY')

  const discount =
    !!eventDiscountCode && eventDiscountCode.fixedAmount > ticketType.price
      ? ticketType.price
      : eventDiscountCode?.fixedAmount
  const finalPrice = eventDiscountCode ? saleOrder.totalAmount : ticketTypePrice

  return (
    <Dialog
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      fullWidth
      TransitionComponent={Transition}
      open={open}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' className={classes.title}>
            {title}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.bodyContainer}>
          <div className={classes.infoContainer}>
            <Typography className={classes.subTitle} variant='h6' color='primary'>
              Corroborá que los siguientes datos sean correctos
            </Typography>
            <Typography className={classes.field}>
              Nombre:&nbsp;
              <strong>
                {firstName} {lastName}
              </strong>
            </Typography>
            <Typography className={classes.field}>
              DNI: <strong>{idNumber}</strong>
            </Typography>
            <Typography className={classes.field}>
              Género: <strong>{genderDictionary[gender]}</strong>
            </Typography>
            <Typography className={classes.field}>
              Fecha de nacimiento: <strong>{birthDateFormat}</strong>
            </Typography>
            <Typography className={classes.field}>
              Email: <strong>{eMail}</strong>
            </Typography>
            <Typography className={classes.field}>
              Teléfono: <strong>{phone}</strong>
            </Typography>
            <Typography className={classes.field}>
              Distancia: <strong>{distanceName}</strong>
            </Typography>
            <Typography className={classes.field}>
              Categoría: <strong>{categoryName}</strong>
            </Typography>
            <Typography className={classes.field}>
              Ticket:&nbsp;
              <strong>{ticketTypeName}</strong>
            </Typography>
            {eventDiscountCode && (
              <Typography className={classes.field}>
                Descuento aplicado:{' '}
                <strong>
                  {eventDiscountCode.discountType === DISCOUNT_TYPE.FIXED_AMOUNT
                    ? numericFormatter(Number(discount).toString(), {
                        ...PRICE_FORMAT,
                        fixedDecimalScale: !Number.isInteger(discount)
                      })
                    : `${eventDiscountCode.percentage}%`}
                </strong>
              </Typography>
            )}
            <Typography>
              Importe a pagar:&nbsp;
              <strong>
                {formData.isFree
                  ? 'Sin costo'
                  : numericFormatter(Number(finalPrice).toString(), {
                      ...PRICE_FORMAT,
                      fixedDecimalScale: !Number.isInteger(finalPrice)
                    })}
              </strong>
            </Typography>

            {paymentTypeName && finalPrice !== TICKET_FREE_PRICE && (
              <Typography className={classes.field}>
                Modalidad de pago: <strong>{paymentTypeName}</strong>
              </Typography>
            )}

            <div className={classes.actionButton}>
              <Button variant='contained' color='primary' onClick={handleOnClose}>
                Editar
              </Button>
              <Button variant='contained' color='primary' onClick={onConfirm}>
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmInscriptionDialog
