import React from 'react'

import useStyles from './Marker.style'

const Marker = () => {
  const classes = useStyles()
  return <div className={classes.marker} />
}

export default Marker
