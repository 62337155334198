import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minHeight: '350px !important',
    [theme.breakpoints.up('md')]: {
      width: 600,
      maxWidth: 600
    }
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2)
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(3, 0)
  },
  title: {
    color: '#696969'
  },
  button: {
    alignSelf: 'center',
    height: 41,
    maxWidth: 200,
    width: '45%'
  }
}))
