import moment from 'moment'
import { AuthProxy, setAuthorizationHeader, UserAccountsProxy, UserProfilesProxy } from 'services'
import { verifyUserLogged } from 'state/modules/app'

import * as types from './types'

const JWT_KEY = process.env.REACT_APP_JWT_KEY
// Action Creators
export const closeSession = () => ({
  type: types.CLOSE_SESSION
})

export const restoreSession = (jwt) => ({
  type: types.RESTORE_SESSION,
  user: {
    id: jwt.id,
    username: jwt.username,
    loggedAt: new Date(),
    expiredAt: jwt.expiredAt
  }
})

export const signInAttempt = () => ({
  type: types.SIGN_IN_ATTEMPT
})

export const signInSuccess = (id, username, expiredAt) => ({
  type: types.SIGN_IN_SUCCESS,
  user: {
    id,
    username,
    loggedAt: new Date(),
    expiredAt
  }
})

export const signInFailure = (error) => ({
  type: types.SIGN_IN_FAILURE,
  error
})

// Thunks
export const signIn = (username, password) => async (dispatch) => {
  try {
    dispatch(signInAttempt())

    const proxy = new AuthProxy()
    const jwt = await proxy.login(username, password)

    if (jwt === false) {
      dispatch(signInFailure())
      return false
    }

    localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
    const { exp } = JSON.parse(atob(jwt.auth_token.split('.')[1]))
    setAuthorizationHeader(jwt.auth_token)
    dispatch(signInSuccess(jwt.id, jwt.username, exp))
    return true
  } catch (error) {
    dispatch(signInFailure(error))
    return false
  }
}

export const signOut = () => (dispatch) => {
  localStorage.removeItem(JWT_KEY)
  setAuthorizationHeader() // clears the auth header
  dispatch(closeSession())
  dispatch(setSignUpData(undefined))
}

export const verifyToken = () => (dispatch) => {
  setAuthorizationHeader()

  const jwtJson = localStorage.getItem(JWT_KEY)
  if (jwtJson) {
    const jwt = JSON.parse(jwtJson)
    const { exp } = JSON.parse(atob(jwt.auth_token.split('.')[1]))
    dispatch(restoreSession({ ...jwt, expiredAt: exp }))
    setAuthorizationHeader(jwt.auth_token)
    return true
  }
}

export const verifyExpiredToken = (user) => (dispatch) => {
  const { expiredAt } = user
  if (expiredAt < moment().unix() && localStorage.getItem(JWT_KEY)) {
    dispatch(closeSession())
    setAuthorizationHeader()
    localStorage.removeItem(JWT_KEY)
    return true
  }
}

export const googleSignIn = (credentials) => async (dispatch) => {
  try {
    dispatch(signInAttempt())

    const proxy = new AuthProxy()
    const jwt = await proxy.googleLogin(credentials)
    localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
    setAuthorizationHeader(jwt.auth_token)

    dispatch(signInSuccess(jwt.id, credentials))
    return true
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch(setSignUpData(credentials))
    } else {
      dispatch(signInFailure(error))
    }

    return false
  }
}

export const facebookSignIn = (fbResponse) => async (dispatch) => {
  try {
    dispatch(signInAttempt())

    const { userID, accessToken } = fbResponse
    const proxy = new AuthProxy()
    const jwt = await proxy.facebookLogin(userID, accessToken)
    jwt.username = userID
    localStorage.setItem(JWT_KEY, JSON.stringify(jwt))
    setAuthorizationHeader(jwt.auth_token)
    dispatch(signInSuccess(jwt.id, userID))
    return true
  } catch (error) {
    console.log(error)
    // handle this situation as:
    // 1. If the request returns a 400 (bad request) status, the account doesn't exists.
    //  so, we must dispatch setSignUpData
    if (error.response && error.response.status === 400) {
      dispatch(setSignUpData(fbResponse))
    } else {
      // 2. The request has failed for another reason
      //  so, dispatch the error
      dispatch(signInFailure(error))
    }

    return false
  }
}

export const signUpAttempt = () => ({
  type: types.SIGN_UP_ATTEMPT
})

export const signUpSuccess = () => ({
  type: types.SIGN_UP_SUCCESS
})

export const signUpFailure = (error) => ({
  type: types.SIGN_UP_FAILURE,
  error
})

export const setSignUpData = (signUpData) => ({
  type: types.SET_SIGN_UP_DATA,
  signUpData
})

export const loadUserDataAttempt = () => ({
  type: types.LOAD_USER_DATA_ATTEMPT
})

export const loadUserDataSuccess = (userData) => ({
  type: types.LOAD_USER_DATA_SUCCESS,
  userData
})

export const loadUserDataFailure = (error) => ({
  type: types.LOAD_USER_DATA_FAILURE,
  error
})

export const manualActivationAttempt = () => ({
  type: types.MANUAL_ACTIVATION_ATTEMPT
})

export const manualActivationSuccess = () => ({
  type: types.MANUAL_ACTIVATION_SUCCESS
})

export const manualActivationFailure = (error) => ({
  type: types.MANUAL_ACTIVATION_FAILURE,
  error
})

// Thunks
export const signUp = (userAccount) => async (dispatch) => {
  try {
    dispatch(signUpAttempt())

    const proxy = new UserAccountsProxy()
    await proxy.register(userAccount)
    dispatch(signUpSuccess())
    return true
  } catch (error) {
    dispatch(signUpFailure(error))
    return false
  }
}

export const facebookSignUp = (userAccount, fbResponse) => async (dispatch) => {
  try {
    dispatch(signUpAttempt())
    userAccount.facebookId = fbResponse.authResponse.userID
    const proxy = new UserAccountsProxy()
    await proxy.registerFacebook(userAccount, fbResponse.authResponse.accessToken)
    dispatch(signUpSuccess())

    // login directly
    dispatch(facebookSignIn(fbResponse.authResponse))
    return true
  } catch (error) {
    dispatch(signUpFailure(error))
    return false
  }
}

export const googleSignUp = (userAccount, googleCredentials) => async (dispatch) => {
  try {
    dispatch(signUpAttempt())
    const proxy = new UserAccountsProxy()
    await proxy.registerGoogle(userAccount, googleCredentials)
    dispatch(signUpSuccess())

    // login directly
    dispatch(googleSignIn(googleCredentials))
    return true
  } catch (error) {
    dispatch(signUpFailure(error))
    return false
  }
}

export const recoverAccountAttempt = () => ({
  type: types.RECOVER_ACCOUNT_ATTEMPT
})

export const recoverAccountSuccess = () => ({
  type: types.RECOVER_ACCOUNT_SUCCESS
})

export const recoverAccountFailure = (error) => ({
  type: types.RECOVER_ACCOUNT_FAILURE,
  error
})

export const recoverAccount = (email) => async (dispatch) => {
  try {
    dispatch(recoverAccountAttempt())

    const proxy = new UserAccountsProxy()
    await proxy.sendRecoverInstructions({ UsernameOrEmail: email })
    dispatch(recoverAccountSuccess())
    return true
  } catch (error) {
    dispatch(recoverAccountFailure(error))
    return false
  }
}

export const resendEmail = (email) => async (dispatch) => {
  const proxy = new UserAccountsProxy()
  await proxy.resendActivationEmail(email)
}

export const loadWelcomeUserAttempt = () => ({
  type: types.LOAD_WELCOME_USER_ATTEMPT
})

export const loadWelcomeUserSuccess = (userData) => ({
  type: types.LOAD_WELCOME_USER_SUCCESS,
  userData
})

export const loadWelcomeUserFailure = (error) => ({
  type: types.LOAD_WELCOME_USER_FAILURE,
  error
})

export const loadWelcomeUser = (key) => async (dispatch) => {
  try {
    dispatch(loadWelcomeUserAttempt())

    const proxy = new UserAccountsProxy()
    const user = await proxy.getByKey(key)
    dispatch(loadWelcomeUserSuccess(user))
    dispatch(verifyUserLogged({ state: true }))
    return true
  } catch (error) {
    dispatch(loadWelcomeUserFailure(error))
    return false
  }
}

export const resetPasswordAttempt = () => ({
  type: types.RESET_PASSWORD_ATTEMPT
})

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS
})

export const resetPasswordFailure = (error) => ({
  type: types.RESET_PASSWORD_FAILURE,
  error
})

export const resetPassword = (resetPasswordModel) => async (dispatch) => {
  try {
    dispatch(resetPasswordAttempt())

    const proxy = new UserAccountsProxy()
    await proxy.resetPassword(resetPasswordModel)
    dispatch(resetPasswordSuccess())
    return true
  } catch (error) {
    dispatch(resetPasswordFailure(error))
    return false
  }
}

export const loadOrganizationsAttempt = () => ({
  type: types.LOAD_ORGANIZATIONS_ATTEMPT
})

export const loadOrganizationsSuccess = (organizations) => ({
  type: types.LOAD_ORGANIZATIONS_SUCCESS,
  organizations
})

export const loadOrganizationsFailure = (error) => ({
  type: types.LOAD_ORGANIZATIONS_FAILURE,
  error
})

export const loadOrganizations = (userAccountId) => async (dispatch) => {
  try {
    dispatch(loadOrganizationsAttempt())

    const proxy = new UserAccountsProxy()
    const organizationsPage = await proxy.getOrganizations(userAccountId)
    const adminOrganizations = organizationsPage.results.filter((org) => org.isAdmin)
    dispatch(loadOrganizationsSuccess(adminOrganizations))
    return true
  } catch (error) {
    dispatch(loadOrganizationsFailure(error))
    return false
  }
}

export const loadUserData = (userId) => async (dispatch) => {
  try {
    dispatch(loadUserDataAttempt())
    const proxy = new UserProfilesProxy()
    const data = await proxy.get(userId)
    dispatch(loadUserDataSuccess(data))
    return data
  } catch (error) {
    dispatch(loadUserDataFailure(error))
  }
}

export const manualActivation = (userAccount) => async (dispatch) => {
  try {
    dispatch(manualActivationAttempt())

    const proxy = new UserAccountsProxy()
    const data = await proxy.manualActivation(userAccount)

    dispatch(manualActivationSuccess(data))
    return data
  } catch (error) {
    dispatch(manualActivationFailure(error))
  }
}
