import React, { useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { PaymentLocationCard, SectionLayoutDialog } from 'shared'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './DeletePaymentLocationDialog.style'

const DeletePaymentLocationDialog = ({ open, onClose, paymentLocation, title, onDelete }) => {
  const classes = useStyles()

  const { values, setFieldValue } = useFormikContext()

  const [deletePending, setDeletePending] = useState(false)

  const handleDelete = async () => {
    try {
      setDeletePending(true)
      await onDelete()
    } catch (error) {
      showSnackbarError(error)
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={onClose}
      title='ELIMINAR PUNTO FISICO'
      className={classes.dialog}
      contentClassName={classes.container}>
      <header className={classes.header}>
        <Typography className={classes.title}>¿Desea eliminar el siguiente lugar pago?</Typography>
      </header>
      <PaymentLocationCard
        {...{ values, setFieldValue }}
        paymentLocation={paymentLocation}
        title={title}
      />

      <div className={classes.buttonsContainer}>
        <Button color='primary' variant='contained' className={classes.button} onClick={onClose}>
          Cancelar
        </Button>
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          endIcon={deletePending && <CircularProgress color='primary' size={15} />}
          disabled={deletePending}
          onClick={handleDelete}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default DeletePaymentLocationDialog
