import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { FileViewerContent } from 'shared'
import { downloadResultsReport, loadEventResultsReportUrl } from 'state/modules/events'

import { useStyles } from './EventResultsDialog.style'

const EventResultsDialog = ({ event, open, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { eventResultsReportUrl, downloadResultsReportPending } = useSelector(
    (state) => state.events
  )

  useEffect(() => {
    const hasResultsReport = event.hasResults && !event.hasResultsSheetFile

    if (hasResultsReport) dispatch(loadEventResultsReportUrl(event.id))
  }, [dispatch])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        classes: {
          root: classes.dialog
        }
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop
        }
      }}
      className={classes.container}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.headerContainer}>
          <Typography color='primary' align='center' variant='h6' className={classes.title}>
            {event.name}
          </Typography>
          <IconButton color='primary' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.resultHeaderContainer}>
          <Typography color='secondary' variant='h6' align='center' className={classes.title}>
            RESULTADOS
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FileViewerContent
          fileExtension='application/pdf'
          fileUrl={eventResultsReportUrl}
          fileViewerClassName={classes.fileViewer}
        />
      </DialogContent>

      <Button
        color='primary'
        variant='contained'
        className={classes.downloadResultButton}
        disabled={downloadResultsReportPending}
        endIcon={downloadResultsReportPending && <CircularProgress size={16} color='primary' />}
        onClick={() => dispatch(downloadResultsReport(event))}>
        Descargar resultados
      </Button>
    </Dialog>
  )
}

export default EventResultsDialog
