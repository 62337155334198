import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: '#696969',
    [theme.breakpoints.down('md')]: {
      fontSize: '.9rem'
    }
  },
  paymentList: {
    padding: 0
  },
  payment: {
    display: 'list-item',
    listStyleType: 'circle',

    '&.paymentPending': {
      minHeight: 180
    }
  },
  paymentTitle: {
    fontSize: '.975rem',
    '& strong': {
      fontWeight: 600
    }
  },
  formControl: {
    margin: 0,
    padding: theme.spacing(2, 0)
  },
  checkbox: {
    '& svg': {
      color: theme.palette.primary.main
    },
    '&.hide': {
      display: 'none'
    },
    '&.paymentDisabled': {
      '& svg': {
        color: '#969696'
      }
    }
  },
  label: {
    fontSize: '.925rem',
    fontWeight: 600
  },
  button: {
    alignSelf: 'center',
    height: 41,
    width: 215
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  option: {
    display: 'flex',
    gap: theme.spacing(),
    alignItems: 'center'
  },
  pendingBindContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing()
  },
  pendingBindText: {
    color: '#FF9D29',
    fontSize: '.925rem',
    fontWeight: 600
  },
  copyButton: {
    height: 35,
    width: 35
  },
  copyIcon: {
    height: 20,
    width: 20
  },
  copyText: {
    fontWeight: 600,
    fontSize: '.825rem'
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    marginBottom: theme.spacing()
  },
  text: {
    fontWeight: 500,
    fontSize: '.875rem'
  },
  tooltip: {
    backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.3),
    paddingLeft: theme.spacing(3),
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.up('md')]: {
      width: 300
    }
  },
  arrowTooltip: {
    color: theme.utils.rgba(theme.palette.primary.main, 0.3)
  },
  pendingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    gap: theme.spacing()
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  switchText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: 5
  },
  switchTooltip: {
    width: 260,
    marginBottom: 25,
    borderEndStartRadius: 0
  },
  messageText: {
    fontWeight: 600
  }
}))
