import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    marginRight: 40,

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'baseline'
    }
  },
  calendarDate: {
    background: theme.palette.secondary.main,
    width: 110,
    maxHeight: 23,
    marginBottom: 5,
    flexShrink: 0,
    fontWeight: 600,
    fontSize: '1.1em',
    textAlign: 'center',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      marginRight: 10
    }
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    '& h6': {
      fontSize: '1.1em',
      maxWidth: 450,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& h6': {
        fontSize: '1.1em',
        fontWeight: 600,
        textOverflow: 'ellipsis'
      }
    }
  },

  social: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginRight: 30,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(1),
      marginRight: 0
    }
  },
  iconFavorite: {
    cursor: 'pointer',
    alignSelf: 'center',
    fontSize: '1.8em',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7em'
    }
  },
  areaLevel: {
    display: 'flex',
    '& h6': {
      fontSize: '1em'
    }
  },
  containerLike: {
    display: 'flex',
    alignItems: 'baseline'
  },

  containerLink: {
    display: 'flex',
    alignItems: 'baseline'
  },
  linkResult: {
    textDecoration: 'underline',
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: '.9em',
    flexShrink: 0
  }
}))
