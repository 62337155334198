import { makeStyles } from '@material-ui/core'
import imageDesktop from 'assets/img/landing-are-you-ready.jpg'
import imageMobile from 'assets/img/landing-start.jpg'

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    minWidth: '100%',
    backgroundImage: `url(${imageMobile})`,
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${imageDesktop})`
    }
  },
  WhiteBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.7932422969187676) 0%, rgba(255,255,255,0.7932422969187676) 37%, rgba(255,255,255,0) 100%)',
    [theme.breakpoints.up('md')]: {
      background:
        'linear-gradient(to right, rgba(255,255,255,0.7932422969187676) 0%, rgba(255,255,255,0.7932422969187676) 37%, rgba(255,255,255,0) 100%)'
    }
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '170px 16px',
    [theme.breakpoints.up('md')]: {
      padding: '200px 80px'
    },
    zIndex: 1
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
    zIndex: 1
  },
  paragraph: {
    fontSize: '1.168rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    marginTop: '1.875rem',
    zIndex: 1,
    width: 350,
    '& br': {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      width: 530,
      '& br': {
        display: 'initial'
      }
    }
  },
  subtext: {
    fontWeight: 700,
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    marginTop: '1.25rem',
    zIndex: 1
  },
  button: {
    width: '10rem',
    height: '2.856rem',
    background: theme.palette.primary.main,
    borderRadius: '0.625rem',
    marginTop: '3.125rem',
    '& span': {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: '#ffff'
    },
    [theme.breakpoints.up('md')]: {
      width: '15.4375rem',
      height: '3rem'
    }
  }
}))
