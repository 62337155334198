import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3)
    }
  },
  dateLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem'
  }
}))
