import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    padding: theme.spacing(0, 2),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up('lg')]: {
      padding: 0
    }
  },

  appNavBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 3000,
    height: 80,
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      top: 20,
      left: 0,
      height: 80,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2)
    }
  },
  sportmetricLogo: {
    width: 210,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      width: 180,
      height: 53,
      backgroundPosition: 'center'
    }
  },
  closeButton: {
    color: theme.palette.primary.contrastText
  },
  mainContainer: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      padding: 0,
      width: 1200
    }
  },
  headerContainer: {
    width: '100%',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(18),
      paddingBottom: theme.spacing(4)
    }
  },
  bestOption: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    color: theme.palette.primary.main,
    width: 258,
    fontSize: '1.75rem',
    boxSizing: 'inherit',
    '& svg': {
      animationDelay: '1500ms !important'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem',
      width: 395
    }
  },
  title: {
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 600,
    fontSize: '1.75rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.6875rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(5, 0)
  },
  buttonBasic: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 10,

    width: 190,
    height: 41,
    '& span': {
      fontFamily: theme.typography.fontFamilyAlt,
      color: 'white',
      fontWeight: 700,
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.6875rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
      height: 67
    }
  },
  buttonProfessional: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
    width: 190,
    height: 41,

    '& span': {
      fontFamily: theme.typography.fontFamilyAlt,
      color: theme.palette.primary.main,
      fontWeight: 700,
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1.6875rem'
      }
    },
    [theme.breakpoints.up('md')]: {
      width: 300,
      height: 67
    }
  },
  optionsCotainer: {
    display: 'flex',
    gridGap: 50,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up(1190)]: {
      flexDirection: 'row',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  titleOptionContainer: {
    display: 'flex',
    height: 41,
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    '&.secondary': {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      height: 68
    },
    [theme.breakpoints.up('lg')]: {
      width: 583
    }
  },
  titleOption: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    padding: 10,
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 700,
    fontSize: '1.5rem',

    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    }
  },
  subTextOption: {
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      width: 583
    }
  },
  endButton: {
    color: theme.palette.primary.main,
    bottom: -5,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  subTextOptionAlt: {
    fontWeight: 600,
    padding: theme.spacing(1, 2),
    fontSize: '1.125rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      lineHeight: '30px',
      padding: theme.spacing(1, 0)
    },
    [theme.breakpoints.up('lg')]: {
      width: 583
    }
  },
  titleOptionProfessional: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    padding: 10,
    fontFamily: theme.typography.fontFamilyAlt,
    fontWeight: 700,
    fontSize: '1.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem'
    }
  },
  option: {
    display: 'flex',
    gap: theme.spacing(2),

    padding: '15px 0'
  },
  iconCheck: {
    marginTop: theme.spacing(0.4),
    width: 18,
    height: 18
  },
  optionText: {
    fontWeight: 500,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.125rem'
    }
  },
  optionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('lg')]: {
      width: 583
    }
  },
  infoContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 500
    }
  }
}))
