import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { AttachFileOutlined as AttachFileOutlinedIcon } from '@material-ui/icons'
import { SaleOrdersProxy } from 'services'
import { CustomTextField, FileViewerDialog, SectionLayoutDialog } from 'shared'
import { loadEventInscriptionByIdNumberAndCountry } from 'state/modules/events'
import { showSnackbarError } from 'utils/snackbar'

import { useStyles } from './UploadReceiptDialog.style'

const UploadReceiptDialog = ({ open, onClose, saleOrder }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const { eventInscription } = useSelector((state) => state.events)

  const [openFileViewerDialog, setOpenFileViewerDialog] = useState(false)
  const [fileSrc, setFileSrc] = useState(null)
  const [originalFile, setOriginalFile] = useState(null)
  const [uploadReceiptPending, setUploadReceiptPending] = useState(false)

  const { firstName, lastName, idNumber, paymentType } = eventInscription

  const handleChangeFile = () => inputRef.current.click()

  const handleFile = async (event) => {
    if (event.target.files.length === 0) return

    const file = event.target.files[0]
    const { type: fileType } = file
    const validFileFormats = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']

    if (!validFileFormats.includes(fileType)) {
      showSnackbarError({
        message:
          'El formato del comprobante no es válido, por favor seleccione un archivo con formato JPG, JPEG, PNG O PDF'
      })
      return
    }
    event.target.value = ''

    const reader = new FileReader()
    reader.onload = () => setFileSrc(reader.result)
    setOriginalFile(file)
    reader.readAsDataURL(file)
  }

  const handleUpdloadReceipt = async () => {
    try {
      setUploadReceiptPending(true)

      const payment = {
        amount: saleOrder.totalAmount,
        orderPayment: paymentType
      }

      const proxy = new SaleOrdersProxy()
      const saleOrderPayment = await proxy.addSaleOrderPayment(saleOrder.id, payment)
      await proxy.putSetReceiptFile(saleOrder.id, saleOrderPayment.id, originalFile)

      await dispatch(loadEventInscriptionByIdNumberAndCountry(eventInscription))

      handleOnClose()
    } catch (error) {
      showSnackbarError({ message: 'Ocurrió un error al intentar guardar el comprobante' })
    } finally {
      setUploadReceiptPending(false)
    }
  }

  const handleOnClose = () => {
    onClose()
    setOriginalFile(null)
    setFileSrc(null)
  }

  return (
    <SectionLayoutDialog
      open={open}
      onClose={handleOnClose}
      title='SUBIR COMPROBANTE'
      className={classes.dialog}
      contentClassName={classes.container}
      backDropClassName={classes.backDrop}>
      <div className={classes.mainContainer}>
        <Typography variant='h6' align='center'>
          {firstName} {lastName} - DNI {idNumber}
        </Typography>
        <Typography variant='h6'>
          Adjunte su comprobante de transferencia o foto del comprobante que le entregaron en el
          lugar de pago. Se admiten formatos .jpg .jpeg .png o .pdf
        </Typography>
        <div className={classes.content}>
          <div className={classes.uploadReceiptContainer}>
            <input ref={inputRef} type='file' style={{ display: 'none' }} onChange={handleFile} />

            <CustomTextField
              className={classes.textField}
              color='primary'
              variant='outlined'
              placeholder='Adjuntar Archivo'
              onClick={handleChangeFile}
              value={originalFile?.name ?? ''}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleChangeFile}
              className={classes.fileButton}>
              <AttachFileOutlinedIcon color='secondary' className={classes.attachIcon} />
            </Button>
          </div>
          {fileSrc && originalFile && (
            <>
              <Button
                variant='contained'
                color='primary'
                className={classes.receiptButton}
                onClick={() => setOpenFileViewerDialog(true)}>
                Ver mi comprobante
              </Button>
              <FileViewerDialog
                open={openFileViewerDialog}
                title={originalFile.name}
                fileUrl={fileSrc}
                fileExtension={originalFile.type.split('/')[1]}
                onClose={() => setOpenFileViewerDialog(false)}
              />
            </>
          )}
        </div>
        <Button
          color='primary'
          variant='contained'
          className={classes.acceptButton}
          disabled={!originalFile || uploadReceiptPending}
          endIcon={uploadReceiptPending && <CircularProgress color='primary' size={16} />}
          onClick={handleUpdloadReceipt}>
          Aceptar
        </Button>
      </div>
    </SectionLayoutDialog>
  )
}

export default UploadReceiptDialog
