import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  infoLabel: {
    fontSize: '1.25rem',
    fontWeight: 600
  },
  infoTitle: {
    fontSize: '.9rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    marginTop: theme.spacing(2)
  },
  infoText: {
    fontSize: '.85rem',
    fontWeight: 500,
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'row'
  },
  formInputClassName: {
    marginBottom: theme.spacing(4)
  },
  inputEdit: {
    '& input': {
      height: 19,
      color: theme.palette.primary.main
    }
  },
  asyncSelect: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '& > div > div >div': {
      color: theme.palette.primary.main
    }
  }
}))
