import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchWidget } from 'pages/HomePage/components'
import { ROUTES } from 'routes'
import { EventContainer, Footer, HeaderBox, MainLayout, NavBar, PageContainer } from 'shared'
import {
  loadCreatedByMe,
  loadLastResultForMe,
  loadUpcomingForMe,
  resetCreatedByMe,
  resetLastResultForMe,
  resetUpcomingForMe
} from 'state/modules/events'

import { useStyles } from './MyEventsPage.style'

const MyEventsPage = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const {
    lastResultEvents,
    lastResultEventsPending,
    upcomingEvents,
    createdEvents,
    createdEventsPending,
    upcomingEventsPending
  } = useSelector((state) => state.events)

  const classes = useStyles()

  useEffect(() => {
    if (user) {
      dispatch(loadLastResultForMe())
      dispatch(loadUpcomingForMe())
      dispatch(loadCreatedByMe())
    }
    return () => {
      dispatch(resetLastResultForMe())
      dispatch(resetUpcomingForMe())
      dispatch(resetCreatedByMe())
    }
  }, [dispatch, user])

  return (
    <MainLayout>
      <NavBar widget={(props) => <SearchWidget mini {...props} />} showWidget />
      <PageContainer>
        <div className={classes.mainContainer}>
          <HeaderBox titles={['Mis eventos']} />
          <div className={classes.bodyContainer}>
            <div className={classes.sectionContainer}>
              <EventContainer
                title='MIS EVENTOS CREADOS'
                events={createdEvents}
                allPageUrl={ROUTES.EVENTS.MY_CREATED_EVENTS}
                typeCard='createCard'
                prevArrowPosition={{ top: 40, left: -40 }}
                nextArrowPosition={{ top: 40, right: -40 }}
                isLoading={createdEventsPending}
                showOptions={!createdEventsPending && !!createdEvents.length}
                fetchNewPage={() => dispatch(loadCreatedByMe())}
              />
            </div>
            <div className={classes.sectionContainer}>
              <EventContainer
                title='MIS PRÓXIMOS EVENTOS'
                events={upcomingEvents}
                allPageUrl={ROUTES.EVENTS.MY_UPCOMING_EVENTS}
                prevArrowPosition={{ top: 170, left: -40 }}
                nextArrowPosition={{ top: 170, right: -40 }}
                isLoading={upcomingEventsPending}
                showOptions={!upcomingEventsPending && !!upcomingEvents.length}
                fetchNewPage={() => dispatch(loadUpcomingForMe())}
              />
            </div>
            <div className={classes.sectionContainer}>
              <EventContainer
                title='ÚLTIMOS RESULTADOS'
                events={lastResultEvents}
                allPageUrl={ROUTES.EVENTS.MY_LATEST_RESULT_EVENTS}
                typeCard='lastResultsCard'
                prevArrowPosition={{ top: 170, left: -40 }}
                nextArrowPosition={{ top: 170, right: -40 }}
                isLoading={lastResultEventsPending}
                showOptions={!lastResultEventsPending && !!lastResultEvents.length}
                fetchNewPage={() => dispatch(loadLastResultForMe())}
              />
            </div>
          </div>
        </div>
      </PageContainer>
      <Footer />
    </MainLayout>
  )
}

export default MyEventsPage
