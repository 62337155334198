import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    backgroundColor: 'white'
  },
  form: {
    minHeight: 470,
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 15)
    },
    '&.register': {
      [theme.breakpoints.up('lg')]: {
        marginTop: 40
      }
    }
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    minHeight: 60,
    gridGap: 0,
    [theme.breakpoints.up('lg')]: {
      gridGap: 50,
      width: '100%',
      gridTemplateColumns: '1fr 1fr'
    }
  },
  formContainer: {
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  initialText: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      height: 80
    }
  },
  initialTextSignUpGoogle: {
    display: 'flex',
    paddingRight: 20,
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      height: 80,
      marginTop: 50
    }
  },
  initialTextFirstPage: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20
  },
  labelStep: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16
  },
  input: {
    margin: 0,
    height: 49,
    '& fieldset': {
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '&::placeholder': {
      color: theme.palette.primary.main
    }
  },
  inputDatePicker: {
    margin: 0,
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main
    },
    '& label': {
      color: theme.palette.primary.main,
      transform: 'translate(14px, 11px) scale(1)'
    }
  },

  radioLabel: {
    color: theme.palette.primary.main,
    fontSize: '.9rem',
    fontWeight: 600
  },
  radioRoot: {
    color: theme.palette.primary.main
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem'
    }
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '.9rem',
    fontWeight: 600,
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(13),
    '& button': {
      fontSize: 18,
      padding: theme.spacing(1, 2),
      fontWeight: 500,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
      width: 250,
      height: 48,
      borderRadius: 10
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6)
    }
  },
  buttonContainerFirstForm: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(),
    '& button': {
      fontSize: 18,
      padding: theme.spacing(1, 2),
      fontWeight: 500,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
      width: 250,
      height: 48,
      borderRadius: 10
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    }
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.primary.main
    }
  },
  bottomParagraph: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    margin: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  separator: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    textAlign: 'center',
    backgroundImage: `linear-gradient(${theme.palette.primary.main},${theme.palette.primary.main}), linear-gradient(${theme.palette.primary.main},${theme.palette.primary.main})`,
    backgroundSize: '45% 1px',
    backgroundPosition: 'center left, center right',
    backgroundRepeat: 'no-repeat'
  },
  socialButton: {
    marginTop: theme.spacing(),

    [theme.breakpoints.up('lg')]: {
      width: 320
    }
  },
  tosParagraph: {
    color: '#969696',
    fontSize: '0.875rem',
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  asyncSelect: {
    marginBottom: theme.spacing(),
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
    width: '100%',
    borderRadius: 10,
    fontSize: '1rem',
    '& > div > div > div': {
      color: theme.palette.primary.main
    },
    '& svg': {
      color: theme.palette.secondary.main
    },
    '& > div': {
      border: 'none',
      boxShadow: 'none',
      borderRadius: 10
    },
    '& > div:hover': {
      border: 'none',
      boxShadow: 'none'
    },
    zIndex: 20
  },
  selectItem: {
    height: 45,
    '& input': {
      height: 3,
      color: theme.palette.primary.main
    },
    '& fieldset': {
      height: 45,
      borderColor: theme.palette.primary.main,
      borderRadius: 5
    },
    '& svg': {
      color: theme.palette.primary.main,
      height: 19
    },
    '& label': {
      color: theme.palette.primary.main,
      transform: 'translate(14px, 11px) scale(1)'
    }
  },
  labelSelect: {
    color: theme.palette.primary.main,
    transform: 'translate(14px, 11px) scale(1)'
  },
  formGroup: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    color: `${theme.palette.error.main} !important`,
    fontSize: 12
  },
  rolesParagraph: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.primary.main
  },
  rolesContainer: {
    marginBottom: 20,
    '& > div': {
      margin: theme.spacing(1, 0)
    },
    '& label': {
      height: 60
    },
    '& span': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('sm')]: {
      '& label': {
        height: 30
      }
    }
  },
  infoContainer: {
    '& > div': {
      margin: theme.spacing(2, 0)
    },
    '& label': {
      height: 30
    },
    '& span': {
      color: theme.palette.primary.main
    }
  },
  switchTooltip: {
    width: 260,
    marginBottom: 25,
    borderEndStartRadius: 0
  },
  viewAdminPopper: {
    zIndex: 1300,
    '& svg': {
      position: 'absolute',
      width: 40
    },
    width: 400,
    color: theme.palette.tooltip.main
  },
  inputText: {
    color: '#1D1B20',
    fontSize: 12
  },
  containerInputText: {
    marginLeft: 10
  },
  tooltip: {
    backgroundColor: `${theme.palette.tooltip.main} !important`,
    width: '400px !important',
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    }
  },
  arrowTooltip: {
    color: `${theme.palette.tooltip.main} !important`
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: 130
    }
  },
  signUpOptionsContainer: {
    width: '100%',
    height: 130,
    backgroundColor: theme.utils.rgba('#D9D9D9', 0.7),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  signInOptionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5, 10),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 10)
    }
  },
  signUpTitle: {
    fontSize: '1.25rem',
    width: 339
  },
  signUpButton: {
    borderRadius: 10,
    height: 46,
    width: 250,
    backgroundColor: theme.palette.primary.contrastText,

    '& span': {
      fontSize: '1rem'
    }
  },
  formControl: {
    width: '100%'
  },
  closeButton: {
    top: '10px !important',
    right: '100px !important',
    '& svg': {
      height: '12px !important',
      width: '12px !important'
    }
  },
  selectRolesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  googleLoginContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: 46,
    width: 250,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.contrastText,
    '& > div': {
      width: 240,
      marginTop: 2
    },
    '& div': {
      border: 'none'
    }
  },
  buttonContainerGoogleSignUp: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(),
    '& button': {
      fontSize: 18,
      padding: theme.spacing(1, 2),
      fontWeight: 500,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(2),
      width: 250,
      height: 48,
      borderRadius: 10
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end'
    }
  }
}))

export default useStyles
