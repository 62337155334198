import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  section: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10)
    }
  },
  button: {
    position: 'absolute',
    bottom: 20,
    left: '40%',
    color: theme.palette.primary.contrastText,
    zIndex: 100,
    '& svg': {
      width: 50,
      height: 50
    },
    [theme.breakpoints.up('md')]: {
      left: '48%',
      bottom: 0
    }
  }
}))
