import EventsProxy from 'services/EventsProxy'

import * as types from './types'

// Action Creators
export const loadUpcomingEventsAttempt = () => ({
  type: types.LOAD_UPCOMING_EVENTS_ATTEMPT
})

export const loadUpcomingEventsSuccess = (eventsPage) => ({
  type: types.LOAD_UPCOMING_EVENTS_SUCCESS,
  eventsPage
})

export const loadUpcomingEventsFailure = (error) => ({
  type: types.LOAD_UPCOMING_EVENTS_FAILURE,
  error
})

export const loadPastEventsAttempt = () => ({
  type: types.LOAD_PAST_EVENTS_ATTEMPT
})

export const loadPastEventsSuccess = (eventsPage) => ({
  type: types.LOAD_PAST_EVENTS_SUCCESS,
  eventsPage
})

export const loadPastEventsFailure = (error) => ({
  type: types.LOAD_PAST_EVENTS_FAILURE,
  error
})

export const resetUpcomingEvents = () => ({
  type: types.RESET_UPCOMING_EVENTS
})

export const resetPastEvents = () => ({
  type: types.RESET_PAST_EVENTS
})

// Thunks
export const loadUpcomingEvents = (searchObj) => async (dispatch, getState) => {
  try {
    const { upcomingPageNumber } = getState().home
    if (upcomingPageNumber === 1) {
      dispatch(resetUpcomingEvents())
    }
    dispatch(loadUpcomingEventsAttempt())

    const proxy = new EventsProxy()
    const eventsPage = await proxy.getUpcomingEvents(searchObj, upcomingPageNumber)
    dispatch(loadUpcomingEventsSuccess(eventsPage))
    return true
  } catch (error) {
    dispatch(loadUpcomingEventsFailure(error))
    // dispatch(showSnackbarError(error))
    return false
  }
}

export const loadPastEvents = (searchObj) => async (dispatch, getState) => {
  try {
    const { pastPageNumber } = getState().home
    if (pastPageNumber === 1) {
      dispatch(resetPastEvents())
    }

    dispatch(loadPastEventsAttempt())

    const proxy = new EventsProxy()
    const eventsPage = await proxy.getPastEvents(searchObj, pastPageNumber)
    dispatch(loadPastEventsSuccess(eventsPage))
    return true
  } catch (error) {
    dispatch(loadPastEventsFailure(error))
    // dispatch(showSnackbarError(error))
    return false
  }
}
