import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  dialog: {
    alignSelf: 'center',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      width: 700,
      maxWidth: 700,
      margin: '0 auto'
    }
  },
  content: {
    [theme.breakpoints.up('md')]: {
      width: '80%'
    }
  },
  reUtilContainer: {
    margin: theme.spacing(4, 0),
    gap: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  reUtilButton: {
    color: theme.palette.primary.main,
    '& span': {
      fontWeight: 500
    }
  },
  infoContainer: {
    display: 'flex',
    gap: theme.spacing(),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  },
  optionsContainer: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    minHeight: 100,
    flexWrap: 'nowrap',
    maxHeight: 300,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 12,
      [theme.breakpoints.up('md')]: {
        width: 16
      }
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: 10,
      backgroundColor: theme.utils.rgba(theme.palette.primary.main, 0.2)
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      background: theme.utils.rgba(theme.palette.primary.main, 0.45)
    },
    '& label': {
      marginLeft: 0
    }
  },
  option: {
    width: '100%'
  },
  radioButton: {
    width: 14,
    height: 14,
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main
    },
    '& svg': {
      width: 14,
      height: 14
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2, 0),
    '& button': {
      marginLeft: -13,
      padding: theme.spacing(1, 7.5)
    }
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical'
  },
  loading: {
    alignSelf: 'center'
  },
  showButton: {
    alignSelf: 'center',
    padding: theme.spacing(1, 8),
    margin: theme.spacing(2, 0)
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(3),
    height: 50,
    backgroundColor: '#D9D9D9'
  }
}))
