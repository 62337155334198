import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 400
  },
  followed: {
    width: '40%',
    height: 10
  },
  button: {
    width: '25%',
    height: 20
  }
}))
