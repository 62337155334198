import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  slickSlider: {
    position: 'static'
  },
  container: {
    width: '100%',
    margin: '0 auto',
    position: 'relative',
    alignSelf: 'center',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      margin: 0
    },
    [theme.breakpoints.up('lg')]: {
      width: 600
    }
  },
  text: {
    fontWeight: 600,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.90vw'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.625rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  },
  prevButton: {
    marginLeft: -15,
    '&.hidden': {
      visibility: 'hidden'
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -15
    }
  },
  nextButton: {
    marginRight: -15,
    '&.hidden': {
      visibility: 'hidden'
    },

    [theme.breakpoints.up('md')]: {
      marginRight: -15
    }
  },
  arrowIcon: {
    width: 40,
    height: 40
  },

  continueLabel: {
    fontSize: '1.75rem',
    fontWeight: 600
  }
}))
