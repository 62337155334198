import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  scheduleSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  scheduleContainer: {
    marginTop: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  },
  anchorContainer: {
    display: 'none', // replace
    gap: theme.spacing(),
    alignItems: 'center'
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
    fontSize: '.875rem',
    cursor: 'pointer'
  },
  tooltip: {
    backgroundColor: theme.palette.tooltip.main,
    padding: theme.spacing(1.5, 3),
    margin: theme.spacing(0, 2),
    fontSize: '.875rem',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      maxWidth: 400
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 700
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2)
    }
  },
  dateLabel: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '1rem'
  }
}))
