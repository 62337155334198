import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    cursor: 'pointer'
  },
  alias: {
    height: 25,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(4),
    marginRight: -theme.spacing(3),
    fontSize: '1.125rem',
    fontWeight: 500,
    borderRadius: '15px 0px 0px 15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.secondary.main,
    maxWidth: 144,
    transition: 'all .3s',
    '&.secondary': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 164
    }
  },
  profileImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 40,
    height: 40,
    borderRadius: 70,
    flexShrink: 0,
    cursor: 'pointer'
  },
  actionContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'center'
  },
  link: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    fontSize: '1.125rem',
    fontWeight: 500
  },
  searchPopup: {
    top: '80px !important',
    left: '0 !important',
    maxWidth: 'none',
    width: '100%',
    position: 'relative',
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ffff',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  sessionOptions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.spacing(3)
  },
  button: {
    borderRadius: 10,
    height: 51,
    '& span': {
      fontSize: '1.125rem',
      fontWeight: 600
    }
  },
  closeButton: {
    color: theme.palette.primary.contrastText,
    '& svg': {
      width: 30,
      height: 30
    }
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  arrowIcon: {
    color: theme.palette.primary.contrastText,
    '&.secondary': {
      color: theme.palette.primary.main
    }
  }
}))
