import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-scroll'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { ROUTES } from 'routes'
import {
  AccountSportmetricDialog,
  AddOrganizerRoleDialog,
  CreateOrganizationInfoDialog,
  SignInDialog,
  SignUpDialog
} from 'shared'
import { ANALYTICS_EVENTS } from 'utils/constants'
import { trackEvent } from 'utils/functions'

import { MarkText } from '../MarkText'
import { NavBar } from '../NavBar'
import { SectionBlock } from '../SectionBlock'

import { useStyles } from './ChooseOptionSection.style'

const ChooseOptionSection = ({ activeAnimation, endAnimationAction, ...sectionProps }) => {
  const classes = useStyles()
  const history = useHistory()

  const { user, userData } = useSelector((state) => state.auth)
  const { organizationsAdminAll } = useSelector((state) => state.userAccounts)
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))

  const [openAccountSportmetricDialog, setOpenAccountSportmetricDialog] = useState(false)
  const [openSignInDialog, setOpenSignInDialog] = useState(false)
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false)
  const [openAddOrganizerRoleDialog, setOpenAddOrganizerRoleDialog] = useState(false)
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false)

  const optionsBasic = [
    'Generar el perfil de tu organización.',
    'Crear eventos  de manera  independiente o en conjunto con otros organizadores.',
    'Publicar y difundir tus eventos.',
    'Crear y compartir formulario externo de inscripción.',
    'Publicar los resultados de tus competencias, independientemente del sistema de cronometraje usado. '
  ]

  const optionsProffesional = [
    'Crear y personalizar el formulario de inscripción.',
    'Crear tickets para cobrar tus inscripciones.',
    'Habilitar los medios de pago más convenientes.',
    'Llevar el control de los pagos recibidos en un sólo lugar.',
    'Monitorear y administrar inscriptos a tus eventos.',
    'Descargar las planillas necesarias para: acreditación, seguro y cronometraje, generadas automáticamente.',
    'Enviar comunicaciones automáticas a inscriptos sobre cambios en el evento.',
    'Establecer comunicación directa con pre-inscriptos e inscriptos.'
  ]

  const handleOpenSignUpDialog = () => {
    setOpenSignUpDialog(true)
    setOpenSignInDialog(false)
  }

  const handleOpenSignInDialog = () => {
    setOpenSignInDialog(true)
    setOpenSignUpDialog(false)
  }

  const handleOnClose = () => {
    setOpenAddOrganizerRoleDialog(false)
    history.push(`${ROUTES.USERS_ACCOUNTS.PROFILE}/${user.id}`)
  }

  const redirectCreateOrganization = () => {
    if (!user) {
      setOpenAccountSportmetricDialog(true)
      return
    }
    if (!userData?.isOrganizer) {
      setOpenAddOrganizerRoleDialog(true)
      return
    }

    if (organizationsAdminAll.some((x) => x.organization.createdById === user.id)) {
      setOpenCreateOrganizationDialog(true)
      return
    }

    trackEvent(ANALYTICS_EVENTS.ACTION.CREATE_ORGANIZATION)
    history.push(ROUTES.ORGANIZATIONS.CREATE)
  }

  const handleOnCloseAll = () => {
    setOpenSignInDialog(false)
    setOpenSignUpDialog(false)
  }

  return (
    <SectionBlock
      containerClassName={classes.container}
      nextSectionId='monitorSection'
      buttonClassName={classes.endButton}
      {...sectionProps}>
      <NavBar />
      {(openSignInDialog || openSignUpDialog) && !isDesktop && (
        <NavBar
          altMode
          position='fixed'
          className={classes.appNavBar}
          onCloseProps={{
            activeClose: true,
            sportmetricLogo: classes.sportmetricLogo,
            buttonClassName: classes.closeButton,
            onCloseAction: handleOnCloseAll
          }}
        />
      )}
      <div className={classes.mainContainer}>
        <div className={classes.headerContainer}>
          <Typography color='primary' className={classes.title}>
            ELEGÍ&nbsp;
            <MarkText
              activeAnimation={activeAnimation}
              className={classes.bestOption}
              endAnimationAction={endAnimationAction}>
              LA MEJOR OPCIÓN
            </MarkText>
            &nbsp;PARA TU ORGANIZACIÓN
          </Typography>
        </div>
        <div className={classes.optionsCotainer}>
          <div className={classes.optionContainer}>
            <div className={classes.optionContent}>
              <div className={classes.titleOptionContainer}>
                <Typography color='primary' className={classes.titleOption}>
                  BASICO (gratis)
                </Typography>
              </div>
              <Typography color='primary' className={classes.subTextOption}>
                Con Sportmetric podés:
              </Typography>
              <div className={classes.infoContainer}>
                {optionsBasic.map((x, i) => (
                  <div className={classes.option} key={i}>
                    <CheckIcon className={classes.iconCheck} color='primary' />
                    <Typography className={classes.optionText}>{x}</Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonBasic}
                color='primary'
                variant='contained'
                onClick={redirectCreateOrganization}>
                Empezar
              </Button>
            </div>
          </div>
          <div className={classes.optionContainer}>
            <div className={clsx(classes.titleOptionContainer, 'secondary')}>
              <Typography color='primary' className={classes.titleOptionProfessional}>
                PROFESIONAL
              </Typography>
            </div>
            <Typography color='primary' className={classes.subTextOptionAlt}>
              Todo lo necesario para administrar y potenciar tus eventos con un mínimo porcentaje.
            </Typography>
            <div className={classes.infoContainer}>
              {optionsProffesional.map((x, i) => (
                <div className={classes.option} key={i}>
                  <CheckIcon className={classes.iconCheck} color='secondary' />
                  <Typography className={classes.optionText}>{x}</Typography>
                </div>
              ))}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                className={classes.buttonProfessional}
                color='secondary'
                to='frequentQuestionsSection'
                variant='contained'
                smooth={true}
                duration={500}
                onClick={() => trackEvent(ANALYTICS_EVENTS.ACTION.VIEW_MORE_QUESTIONS)}
                component={Link}>
                Ver +
              </Button>
            </div>
          </div>
        </div>
        <CreateOrganizationInfoDialog
          open={openCreateOrganizationDialog}
          onClose={() => setOpenCreateOrganizationDialog(false)}
        />
        <AccountSportmetricDialog
          open={openAccountSportmetricDialog}
          onClose={() => setOpenAccountSportmetricDialog(false)}
          setOpenSignInDialog={setOpenSignInDialog}
          setOpenSignUpDialog={setOpenSignUpDialog}
        />
        <SignInDialog
          open={openSignInDialog}
          onClose={() => setOpenSignInDialog(false)}
          handleOpenSignUpDialog={handleOpenSignUpDialog}
        />
        <SignUpDialog
          open={openSignUpDialog}
          onClose={() => setOpenSignUpDialog(false)}
          handleOpenSignInDialog={handleOpenSignInDialog}
        />

        <AddOrganizerRoleDialog open={openAddOrganizerRoleDialog} onClose={handleOnClose} />
      </div>
    </SectionBlock>
  )
}

export default ChooseOptionSection
