import axios from 'axios'

import BaseProxy from './BaseProxy'

export class CountriesProxy extends BaseProxy {
  async getAddress(latitude, longitude) {
    const urlParams = this._makeUrlParams({ latitude, longitude })
    const response = await axios.get(`${this._baseUrl}/countries/address/${urlParams}`)
    return response.data
  }

  async getCountries() {
    const response = await axios.get(`${this._baseUrl}/countries/all`)
    return response.data
  }

  async getAreaLevel1(id) {
    const response = await axios.get(`${this._baseUrl}/countries/${id}/area-level-1-list`)
    return response.data
  }

  async getAreaLevel2(id) {
    const response = await axios.get(`${this._baseUrl}/countries/${id}/area-level-2-list`)
    return response.data
  }
}

export default CountriesProxy
