import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
  tabMiniList: {
    display: 'flex',
    flexDirection: 'row',
    height: 60,
    alignItems: 'flex-start'
  },
  tabList: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  tabListMini: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  tab: {
    background: '#fff',
    border: 'none',
    textTransform: 'none',
    padding: theme.spacing(1, 1.5),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& span': {
        color: theme.palette.primary.main
      }
    },
    '& span': {
      fontSize: '0.9rem',
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('md')]: {
      background: 'rgba(6, 0, 173, .65)',
      padding: theme.spacing(1, 2),
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        '& span': {
          color: theme.palette.primary.main
        }
      },
      '& span': {
        fontSize: '1.125rem',
        color: '#ffffff'
      }
    }
  },
  tabSelected: {
    background: theme.palette.secondary.main,
    '& span': {
      color: theme.palette.primary.main
    }
  },
  tabDropdownList: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  tabDropdownListMini: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  tabDropdown: {
    border: 'none',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.25em',
    width: '100%',
    padding: theme.spacing(),

    '&:focus': {
      outline: 'none'
    },
    '&:active': {
      outline: 'none'
    }
  },
  tabDropdownMini: {
    borderColor: theme.palette.secondary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    padding: theme.spacing(),
    '&:focus': {
      outline: 'none'
    },
    '&:active': {
      outline: 'none'
    },
    '& option': {
      color: theme.palette.primary.main
    },

    [theme.breakpoints.up('md')]: {
      '&.secondary': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRight: 'none',
        backgroundColor: theme.palette.primary.contrastText
      }
    }
  }
}))

export default useStyles
